import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {IoMdClose} from "react-icons/io";
import SHSelect from "../../../components/common/SHSelect";
import {Modal, Table} from "react-bootstrap";
import SHTreeView from "../../../components/common/SHTreeView";
import restapi from "../../../helper/restAPIHelper";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import SHSearch from "../../component/SHSearch";
import SHInput from "../../../components/common/SHInput";
import axios from "axios";
import {toast} from "react-toastify";
import {CmmnFn} from "../../../helper/CmmnHelper";
import iconTrashBean from "../../../images/icon_trashBean.png";
import errorCodeDic from "../../../util/errorCodeDic";
import messageCodeDic from "../../../util/messageCodeDic";
import {ProgressContext} from "../../../context/Progress";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;

    siteId: number;
    wrkDt: string;
    qrNfcTpCd: string
    wrkPlcCd?: number;

}

const DprtDtlStatModal = (props: IProps) => {
    const [dataList, setDataList] = useState<any>([])
    const [datatable, setDatatable] = useState<any>();
    const searchFormRef = useRef<any>();
    const {spinner} = useContext(ProgressContext);


    const fn_afterOpenEvnt = () => {
        fn_retrieveDataList();
    }

    const fn_retrieveDataList = () => {
        let param:any = {siteId:props.siteId, wrkDt:props.wrkDt, qrNfcTpCd:props.qrNfcTpCd}
        if(props.wrkPlcCd){
            param.wrkPlcCd = props.wrkPlcCd;
        }

        restapi.retrieveDprtDtlStatDataList(param).then((result) => {
            setDataList(result);
        })
    }


    function fn_closeModal() {
        props.setModalIsOpen(false);
    }


    const fn_select = () => {
        var checkedData = getCheckedItems(datatable);

        if(checkedData.length == 0){
            toast.warn('위험요인을 선택해 주십시오.');
            return;
        }

        // @ts-ignore
        checkedData = [].concat(checkedData);
        // @ts-ignore
        props.setDngrFctrDataList(props.dngrFctrDataList.concat(checkedData))

        props.setModalIsOpen(false);


    }

    const fn_onCapChkClick = (data: any) => {
        data.wrkDt = props.wrkDt.replace(/-/gi,'');
        spinner.start();
        restapi.toggleDailyNoCapWrkrData(data).then((rsltData: any) => {
            if (rsltData > 0) {
                fn_retrieveDataList();
            } else {
                toast.error(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });



    }


    return (
        <div>
            <Modal
                className="sh-modal sub" fullscreen
                show={props.modalIsOpen}
                onShow={fn_afterOpenEvnt}
                onHide={fn_closeModal}
            >

                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        출역현황 상세보기 [{props.wrkDt}]
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4 sh-modal-table-w100">

                    <div className="sh-modal-div">
                        <div className="div-body px-3">
                            <div className="card-body">
                                <table
                                    className="sh-table-primary w-100 dataTable no-footer">
                                    <thead>
                                    <tr>
                                        <th className={'text-center'}>No</th>
                                        <th className={'text-center'}>성명</th>
                                        <th className={'text-center'}>업체</th>
                                        <th className={'text-center'}>공종</th>
                                        <th className={'text-center'}>직종</th>
                                        <th className={'text-center'}>현장 입장 시간</th>
                                        <th className={'text-center'}>현장 퇴장 시간</th>
                                        <th className={'text-center'}>개인보호구 미착용 체크</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        dataList.length > 0 ?
                                            dataList.map((data: any, idx:number) => (
                                                <tr key={idx}>
                                                    <td className={'text-center'}>
                                                        {idx + 1}
                                                    </td>
                                                    <td className={'text-center'}>
                                                        {data.userNm}
                                                    </td>
                                                    <td className={'text-center'}>
                                                        {data.cmpnyNm}
                                                    </td>
                                                    <td className={'text-center'}>
                                                        {data.workTypeNm}
                                                    </td>
                                                    <td className={'text-center'}>
                                                        {data.ocptNm}
                                                    </td>
                                                    <td className={'text-center'}>
                                                        {data.entrTime}
                                                    </td>
                                                    <td className={'text-center'}>
                                                        {data.exitTime}
                                                    </td>
                                                    <td className={'text-center'}>
                                                        <input onChange={e => fn_onCapChkClick(data)} checked={data.isNoCap} type="checkbox" className="form-check-input sh-check" />
                                                    </td>
                                                </tr>

                                            )) : <tr>
                                                <td colSpan={7}>출역 내역이 없습니다.</td>
                                            </tr>
                                    }

                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DprtDtlStatModal;
