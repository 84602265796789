const messageCodeDic = {
    '001': '저장 되었습니다.',
    '002': '삭제 되었습니다.',
    '003': '저장 하시겠습니까?',
    '004': '삭제 하시겠습니까?',
    '005': '삭제할 데이터를 선택해 주십시오',
    '006': '정상 처리 되었습니다.',
    '007': '복사할 데이터를 선택해 주십시오',

}

export default messageCodeDic;
