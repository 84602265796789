import React from "react";
import {Container, Row} from "react-bootstrap";
import DashWeather from "../home/DashWeather";
import HOMonthlyManagementCalendar from "./HOMonthlyManagementCalendar";
import HOWeeklySafetyCheck from "./HOWeeklySafetyCheck";
import HOLocalFieldStatus from "./HOLocalFieldStatus";
import HOConstructionSummary from "./HOConstructionSummary";
import logo_gsil from "../../assets/images/logo/logo_gsli.png";

export default function HOTotalSafetyStatus() {
    return(
        <Container fluid className="py-3" style={{overflow: "hidden"}}>
            <Row>
                <div style={{width:"400px", height:"calc(100vh - 116px)", overflowY:"scroll"}}>
                    <DashWeather />
                    <HOMonthlyManagementCalendar />
                    <HOWeeklySafetyCheck />
                </div>
                <div style={{flex: "1 0 0%"}}>
                    <HOLocalFieldStatus />
                </div>
                <div style={{width:"700px"}}>
                    <HOConstructionSummary />
                    <div className="mt-2 w-100 d-flex justify-content-end align-items-end">
                        <img  style={{width: 70}} src={logo_gsil} alt="로고" />
                    </div>
                </div>
            </Row>
        </Container>
    );
}
