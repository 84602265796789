import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from "axios";
import Datatables, {getCheckedItems} from "../component/Datatables";
import Modal from 'react-modal';

import NoticeRegistModal from "./modal/NoticeRegistModal";
import {MultiSelect} from '@grapecity/wijmo.react.input';
import {getLoginUser} from "../../helper/SecurityHelper";
import {toast} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import SHSearch from '../component/SHSearch';
import {Col, Container, Row, Table} from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";
import SHIconButton from "../../components/common/SHIconButton";
import SHSelect from "../../components/common/SHSelect";
import SHInput from "../../components/common/SHInput";
import {UserContext} from "../../context/User";
import SafetyNotificationManagementModal from "../safetyNotificationManagement/modal/SafetyNotificationManagementModal";


type Props = {};


Modal.setAppElement('#root');
const NoticeRegistrationPage = (props: Props) => {
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [noticeDetailData, setNoticeDetailData] = useState(null);
    const defaultUser = getLoginUser();
    const cmpnySeqRef = useRef<any>();
    const srchTxtRef = useRef<any>();
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const {user} = useContext<any>(UserContext);
    const [isCodeDataLoaded, setIsCodeDataLoaded] = useState<boolean>(false);


    useEffect(()=>{

        if(datatable){
            datatable.draw();
        }

    }, [user, datatable])

    useEffect(() => {

        datatable?.draw();

    }, [modalIsOpen])

    useEffect(() => {
        if(isCodeDataLoaded){
            datatable?.draw();
        }

    }, [isCodeDataLoaded])


    const fn_delete = () => {
        let checkedData = getCheckedItems(datatable, "noticeId")

        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }

        if (!window.confirm(messageCodeDic['004'])) {
            return;
        }

        axios.post('/admin/api/notice/deleteNoticeData', {delList: checkedData}).then(rsltData => {
                if (rsltData.data > 0) {
                    toast.success(messageCodeDic['002'])
                    datatable?.draw();
                } else {
                    toast.error(errorCodeDic['003'])
                }

            }
        ).catch(() => {
                toast.error(errorCodeDic['003'])
            }
        )
    }



    const fn_registPopOpen = () => {
        setNoticeDetailData(null);
        setModalIsOpen(true);
    }

    const fn_updateViewCnt = (param: object) => {
        axios.post('/admin/api/notice/updateViewCnt', param)
    }



    return (
        <Container fluid className="bg-white px-4 pt-4 sh-text-font_primary">
            <SubMenuTitle title={'공지사항 등록'} parentMenu={'공지사항 관리'}/>
            {/* 공지사항 모달 */}
            <NoticeRegistModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} siteId={user?.blngSiteId || defaultUser.blngSiteId}
                               noticeDetailData={noticeDetailData} />
            <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                <Table className="sh-font-SP sh-fs-b2 my-3">
                    <tbody>
                    <tr className="border-top align-middle border-bottom">

                        <th className="sh-bg-th">업체</th>
                        <td>
                            <SHInput type={'hidden'} name={'siteId'} value={user?.blngSiteId || defaultUser.blngSiteId}></SHInput>
                            <SHSelect name={'rgstCmpnySeq'} setIsCodeDataLoaded={setIsCodeDataLoaded} initOptionGb={'all'} codeGb={'siteCmpny'} selParam={{siteId:user?.blngSiteId || defaultUser.blngSiteId}}></SHSelect>
                        </td>
                        <th className="sh-bg-th">검색어</th>
                        <td style={{width:'40%'}}>
                            <input type={'text'} name={'srchTxt'} className='form-control' ref={srchTxtRef}/>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </SHSearch>


            <div className='w-100 d-flex justify-content-end align-items-center mb-2'>

                <SHIconButton kind={'submit'} marginRight={'5px'} onClick={fn_registPopOpen}>등록</SHIconButton>

                <SHIconButton kind={'delete'} marginRight={'0'} onClick={fn_delete}>삭제</SHIconButton>
            </div>

            <div className={'ge-result'}>
                <Datatables
                    dataUrl={'/admin/api/notice/retrieveNoticeDataList'}
                    parameterFormRef={searchFormRef}
                    columns={[

                        {data: "title", title: "제목"},
                        {data: "noticePeriod", title: "공지기간"},
                        {data: "regDate", title: "작성일"},
                        {data: "viewCount", title: "조회수"},
                    ]}
                    checked={true}
                    index={true}

                    onLoad={(dt: any) => {
                        setDatatable(dt)
                    }}
                    onClick={(data) => {
                        setNoticeDetailData(data)
                        setModalIsOpen(true);
                        fn_updateViewCnt(data);
                    }
                    }
                />
            </div>
        </Container>


    );
};

export default NoticeRegistrationPage;
