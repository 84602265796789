import SHSearch from "../../component/SHSearch";
import {Col, Row, Table} from "react-bootstrap";
import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import Datatables from "../../component/Datatables";
import ecardApi from "../../../helper/ecardAPIHelper";
import AsscWrkDtlApiModal from "./AsscWrkDtlApiModal";

interface IProps {
   statData: any;
   dataList: any;
   srchData: any;
   setSrchData: Dispatch<SetStateAction<boolean>>;
   setSelData: Dispatch<SetStateAction<any>>;
   wrkDt: string;
   setWrkDt: Dispatch<SetStateAction<string>>;
   isDatePopupOpen: boolean;
   setIsDatePopupOpen: Dispatch<SetStateAction<boolean>>;

}

const AsscWrkrInfoApiPage = (props: IProps) => {
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [selectWorkerSeq, setSelectWorkerSeq] = useState<number>();
    const [place, setPlace] = useState<any>([]);
    const [company, setCompany] = useState<any>([]);
    const [deductionNo, setDeductionNo] = useState<string>();
    const [licenseNo, setLicenseNo] = useState<string>();
    const [nationalityCd, setNationalityCd] = useState<string>();
    const [dataList, setDataList] = useState<any>([]);
    useEffect(() => {
        ecardApi.placeList().then((r)=>{
            setPlace(r)
        })
    }, [])
    useEffect(() => {
        if(deductionNo){
            ecardApi.companyList(deductionNo).then((r)=>{
                setCompany(r)
            })
        }
    }, [deductionNo])
    function fn_search(){
        ecardApi.workerList(deductionNo, licenseNo, nationalityCd).then((r)=>{
            setDataList(r)
        })
    }
    return(
        <>
            <AsscWrkDtlApiModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} workerSeq={selectWorkerSeq} />
            <SHSearch btnType={'p'} onSearch={fn_search}>
                <Row>
                    <Col>
                        <Table className="sh-font-SP sh-fs-b2 my-3">
                            <tbody>
                            <tr className="border-top border-bottom align-middle">
                                <th className="sh-bg-th" style={{width: '10%'}}>현장</th>
                                <td style={{width: '35%'}}>
                                    <select className={"form-select"} onChange={(e)=>setDeductionNo(e.target.value)}>
                                        <option value={""}> - 선택 - </option>
                                        {place && place.map((p: any)=>{
                                          return <option value={p.deductionNo}>{p.placeNm}</option>
                                        })}
                                    </select>
                                </td>
                                <th className="sh-bg-th" style={{width: '10%'}}>국적</th>
                                <td style={{width: '35%'}}>
                                    <select className={"form-select"} onChange={(e) => {
                                        setNationalityCd(e.target.value)
                                    }}>
                                        <option value={""}> - 전체 -</option>
                                        <option value={"KO"}>대한민국</option>
                                        <option value={"CN"}>중국</option>
                                        <option value={"VN"}>베트남</option>
                                        <option value={"UZ"}>우즈베키스탄</option>
                                        <option value={"PH"}>필리핀</option>
                                        <option value={"TH"}>태국</option>
                                        <option value={"ID"}>인도네시아</option>
                                        <option value={"KH"}>캄보디아</option>
                                        <option value={"LK"}>스리랑카</option>
                                        <option value={"MM"}>미얀마</option>
                                        <option value={"MN"}>몽골</option>
                                        <option value={"NP"}>네팔</option>
                                        <option value={"ETC"}>기타</option>
                                    </select>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </SHSearch>
            <div className={'ge-result mb-5'}>
                <Datatables
                    datas={dataList}
                    className="sh-fs-b4"
                    columns={[
                        {data: "workerSeq", title: "근로자번호"},
                        {data: "workerNm", title: "근로자명"},
                        {data: "workerNick", title: "근로자한글명"},
                        {data: "nationalityCd", title: "국적", render:(data: any) => ecardApi.nationalityNm(data) },
                        {data: "birth", title: "생년월일"},
                        {data: "gender", title: "성별"},
                        {data: "phone", title: "연락처"},
                        {data: "zipNo", title: "우편번호"},
                        {data: "addr", title: "기본주소", textAlign: 'left'},
                        {data: "addrDetail", title: "상세주소", textAlign: 'left'},
                        {data: "employmentCertificateNo", title: "취업인정교육증 번호"},
                        {data: "employmentAuthEduInsttNm", title: "취업교육기관명"},
                        {data: "employmentCertificateValidStartDate", title: "증명서 유효 시작일"},
                        {data: "employmentCertificateValidEndDate", title: "증명서 유효 종료일"}
                    ]}
                    checked={false}
                    onLoad={(dt: any) => {
                        setDatatable(dt)
                    }}
                    onClick={(data) => {
                        setSelectWorkerSeq(data.workerSeq);
                        setModalIsOpen(true);
                    }
                }
                />
            </div>
        </>
        )

}

export default AsscWrkrInfoApiPage;
