import React, {useEffect, useRef, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import restapi from "../../helper/restAPIHelper";
import SftyChkInfoListModal from "./modal/SftyChkInfoListModal";
import SiteSftyChkInfoListModal from "./modal/SiteSftyChkInfoListModal";

const HOCRRiskStatus = () => {
    const [actNoCmpltCnt, setActNoCmpltCnt] = useState<number>(0);
    const [actNoCmpltSiteCnt, setActNoCmpltSiteCnt] = useState<number>(0);
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [sftyChkModalIsOpen, setSftyChkModalIsOpen] = useState<boolean>(false);
    const [dataList, setDataList] = useState<any>([]);
    const [actNoCmpltSiteDataList, setActNoCmpltSiteDataList] = useState<any>([]);
    const [sftyChkDataList, setSftyChkDataList] = useState<any>([]);
    const timerRef = useRef<any>();

    useEffect(() => {
        fn_retrieveData();
        timerRef.current = setInterval(fn_retrieveData, 5000);

        return () => {
            clearInterval(timerRef.current);
        };
    }, []);
    const fn_retrieveData = () => {
        restapi.retrieveSftyChkActStatData({}).then((rsltData: any) => {
            setActNoCmpltCnt(rsltData.actNoCmpltCnt);

        })

        restapi.retrieveActNoCmpltSiteDataList().then((rsltData: any) => {
            setActNoCmpltSiteDataList(rsltData);
            setActNoCmpltSiteCnt(rsltData.length);
        })
    }


    function fn_openSiteList(){


        setDataList(actNoCmpltSiteDataList)
        setModalIsOpen(true);
    }

    const fn_openSftyChkInfoModal = () => {

        restapi.retrieveSiteSftyManageDataTotList({actStatGbCd: 'C005002'}).then((rsltData: any) => {
            setSftyChkDataList(rsltData);
            setSftyChkModalIsOpen(true);
        })

    }


    return(
        <>
            <SiteSftyChkInfoListModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} dataList={dataList} />
            <SftyChkInfoListModal setModalIsOpen={setSftyChkModalIsOpen} modalIsOpen={sftyChkModalIsOpen} dataList={sftyChkDataList} ></SftyChkInfoListModal>
            <Container fluid className="sh-bg-pane rounded-[5px] py-2 px-3">
                <Row className="h-100">
                    <Col>
                        <div className="d-flex flex-column justify-content-between h-100">
                            <div>
                                <div className="pe-2 sh-fs-h4 sh-fw-5 sh-font-SD mb-2">위험 관리 현황</div>
                                <Row>
                                    <Col>
                                        <div onClick={fn_openSiteList} className="rounded-3 p-2 cursor-pointer" style={{backgroundColor: '#35D0B7', lineHeight: '20px'}}>
                                            <div className="sh-fw-5">미조치 현장수</div>
                                            <div className="text-end"><span className="sh-fs-h3 sh-fw-6 me-1">{actNoCmpltSiteCnt}</span>개소</div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div onClick={fn_openSftyChkInfoModal} className="rounded-3 p-2 cursor-pointer" style={{backgroundColor: '#FF718B', lineHeight: '20px'}}>
                                            <div className="sh-fw-5">미조치 대상건</div>
                                            <div className="text-end"><span className="sh-fs-h3 sh-fw-6 me-1">{actNoCmpltCnt}</span>건</div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default HOCRRiskStatus;
