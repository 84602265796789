import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from "axios";
import Datatables, { getCheckedItems } from "../component/Datatables";
import Modal from 'react-modal';
import {getLoginUser} from "../../helper/SecurityHelper";
import {toast} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import SHSelect from "../../components/common/SHSelect";
import SafetyEduRgstModal from "./modal/SafetyEduRgstModal";
import {CmmnFn} from "../../helper/CmmnHelper";
import {ProgressContext} from "../../context/Progress";
import SHSearch from '../component/SHSearch';
import {Col, Container, Row, Table} from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";
import SHIconButton from '../../components/common/SHIconButton';
import {UserContext} from "../../context/User";
import SHInput from "../../components/common/SHInput";
import SHCheckbox from "../../components/common/SHCheckbox";
import SHDatePicker2 from "../../components/common/SHDatePicker2";
import {DateHelper} from "../../helper/DateHelper";


type Props = {};

Modal.setAppElement('#root');
const SafetyEducationManagementPage = (props: Props) => {
    const [dataList, setDataList] = useState<any[]>([]);
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState(null);
    const [isSaveSuccess, setIsSaveSuccess] = useState(false);
    const defaultUser = getLoginUser();
    const srchTxtRef = useRef<any>();
    const [srchData, setSrchData] = useState<any>()
    const {spinner} = useContext(ProgressContext);
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const {user} = useContext<any>(UserContext);
    const [startDate, setStartDate] = useState<any>(DateHelper.dateByMonth(-3));
    const [endDate, setEndDate] = useState<any>(DateHelper.dateByMonth(0));


    useEffect(()=>{

        if(datatable){
            datatable.draw();
        }

    }, [user, datatable])

    useEffect(() => {
        if (!modalIsOpen) {
            datatable?.draw();
        }
    }, [modalIsOpen])

    const fn_setSearchData = (e: any) => {

        if (e.target.type == 'checkbox') {
            setSrchData({
                ...srchData,
                [e.target.name]: e.target.checked
            })
        } else {

            setSrchData({
                ...srchData,
                [e.target.name]: e.target.value
            })
        }
    }

    const fn_search = (e?: any) => {

        if (e) {
            e.preventDefault()
        }
        datatable.draw();

        setIsSaveSuccess(false);
    }

    const fn_delete = () => {
        var checkedData = getCheckedItems(datatable, 'eduId');

        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }

        if (!window.confirm(messageCodeDic['004'])) {
            return;
        }

        axios.post('/admin/api/edu/deleteEduData', {delList: checkedData}).then(rsltData => {
                if (rsltData.data > 0) {
                    toast.success(messageCodeDic['002'])
                    fn_search();
                } else {
                    toast.error(errorCodeDic['003'])
                }

            }
        ).catch(() => {
                toast.error(errorCodeDic['003'])
            }
        )
    }


    const fn_registPopOpen = () => {
        setDetailData(null);
        setModalIsOpen(true);
    }

    const fn_updateViewCnt = (param: object) => {
        axios.post('/admin/api/notice/updateViewCnt', param)
    }

    const fn_onIsAllProdChg = (e:any) => {
        if(e.target.checked){
            setStartDate(null);
            setEndDate(null);
        }else{
            setStartDate(DateHelper.dateByMonth(-3));
            setEndDate(DateHelper.dateByMonth(0));
        }
    }


    return (
        <Container fluid className="bg-white px-4 pt-4 sh-text-font_primary">
            <SubMenuTitle title={'안전교육 관리'} parentMenu={'안전교육 관리'}/>

            <SafetyEduRgstModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen}  siteId={user?.blngSiteId || defaultUser.blngSiteId}
                                detailData={detailData} setIsSaveSuccess={setIsSaveSuccess}/>

                <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                    <Row>
                        <Col>
                            <Table className="sh-font-SP sh-fs-b2 my-3">
                                <tbody>
                                <tr className="border-top border-bottom align-middle">
                                    <th className="sh-bg-th">교육종류</th>
                                    <td>
                                        <SHInput type={'hidden'} name={'siteId'} value={user?.blngSiteId || defaultUser.blngSiteId} />
                                        <SHSelect initOptionGb={'all'} name={'eduGbCd'} codeGb={'cmmn'} codeGrpId={'E001'} className={"min-w-[100px]"}
                                                  onChange={fn_setSearchData}
                                                  value={srchData?.eduGbCd}></SHSelect>
                                    </td>
                                    <th className="sh-bg-th">기간</th>
                                    <td>
                                        <div className="d-flex align-items-center w-100">
                                            <div className="d-flex align-items-center w-100" >
                                                <SHDatePicker2 setter={setStartDate} name={'strtDt'} defaultValue={startDate} onChange={fn_setSearchData} />
                                                <span>&nbsp;~&nbsp;</span>
                                                <SHDatePicker2 setter={setEndDate} name={'endDt'} defaultValue={endDate} onChange={fn_setSearchData} />
                                            </div>
                                        <SHCheckbox name={'isAllProd'} onChange={(e: any) => {fn_setSearchData(e); fn_onIsAllProdChg(e);}}
                                                    checked={srchData?.isAllProd} className={'ml-3 w-[110px]'}>전체기간</SHCheckbox>
                                        </div>
                                    </td>

                                    <th className="sh-bg-th">검색어</th>
                                    <td className="w-[40%]">
                                        <input type={'text'} name={'srchTxt'} className='form-control' ref={srchTxtRef}/>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </SHSearch>


            <div className='w-100 d-flex justify-content-end align-items-center mb-2'>
                <SHIconButton kind={'submit'} marginRight={'5px'} onClick={fn_registPopOpen}>등록</SHIconButton>
                <SHIconButton kind={'delete'} marginRight={'0'} onClick={fn_delete}>삭제</SHIconButton>
            </div>

            <div className={'ge-result'}>
                <Datatables
                    dataUrl={'/admin/api/edu/retrieveEduDataList'}
                    parameterFormRef={searchFormRef}
                    columns={[
                        {data: "eduGbNm", title: "구분"},
                        {data: "eduTitle", title: "교육제목"},
                        {data: "eduDt", title: "교육일자"},
                        {data: "tchrNm", title: "강사성명"},
                        {data: "eduPlc", title: "교육장소"},
                        {data: "eduTimeProd", title: "교육 시간"},
                        {data: "rgstrNm", title: "작성자"},
                    ]}
                    checked={true}
                    index={true}

                    onLoad={(dt: any) => {
                        setDatatable(dt)
                    }}
                    onClick={(data) => {
                        setDetailData(data)
                        setModalIsOpen(true);
                        fn_updateViewCnt(data);
                    }
                    }
                />
            </div>


        </Container>


    );
};

export default SafetyEducationManagementPage;
