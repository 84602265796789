import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from "axios";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import {Col, Container, Row, Table } from 'react-bootstrap';
import Datatables, {getCheckedItems} from "../component/Datatables";
import {toast, ToastContainer} from "react-toastify";
import SHSearch from "../component/SHSearch";
import SubMenuTitle from "../component/SubMenuTitle";
import SHSelect from "../../components/common/SHSelect";
import Modal from "react-modal";
import {getLoginUser} from "../../helper/SecurityHelper";
import SHDatePicker2 from "../../components/common/SHDatePicker2";
import SHInput from "../../components/common/SHInput";
import {UserContext} from "../../context/User";
import {DateHelper} from "../../helper/DateHelper";
import IncgrtyPicModal from "../siteSafetyManagement/modal/IncgrtyPicModal";
import SosRqstHisModal from "./modal/SosRqstHisModal";

type Props = {};

Modal.setAppElement('#root');

const SosRqstHisPage = (props: Props) => {
    const [datatable, setDatatable] = useState<any>();

    const [workTypeCdList, setwWrkTypeCdList] = useState<any>([]);
    const [ocptCdList, setOcptCdList] = useState<any>([]);
    const prodRef = useRef<any>();
    const [startDate, setStartDate] = useState<Date>(DateHelper.dateByMonth(-3));
    const [endDate, setEndDate] = useState<Date>(DateHelper.dateByMonth(0));
    const [data, setData] = useState<any>({})
    const searchFormRef = useRef<any>();
    const workTypeCdRef = useRef<any>();
    const ocptCdRef = useRef<any>();
    const defaultUser = getLoginUser();
    const {user} = useContext<any>(UserContext);
    const [isCodeDataLoaded, setIsCodeDataLoaded] = useState<boolean>(false);
    const [isPicModalOpen, setIsPicModalOpen] = useState<boolean>(false);
    const [picDtlData, setPicDtlData] = useState<any>({});
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState<any>({});

    useEffect(()=>{

        if(datatable){
            fn_retrieveSrchCond(user?.blngSiteId || defaultUser.blngSiteId)
            datatable.draw();
        }

    }, [user, datatable])

    useEffect(() => {
        if(isCodeDataLoaded){
            datatable?.draw();
        }

    }, [isCodeDataLoaded, modalIsOpen])

    const fn_retrieveSrchCond = (siteId: number) => {
        //공종조회
        fn_retrievesetConstructionItemList(defaultUser.blngSiteId).then((rlstData) => {
            setwWrkTypeCdList(rlstData);
        })
        //직종조회
        fn_retrievesetOccupationItemList(defaultUser.blngSiteId).then((rlstData) => {
            setOcptCdList(rlstData);
        })
    }

    /**
     * 공종 콤보박스 조회
     */
    const fn_retrievesetConstructionItemList = (siteId: string) => {
        return new Promise(function (resolve, reject) {

            axios.post('/admin/api/manage/retrieveSiteManageItemList', {
                siteId: siteId,
                manageCode: 'M001001'
            }).then((result) => {
                resolve(result.data);
            })
        });
    }

    /**
     * 직종 콤보박스 조회
     */
    const fn_retrievesetOccupationItemList = (siteId: string) => {
        return new Promise(function (resolve, reject) {
            axios.post('/admin/api/manage/retrieveSiteManageItemList', {
                siteId: siteId,
                manageCode: 'M001002'
            }).then((result) => {
                resolve(result.data);
            })
        });
    }

    const fn_setFormData = (e: any) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Container fluid className="bg-white px-4 pt-4 sh-text-font_primary">
            <IncgrtyPicModal detailData={picDtlData} setModalIsOpen={setIsPicModalOpen} modalIsOpen={isPicModalOpen} />
            <SubMenuTitle title={'SOS 요청 이력'} parentMenu={'공지사항 관리'} />
            <SosRqstHisModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} detailData={detailData}/>

            <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                <Table className="sh-font-SP sh-fs-b2 my-3">
                    <tbody>
                    <tr className="border-top border-bottom align-middle">
                        <th className="sh-bg-th">업체</th>
                        <td>
                            <SHInput type={'hidden'} name={'siteId'} value={user?.blngSiteId || defaultUser.blngSiteId}></SHInput>
                            <SHSelect name={'cmpnySeq'} onChange={fn_setFormData} value={data.cmpnySeq} setIsCodeDataLoaded={setIsCodeDataLoaded} initOptionGb={'all'} codeGb={'siteCmpny'} selParam={{siteId:user?.blngSiteId || defaultUser.blngSiteId}}></SHSelect>

                        </td>
                        <th className="sh-bg-th">공종</th>
                        <td>
                            <select className='form-select' name={'workTypeCd'} ref={workTypeCdRef}
                                    onChange={fn_setFormData}>
                                <option value=''>전체</option>
                                {workTypeCdList.map((data: any, idx: number) => (
                                    <option key={idx} value={data.manageSn}>{data.manageName}</option>
                                ))}
                            </select>
                        </td>
                        <th className="sh-bg-th">직종</th>
                        <td>
                            <select className='form-select min-w-[100px]' name={'ocptCd'} ref={ocptCdRef}
                                    onChange={fn_setFormData}>
                                <option value=''>전체</option>
                                {ocptCdList.map((data: any, idx: number) => (
                                    <option key={idx} value={data.manageSn}>{data.manageName}</option>
                                ))}
                            </select>
                        </td>
                    </tr>
                    <tr className="border-bottom align-middle">
                        <th className="sh-bg-th">기간</th>
                        <td>
                            <div className="d-flex align-items-center w-100" ref={prodRef}>
                                <SHDatePicker2 setter={setStartDate} name={'startRgstDt'} defaultValue={startDate} onChange={fn_setFormData} />
                                <span>&nbsp;~&nbsp;</span>
                                <SHDatePicker2 setter={setEndDate} name={'endRgstDt'} defaultValue={endDate} onChange={fn_setFormData} />
                            </div>
                        </td>
                        <td colSpan={6}>
                            <div className="d-flex align-items-center">
                                <div className={'form-check w-25'}>
                                    <input className="form-check-input sh-check" id={'isAll'} name={'isAll'} type="checkbox" onChange={fn_setFormData}/>
                                    <label className={'form-check-label text-black'} htmlFor={'isAll'}>전체기간</label>
                                </div>
                                <SHInput className={'form-control w-100'} name={'srchTxt'} onChange={fn_setFormData}/>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </SHSearch>
            <div className={'ge-result'}>
                <Datatables
                    dataUrl={'/admin/api/sos/retrieveSosHisDataList'}
                    parameterFormRef={searchFormRef}
                    columns={[
                        {data: "sosGbNm", title: "구분", textAlign: 'center'},
                        {
                            data: "statCd", title: "상태", textAlign: 'center',
                            render: (data: any, type: any, row: any) => {
                                if (row.statCd == null || row.statCd == '' || row.statCd == 'P001001') {
                                    return '발생';
                                } else if(row.statCd == 'P001002'){
                                    return '해제';
                                } else if(row.statCd == 'P001003'){
                                    return '오류';
                                } else if(row.statCd == 'P001004'){
                                    return '처리중';
                                } else {
                                    return '-';
                                }
                            }
                        },
                        {data: "rgstDt", title: "요청일", textAlign: 'center'},
                        {data: "rgstTime", title: "요청시간", textAlign: 'center'},
                        {data: "userName", title: "성명", textAlign: 'center'},
                        {data: "userPhone", title: "전화번호", textAlign: 'center'},
                        {data: "companyName", title: "업체", textAlign: 'center'},
                        {data: "workTypeNm", title: "공종", textAlign: 'center'},
                        {data: "ocptNm", title: "직종", textAlign: 'center'},
                        {
                            title: "사진",
                            type: "button",
                            className: "text-center",
                            buttons: [{
                                label: "<img style='height: 20px' class='cursor-pointer' src='/icon_picture.svg' />",
                                onClick: (v: any) => {
                                    if(v.atchFileId){
                                        setPicDtlData({title: '사진', fileId: v.atchFileId})
                                        setIsPicModalOpen(true);

                                    }else{
                                        toast.warn('등록된 사진이 없습니다');
                                    }
                                },
                            }],
                        },
                    ]}

                    checked={true}
                    index={true}

                    onLoad={(dt: any) => {
                        setDatatable(dt)
                    }}

                    onClick={(data) => {
                        setDetailData(data);
                        setModalIsOpen(true);
                    }}
                >
                </Datatables>
            </div>
        </Container>
    );
};

export default SosRqstHisPage;
