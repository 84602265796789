import React, {useEffect, useRef, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {Doughnut} from "react-chartjs-2";
import restapi from "../../helper/restAPIHelper";
import MonitorWrkrInfoListModal from "../home/modal/MonitorWrkrInfoListModal";
import InOutStatPerSiteInfoListModal from "../home/modal/InOutStatPerSiteInfoListModal";

const HOCRInOut = () => {

    const [workerData, setWorkerData] = useState<any>({
        labels: ['근로자', '관리자'],
        datasets: [
            {
                data: [10, 9],
                backgroundColor: [
                    '#9CD5FF',
                    '#5685F7',
                ],
                borderColor: [
                    'rgba(30, 31, 35, 1)',
                    'rgba(30, 31, 35, 1)',
                ],
                borderWidth: 1,
                hoverOffset: 5,
            },
        ],
    })
    const [elderlyData, setElderlyData] = useState<any>({
        labels: ['고령자', '그 외'],
        datasets: [
            {
                data: [10, 9],
                backgroundColor: [
                    '#B7EDED',
                    '#35D0B7',
                ],
                borderColor: [
                    'rgba(30, 31, 35, 1)',
                    'rgba(30, 31, 35, 1)',
                ],
                borderWidth: 1,
                hoverOffset: 5,
            },
        ],
    })
    const [foreignerData, setForeignerData] = useState({
        labels: ['내국인', '외국인'],
        datasets: [
            {
                data: [10, 9],
                backgroundColor: [
                    '#F0E5FC',
                    '#C893FD',
                ],
                borderColor: [
                    'rgba(30, 31, 35, 1)',
                    'rgba(30, 31, 35, 1)',
                ],
                borderWidth: 1,
                hoverOffset: 5,
            },
        ],
    })

    const [smWorkerData, setSmWorkerData] = useState<any>({
        labels: ['근로자', '관리자'],
        datasets: [
            {
                data: [10, 9],
                backgroundColor: [
                    '#9CD5FF',
                    '#5685F7',
                ],
                borderColor: [
                    'rgba(30, 31, 35, 1)',
                    'rgba(30, 31, 35, 1)',
                ],
                borderWidth: 1,
                hoverOffset: 5,
            },
        ],
    })
    const [smElderlyData, setSmElderlyData] = useState<any>({
        labels: ['고령자', '그 외'],
        datasets: [
            {
                data: [10, 9],
                backgroundColor: [
                    '#B7EDED',
                    '#35D0B7',
                ],
                borderColor: [
                    'rgba(30, 31, 35, 1)',
                    'rgba(30, 31, 35, 1)',
                ],
                borderWidth: 1,
                hoverOffset: 5,
            },
        ],
    })
    const [smForeignerData, setSmForeignerData] = useState({
        labels: ['내국인', '외국인'],
        datasets: [
            {
                data: [10, 9],
                backgroundColor: [
                    '#F0E5FC',
                    '#C893FD',
                ],
                borderColor: [
                    'rgba(30, 31, 35, 1)',
                    'rgba(30, 31, 35, 1)',
                ],
                borderWidth: 1,
                hoverOffset: 5,
            },
        ],
    })

    const [totCnt, setTotCnt] = useState<number>(0);
    const [smTotCnt, setSmTotCnt] = useState<number>(0);
    const timerRef = useRef<any>();
    const [statData, setStatData] = useState<any>({wrkrCnt:0, mngrCnt:0, koreanCnt:0, frgnrCnt:0, oldManCnt:0, youngManCnt:0})
    const [smStatData, setSmStatData] = useState<any>({wrkrCnt:0, mngrCnt:0, koreanCnt:0, frgnrCnt:0, oldManCnt:0, youngManCnt:0})
    const [wrkrDataList, setWrkrDataList] = useState<any>([]);
    const [mngrDataList, setMngrDataList] = useState<any>([]);
    const [koreanDataList, setKoreanDataList] = useState<any>([]);
    const [frgnrDataList, setFrgnrDataList] = useState<any>([]);
    const [oldManDataList, setOldManDataList] = useState<any>([]);
    const [youngManDataList, setYoungManDataList] = useState<any>([]);
    const [isInOutDtlModalOpen, setIsInOutDtlModalOpen] = useState<boolean>(false);
    const [dtlModalDataList, setDtlModalDataList] = useState<any>([]);
    const [dtlModalTitle, setDtlModalTitle] = useState<string>('');
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            }
        },
        rotation: -90,
        circumference: 180,
        cutout: '70%',
    };
    const [gbCd, setGbCd] = useState<string>("A");

    const [wrkrMngrStatDataList, setWrkrMngrStatDataList] = useState<any>([]);
    const [korFrgnrStatDataList, setKorFrgnrStatDataList] = useState<any>([]);
    const [eldEtcStatDataList, setEldEtcStatDataList] = useState<any>([]);

    useEffect(() => {
        fn_retrieveData();
        timerRef.current = setInterval(fn_retrieveData, 5000);

        return () => {
            clearInterval(timerRef.current);
        };
    }, []);

    const fn_retrieveData = () => {
        restapi.retrieveTotEtrncStatInfo({qrNfcTpCd: 'T003001'}).then((rsltData: any) => {
            const wrkrTotDataList = rsltData.wrkrTotDataList;
            const mngrTotDataList = rsltData.mngrTotDataList;
            const wrkrVulnarableDataList = rsltData.wrkrVulnarableDataList;
            const mngrVulnarableDataList = rsltData.mngrVulnarableDataList;
            const mngrKoreanDataList = rsltData.mngrKoreanDataList;
            const wrkrKoreanDataList = rsltData.wrkrKoreanDataList;
            const oldManCnt = wrkrVulnarableDataList.length + mngrVulnarableDataList.length;
            const youngManCnt = wrkrTotDataList.length + mngrTotDataList.length - oldManCnt;
            const koreanCnt = mngrKoreanDataList.length + wrkrKoreanDataList.length;
            const frgnrCnt = wrkrTotDataList.length + mngrTotDataList.length - koreanCnt;
            const totCnt = wrkrTotDataList.length + mngrTotDataList.length
           // setWrkrDataList(wrkrTotDataList);
           // setMngrDataList(mngrTotDataList);
            const koreanDataList = mngrKoreanDataList.concat(wrkrKoreanDataList)
           // setKoreanDataList(koreanDataList);
            let koreanUserSeqList: any = [];
            koreanDataList.map((data: any) => koreanUserSeqList.push(data.userSeq));

            let frgnrDataList = wrkrTotDataList.concat(mngrTotDataList);
            frgnrDataList = frgnrDataList.filter((data: any) => !koreanUserSeqList.includes(data.userSeq))
           // setFrgnrDataList(frgnrDataList);
            const oldManDataList = wrkrVulnarableDataList.concat(mngrVulnarableDataList);
           // setOldManDataList(oldManDataList);
            const totDataList = wrkrTotDataList.concat(mngrTotDataList);
            let oldUserSeqList: any = [];
            oldManDataList.map((data: any) => oldUserSeqList.push(data.userSeq));
            const youngDataList = totDataList.filter((data: any) => !oldUserSeqList.includes(data.userSeq));
           // setYoungManDataList(youngDataList);

            setStatData({wrkrCnt:wrkrTotDataList.length, mngrCnt:mngrTotDataList.length, koreanCnt:koreanCnt, frgnrCnt:frgnrCnt, oldManCnt:oldManCnt, youngManCnt:youngManCnt})
            setWorkerData({
                labels: ['근로자', '관리자'],
                datasets: [
                    {
                        data: [wrkrTotDataList.length, mngrTotDataList.length],
                        backgroundColor: [
                            '#9CD5FF',
                            '#5685F7',
                        ],
                        borderColor: [
                            'rgba(30, 31, 35, 1)',
                            'rgba(30, 31, 35, 1)',
                        ],
                        borderWidth: 1,
                        hoverOffset: 5,
                    },
                ],
            })

            console.log('totCnt: ', totCnt);
            setForeignerData({
                labels: ['내국인', '외국인'],
                datasets: [
                    {
                        data: [koreanCnt, frgnrCnt],
                        backgroundColor: [
                            '#F0E5FC',
                            '#C893FD',
                        ],
                        borderColor: [
                            'rgba(30, 31, 35, 1)',
                            'rgba(30, 31, 35, 1)',
                        ],
                        borderWidth: 1,
                        hoverOffset: 5,
                    },
                ],
            });


            setElderlyData({
                labels: ['고령자', '그 외'],
                datasets: [
                    {
                        data: [oldManCnt, youngManCnt],
                        backgroundColor: [
                            '#B7EDED',
                            '#35D0B7',
                        ],
                        borderColor: [
                            'rgba(30, 31, 35, 1)',
                            'rgba(30, 31, 35, 1)',
                        ],
                        borderWidth: 1,
                        hoverOffset: 5,
                    },
                ],
            })



            setTotCnt(totCnt);

        })

        restapi.retrieveInOutStatSmSiteStatData({}).then((rsltData) => {
            const wrkrStatData = rsltData.wrkrStatData;
            const mngrStatData = rsltData.mngrStatData;
            const koreanStatData = rsltData.koreanStatData;
            const foreignerStatData = rsltData.foreignerStatData;
            const vulnarableStatData = rsltData.vulnarableStatData;
            const youngStatData = rsltData.youngStatData;
            const totCnt = wrkrStatData.cnt + mngrStatData.cnt;

            setSmStatData({wrkrCnt:wrkrStatData.cnt, mngrCnt:mngrStatData.cnt, koreanCnt:koreanStatData.cnt, frgnrCnt:foreignerStatData.cnt, oldManCnt:vulnarableStatData.cnt, youngManCnt:youngStatData.cnt})
            setSmWorkerData({
                labels: ['근로자', '관리자'],
                datasets: [
                    {
                        data: [wrkrStatData.cnt, mngrStatData.cnt],
                        backgroundColor: [
                            '#9CD5FF',
                            '#5685F7',
                        ],
                        borderColor: [
                            'rgba(30, 31, 35, 1)',
                            'rgba(30, 31, 35, 1)',
                        ],
                        borderWidth: 1,
                        hoverOffset: 5,
                    },
                ],
            })

            console.log('totCnt: ', totCnt);
            setSmForeignerData({
                labels: ['내국인', '외국인'],
                datasets: [
                    {
                        data: [koreanStatData.cnt, foreignerStatData.cnt],
                        backgroundColor: [
                            '#F0E5FC',
                            '#C893FD',
                        ],
                        borderColor: [
                            'rgba(30, 31, 35, 1)',
                            'rgba(30, 31, 35, 1)',
                        ],
                        borderWidth: 1,
                        hoverOffset: 5,
                    },
                ],
            });


            setSmElderlyData({
                labels: ['고령자', '그 외'],
                datasets: [
                    {
                        data: [vulnarableStatData.cnt, youngStatData.cnt],
                        backgroundColor: [
                            '#B7EDED',
                            '#35D0B7',
                        ],
                        borderColor: [
                            'rgba(30, 31, 35, 1)',
                            'rgba(30, 31, 35, 1)',
                        ],
                        borderWidth: 1,
                        hoverOffset: 5,
                    },
                ],
            })



            setSmTotCnt(totCnt);
        });


        restapi.retrieveInOutStatPerSiteData({}).then((rsltData) => {
            console.log('sitePer wrkr: ', rsltData);
            const wrkrStatList = rsltData.wrkrStatList;
            const mngrStatList = rsltData.mngrStatList;
            const koreanStatList = rsltData.koreanStatList;
            const foreignerStatList = rsltData.foreignerStatList;
            const vulnarableStatList = rsltData.vulnarableStatList;
            const youngStatList = rsltData.youngStatList;
            let dataList: any;

            dataList = fn_mergeStatData(wrkrStatList, mngrStatList);
            setWrkrMngrStatDataList(dataList);
            dataList = fn_mergeStatData(koreanStatList, foreignerStatList);
            setKorFrgnrStatDataList(dataList);
            dataList = fn_mergeStatData(vulnarableStatList, youngStatList);
            setEldEtcStatDataList(dataList);



            setWrkrDataList(wrkrStatList);
            setMngrDataList(mngrStatList);
            setKoreanDataList(koreanStatList);
            setFrgnrDataList(foreignerStatList);
            setOldManDataList(vulnarableStatList);
            setYoungManDataList(youngStatList);
        })
    }

    const fn_mergeStatData = (firstData: any, secondData: any) => {
        let siteIdArray: any = [];
        let dataList: any = [];
        let siteId: any;
        let data1;
        let data2;
        let data: any = {};
        firstData.map((data: any) => {
            siteIdArray.push(data.siteId);
        });

        secondData.map((data: any) => {
            if(!siteIdArray.includes(data.siteId)){
                siteIdArray.push(data.siteId);
            }
        })
        for(var i = 0; i < siteIdArray.length; i++){
            data = {cnt1: 0, cnt2: 0};
            siteId = siteIdArray[i];
            data1 = firstData.filter((data: any) => (siteId == data.siteId));
            data2 = secondData.filter((data: any) => (siteId == data.siteId));
            if(data1.length > 0){
                data.siteId = data1[0].siteId;
                data.siteName = data1[0].siteName;
                data.cnt1 = data1[0].cnt;
            }

            if(data2.length > 0){
                data.siteId = data2[0].siteId;
                data.siteName = data2[0].siteName;
                data.cnt2 = data2[0].cnt;
            }


            dataList.push(data);
        }

        return dataList;
    }




    const fn_openInOutDtlModal = (gb: string) => {
        if(gb == 'wrkr' || gb == 'mngr'){
            setDtlModalDataList(wrkrMngrStatDataList);
            setDtlModalTitle('근로자/관리자 출역 목록');
            setGbCd("A");
        }else if(gb == 'korean' || gb == 'frgnr'){
            setDtlModalDataList(korFrgnrStatDataList);
            setDtlModalTitle('내/외국인 출역 목록');
            setGbCd("B");
        }else if(gb == 'old' || gb == 'young'){
            setDtlModalDataList(eldEtcStatDataList);
            setDtlModalTitle('고령자/그외 출역 목록');
            setGbCd("C");
        }

        setIsInOutDtlModalOpen(true);
    }


    return(
        <>
            <InOutStatPerSiteInfoListModal gbCd={gbCd} setModalIsOpen={setIsInOutDtlModalOpen} modalIsOpen={isInOutDtlModalOpen} wrkrDataList={dtlModalDataList} title={dtlModalTitle} />
            <Container fluid className="sh-bg-pane rounded-[5px] py-2 px-3 mb-3">
                <Row className="h-100">
                    <Col>
                        <div className="d-flex flex-column justify-content-between h-100">
                            <div>
                                <div className="pe-2 sh-fs-h4 sh-fw-5 sh-font-SD mb-2">건설 현장 출역현황 [총 {totCnt}명]</div>
                                <Row>
                                    <Col className="pe-0">
                                        <div className="position-relative">
                                            <div className="text-center">근로자</div>
                                            <Doughnut className="position-relative z-50" style={{marginTop: '-20px'}}
                                                      options={options}
                                                      data={workerData} width="100%" height="100%"/>
                                            <div className="position-absolute top-z-index" style={{
                                                left: "70px",
                                                top: "90px",
                                                transform: 'translate(-50%, -50%)'
                                            }}>
                                                <div>
                                                    <span
                                                        className="sh-fw-5 sh-fs-b2 cursor-pointer"
                                                        onClick={() => fn_openInOutDtlModal('wrkr')}>{statData.wrkrCnt}</span>
                                                    <span
                                                        className="sh-fw-5 sh-fs-b2">/</span>
                                                    <span
                                                        className="sh-fw-5 sh-fs-b2 cursor-pointer"
                                                        onClick={() => fn_openInOutDtlModal('mngr')}>{statData.mngrCnt}</span>
                                                </div>
                                            </div>
                                            <div
                                                className="position-absolute top-z-index d-flex align-items-center justify-content-center sh-fs-b4 w-100"
                                                style={{marginTop: '-20px'}}>
                                                <div className={'cursor-pointer'}>
                                                    <span style={{color: '#9CD5FF'}}>●</span>
                                                    <span className="ps-1 cursor-pointer"
                                                          onClick={() => fn_openInOutDtlModal('wrkr')}>근로자</span>
                                                    <span className="ps-2" style={{color: '#5685F7'}}>●</span>
                                                    <span className="ps-1 cursor-pointer" onClick={() => fn_openInOutDtlModal('mngr')}>관리자</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="pe-0">
                                        <div className="position-relative">
                                            <div className="text-center">외국인</div>
                                            <Doughnut className="position-relative z-50" style={{marginTop: '-20px'}}
                                                      options={options}
                                                      data={foreignerData} width="100%" height="100%"/>
                                            <div className="position-absolute top-z-index" style={{
                                                left: "70px",
                                                top: "90px",
                                                transform: 'translate(-50%, -50%)'
                                            }}>
                                                <div>
                                                    <span
                                                        className="sh-fw-5 sh-fs-b2 cursor-pointer"
                                                        onClick={() => fn_openInOutDtlModal('korean')}>{statData.koreanCnt}</span>
                                                    <span
                                                        className="sh-fw-5 sh-fs-b2">/</span>
                                                    <span
                                                        className="sh-fw-5 sh-fs-b2 cursor-pointer"
                                                        onClick={() => fn_openInOutDtlModal('frgnr')}>{statData.frgnrCnt}</span>
                                                </div>
                                            </div>

                                            <div
                                                className="position-absolute top-z-index d-flex align-items-center justify-content-center sh-fs-b4 w-100"
                                                style={{marginTop: '-20px'}}>
                                                <div className={'cursor-pointer'}>
                                                    <span style={{color: '#F0E5FC'}}>●</span>
                                                    <span className="ps-1" onClick={() => fn_openInOutDtlModal('korean')}>내국인</span>
                                                    <span className="ps-2" style={{color: '#C893FD'}}>●</span>
                                                    <span className="ps-1" onClick={() => fn_openInOutDtlModal('frgnr')}>외국인</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="position-relative">
                                            <div className="text-center">고령자</div>
                                            <Doughnut className="position-relative z-50" style={{marginTop: '-20px'}}
                                                      options={options}
                                                      data={elderlyData} width="100%" height="100%"/>
                                            <div className="position-absolute top-z-index" style={{
                                                left: "70px",
                                                top: "90px",
                                                transform: 'translate(-50%, -50%)'
                                            }}>
                                                <div>
                                                    <span
                                                        className="sh-fw-5 sh-fs-b2 cursor-pointer"
                                                        onClick={() => fn_openInOutDtlModal('old')}>{statData.oldManCnt}</span>
                                                    <span
                                                        className="sh-fw-5 sh-fs-b2">/</span>
                                                    <span
                                                        className="sh-fw-5 sh-fs-b2 cursor-pointer"
                                                        onClick={() => fn_openInOutDtlModal('young')}>{statData.youngManCnt}</span>
                                                </div>
                                            </div>
                                            <div
                                                className="position-absolute top-z-index d-flex align-items-center justify-content-center sh-fs-b4 w-100"
                                                style={{marginTop: '-20px'}}>
                                                <div className={'cursor-pointer'}>
                                                    <span style={{color: '#B7EDED'}}>●</span>
                                                    <span className="ps-1" onClick={() => fn_openInOutDtlModal('old')}>고령자</span>
                                                    <span className="ps-2" style={{color: '#35D0B7'}}>●</span>
                                                    <span className="ps-1" onClick={() => fn_openInOutDtlModal('young')}>그 외</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container fluid className="sh-bg-pane rounded-[5px] py-2 px-3 mb-3">
                <Row className="h-100">
                    <Col>
                        <div className="d-flex flex-column justify-content-between h-100">
                            <div>
                                <div className="pe-2 sh-fs-h4 sh-fw-5 sh-font-SD mb-2">단가보수 현장 출역현황 [총 {smTotCnt}명]</div>
                                <Row>
                                    <Col className="pe-0">
                                        <div className="position-relative">
                                            <div className="text-center">근로자</div>
                                            <Doughnut className="position-relative z-50" style={{marginTop: '-20px'}}
                                                      options={options}
                                                      data={smWorkerData} width="100%" height="100%"/>
                                            <div className="position-absolute top-z-index" style={{
                                                left: "70px",
                                                top: "90px",
                                                transform: 'translate(-50%, -50%)'
                                            }}>
                                                <div>
                                                    <span
                                                        className="sh-fw-5 sh-fs-b2 "
                                                        >{smStatData.wrkrCnt}</span>
                                                    <span
                                                        className="sh-fw-5 sh-fs-b2">/</span>
                                                    <span
                                                        className="sh-fw-5 sh-fs-b2 "
                                                        >{smStatData.mngrCnt}</span>
                                                </div>
                                            </div>
                                            <div
                                                className="position-absolute top-z-index d-flex align-items-center justify-content-center sh-fs-b4 w-100"
                                                style={{marginTop: '-20px'}}>
                                                <div className={''}>
                                                    <span style={{color: '#9CD5FF'}}>●</span>
                                                    <span className="ps-1 "
                                                          >근로자</span>
                                                    <span className="ps-2" style={{color: '#5685F7'}}>●</span>
                                                    <span className="ps-1 " >관리자</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="pe-0">
                                        <div className="position-relative">
                                            <div className="text-center">외국인</div>
                                            <Doughnut className="position-relative z-50" style={{marginTop: '-20px'}}
                                                      options={options}
                                                      data={smForeignerData} width="100%" height="100%"/>
                                            <div className="position-absolute top-z-index" style={{
                                                left: "70px",
                                                top: "90px",
                                                transform: 'translate(-50%, -50%)'
                                            }}>
                                                <div>
                                                    <span
                                                        className="sh-fw-5 sh-fs-b2 "
                                                        >{smStatData.koreanCnt}</span>
                                                    <span
                                                        className="sh-fw-5 sh-fs-b2">/</span>
                                                    <span
                                                        className="sh-fw-5 sh-fs-b2 "
                                                        >{smStatData.frgnrCnt}</span>
                                                </div>
                                            </div>

                                            <div
                                                className="position-absolute top-z-index d-flex align-items-center justify-content-center sh-fs-b4 w-100"
                                                style={{marginTop: '-20px'}}>
                                                <div className={''}>
                                                    <span style={{color: '#F0E5FC'}}>●</span>
                                                    <span className="ps-1" >내국인</span>
                                                    <span className="ps-2" style={{color: '#C893FD'}}>●</span>
                                                    <span className="ps-1" >외국인</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="position-relative">
                                            <div className="text-center">고령자</div>
                                            <Doughnut className="position-relative z-50" style={{marginTop: '-20px'}}
                                                      options={options}
                                                      data={smElderlyData} width="100%" height="100%"/>
                                            <div className="position-absolute top-z-index" style={{
                                                left: "70px",
                                                top: "90px",
                                                transform: 'translate(-50%, -50%)'
                                            }}>
                                                <div>
                                                    <span
                                                        className="sh-fw-5 sh-fs-b2 "
                                                        >{smStatData.oldManCnt}</span>
                                                    <span
                                                        className="sh-fw-5 sh-fs-b2">/</span>
                                                    <span
                                                        className="sh-fw-5 sh-fs-b2 "
                                                        >{smStatData.youngManCnt}</span>
                                                </div>
                                            </div>
                                            <div
                                                className="position-absolute top-z-index d-flex align-items-center justify-content-center sh-fs-b4 w-100"
                                                style={{marginTop: '-20px'}}>
                                                <div className={''}>
                                                    <span style={{color: '#B7EDED'}}>●</span>
                                                    <span className="ps-1" >고령자</span>
                                                    <span className="ps-2" style={{color: '#35D0B7'}}>●</span>
                                                    <span className="ps-1" >그 외</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default HOCRInOut;
