import { useEffect, useState } from "react";

interface IProps {
    children: any;
    visibility: boolean;
    className?: string;
}

const SHDropdown = (props: IProps) => {
    const [visibilityAnimation, setVisibilityAnimation] = useState(false);
    const [repeat, setRepeat] = useState<any>(null);

    useEffect(() => {
        if (props.visibility) {
            clearTimeout(repeat);
            setRepeat(null);
            setVisibilityAnimation(true);
        } else {
            setRepeat(setTimeout(() => {
                setVisibilityAnimation(false);
            }, 400));
        }
    }, [props.visibility]);

    return (
        <article className={`${props.className && props.className} z-[1000] absolute components-dropdown`}>
            {props.visibility && props.children}
        </article>
    )
};

export default SHDropdown;
