import React from 'react';
import SubMenuTitle from "../component/SubMenuTitle";
import {Button, Container} from "react-bootstrap";
import {getLoginUser} from "../../helper/SecurityHelper";

type Props = {};

const SafetyLawPage = (props: Props) => {
    const user = getLoginUser();

    return (
        <Container fluid className="bg-white px-4 pt-4 sh-text-font_primary">
            <SubMenuTitle title={'안전법률'} parentMenu={user.authGrpId == 'HEAD' ? '안전 정보 및 공지 관리' : '안전 정보 및 공지 관리'} />
            <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="text-center h2 mb-5">
                    <h1>관계법령 및 안전규정</h1>
                </div>

                <div className="safetyLawSub d-flex flex-row mb-3" style={{width:"1000px"}}>
                    <div className="d-flex align-items-center justify-content-center w-50">
                        <span className="safetyLawSubTitle align-middle h5">중대재해처벌법</span>
                    </div>
                    <div className="d-flex flex-column w-50 p-2">
                        <div className="safetyLawLink mb-2">
                            <Button variant="link" href="https://www.law.go.kr/법령/중대재해처벌등에관한법률" target="_blank">1. 중대재해 처벌 등에 관한 법률</Button>
                        </div>
                        <div className="safetyLawLink mb-2">
                            <Button variant="link" href="https://www.law.go.kr/법령/중대재해처벌등에관한법률시행령" target="_blank">2. 중대재해 처벌 등에 관한 법률 시행령</Button>
                        </div>
                    </div>
                </div>

                <div className="safetyLawSub d-flex flex-row mb-3" style={{width:"1000px"}}>
                    <div className="d-flex align-items-center justify-content-center w-50">
                        <span className="safetyLawSubTitle align-middle h5">산업안전보건법</span>
                    </div>
                    <div className="d-flex flex-column w-50 p-2">
                        <div className="safetyLawLink mb-2">
                            <Button variant="link" href="https://www.law.go.kr/법령/산업안전보건법" target="_blank">1.
                                산업안전보건법</Button>
                        </div>
                        <div className="safetyLawLink mb-2">
                            <Button variant="link" href="https://www.law.go.kr/법령/산업안전보건법시행령" target="_blank">2. 산업안전보건법
                                시행령</Button>
                        </div>
                        <div className="safetyLawLink mb-2">
                            <Button variant="link" href="https://www.law.go.kr/법령/산업안전보건법시행규칙" target="_blank">3.
                                산업안전보건법 시행규칙</Button>
                        </div>
                        <div className="safetyLawLink mb-2">
                            <Button variant="link" href="https://www.law.go.kr/법령/산업안전보건기준에관한규칙" target="_blank">4.
                                산업안전보건법기준에 관한 규칙</Button>
                        </div>
                    </div>
                </div>

                <div className="safetyLawSub d-flex flex-row mb-3" style={{width: "1000px"}}>
                    <div className="d-flex align-items-center justify-content-center w-50">
                        <span className="safetyLawSubTitle align-middle h5">건설산업기본법</span>
                    </div>
                    <div className="d-flex flex-column w-50 p-2">
                        <div className="safetyLawLink mb-2">
                            <Button variant="link" href="https://www.law.go.kr/법령/건설산업기본법" target="_blank">1.
                                건설산업기본법</Button>
                        </div>
                        <div className="safetyLawLink mb-2">
                            <Button variant="link" href="https://www.law.go.kr/법령/건설산업기본법시행령" target="_blank">2. 건설산업기본법
                                시행령</Button>
                        </div>
                        <div className="safetyLawLink mb-2">
                            <Button variant="link" href="https://www.law.go.kr/법령/건설산업기본법시행규칙" target="_blank">3.
                                건설산업기본법 시행 규칙</Button>
                        </div>
                    </div>
                </div>

                <div className="safetyLawSub d-flex flex-row mb-3" style={{width: "1000px"}}>
                    <div className="d-flex align-items-center justify-content-center w-50">
                        <span className="safetyLawSubTitle align-middle h5">건설기술 진흥법</span>
                    </div>
                    <div className="d-flex flex-column w-50 p-2">
                        <div className="safetyLawLink mb-2">
                            <Button variant="link" href="https://www.law.go.kr/법령/건설기술진흥법" target="_blank">1. 건설기술
                                진흥법</Button>
                        </div>
                        <div className="safetyLawLink mb-2">
                            <Button variant="link" href="https://www.law.go.kr/법령/건설기술진흥법시행령" target="_blank">2. 건설기술
                                진흥법 시행령</Button>
                        </div>
                        <div className="safetyLawLink mb-2">
                            <Button variant="link" href="https://www.law.go.kr/법령/건설기술진흥법시행규칙" target="_blank">3. 건설기술
                                진흥법 시행 규칙</Button>
                        </div>
                        <div className="safetyLawLink mb-2">
                            <Button variant="link" href="https://www.law.go.kr/admRulLsInfoP.do?admRulSeq=2100000216960"
                                    target="_blank">4.
                                안전관리 업무수행 지침</Button>
                        </div>
                    </div>
                </div>
                <div className="safetyLawSub d-flex flex-row mb-3" style={{width: "1000px"}}>
                    <div className="d-flex align-items-center justify-content-center w-50">
                        <span className="safetyLawSubTitle align-middle h5">건설기계관리법</span>
                    </div>
                    <div className="d-flex flex-column w-50 p-2">
                        <div className="safetyLawLink mb-2">
                            <Button variant="link" href="https://www.law.go.kr/%EB%B2%95%EB%A0%B9/%EA%B1%B4%EC%84%A4%EA%B8%B0%EA%B3%84%EA%B4%80%EB%A6%AC%EB%B2%95" target="_blank">건설기계관리법</Button>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default SafetyLawPage;
