import SubMenuTitle from "../component/SubMenuTitle";
import SafetyManagementBoardModal from "../safetyManagementBoard/modal/SafetyManagementBoardModal";
import {Button, Container, ListGroup, Overlay, Table} from "react-bootstrap";
import React, {useContext, useEffect, useRef, useState} from "react";
import SHSearch from "../component/SHSearch";
import SHDatePicker2 from "../../components/common/SHDatePicker2";
import SHIconButton from "../../components/common/SHIconButton";
import Datatables from "../component/Datatables";
import {DateHelper} from "../../helper/DateHelper";
import SHInput from "../../components/common/SHInput";
import SHSelect from "../../components/common/SHSelect";
import {TopMenuContext} from "../../context/TopMenu";
import SafetyNewspaperModal from "./modal/SafetyNewspaperModal";
import {getLoginUser} from "../../helper/SecurityHelper";


type Props = {};

const SafetyNewspaperPage = (props: Props) => {
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState(null);
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const prodRef = useRef<any>();
    const [startDate, setStartDate] = useState<any>(DateHelper.dateByMonth(-3));
    const [endDate, setEndDate] = useState<any>(DateHelper.dateByMonth(0));
    const [data, setData] = useState<any>();
    const srchTxtRef = useRef<any>();
    const [isCommentMode, setIsCommentMode] = useState<boolean>(false);
    const defaultUser: any = getLoginUser();
    const user = getLoginUser();

    useEffect(() => {

    }, [])

    useEffect(() => {
        datatable?.draw();
    }, [modalIsOpen])

    const fn_setSrchData = (e: any) => {
        setData((item: any) => {
            return {...item, [e.target.name]: e.target.value}
        });
    }

    const fn_registPopOpen = () => {
        setDetailData(null);
        setModalIsOpen(true);
        setIsCommentMode(false);
    }

    const fn_delete = () => {
        console.log("사용안함")
    }

    const fn_comment = () =>{
        alert("코멘트");
    }

    const fn_onIsAllProdChg = (e:any) => {
        if(e.target.checked){
            setStartDate(null);
            setEndDate(null);
        }else{
            setStartDate(DateHelper.dateByMonth(-3));
            setEndDate(DateHelper.dateByMonth(0));
        }
    }

    return (
        <Container fluid className="bg-white px-4 pt-4 sh-text-font_primary">

            <SubMenuTitle title={'아차사고 관리'} parentMenu={'안전 정보 및 공지 관리'}/>
            <SafetyNewspaperModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} detailData={detailData} isCommentMode={isCommentMode}/>

            <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                <Table className="sh-font-SP sh-fs-b2 my-3">
                    <tbody>
                    <tr className="border-top border-bottom align-middle">
                        <th className="sh-bg-th" style={{width: '15%'}}>상태</th>
                        <td style={{width: '35%'}}>
                            <SHSelect initOptionGb={'all'} className={"w-100"} name={'statusCd'} codeGrpId={'S005'}
                                      onChange={fn_setSrchData} value={data?.statusCd}></SHSelect>
                        </td>
                        <th className="sh-bg-th" style={{width: '15%'}}>현장</th>
                        <td>
                            <SHSelect initOptionGb={'all'} className={'form-control'} name={'siteId'} codeGb={'site'}
                                      value={data?.siteId} onChange={fn_setSrchData}></SHSelect>
                        </td>
                    </tr>
                    <tr className="border-top border-bottom align-middle">
                        <th className="sh-bg-th" style={{width: '15%'}}>기간</th>
                        <td style={{width: '35%'}}>
                            <div className="d-flex flex-row gap-2">
                                <div className="d-flex align-items-center" ref={prodRef}>
                                    <SHDatePicker2 setter={setStartDate} name={'startRgstDt'} defaultValue={startDate}
                                                   onChange={fn_setSrchData}/>
                                    <span>&nbsp;~&nbsp;</span>
                                    <SHDatePicker2 setter={setEndDate} name={'endRgstDt'} defaultValue={endDate} onChange={fn_setSrchData}/>
                                </div>
                                <div className="d-flex align-items-center gap-1" ref={prodRef}>
                                    <input className="form-check-input sh-check" id={'isAll'} name={'isAll'}
                                           type="checkbox" onChange={(e: any) => {fn_setSrchData(e); fn_onIsAllProdChg(e);}}/>
                                    <label className={'form-check-label text-black'} htmlFor={'isAll'}>전체기간</label>
                                </div>
                            </div>
                        </td>
                        <th className="sh-bg-th" style={{width: '15%'}}>검색어</th>
                        <td style={{width: '35%'}}>
                            <input type={'text'} name={'srchTxt'} className='form-control' ref={srchTxtRef}/>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </SHSearch>

            {/*<div className='w-100 d-flex justify-content-end align-items-center mb-2'>
                {user.authCode != 'headRead' &&
                    <SHIconButton kind={'submit'} marginRight={'5px'} onClick={fn_registPopOpen}>등록</SHIconButton>
                }
                <SHIconButton kind={'delete'} marginRight={'0'} onClick={fn_delete}>삭제</SHIconButton>
            </div>*/}

            <div className={'ge-result'}>
                <Datatables
                    dataUrl={'/admin/api/sftyNwsppr/retrieveSftyNwspprDataList'}
                    parameterFormRef={searchFormRef}
                    columns={[
                        {data: "statusNm", title: "상태", textAlign: 'center'},
                        {data: "contents", title: "내용", textAlign: 'center'},
                        {data: "rgstDt", title: "작성일", textAlign: 'center'},
                        {data: "cnfrmNm", title: "확인자", textAlign: 'center'},
                    ]}
                    fixedTop={"isFixedTop"}
                    checked={false}
                    index={true}
                    onLoad={(dt: any) => {
                        setDatatable(dt)
                    }}
                    onClick={(data,gb) => {
                        if(gb == 'btn') {
                            setIsCommentMode(true);
                        }else {
                            setIsCommentMode(false);
                        }
                        setDetailData(data);
                        setModalIsOpen(true);
                    }}
                />
            </div>
        </Container>
    )
}

export default SafetyNewspaperPage;
