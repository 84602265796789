import {IoMdClose} from "react-icons/io";
import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {CmmnFn, fn_getCurrentDate} from "../../../helper/CmmnHelper";
import {getLoginUser, IS_SM_SITE} from "../../../helper/SecurityHelper";
import {Col, Row, Table, Modal} from "react-bootstrap";
import {COMMON_MODAL_STYLE, COMMON_MODAL_STYLE_SCROLL, COMMON_MULTISEL_STRINGS} from "../../../helper/StyleHelper";
import SHInput from "../../../components/common/SHInput";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import SHSelect from "../../../components/common/SHSelect";

import {MultiSelect} from "react-multi-select-component";
import restapi from "../../../helper/restAPIHelper";

import WrkrSrchModal from "./WrkrSrchModal";
import SHIconButton from "../../../components/common/SHIconButton";
import IconRemove from '../../../images/icon_remove.png';
import LoadRiskEvalModal from "./LoadRiskEvalModal";
import ApprLineModal from "./ApprLineModal";
import ApprCardList from "../../../components/appr/ApprCardList";
import SHFile from "../../../components/common/SHFile";
import {NUMBER, validFormData, toJsonFormData, toValidJsonFormData, disableForm} from "../../../helper/FormHelper";
import {ProgressContext} from "../../../context/Progress";
import RskAsmntRgstModal from "./RskAsmntRgstModal";
import {useReactToPrint} from "react-to-print";
import SiteMngrSrchModal from "./SiteMngrSrchModal";
import SHTextarea from "../../../components/common/SHTextarea";
import SHDatePicker2 from "../../../components/common/SHDatePicker2";
import {DateHelper} from "../../../helper/DateHelper";
import Select from "react-select";


const $ = require("jquery")

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
    siteId: number;
    isReadOnly?: boolean;
    isCopy?: boolean;
}

const WrkPrmtRgstModal = (props: IProps) => {

    const user = getLoginUser();

    const prodRef = useRef<any>();

    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);
    const [data, setData] = useState<any>()
    const [selChrgrs, setSelChrgrs] = useState<any>([])
    const [eqmntDataList, setEqmntDataList] = useState<any>([]);
    const [selEqmnts, setSelEqmnts] = useState<any>([]);
    const [eqmntOpts, setEqmntOpts] = useState<any>([]);
    const [todayWrkInfoList, setTodayWrkInfoList] = useState<any[]>([]);
    const [wrkrDataList, setWrkrDataList] = useState<any>([]);
    const [wrkDt, setWrkDt] = useState<Date | null>();

    const [todayWrkIdx, setTodayWrkIdx] = useState<number>(0);
    const [finalWrkrDataList, setFinalWrkrDataList] = useState<any[]>([]);
    const [isWrkCntntsHide, setIsWrkCntntsHide] = useState<boolean>(false);
    const [wrkHrList, setWrkHrList] = useState<any>([]);
    const [wrkMiList, setWrkMiList] = useState<any>([]);

    const [isRiskEvalModalOpen, setIsRiskEvalModalOpen] = useState<boolean>(false);
    const [riskEvalList, setRiskEvalList] = useState<any>([]);
    const [riskEvlWrkActDataList, setRiskEvlWrkActDataList] = useState<any>([]);
    const [riskAsmntIdList, setRiskAsmntIdList] = useState<any>([]);
    const [isWrkrModalOpen, setIsWrkrModalOpen] = useState<boolean>(false);

    const [apprLineDataList, setApprLineDataList] = useState<any>([]);
    const [selected, setSelected] = useState<any>([])
    const [refUserList, setRefUserList] = useState<any>([]);
    const [mngrUserDataList, setMngrUserDataList] = useState<any>([]);
    const [allUserDataList, setAllUserDataList] = useState<any>([]);
    const [isApprLineModalOpen, setIsApprLineModalOpen] = useState(false);
    const [isFilUpload, setIsFileUpload] = useState<any>(null);
    const [localWrkFormFileSrc, setLocalWrkFormFileSrc] = useState<any>(null);
    const [atchFileList, setAtchFileList] = useState([]);
    const formRef = React.createRef<HTMLFormElement>();
    const {spinner} = useContext(ProgressContext);
    const [isRskAsmntModalOpen, setIsRskAsmntModalOpen] = useState<boolean>(false);
    const [rskAsmntDtlData, setRskAsmntDtlData] = useState<any>({});
    const componentRef = useRef();
    const [isMngrSrchModalOpen, setIsMngrSrchModalOpen] = useState<boolean>(false);
    const isSmSite = CmmnFn.isSmSite()
    const [wrkPrmtRsltList, setWrkPrmtRsltList] = useState<any>([]);

    useEffect(() => {
        const optsArray: number[] = []
        for (let i = 0; i < 50; i++) {
            optsArray.push(i + 1);
        }
        setEqmntOpts(optsArray);

    }, [])

    useEffect(() => {
        console.log('data: ', data);
    }, [data])

    useEffect(() => {

        let apprLinUserSeqList: number[] = [];
        apprLineDataList.map((data: any) => {
            apprLinUserSeqList.push(data.userSeq);
        });

        let cstmAllUserDataList = allUserDataList.filter((data: any) => (!apprLinUserSeqList.includes(data.userSeq)));
        let cstmData: object[] = [];

        cstmAllUserDataList = cstmAllUserDataList.filter((data: any) => (data.userSeq != props.detailData?.rgstrSeq))
        cstmAllUserDataList.map((data: any) => {
            cstmData.push({
                label: '[' + data.companyName + '] ' + data.userName + ' (' + data.chrgNm + ')',
                value: data.userSeq
            });
        })

        // @ts-ignore
        setRefUserList([].concat(cstmData));

    }, [apprLineDataList]);

    useEffect(() => {
        if (riskEvalList.length > 0) {
            let riskAsmntIdList: number[] = [];
            let riskEvlData: any = [];

            riskEvalList.map((data: any) => {
                riskAsmntIdList.push(data.riskAsmntId);
                if (data.riskAsmntFileId) {
                    riskEvlData.push({workTypeNm: data.workTypeNm, wrkLvlNm: data.wrkLvl, wrkTpNm: data.wrkTp})
                }
            })

            setRiskAsmntIdList(riskAsmntIdList);
            restapi.retrieveRiskEvlWrkActDataList({
                riskAsmntIdList: riskAsmntIdList,
                siteId: data.siteId
            }).then((rsltData) => {
                setRiskEvlWrkActDataList(riskEvlData.concat(rsltData));
            })
        }
    }, [riskEvalList])

    useEffect(() => {
        let selSeq: number[] = [];
        selected.map((data: any) => {
            selSeq.push(data.value);
        })

        let cstmAllUserDataList: any = allUserDataList.filter((data: any) => {
            return !selSeq.includes(data.userSeq);
        })

        setMngrUserDataList([].concat(cstmAllUserDataList));
        let showText = ''

        if (selected.length > 0 && selected.length == refUserList.length) {
            selected.map((data: any, idx: number) => {
                showText += data.label
                if (idx < selected.length - 1) {
                    showText += ', '
                }

            })

            $('#rgstForm').find('#refTd').find('.dropdown-heading-value').find('span').html(showText);
        }


    }, [selected, refUserList])

    useEffect(() => {

        console.log('selChrgrs: ', selChrgrs);
        let wrkChrgrNm = '';
        if (selChrgrs.length > 0) {
            selChrgrs.map((data: any, idx: number) => {
                if (idx > 0) {
                    wrkChrgrNm += ', ';
                }
                wrkChrgrNm += data.userName;
            })
        }
        setData({
            ...data,
            'wrkChrgrNm': wrkChrgrNm
        })

    }, [selChrgrs])


    const fn_genWrkTime = () => {

        let date = new Date(2023, 0, 1);
        let hrArr = ['00']
        let minArr = ['00']
        let hr;
        let mi;

        for (let i = 0; i < 23; i++) {
            date.setHours(date.getHours() + 1);
            hr = date.getHours() + '';
            if (hr.length == 1) {
                hr = '0' + hr;
            }
            hrArr.push(hr);
        }
        for (let i = 0; i < 5; i++) {
            date.setMinutes(date.getMinutes() + 10);
            mi = date.getMinutes() + '';
            if (mi.length == 1) {
                mi = '0' + mi;
            }
            minArr.push(mi);
        }


        setWrkHrList(hrArr);
        setWrkMiList(minArr)


    }


    useEffect(() => {
    }, [wrkrDataList]);


    useEffect(() => {

        // 상세 조회
        if (props.detailData) {
            restapi.retrieveWrkPrmtDtlData(props.detailData.wrkPrmtId, props.detailData.siteId).then((rsltData) => {
                const dtlEqmntDataList = rsltData.eqmntDataList;
                const dtlWrkrStatDataList = rsltData.wrkrStatDataList;
                const riskAsmntIdList = rsltData.riskAsmntIdList;
                const todayWrkInfoList = rsltData.todayWrkInfoList;
                const wrkPrmtRsltList = rsltData.wrkPrmtRsltList;

                let cstmEqmntList: any = [];
                let cstmFinalWrkrDataList: any = [];
                setRiskAsmntIdList(riskAsmntIdList);
                setRiskEvlWrkActDataList(rsltData.riskEvlWrkActDataList);
                setTodayWrkInfoList(todayWrkInfoList);

                dtlEqmntDataList.map((dtlEqmntData: any) => {
                    eqmntDataList.map((data: any) => {
                        if (data.value == dtlEqmntData.eqmntCd) {
                            data.eqmntCnt = dtlEqmntData.eqmntCnt;
                            cstmEqmntList.push(data);
                        }
                    })
                })

                setSelEqmnts(cstmEqmntList);
                setWrkrDataList(dtlWrkrStatDataList);
                setFinalWrkrDataList(cstmFinalWrkrDataList);
                if (wrkPrmtRsltList) {
                    setWrkPrmtRsltList(wrkPrmtRsltList);
                }

            })
        }
        if (props.isReadOnly || props.detailData?.prcsStatCd == 'S001004') {
            disableForm(formRef);
        }
    }, [eqmntDataList])


    const fn_retrieveEqmntDataList = () => {
        restapi.retrieveSiteManageItemList(props.siteId, 'M001005').then((rsltData) => {
            const rsltEqmntDataList: any = []
            rsltData.map((data: any) => {
                rsltEqmntDataList.push({label: data.manageName, value: data.manageSn})
            })
            console.log('rsltEqmntDataList: ', rsltEqmntDataList);
            setEqmntDataList([].concat(rsltEqmntDataList));
        })
    }


    const fn_setFormData = (e: any) => {


        CmmnFn.setFormData(e, data, setData);

    }

    const fn_onAfterOpen = () => {

        fn_retrieveEqmntDataList();
        fn_genWrkTime();
        setRiskEvalList([]);

        // 신규
        if (!props.detailData) {

            const siteId = props.siteId;


            setData({
                siteId: siteId,
                isWrkSfty1: true,
                isWrkSfty2: true,
                isWrkSfty3: true,
                isWrkSfty4: true,
                cmpnySeq: user.blngCmpnySeq,
            })
            setWrkDt(new Date());
            setSelChrgrs([]);
            setFinalWrkrDataList([]);
            setTodayWrkInfoList([]);
            setSelEqmnts([]);
            setRiskAsmntIdList([]);
            setRiskEvlWrkActDataList([]);
            setApprLineDataList([]);
            setSelected([]);
            setIsFileUpload(null);
            setLocalWrkFormFileSrc(null);
            setWrkrDataList([]);
            setAtchFileList([]);
            // 상세
        } else {
            setData(props.detailData);
            setIsFileUpload(props.detailData.isFileUpload);
            setWrkDt(DateHelper.parse(props.detailData.wrkDt));
            fn_retrieveApprUserDataList(props.detailData);
            const wrkCntntsFileId = props.detailData.wrkCntntsFileId;
            const atchFileId = props.detailData.atchFileId;
            if (wrkCntntsFileId) {
                setLocalWrkFormFileSrc(process.env.REACT_APP_FILE_URL + "/shss-data/fileDownload?attachSeq=" + wrkCntntsFileId + '&sn=0&isPdf=1');
            } else {
                setLocalWrkFormFileSrc(null);
            }

            restapi.retrieveWrkPrmtChrgrDataList({wrkPrmtId: props.detailData.wrkPrmtId}).then((rsltData: any) => {
                setSelChrgrs(rsltData);
            })

            if (atchFileId) {
                restapi.retrieveAttachInfoList({attachSeq: atchFileId}).then((rsltData: any) => {
                    setAtchFileList([].concat(rsltData))
                })
            } else {
                setAtchFileList([]);
            }

        }

    }

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const submit = (e: any) => {
        e.preventDefault()

    }

    const fn_datePopupOpen = () => {
        setIsDatePopupOpen(!isDatePopupOpen);
    };


    const fn_registData = () => {

        if (isFilUpload === null) {
            toast.warn('파일올리기 또는 직접 입력 버튼을 선택해 주십시오')
            return;
        }

        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if (!data.wrkTypeCd) {
            toast.warn('공종을 선택해 주십시오.');
            return;
        }

        if (!data.cmpnySeq) {
            toast.warn('작업업체명을 선택해 주십시오.');
            return;
        }

        if (!wrkDt) {
            toast.warn('작업일을 선택해 주십시오.')
            return;
        }

        if (!data.wrkTimeGbCd) {
            toast.warn('작업시간을 선택해 주십시오.');
            return;
        }

        if (CmmnFn.isSmSite()) {
            if (!data.wrkSiteCd) {
                toast.warn('작업 현장을 선택해 주십시오.');
                return;
            }
        }

        if (isFilUpload === false) {
            if (selChrgrs.length == 0) {
                toast.warn('작업책임자를 선택해 주십시오.');
                return;
            }

            if (todayWrkInfoList.length == 0) {
                toast.warn('금일 작업 사항을 입력해 주십시오.');
                return;
            }

            let isValid = true;
            todayWrkInfoList.map((data: any, idx: number) => {
                if (!data.ocptCd) {
                    isValid = false;
                    toast.warn((idx + 1) + ' 번째 금일작업 사항의 직종을 선택해 주십시오.')
                }

                if (!data.wrkCntnts) {
                    isValid = false;
                    toast.warn((idx + 1) + ' 번째 금일작업 사항의 내용을 입력해 주십시오.')
                }
            })

            if (!isValid) {
                return;
            }
        }

        if (!data.wrkrCnt) {
            toast.warn('작업인원을 입력해 주십시오.');
            return;
        }

        if (!CmmnFn.isSmSite() && riskEvlWrkActDataList.length == 0) {
            toast.warn('위험성평가를 선택해 주십시오.');
            return;
        }

        if (wrkrDataList.length > data.wrkrCnt) {
            toast.warn('작업 근로자는 작업인원 제한 수를 초과 할 수 없습니다.')
            return;
        }



        let parmChrgs: any = []
        selChrgrs.map((data: any) => {
            parmChrgs.push(data.userSeq);
        })

        selEqmnts.map((data: any) => {
            data.eqmntCd = data.value
        })


        var option = {
            addValues: {
                rgstrSeq: user.userSeq,
                updtrSeq: user.userSeq,
                cmpnySeq: data.cmpnySeq,
                wrkPrmtId: data.wrkPrmtId,
                wrkCntntsFileId: data.wrkCntntsFileId,
                atchFileId: data.atchFileId
            },
            files: ['wrkCntntsFile', 'atchFileList']
        }

        var param: any = {}
        var formData: any = validFormData(formRef, option, param)
        param["wrkDt"] = DateHelper.format(wrkDt)
        param["chrgDataList"] = parmChrgs
        param["wrkrStatDataList"] = wrkrDataList
        param["eqmntDataList"] = selEqmnts
        param["todayWrkInfoList"] = todayWrkInfoList
        param['isFileUpload'] = isFilUpload;

        if (riskAsmntIdList.length > 0) {
            param["riskAsmntIdList"] = riskAsmntIdList
        }

        if (!isFilUpload) {
            formData.delete('wrkCntntsFile');
        }

        if (apprLineDataList.length > 0) {
            let apprUserSeqList: number[] = [];

            apprLineDataList.map((data: any) => {
                apprUserSeqList.push(data.userSeq);
            })

            param["apprUserSeqList"] = apprUserSeqList
        }

        if (selected.length > 0) {
            let refUserSeqList: number[] = [];

            selected.map((data: any) => {
                refUserSeqList.push(data.value);
            })
            param["refUserSeqList"] = refUserSeqList
        }

        console.log('param: ', param);

        if (!window.confirm('저장 하시겠습니까?')) {
            return;
        }

        if(props.isCopy){
            param.wrkPrmtId = null;
        }
        formData.set('wrkPrmt', new Blob([JSON.stringify(param)], {type: "application/json"}));
        spinner.start();
        axios.post('/admin/api/wrkPrmt/saveData', formData).then((result) => {
                if (result.data > 0) {
                    toast.success(messageCodeDic['001'])
                    props.setModalIsOpen(false);
                } else {
                    toast.error(errorCodeDic['003']);
                }
            }
        ).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });


    }

    const fn_delRow = (idx: number) => {

        // @ts-ignore
        let cstmTodayWrkrInfoDataList: any = [].concat(todayWrkInfoList);
        // @ts-ignore
        let cstmFinalWrkrDataList: any = [].concat(finalWrkrDataList);

        cstmTodayWrkrInfoDataList.splice(idx, 1);
        cstmFinalWrkrDataList.splice(idx, 1);
        setTodayWrkInfoList(cstmTodayWrkrInfoDataList);
        setFinalWrkrDataList(cstmFinalWrkrDataList);
    }

    const fn_delWrkStatRow = (idx: number) => {
        wrkrDataList.splice(idx, 1);
        setWrkrDataList([].concat(wrkrDataList));

    }

    const fn_saveRow = (idx: number) => {
        setTodayWrkIdx(idx);

        const ocptCd = todayWrkInfoList[idx].ocptCd;
        const wrkCntnts = todayWrkInfoList[idx].wrkCntnts;

        if (!ocptCd) {
            toast.warn('직종을 선택해 주십시오');
            return;
        }


        if (finalWrkrDataList[idx].isCnfrmd === null) {
            toast.warn('작업자를 선택해 주십시오');
            return;
        }

        finalWrkrDataList[idx].map((wrkData: any) => {
            wrkData.isCnfrmd = true;
            wrkData.ocptCd = ocptCd;
            wrkData.wrkCntnts = wrkCntnts
        });
        // @ts-ignore
        setFinalWrkrDataList([].concat(finalWrkrDataList))
    }

    const fn_addTodayWrkRow = () => {
        setTodayWrkInfoList(todayWrkInfoList.concat({}))
    }

    const fn_wrkrModalOpen = () => {

        if (!data.cmpnySeq) {
            toast.warn('우선 작업 업체명을 선택해 주십시오.')
            return;
        }
        setIsWrkrModalOpen(true);
    }

    const fn_updateTodayWrkInfoData = (name: string, value:any, idx: number) => {
        todayWrkInfoList[idx][name] = value;


        // @ts-ignore
        setTodayWrkInfoList([].concat(todayWrkInfoList))


    }

    const fn_updateWrkStatInfoData = (e: any, parIdx: number, idx: number) => {
        finalWrkrDataList[parIdx][idx][e.target.name] = e.target.value;

        // @ts-ignore
        setFinalWrkrDataList([].concat(finalWrkrDataList));
    }

    const fn_toggleWrkCntnts = () => {
        setIsWrkCntntsHide(!isWrkCntntsHide);
    }

    const fn_onWrkTimeSelChg = (e: any) => {
        const timeGb = e.target.value;
        let wrkStrtHr;
        let wrkStrtMi;
        let wrkEndHr;
        let wrkEndMi;

        // 주간
        if (timeGb == 'T001001') {
            wrkStrtHr = '08';
            wrkStrtMi = '00';
            wrkEndHr = '17';
            wrkEndMi = '00';

            // 조출
        } else if (timeGb == 'T001002') {
            wrkStrtHr = '05';
            wrkStrtMi = '00';
            wrkEndHr = '08';
            wrkEndMi = '00';
            // 중식
        } else if (timeGb == 'T001003') {
            wrkStrtHr = '12';
            wrkStrtMi = '00';
            wrkEndHr = '13';
            wrkEndMi = '00';
            // 연장
        } else if (timeGb == 'T001004') {
            wrkStrtHr = '17';
            wrkStrtMi = '00';
            wrkEndHr = '22';
            wrkEndMi = '00';
            // 야간
        } else if (timeGb == 'T001005') {
            wrkStrtHr = '22';
            wrkStrtMi = '00';
            wrkEndHr = '00';
            wrkEndMi = '00';

        }

        setData({
            ...data,
            wrkStrtHr: wrkStrtHr,
            wrkStrtMi: wrkStrtMi,
            wrkEndHr: wrkEndHr,
            wrkEndMi: wrkEndMi,
            wrkTimeGbCd: timeGb
        })
    }

    const fn_updateSelEqmntsData = (e: any, selIdx: number) => {
        selEqmnts.map((data: any, idx: number) => {
            if (selIdx == idx) {
                data.eqmntCnt = e.target.value;
            }
        })
        setSelEqmnts([].concat(selEqmnts));
    }

    const fn_retrieveApprUserDataList = (detailData: any) => {

        restapi.retrieveWrkPrmtApprInfo({wrkPrmtId: detailData.wrkPrmtId}).then((apprData) => {
            const apprUserDataList = apprData.apprUserDataList;
            const refUserDataList = apprData.refUserDataList;
            let cstmData: object[] = [];

            restapi.retrieveUserDataList({
                cmpnySeq: detailData.cmpnySeq,
                isApprMngr: true,
                siteId: props.siteId
            }).then((rsltData) => {
                setAllUserDataList(rsltData);
                if (apprUserDataList.length == 0 && refUserDataList.length == 0) {
                    setMngrUserDataList(rsltData);
                    rsltData = rsltData.filter((data: any) => (data.userSeq != detailData.rgstrSeq))
                    rsltData.map((data: any) => {
                        cstmData.push({
                            label: '[' + data.companyName + '] ' + data.userName + ' (' + data.chrgNm + ')',
                            value: data.userSeq
                        });
                    })
                    setRefUserList(cstmData);
                    setApprLineDataList([]);
                    setSelected([]);
                } else {
                    let refUserSeqList: any = [];
                    let apprUserSeqList: any = [];
                    refUserDataList.map((data: any) => {
                        refUserSeqList.push(data.userSeq)
                    })
                    apprUserDataList.map((data: any) => {
                        apprUserSeqList.push(data.userSeq)
                    })

                    setMngrUserDataList(rsltData.filter((data: any) => {
                        return !refUserSeqList.includes(data.userSeq);
                    }));

                    let cstmApprLineDataList = rsltData.filter((data: any) => {
                        return apprUserSeqList.includes(data.userSeq);
                    })

                    cstmApprLineDataList.map((cstmData: any) => {
                        apprUserDataList.map((apprData: any) => {
                            if (cstmData.userSeq == apprData.userSeq) {
                                cstmData.apprStat = apprData.apprStat;
                                cstmData.apprStatNm = apprData.apprStatNm;
                                cstmData.prcsDt = apprData.prcsDt;
                                cstmData.apprSn = apprData.apprSn;
                            }
                        })
                    })

                    // 오름차순 정렬
                    cstmApprLineDataList = cstmApprLineDataList.sort((a: any, b: any) => {
                        if (a.apprSn > b.apprSn) return 1;
                        if (a.apprSn < b.apprSn) return -1;
                        return 0;
                    });

                    setApprLineDataList(cstmApprLineDataList)

                    let csmtSelectData = rsltData.filter((data: any) => {
                        return refUserSeqList.includes(data.userSeq);
                    })


                    csmtSelectData.map((data: any) => {
                        data.label = '[' + data.companyName + '] ' + data.userName + ' (' + data.chrgNm + ')';
                        data.value = data.userSeq;
                    })


                    rsltData = rsltData.filter((data: any) => {
                        return !apprUserSeqList.includes(data.userSeq);
                    })

                    rsltData.map((data: any) => {
                        cstmData.push({
                            label: '[' + data.companyName + '] ' + data.userName + ' (' + data.chrgNm + ')',
                            value: data.userSeq
                        });
                    })

                    setRefUserList(cstmData);
                    setSelected(csmtSelectData);

                }

            })

        })


    }

    const fn_rqstAppr = () => {
        if (apprLineDataList.length == 0) {
            toast.warn('결재선을 선택해 주십시오.');
            return;
        }

        let apprUserSeqList: number[] = [];
        apprLineDataList.map((data: any, idx: number) => {
            apprUserSeqList.push(data.userSeq);
        })

        let refUserSeqList: any = [];
        selected.map((data: any) => {
            refUserSeqList.push(data.userSeq || data.value)
        })

        if (!window.confirm('결재요청 하시겠습니까?')) {
            return;
        }

        let prcsStatCd = '';

        if (apprUserSeqList.length == 1) {
            prcsStatCd = 'S001004';
        } else {
            prcsStatCd = 'S001002';
        }

        axios.post('/admin/api/wrkPrmt/rqstAppr', {
            wrkPrmtId: props.detailData.wrkPrmtId,
            apprUserSeqList: apprUserSeqList,
            refUserSeqList: refUserSeqList,
            prcsStatCd: prcsStatCd
        }).then((rslt) => {
            if (rslt.data > 0) {
                toast.success(messageCodeDic['006']);
                props.setModalIsOpen(false);
            } else {
                toast.warn(errorCodeDic['003']);
            }
        })

    }

    const fn_appr = (gb: string) => {
        let url = '';
        let param: any = {wrkPrmtId: props.detailData.wrkPrmtId};
        let msg = ''

        if ('appr' == gb) {
            url = '/admin/api/wrkPrmt/approve';
            let apprUserSeqList = [];
            const myInfo = apprLineDataList.filter((data: any) => (data.userSeq == user.userSeq))[0];
            const nextInfo = apprLineDataList.filter((data: any) => (data.apprSn == (myInfo.apprSn + 1)));

            apprUserSeqList.push(myInfo.userSeq);
            if (nextInfo.length > 0) {
                apprUserSeqList.push(nextInfo[0].userSeq);
            }

            param.apprUserSeqList = apprUserSeqList;
            msg = '승인 하시겠습니까?'
        } else if ('rjct' == gb) {
            url = '/admin/api/wrkPrmt/reject';
            param.userSeq = user.userSeq;
            msg = '반려 하시겠습니까?'
        }

        if (!window.confirm(msg)) {
            return;
        }

        axios.post(url, param).then((rslt) => {
            if (rslt.data > 0) {
                toast.success(messageCodeDic['006']);
                props.setModalIsOpen(false);
            } else {
                toast.warn(errorCodeDic['003']);
            }
        })
    }

    const fn_cancelRqst = () => {

        let apprUserSeqList: any = [];

        apprLineDataList.map((data: any) => {
            apprUserSeqList.push(data.userSeq)
        })
        if (!window.confirm('회수 하시겠습니까?')) {
            return;
        }

        axios.post('/admin/api/wrkPrmt/cancelAppr', {
            wrkPrmtId: props.detailData.wrkPrmtId,
            apprUserSeqList: apprUserSeqList
        }).then((rslt) => {
            if (rslt.data > 0) {
                toast.success(messageCodeDic['006']);
                props.setModalIsOpen(false);
            } else {
                toast.warn(errorCodeDic['003']);
            }
        })
    }

    const fn_deleteWrkPrmtData = () => {

        if (!window.confirm('삭제 하시겠습니까?')) {
            return;
        }
        axios.post('/admin/api/wrkPrmt/deleteWrkPrmtData', {wrkPrmtId: props.detailData.wrkPrmtId}).then((rslt) => {
            if (rslt.data > 0) {
                toast.success(messageCodeDic['006']);
                props.setModalIsOpen(false);
            } else {
                toast.warn(errorCodeDic['003']);
            }
        })
    }

    const fn_addWrkCntntsFile = (e: any) => {
        if (e.target.files.length > 0) {
            let imgSrc = URL.createObjectURL(e.target.files[0]);

            if (e.target.files[0].type !== 'application/pdf') {
                toast.warn('pdf 형식의 파일만 업로드 가능합니다.');
                return
            }
            setLocalWrkFormFileSrc(imgSrc);
            setIsFileUpload(true)
        }
    }

    const fn_openRskAsmntModal = (param: any) => {
        const riskAsmntId = param.riskAsmntId;

        spinner.start();
        restapi.retrieveRskAsmntTotDataList({riskAsmntId: riskAsmntId}).then((rsltData: any) => {
            if (rsltData.length > 0) {
                setRskAsmntDtlData(rsltData[0]);
                setIsRskAsmntModalOpen(true);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });
    }
    const handlePrint = useReactToPrint({
        content: () => formRef.current,
        documentTitle: 'test',
    });

    const fn_openMngrSrchModal = () => {
        if (!data.cmpnySeq) {
            toast.warn('우선 작업 업체명을 선택해 주십시오.')
            return;
        }
        setIsMngrSrchModalOpen(true);
    }

    return (
        <div>
            <LoadRiskEvalModal setModalIsOpen={setIsRiskEvalModalOpen} modalIsOpen={isRiskEvalModalOpen}
                               riskEvalList={riskEvalList} wrkDt={DateHelper.format(wrkDt)}
                               setRiskEvalList={setRiskEvalList} siteId={data?.siteId} cmpnySeq={data?.cmpnySeq}/>
            <ApprLineModal setModalIsOpen={setIsApprLineModalOpen} modalIsOpen={isApprLineModalOpen}
                           mngrUserDataList={mngrUserDataList}
                           apprLineDataList={apprLineDataList} cmpnySeq={data?.cmpnySeq} rgstrSeq={data?.rgstrSeq}
                           setApprLineDataList={setApprLineDataList} siteId={props.siteId}/>

            <WrkrSrchModal setModalIsOpen={setIsWrkrModalOpen} modalIsOpen={isWrkrModalOpen} wrkrDataList={wrkrDataList}
                           setWrkrDataList={setWrkrDataList} siteId={props.siteId} cmpnySeq={data?.cmpnySeq}/>
            <SiteMngrSrchModal setModalIsOpen={setIsMngrSrchModalOpen} modalIsOpen={isMngrSrchModalOpen}
                               mngrDataList={selChrgrs} setMngrDataList={setSelChrgrs} siteId={props.siteId}
                               cmpnySeq={data?.cmpnySeq}></SiteMngrSrchModal>
            <RskAsmntRgstModal setModalIsOpen={setIsRskAsmntModalOpen} isSub={true} modalIsOpen={isRskAsmntModalOpen}
                               siteId={props.siteId}
                               detailData={rskAsmntDtlData}/>
            <Modal className="sh-modal" size="xl" show={props.modalIsOpen} onHide={fn_closeModal}
                   onShow={fn_onAfterOpen}>
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        {CmmnFn.isSmSite() ? '작업지시서 등록' : '작업허가서 등록'}
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">

                    <form name={'rgstForm'} id={'rgstForm'} encType="multipart/form-data" method={"post"}
                          onSubmit={submit} ref={formRef}>
                        <SHInput type={'hidden'} value={data?.siteId} name={'siteId'}
                                 onChange={fn_setFormData}></SHInput>

                        {(props.detailData && props.detailData.prcsStatCd) &&
                            <div className="sh-modal-div">
                                <div className="div-header sh-fw-5">
                                    <div className={'min-w-[100px] font-semibold'}>
                                        결재 정보
                                    </div>
                                    <div className='w-100 d-flex justify-content-end align-items-center mb-2'>

                                        {props.detailData.prcsStatCd == 'S001001' && props.detailData?.rgstrSeq == user.userSeq &&
                                            <>
                                                <SHIconButton kind={'normal'} marginRight={'5px'} onClick={() => {
                                                    setIsApprLineModalOpen(true)
                                                }}> 결재선
                                                </SHIconButton>
                                                <SHIconButton kind={'normal'} marginRight={'5px'}
                                                              onClick={fn_rqstAppr}> 결재요청
                                                </SHIconButton>
                                            </>
                                        }
                                        {
                                            apprLineDataList.filter((data: any) => (data.apprStat == 'S002001'))[0]?.userSeq == user.userSeq &&
                                            <>
                                                <button className={'sh-btn mr-5 btn-primary'} onClick={() => {
                                                    fn_appr('appr')
                                                }}> 승인
                                                </button>
                                                <button className={'sh-btn mr-5 btn-danger'} onClick={() => {
                                                    fn_appr('rjct')
                                                }}> 반려
                                                </button>
                                            </>
                                        }

                                        {(props.detailData.prcsStatCd == 'S001002' && (props.detailData?.rgstrSeq == user.userSeq || apprLineDataList.filter((data: any) => (data.userSeq == user.userSeq)).length > 0)) &&
                                            <SHIconButton kind={'normal'} marginRight={'5px'}
                                                          onClick={fn_cancelRqst}> 회수
                                            </SHIconButton>
                                        }

                                        {(['S001001', 'S001002'].includes(props.detailData.prcsStatCd) && props.detailData?.rgstrSeq == user.userSeq) &&

                                            <SHIconButton kind={'delete'} marginRight={'5px'}
                                                          onClick={fn_deleteWrkPrmtData}> 삭제
                                            </SHIconButton>
                                        }


                                    </div>


                                </div>

                                <div className="div-body px-3">
                                    <div className='w-100 d-flex justify-content-end align-items-center mb-2'>

                                    </div>
                                    <div className="w-100">
                                        {apprLineDataList.length > 0 ? <ApprCardList items={apprLineDataList}/> :
                                            <span>※ 결재정보를 등록해 주십시오.</span>
                                        }

                                    </div>
                                    <div>
                                        <Table className="align-middle">
                                            <tbody>
                                            <tr>
                                                <th className="sh-bg-th" style={{width: '15%'}}>참조자</th>
                                                <td style={{width: '85%'}} colSpan={3} id={'refTd'}>
                                                    <Select
                                                                 options={refUserList}
                                                                 value={selected}
                                                                 isDisabled={props.detailData.prcsStatCd == 'S001001' ? false : true}
                                                                 onChange={setSelected}
                                                                 placeholder={"참조 선택"}
                                                                 isMulti

                                                    />
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>


                                    </div>
                                </div>
                            </div>
                        }
                        <div className="sh-modal-div">
                            <div className="div-header" style={{ justifyContent:'flex-start' }}>
                                <button type={"button"} style={{marginRight: '5px'}} onClick={() => {
                                    if (!wrkDt) {
                                        toast.warn('먼저 작업일자를 선택해 주십시오.');
                                        return;
                                    }
                                    setIsRiskEvalModalOpen(true)
                                }}
                                        className={"sh-btn gray view"}
                                        disabled={['S001003','S001004'].includes(props.detailData?.prcsStatCd) ? true : false}>
                                    위험성 평가 불러오기
                                </button>

                                <h1 style={{ color:'red' }}>* 위험성평가서를 먼저 작성하셔야 합니다.</h1>
                            </div>
                        </div>



                        <div className="sh-modal-div">
                            <div className="div-header">
                                기본정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>공종</th>
                                        <td style={{width: '35%'}}>
                                            <SHSelect initOptionGb={'sel'} name={'wrkTypeCd'}
                                                      selParam={{siteId: props.siteId, manageCode: 'M001001'}}
                                                      codeGb={'siteMngCd'} className="w-100"
                                                      onChange={fn_setFormData}
                                                      value={data?.wrkTypeCd}
                                                      disabled={props.detailData?.prcsStatCd == 'S001003' ? true : false}
                                            ></SHSelect>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>작업 업체명</th>
                                        <td style={{width: '35%'}}>
                                            <SHSelect initOptionGb={'sel'} name={'cmpnySeq'}
                                                      selParam={{
                                                          siteId: props.siteId,
                                                          cmpnySeq: user.authCode === 'siteMstr' ? null : user.blngCmpnySeq
                                                      }}
                                                      codeGb={'siteCmpny'}
                                                      className="sh-form select w-100"
                                                      disabled={wrkrDataList.length > 0 || selChrgrs.length > 0}
                                                      onChange={(e) => {
                                                          fn_setFormData(e);
                                                      }}
                                                      value={data?.cmpnySeq}></SHSelect>
                                        </td>

                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>작업일</th>
                                        <td style={{width: '35%'}} ref={prodRef}>
                                            <SHDatePicker2 setter={setWrkDt} name={'eduDt'} defaultValue={wrkDt} onChange={fn_setFormData} fitWidth={true} disabled={['S001003', 'S001004'].includes(props.detailData?.prcsStatCd) ? true : false}/>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>작업시간</th>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <SHSelect initOptionGb={'sel'} name={'wrkTimeGbCd'}
                                                          className={'sh-form min-w-[80px] select mr-10'}
                                                          codeGb={'cmmn'}
                                                          codeGrpId={'T001'}
                                                          onChange={(e) => {
                                                              fn_onWrkTimeSelChg(e);
                                                          }}
                                                          value={data?.wrkTimeGbCd}
                                                          disabled={props.detailData?.prcsStatCd == 'S001003' ? true : false}
                                                ></SHSelect>
                                                <select className={'sh-form select'} value={data?.wrkStrtHr || ''}
                                                        onChange={fn_setFormData} name={'wrkStrtHr'}
                                                        disabled={props.detailData?.prcsStatCd == 'S001003' ? true : false}
                                                >
                                                    {
                                                        wrkHrList.map((data: string, idx: number) => (
                                                            <option className={'text-center'} key={idx}
                                                                    value={data}>{data}</option>
                                                        ))
                                                    }

                                                </select>
                                                <span className="mr-10">시</span>
                                                <select className={'sh-form select'} name={'wrkStrtMi'}
                                                        onChange={fn_setFormData}
                                                        value={data?.wrkStrtMi || ''}
                                                        disabled={props.detailData?.prcsStatCd == 'S001003' ? true : false}
                                                >
                                                    {
                                                        wrkMiList.map((data: string, idx: number) => (
                                                            <option className={'text-center'} key={idx}
                                                                    value={data}>{data}</option>
                                                        ))
                                                    }
                                                </select>
                                                <span className="mr-10">분</span>
                                                <span className="mr-10">~</span>
                                                <select className={'sh-form select'} value={data?.wrkEndHr || ''}
                                                        onChange={fn_setFormData}
                                                        name={'wrkEndHr'}
                                                        disabled={props.detailData?.prcsStatCd == 'S001003' ? true : false}
                                                >
                                                    {
                                                        wrkHrList.map((data: string, idx: number) => (
                                                            <option className={'text-center'} key={idx}
                                                                    value={data}>{data}</option>
                                                        ))
                                                    }

                                                </select>
                                                <span className="mr-10">시</span>
                                                <select className={'sh-form select'} name={'wrkEndMi'}
                                                        onChange={fn_setFormData}
                                                        value={data?.wrkEndMi || ''}
                                                        disabled={props.detailData?.prcsStatCd == 'S001003' ? true : false}
                                                >
                                                    {
                                                        wrkMiList.map((data: string, idx: number) => (
                                                            <option className={'text-center'} key={idx}
                                                                    value={data}>{data}</option>
                                                        ))
                                                    }
                                                </select>
                                                <span>분</span>
                                            </div>
                                        </td>
                                    </tr>
                                    {
                                        CmmnFn.isSmSite() &&
                                        <tr className="border-top align-middle">
                                            <th className="sh-bg-th" style={{width: '15%'}}>작업 현장</th>
                                            <td colSpan={3} style={{width: '85%'}}>
                                                <SHSelect initOptionGb={'sel'} name={'wrkSiteCd'}
                                                          selParam={{siteId: props.siteId, manageCode: 'M001006'}}
                                                          codeGb={'siteMngCd'} className="w-100"
                                                          onChange={fn_setFormData}
                                                          value={data?.wrkSiteCd}></SHSelect>
                                            </td>
                                        </tr>
                                    }

                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        <div className="sh-modal-div">
                            <div className="div-header">
                                <div>작업내용</div>

                                <div className="d-flex align-items-center">
                                    <input className={'hidden'} name={"wrkCntntsFile"}
                                           id="wrkprmRgstModal_input-file" type={"file"}
                                           onChange={fn_addWrkCntntsFile}/>

                                    <label className={"sh-btn gray view mr-5 cursor-pointer"}
                                           htmlFor={"wrkprmRgstModal_input-file"} style={{display:['S001003','S001004'].includes(props.detailData?.prcsStatCd) ? "none" : "block"}}>
                                        파일올리기
                                    </label>
                                    <button type={"button"} style={{marginRight: '5px'}} onClick={() => {setIsFileUpload(false)}} className={"sh-btn gray view"}
                                            disabled={['S001003','S001004'].includes(props.detailData?.prcsStatCd) ? true : false}
                                    >
                                        직접 입력
                                    </button>
                                </div>

                            </div>
                            <div className={"px-3 div-body"}>
                                {isFilUpload === true ? (localWrkFormFileSrc &&
                                        <> <Table className={"my-2 align-middle"}>
                                            <tbody>
                                            <tr>

                                                <th className="sh-bg-th" style={{width: '15%'}}>작업인원</th>
                                                <td style={{width: '85%'}}>
                                                    <SHInput type="number" name={'wrkrCnt'} onChange={fn_setFormData}
                                                             value={data?.wrkrCnt || 0}
                                                             className="sh-form input"
                                                    />
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>

                                            <iframe src={localWrkFormFileSrc} className='w-100 min-h-[600px]'/>
                                        </>
                                    )

                                    : isFilUpload === false &&
                                    <>
                                        <Table className={"my-2 align-middle"}>
                                            <tbody>

                                            {isSmSite ?
                                                <tr className="border-top align-middle">
                                                    <th className="sh-bg-th" style={{width: '15%'}}>작업 책임자</th>
                                                    <td style={{width: '35%'}}>
                                                        <div
                                                            className="d-flex justify-content-between align-items-center">
                                                            <SHInput type={'text'} readOnly={true}
                                                                     className={'form-control'} name={'wrkChrgrNm'}
                                                                     value={data?.wrkChrgrNm}/>

                                                            <button className="sh-btn btn-secondary min-w-[70px] ml-5"
                                                                    type="button" onClick={fn_openMngrSrchModal}>검색
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <th className="sh-bg-th" style={{width: '15%'}}>작업인원</th>
                                                    <td style={{width: '35%'}}>
                                                        <SHInput type="number" name={'wrkrCnt'}
                                                                 onChange={fn_setFormData}
                                                                 value={data?.wrkrCnt || 0}
                                                                 className="sh-form input"
                                                        />
                                                    </td>
                                                </tr> :
                                                <>
                                                    <tr className="border-top align-middle">
                                                        <th className="sh-bg-th" style={{width: '15%'}}>작업 책임자</th>
                                                        <td style={{width: '35%'}}>
                                                            <div
                                                                className="d-flex justify-content-between align-items-center">
                                                                <SHInput type={'text'} readOnly={true}
                                                                         className={'form-control'} name={'wrkChrgrNm'}
                                                                         value={data?.wrkChrgrNm}/>

                                                                <button
                                                                    className="sh-btn btn-secondary min-w-[70px] ml-5"
                                                                    type="button" onClick={fn_openMngrSrchModal}>검색
                                                                </button>
                                                            </div>
                                                        </td>
                                                        <th className="sh-bg-th" style={{width: '15%'}}>작업위치</th>
                                                        <td style={{width: '35%'}}>
                                                            <SHSelect initOptionGb={'sel'} name={'wrkPlcCd'}
                                                                      selParam={{
                                                                          siteId: props.siteId,
                                                                          manageCode: 'M001004'
                                                                      }}
                                                                      codeGb={'siteMngCd'} className="w-100"
                                                                      onChange={fn_setFormData}
                                                                      value={data?.wrkPlcCd}></SHSelect>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th className="sh-bg-th" style={{width: '15%'}}>중장비</th>
                                                        <td style={{width: '35%'}}>
                                                            <Select
                                                                         options={eqmntDataList}
                                                                         value={selEqmnts}
                                                                         onChange={setSelEqmnts}
                                                                         placeholder={"중장비 선택"}
                                                                         isMulti
                                                            />
                                                        </td>
                                                        <th className="sh-bg-th" style={{width: '15%'}}>작업인원</th>
                                                        <td style={{width: '35%'}}>
                                                            <SHInput type="number" name={'wrkrCnt'}
                                                                     onChange={fn_setFormData}
                                                                     value={data?.wrkrCnt || 0}
                                                                     className="sh-form input"
                                                            />
                                                        </td>
                                                    </tr>
                                                </>

                                            }

                                            </tbody>
                                        </Table>

                                        {!isSmSite &&
                                            <Table borderless className="sub my-2 mb-0 text-center">
                                                <thead>
                                                <tr className="table-secondary">
                                                    <th className="align-middle w-[10%]">No</th>
                                                    <th className="align-middle w-[70%]">장비명</th>
                                                    <th className="align-middle w-[20%]">수량</th>

                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    selEqmnts.length > 0 ?
                                                        selEqmnts.map((data: any, idx: number) => (
                                                            <tr key={idx}>
                                                                <td className={'text-center'}>{idx + 1}
                                                                </td>
                                                                <td className={'text-center'}>{data.label}
                                                                </td>
                                                                <td>
                                                                    <select className={'form-select text-center'}
                                                                            value={data.eqmntCnt}
                                                                            onChange={(e) => {
                                                                                fn_updateSelEqmntsData(e, idx)
                                                                            }}>
                                                                        {
                                                                            eqmntOpts.map((data: number) => (
                                                                                <option key={data}
                                                                                        value={data}>{data}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </td>

                                                            </tr>

                                                        )) : <tr>
                                                            <td colSpan={3}>중장비를 선택해 주세요.</td>
                                                        </tr>
                                                }

                                                </tbody>
                                            </Table>
                                        }

                                    </>

                                }

                            </div>

                        </div>

                        {isFilUpload === true ? '' : isFilUpload === false &&
                            <div className="sh-modal-div">
                                <div className="div-header">
                                    <div>
                                        금일 작업 사항
                                    </div>
                                    <SHIconButton kind={'add'} onClick={fn_addTodayWrkRow}>추가</SHIconButton>
                                </div>
                                <div className="div-body px-3">
                                    <Table className="my-2" borderless style={{borderTop: 'none'}}>
                                        <colgroup>
                                            <col className="align-middle w-[20%]"/>
                                            <col className="align-middle w-[72%]"/>
                                            <col className="align-middle w-[8%]"/>

                                        </colgroup>
                                        <tbody>
                                        {
                                            todayWrkInfoList.length > 0 ?
                                                todayWrkInfoList.map((todayWrkData: any, idx) => (
                                                    <tr key={idx} style={{borderBottom: 'none'}}>
                                                        <td>
                                                            <SHSelect initOptionGb={'sel'}
                                                                      className="w-100"
                                                                      selParam={{
                                                                          siteId: props.siteId,
                                                                          manageCode: 'M001002'
                                                                      }} onChange={(e) => {
                                                                fn_updateTodayWrkInfoData('ocptCd', e.target.value, idx)
                                                            }}
                                                                      codeGb={'siteMngCd'}
                                                                      value={todayWrkData.ocptCd}></SHSelect>
                                                        </td>
                                                        <td>
                                                            <SHInput className="sh-form input"
                                                                     value={todayWrkData.wrkCntnts}
                                                                     onChange={(e) => {
                                                                         fn_updateTodayWrkInfoData('wrkCntnts', e.target.value, idx)
                                                                     }}/>
                                                        </td>
                                                        <td className={'text-center'}>
                                                            <img
                                                                className={'w-[25px] cursor-pointer inline-block'}
                                                                src={IconRemove} onClick={() => fn_delRow(idx)}/>
                                                        </td>
                                                    </tr>

                                                )) : <tr style={{borderBottom: 'none'}}>
                                                    <td colSpan={5}>
                                                        <div className="text-center">
                                                            금일 작업사항을 등록해 주세요.
                                                        </div>
                                                    </td>
                                                </tr>
                                        }

                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        }

                        <div className="sh-modal-div">
                            <div className="div-header">
                                <div>작업 근로자 리스트</div>
                                <SHIconButton kind={'add'} onClick={fn_wrkrModalOpen} disabled={['S001003','S001004'].includes(props.detailData?.prcsStatCd) ? true : false}>작업자 등록</SHIconButton>
                            </div>
                            <div className="div-body px-3">
                                <Table className="sub my-2 text-center">
                                    <thead>
                                    <tr className="table-secondary">
                                        <th className="align-middle w-[20%]">No</th>
                                        <th className="align-middle w-[20%]">이름</th>
                                        <th className="align-middle w-[20%]">업체</th>
                                        <th className="align-middle w-[20%]">공종</th>
                                        <th className="align-middle w-[12%]">직종</th>
                                        <th className="align-middle w-[8%]">삭제</th>
                                    </tr>
                                    </thead>
                                    <tbody id="wrkDclrRgst_siteTBody">
                                    {
                                        wrkrDataList.length > 0 ?
                                            wrkrDataList.map((data: any, idx: number) => (
                                                <tr key={idx}>
                                                    <td>{idx + 1}</td>
                                                    <td className={'align-content-start'}>
                                                        {data.userName}
                                                    </td>
                                                    <td className={'align-content-start'}>
                                                        {data.companyName}
                                                    </td>
                                                    <td className={'align-middle'}>
                                                        {data.siteWrkTpNm}
                                                    </td>
                                                    <td className={'align-middle'}>
                                                        {data.siteOcptNm}
                                                    </td>
                                                    <td>
                                                        <img
                                                            style={{display:['S001003','S001004'].includes(props.detailData?.prcsStatCd) ? "none" : "inline"}}
                                                            className={'w-[25px] cursor-pointer inline-block'}
                                                            src={IconRemove} onClick={() => {
                                                            fn_delWrkStatRow(idx)
                                                        }}/>
                                                    </td>
                                                </tr>
                                            ))


                                            : <tr>
                                                <td colSpan={6}>
                                                    <div className="text-center">
                                                        허가서에 포함된 근로자가 없습니다.
                                                    </div>
                                                </td>
                                            </tr>
                                    }

                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        <div className="sh-modal-div">
                            <div className="div-header">
                                <div>
                                    위험성평가 요약
                                </div>
                                <button type={"button"} style={{marginRight: '5px'}} onClick={() => {
                                    //if (!wrkDt) {
                                    //    toast.warn('먼저 작업일을 선택해 주십시오.');
                                    //    return;
                                    //}
                                    setIsRiskEvalModalOpen(true)
                                }}
                                        className={"sh-btn gray view"}
                                    disabled={['S001003','S001004'].includes(props.detailData?.prcsStatCd) ? true : false}>
                                    위험성 평가 불러오기
                                </button>
                            </div>
                            <div className="div-body px-3">
                                <Table borderless className="sub my-2 mb-0 text-center">
                                    <thead>
                                    <tr className="table-secondary">
                                        <th className="align-middle w-[25%]">공종</th>
                                        <th className="align-middle w-[25%]">세부공종</th>
                                        <th className="align-middle w-[25%]">작업단계</th>
                                        <th className="align-middle w-[25%]">작업기간</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        riskEvlWrkActDataList.length > 0 ?
                                            riskEvlWrkActDataList.map((data: any, idx: number) => (
                                                <tr key={idx} onClick={() => fn_openRskAsmntModal(data)}
                                                    className={'cursor-pointer'}>
                                                    <td className={'text-center'}>{data.workTypeNm}
                                                    </td>
                                                    <td className={'text-center'}>{data.wrkTpNm}
                                                    </td>
                                                    <td className={'text-center'}>{data.wrkLvlNm}
                                                    </td>
                                                    <td className={'text-center'}>{(data.raStartDate) ? data.raStartDate + ' ~ ' + data.raEndDate : '-'}
                                                    </td>
                                                </tr>

                                            )) : <tr>
                                                <td colSpan={5}>위험성 평가서를 선택해 주세요.</td>
                                            </tr>
                                    }

                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        {
                            wrkPrmtRsltList.length > 0 &&
                            <div className="sh-modal-div">
                                <div className="div-header">
                                    <div>
                                        작업 결과
                                    </div>
                                </div>
                                <div className="div-body px-3">

                                    {wrkPrmtRsltList.map((data: any, idx: number) => (
                                        idx == 0 &&
                                        <Row key={idx}>
                                            <Col>

                                                <img className={'max-h-[300px]'}
                                                    src={process.env.REACT_APP_FILE_URL + "/shss-data/fileDownload?attachSeq=" + data?.photoFileId + '&sn=0&isPdf=0&time=' + new Date().getTime()}/>


                                            </Col>
                                            <Col>
                                                <span className="sh-fw-5">등록 일시</span>
                                                <SHInput className="form-control mb-2"
                                                         readOnly={true}
                                                         value={data.rgstDt}
                                                />
                                                <span className="sh-fw-5">일일 안전 사고 보고</span>
                                                <SHTextarea className={"form-control"}
                                                         disabled={true}
                                                         value={data.rprtCntnts} name={'rprtCntnts'}/>
                                            </Col>




                                        </Row>
                                    ))}
                                </div>
                            </div>
                        }


                        <div className="sh-modal-div">
                            <div className="div-header">
                                <div>첨부파일</div>
                            </div>
                            <div className="div-body px-3">
                                <SHFile fileName={'atchFileList'} fileList={atchFileList}
                                        setFileList={setAtchFileList} disabled={['S001003','S001004'].includes(props.detailData?.prcsStatCd) ? true : false}></SHFile>

                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        {!props.isReadOnly &&
                            ((['S001001', 'S001002'].includes(props.detailData?.prcsStatCd) && props.detailData?.rgstrSeq == user.userSeq)
                                || !props.detailData?.prcsStatCd)
                            &&
                            <button onClick={fn_registData}
                                    className='sh-btn pill p'>저장
                            </button>
                        }

                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default WrkPrmtRgstModal;

