import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {IoMdClose} from "react-icons/io";
import SHSelect from "../../../components/common/SHSelect";
import {Modal, Table} from "react-bootstrap";
import SHTreeView from "../../../components/common/SHTreeView";
import restapi from "../../../helper/restAPIHelper";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import SHSearch from "../../component/SHSearch";
import SHInput from "../../../components/common/SHInput";
import axios from "axios";
import {toast} from "react-toastify";
import iconTrashBean from "../../../images/icon_trashBean.png";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import warnCodeDic from "../../../util/warnCodeDic";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {CmmnFn} from "../../../helper/CmmnHelper";


interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    siteId: number
    manageItem: any;

}

const CnfgEqmntChkListModal = (props: IProps) => {
    const [dataList, setDataList] = useState<any>([])
    const fileRef = useRef() as React.MutableRefObject<any>;
    const [chgTargetData, setChgTargetData] = useState<any>(null);
    const user = getLoginUser();

    const fn_afterOpenEvnt = () => {
        fn_retrieveEqmntChkList();
    }


    function fn_closeModal() {
        props.setModalIsOpen(false);
    }


    const fn_save = () => {
        let isValid = true;
        let paramDataList: any= [];
        let param: any = {siteId:props.manageItem.siteId, eqmntCd: props.manageItem.manageSn, eqmntChkDataList:[]};

        dataList.map((data: any, idx: number) => {
            if(data.statGb !== 'O'){
                data.chkSeq = idx + 1;
                paramDataList.push(data);
                if(!data.chkCntnts){
                    toast.warn((idx + 1) + ' 번째 장비점검 사항을 입력해 주십시오');
                    isValid = false;
                }
            }
        }, []);

        console.log('paramDataList: ', paramDataList);
        if(!isValid){
            return;
        }

        if(paramDataList.length == 0 ){
            toast.warn('수정된 사항이 없습니다.');
            return;
        }

        param.eqmntChkDataList = paramDataList;

        if(!window.confirm('저장 하시겠습니까?')){
            return;
        }

        axios.post('/admin/api/manage/saveEqmntChkData', param).then((result) => {
            if (result.data > 0) {
                toast.success(messageCodeDic['001'])
                props.setModalIsOpen(false);
            } else {
                toast.error(errorCodeDic['003']);
            }
        })

    }

    const fn_retrieveEqmntChkList = () => {

        const param = {siteId:props.manageItem.siteId, eqmntCd: props.manageItem.manageSn}
        restapi.retrieveEqmntChkList(param).then((result) => {
            setDataList([].concat(result))
        })
    }

    const fn_addRow = () => {

        dataList.push({statGb: 'C', siteId:props.manageItem.siteId, eqmntCd:props.manageItem.manageSn});
        setDataList([].concat(dataList));
    }

    const fn_delRow = (chkSeq: string, selIdx: number) => {

        console.log('manageItem: ', props.manageItem);
        const param = {siteId: props.manageItem.siteId, eqmntCd: props.manageItem.manageSn, chkSeq:chkSeq};

        if(window.confirm((selIdx + 1) + ' 번째 항목을 삭제 하시겠습니까?')){
            axios.post('/admin/api/manage/deleteEqmntChkSiteData', param).then((result) => {
                const rsltCnt = result.data;
                if(rsltCnt > 0){
                    toast.success(messageCodeDic['002']);
                    fn_retrieveEqmntChkList();
                }else{
                    if(rsltCnt == -11){
                        toast.warn(warnCodeDic['001']);
                    }else{
                        toast.error(errorCodeDic['003']);
                    }
                }
            })
        }
    }

    const fn_setDataList = (e:any, paramData:any, selIdx:number) => {


        if(paramData.chkSeq){
            dataList.map((data:any) => {

                if(data.eqmntCd == paramData.eqmntCd && data.chkSeq == paramData.chkSeq){
                    data[e.target.name] = e.target.value
                    data.statGb = 'U'
                }

            })
        }else{
            dataList[selIdx][e.target.name] = e.target.value
            dataList[selIdx].statGb = 'C'
        }

        setDataList([].concat(dataList));
    }

    const fn_onEqmntImgClick = (data: any) => {
        console.log('data: ', data);
        if(!data.chkSeq){
            toast.warn('신규 장비점검 사항은 저장 후 사진첨부가 가능합니다.')
            return;
        }

        if(!window.confirm('해당 이미지를 변경 하시겠습니까?')){
            return;
        }
        setChgTargetData(data);
        fileRef.current.click();
    }

    const fn_changeEqmntImg = (e: any) => {
        console.log('e.target.files: ', e.target.files);

        const target = e.target.files
        let param = chgTargetData
        param.rgstrSeq = user.userSeq;
        param.siteId = props.siteId;
        if(e.target.files.length > 0){
            let formData = new FormData();
            formData.append("eqmntChkImgFile", e.target.files[0]);
            formData.set('eqmntChkInfo', new Blob([JSON.stringify(param)], {type: "application/json"}));
            axios.post('/admin/api/manage/updateEqmntChkImg', formData).then((result) => {
                    if (result.data > 0) {
                        toast.success(messageCodeDic['006'])
                        fn_retrieveEqmntChkList();
                    } else {
                        toast.error(errorCodeDic['003']);
                    }
                }
            )
        }
    }

    return (
        <div>

            <Modal
                className="sh-modal sub" fullscreen
                show={props.modalIsOpen}
                onShow={fn_afterOpenEvnt}
                onHide={fn_closeModal}
            >

                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        [ {props.manageItem?.manageName} ] 장비
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4 sh-modal-table-w100">
                    <div className="sh-modal-div">
                        <div className="div-header">
                            <div className='flex justify-content-between w-100'>
                                <span>장비점검 사항 설정</span>
                                <button className="sh-btn btn-secondary" type="button" onClick={() => {
                                    fn_addRow();
                                }}>추가
                                </button>
                            </div>
                        </div>
                        <div className="div-body px-3">
                            <div className="card-body">
                                <input hidden type={'file'} ref={fileRef} onChange={fn_changeEqmntImg}/>
                                <table
                                    className="sh-table-primary w-100 dataTable no-footer">
                                    <thead>
                                    <tr className="table-secondary">
                                        <th className="text-center">No</th>
                                        <th className="text-center">장비점검 사항</th>
                                        <th className="text-center">사진</th>
                                        <th className="text-center">삭제</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        dataList.length > 0 ?
                                            dataList.map((data: any, idx:number) => (
                                                <tr key={idx}>
                                                    <td className={'text-center'}>
                                                        {idx+1}
                                                    </td>
                                                    <td className={'text-center'}>
                                                        <SHInput name={'chkCntnts'} className={'sh-form form-control'} disabled={data.statGb=='D' ? true :  false} value={data.chkCntnts} onChange={(e) => {fn_setDataList(e, data, idx)}} />

                                                    </td>
                                                    <td className={'text-center max-w-[35px]'}> {(data.orgEqmntCd && CmmnFn.isValidEqmntOrgPhoto(data.orgEqmntCd, data.chkSeq)) ? (
                                                        data.imgFileId ? <img className={'cursor-pointer max-h-[200px]'} onClick={() => {fn_onEqmntImgClick(data)}} src={process.env.REACT_APP_FILE_URL + "/shss-data/fileDownload?attachSeq=" + data.imgFileId + '&sn=0&isPdf=0&time=' + new Date().getTime()}/>
                                                        : <img className={'cursor-pointer max-h-[200px]'} onClick={() => {fn_onEqmntImgClick(data)}} src={process.env.REACT_APP_FILE_URL + '/shss-data/eqmntChk/c' + ((data.orgEqmntCd + '').length == 1 ? '0' + data.orgEqmntCd : data.orgEqmntCd) + '_' + data.chkSeq + '.png'}/>
                                                    ) : data.imgFileId ? <img className={'cursor-pointer max-h-[200px]'} onClick={() => {fn_onEqmntImgClick(data)}} src={process.env.REACT_APP_FILE_URL + "/shss-data/fileDownload?attachSeq=" + data.imgFileId + '&sn=0&isPdf=0&time=' + new Date().getTime()}/> :
                                                        (<span className={'cursor-pointer'} onClick={() => {fn_onEqmntImgClick(data)}}>미등록</span>)

                                                    }
                                                    </td>
                                                    <td className={'text-center'}><img
                                                        className={'w-[25px] cursor-pointer inline-block'}
                                                        src={iconTrashBean} onClick={() => fn_delRow(data.chkSeq, idx)}/>
                                                    </td>
                                                </tr>

                                            )) : <tr>
                                                <td colSpan={4}>장비점검 사항을 등록해 주세요.</td>
                                            </tr>
                                    }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <button onClick={fn_save}
                                className='sh-btn pill p'>저장
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CnfgEqmntChkListModal;
