import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";

import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {getLoginUser} from "../../../helper/SecurityHelper";

import axios from "axios";
import {toast} from "react-toastify";
import errorCodeDic from "../../../util/errorCodeDic";
import restapi from "../../../helper/restAPIHelper";
import SHFile from "../../../components/common/SHFile";
import {ProgressContext} from "../../../context/Progress";
import {UserContext} from "../../../context/User";
import messageCodeDic from "../../../util/messageCodeDic";



interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    isReadOnly?: boolean;
}

const WrkPrmtProdExcelUploadModal = (props: IProps) => {

    const [fileList, setFileList] = useState([]);
    const formRef = React.createRef<HTMLFormElement>();
    const defaultUser = getLoginUser();
    const {user} = useContext<any>(UserContext);
    const {spinner} = useContext(ProgressContext);
    const siteId = user?.blngSiteId || defaultUser.blngSiteId
    const [data, setData] = useState<any>({})


    function afterOpenModal() {
        setFileList([]);
    }

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_addFile = (e: any) => {

        e.preventDefault();

        if (fileList.length == 0) {
            toast.warn('엑셀 파일을 업로드해 주십시오');
            return;
        }

        if(!window.confirm('작업지시서를 등록 하시겠습니까?')){
            return;
        }

        const param = {siteId:defaultUser?.blngSiteId, cmpnySeq: defaultUser?.blngCmpnySeq, rgstrSeq: defaultUser?.userSeq};
        // @ts-ignore
        var formData: any = new FormData(formRef.current);
        formData.set('excelFile', fileList[0]);
        formData.set('jsonData', new Blob([JSON.stringify(param)], {type: "application/json"}));

        spinner.start();
        axios.post('/admin/api/wrkPrmtProd/uploadExcelData', formData).then((result) => {

            const rsltCnt = result.data.rsltCnt;
            if (rsltCnt > 0) {
                toast.success(messageCodeDic['001'])
                props.setModalIsOpen(false);
            } else {
                if(result.data.errMsg){
                    toast.error(result.data.errMsg);
                }else{
                    toast.error(errorCodeDic['003']);
                }
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });


    }


    return (
        <div>

            <Modal
                className="sh-modal" size="lg"
                show={props.modalIsOpen}
                onShow={afterOpenModal}
                onHide={fn_closeModal}

            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        {'작업지시서 등록'}
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">
                    <div className={'font-bold'}>• 한번에 많은 내용을 등록 하게 되면 작업지시서 생성이 오래 걸릴 수 있습니다.</div>
                    <div className={'font-bold mb-3'}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;업로드 하는 엑셀 파일 내의 작업지시서 개수를 10개 이하로 등록해주세요.</div>
                    <form name={'form'} ref={formRef} encType="multipart/form-data" method={"post"}>
                    <SHFile isOnlyExcel={true} maxFileCnt={1} fileName={'wrkPrmtProdFileList'} fileId={'wrkPrmtProdFileList'} fileList={fileList} setFileList={setFileList}></SHFile>
                    </form>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l me-3'
                            onClick={fn_closeModal}>닫기
                        </button>

                        <button
                            className='sh-btn pill p' onClick={fn_addFile}>저장
                        </button>

                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default WrkPrmtProdExcelUploadModal;

