import React, {Dispatch, SetStateAction, useContext, useState} from "react";
import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import SHInput from "../../../components/common/SHInput";
import SHTextarea from "../../../components/common/SHTextarea";
import SHFile from "../../../components/common/SHFile";
import {ProgressContext} from "../../../context/Progress";
import {getLoginUser} from "../../../helper/SecurityHelper";
import SHSelect from "../../../components/common/SHSelect";
import restapi from "../../../helper/restAPIHelper";
import {toast} from "react-toastify";
import axios from "axios";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import SHIconButton from "../../../components/common/SHIconButton";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
    isCommentMode: boolean;
}

const SafetyNewspaperModal = (props: IProps) => {


    const [data, setData] = useState<any>();
    const [repFileSn, setRepFileSn] = useState<any>(null);
    const [fileList, setFileList] = useState([]);
    const formRef = React.createRef<HTMLFormElement>();
    const [apprLineDataList, setApprLineDataList] = useState<any>([]);
    const {spinner} = useContext(ProgressContext);
    const defaultUser: any = getLoginUser();

    const fn_updateData = (e: any) => {
        setData((item: any) => {
            if(e.target.name == 'siteId'){
                return {
                    ...item
                    ,['siteId']: e.target.value
                    ,['cmpnySeq']:null
                    ,['wrkSn']:null
                    ,['ocptSn']:null
                }
            }else{
                return {...item, [e.target.name]: e.target.value}
            }
        });
    }

    const submit = (e: any) => {
        e.preventDefault()
    }

    const fn_registData = () => {
        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if (!data.siteId) {
            toast.warn('현장명을 선택해 주십시오.')
            return;
        }

        if (!data.cmpnySeq) {
            toast.warn('업체명을 선택해 주십시오.')
            return;
        }

        if (!data.wrkSn) {
            toast.warn('공종을 선택해 주십시오.')
            return;
        }

        if (!data.ocptSn) {
            toast.warn('직종을 선택해 주십시오.')
            return;
        }

        if (!data.title) {
            toast.warn('제목을 입력해 주십시오.')
            return;
        }

        if (!data.contents) {
            toast.warn('내용을 입력해 주십시오.')
            return;
        }

        let param = data;
        // @ts-ignore
        var formData: any = new FormData(formRef.current)

        if(fileList.length > 0){
            for(var i =0;i<fileList.length;i++){
                formData.append('atchFileList',fileList[i]);
            }
        }

        param.rgstrSeq = defaultUser.userSeq;
        param.updtrSeq = defaultUser.userSeq;
        formData.set('sftyNwspprVO', new Blob([JSON.stringify(param)], {type: "application/json"}))

        if (formData) {
            if (!window.confirm('저장 하시겠습니까?')) {
                return;
            }

            spinner.start();
            axios.post('/admin/api/sftyNwsppr/saveSftyNwspprData', formData).then((result) => {
                if (result.data > 0) {
                    toast.success(messageCodeDic['001'])
                    props.setModalIsOpen(false);
                } else {
                    toast.error(errorCodeDic['003']);
                }
            }).catch(error => {
                toast.error(errorCodeDic['003']);
                console.log('error: ', error);
            }).finally(() => {
                spinner.stop();
            });
        }
    }

    const fn_commentRegist = () => {
        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if (!data.comment) {
            toast.warn('코멘트를 입력해 주십시오.')
            return;
        }

        let param = data;
        // @ts-ignore
        var formData: any = new FormData(formRef.current)

        param.cnfrmSeq = defaultUser.userSeq;

        formData.set('sftyNwspprVO', new Blob([JSON.stringify(param)], {type: "application/json"}))

        if (formData) {
            if (!window.confirm('확인 처리 하시겠습니까?')) {
                return;
            }

            spinner.start();
            axios.post('/admin/api/sftyNwsppr/saveSftyNwspprDataForComment', formData).then((result) => {
                if (result.data > 0) {
                    toast.success(messageCodeDic['001'])
                    props.setModalIsOpen(false);
                } else {
                    toast.error(errorCodeDic['003']);
                }
            }).catch(error => {
                toast.error(errorCodeDic['003']);
                console.log('error: ', error);
            }).finally(() => {
                spinner.stop();
            });
        }
    }

    const fn_openModal = () => {
        if(!props.detailData){
            setFileList([]);
            setData(null);
        }else{
            setData(props.detailData);
            if(props.detailData.atchFileId){
                restapi.retrieveAttachInfoList({attachSeq: props.detailData.atchFileId}).then((rsltData: any) => {
                    setFileList([].concat(rsltData));
                })
            }else{
                setFileList([]);
            }
        }
    }

    const fn_closeModal = () => {
        props.setModalIsOpen(false);
    }

    return (
        <div>
            <Modal
                className="sh-modal" size="xl"
                show={props.modalIsOpen}
                onShow={fn_openModal}
                onHide={fn_closeModal}
            >

                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        {'상세보기'}
                        <div className={'d-flex justify-content-between align-items-center'}>
                            <button className="sh-btn btn-secondary min-w-[50px] ml-5 mr-10" onClick={fn_commentRegist} type="button">확인
                            </button>
                            <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <form name={'detailForm'} ref={formRef} encType="multipart/form-data" method={"post"}
                          onSubmit={submit}>
                        <SHInput type={'hidden'} value={data?.atchFileId} name={'atchFileId'}></SHInput>
                        <SHInput type={'hidden'} value={data?.sftyNwspprId} name={'sftyNwspprId'}></SHInput>

                        <div className="sh-modal-div">

                            <div className="div-body px-3">
                                <div className="w-100" style={{display:data?.cnfrmSeq?'block':'none'}}>
                                    <div className="container-fluid px-0">
                                        <div className="row" id="ga-company-sites">
                                            <div className="col-md-10 mb-4">
                                            </div>
                                            <div className="col-md-2 mb-2">
                                                <div className="card ga-card small">
                                                    <div className="card-header text-center">{data?.dprtNm}</div>
                                                    <div className="card-body items-center min-h-[100px]">
                                                        <h5 className="card-title text-center">{data?.cnfrmNm}</h5>
                                                        <h5 className={"card-title text-center text-blue-600"}>확인완료</h5>
                                                        <h5 className="card-title text-center">{data?.cnfrmDt}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>작성일</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput type="text" className="form-control" name={'rgstDt'}
                                                     onChange={fn_updateData} value={data?.rgstDt} disabled={true}/>
                                        </td>
                                        <td style={{width: '50%'}}>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        <div className="sh-modal-div">
                            <div className="div-header">
                                내용 정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>내용</th>
                                        <td style={{width: '85%'}} colSpan={3}>
                                            <SHTextarea rows={5} onChange={fn_updateData} name={'contents'}
                                                        value={data?.contents}
                                                        className={"form-control"}
                                                        placeholder='내용을 입력해주세요.'
                                                        disabled={data?.sftyNwspprId == null || data?.rgstrSeq == defaultUser.userSeq?false:true}
                                            />
                                        </td>
                                    </tr>
                                    <tr className="align-middle" style={{width: '15%'}}>
                                        <th className="sh-bg-th">첨부파일</th>
                                        <td colSpan={3}>
                                            <SHFile fileName={'files'} fileList={fileList} setFileList={setFileList}
                                                    disabled={true}></SHFile>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        <div className="sh-modal-div">
                            <div className="div-header">
                                코멘트 정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    {(props.isCommentMode || data?.cnfrmSeq) &&
                                        <tr className="border-top align-middle">
                                            <th className="sh-bg-th" style={{width: '15%'}}>확인자</th>
                                            <td style={{width: '35%'}}>
                                                <SHInput type="text" className="form-control" name={'cnfrmNm'}
                                                         onChange={fn_updateData} value={data?.cnfrmNm?data?.cnfrmNm:defaultUser.userName} disabled={true}/>
                                            </td>
                                            <th className="sh-bg-th" style={{width: '15%'}}>코멘트 일시</th>
                                            <td style={{width: '35%'}}>
                                                <SHInput type="text" className="form-control" name={'cnfrmDt'}
                                                         onChange={fn_updateData} value={data?.cnfrmDt} disabled={true}/>
                                            </td>
                                        </tr>
                                    }


                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>코멘트</th>
                                        <td style={{width: '85%'}} colSpan={3}>
                                            <SHTextarea rows={5} onChange={fn_updateData} name={'comment'}
                                                        value={data?.comment}
                                                        className={"form-control"}
                                                        placeholder='코멘트를 입력해주세요.'
                                                        disabled={data?.cnfrmSeq || false}
                                            />
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </form>
                </Modal.Body>

                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                    </div>
                </Modal.Footer>

            </Modal>
        </div>
    )
}

export default SafetyNewspaperModal;
