import React, {useContext, useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {ReactComponent as IconRight} from "../../assets/images/ico/icon-right.svg";
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import {Doughnut} from 'react-chartjs-2';
import {UserContext} from "../../context/User";
import {getLoginUser} from "../../helper/SecurityHelper";
import restapi from "../../helper/restAPIHelper";
import {useNavigate} from "react-router-dom";
import IncgrtyInfoListModal from "./modal/IncgrtyInfoListModal";

ChartJS.register(ArcElement, Tooltip);

const DashboardNonconformityState = () => {
    const {user} = useContext<any>(UserContext);
    const defaultUser = getLoginUser();
    const navigate = useNavigate();
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [incgrtyDataList, setIncgrtyDataList] = useState<any>([]);
    const [data, setData] = useState<any>({totCnt: 0, cmpltCnt: 0, nCmpltCnt: 0});
    const [prcsStatCd, setPrcsStatCd] = useState<string>('');
    const [cData, setCData] = useState<any>({
        labels: ['전체'],
        datasets: [{
            data: [0],
            backgroundColor: [
                '#5685F7',
            ],
            borderWidth: 0,
            borderRadius: 50,
        }],
    });
    const [iData, setIData] = useState<any>({
        labels: ['미조치'],
        datasets: [{
            data: [0, 0],
            backgroundColor: [
                '#FF718B',
                '#2A2B2F',
            ],
            borderWidth: 0,
            borderRadius: 50,
        }],
    });
    const [aData, setAData] = useState<any>({
        labels: ['조치완료'],
        datasets: [{
            data: [0, 0],
            backgroundColor: [
                '#30E4C7',
                '#2A2B2F',
            ],
            borderWidth: 0,
            borderRadius: 50,
        }],
    });

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            }
        },
        cutout: '80%',
    };

    useEffect(() => {
        const siteId = user?.blngSiteId || defaultUser.blngSiteId;
        restapi.retrieveIncgrtyDataTotList({siteId:siteId}).then((rsltData: any) => {
            const totCnt = rsltData.length;
            let cmpltCnt = 0;
            let nCmpltCnt = 0;
            console.log('totCnt: ', totCnt);

            rsltData.map((data: any) => {
                if(data.prcsStatCd == 'I001001'){
                    nCmpltCnt ++;
                }else if(data.prcsStatCd == 'I001002'){
                    cmpltCnt ++;
                }
            })

            setIncgrtyDataList(rsltData);

            console.log('cmpltCnt: ', cmpltCnt);
            console.log('nCmpltCnt: ', nCmpltCnt);
            setData({totCnt: totCnt, cmpltCnt: cmpltCnt, nCmpltCnt: nCmpltCnt})

            setCData(
                {
                    labels: ['전체'],
                    datasets: [{
                        data: [totCnt],
                        backgroundColor: [
                            '#5685F7',
                        ],
                        borderWidth: 0,
                        borderRadius: 50,
                    }],
                }
            )

            setIData(
                {
                    labels: ['미조치'],
                    datasets: [{
                        data: [nCmpltCnt, totCnt-nCmpltCnt],
                        backgroundColor: [
                            '#FF718B',
                            '#2A2B2F',
                        ],
                        borderWidth: 0,
                        borderRadius: 50,
                    }],
                }
            )

            setAData(
                {
                    labels: ['조치완료'],
                    datasets: [{
                        data: [cmpltCnt, totCnt-cmpltCnt],
                        backgroundColor: [
                            '#30E4C7',
                            '#2A2B2F',
                        ],
                        borderWidth: 0,
                        borderRadius: 50,
                    }],
                }
            )


            console.log('incgrty rsltData: ', rsltData);
        })
    }, [user])

    const fn_openIncgrtyInfoModal = (prcsStatCd: string) => {
        setPrcsStatCd(prcsStatCd);
        setModalIsOpen(true);
    }

    return(
        <>
            <Container fluid className="sh-bg-pane rounded-[5px] py-2 px-3">
                <IncgrtyInfoListModal prcsStatCd={prcsStatCd} setModalIsOpen={setModalIsOpen}  modalIsOpen={modalIsOpen} incgrtyDataList={incgrtyDataList} siteId={user?.blngSiteId || defaultUser.blngSiteId} ></IncgrtyInfoListModal>
                <Row className="px-0 mb-2">
                    <Col bsPrefix="col-7" className="d-flex align-items-center w-100">
                        <div className="w-100 d-flex align-items-center justify-content-between">
                            <span className="pe-2 sh-fs-h4 sh-fw-5 sh-font-SD">부적합 현황</span>
                            <div className={'cursor-pointer'} onClick={() => navigate('/siteSafetyManagement/incongruityManagement')}>
                            <IconRight style={{width:'20px',marginBottom:'1px'}} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-1">
                    <Col>
                        <div onClick={() => fn_openIncgrtyInfoModal('')} className="cursor-pointer d-flex w-100 position-relative justify-content-center">
                            <Doughnut className="z-50 position-relative" options={options} data={cData} width="80%" />
                            <div className="position-absolute w-100 text-center start-50 top-50 z-10 sh-fs-b2" style={{transform: 'translate(-50%, -50%)'}}>
                                <div className="sh-text-font_secondary02 sh-lh-b1">전체</div>
                                <div>
                                    <span className="sh-fw-5 sh-fs-b1 me-1">{data.totCnt}</span>
                                    <span className="sh-fs-b3">건</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div onClick={() => fn_openIncgrtyInfoModal('I001001')} className="cursor-pointer d-flex w-100 position-relative justify-content-center">
                            <Doughnut className="z-50 position-relative" options={options} data={iData} width="80%" />
                            <div className="position-absolute w-100 text-center start-50 top-50 z-10 sh-fs-b2" style={{transform: 'translate(-50%, -50%)'}}>
                                <div className="sh-text-font_secondary02 sh-lh-b1">미조치</div>
                                <div>
                                    <span className="sh-fw-5 sh-fs-b1 me-1">{data.nCmpltCnt}</span>
                                    <span className="sh-fs-b3">건</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div onClick={() => fn_openIncgrtyInfoModal('I001002')} className="cursor-pointer d-flex w-100 position-relative justify-content-center">
                            <Doughnut className="z-50 position-relative" options={options} data={aData} width="80%" />
                            <div className="position-absolute w-100 text-center start-50 top-50 z-10 sh-fs-b2" style={{transform: 'translate(-50%, -50%)'}}>
                                <div className="sh-text-font_secondary02 sh-lh-b1">조치완료</div>
                                <div>
                                    <span className="sh-fw-5 sh-fs-b1 me-1">{data.cmpltCnt}</span>
                                    <span className="sh-fs-b3">건</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default DashboardNonconformityState;
