import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {COMMON_MODAL_STYLE} from "../../../helper/StyleHelper";
import {Button, Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import SHSelect from "../../../components/common/SHSelect";
import {getLoginUser} from "../../../helper/SecurityHelper";
import SHSearch from "../../component/SHSearch";
import SHInput from "../../../components/common/SHInput";
import restapi from "../../../helper/restAPIHelper";
import SiteSftyChkRgstModal from "../../safetyWorkReportManagement/modal/SiteSftyChkRgstModal";
import SiteManageRgstModal from "../../headManage/modal/SiteManageRgstModal";
import {SiteContext} from "../../../context/Site";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
}

const $ = require("jquery")

const LinkInfoModal = (props: IProps) => {
    const [datatable, setDatatable] = useState<any>();

    const [isCodeDataLoaded, setIsCodeDataLoaded] = useState<any>(null);
    const searchFormRef = useRef<any>();
    const [dataList, setDataList] = useState<any>([]);
    const [orgnDataList, setOrgnDataList] = useState<any>([]);
    const {setSite} = useContext<any>(SiteContext);
    const user = getLoginUser();


    useEffect(() => {
        if (isCodeDataLoaded) {
            fn_search();
        }
    }, [isCodeDataLoaded]);

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_search = (e?: any) => {
        e?.preventDefault()
        datatable?.draw()
    }

    const fn_onShow = () => {

    }

    const fn_selectChange = (e: any) => {
        console.log('e: ', e.target.value);
        if(e.target.value !== ''){
            window.open(e.target.value, '_blank');
        }
    }

    return (
        <div>
            <Modal
                className="sh-modal sub" size="sm"
                show={props.modalIsOpen}
                onShow={() => {
                    fn_onShow()

                }}
                onHide={fn_closeModal}
            >


                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        링크 바로가기
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">

                    <div className="safetyLawSub d-flex flex-row mb-3">

                        <div className="d-flex flex-column w-100 p-2">
                            <select className={'form-select'} onChange={(e) => fn_selectChange(e)}>
                                <option value={""}>링크 바로가기</option>
                                <option value={"https://www.kiscon.net"}>1. 키스콘</option>
                                <option value={"https://www.cw.or.kr"}>2. 건설근로자공제회</option>
                                {user.authGrpId == 'HEAD' &&
                                    <>
                                        <option value={"https://www.csi.go.kr"}>3. CSI</option>
                                        <option value={"https://www.moel.go.kr"}>4. 고용노동부</option>
                                    </>
                                }
                            </select>


                            {/*<div className="safetyLawLink mb-2">*/}
                            {/*    <Button variant="link" href="https://www.kiscon.net" target="_blank">1. 키스콘</Button>*/}
                            {/*</div>*/}
                            {/*<div className="safetyLawLink mb-2">*/}
                            {/*    <Button variant="link" href="https://www.cw.or.kr" target="_blank">2. 건설근로자공제회</Button>*/}
                            {/*</div>*/}
                            {/*{user.authGrpId == 'HEAD' &&*/}
                            {/*    <>*/}
                            {/*        <div className="safetyLawLink mb-2">*/}
                            {/*            <Button variant="link" href="https://www.csi.go.kr" target="_blank">3.*/}
                            {/*                CSI</Button>*/}
                            {/*        </div>*/}
                            {/*        <div className="safetyLawLink mb-2">*/}
                            {/*            <Button variant="link" href="https://www.moel.go.kr" target="_blank">4.*/}
                            {/*                고용노동부</Button>*/}
                            {/*        </div>*/}
                            {/*    </>*/}
                            {/*}*/}

                        </div>

                    </div>

                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>

                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default LinkInfoModal;
