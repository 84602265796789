import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from "axios";
import Datatables, {getCheckedItems} from "../component/Datatables";
import Modal from 'react-modal';
import {getLoginUser} from "../../helper/SecurityHelper";
import {toast} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import SHSelect from "../../components/common/SHSelect";
import {ProgressContext} from "../../context/Progress";
import restapi from "../../helper/restAPIHelper";
import {Col, Container, Row, Tab, Table, Tabs} from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";
import {CmmnFn} from "../../helper/CmmnHelper";
import EduStatPage from "./subPage/EduStatPage";
import SftyCapStatPage from "./subPage/SftyCapStatPage";
import OldWrkrStatPage from "./subPage/OldWrkrStatPage";
import WrkrInfoListModal from "../home/modal/WrkrInfoListModal";

type Props = {};

Modal.setAppElement('#root');
const SiteWrkrMngPage = (props: Props) => {

    const user = getLoginUser();
    const {spinner} = useContext(ProgressContext);
    const [dataList, setDataList] = useState<any>([]);
    const [srchData, setSrchData] = useState<any>({rgstDt: CmmnFn.fn_getCurrentDate(), eduGbCd: 'E001001', tabGb: 'sftyEdu'});
    const [statData, setStatData] = useState<any>({totSiteCnt: 0, noSiteCnt: 0, totWrkrCnt: 0, noWrkrCnt: 0});
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [wrkrDataList, setWrkrDataList] = useState<any>([]);
    const [selData, setSelData] = useState<any>(null);
    const [wrkDt, setWrkDt] = useState<string>(CmmnFn.fn_getCurrentDate());
    const [isDatePopupOpen, setIsDatePopupOpen] = useState<boolean>(false);
    const prodRef = useRef<any>();

    useEffect(() => {
        fn_search();
    }, [srchData])

    useEffect(() => {
        setSrchData({
            ...srchData,
            'rgstDt': wrkDt
        })
    }, [wrkDt]);


    useEffect(() => {
        if(selData){
            selData.wrkDt =  srchData.rgstDt;
            selData.qrNfcTpCd = 'T003001'
            selData.eduGbCd = srchData.eduGbCd;
            selData.tabGb = srchData.tabGb;

            restapi.retrieveDprtDtlStatDataList(selData).then((rsltData: any) => {
                console.log('qrnfc data: ', rsltData);
                setWrkrDataList([].concat(rsltData));
                setModalIsOpen(true);
            }).catch(error => {
                toast.error(errorCodeDic['003']);
                console.log('error: ', error);
            })
                .finally(() => {
                    spinner.stop();
                });
        }
    }, [selData]);

    const fn_search = () => {
        spinner.start();
        restapi.retrieveSiteWrkrMngStatList(srchData).then((rsltData: any) => {
            setDataList(rsltData);
            const totSiteCnt = rsltData.length;
            let noSiteCnt = 0;
            let totWrkrCnt = 0;
            let noWrkrCnt = 0;
            if(rsltData){
                rsltData.map((data: any) => {
                    const entrCnt = data.entrCnt;
                    const prfmdCnt = data.prfmdCnt;

                    let nPrfmdCnt = entrCnt - prfmdCnt;

                    if(srchData.tabGb == 'sftyCap'){
                        nPrfmdCnt = prfmdCnt
                    }


                    if(nPrfmdCnt < 0){
                        nPrfmdCnt = 0;
                    }
                    totWrkrCnt += entrCnt;
                    noWrkrCnt += nPrfmdCnt;
                    if(nPrfmdCnt > 0){
                        noSiteCnt ++;
                    }
                })
                setStatData({totSiteCnt: totSiteCnt, noSiteCnt: noSiteCnt, totWrkrCnt: totWrkrCnt, noWrkrCnt: noWrkrCnt});
            }else{
                setStatData({totSiteCnt: 0, noSiteCnt: 0, totWrkrCnt: 0, noWrkrCnt: 0});
            }

        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });
    }

    return (
        <Container fluid className="bg-white px-4 pt-4 sh-text-font_primary">
            <WrkrInfoListModal setModalIsOpen={setModalIsOpen} wrkDt={wrkDt.replace(/-/gi, '')} modalIsOpen={modalIsOpen} wrkrDataList={wrkrDataList} siteId={selData?.siteId} title={selData?.siteName} tabGb={srchData.tabGb} />
            <SubMenuTitle title={'현장 근로자 관리'} parentMenu={'안전 정보 및 공지 관리'}/>

            <Tabs
                transition={true}
                activeKey={srchData.tabGb}
                onSelect={(k) => setSrchData({
                    ...srchData,
                    'tabGb': k
                })}
                id="tabGb"
                className="mb-3"
            >
            <Tab eventKey="sftyEdu" title="안전교육 관리">
                <EduStatPage statData={statData} dataList={dataList} srchData={srchData} setSelData={setSelData} setSrchData={setSrchData} isDatePopupOpen={isDatePopupOpen} setIsDatePopupOpen={setIsDatePopupOpen} setWrkDt={setWrkDt} wrkDt={wrkDt}/>
            </Tab>
            <Tab eventKey="sftyCap" title="개인보호구 미착용 관리">
                <SftyCapStatPage statData={statData} dataList={dataList} srchData={srchData} setSelData={setSelData} setSrchData={setSrchData} isDatePopupOpen={isDatePopupOpen} setIsDatePopupOpen={setIsDatePopupOpen} setWrkDt={setWrkDt} wrkDt={wrkDt} />
            </Tab>
            <Tab eventKey="oldWrkr" title="고령 근로자 관리">
                <OldWrkrStatPage statData={statData} dataList={dataList} srchData={srchData} setSelData={setSelData} setSrchData={setSrchData} isDatePopupOpen={isDatePopupOpen} setIsDatePopupOpen={setIsDatePopupOpen} setWrkDt={setWrkDt} wrkDt={wrkDt} />
            </Tab>
            </Tabs>
        </Container>


    );



};

export default SiteWrkrMngPage;
