import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import {PushContext} from "../../../context/Push";
import {ReactComponent as IconRequestStop} from '../../../images/alertPopup/icon_request_stop.svg';
import {ReactComponent as IconAddrPoint} from '../../../images/alertPopup/icon_addr_point.svg';
import {ReactComponent as IconCallerSample} from '../../../images/alertPopup/icon_caller_sample.svg';
import {ReactComponent as IconRequestSos} from '../../../images/alertPopup/icon_request_sos.svg';
import iconNodata from "../../../images/icon_docdata.png";
import IncgrtyPicModal from "../../siteSafetyManagement/modal/IncgrtyPicModal";
import { Constatnts } from "../../../util/Constants";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;

}

const $ = require("jquery")

const PushModal = (props: IProps) => {
    const {getMessages, removeMessage, items} = useContext<any>(PushContext);
    const [item, setItem] = useState<any>({});
    const [total, setTotal] = useState<number>(0);
    const [audio,setAudio] = useState<HTMLAudioElement>(new Audio(Constatnts.audioStr));
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [dtlData, setDtlData] = useState<any>({});

    useEffect(() => {
        console.log('msg item: ', getMessages()[getMessages().length - 1]);
        setItem(getMessages()[getMessages().length - 1])
        setTotal(getMessages().length)
    }, [items])

    function fn_closeModal() {
        var messages = removeMessage(item)
        if (messages.length > 0) {
            setItem(messages[messages.index - 1])
            setTotal(messages.length)
        } else {
            props.setModalIsOpen(false);
        }
    }

    useEffect(() => {
        if (total > 0) {
            audio.play();
        }
    }, [total])

    const fn_onShow = () => {

    }

    const fn_openPicModal = (fileId: any) => {
        var param = {title: '사진', fileId:fileId}
        setDtlData(param);
        setIsModalOpen(true);
    }


    return (
        <div>
            <IncgrtyPicModal detailData={dtlData} setModalIsOpen={setIsModalOpen} modalIsOpen={isModalOpen} />
            <Modal
                className="sh-modal sub alert-popup"
                show={props.modalIsOpen}
                onShow={() => {
                    fn_onShow()

                }}
                onHide={fn_closeModal}
            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        <div className="d-flex">
                            {item?.type == 'wrkStop' ?
                                <div className="d-inline-flex">
                                    <IconRequestStop style={{width: '32px'}}/>
                                    <span className="alert-title d-flex">작업중지 요청</span>
                                </div>
                                : null }
                            {item?.type == 'sos' ?
                                <div className="d-inline-flex">
                                    <IconRequestSos style={{width: '32px'}}/>
                                    <span className="alert-title d-flex">SOS 응급요청</span>
                                </div>
                                : null }
                        </div>

                        <IoMdClose style={{width: '28px', height: '28px'}} className='hover:cursor-pointer'
                                   onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">
                    <div className="alert-target-card">
                        <div className="alert-addr d-flex flex-column">
                            <div>
                                <span className="site-name">{item?.siteNm}</span>
                            </div>
                            <div className="d-flex">
                                <IconAddrPoint style={{width: '20px'}}/>
                                <span className="">{item?.siteAddress}</span>
                            </div>
                        </div>
                        {/*<hr/>
                        <div className="alert-phone d-flex flex-column">
                            <div className="d-flex flex-row">
                                <span className="posi d-flex">현장소장</span>
                                <span className="nameAndPhone d-flex">미등록</span>
                            </div>
                            <div className="d-flex flex-row">
                                <span className="posi">안전관리자</span>
                                <span className="nameAndPhone">미등록</span>
                            </div>
                        </div>*/}
                    </div>
                    <div className="alert-info-card d-flex">
                        <div className="d-flex alert-caller">
                            {item?.atchFileId != null ?
                                    <img className={'cursor-pointer'} onClick={() => fn_openPicModal(item?.atchFileId)} src={process.env.REACT_APP_FILE_URL + "/shss-data/fileDownload?attachSeq=" + item?.atchFileId + '&sn=0&isPdf=0&time=' + new Date().getTime()}/>
                                    : null
                            }
                            {item?.atchFileId == null ?
                                <img src={iconNodata} alt="이미지"/>
                                : null
                            }
                        </div>
                        <div className="d-flex flex-column">
                            <div className="d-flex flex-row">
                                <span className="attr d-flex">일시</span>
                                <span className="cntnt d-flex">{item?.sendTime}</span>
                            </div>
                            <div className="d-flex flex-row">
                                <span className="attr d-flex">발신자</span>
                                <div>
                                    <span className="cntnt d-flex">{item?.userName}</span>
                                    <span className="cntnt-detail d-flex">{item?.siteCmpnyNm+"/"+item?.siteWrkTpNm+"/"+item?.siteOcptNm}</span>
                                </div>
                            </div>
                            {/*<div className="d-flex flex-row">
                                <span className="attr d-flex">위치</span>
                                <span className="cntnt d-flex">미등록</span>
                            </div>*/}
                            <div className="d-flex flex-row">
                                <span className="attr d-flex">사유</span>
                                {item?.type == 'wrkStop' ?
                                    <span className="d-flex reason">{item?.comments}</span>
                                    : null }
                                {item?.type == 'sos' ?
                                    <span className="d-flex reason">{item?.title}</span>
                                    : null }

                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default PushModal;
