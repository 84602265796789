import SHSearch from "../../component/SHSearch";
import {Col, Row, Table} from "react-bootstrap";
import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import Datatables from "../../component/Datatables";
import SHDatePicker from "../../../components/common/SHDatePicker";
import CustomDatePickerRange from "../../component/ui/DatePickerRange";
import axios from "axios";
import {toast} from "react-toastify";
import errorCodeDic from "../../../util/errorCodeDic";
import SHDatePicker2 from "../../../components/common/SHDatePicker2";
import {DateHelper} from "../../../helper/DateHelper";
import Select from "react-select";

interface IProps {
   dataList: any;
   riskyDataList: any;
}

const SensorTab1Page = (props: IProps) => {



    const fn_setSrchData = (e: any) => {


    }
    return(
        <>
            <div className={'ge-result'}>
                <div className={'font-bold mb-2'}>X, Y, Z의 값이 5.000을 넘어갈 경우 위험 경고가 발생됩니다.</div>
                <Datatables
                    datas={props.dataList}
                    scrollY="550px"
                    className="sh-fs-b4"
                    columns={[
                        {data: "valueX", title: "X값"},
                        {data: "valueY", title: "Y값"},
                        {data: "valueZ", title: "Z값"},
                        {data: "measureTime", title: "측정시간"}
                    ]}
                    index={true}
                    checked={false}
                    onLoad={(dt: any) => {
                        //setDatatable(dt)
                    }}

                    onClick={(data) => {

                    }
                    }
                />


            </div>

            <div className={'ge-result mt-3'}>
                <div className={'font-bold mb-2'}>위험 기록</div>
                <Datatables
                    datas={props.riskyDataList}
                    scrollY="550px"
                    className="sh-fs-b4"
                    columns={[
                        {data: "valueX", title: "X값"},
                        {data: "valueY", title: "Y값"},
                        {data: "valueZ", title: "Z값"},
                        {data: "measureTime", title: "측정시간"}
                    ]}
                    index={true}
                    checked={false}
                    onLoad={(dt: any) => {
                        //setDatatable(dt)
                    }}

                    onClick={(data) => {

                    }
                    }
                />


            </div>
        </>
    )

}

export default SensorTab1Page;
