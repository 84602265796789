import React, {useContext, useEffect, useRef, useState} from 'react';
import SubMenuTitle from "../component/SubMenuTitle";
import SHSearch from "../component/SHSearch";
import {Container, Tab, Table, Tabs} from "react-bootstrap";
import SHInput from "../../components/common/SHInput";
import Datatables,  {getCheckedItems}  from "../component/Datatables";
import {getLoginUser} from "../../helper/SecurityHelper";
import {UserContext} from "../../context/User";
import axios from "axios";
import SHSelect from "../../components/common/SHSelect";
import WrkPrmtRgstModal from "../siteSafetyManagement/modal/WrkPrmtRgstModal";
import RskAsmntRgstModal from "../siteSafetyManagement/modal/RskAsmntRgstModal";
import {toast, ToastContainer} from "react-toastify";
import errorCodeDic from "../../util/errorCodeDic";
import messageCodeDic from '../../util/messageCodeDic';
import { CmmnFn } from '../../helper/CmmnHelper';


type Props = {};

const ApprovalStatusManagementPage = (props: Props) => {

    const searchFormRef = useRef<any>();
    const cmpnySeqRef = useRef<any>();
    const [data, setData] = useState<any>();
    const srchTxtRef = useRef<any>();
    const defaultUser = getLoginUser();
    const {user} = useContext<any>(UserContext);
    const [gridTab, setGridTab] = useState<any>('wrkPrmt')
    const [wrkPrmtDatatable, setWrkPrmtDatatable] = useState<any>();
    const [rskAsmntDatatable, setRskAsmntDatatable] = useState<any>();
    const [cmpnyCodeList, setCmpnyCodeList] = useState<any>([]);
    const [detailData, setDetailData] = useState<any>(null);
    const [wrkModalIsOpen, setWrkModalIsOpen] = React.useState(false);
    const [rskModalIsOpen, setRskModalIsOpen] = React.useState(false);

    const [datatable, setDatatable] = useState<any>();
    const [modalRgstIsOpen, setModalRgstIsOpen] = React.useState(false);
    const [modalAlwaysRgstIsOpen, setModalAlwaysRgstIsOpen] = React.useState(false);
    const [wrkrModalIsOpen, setWrkrModalIsOpen] = React.useState(false);
    const [isCopy, setIsCopy] = useState<boolean>(false);

    
    const fn_copy = () => {
        var checkedData = getCheckedItems(datatable);

        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['007']);
            return;
        }

        if (checkedData.length > 1) {
            toast.warn('하나의 항목만 선택해 주십시오.');
            return;
        }
        console.log('checkedData: ', checkedData);

        checkedData[0].prcsStatCd = null;
        checkedData[0].prcsStatNm = null
        checkedData[0].rgstDt = null;
        checkedData[0].rgstrNm = null;
        checkedData[0].rgstrSeq = null;
        checkedData[0].wrkDt = CmmnFn.fn_getCurrentDate();
        checkedData[0].wrkStatNm = null;
        setDetailData(checkedData[0]);
        setIsCopy(true);
        if(checkedData[0].ptwTypeCd == 'S016001'){
            setModalRgstIsOpen(true);
        }else{
            setModalAlwaysRgstIsOpen(true);
        }
    }

    const fn_setSrchData = (e: any) => {

        if (e.target.name == 'state') {
            setData({
                ...data,
                ['state']: e.target.value,
                ['prcsGbCd']: e.target.value,
                ['prcsStatCd']: e.target.value
            })
            console.log(data);
        }else{
            setData({
                ...data,
                [e.target.name]: e.target.value
            })
        }
    }

    useEffect(() => {

        if (datatable) {
            datatable.draw();
        }

    }, [user, datatable])

    useEffect(() => {
        if (!modalRgstIsOpen && !wrkrModalIsOpen && !modalAlwaysRgstIsOpen) {
            setIsCopy(false);
            datatable?.draw();
        }

    }, [modalRgstIsOpen, wrkrModalIsOpen, modalAlwaysRgstIsOpen])

    useEffect(() => {
        fn_retrieveSrchCond(user?.blngSiteId || defaultUser.blngSiteId)
    }, [user])

    useEffect(() => {
        fn_search()
    },[gridTab])

    const fn_retrieveSrchCond = (siteId: number) => {
        fn_retrieveCmpnySiteDataList(defaultUser.blngSiteId).then((rsltData) => {
            setCmpnyCodeList(rsltData);
        });
    }

    const fn_retrieveCmpnySiteDataList = async (siteId: string) => {
        return new Promise(function (resolve, reject) {
            axios.post('/admin/api/company/retrieveCmpnySiteDataList', {
                siteId: siteId
            }).then((result) => {
                resolve(result.data);
            })
        });
    }

    const fn_search = () =>{
        if(gridTab == 'wrkPrmt'){
            wrkPrmtDatatable?.draw()
        }else if(gridTab == 'rskAsmnt'){
            rskAsmntDatatable?.draw()
        }else if(gridTab == 'contact'){
            //TODO PTW 설계 필요
            datatable?.draw()

        }
    }

    
    const fn_print = (e: any) =>{

        let param ={
            ptwId:e.ptwId
        }

        axios.post('/admin/api/ptw/createPtwPrint', param).then(rsltData => {
            console.log("process.env.REACT_APP_FILE_URL", process.env.REACT_APP_FILE_URL)
                window.open(process.env.REACT_APP_FILE_URL + "/shss-data/fileDownloadByFileName?fileName="+rsltData.data, "_blank")
            }
        ).catch(() => {
                toast.error(errorCodeDic['003'])
            }
        )
    }


    return (
        <Container fluid className="bg-white px-4 pt-4 sh-text-font_primary">
            <WrkPrmtRgstModal setModalIsOpen={setWrkModalIsOpen} modalIsOpen={wrkModalIsOpen}
                              siteId={user?.blngSiteId || defaultUser.blngSiteId}
                              detailData={detailData}/>
            <RskAsmntRgstModal setModalIsOpen={setRskModalIsOpen} modalIsOpen={rskModalIsOpen} siteId={user?.blngSiteId || defaultUser.blngSiteId}
                               detailData={detailData}/>

            <SubMenuTitle title={'나의결재현황'} parentMenu={'My Page'} />
            <SHSearch onSearch={() => fn_search()} formRef={searchFormRef} btnType={'p'}>
                <Table className="sh-font-SP sh-fs-b2 my-3">
                    <tbody>
                    <tr className="border-top border-bottom align-middle">
                        <th className="sh-bg-th" style={{width:"15%"}}>업체</th>
                        <td style={{width:"35%"}}>
                            <SHInput type={'hidden'} name={'siteId'} value={user?.blngSiteId || defaultUser.blngSiteId}></SHInput>
                            <SHInput type={'hidden'} name={'prcsGbCd'} value={data?.prcsGbCd}></SHInput>
                            <SHInput type={'hidden'} name={'prcsStatCd'} value={data?.prcsStatCd}></SHInput>
                            <SHInput type={'hidden'} name={'apprUserSeq'} value={defaultUser.userSeq}></SHInput>
                            <select className='form-select' name={'cmpnySeq'} ref={cmpnySeqRef}
                                    onChange={fn_setSrchData}>
                                <option value=''>전체</option>
                                {cmpnyCodeList.map((data: any, idx: number) => (
                                    <option key={idx} value={data.companySeq}>{data.companyName}</option>
                                ))}
                            </select>
                        </td>
                        <th className="sh-bg-th" style={{width:"15%"}}>상태</th>
                        <td style={{width:"35%"}}>
                            <SHSelect className={'form-select'} initOptionGb={'all'} name={'state'}
                                      onChange={fn_setSrchData} value={data?.state} codeGrpId={'S001'}></SHSelect>
                        </td>
                    </tr>

                    <tr className="border-top border-bottom align-middle">
                        <th className="sh-bg-th" style={{width:"15%"}}>검색어</th>
                        <td style={{width:"35%"}}>
                            <input type={'text'} name={'srchTxt'} className='form-control' ref={srchTxtRef}/>
                        </td>
                        <th className="sh-bg-th" style={{width:"15%"}}></th>
                        <td style={{width:"35%"}}></td>
                    </tr>
                    </tbody>
                </Table>
            </SHSearch>

            <div className={'ge-result'}>
                <Tabs
                    transition={true}
                    activeKey={gridTab}
                    onSelect={(k) => { setGridTab(k); console.log(k); }}
                    id="gridTab"
                    className="mb-3"
                >
                    <Tab eventKey="wrkPrmt" title="작업허가서">
                        <Datatables
                            dataUrl={'/admin/api/wrkPrmt/retrieveWrkPrmtDataList'}
                            parameterFormRef={searchFormRef}
                            columns={
                                [
                                    {
                                        data: "prcsStatNm", title: "상태", textAlign: 'center', render: (data: any) => {
                                            switch (data) {
                                                case '작성중':
                                                    return "<div class='sh-box-status writing'>작성중</div>";
                                                case '결재요청':
                                                    return "<div class='sh-box-status request'>결재요청</div>";
                                                case '결재중':
                                                    return "<div class='sh-box-status approval'>결재중</div>";
                                                case '결재완료':
                                                    return "<div class='sh-box-status approvaldone'>결재완료</div>";
                                                case '반려':
                                                    return "<div class='sh-box-status refuse'>반려</div>";
                                                default:
                                                    return "";
                                            }
                                        }
                                    },
                                    {data: "companyName", title: "업체", textAlign: 'center'},
                                    {data: "wrkTypeNm", title: "공종", textAlign: 'center'},
                                    {data: "wrkrCnt", title: "작업인원", textAlign: 'center'},
                                    {data: "wrkDt", title: "작업일", textAlign: 'center'},
                                    {data: "wrkTimeGbNm", title: "작업시간", textAlign: 'center'},
                                    {
                                        title: "프린트",
                                        type: "button",
                                        className: "text-center",
                                        buttons: [{
                                            label: "<img style='height: 20px' class='cursor-pointer' src='/icon_print.svg' />",
                                            onClick: (v: any) => {
                                                alert('개발중')
                                            },
                                        }],
                                    },
                                ]
                            }
                            checked={false}
                            index={true}

                            onLoad={(dt: any) => {
                                setWrkPrmtDatatable(dt)
                            }}
                            onClick={(data) => {
                                setDetailData(data)
                                setWrkModalIsOpen(true);
                            }}
                        />
                    </Tab>
                    <Tab eventKey="rskAsmnt" title="위험성평가서">
                        <Datatables
                            dataUrl={'/admin/api/rskAsmnt/retrieveRskAsmntDataList'}
                            parameterFormRef={searchFormRef}
                            columns={[
                                {data: "rskAsmntGbNm", title: "구분", textAlign: 'center'},
                                {
                                    data: "prcsStatNm", title: "상태", textAlign: 'center', render: (data: any) => {
                                        switch (data) {
                                            case '작성중':
                                                return "<div class='sh-box-status writing'>작성중</div>";
                                            case '결재요청':
                                                return "<div class='sh-box-status request'>결재요청</div>";
                                            case '결재중':
                                                return "<div class='sh-box-status approval'>결재중</div>";
                                            case '결재완료':
                                                return "<div class='sh-box-status approvaldone'>결재완료</div>";
                                            case '반려':
                                                return "<div class='sh-box-status refuse'>반려</div>";
                                            default:
                                                return "";
                                        }
                                    }
                                },
                                {data: "cmpnyNm", title: "업체", textAlign: 'center'},
                                {data: "workTypeNm", title: "공종", textAlign: 'center'},
                                {data: "raProd", title: "평가기간", textAlign: 'center'},
                                {data: "evlCntnts", title: "평가내용", textAlign: 'center'},
                                {data: "rgstrNm", title: "작성자", textAlign: 'center'},
                                {data: "rgstDt", title: "작성일", textAlign: 'center'},
                                {
                                    title: "프린트",
                                    type: "button",
                                    className: "text-center",
                                    buttons: [{
                                        label: "<img style='height: 20px' class='cursor-pointer' src='/icon_print.svg' />",
                                        onClick: (v: any) => {
                                            alert('개발중')
                                        },
                                    }],
                                },
                            ]}
                            checked={false}
                            index={true}

                            onLoad={(dt: any) => {
                                setRskAsmntDatatable(dt)
                            }}
                            onClick={(data) => {
                                setDetailData(data);
                                setRskModalIsOpen(data);
                            }}
                        />
                    </Tab>
                    <Tab eventKey="contact" title="사전작업허가제">
                        <Datatables
                        dataUrl={'/admin/api/ptw/retrievePTWDataList'}
                        parameterFormRef={searchFormRef}
                        columns={

                                [

                                    {data: "ptwTypeNm", title: "유형", textAlign: 'center'},
                                    {
                                        data: "prcsStatNm", title: "상태", textAlign: 'center', render: (data: any) => {
                                            switch (data) {
                                                case '작성중':
                                                    return "<div class='sh-box-status writing'>작성중</div>";
                                                case '결재요청':
                                                    return "<div class='sh-box-status request'>결재요청</div>";
                                                case '결재중':
                                                    return "<div class='sh-box-status approval'>결재중</div>";
                                                case '결재완료':
                                                    return "<div class='sh-box-status approvaldone'>결재완료</div>";
                                                case '반려':
                                                    return "<div class='sh-box-status refuse'>반려</div>";
                                                default:
                                                    return "";
                                            }
                                        }
                                    },
                                    {data: "companyName", title: "업체", textAlign: 'center'},
                                    {data: "wrkTypeNm", title: "공종", textAlign: 'center'},
                                    {data: "wrkrCnt", title: "작업인원", textAlign: 'center', render: (data: any, type: any, row: any, meta: any) => {
                                            if(row.ptwTypeCd == 'S016002'){
                                                return row.alwaysWrkWrkrCount;
                                            }else{
                                                return data;
                                            }
                                        }},
                                    {data: "wrkDt", title: "작업일", textAlign: 'center'},
                                    {data: "wrkTimeGbNm", title: "작업시간", textAlign: 'center', render: (data: any, type: any, row: any, meta: any) => {
                                            if(!data){
                                                return "-"
                                            }else{
                                                return data;
                                            }
                                        }},
                                    {
                                        title: "기능",
                                        type: "button",
                                        className: "text-center",
                                        buttons: [{
                                            label: "<img style='height: 20px' class='cursor-pointer' src='/icon_print.svg' />",
                                            onClick: (v: any) => {
                                                if(v.ptwTypeCd == 'S016001'){
                                                    fn_print(v);
                                                }else{
                                                    alert("출력이 불가능한 유형입니다.");
                                                }
                                            },
                                        }, {
                                            label: "<img style='height: 20px' class='cursor-pointer' src='/icon_user_plus.svg' />",
                                            onClick: (v: any) => {
                                                if(v.ptwTypeCd == 'S016001'){
                                                    setDetailData(v);
                                                    setWrkrModalIsOpen(true);
                                                    console.log(2, v)
                                                }else{
                                                    alert("근로자 등록이 불가능한 유형입니다.");
                                                }

                                            },
                                        }],
                                    },

                                ]
                        }
                        checked={true}
                        index={true}

                        onLoad={(dt: any) => {
                            setDatatable(dt)
                        }}
                        onClick={(data) => {
                            if(data.ptwTypeCd == 'S016001'){
                                setDetailData(data)
                                setModalRgstIsOpen(true);
                            }else if(data.ptwTypeCd == 'S016002'){
                                setDetailData(data);
                                setModalAlwaysRgstIsOpen(true);
                            }
                        }
                        }
                    />
                    </Tab>
                </Tabs>
            </div>
        </Container>
    );
};

export default ApprovalStatusManagementPage;
