import SHSearch from "../../component/SHSearch";
import {Col, Row, Table} from "react-bootstrap";
import SHSelect from "../../../components/common/SHSelect";
import {CmmnFn} from "../../../helper/CmmnHelper";
import React, {Dispatch, SetStateAction} from "react";
import Datatables from "../../component/Datatables";
import SiteWrkrMngPage from "../SiteWrkrMngPage";
import SHDatePicker from "../../../components/common/SHDatePicker";
import CustomDatePicker from "../../component/ui/DatePicker";

interface IProps {
    statData: any;
    dataList: any;
    srchData: any;
    setSrchData: Dispatch<SetStateAction<boolean>>;
    setSelData: Dispatch<SetStateAction<any>>;
    wrkDt: string;
    setWrkDt: Dispatch<SetStateAction<string>>;
    isDatePopupOpen: boolean;
    setIsDatePopupOpen: Dispatch<SetStateAction<boolean>>;

}

const OldWrkrStatPage = (props: IProps) => {

    return(
        <>
            <SHSearch btnType={'p'}>
                <Row>
                    <Col>
                        <Table className="sh-font-SP sh-fs-b2 my-3">
                            <tbody>
                            <tr className="border-top align-middle">
                                <th className="sh-bg-th" style={{width: '10%'}}>일자</th>
                                <td style={{width: '23%'}}>
                                    <SHDatePicker className={"text-center"} readOnly={true}
                                                  value={props.wrkDt} name={'wrkDt'} onClick={() => props.setIsDatePopupOpen(!props.isDatePopupOpen)}
                                                  calendar={() => props.setIsDatePopupOpen(!props.isDatePopupOpen)}
                                    />
                                    {props.isDatePopupOpen
                                        ? <div className='absolute top-155 right-80'>
                                            <CustomDatePicker setIsDatePopupOpen={props.setIsDatePopupOpen}
                                                              setDateView={props.setWrkDt}
                                            />
                                        </div>
                                        : null}
                                </td>
                                <th className="sh-bg-th" style={{width: '10%'}}>위치(구단위)</th>
                                <td style={{width: '23%'}}>
                                    <SHSelect initOptionGb={'all'} className={'form-select'} name={'guCd'} codeGb={'bjd'} value={props.srchData.guCd} onChange={e => CmmnFn.setFormData(e, props.srchData, props.setSrchData)} codeGrpId={'SEOUL'}></SHSelect>
                                </td>
                                <th className="sh-bg-th" style={{width:"10%"}}>검색어</th>
                                <td style={{width:"23%"}}>
                                    <input type={'text'} name={'srchTxt'} value={props.srchData.srchTxt} onChange={e => CmmnFn.setFormData(e, props.srchData, props.setSrchData)} className='form-control'/>
                                </td>

                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </SHSearch>

            <div className="rounded-[5px] border mb-3 pl-23">
                <Row>
                    <Col>
                        <span className="font-semibold text-sm">■ 고령 근로자 현황</span>
                    </Col>
                    <Col>
                    </Col>

                </Row>
                <Row style={{height: '70px'}}>
                    <Col>
                        <Row className="sh-bg-accent w-100 text-center sh-text-white py-1  mt-2 rounded-t-lg">
                            <Col>전체 근로자 수</Col>
                            <Col>고령 근로자 수</Col>
                            <Col>고령 근로자 비율</Col>
                        </Row>
                        <Row className="w-100 text-center py-1">
                            <Col>{props.statData.totWrkrCnt}</Col>
                            <Col>{props.statData.totWrkrCnt - props.statData.noWrkrCnt}</Col>
                            <Col>{props.statData.totWrkrCnt == 0 ? '0.0%' : ((props.statData.totWrkrCnt - props.statData.noWrkrCnt)/props.statData.totWrkrCnt * 100).toFixed(1) + ' %'}</Col>
                        </Row>
                    </Col>
                    <Col>

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <span className="font-semibold text-sm text-[#5685F7]">* 근로자수는 금일 출역한 근로자 수 기준입니다.</span>
                    </Col>
                    <Col>

                    </Col>

                </Row>
            </div>
            <div className={'ge-result'}>
                <Datatables
                    datas={props.dataList}
                    scrollY="550px"
                    className="sh-fs-b4"
                    columns={[
                        {data: "siteTypeNm", title: "본사공종"},
                        {data: "siteName", title: "현장명"},
                        {data: "guNm", title: "위치(구단위)"},
                        {data: 'prfmdCnt', title: '고령 근로자 수'},
                        {data: "entrCnt", title: "전체 근로자 수(금일 출역)"},

                    ]}
                    checked={false}
                    onLoad={(dt: any) => {
                        //setDatatable(dt)
                    }}

                    onClick={(data) => {
                        props.setSelData(Object.assign({}, data));
                    }
                    }
                />


            </div>
        </>
    )

}

export default OldWrkrStatPage;
