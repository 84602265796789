import React, {Dispatch, SetStateAction} from "react";
import exp from "constants";
import {Modal} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import restapi from "../../helper/restAPIHelper";
import {DateHelper} from "../../helper/DateHelper";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    title:string;
    src: string;
}

export default function SHPdfViewModal(props: IProps){

    const fn_openModal = () => {

    }

    const fn_closeModal = () => {
        props.setModalIsOpen(false);
    }

    return (
        <div>
            <Modal
                className="sh-modal sub" size="xl"
                show={props.modalIsOpen}
                onShow={fn_openModal}
                onHide={fn_closeModal}
            >

                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        {props.title}
                        <div className={'d-flex justify-content-between align-items-center'}>
                            <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <div>
                        <iframe src={props.src} className='w-100 min-h-[600px]'/>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
        )
}
