import axios, {AxiosResponse} from 'axios';
//prod
//local
const responseBody = (response: AxiosResponse) => response.data;
const requests = {
    get: (url: string) => axios.get(url).then(responseBody),
    post: (url: string, body: any, option: any) =>
        axios.post(url, body, option).then(responseBody),
    put: (url: string, body: object, option: any) =>
        axios.put(url, body, option).then(responseBody),
    delete: (url: string) => axios.delete(url).then(responseBody),
};

const ecardApi = {
    placeList: () => {
        return requests.get(
            '/admin/ecard/placeList'
        )
    },
    companyList: (deductionNo: string) => {
        return requests.post(
            '/admin/ecard/companyList', {deductionNo: deductionNo}, null
        )
    },
    workerList: (deductionNo?: string, licenseNo?: string, nationalityCd?: string) => {
        return requests.post(
            '/admin/ecard/workerList', {deductionNo: deductionNo, licenseNo: licenseNo, nationalityCd: nationalityCd}, null
        )
    },
    workList: (placeCd?: string, licenseNo?: string, nationalityCd?: string, startDate?: Date, endDate?: Date) => {
        return requests.post(
            '/admin/ecard/workList', {placeCd: placeCd, licenseNo: licenseNo, nationalityCd: nationalityCd, workStartDt: startDate, workEndDt: endDate}, null
        )
    },
    workerInfo: (workerSeq: number) => {
        return requests.post(
            '/admin/ecard/workerInfo', {workerSeq: workerSeq}, null
        )
    },
    nationalityNm: (nationalityCd: string) => {
        switch (nationalityCd){
            case "ETC": return "기타";
            case "NP": return "네팔";
            case "KO": return "대한민국";
            case "MN": return "몽골";
            case "MM": return "미얀마";
            case "VN": return "베트남";
            case "LK": return "스리랑카";
            case "UZ": return "우즈베키스탄";
            case "ID": return "인도네시아";
            case "CN": return "중국";
            case "KH": return "캄보디아";
            case "TH": return "태국";
            case "PH": return "필리핀";
            default: return "-";
        }
    },
};
export default ecardApi;
