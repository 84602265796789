import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from "axios";
import Datatables, {getCheckedItems} from "../component/Datatables";
import Modal from 'react-modal';
import {getLoginUser} from "../../helper/SecurityHelper";
import {toast} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import SHSelect from "../../components/common/SHSelect";
import {CmmnFn} from "../../helper/CmmnHelper";
import {ProgressContext} from "../../context/Progress";
import SHSearch from '../component/SHSearch';
import {Col, Container, Row, Table} from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";
import SiteSftyChkRgstModal from "./modal/SiteSftyChkRgstModal";
import SiteSftyChkRsltModal from "./modal/SiteSftyChkRsltModal";
import {UserContext} from "../../context/User";
import SHInput from "../../components/common/SHInput";
import SHDatePicker2 from "../../components/common/SHDatePicker2";
import {DateHelper} from "../../helper/DateHelper";

type Props = {};

Modal.setAppElement('#root');
const SiteSftyChkActPage = (props: Props) => {
    const [dataList, setDataList] = useState<any[]>([]);
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [rsltModalIsOpen, setRsltModalIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState(null);
    const [isSaveSuccess, setIsSaveSuccess] = useState(false);
    const [srchData, setSrchData] = useState<any>()
    const {spinner} = useContext(ProgressContext);
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const [startDate, setStartDate] = useState<any>(DateHelper.dateByMonth(-3));
    const [endDate, setEndDate] = useState<any>(DateHelper.dateByMonth(0));
    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);
    const defaultUser = getLoginUser();
    const prodRef = useRef<any>();
    const [prcsGb, setPrcsGb] = useState<any>(null);
    const [data, setData] = useState<any>()
    const {user} = useContext<any>(UserContext);

    useEffect(()=>{

        if(datatable){
            datatable.draw();
        }

    }, [user, datatable])

    useEffect(() => {
        if (!modalIsOpen) {
            datatable?.draw();
        }
    }, [modalIsOpen])


    const fn_setSearchData = (e: any) => {
        setSrchData({
            ...srchData,
            [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        if (!rsltModalIsOpen) {
            datatable?.draw();
        }
    }, [rsltModalIsOpen])

    const fn_search = (e?: any) => {
        if (e) {
            e.preventDefault()
        }
        datatable.draw();
        setIsSaveSuccess(false);
    }

    const fn_delete = () => {
        let checkedData = getCheckedItems(datatable,"sftyChkSeq")
        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }
        if(!window.confirm(messageCodeDic['004'])){
            return;
        }
        axios.post('/admin/api/siteSftyManage/deleteSftyWrkMetData', {delList: checkedData}).then(rsltData => {
            if (rsltData.data > 0) {
                toast.success(messageCodeDic['002'])
                fn_search();
            } else {
                toast.error(errorCodeDic['003'])
            }
        }).catch(() => {
            toast.error(errorCodeDic['003'])
        })
    }
    const fn_setFormData = (e: any) => {

        setData({
            ...data,
            [e.target.name]: e.target.value
        })

    }


    const fn_datePopupOpen = () => {
        setIsDatePopupOpen(!isDatePopupOpen);
    };

    function dateFormat(addMonth: number) {
        let today = new Date();
        let month = today.getMonth() + addMonth;
        let day = today.getDate();
        let monthFormat = month >= 10 ? month : '0' + month;
        let dayFormat = day >= 10 ? day : '0' + day;

        return today.getFullYear() + '-' + monthFormat + '-' + dayFormat ;
    }

    return (
        <Container fluid className="bg-white px-4 pt-4 sh-text-font_primary">
            <SubMenuTitle title={'현장안전점검'} parentMenu={'안전업무보고서 관리'}/>
            <SiteSftyChkRgstModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} prcsGb={prcsGb}
                                  detailData={detailData}/>
            <SiteSftyChkRsltModal setModalIsOpen={setRsltModalIsOpen} modalIsOpen={rsltModalIsOpen}
                                  detailData={detailData}/>
                <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                    <SHInput type={'hidden'} name={'isSiteMngrPov'} value={false}></SHInput>
                    <Row>
                        <Col>
                            <Table className="sh-font-SP sh-fs-b2 my-3">
                                <tbody>
                                <tr className="border-top border-bottom align-middle">
                                    <th className="sh-bg-th"  style={{width:'6%'}}>공종</th>
                                    <td style={{width:'19%'}}>
                                        <SHInput type={'hidden'} name={'siteId'} value={user?.blngSiteId || defaultUser.blngSiteId}></SHInput>
                                        <SHSelect initOptionGb={'all'} className={'form-select'} name={'workTypeCd'} codeGb={'cmmn'} codeGrpId={'T002'}
                                                  onChange={fn_setSearchData}
                                                  value={srchData?.workTypeCd}></SHSelect>
                                    </td>
                                    <th className="sh-bg-th" style={{width:'6%'}}>진행상황</th>
                                    <td style={{width:'19%'}}>
                                        <SHSelect initOptionGb={'all'} className={'form-select'} name={'sftyChkGbCd'} codeGb={'cmmn'} codeGrpId={'C003'}
                                                  onChange={fn_setSearchData}
                                                  value={srchData?.sftyChkGbCd}></SHSelect>
                                    </td>
                                    <th className="sh-bg-th" style={{width:'6%'}}>점검일시</th>
                                    <td style={{width:'35%'}}>
                                        <div className="d-flex align-items-center" ref={prodRef}>
                                            <SHDatePicker2 setter={setStartDate} name={'startSftyChkDt'} defaultValue={startDate} onChange={fn_setSearchData}/>
                                            <span>&nbsp;~&nbsp;</span>
                                            <SHDatePicker2 setter={setEndDate} name={'endSftyChkDt'} defaultValue={endDate} onChange={fn_setSearchData}/>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </SHSearch>

            <div className={'ge-result'}>
                <Datatables
                    dataUrl={'/admin/api/siteSftyManage/retrieveSiteSftyManageDataList'}
                    parameterFormRef={searchFormRef}
                    columns={[
                        {data: "actStatGbNm", title: "상태"},
                        {data: "dprtNm", title: "점검부서"},
                        {data: "sftyChkRsltRgstDt", title: "점검결과 등록일"},
                        {data: "workTypeNm", title: "공종"},
                        {data: "sftyChkDt", title: "점검일"},
                        {data: "actCmpltDt", title: "조치 완료일"},
                    ]}
                    checked={false}
                    index={true}

                    onLoad={(dt: any) => {
                        setDatatable(dt)
                    }}
                    onClick={(data, gb) => {

                        setPrcsGb('actRgst');
                        setDetailData(data)
                        setModalIsOpen(true);
                        //점검예정일 경우 수정팝업 호출


                        /* if(data.sftyChkGbCd == "C003002") {
                             setDetailData(data)
                             setModalIsOpen(true);
                             fn_updateViewCnt(data);
                         } else { // 점검중 또는 점검완료일 경우 점검결과 팝업 호출
                             setDetailData(data)
                             setRsltModalIsOpen(true);
                             fn_updateViewCnt(data);
                         }*/
                    }
                    }
                />
            </div>
        </Container>
    );
};

export default SiteSftyChkActPage;
