import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import Datatables, {getCheckedItems} from "../../component/Datatables";

import SHSearch from "../../component/SHSearch";
import SHInput from "../../../components/common/SHInput";
import restapi from "../../../helper/restAPIHelper";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    wrkrDataList: object[];
    title: string;
    gbCd: string;

}

const $ = require("jquery")

const InOutStatPerSiteInfoListModal = (props: IProps) => {
    const [datatable, setDatatable] = useState<any>();

    const [isCodeDataLoaded, setIsCodeDataLoaded] = useState<any>(null);
    const searchFormRef = useRef<any>();
    const [dataList, setDataList] = useState<any>([]);
    const [orgnDataList, setOrgnDataList] = useState<any>([]);
    const [columns, setColumns] = useState([
        {data: "siteName", title: "현장명", textAlign: 'center'},
        {data: "cnt1", title: "근로자 출역 현황", textAlign: 'center'},
        {data: "cnt2", title: "관리자 출역 현황", textAlign: 'center'},
    ])

    useEffect(() => {
        if (isCodeDataLoaded) {
            fn_search();
        }
    }, [isCodeDataLoaded]);

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_search = (e?: any) => {
        e?.preventDefault()
        datatable?.draw()
    }

    const fn_onShow = () => {
        if(props.gbCd == 'A'){
            setColumns([
                {data: "siteName", title: "현장명", textAlign: 'center'},
                {data: "cnt1", title: "근로자 출역 현황", textAlign: 'center'},
                {data: "cnt2", title: "관리자 출역 현황", textAlign: 'center'},
            ]);
        } else if(props.gbCd == 'B'){
            setColumns([
                {data: "siteName", title: "현장명", textAlign: 'center'},
                {data: "cnt1", title: "내국인 출역 현황", textAlign: 'center'},
                {data: "cnt2", title: "외국인 출역 현황", textAlign: 'center'},
            ]);
        } else if(props.gbCd == 'C'){
            setColumns([
                {data: "siteName", title: "현장명", textAlign: 'center'},
                {data: "cnt1", title: "고령자 출역 현황", textAlign: 'center'},
                {data: "cnt2", title: "그 외 출역 현황", textAlign: 'center'},
            ]);
        }

        setDataList(props.wrkrDataList);
        setOrgnDataList(props.wrkrDataList);
    }

    return (
        <div>

            <Modal
                className="sh-modal" size="xl"
                show={props.modalIsOpen}
                onShow={() => {
                    fn_onShow()

                }}
                onHide={fn_closeModal}
            >


                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        {props.title}
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">

                    <div className={'ge-result'}>
                        <Datatables
                            datas={dataList}
                            scrollY="550px"
                            className="sh-fs-b4"
                            parameterFormRef={searchFormRef}
                            columns={columns}
                            checked={false}
                            onLoad={(dt: any) => {
                                setDatatable(dt)
                            }}

                            onClick={(data) => {

                            }
                            }
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>

                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default InOutStatPerSiteInfoListModal;
