import React, {useContext, useEffect, useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import IconClock from '../../assets/images/ico/icon-clock.png';
import IconWthrShiny from '../../assets/images/ico/icon-weather-a-01.png';
import IconWthrCldy from '../../assets/images/ico/icon-weather-a-03.png';
import IconWthrCld from '../../assets/images/ico/icon-weather-a-04.png';
import restapi from "../../helper/restAPIHelper";
import {UserContext} from "../../context/User";
import {getLoginUser} from "../../helper/SecurityHelper";
import {SiteContext} from "../../context/Site";
import {CmmnFn} from "../../helper/CmmnHelper";
import {Constatnts} from "../../util/Constants";
import {TopMenuContext} from "../../context/TopMenu";

const DashWeather = () => {
    const [date, setDate] = useState(() => new Date());
    const week = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    let dayOfWeek = week[date.getDay()];
    const [pm10Grade, setPm10Grade] = useState<string|null>(null);
    const [pm10Color, setPm10Color] = useState<any|null>(null);
    const [pm25Grade, setPm25Grade] = useState<string|null>(null);
    const [pm25Color, setPm25Color] = useState<any|null>(null);
    const [wthrInfo, setWthrInfo] = useState<any>({});
    const {user} = useContext<any>(UserContext);
    const defaultUser = getLoginUser();
    const {site} = useContext<any>(SiteContext);
    const {menu} = useContext<any>(TopMenuContext);

    useEffect(() => {
        const timeId = setInterval(() => tick(), 1000)
        let siteId = user?.blngSiteId || defaultUser.blngSiteId

        console.log('wthr site: ', site);
        if (site) {
            siteId = site.siteId
        }

        console.log('CmmnFn.isSmSite(): ', CmmnFn.isSmSite());
        if(CmmnFn.isSmSite()){
            siteId = Constatnts.defaultSiteId;
        }

        if(menu == 'mainControl'){
            siteId = Constatnts.defaultCntrlSiteId;
        }

        // 날씨 정보 가져오기
        restapi.retrieveWtherInfoData({}).then((rsltData) => {
            setWthrInfo(rsltData);
        })

        return () => {
            clearInterval(timeId)
        }


    }, [user, site])
    useEffect(() => {
        var pm10Value = wthrInfo.pm10
        if(10 <= pm10Value && pm10Value <= 30){
            setPm10Grade("좋음")
            setPm10Color("#1c67d7")
        }else if(31 <= pm10Value && pm10Value <= 80){
            setPm10Grade("보통")
            setPm10Color("#01b56e")
        }else if(81 <= pm10Value && pm10Value <= 150){
            setPm10Grade("나쁨")
            setPm10Color("#f9c700")
        }else if(151 <= pm10Value){
            setPm10Grade("매우나쁨")
            setPm10Color("#e53030")
        }
        var pm25Value = wthrInfo.pm25
        if(10 <= pm25Value && pm25Value <= 15){
            setPm25Grade("좋음")
            setPm25Color("#1c67d7")
        }else if(16 <= pm25Value && pm25Value <= 35){
            setPm25Grade("보통")
            setPm25Color("#01b56e")
        }else if(36 <= pm25Value && pm25Value <= 75){
            setPm25Grade("나쁨")
            setPm25Color("#f9c700")
        }else if(75 <= pm25Value){
            setPm25Grade("매우나쁨")
            setPm25Color("#e53030")
        }
    }, [wthrInfo])
    const tick = () => {
        setDate(new Date())
    }

    return (
        <>
            <Container className="sh-bg-pane rounded-[5px] py-2 px-3 mb-3">
                <Row className="sh-fw-5 p-0">
                    <Col bsPrefix="col-5" className="pe-0">
                        <div className="d-flex align-items-center justify-content-center w-100 h-100">
                            <span className="sh-fs-b1">{date.toLocaleDateString()} {dayOfWeek}</span>
                        </div>
                    </Col>
                    <Col bsPrefix="col-7" className="ps-0">
                        <div className="d-flex align-items-center justify-content-center w-100 h-100">
                            <img className="mr-3 mb-1" src={IconClock} style={{width: '22px', height: '22px'}}
                                 alt="시계"/>
                            <span className="sh-fs-h2" style={{
                                //@ts-ignore
                                whiteSpace: "-1px"}}>{date.toLocaleTimeString()}</span>
                        </div>
                    </Col>
                </Row>
                <Row className="p-0">
                    <Col bsPrefix="col-3" className="d-flex align-items-center justify-content-center"
                         style={{height: '8vh'}}>
                        {[1, 3, 4].includes(wthrInfo.sky) &&
                            <img style={{height: '100%'}}
                                 src={wthrInfo.sky == 1 ? IconWthrShiny : wthrInfo.sky == 3 ? IconWthrCld : wthrInfo.sky == 4 ? IconWthrCldy : ''}/>
                        }
                    </Col>
                    <Col bsPrefix="col-3" className="d-flex align-items-center justify-content-center shss-fw-5">
                        <div className="sh-fs-h1 sh-fw-4">{wthrInfo.t1h != undefined && wthrInfo.t1h != null ? (wthrInfo.t1h + '°') : ''}</div>
                    </Col>
                    <Col bsPrefix="col-6" className="d-flex align-items-center">
                        <div>
                            <div
                                className="sh-fs-b2 sh-fw-5 sh-lh-b2">{wthrInfo.sky == 1 ? '맑음' : wthrInfo.sky == 3 ? '구름많음' : wthrInfo.sky == 4 ? '흐림' : ''}</div>
                            <div
                                className="sh-text-font_secondary02 sh-fs-b3 sh-fw-4">{wthrInfo.t1h ? '어제보다 1° 낮아요' : ''}</div>
                        </div>
                    </Col>
                </Row>
                <Row className="p-0 mb-1">
                    {wthrInfo &&
                        <>
                            <Col className="text-center">
                                <div className="sh-text-font_secondary02 sh-fs-b4">풍향</div>
                                <div className="sh-fw-5 sh-fs-b2">{wthrInfo.wsd != undefined && wthrInfo.wsd != null ? (wthrInfo.wsd + 'm/s') : ''}</div>
                            </Col>
                            <Col className="text-center">
                                <div className="sh-text-font_secondary02 sh-fs-b4">습도</div>
                                <div className="sh-fw-5 sh-fs-b2">{wthrInfo.reh != undefined && wthrInfo.reh != null ? (wthrInfo.reh + '%') : ''}</div>
                            </Col>

                            <Col className="text-center">
                                <div className="sh-text-font_secondary02 sh-fs-b4">미세먼지</div>
                                <div className="sh-fw-5 sh-fs-b2" style={{color:pm10Color}}>{pm10Grade}</div>
                            </Col>
                            <Col className="text-center">
                                <div className="sh-text-font_secondary02 sh-fs-b4 text-nowrap">초미세먼지</div>
                                <div className="sh-fw-5 sh-fs-b2" style={{color:pm25Color}}>{pm25Grade}</div>
                            </Col>
                        </>
                    }

                    {!wthrInfo &&
                        <Col className="text-center">
                            <div className="sh-text-font_secondary02 sh-fs-h2">현장 날씨정보가 없습니다.</div>

                        </Col>
                    }

                </Row>
            </Container>
        </>
    );
};

export default DashWeather;

