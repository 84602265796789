import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {COMMON_MODAL_STYLE} from "../../../helper/StyleHelper";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import SHSelect from "../../../components/common/SHSelect";
import {getLoginUser} from "../../../helper/SecurityHelper";
import SHSearch from "../../component/SHSearch";
import SHInput from "../../../components/common/SHInput";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData?: any;
    wrkrDataList: object[];
    setWrkrDataList: Dispatch<SetStateAction<object[]>>;
    isSingleCheck?: boolean
    siteNm?: string
    siteId: number
    cmpnySeq: number

}

const $ = require("jquery")

const WrkrSrchModal = (props: IProps) => {
    const [datatable, setDatatable] = useState<any>();
    const searchFormRef = useRef<any>();
    const user = getLoginUser();
    const [isCodeDataLoaded, setIsCodeDataLoaded] = useState<any>(null);
    const [wrkrDataList, setWrkrDataList] = useState<any>([props.wrkrDataList]);
    const [userSeqList, setUserSeqList] = useState<number[]>([]);
    const [userName, setUserName] = useState<string>();

    useEffect(() => {
        if(isCodeDataLoaded){
            fn_search();
        }
    }, [isCodeDataLoaded]);

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_search = (e?: any) => {
        e?.preventDefault()
        datatable?.draw()
    }
    const fn_select = () => {
        if (wrkrDataList.length == 0) {
            toast.warn('작업자를 선택해 주십시오.');
            return;
        }

        props.setWrkrDataList(wrkrDataList);
        props.setModalIsOpen(false);
    }

    const fn_onShow = () => {
        let userSeqList:any = []
        props.wrkrDataList.map((data: any) => {
            userSeqList.push(data.userSeq);
        })
        setWrkrDataList(props.wrkrDataList);
        setUserSeqList(userSeqList);
        setIsCodeDataLoaded(false);

    }

    return (
        <div>

            <Modal
                className="sh-modal sub" size="xl"
                show={props.modalIsOpen}
                onShow={() => {
                    fn_onShow()

                }}
                onHide={fn_closeModal}
            >


                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        작업자 추가
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">

                        <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                            <SHInput type={'hidden'} name={'siteId'} value={props.siteId}></SHInput>
                            <SHInput type={'hidden'} name={'authCode'} value={'siteWrkr'}></SHInput>
                            <Row>
                                <Col>
                                    <Table className="sh-font-SP sh-fs-b2 my-3">
                                        <tbody>
                                        <tr className="border-top align-middle">
                                            <th className="sh-bg-th" style={{width: '15%'}}>업체명</th>
                                            <td style={{width: '35%'}}>
                                                <SHSelect initOptionGb={'none'} name={'cmpnySeq'} className={'form-select'}
                                                          selParam={{siteId: props.siteId, cmpnySeq: props.cmpnySeq}}
                                                          disabled={true} codeGb={'siteCmpny'}
                                                          setIsCodeDataLoaded={setIsCodeDataLoaded}
                                                ></SHSelect>
                                            </td>
                                            <th className="sh-bg-th" style={{width: '15%'}}>공종</th>
                                            <td style={{width: '35%'}}>
                                                <SHSelect initOptionGb={'all'} name={'workTypeCd'}  className={'form-select'}
                                                          selParam={{siteId: props.siteId, manageCode: 'M001001'}}
                                                          codeGb={'siteMngCd'}
                                                ></SHSelect>
                                            </td>
                                        </tr>
                                        <tr className="border-top align-middle">
                                            <th className="sh-bg-th" style={{width: '15%'}}>직종</th>
                                            <td style={{width: '35%'}}>
                                                <SHSelect initOptionGb={'all'} name={'ocptCd'}  className={'form-select'}
                                                          selParam={{siteId: props.siteId, manageCode: 'M001002'}}
                                                          codeGb={'siteMngCd'}
                                                ></SHSelect>
                                            </td>
                                            <th className="sh-bg-th" style={{width: '15%'}}>이름</th>
                                            <td style={{width: '35%'}}>
                                                <SHInput type="text" className="form-control" name={'userName'}
                                                         onChange={(e)=>{
                                                             setUserName(e.target.value)
                                                         }} value={userName}/>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Col>

                            </Row>
                        </SHSearch>
                    <div className={'ge-result'}>
                        <Datatables
                            dataUrl={'/admin/api/user/retrieveUserDataListWithView'}
                            parameterFormRef={searchFormRef}
                            checkedDataList={userSeqList}
                            pk={'userSeq'}
                            columns={[
                                {data: "siteWrkTpNm", title: "공종", textAlign: 'center'},
                                {data: "siteOcptNm", title: "직종", textAlign: 'center'},
                                {data: "userName", title: "이름", textAlign: 'left'},
                                {data: "userPhone", title: "전화번호", textAlign: 'center'},
                                {data: "regDate", title: "등록일", textAlign: 'center'},
                                {data: "sgnfcnt", title: "특이사항", textAlign: 'center'},
                            ]}
                            checked={true}
                            isSingleCheck={props.isSingleCheck}
                            onLoad={(dt: any) => {
                                setDatatable(dt)
                            }}
                            onAllCheckedClick={(datas: any, checked: any)=>{
                                console.log(datas)
                                console.log('checked: ', checked);
                                let cstmUserSeqList = userSeqList;
                                let cstmWrkrDataList = wrkrDataList;

                                if(checked){
                                    datas.map((data: any) => {
                                        if(cstmUserSeqList.indexOf(data.userSeq) == -1){
                                            cstmUserSeqList.push(data.userSeq);
                                            cstmWrkrDataList.push(data);
                                        }
                                    })
                                }else{
                                    datas.map((data: any) => {
                                        const idx = cstmUserSeqList.indexOf(data.userSeq);

                                        if(cstmUserSeqList.indexOf(data.userSeq) > -1){
                                            cstmUserSeqList.splice(idx, 1);
                                            cstmWrkrDataList.splice(idx, 1);
                                        }
                                    })
                                }
                            }}
                            onClick={(data) => {
                                console.log('on clicked');
                                let cstmUserSeqList = userSeqList;
                                let cstmWrkrDataList = wrkrDataList;
                                const idx = cstmUserSeqList.indexOf(data.userSeq);

                                if(data.isChecked){
                                    if(idx == -1){
                                        cstmUserSeqList.push(data.userSeq)
                                        cstmWrkrDataList.push(data);

                                    }
                                }else{
                                    if(idx > -1){
                                        cstmUserSeqList.splice(idx, 1);
                                        cstmWrkrDataList.splice(idx, 1);
                                    }
                                }
                                // @ts-ignore
                                setUserSeqList([].concat(cstmUserSeqList));
                                setWrkrDataList([].concat(cstmWrkrDataList));

                            }
                            }
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <button onClick={fn_select}
                                className='sh-btn pill p'>선택

                        </button>
                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default WrkrSrchModal;
