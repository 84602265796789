import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import {ReactComponent as IconRight} from "../../assets/images/ico/icon-right.svg";
import {ArcElement, Chart as ChartJS, Tooltip} from "chart.js";
import {Doughnut} from "react-chartjs-2";
import {ReactComponent as IconTeam} from "../../assets/images/ico/icon-team.svg";
import {ReactComponent as IconPerson} from "../../assets/images/ico/icon-person.svg";
import {ReactComponent as IconTeamOut} from "../../assets/images/ico/icon-teamout.svg";
import {UserContext} from "../../context/User";
import {getLoginUser} from "../../helper/SecurityHelper";
import restapi from "../../helper/restAPIHelper";
import {toast} from "react-toastify";
import errorCodeDic from "../../util/errorCodeDic";
import WrkrInfoListModal from "./modal/WrkrInfoListModal";
import {useNavigate} from "react-router-dom";
import {MenuOpenContext} from "../../context/MenuOpen";
import {Constatnts} from "../../util/Constants";

ChartJS.register(ArcElement, Tooltip);

interface IProps {
    etrncStatData: any;
}

const DashTotalDepartureStatus = (props: IProps) => {
    const {open} = useContext(MenuOpenContext);
    const {user} = useContext<any>(UserContext);
    const defaultUser = getLoginUser();
    const chartRef1 = useRef<any>();
    const chartRef2 = useRef<any>();
    const [data, setData] = useState<any>({totEtrncCnt:0, curCnt:0, outCnt:0})
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [title, setTitle] = useState<string>('');
    const [wrkrDataList, setWrkrDataList] = useState<any[]>([]);
    const navigate = useNavigate();
    const [tabGb, setTabGb] = useState<string>('');
    const [employeeData, setEmployeeData] = useState<any>({
        labels: ['중점관리구역', '중점관리구역 외', '고령자'],
        datasets: [
            {
                data: [75, 320, 50],
                backgroundColor: [
                    'rgba(86, 133, 247, 1)',
                    'rgba(192, 211, 255, 1)',
                    'rgba(48, 228, 199, 1)',
                ],
                borderColor: [
                    'rgba(30, 31, 35, 1)',
                    'rgba(30, 31, 35, 1)',
                    'rgba(30, 31, 35, 1)',
                ],
                borderWidth: 1,
                hoverOffset: 10,
            }
        ]
    });
    const [managerData, setManagerData] = useState<any>({
        labels: ['중점관리구역', '중점관리구역 외', '고령자'],
        datasets: [
            {
                data: [4, 20, 0],
                backgroundColor: [
                    'rgba(86, 133, 247, 1)',
                    'rgba(192, 211, 255, 1)',
                    'rgba(48, 228, 199, 1)',
                ],
                borderColor: [
                    'rgba(30, 31, 35, 1)',
                    'rgba(30, 31, 35, 1)',
                    'rgba(30, 31, 35, 1)',
                ],
                borderWidth: 1,
                hoverOffset: 5,
            }
        ]
    })

    useEffect(() => {
        if(props.etrncStatData){
            const rsltData = props.etrncStatData
            const wrkrTotDataList = rsltData.wrkrTotDataList;
            const wrkrDprtDataList = rsltData.wrkrDprtDataList;
            const wrkrVulnarableDataList = rsltData.wrkrVulnarableDataList;
            const mngrTotDataList = rsltData.mngrTotDataList
            const mngrDprtDataList = rsltData.mngrDprtDataList
            const mngrVulnarableDataList = rsltData.mngrVulnarableDataList;

            setEmployeeData(
                {
                    labels: ['중점관리구역', '중점관리구역 외', '고령자'],
                    datasets: [
                        {
                            data: [wrkrDprtDataList.length, wrkrTotDataList.length - wrkrDprtDataList.length, wrkrVulnarableDataList.length],
                            backgroundColor: [
                                'rgba(86, 133, 247, 1)',
                                'rgba(192, 211, 255, 1)',
                                'rgba(48, 228, 199, 1)',
                            ],
                            borderColor: [
                                'rgba(30, 31, 35, 1)',
                                'rgba(30, 31, 35, 1)',
                                'rgba(30, 31, 35, 1)',
                            ],
                            borderWidth: 1,
                            hoverOffset: 10,
                        }
                    ]
                }
            )

            setManagerData({
                labels: ['중점관리구역', '중점관리구역 외', '고령자'],
                datasets: [
                    {
                        data: [mngrDprtDataList.length, mngrTotDataList.length - mngrDprtDataList.length, mngrVulnarableDataList.length],
                        backgroundColor: [
                            'rgba(86, 133, 247, 1)',
                            'rgba(192, 211, 255, 1)',
                            'rgba(48, 228, 199, 1)',
                        ],
                        borderColor: [
                            'rgba(30, 31, 35, 1)',
                            'rgba(30, 31, 35, 1)',
                            'rgba(30, 31, 35, 1)',
                        ],
                        borderWidth: 1,
                        hoverOffset: 5,
                    }
                ]
            })
            rsltData.statTotWrkrCnt = wrkrTotDataList.length;
            rsltData.statTotMngrCnt = mngrTotDataList.length;

            setData(rsltData);
        }
    }, [props]);


    useEffect(() => {
        chartRef1.current.resize()
        chartRef2.current.resize()
    },[open])

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            emptyDoughnut: {
                color: 'rgba(255,255,0, 0.5)',
                width: 20,
                radiusDecrease: 10,
            }
        },
        rotation: -90,
        circumference: 180,
        cutout: '70%',
    };

    useEffect(() => {
        if(tabGb) {
            setModalIsOpen(true);
        }
    }, [tabGb]);

    const fn_openWrkrInfoModal = (srchGb: string) => {

        if(srchGb == 'tot'){
            setWrkrDataList(data.totWrkrList);
            setTitle('총 출역')
        }else if(srchGb == 'crnt'){
            setWrkrDataList(data.curWrkrList);
            setTitle('현재 인원')
        }else if(srchGb == 'out'){
            setWrkrDataList(data.outWrkrList);
            setTitle('OUT 인원')
        }else if(srchGb == 'wrkr'){
            setWrkrDataList(data.wrkrTotDataList);
            setTitle('근로자 출역 현황')
        }else if(srchGb == 'mngr'){
            setWrkrDataList(data.mngrTotDataList);
            setTitle('관리자 출역 현황')
        }
        setTabGb(srchGb);
    }

    return (
        <>
            <Container fluid className="sh-bg-pane rounded-[5px] py-2 px-3">
                <WrkrInfoListModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen}  wrkrDataList={wrkrDataList} siteId={user?.blngSiteId || defaultUser.blngSiteId} title={title} tabGb={tabGb} ></WrkrInfoListModal>
                <Row className="px-0 mb-2">
                    <Col bsPrefix="col-7" className="d-flex align-items-center w-100">
                        <div className="w-100 d-flex align-items-center justify-content-between">
                            <span className="pe-2 sh-fs-h4 sh-fw-5 sh-font-SD">총 출역현황</span>
                            <div className={'cursor-pointer'} onClick={() => navigate('/manPowerManagement/departureStatusManagementPage')}>
                            <IconRight  style={{width: '20px', marginBottom: '1px'}} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex align-items-center">
                        <Container className="px-1 pt-3">
                            <Row>
                                <Col>
                                    <Container fluid className="px-0 cursor-pointer" onClick={() => fn_openWrkrInfoModal('tot')}>
                                        <Row>
                                            <Col bsPrefix="col-5"
                                                 className="d-flex justify-content-center align-items-center pe-1">
                                                <div
                                                    className="d-flex rounded-circle justify-content-center sh-bg-secondary01"
                                                    style={{width: '35px', height: '35px'}}>
                                                    <IconTeam className="sh-fill-white cursor-pointer" style={{width: '24px'}}/>
                                                </div>
                                            </Col>
                                            <Col bsPrefix="col-7" className="ps-1">
                                                <div className="w-100 sh-fs-b3 sh-lh-h2">
                                                    <div className="sh-text-secondary01">총 출역</div>
                                                    <div>
                                                        <span className="sh-fw-5 sh-fs-h2">{data.totEtrncCnt}</span>
                                                        <span>명</span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                                <Col className="px-0">
                                    <Container fluid className="px-0 cursor-pointer"  onClick={() => fn_openWrkrInfoModal('crnt')}>
                                        <Row>
                                            <Col bsPrefix="col-5"
                                                 className="d-flex justify-content-center align-items-center pe-1">
                                                <div
                                                    className="d-flex rounded-circle justify-content-center sh-bg-secondary04"
                                                    style={{width: '35px', height: '35px'}}>
                                                    <IconPerson className="sh-fill-white" style={{width: '24px'}}/>
                                                </div>
                                            </Col>
                                            <Col bsPrefix="col-7" className="ps-1">
                                                <div className="w-100 sh-fs-b3 sh-lh-h2">
                                                    <div className="sh-text-secondary04 text-nowrap">현재 인원</div>
                                                    <div>
                                                        <span className="sh-fw-5 sh-fs-h2">{data.curCnt}</span>
                                                        <span>명</span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                                <Col>
                                    <Container fluid className="px-0 cursor-pointer" onClick={() => fn_openWrkrInfoModal('out')}>
                                        <Row>
                                            <Col bsPrefix="col-5"
                                                 className="d-flex justify-content-center align-items-center pe-1">
                                                <div
                                                    className="d-flex rounded-circle justify-content-center sh-bg-secondary02"
                                                        style={{width: '35px', height: '35px'}}>
                                                    <IconTeamOut className="sh-fill-white" style={{width: '24px'}}/>
                                                </div>
                                            </Col>
                                            <Col bsPrefix="col-7" className="ps-1">
                                                <div className="w-100 sh-fs-b3 sh-lh-h2">
                                                    <div className="sh-text-secondary02 text-nowrap">OUT 인원</div>
                                                    <div>
                                                        <span className="sh-fw-5 sh-fs-h2">{data.outCnt}</span>
                                                        <span>명</span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                            </Row>
                            <Row>
                                <Col bsPrefix={"col-6"}>
                                    <div onClick={() => fn_openWrkrInfoModal('wrkr')} className="cursor-pointer d-flex position-relative justify-content-center w-100">
                                        <Doughnut className="position-relative z-50" options={options}
                                                  data={employeeData} width="100%" height="100%" ref={chartRef1} />
                                        <div className="position-absolute w-100 text-center z-10 left-0" style={{bottom:"20px"}}>
                                            <div className="sh-fs-b2 sh-lh-h3">근로자</div>
                                            <div>
                                                <span className="sh-fw-6 sh-fs-h3 me-1">{data.statTotWrkrCnt}</span>
                                                <span className="sh-fs-b3 sh-text-font_secondary02">명</span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col bsPrefix={"col-6"}>
                                    <div onClick={() => fn_openWrkrInfoModal('mngr')} className="cursor-pointer d-flex position-relative justify-content-center w-100">
                                        <Doughnut className="position-relative z-50" options={options}
                                                  data={managerData} width="100%" height="100%"  ref={chartRef2}/>
                                        <div className="position-absolute w-100 text-center z-10 left-0" style={{bottom:"20px"}}>
                                            <div className="sh-fs-b2 sh-lh-h3">관리자</div>
                                            <div>
                                                <span className="sh-fw-6 sh-fs-h3 me-1">{data.statTotMngrCnt}</span>
                                                <span className="sh-fs-b3 sh-text-font_secondary02">명</span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="pb-2">
                                <Col className="d-flex justify-content-center w-100 sh-fs-b4">
                                    <div>
                                        <span className="pe-1 sh-text-secondary03">●</span>
                                        <span className="pe-1">중점관리구역</span>
                                    </div>
                                    <div className="px-2">
                                        <span className="pe-1 sh-text-secondary05">●</span>
                                        <span className="pe-1">중점관리구역 외</span>
                                    </div>
                                    <div>
                                        <span className="pe-1 sh-text-secondary01">●</span>
                                        <span className="pe-1">고령자</span>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default DashTotalDepartureStatus;

