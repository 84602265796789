import {Outlet, Route, Routes, useNavigate} from "react-router-dom";
import {getLoginUser} from "../helper/SecurityHelper";
import React, {useContext, useEffect} from "react";
import {ProgressContext} from "../context/Progress";
import {Box} from "@mui/material";
import {ToastContainer} from "react-toastify";
import Menubar from "../components/common/Menubar";
import Login from "../pages/Login";
import MainLayout from "../components/layout/MainLayout";
import {routes, smSiteRoutes} from "./index";
import HeadOfficeMonitoring from "../pages/headoffice/HeadScreenRoot";
import {UserContext} from "../context/User";
import HeadScreenRoot from "../pages/headoffice/HeadScreenRoot";

const MainRoutes = () => {
    const defaultUser = getLoginUser()
    const {user, setUser} = useContext<any>(UserContext);
    const navigate = useNavigate();

    return (
        <Routes>
            <Route path="/login" element={<Login/>}/>

            {defaultUser ? ((user?.authGrpId || defaultUser.authGrpId) == 'SITE' ? <>
                        {(user?.isSmSite || defaultUser.isSmSite)
                            ? <><Route path="/smSite" element={<MainLayout isSmSite={true}/>}>
                                {smSiteRoutes}
                            </Route>
                                <Route path="/" element={<Login/>}/>
                            </> :
                            <Route path="/" element={<MainLayout isSmSite={false}/>}>
                                {routes}
                            </Route>

                        }


                    </> :
                    <Route path="/" element={<HeadScreenRoot/>}>
                    </Route>
            ) : (<Route path="/" element={<Login/>}/>)

            }


        </Routes>

    );
};

export default MainRoutes;
