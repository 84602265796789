import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";

import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {fn_getCurrentDate} from "../../../helper/CmmnHelper";
import {getLoginUser} from "../../../helper/SecurityHelper";




interface IProps {
    detailData: any;
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
}

const IncgrtyPicModal = (props: IProps) => {

    const currentDate = fn_getCurrentDate();
    const user = getLoginUser();

    const [data, setData] = useState<any>()



    function afterOpenModal() {

    }

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }


    return (
        <div>

            <Modal
                className="sh-modal" size="lg"
                show={props.modalIsOpen}
                onShow={afterOpenModal}
                onHide={fn_closeModal}

            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        {props.detailData.title}
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">
                    <img
                        src={process.env.REACT_APP_FILE_URL + "/shss-data/fileDownload?attachSeq=" + props.detailData.fileId + '&sn=0&isPdf=0&time=' + new Date().getTime()}/>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default IncgrtyPicModal;

