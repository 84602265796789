import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {COMMON_MODAL_STYLE, COMMON_MULTISEL_STRINGS} from "../../../helper/StyleHelper";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import SHSelect from "../../../components/common/SHSelect";
import {getLoginUser} from "../../../helper/SecurityHelper";
import SHSearch from "../../component/SHSearch";
import SHInput from "../../../components/common/SHInput";
import restapi from "../../../helper/restAPIHelper";
import {MultiSelect} from "react-multi-select-component";
import SHDatePicker2 from "../../../components/common/SHDatePicker2";
import SHCheckbox from "../../../components/common/SHCheckbox";
import SHTextarea from "../../../components/common/SHTextarea";
import SHFile from "../../../components/common/SHFile";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    dataList: object[];

}

const $ = require("jquery")

const EtrncStatByCmpnyModal = (props: IProps) => {
    const [datatable, setDatatable] = useState<any>();

    const searchFormRef = useRef<any>();
    const [dataList, setDataList] = useState<any>([]);
    const [statData, setStatData] = useState<any>({totCmpnyCnt:0, totWrkrCnt:0})


    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_onShow = () => {
        setDataList(props.dataList);
        let totCmpnyCnt = props.dataList.length;
        let totWrkrCnt = 0;

        props.dataList.map((data: any) => {
            totWrkrCnt += data.wrkrCnt;
        })

        setStatData({totCmpnyCnt: totCmpnyCnt, totWrkrCnt: totWrkrCnt});
    }

    return (
        <div>

            <Modal
                className="sh-modal" size="xl"
                show={props.modalIsOpen}
                onShow={() => {
                    fn_onShow()

                }}
                onHide={fn_closeModal}
            >


                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        {'업체별 출역현황'}
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">

                    <div className="sh-modal-div">
                        <div className="div-header">
                             총계
                        </div>

                        <div className="div-body px-3">
                            <Table className="my-2 align-middle">
                                <tbody>
                                <tr className="border-top align-middle">
                                    <th className="sh-bg-th" style={{width: '15%'}}>총 출역 업체수</th>
                                    <td style={{width: '35%'}} className={'text-center'}>
                                        {statData.totCmpnyCnt}
                                    </td>
                                    <th className="sh-bg-th" style={{width: '15%'}}>총 출역 인원</th>
                                    <td style={{width: '35%'}} className={'text-center'}>
                                        {statData.totWrkrCnt}
                                    </td>
                                </tr>

                                </tbody>
                            </Table>
                        </div>
                    </div>

                    <div className="sh-modal-div">
                        <div className="div-header">
                            출역 업체 목록
                        </div>

                        <div className="div-body px-3">
                        <Datatables
                            datas={dataList}
                            scrollY="550px"
                            className="sh-fs-b4"
                            parameterFormRef={searchFormRef}
                            columns={[
                                {data: "cmpnyNm", title: "업체명", textAlign: 'center'},
                                {data: "wrkrCnt", title: "출역인원", textAlign: 'center'},
                                {data: "remainCnt", title: "현재인원", textAlign: 'center'},
                                {data: "outCnt", title: "현장 OUT", textAlign: 'center'},
                            ]}
                            checked={false}
                            onLoad={(dt: any) => {
                                setDatatable(dt)
                            }}

                            onClick={(data) => {

                            }
                            }
                        />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>

                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default EtrncStatByCmpnyModal;
