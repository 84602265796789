import React, {useState} from "react";
import IconLoading from '../images/loading/loading.svg';
export default function ImageLoader({ src, className, style, ref, alt } : any){
    const [loaded, setLoaded] = useState(false);
    return (
        <div className={className}>
            {!loaded && (
                <div style={{
                    height: '100%',
                    width: '100%',
                    minHeight:"250px"
                }}>
                    <img src={IconLoading} className={"loading-cctv"}/>
                </div>
            )}
            {src && <img
                style={loaded ? { width:"100%" } : { display: 'none' }}
                src={src}
                onLoad={() => setLoaded(true)}
            />}
        </div>
    );
};
