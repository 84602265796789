import React, {useContext, useEffect, useState} from 'react';

import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import {CmmnFn} from "../../helper/CmmnHelper";
import {Container } from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";
import iconEdit from "../../images/icon_edit.png";
import iconTrash from "../../images/icon_trashBean.png";
import iconCross from "../../images/icon_cross_01.png";
import iconArrow from "../../images/icon_arrow_01.png";
import {getLoginUser} from "../../helper/SecurityHelper";
import warnCodeDic from "../../util/warnCodeDic";
import {UserContext} from "../../context/User";
import SHInput from "../../components/common/SHInput";
import HeadMangeItemRgstModal from "./modal/HeadMangeItemRgstModal";
import ExcelFormUploadModal from "./modal/ExcelFormUploadModal";

type Props = {};

const HeadManagementAdminPage = (props: Props) => {
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [cnfgItemList, setCnfgItemList] = useState<any>([]);
    const [manageItemList, setManageItemList] = useState<any>([]);
    const [isSaveSuccess, setIsSaveSuccess] = useState(false);
    const [cnfgItem, setCnfgItem] = useState<any>(null);
    const [manageItem, setManageItem] = useState<any>(null);
    const defaultUser = getLoginUser();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const {user} = useContext<any>(UserContext);
    const [isExcelFormOpen, setIsExcelFormOpen] = useState<boolean>(false);

    useEffect(()=>{


        setCnfgItem(null);
        setManageItem(null);
        fn_retrieveCnfgItemList();

    }, [user])

    useEffect(() => {

        if (cnfgItemList.length > 0) {
            if(cnfgItem){
                fn_retrieveSiteManageItemList(cnfgItem.code)
            }else{
                setManageItemList([]);
            }
        }
    }, [cnfgItemList, cnfgItem])


    useEffect(() => {
        if (isSaveSuccess) {
            fn_retrieveSiteManageItemList(cnfgItem.code);
            setIsSaveSuccess(false);
        }
    }, [isSaveSuccess])

    const fn_retrieveSiteManageItemList = (code: string) => {

        let codeGrpId = '';
        if(code == 'M003001'){
            codeGrpId = 'T002';
        }else if(code == 'M003002'){
            codeGrpId = 'C004';
        }else if(code == 'M003003'){
            codeGrpId = 'D001';
        }else if(code == 'M003004'){
            codeGrpId = 'D002';
        }else if(code == 'M003005'){
            codeGrpId = 'C002';
        }

        console.log('code: ', code);
        console.log('codeGrpId: ', codeGrpId);
        CmmnFn.loadCmmnCdData(codeGrpId).then((resultData: any) => {
            console.log('resultData: ', resultData);
            setManageItemList(resultData);
        })


        /*const siteId = (user?.blngSiteId || defaultUser.blngSiteId);
        const param = {siteId:siteId, manageCode:manageCode}
        axios.post('/admin/api/manage/retrieveSiteManageItemList', param).then((result) => {
            setManageItemList(result.data)
        });
        */



    }


    const fn_registPopOpen = (isNew:boolean) => {


        if(!cnfgItem){
            toast.warn('먼저 분류를 선택해 주십시오.');
            return;
        }

        if(isNew){
            fn_initManageItem();
        }
        setModalIsOpen(true);

    }

    const fn_initManageItem = () => {
        const code = cnfgItem.code;
        let codeGrpId;
        let codeGrpNm;

        if(code == 'M003001'){
            codeGrpId = 'T002';
            codeGrpNm = '현장 공종';
        }else if(code == 'M003002'){
            codeGrpId = 'C004';
            codeGrpNm = '안전점검 조치대상 구분';
        }else if(code == 'M003003'){
            codeGrpId = 'D001';
            codeGrpNm = '본사 부서 구분';
        }else if(code == 'M003004'){
            codeGrpId = 'D002';
            codeGrpNm = '엑셀 업로드 양식';
        }else if(code == 'M003005'){
            codeGrpId = 'C002';
            codeGrpNm = '업체 대표공종 구분';
        }

        setManageItem({codeGrpId: codeGrpId, codeGrpNm:codeGrpNm});
    }

    const fn_deleteCmmnCodeData = (code: string) => {

        if(!window.confirm(messageCodeDic['004'])){
            return;
        }

        axios.post('/admin/api/cmmn/deleteCmmnCodeData', {code: code}).then((result) => {
            if(result.data > 0){
                fn_retrieveSiteManageItemList(cnfgItem.code);
                toast.success(messageCodeDic['002'])
            }else{
                if(result.data == -11){
                    toast.warn(warnCodeDic['001']);
                }else{
                    toast.error(errorCodeDic['003']);
                }


            }

        })
    }

    const fn_retrieveCnfgItemList = () => {
        let codes:any = [];


        CmmnFn.loadCmmnCdData('M003', codes).then((resultData: any) => {

            if(cnfgItem){
                resultData.map((data: any) => {
                    if (cnfgItem == data.code) {
                        data.isSelected = true;
                    } else {
                        data.isSelected = false;
                    }
                });
            }
            setCnfgItemList(resultData);


        })

    }

    const fn_onCnfgItemChanged = (code: string) => {


        let cstmCnfgItemList = cnfgItemList;

        cstmCnfgItemList.map((data: any) => {
            if (code == data.code) {
                data.isSelected = true;
                console.log('cnfgItem: ', data);
                setCnfgItem(data);
            } else {
                data.isSelected = false;
            }

        })

        setManageItem(null);

        setCnfgItemList([].concat(cstmCnfgItemList));

    }
    const fn_onManageItemChanged = (code: string) => {
        let cstmManageItemList = manageItemList;

        cstmManageItemList.map((data: any) => {

            if (code == data.code) {
                data.isSelected = true;
                setManageItem(data);
            } else {
                data.isSelected = false;
            }

        })

        if(cnfgItem.code == "M003004"){
            setIsExcelFormOpen(true);
        }
        setManageItemList([].concat(cstmManageItemList));
    }

    return (
        <Container fluid className="bg-white px-4 pt-4 sh-text-font_primary">
            <ExcelFormUploadModal formCd={manageItem?.code} setModalIsOpen={setIsExcelFormOpen} modalIsOpen={isExcelFormOpen} />

            <SubMenuTitle title={'세부항목설정'} parentMenu={'설정'} />


            <div className="rounded-[5px] border mb-3 px-3">
            <HeadMangeItemRgstModal setModalIsOpen={setModalIsOpen} manageItem={manageItem} modalIsOpen={modalIsOpen}
                                detailData={cnfgItem} setIsSaveSuccess={setIsSaveSuccess}/>
                <SHInput type={'hidden'} name={'siteId'} value={user?.blngSiteId || defaultUser.blngSiteId}></SHInput>
            </div>

            <div className="ge-result container-fluid" >
                <div className="row">
                    <div className="col-md-3 pe-0">
                        <div className="input-group input-group-sm mb-3">
                            <div className="form-control p-3">
                                <div className="ge-title01">분류</div>
                                <div data-ge-area="step1">
                                    {
                                        cnfgItemList.length > 0 ?
                                            cnfgItemList.map((data: any, idx: number) => (

                                                <div className="row" key={idx}>
                                                    <div className="col-12">
                                                        <div className="form-check">
                                                            <input className="form-check-input"
                                                                   name="lvl1Cd"
                                                                   type="checkbox"
                                                                   id={"lvl1_" + idx}
                                                                   onChange={() => {
                                                                       fn_onCnfgItemChanged(data.code)
                                                                   }}
                                                                   value={data.code}
                                                                   checked={data.isSelected || false}/>
                                                            <label className="form-check-label"
                                                                   htmlFor={"lvl1_" + idx}>{data.codeNm}</label>
                                                        </div>
                                                    </div>

                                                </div>
                                            )) : <span>조회된 데이터가 없습니다.</span>


                                    }


                                </div>


                            </div>
                            <span className="mt-2 px-3"><img
                                src={iconArrow} width="9"
                                className="align-self-start"/></span>

                        </div>
                    </div>
                    <div className="col-md-9 ps-0 pe-0">
                        <div className="input-group input-group-sm mb-3">
                            <div className="form-control p-3">
                                <div className="ge-title01">항목</div>
                                <div data-ge-area="step2">
                                    {
                                        manageItemList.length > 0 ?
                                            manageItemList.map((data: any, idx: number) => (

                                                <div className="row" key={idx}>
                                                    <div className="col-9">
                                                        <div className="form-check">
                                                            <input className="form-check-input"
                                                                   name="lvl2Cd"
                                                                   id={"lvl2_" + idx}
                                                                   type="checkbox"
                                                                   onChange={() => {
                                                                       fn_onManageItemChanged(data.code)
                                                                   }}
                                                                   value={data.code}
                                                                   checked={data.isSelected || false}/>
                                                            <label className="form-check-label"
                                                                   htmlFor={"lvl2_" + idx}>{data.codeNm}</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        {cnfgItem?.code !== 'M003004'
                                                        && <div className="d-flex align-items-center w-100">
                                                                <img onClick={() => {setManageItem(data); fn_registPopOpen(false);}}
                                                                     src={iconEdit} width="20"
                                                                     className="align-self-end cursor-pointer"/>


                                                                <img onClick={() => {fn_deleteCmmnCodeData(data.code)}}
                                                                     src={iconTrash}
                                                                     width="20"
                                                                     className="align-self-end cursor-pointer ml-5"/>




                                                            </div>
                                                        }

                                                    </div>
                                                </div>
                                            )) : <span>조회된 데이터가 없습니다.</span>


                                    }

                                </div>
                                {cnfgItem?.code !== 'M003004' &&
                                    <div className="w-100 d-inline-flex text-center justify-content-between">
                                        <button className="ge-add01"  onClick={() => {
                                            fn_registPopOpen(true);
                                        }
                                        }><img className={'ge-img'}
                                               src={iconCross} width="13"/></button>
                                    </div>

                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Container>
    );
};

export default HeadManagementAdminPage;
