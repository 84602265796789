import React, {Dispatch, SetStateAction, useRef, useState} from "react";

interface IProps {
    className?: string;
    name: string;
    onChange?: Dispatch<SetStateAction<any>>;
    value: any;
    placeholder?: string;
    rows?: number;
    disabled?: boolean;
    autoHeight?: boolean;
}
export default function SHTextarea(props: IProps) {
    const [defaultValue, setDefaultValue] = useState('');
    const refTextarea = useRef<any>();
    const fn_setDefaultValue = (e: any) => {
        setDefaultValue(e.target.value);
    }

    const fn_authHeightByKeyUp = (e:any) => {
        e.target.style.height = 'auto';
        e.target.style.height = e.target.scrollHeight + 'px';
    }

    return (
        <textarea className={props.className||"form-control"}
                  name={props.name}
                  onChange={props.onChange || fn_setDefaultValue}
                  value={props.value || defaultValue}
                  placeholder={props.placeholder || ''}
                  rows={props.rows}
                  disabled={props.disabled}
                  onKeyUp={
                      (e) => {
                          if(props.autoHeight){
                              fn_authHeightByKeyUp(e);
                          }
                      }
                  }
                  style={{
                      height:props.autoHeight?refTextarea?.current?.scrollHeight + 'px':refTextarea?.current?.minHeight}}
                  ref={refTextarea}
        ></textarea>
    )

}
