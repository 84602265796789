import React, {Dispatch, SetStateAction, useContext, useState} from "react";
import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import SHInput from "../../../components/common/SHInput";
import {getLoginUser} from "../../../helper/SecurityHelper";
import axios from "axios";
import {toast} from "react-toastify";
import errorCodeDic from "../../../util/errorCodeDic";
import {ProgressContext} from "../../../context/Progress";


interface IProps{
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
}
const PasswordChangeModal = (props: IProps) =>{

    const formRef = React.createRef<HTMLFormElement>();
    const [data, setData] = useState<any>()
    const defaultUser = getLoginUser();
    const {spinner} = useContext(ProgressContext);

    const updateData = (e: any) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const fn_passwordChange = () =>{

        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if(!data.userPassword){
            toast.warn('기존 비밀번호를 입력해 주십시오.')
            return;
        }

        if(!data.newPassword){
            toast.warn('새로운 비밀번호를 입력해 주십시오.')
            return;
        }

        if(!data.newPasswordConfirm){
            toast.warn('새로운 비밀번호를 한번더 입력해 주십시오.')
            return;
        }

        if(data.newPassword != data.newPasswordConfirm){
            toast.warn('새로운 비밀번호가 비밀번호 확인과 일치하지 않습니다.')
            return;
        }

        let param = data;
        param.userSeq = defaultUser.userSeq;

        if (!window.confirm('비밀번호를 변경 하시겠습니까?')) {
            return;
        }

        spinner.start();
        axios.post('/admin/api/user/changePassword', param).then((result) => {

            if (result.data.isSuccess) {
                toast.success('비밀번호가 변경되었습니다.');
                props.setModalIsOpen(false);
            } else {
                if(result.data.errorCode == "002"){
                    toast.error('기존 비밀번호가 일치하지 않습니다.');
                }else if(result.data.errorCode == "003"){
                    toast.error('새로운 비밀번호와 비밀번호 확인이 일치하지 않습니다.');
                }
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        }).finally(() => {
            spinner.stop();
        });
    }

    const fn_closeModal = () =>{
        props.setModalIsOpen(false)
    }

    const fn_openModal = () =>{
        setData(null);
    }

    const submit = (e: any) => {
        e.preventDefault()

    }

    return(
        <div>
            <Modal
                className="sh-modal"
                show={props.modalIsOpen}
                onShow={fn_openModal}
                onHide={fn_closeModal}
            >


                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        비밀번호 변경
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">

                    <form name={'detailForm'} method={"post"} onSubmit={submit}>
                        <div className="sh-modal-div">
                            <div className="div-body px-3" style={{border: "1px solid #eeeeee"}}>
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>기존 비밀번호</th>
                                        <td>
                                            <SHInput type="password" className="form-control" name={'userPassword'}
                                                     onChange={updateData} value={data?.userPassword}/>
                                        </td>
                                    </tr>
                                    <tr className="align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>새로운 비밀번호</th>
                                        <td>
                                            <SHInput type="password" className="form-control" name={'newPassword'}
                                                     onChange={updateData} value={data?.newPassword}/>
                                        </td>
                                    </tr>
                                    <tr className="align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>새로운 비밀번호 확인</th>
                                        <td>
                                            <SHInput type="password" className="form-control" name={'newPasswordConfirm'}
                                                     onChange={updateData} value={data?.newPasswordConfirm}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <button onClick={fn_passwordChange}
                                className='sh-btn pill p'>변경
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default PasswordChangeModal;