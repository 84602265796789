import React, {useContext, useEffect, useRef, useState} from 'react';
import Datatables, {getCheckedItems} from "../component/Datatables";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import SHSelect from "../../components/common/SHSelect";
import SHInput from "../../components/common/SHInput";
import SiteSearchModal from "../companyManagement/modal/SiteSearchModal";
import WrkPrmtRgstModal from "./modal/WrkPrmtRgstModal";
import SHSearch from '../component/SHSearch';
import SubMenuTitle from "../component/SubMenuTitle";
import {getLoginUser, IS_SM_SITE} from "../../helper/SecurityHelper";
import {Col, Container, Row, Table} from "react-bootstrap";
import SHIconButton from "../../components/common/SHIconButton";
import WrkPrmtDetailModal from "./modal/WrkPrmtDetailModal";
import {CmmnFn} from "../../helper/CmmnHelper";
import {UserContext} from "../../context/User";
import WrkrRgstModal from "./modal/WrkrRgstModal";
import SHDatePicker2 from "../../components/common/SHDatePicker2";
import {DateHelper} from "../../helper/DateHelper";
import IFrameModal from "../../modal/IFrameModal";
import restapi from "../../helper/restAPIHelper";

type Props = {};

const WorkPermitManagementPage = (props: Props) => {
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [modalRgstIsOpen, setModalRgstIsOpen] = React.useState(false);
    const [wrkrModalIsOpen, setWrkrModalIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState<any>(null);
    const [isSaveSuccess, setIsSaveSuccess] = useState(false);
    const [isSitePopOpend, setIsSitePopOpend] = React.useState<boolean>(false);
    const [blngSiteList, setBlngSiteList] = useState<any[]>([]);
    const prodRef = useRef<any>();
    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);
    const [startDate, setStartDate] = useState<any>(DateHelper.dateByMonth(-3));
    const [endDate, setEndDate] = useState<any>(DateHelper.dateByMonth(0));
    const [data, setData] = useState<any>({})
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const defaultUser = getLoginUser();
    const {user} = useContext<any>(UserContext);
    const [isCodeDataLoaded, setIsCodeDataLoaded] = useState<boolean>(false);
    const [isCopy, setIsCopy] = useState<boolean>(false);
    const [isIFrameModalOpen, setIsIFrameModalOpen] = useState<boolean>(false);
    const [wrkCntntsFileId, setWrkCntntsFileId] = useState<string>('');

    useEffect(() => {

        if (datatable) {
            datatable.draw();
        }

    }, [user, datatable])

    useEffect(() => {
        if (!modalRgstIsOpen && !wrkrModalIsOpen) {
            setIsCopy(false);
            datatable?.draw();
        }

    }, [modalRgstIsOpen, wrkrModalIsOpen])

    useEffect(() => {
        if(isCodeDataLoaded){
            datatable?.draw();
        }

    }, [isCodeDataLoaded])


    const fn_search = () => {
        datatable.draw();
    }

    /**
     * 등록 버튼 클릭
     */
    const fn_registPopOpen = () => {


        setDetailData(null)
        setModalRgstIsOpen(true);
    }

    const fn_copy = () => {
        var checkedData = getCheckedItems(datatable);

        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['007']);
            return;
        }

        if (checkedData.length > 1) {
            let msg = '';
            if(CmmnFn.isSmSite()) {
                msg = '작업지시서';
            }else{
                msg = '작업허가서'
            }
            toast.warn('하나의 '+ msg +'만 선택해 주십시오.');
            return;
        }
        console.log('checkedData: ', checkedData);

        checkedData[0].prcsStatCd = null;
        checkedData[0].prcsStatNm = null
        checkedData[0].rgstDt = null;
        checkedData[0].rgstrNm = null;
        checkedData[0].rgstrSeq = null;
        checkedData[0].wrkDt = CmmnFn.fn_getCurrentDate();
        checkedData[0].wrkStatNm = null;
        setIsCopy(true);
        setDetailData(checkedData[0]);
        setModalRgstIsOpen(true);
    }

    const fn_delete = () => {

        var checkedData = getCheckedItems(datatable);
        let delList: number[] = [];

        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }

        checkedData.map((data: any) => {
            delList.push((data.wrkPrmtId));
        })

        console.log('delList: ', delList);

        if (!window.confirm(messageCodeDic['004'])) {
            return;
        }

        axios.post('/admin/api/wrkPrmt/deleteWrkPrmtData', {delList: delList}).then(rsltData => {
                if (rsltData.data > 0) {
                    toast.success(messageCodeDic['002'])
                    fn_search();
                } else {
                    toast.error(errorCodeDic['003'])
                }
            }
        ).catch(() => {
                toast.error(errorCodeDic['003'])
            }
        )
    }


    const fn_datePopupOpen = () => {
        setIsDatePopupOpen(!isDatePopupOpen);
    };

    const fn_setFormData = (e: any) => {

        setData({
            ...data,
            [e.target.name]: e.target.value
        })

    }

    const fn_onChangeToAllVal = (e: any) => {
        const checked = e.target.checked;

        if (e.target.checked) {
        }

    }

    const fn_print = (e: any) =>{
        let param: any ={
            wrkPrmtId:e.wrkPrmtId,
            siteName: e.siteName, //현장명
            wrkTypeNm:e.wrkTypeNm, //고종
            companyName:e.companyName, //업체
            wrkPlcNm:e.wrkPlcNm, //작업위치
            chrgrNms:e.chrgrNms, //작업책임자
            wrkDt:e.wrkDt,
            wrkStrtHr:e.wrkStrtHr,
            wrkEndHr:e.wrkEndHr,
            wrkStrtMi:e.wrkStrtMi,
            wrkEndMi:e.wrkEndMi,
            wrkTimeGbNm:e.wrkTimeGbNm, //주간/야간
            wrkrCnt:e.wrkrCnt, //작업인원
        }

        if(e.wrkCntntsFileId){
            setWrkCntntsFileId(e.wrkCntntsFileId);
            setIsIFrameModalOpen(true);
        }else{

            restapi.retrieveWrkPrmtApprInfo({wrkPrmtId: e.wrkPrmtId}).then((rsltData: any) => {
                const apprUserDataList: any = rsltData.apprUserDataList;
                let apprData;
                let siteChrgNm;
                let userName;
                let apprStatNm;
                let prcsDt;
                if(apprUserDataList.length > 0){
                    let maxLength = apprUserDataList.length;
                    if(maxLength > 8){
                        maxLength = 8;
                    }

                    for(var i= 0; i < maxLength; i++){
                        apprData = apprUserDataList[i];
                        siteChrgNm = apprData.siteChrgNm;
                        userName = apprData.userName;
                        apprStatNm = apprData.apprStatNm;
                        prcsDt = apprData.prcsDt;
                        param['siteChrgNm' + (i+1)] = siteChrgNm;
                        param['userName' + (i+1)] = userName;
                        param['apprStatNm' + (i+1)] = apprStatNm;
                        param['prcsDt' + (i+1)] = prcsDt;
                    }

                }

                axios.post('/admin/api/wrkPrmt/createWrkPrmtPrint', param).then(rsltData => {
                        window.open(process.env.REACT_APP_FILE_URL + "/shss-data/fileDownloadByFileName?fileName="+rsltData.data, "_blank")
                    }
                ).catch(() => {
                        toast.error(errorCodeDic['003'])
                    }
                )

            })


        }

    }

    return (
        <Container fluid className="bg-white px-4 pt-4 sh-text-font_primary">
            <IFrameModal setModalIsOpen={setIsIFrameModalOpen} modalIsOpen={isIFrameModalOpen} title={(CmmnFn.isSmSite()? '작업지시서' : '작업허가서') + ' PDF'} iframeSrc={process.env.REACT_APP_FILE_URL + "/shss-data/fileDownload?attachSeq=" + wrkCntntsFileId + '&sn=0&isPdf=1'} />
            <SubMenuTitle title={CmmnFn.isSmSite()? '작업지시서 등록' : '작업허가서 작성'} parentMenu={'현장안전관리'}/>
            <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                <SiteSearchModal setModalIsOpen={setIsSitePopOpend} modalIsOpen={isSitePopOpend}
                                 blngSiteList={blngSiteList}
                                 setBlngSiteList={setBlngSiteList} isSingleCheck={true} siteNm={data?.siteName}/>
                <WrkPrmtRgstModal setModalIsOpen={setModalRgstIsOpen} modalIsOpen={modalRgstIsOpen}
                                  siteId={user?.blngSiteId || defaultUser.blngSiteId}
                                  detailData={detailData} isCopy={isCopy}/>
                <WrkPrmtDetailModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen}
                                    detailData={detailData} setIsSaveSuccess={setIsSaveSuccess}/>
                <WrkrRgstModal setModalIsOpen={setWrkrModalIsOpen} modalIsOpen={wrkrModalIsOpen}
                               siteId={user?.blngSiteId || defaultUser.blngSiteId}
                               detailData={detailData}></WrkrRgstModal>
                <Row>
                    <Col>
                        <Table className="sh-font-SP sh-fs-b2 my-3">
                            <tbody>
                            <tr className="border-top align-middle border-bottom">
                                <th className="sh-bg-th">업체명</th>
                                <td>
                                    <SHInput type={'hidden'} name={'siteId'}
                                             value={user?.blngSiteId || defaultUser.blngSiteId}></SHInput>
                                    <SHSelect name={'cmpnySeq'} onChange={fn_setFormData} value={data.cmpnySeq} setIsCodeDataLoaded={setIsCodeDataLoaded} initOptionGb={'all'} codeGb={'siteCmpny'} selParam={{siteId:user?.blngSiteId || defaultUser.blngSiteId}}></SHSelect>

                                </td>
                                <th className="sh-bg-th">공종</th>
                                <td>
                                    <SHSelect initOptionGb={'all'} name={'wrkTypeCd'} className={"form-select"}
                                              selParam={{siteId: user?.blngSiteId || defaultUser.blngSiteId, manageCode: 'M001001'}}
                                              codeGb={'siteMngCd'}
                                              onChange={fn_setFormData}
                                              value={data?.wrkTypeCd}></SHSelect>
                                </td>
                                <th className="sh-bg-th">상태</th>
                                <td colSpan={2}>
                                    <SHSelect className={'form-select'} initOptionGb={'all'} name={'prcsStatCd'}
                                              onChange={fn_setFormData} value={data?.prcsStatCd} codeGrpId={'S001'}></SHSelect>
                                </td>
                            </tr>
                            <tr className="align-middle border-bottom">
                                <th className="sh-bg-th">기간</th>
                                <td colSpan={2}>
                                    <div className="d-flex align-items-center w-100" ref={prodRef}>
                                        <SHDatePicker2 setter={setStartDate} name={'strtDt'} defaultValue={startDate} onChange={fn_setFormData} />
                                        <span>&nbsp;~&nbsp;</span>
                                        <SHDatePicker2 setter={setEndDate} name={'endDt'} defaultValue={endDate} onChange={fn_setFormData} />
                                    </div>
                                </td>
                                <td colSpan={6}>
                                    <div className="d-flex align-items-center">
                                        <div className={'form-check w-25'}>
                                            <input className="form-check-input sh-check" id={'reception_toAll'}
                                                   onChange={fn_onChangeToAllVal} type="checkbox"/>
                                            <label className={'form-check-label text-black'}
                                                   htmlFor={'reception_toAll'}>전체기간</label>
                                        </div>
                                        <SHInput className={'form-control w-100'} name={'srchTxt'}
                                                 onChange={fn_setFormData}/>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </SHSearch>


            <div className='w-100 d-flex justify-content-end align-items-center mb-2'>


                <SHIconButton kind={'submit'} marginRight={'5px'} onClick={fn_registPopOpen}> 등록
                </SHIconButton>

                <SHIconButton kind={'delete'} marginRight={'5px'} onClick={fn_delete}> 삭제
                </SHIconButton>
                <SHIconButton kind={'copy'} marginRight={'0'} onClick={fn_copy}> 복사
                </SHIconButton>
            </div>
            <div className={'ge-result'}>

                {
                    <Datatables
                    dataUrl={'/admin/api/wrkPrmt/retrieveWrkPrmtDataList'}
                    parameterFormRef={searchFormRef}
                    columns={
                        CmmnFn.isSmSite() ?
                            [
                            {
                                data: "prcsStatNm", title: "상태", textAlign: 'center', render: (data: any) => {
                                    switch (data) {
                                        case '작성중':
                                            return "<div class='sh-box-status writing'>작성중</div>";
                                        case '결재요청':
                                            return "<div class='sh-box-status request'>결재요청</div>";
                                        case '결재중':
                                            return "<div class='sh-box-status approval'>결재중</div>";
                                        case '결재완료':
                                            return "<div class='sh-box-status approvaldone'>결재완료</div>";
                                        case '반려':
                                            return "<div class='sh-box-status refuse'>반려</div>";
                                        default:
                                            return "";
                                    }
                                }
                            },
                            {data: "wrkSiteNm", title: "현장명", textAlign: 'center'},
                            {data: "companyName", title: "업체", textAlign: 'center'},
                            {data: "wrkCntntsAbbr", title: '작업사항'},
                            {data: "wrkTypeNm", title: "공종", textAlign: 'center'},
                            {data: "wrkrCnt", title: "작업인원", textAlign: 'center'},
                            {data: "wrkDt", title: "작업일", textAlign: 'center'},
                            {data: "wrkTimeGbNm", title: "작업시간", textAlign: 'center'},
                            {
                                title: "기능",
                                type: "button",
                                className: "text-center",
                                buttons: [{
                                    label: "<img style='height: 20px' class='cursor-pointer' src='/icon_print.svg' />",
                                    onClick: (v: any) => {
                                        fn_print(v);
                                    },
                                }, {
                                    label: "<img style='height: 20px' class='cursor-pointer' src='/icon_user_plus.svg' />",
                                    onClick: (v: any) => {
                                        setDetailData(v);
                                        setWrkrModalIsOpen(true);
                                        console.log(2, v)
                                    },
                                }],
                            },

                        ]
                    : [
                                {
                                    data: "prcsStatNm", title: "상태", textAlign: 'center', render: (data: any) => {
                                        switch (data) {
                                            case '작성중':
                                                return "<div class='sh-box-status writing'>작성중</div>";
                                            case '결재요청':
                                                return "<div class='sh-box-status request'>결재요청</div>";
                                            case '결재중':
                                                return "<div class='sh-box-status approval'>결재중</div>";
                                            case '결재완료':
                                                return "<div class='sh-box-status approvaldone'>결재완료</div>";
                                            case '반려':
                                                return "<div class='sh-box-status refuse'>반려</div>";
                                            default:
                                                return "";
                                        }
                                    }
                                },
                                {data: "companyName", title: "업체", textAlign: 'center'},
                                {data: "wrkTypeNm", title: "공종", textAlign: 'center'},
                                {data: "wrkrCnt", title: "작업인원", textAlign: 'center'},
                                {data: "wrkDt", title: "작업일", textAlign: 'center'},
                                {data: "wrkTimeGbNm", title: "작업시간", textAlign: 'center'},
                                {data: "rgstrNm", title: "작성자", textAlign: 'center'},
                                {data: "rgstDt", title: "작성일", textAlign: 'center'},
                                {
                                    title: "기능",
                                    type: "button",
                                    className: "text-center",
                                    buttons: [{
                                        label: "<img style='height: 20px' class='cursor-pointer' src='/icon_print.svg' />",
                                        onClick: (v: any) => {
                                            fn_print(v);
                                        },
                                    }, {
                                        label: "<img style='height: 20px' class='cursor-pointer' src='/icon_user_plus.svg' />",
                                        onClick: (v: any) => {
                                            setDetailData(v);
                                            setWrkrModalIsOpen(true);
                                            console.log(2, v)
                                        },
                                    }],
                                },

                            ]
                    }
                    checked={true}
                    index={true}

                    onLoad={(dt: any) => {
                        setDatatable(dt)
                    }}
                    onClick={(data) => {
                        setDetailData(data)
                        setModalRgstIsOpen(true);
                        //setModalIsOpen(true);
                    }
                    }
                />}
            </div>


        </Container>
    );
};

export default WorkPermitManagementPage;
