import React, {useContext, useEffect, useRef, useState} from "react";
import sizeConfigs from "../configs/sizeConfigs";
import colorConfigs from "../configs/colorConfigs";
import {AppBar, Drawer, IconButton, List, Toolbar, Typography} from "@mui/material";
import appRoutes from "../../routes/appRoutes";
import SidebarItemCollapse from "./SidebarItemCollapse";
import SidebarItem from "./SidebarItem";
import MenuIcon from "@mui/icons-material/Menu";
import assets from "../../assets";
import {AccountCircle} from "@mui/icons-material";
import {getLoginUser, IS_SM_SITE, logout, setItemWithExpireTime, USER_SESSION} from "../../helper/SecurityHelper";
import {useNavigate} from "react-router-dom";
import {ReactComponent as IconLogout} from '../../assets/images/ico/icon-logout.svg';
import {UserContext} from "../../context/User";
import SHSelect from "./SHSelect";
import {toast} from "react-toastify";
import axios from "axios";
import restapi from "../../helper/restAPIHelper";
import errorCodeDic from "../../util/errorCodeDic";
import {CmmnFn} from "../../helper/CmmnHelper";
import {RouteType} from "../../routes/config";
import { smSiteRoutes } from "../../routes";
import appRoutesSmSite from "../../routes/appRoutesSmSite";
import {Constatnts} from "../../util/Constants";
import LinkInfoModal from "../../pages/headoffice/modal/LinkInfoModal";
type Anchor = 'top' | 'left' | 'bottom' | 'right';




const View = () => {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const user: any = getLoginUser();
    const [siteId, setSiteId] = useState<number>(user?.blngSiteId);
    const [siteCodeList, setSiteCodeList] = useState<any>([]);
    const siteIdRef = useRef<any>();
    const {setUser} = useContext(UserContext);
    const [isLinkModalOpen, setIsLinkModalOpen] = useState<boolean>(false);

    useEffect(() => {
        const pathname = window.location.pathname;
        if(pathname === '/' || pathname.indexOf("monitoring") > -1){
            setOpen(false)
        }else{
            setOpen(true)
        }
    }, [])
    function fn_clickMenu(){
        setOpen(!open)
    }
    function fn_clickHome(){
        navigate("/smSite")
    }
    const fn_onSiteChange = (e: any) => {
        const siteId = e.target.value;
        setSiteId(siteId);
        restapi.changeCurrentUserSite({userSeq:user.userSeq, siteId:siteId, isRepSiteChg:true}).then((rsltCnt: any) => {
            if (rsltCnt > 0) {
                restapi.getUserSession(user.userSeq).then((user: any) => {
                    setUser(user);
                    setItemWithExpireTime(USER_SESSION, user);
                });
            }else{
                toast.error(errorCodeDic['003']);
            }

        });
        console.log('siteId: ', siteId);

    }

    useEffect(() => {
        console.log('cc site: ', siteId);
        restapi.retrieveSiteDataListNoPaging({siteId: siteId}).then((rsltData: any) => {

            if(rsltData.length > 0){
                localStorage.setItem(IS_SM_SITE, rsltData[0].isSmSite);
                if(rsltData[0].isSmSite){
                    let pathname = window.location.pathname

                    if(pathname.indexOf('/smSite/') == -1){
                        pathname = '/smSite' + pathname;
                    }

                    navigate(pathname);
                }else{
                    navigate('/monitoring');
                }

            }



        })

    }, [siteId]);
    useEffect(() => {
        console.log('user: ', user);
        fn_retrieveSiteCodeList().then((siteCodeList: any) => {
            setSiteCodeList(siteCodeList);

            if (siteCodeList.length == 0) {
                toast.warn('조회된 현장이 없습니다.');
                return;
            }


        })
    }, [])

    const fn_retrieveSiteCodeList = async () => {
        return new Promise(function (resolve, reject) {
            let param:any = {}

            if(user?.authGrpId == 'SITE'){
                let siteIdList: any = [];
                const authChrgDataList = user.authChrgDataList;
                authChrgDataList.map((data: any) => {
                    siteIdList.push(data.siteId);
                })
                param.siteIdList = siteIdList;
            }
            axios.post('/admin/api/site/retrieveSiteCodeList', param).then((result) => {
                resolve(result.data);
            })

        });
    }

    const fn_moveToLink = (url: string) => {
        window.open(url, '_blank');
    }

    return (
        <React.Fragment>
            <LinkInfoModal setModalIsOpen={setIsLinkModalOpen} modalIsOpen={isLinkModalOpen} />
            <AppBar
                position="fixed"
                sx={{
                    width: `100%`,
                    ml: sizeConfigs.sidebar.width,
                    boxShadow: "unset",
                    backgroundColor: colorConfigs.topbar.bg,
                    color: colorConfigs.topbar.color
                }}
            >
                <Toolbar className={"text-white"}>
                    <div>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={fn_clickMenu}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <img src={assets.images.logo} style={{height: "26px", display: "inline-block"}}
                             onClick={fn_clickHome}/>
                    </div>
                    <Typography variant="h6" component="div" sx={{flexGrow: 1, textAlign: "center"}}>
                        <select className='sh-top-title shss-font-SD text-center' name={'siteId'} ref={siteIdRef}
                                onChange={fn_onSiteChange} value={siteId}>
                            {siteCodeList.map((data: any, idx: number) => (
                                <option key={idx} value={data.siteId}>{data.siteName}</option>
                            ))}
                        </select>
                    </Typography>
                    <div className="custom-select-wrapper">
                        <select className="custom-select cursor-pointer"
                                style={{backgroundColor: '#232323', borderColor: '#232323'}}>
                            <option className="text-left" value="">링크 바로가기</option>
                        </select>
                        <div className="options text-[0.8em]" style={{backgroundColor: '#232323'}}>
                            <div onClick={() => fn_moveToLink("https://www.kiscon.net")}>1. 키스콘</div>
                            <div onClick={() => fn_moveToLink("https://www.cw.or.kr")}>2. 건설근로자공제회</div>
                            {user?.authGrpId == 'HEAD' &&
                                <>
                                    <div onClick={() => fn_moveToLink("https://www.csi.go.kr")}>3. CSI</div>
                                    <div onClick={() => fn_moveToLink("https://www.moel.go.kr")}>4. 고용노동부</div>
                                </>
                            }
                        </div>
                    </div>
                    <div>

                        <span className="sh-text-font_secondary02 mr-4">|</span>
                        <span style={{
                            fontSize: '12px',
                            color: '#3DEC8D',
                            fontWeight: '500'
                        }}>{user?.userName + '(' + user?.userId + ')'}</span>
                        <span style={{fontSize: '12px'}}>님</span>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                            onClick={logout}
                        >
                            <IconLogout style={{width: '24px'}}/>
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                anchor={"left"}
                style={open ? {width: sizeConfigs.sidebar.width} : {width: "70px"}}
                sx={{
                    overflow: "hidden",
                    flexShrink: 0,
                    zIndex: 1099,
                    "& .MuiDrawer-paper": {
                        position: "relative",
                        width: sizeConfigs.sidebar.width,
                        boxSizing: "border-box",
                        borderRight: "0px",
                        backgroundColor: "#1F1F23",
                        color: colorConfigs.sidebar.color
                    }
                }}
            >
                <List disablePadding style={{paddingTop: "64px"}} className={"sh-menu-scroll shss-font-SD"}
                      onClick={() => {
                          setOpen(true)
                      }}>
                    {user &&
                        appRoutesSmSite.map((route, index) => {
                            if(user.companyGbCd !== 'C001002'){
                                if(route.child){
                                    route.child = route.child.filter((data: any) => (!Constatnts.bldrAuthMenuList.includes(data.state)))
                                }
                            }
                            return (
                                route.sidebarProps ? (
                                    route.child ? (
                                        <SidebarItemCollapse item={route} key={index} />
                                    ) : (
                                        <SidebarItem item={route} key={index} />
                                    )
                                ) : null
                            )})}
                </List>
            </Drawer>
        </React.Fragment>
    );
};

export default View;
