import { colors } from "@mui/material";

const colorConfigs = {
  sidebar: {
    bg: "#1E1F23",
    color: "#8E8E8E",
    hoverBg: "#3DEC8D",
    activeBg: "#3DEC8D"
  },
  topbar: {
    bg: "#232323",
    color: "#000"
  },
  dashboardBg: "#151517",
  mainBg: colors.grey["100"]
};

export default colorConfigs;
