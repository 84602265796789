import React, {Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState} from "react";
import {ReactComponent as IconCalendar} from "../../images/icon-calendar-black.svg";
import DatePicker from "react-datepicker";
import { ko } from 'date-fns/esm/locale';
import {DateHelper} from "../../helper/DateHelper";


interface IProps {
    value?: any;
    name?: string;
    onChange?: Dispatch<SetStateAction<any>>;
    className?: string;
    onClick?: Dispatch<SetStateAction<any>>;
    readOnly?: boolean;
    type?: string;
    disabled?: boolean;
    onBlur?: Dispatch<SetStateAction<any>>;
    placeholder?:string;
    calendar?: Dispatch<SetStateAction<any>>;
    defaultValue?: Date | null;
    fitWidth?: boolean;
    setter?: Dispatch<SetStateAction<Date>> | Dispatch<SetStateAction<Date | null>>;
}

export default function SHComboDatePicker(props: IProps) {
    const calenderInputRef = useRef<any>();
    const [initDate, setInitDate] = useState<any>(new Date());
    const [type, setType] = useState<string>(props.type || '')
    const today = new Date();
    const CustomHeader = ({
                              date,
                              changeYear,
                              changeMonth,
                              decreaseMonth,
                              increaseMonth,
                              prevMonthButtonDisabled,
                              nextMonthButtonDisabled,
                          }: any) => (
        <div className="datepicker-header">
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                {"<"}
            </button>
            <select
                className={'my-2 cursor-pointer'}
                value={date.getFullYear()}
                onChange={({ target: { value } }) => changeYear(value)}
            >
                {new Array(90).fill(null).map((_, i) => (
                    <option key={i} value={today.getFullYear() - i}>
                        {today.getFullYear() - i}
                    </option>
                ))}
            </select>
            <select
                className={'my-2 cursor-pointer'}
                value={date.getMonth()}
                onChange={({ target: { value } }) => changeMonth(value)}
            >
                {Array.from({ length: 12 }, (_, i) => (
                    <option key={i} value={i}>
                        {new Date(0, i).toLocaleString('ko', { month: 'long' })}
                    </option>
                ))}
            </select>
            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                {">"}
            </button>
        </div>
    );
    function fn_change(e: Date) {
        // @ts-ignore
        let dateStr : string = DateHelper.format(e)
        // @ts-ignore
        var oriTime : number = DateHelper.parse(dateStr)?.getTime()
        var date = new Date(oriTime + (1000 * 60 * 60 * 10))
        setInitDate(date)
        if(props.setter){
            props.setter(date)
        }
        if(props.onChange){
            props.onChange({target:{name:props.name, value: date}});
        }
    }
    useEffect(() => {
        if(props.defaultValue){
            setInitDate(props.defaultValue)
        }else{
            setInitDate(null)
        }
    }, [props.defaultValue])
    return (
        <div className={"sh-box-input date px-2"+(type == 'hidden' ? ' d-none' : '')+(props.disabled ? ' disabled' : '')  + (props.fitWidth ? ' fitWidth ' : '')}>
            <DatePicker
                disabled={props.disabled}
                dateFormatCalendar="yyyy년 MM월"
                name={props.name}
                showYearDropdown
                showMonthDropdown
                scrollableMonthYearDropdown={true}
                dateFormat='yyyy-MM-dd'
                renderCustomHeader={(headerProps) => <CustomHeader {...headerProps} />}
                locale={ko}
                autoComplete={'off'}
                className={'form-control sh-date-picker border-0'} selected={initDate} onChange={fn_change} ref={calenderInputRef} />
            <IconCalendar className='cursor-pointer' onClick={(e)=> {
                if(calenderInputRef.current) {
                    calenderInputRef.current.setOpen(true)
                }
            }} />
        </div>
    )
}
