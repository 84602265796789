import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from "axios";
import Datatables, {getCheckedItems} from "../component/Datatables";
import Modal from 'react-modal';
import {getLoginUser} from "../../helper/SecurityHelper";
import {toast} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import SHSelect from "../../components/common/SHSelect";
import {CmmnFn} from "../../helper/CmmnHelper";
import {ProgressContext} from "../../context/Progress";
import SHSearch from '../component/SHSearch';
import {Col, Container, Row, Table} from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";
import SHIconButton from '../../components/common/SHIconButton';
import SiteSftyChkRgstModal from "./modal/SiteSftyChkRgstModal";
import SiteSftyChkRsltModal from "./modal/SiteSftyChkRsltModal";
import {TopMenuContext} from "../../context/TopMenu";
import SHInput from "../../components/common/SHInput";
import SHDatePicker2 from "../../components/common/SHDatePicker2";
import {DateHelper} from "../../helper/DateHelper";
import ExcelFormUploadModal from "../headoffice/modal/ExcelFormUploadModal";

type IProps = {
};

Modal.setAppElement('#root');
const SiteSftyChkPrvntPage = (props: IProps) => {
    const [dataList, setDataList] = useState<any[]>([]);
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [rsltModalIsOpen, setRsltModalIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState(null);
    const [srchData, setSrchData] = useState<any>()
    const {spinner} = useContext(ProgressContext);
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const [startDate, setStartDate] = useState<any>(DateHelper.dateByMonth(-3));
    const [endDate, setEndDate] = useState<any>(DateHelper.dateByMonth(0));
    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);
    const user = getLoginUser();
    const prodRef = useRef<any>();
    const [prcsGb, setPrcsGb] = useState<any>(null);
    const {menu} = useContext<any>(TopMenuContext);
    const fileRef = useRef<any>();
    const [isExcelFormOpen, setIsExcelFormOpen] = useState<boolean>(false);
    const formRef = React.createRef<HTMLFormElement>();

    useEffect(() => {
        if (datatable && srchData &&  !modalIsOpen &&!rsltModalIsOpen) {
            fn_search();
        }
    }, [datatable, srchData, modalIsOpen, rsltModalIsOpen])

    const fn_setSearchData = (e: any) => {
        setSrchData({
            ...srchData,
            [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        if (!modalIsOpen) {
            datatable?.draw();
        }
    }, [modalIsOpen])

    useEffect(() => {
        if (!rsltModalIsOpen) {
            datatable?.draw();
        }
    }, [rsltModalIsOpen])

    const fn_search = (e?: any) => {
        if (e) {
            e.preventDefault()
        }
        datatable.draw();
    }

    const fn_delete = () => {
        let checkedData = getCheckedItems(datatable,"sftyChkSeq")
        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }
        if(!window.confirm(messageCodeDic['004'])){
            return;
        }
        axios.post('/admin/api/siteSftyManage/deleteSftyWrkMetData', {delList: checkedData}).then(rsltData => {
            if (rsltData.data > 0) {
                toast.success(messageCodeDic['002'])
                fn_search();
            } else {
                toast.error(errorCodeDic['003'])
            }
        }).catch(() => {
            toast.error(errorCodeDic['003'])
        })
    }

    const fn_registPopOpen = () => {
        setPrcsGb(null);
        setDetailData(null);
        setModalIsOpen(true);
    }

    const fn_updateViewCnt = (param: object) => {
        axios.post('/admin/api/notice/updateViewCnt', param)
    }

    const fn_datePopupOpen = () => {
        setIsDatePopupOpen(!isDatePopupOpen);
    };

    function dateFormat(addMonth: number) {
        let today = new Date();
        let month = today.getMonth() + addMonth;
        let day = today.getDate();
        let monthFormat = month >= 10 ? month : '0' + month;
        let dayFormat = day >= 10 ? day : '0' + day;

        return today.getFullYear() + '-' + monthFormat + '-' + dayFormat ;
    }

    const fn_addFile = (e: any) => {

        e.preventDefault();

        console.log('e.target.value: ', e.target.value);
        console.log('e.target.files: ', e.target.files);
        if (!e.target.files) {
            return;
        }

        // @ts-ignore
        var formData: any = new FormData(formRef.current);
        formData.set('excelFile', e.target.files[0]);
        formData.set('rgstrSeq', user?.userSeq);

        spinner.start();
        axios.post('/admin/api/siteSftyManage/uploadExcelData', formData).then((result) => {

            const rsltCnt = result.data.rsltCnt;
            const errorMsg = result.data.errorMsg;
            console.log('result: ', result);
            if (rsltCnt > 0) {
                toast.success(messageCodeDic['001'])
                datatable?.draw();

            } else {
                toast.error(errorMsg);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });


    }

    return (
        <Container fluid className="bg-white px-4 pt-4 sh-text-font_primary">
            <ExcelFormUploadModal formCd={'D002003'} isReadOnly={true} setModalIsOpen={setIsExcelFormOpen} modalIsOpen={isExcelFormOpen} />
            <SubMenuTitle title={'예방안전부 주관'} parentMenu={'현장 안전점검'}/>
            <SiteSftyChkRgstModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} prcsGb={prcsGb}
                                 detailData={detailData} sprvDprtGb={'C006001'}/>
            <SiteSftyChkRsltModal setModalIsOpen={setRsltModalIsOpen} modalIsOpen={rsltModalIsOpen}
                                  detailData={detailData} />
            <form name={'form'} ref={formRef} encType="multipart/form-data" method={"post"}></form>
                <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                    <SHInput type={'hidden'} name={'sprvDprtGb'} value={'C006001'}></SHInput>
                    <Row>
                        <Col>
                            <Table className="sh-font-SP sh-fs-b2 my-3">
                                <tbody>
                                <tr className="border-top align-middle">
                                    <th className="sh-bg-th"  style={{width:'6%'}}>공종</th>
                                    <td style={{width:'19%'}}>
                                        <SHSelect initOptionGb={'all'} className={'form-select'} name={'workTypeCd'} codeGb={'cmmn'} codeGrpId={'T002'}
                                                  onChange={fn_setSearchData}
                                                  value={srchData?.workTypeCd}></SHSelect>
                                    </td>
                                    <th className="sh-bg-th" style={{width:'6%'}}>진행상황</th>
                                    <td style={{width:'19%'}}>
                                        <SHSelect initOptionGb={'all'} className={'form-select'} name={'sftyChkGbCd'} codeGb={'cmmn'} codeGrpId={'C003'}
                                                  onChange={fn_setSearchData}
                                                  value={srchData?.sftyChkGbCd}></SHSelect>
                                    </td>
                                    <th className="sh-bg-th" style={{width:'6%'}}>점검일시</th>
                                    <td style={{width:'35%'}}>
                                        <div className="d-flex align-items-center" ref={prodRef}>
                                            <SHDatePicker2 setter={setStartDate} name={'startSftyChkDt'} defaultValue={startDate} onChange={fn_setSearchData}/>
                                            <span>&nbsp;~&nbsp;</span>
                                            <SHDatePicker2 setter={setEndDate} name={'endSftyChkDt'} defaultValue={endDate} onChange={fn_setSearchData}/>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </SHSearch>
            {user.authCode != 'headRead' &&
            <div className={'w-100 d-flex justify-content-between'}>
                <div className='w-100 d-flex justify-content-left align-items-center mb-2'>
                    <input className={'hidden'}  id="input-file" type={"file"} ref={fileRef} onChange={fn_addFile} />
                    <label htmlFor="input-file" className={'sh-btn normal p cursor-pointer'} onClick={(e: any) => {
                        fileRef.current.value = null}

                    }> 엑셀 업로드
                    </label>
                    <button className={'ml-5 sh-btn normal p cursor-pointer'} onClick={() => setIsExcelFormOpen(true)} >업로드 양식 다운로드</button>

                </div>

            <div className='w-100 d-flex justify-content-end align-items-center mb-2'>

                <SHIconButton kind={'submit'} marginRight={'5px'} onClick={fn_registPopOpen}> 계획등록
                </SHIconButton>

                <SHIconButton kind={'delete'} marginRight={'0'} onClick={fn_delete}> 삭제
                </SHIconButton>
            </div>
            </div>
            }
            <div className={'ge-result'}>
                <Datatables
                    dataUrl={'/admin/api/siteSftyManage/retrieveSiteSftyManageDataList'}
                    parameterFormRef={searchFormRef}
                    columns={[
                        {data: "workTypeNm", title: "공종"},
                        {data: "sftyChkDt", title: "점검일시", render: (data: any, type: any, row: any) => {
                                return row.sftyChkDt + "&nbsp;&nbsp;" + row.sftyChkHour + ':' + row.sftyChkMin;
                            }},
                        {data: "siteName", title: "점검현장"},
                        {data: "siteAddress", title: "현장주소"},
                        {data: "extnlCommisnrNm", title: "외부위원"},
                        {data: "extnlCommisnrPhone", title: "핸드폰번호"},
                        {data: "dprtNm", title: "점검부서"},
                        {data: "sftyChkGbNm", title: "진행상황"},
                        {data: '', title: '점검결과등록', render: (data: any, type: any, row: any) => {
                                if(['결과확인 대기중', '이상없음'].includes(row.sftyChkGbNm)){
                                    return "<div class='sh-box-status refuse'><button>결과확인</button></div>";
                                }else if(row.sftyChkGbNm == '최종확인 대기중'){
                                    return "<div class='sh-box-status approval'><button>최종확인</button></div>";
                                }else if(row.sftyChkGbNm == '점검완료'){
                                    return "<div class='sh-box-status approvaldone'><button>확인</button></div>";
                                } else {
                                    return "<div class='sh-box-status writing'><button>결과등록</button></div>";
                                }

                        }},
                    ]}
                    checked={true}
                    index={true}

                    onLoad={(dt: any) => {
                        setDatatable(dt)
                    }}
                    onClick={(data, gb) => {

                        if(data.sftyChkGbNm == '현장조치중') {
                            setPrcsGb('actRgst');
                        } else {
                            setPrcsGb('rsltRgst');
                        }
                        setDetailData(data)
                        setModalIsOpen(true);



                    }
                    }
                />
            </div>
        </Container>
    );
};

export default SiteSftyChkPrvntPage;
