import React, {useContext, useEffect, useRef, useState} from 'react';
import {Col, Container, Row, Table } from 'react-bootstrap';
import SHSearch from '../component/SHSearch';
import SubMenuTitle from "../component/SubMenuTitle";
import SHDatePicker from "../../components/common/SHDatePicker";
import CustomDatePickerRange from "../component/ui/DatePickerRange";
import SHSelect from "../../components/common/SHSelect";
import SHInput from "../../components/common/SHInput";
import {DateHelper} from "../../helper/DateHelper";
import moment from "moment";
import SHDatePicker2 from "../../components/common/SHDatePicker2";
import {getLoginUser} from "../../helper/SecurityHelper";
import {UserContext} from "../../context/User";
import restapi from "../../helper/restAPIHelper";
import {toast} from "react-toastify";
import errorCodeDic from "../../util/errorCodeDic";
import {ProgressContext} from "../../context/Progress";

type Props = {};

const SafetyWorkReportManagementPage = (props: Props) => {
    const prodRef = useRef<any>();
    const defaultUser = getLoginUser();
    const {user} = useContext<any>(UserContext);
    const [strtDt, setStrtDt] = useState<Date>(DateHelper.dateByMonth(-3));
    const [endDt, setEndDt] = useState<Date>(DateHelper.dateByMonth(0));
    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);
    const [srchData, setSrchData] = useState<any>({strtDt: strtDt, endDt:endDt, siteId: user?.blngSiteId || defaultUser.blngSiteId})
    const {spinner} = useContext(ProgressContext);
    const [inWrkrStatByWrkTpAndCmpnyList, setInWrkrStatByWrkTpAndCmpnyList] = useState<any>([]);
    const [wrkPrmtStatByWrkTpAndCmpnyList, setWrkPrmtStatByWrkTpAndCmpnyList] = useState<any>([]);
    const [riskFctrStatByWrkTpAndCmpnyList, setRiskFctrStatByWrkTpAndCmpnyList] = useState<any>([]);
    const [incgrtyStatList, setIncgrtyStatList] = useState<any>([]);
    const [ystrTodayInWrkrStat, setYstrTodayInWrkrStat] = useState<any>({});
    const [totStatData, setTotStatData] = useState<any>({
        inWrkrStatA: 0, inWrkrStatB: 0, inWrkrStatC: 0, wrkPrmtStatA: 0, wrkPrmtStatB: 0, wrkPrmtStatC: 0, wrkPrmtStatD: 0,
        riskFctrStatA: 0, riskFctrStatB: 0, riskFctrStatC: 0, riskFctrStatD: 0, incgrtyStaA: 0
    });


    useEffect(() => {
        if(user){
            setSrchData({
                ...srchData,
                siteId: user.blngSiteId
            })
        }
    }, [user]);

    useEffect(() => {
        fn_retrieveWrkRprtStatData(srchData);
        console.log('srchData: ', srchData);
    }, [srchData]);


    const fn_retrieveWrkRprtStatData = (param: any) => {
        spinner.start();
        restapi.retrieveWrkRprtStatData(param).then((rsltData: any) => {
            const inWrkrStatByWrkTpAndCmpny = rsltData.inWrkrStatByWrkTpAndCmpny;
            const wrkPrmtStatByWrkTpAndCmpny = rsltData.wrkPrmtStatByWrkTpAndCmpny;
            const riskFctrStatByWrkTpAndCmpny = rsltData.riskFctrStatByWrkTpAndCmpny;
            const incgrtyStat = rsltData.incgrtyStat;
            const ystrTodayInWrkrStat = rsltData.ystrTodayInWrkrStat;
            let totStatData = {
                inWrkrStatA: 0, inWrkrStatB: 0, inWrkrStatC: 0, wrkPrmtStatA: 0, wrkPrmtStatB: 0, wrkPrmtStatC: 0, wrkPrmtStatD: 0,
                riskFctrStatA: 0, riskFctrStatB: 0, riskFctrStatC: 0, riskFctrStatD: 0, incgrtyStaA: 0
            }

            setInWrkrStatByWrkTpAndCmpnyList(inWrkrStatByWrkTpAndCmpny);
            setWrkPrmtStatByWrkTpAndCmpnyList(wrkPrmtStatByWrkTpAndCmpny);
            setRiskFctrStatByWrkTpAndCmpnyList(riskFctrStatByWrkTpAndCmpny);
            setIncgrtyStatList(incgrtyStat);
            setYstrTodayInWrkrStat(ystrTodayInWrkrStat);

            inWrkrStatByWrkTpAndCmpny.map((data: any) => {
                totStatData.inWrkrStatA += data.inWrkrCntNotToday;
                totStatData.inWrkrStatB += data.inWrkrCntToday;
                totStatData.inWrkrStatC += (data.inWrkrCntNotToday + data.inWrkrCntToday);
            })

            wrkPrmtStatByWrkTpAndCmpny.map((data: any) => {
                totStatData.wrkPrmtStatA += data.wrkCmpltdCnt;
                totStatData.wrkPrmtStatB += data.apprCmpltdCnt;
                totStatData.wrkPrmtStatC += data.rjctCnt;
                totStatData.wrkPrmtStatD += data.accmCnt
            })

            riskFctrStatByWrkTpAndCmpny.map((data: any) => {
                totStatData.riskFctrStatA += data.upperCnt
                totStatData.riskFctrStatB += data.midCnt
                totStatData.riskFctrStatC += data.underCnt
                totStatData.riskFctrStatD += data.accmCnt;
            })

            incgrtyStat.map((data: any) => {
                totStatData.incgrtyStaA += data.prcsStatCnt
            })
            setTotStatData(totStatData);

        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });
    }


    const fn_setFormData = (e: any) => {

        setSrchData({
            ...srchData,
            [e.target.name]: e.target.value
        })

    }

    const fn_datePopupOpen = () => {
        setIsDatePopupOpen(!isDatePopupOpen);
    };

    return (
        <Container fluid className="bg-white px-4 pt-4 sh-txt-color-primary">
            <SubMenuTitle title={'안전 정보 통계 관리'} parentMenu={'현장안전관리'}/>
            <div className="text-[#222222] overflow-y-auto" style={{height: 'calc(100vh - 180px)'}}>
                <SHSearch btnType={'p'}>
                    <Row>
                        <Col>
                            <Table className="sh-font-SP sh-fs-b2 my-3">
                                <tbody>
                                <tr className="border-top align-middle border-bottom">
                                    <th className="sh-bg-th">기간</th>
                                    <td>
                                        <div className="d-flex align-items-center" ref={prodRef}>
                                            <SHDatePicker2 name={'strtDt'} defaultValue={strtDt}
                                                            onChange={fn_setFormData}/>
                                            <span>&nbsp;~&nbsp;</span>
                                            <SHDatePicker2 name={'endDt'} defaultValue={endDt}
                                                            onChange={fn_setFormData}/>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </SHSearch>
                <div className="sh-modal-div">
                    <div className="div-header">
                        출역통계
                    </div>
                    <div className="div-body px-3">
                        <Row>
                            <Col bsPrefix="col-12">
                                <Container className='sh-box-form py-2 mt-2'>
                                    <table
                                        className="sh-table-primary w-100 dataTable no-footer">
                                        <thead>
                                        <tr className="table-secondary">
                                            <th className="text-center">공종</th>
                                            <th className="text-center">업체명</th>
                                            <th className="text-center">기간 내 총계(당일제외)</th>
                                            <th className="text-center">당일</th>
                                            <th className="text-center">누계</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            inWrkrStatByWrkTpAndCmpnyList.length > 0 ?

                                                inWrkrStatByWrkTpAndCmpnyList.map((data: any, idx: number) => (
                                                    <tr key={idx}>
                                                        <td className={'text-center'}>
                                                            {data.workTypeNm}
                                                        </td>
                                                        <td className={'text-center'}>
                                                            {data.cmpnyNm}
                                                        </td>
                                                        <td className={'text-center'}>
                                                            {data.inWrkrCntNotToday}
                                                        </td>
                                                        <td className={'text-center'}>
                                                            {data.inWrkrCntToday}
                                                        </td>
                                                        <td className={'text-center'}>
                                                            {data.inWrkrCntNotToday + data.inWrkrCntToday}
                                                        </td>
                                                    </tr>
                                                ))


                                                : <tr><td colSpan={5}>조회된 내역이 없습니다</td></tr>
                                        }
                                        {
                                            inWrkrStatByWrkTpAndCmpnyList.length > 0 &&
                                            <tr>
                                                <td colSpan={2} className={'text-center bg-[#00D078] text-white font-bold'}>
                                                    출역인원 총계
                                                </td>
                                                <td className={'text-center'}>
                                                    {totStatData.inWrkrStatA}
                                                </td>
                                                <td className={'text-center'}>
                                                    {totStatData.inWrkrStatB}
                                                </td>
                                                <td className={'text-center'}>
                                                    {totStatData.inWrkrStatC}
                                                </td>
                                            </tr>
                                        }

                                        </tbody>
                                    </table>
                                </Container>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="sh-modal-div">
                    <div className="div-header">
                        작업통계
                    </div>
                    <div className="div-body px-3">
                        <Row>
                            <Col bsPrefix="col-12">
                                <Container className='sh-box-form py-2 mt-2'>
                                    <table
                                        className="sh-table-primary w-100 dataTable no-footer">
                                        <thead>
                                        <tr className="table-secondary">
                                            <th className="text-center">공종</th>
                                            <th className="text-center">업체명</th>
                                            <th className="text-center">작업완료</th>
                                            <th className="text-center">결재완료</th>
                                            <th className="text-center">반려</th>
                                            <th className="text-center">누계</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            wrkPrmtStatByWrkTpAndCmpnyList.length > 0 ?
                                                wrkPrmtStatByWrkTpAndCmpnyList.map((data: any, idx: number) => (
                                                    <tr key={idx}>
                                                        <td className={'text-center'}>
                                                            {data.workTypeNm}
                                                        </td>
                                                        <td className={'text-center'}>
                                                            {data.cmpnyNm}
                                                        </td>
                                                        <td className={'text-center'}>
                                                            {data.wrkCmpltdCnt}
                                                        </td>
                                                        <td className={'text-center'}>
                                                            {data.apprCmpltdCnt}
                                                        </td>
                                                        <td className={'text-center'}>
                                                            {data.rjctCnt}
                                                        </td>
                                                        <td className={'text-center'}>
                                                            {data.accmCnt}
                                                        </td>
                                                    </tr>
                                                )) : <tr><td colSpan={6} className={'text-center'}>조회된 내역이 없습니다</td></tr>
                                        }
                                        {
                                            wrkPrmtStatByWrkTpAndCmpnyList.length > 0 &&
                                            <tr>
                                                <td colSpan={2} className={'text-center bg-[#00D078] text-white font-bold'}>
                                                    작업허가서 총계
                                                </td>
                                                <td className={'text-center'}>
                                                    {totStatData.wrkPrmtStatA}
                                                </td>
                                                <td className={'text-center'}>
                                                    {totStatData.wrkPrmtStatB}
                                                </td>
                                                <td className={'text-center'}>
                                                    {totStatData.wrkPrmtStatC}
                                                </td>
                                                <td className={'text-center'}>
                                                    {totStatData.wrkPrmtStatD}
                                                </td>
                                            </tr>
                                        }

                                        </tbody>
                                    </table>
                                </Container>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="sh-modal-div">
                    <div className="div-header">
                        위험성 평가 통계
                    </div>
                    <div className="div-body px-3">
                        <Row>
                            <Col bsPrefix="col-12">
                                <Container className='sh-box-form py-2 mt-2'>
                                    <table
                                        className="sh-table-primary w-100 dataTable no-footer">
                                        <thead>
                                        <tr className="table-secondary">
                                            <th rowSpan={2} className="text-center">공종</th>
                                            <th rowSpan={2} className="text-center">업체명</th>
                                            <th colSpan={3} className="text-center">위험도</th>
                                            <th rowSpan={2} className="text-center">누계</th>
                                        </tr>
                                        <tr className="table-secondary">

                                            <th className="text-center">상</th>
                                            <th className="text-center">중</th>
                                            <th className="text-center">하</th>

                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            riskFctrStatByWrkTpAndCmpnyList.length > 0 ?
                                                riskFctrStatByWrkTpAndCmpnyList.map((data: any, idx: number) => (
                                                    <tr key={idx}>
                                                        <td className={'text-center'}>
                                                            {data.workTypeNm}
                                                        </td>
                                                        <td className={'text-center'}>
                                                            {data.cmpnyNm}
                                                        </td>
                                                        <td className={'text-center'}>
                                                            {data.upperCnt}
                                                        </td>
                                                        <td className={'text-center'}>
                                                            {data.midCnt}
                                                        </td>
                                                        <td className={'text-center'}>
                                                            {data.underCnt}
                                                        </td>
                                                        <td className={'text-center'}>
                                                            {data.accmCnt}
                                                        </td>
                                                    </tr>
                                                )) : <tr><td colSpan={6} className={'text-center'}>조회된 내역이 없습니다</td></tr>
                                        }

                                        {
                                            riskFctrStatByWrkTpAndCmpnyList.length > 0 &&
                                            <tr>
                                                <td colSpan={2}  className={'text-center bg-[#00D078] text-white font-bold'}>
                                                    총계
                                                </td>
                                                <td className={'text-center'}>
                                                    {totStatData.riskFctrStatA}
                                                </td>
                                                <td className={'text-center'}>
                                                    {totStatData.riskFctrStatB}
                                                </td>
                                                <td className={'text-center'}>
                                                    {totStatData.riskFctrStatC}
                                                </td>
                                                <td className={'text-center'}>
                                                    {totStatData.riskFctrStatD}
                                                </td>
                                            </tr>
                                        }
                                        </tbody>
                                    </table>
                                </Container>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="sh-modal-div">
                    <div className="div-header">
                        부적합 통계
                    </div>
                    <div className="div-body px-3">
                        <Row>
                            <Col bsPrefix="col-12">
                                <Container className='sh-box-form py-2 mt-2'>
                                    <table
                                        className="sh-table-primary w-100 dataTable no-footer">
                                        <thead>
                                        <tr className="table-secondary">
                                            <th className="text-center">유형</th>
                                            <th className="text-center">수량</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            incgrtyStatList.length > 0 ?
                                                incgrtyStatList.map((data: any, idx: number) => (
                                                    <tr key={idx}>
                                                        <td className={'text-center'}>
                                                            {data.prcsStatNm}
                                                        </td>
                                                        <td className={'text-center'}>
                                                            {data.prcsStatCnt}
                                                        </td>
                                                    </tr>
                                                )) : <tr><td colSpan={2}  className={'text-center'}>조회된 내역이 없습니다</td></tr>

                                        }
                                        {
                                            riskFctrStatByWrkTpAndCmpnyList.length > 0 &&
                                            <tr>
                                                <td className={'text-center bg-[#00D078] text-white font-bold'}>
                                                    총계
                                                </td>
                                                <td className={'text-center'}>
                                                    {totStatData.incgrtyStaA}
                                                </td>
                                            </tr>
                                        }

                                        </tbody>
                                    </table>
                                </Container>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

        </Container>
    );
};

export default SafetyWorkReportManagementPage;
