import Modal from 'react-bootstrap/Modal';
import {IoMdClose} from "react-icons/io";
import React, {Dispatch, MutableRefObject, SetStateAction, useContext, useEffect, useRef, useState} from "react";

import {Col, Container, ModalBody, Row, Table} from "react-bootstrap";

import axios from "axios";
import {toast} from "react-toastify";
import {fn_getCurrentDate} from '../../helper/CmmnHelper';
import {getLoginUser} from '../../helper/SecurityHelper';
import {ProgressContext} from '../../context/Progress';
import restapi from "../../helper/restAPIHelper";
import {UserContext} from "../../context/User";
import errorCodeDic from "../../util/errorCodeDic";
import SubMenuTitle from "../component/SubMenuTitle";
import CmpnyCardList from "../../components/cmpny/CmpnyCardList";
import SHFile from "../../components/common/SHFile";
import SHInput from "../../components/common/SHInput";
import messageCodeDic from "../../util/messageCodeDic";
import SHTextarea from "../../components/common/SHTextarea";
import {NUMBER, readonlyForm, validFormData} from "../../helper/FormHelper";
import SHSelect from "../../components/common/SHSelect";
import SHIconButton from "../../components/common/SHIconButton";
import SHDatePicker2 from "../../components/common/SHDatePicker2";
import {DateHelper} from "../../helper/DateHelper";


type IProps = {};


const SubSiteInfoManagePage = (props: IProps) => {
    const prodRef = useRef<any>();
    const currentDate = fn_getCurrentDate();
    const defaultUser = getLoginUser();
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);
    const [cmpnyDataList, setCmpnyDataList] = useState<object[]>([])
    const [data, setData] = useState<any>()
    const formRef = React.createRef<HTMLFormElement>();
    const [fileList, setFileList] = useState([]);
    const [repFileSn, setRepFileSn] = useState<any>(null);
    const {spinner} = useContext(ProgressContext);
    const {user} = useContext<any>(UserContext);

    useEffect(() => {
        readonlyForm(formRef);
    }, [])

    useEffect(() => {
        const siteId = user?.blngSiteId || defaultUser.blngSiteId;
        spinner.start()
        restapi.retrieveSiteDataListNoPaging({siteId: siteId}).then((rsltData: any) => {
            const dtlData = rsltData[0];


            setData(dtlData);
            setStartDate(DateHelper.parse(dtlData.constructionStartDate))
            setEndDate(DateHelper.parse(dtlData.constructionEndDate))
            restapi.retrieveCmpnySiteDataList({siteId: dtlData.siteId}).then((rsltData) => {
                setCmpnyDataList(rsltData);
            })
            if (dtlData.atchFileId) {
                restapi.retrieveAttachInfoList({attachSeq: dtlData.atchFileId}).then((rsltData: any) => {

                    if (dtlData?.repPhotoSn !== null && dtlData?.repPhotoSn !== undefined) {
                        rsltData.map((data: any, idx: number) => {
                            if (data.sn == dtlData.repPhotoSn) {
                                data.isRep = true
                                setRepFileSn(idx);

                            }
                        })
                    }

                    setFileList([].concat(rsltData));
                })
            } else {
                setFileList([]);
            }

        }).catch(() => {
                toast.error(errorCodeDic['003'])
            }
        ).finally(() => {
            spinner.stop();
        })
    }, [user])


    const fn_datePopupOpen = () => {
        setIsDatePopupOpen(!isDatePopupOpen);
    };


    const fn_openAddrPop = () => {
        // @ts-ignore
        new daum.Postcode({
            oncomplete: function (rtrnData: any) {
                // get lon, lat
                var lon = ''
                var lat = ''
                // @ts-ignore
                var geocoder = new kakao.maps.services.Geocoder();
                geocoder.addressSearch(rtrnData.address, function (result: any, status: any) {
                    // @ts-ignore
                    if (status === kakao.maps.services.Status.OK) {
                        lon = result[0].x
                        lat = result[0].y
                    }
                    setData({
                        ...data,
                        siteAddress: rtrnData.address,
                        longitude: lon,
                        latitude: lat
                    })
                });
            }
        }).open();
    }

    const fn_setFormData = (e: any) => {
        if (e.target.name == 'cnstrPrice') {
            if (e.target.value) {
                e.target.value = parseInt(e.target.value.replaceAll(',', '')).toLocaleString()
            }

        }

        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const fn_registData = () => {
        var option = {
            addValues: {
                regSeq: defaultUser.userSeq,
                updateSeq: defaultUser.userSeq,
            },
            castType: {
                cnstrPrice: NUMBER,
            },
            validOpt: {
                siteName: '현장명을 입력해 주십시오.',
                siteAddress: '주소를 입력해 주십시오.',
                constructionStartDate: '공사기간을 입력해 주십시오.',
                constructionEndDate: '공사기간을 입력해 주십시오.',

            },
            files: ['photos']
        }

        var formData: any = validFormData(formRef, option)
        if (!formData) {
            return;
        }

        if (fileList.length > 0 && repFileSn === null) {
            toast.warn('대표 현장사진을 선택해 주십시오')
            return;
        }

        if (fileList.length > 0) {
            formData.set('repFileSn', repFileSn);
        }

        formData.set('longitude', data.longitude);
        formData.set('latitude', data.latitude);
        formData.set('siteType', data.siteType);

        console.log('formData.get: ', formData.get('photos'));
        if (formData) {
            if (!window.confirm('저장 하시겠습니까?')) {
                return;
            }

            spinner.start();
            axios.post('/admin/api/site/saveSiteData', formData).then((result) => {
                    if (result.data > 0) {
                        toast.success(messageCodeDic['001'])
                    } else {
                        toast.error(errorCodeDic['003']);
                    }
                }
            ).catch(error => {
                toast.error(errorCodeDic['003']);
                console.log('error: ', error);
            })
                .finally(() => {
                    spinner.stop();
                });
        }
    }


    return (
        <Container fluid className="bg-white px-4 pt-4 sh-text-font_primary">
            <SubMenuTitle title={'공사기본정보관리'} parentMenu={'현장공사관리'}/>

            <div className='w-100 d-flex justify-content-end align-items-center mb-2'>
                <SHIconButton kind={'submit'} onClick={fn_registData}>저장</SHIconButton>
            </div>


            <form name={'form'} ref={formRef} encType="multipart/form-data" method={"post"}>
                <SHInput type={'hidden'} value={data?.atchFileId} name={'atchFileId'}></SHInput>
                <SHInput type={'hidden'} value={data?.siteId} name={'siteId'}></SHInput>
                <div className="sh-modal-div">
                    <div className="div-header">
                        공사 기본정보
                    </div>
                    <div className="div-body px-3">
                        <Table className="my-2 align-middle">
                            <tbody>
                            <tr className="border-top align-middle">
                                <th className="sh-bg-th" style={{width: '12%'}}>
                                    현장명
                                </th>
                                <td style={{width: '38%'}}>
                                    <SHInput name={'siteName'} value={data?.siteName} onChange={fn_setFormData}
                                             className="form-control"/>
                                </td>
                                <th className="sh-bg-th" style={{width: '12%'}}>
                                    공사기간
                                </th>
                                <td style={{width: '38%'}}>
                                    <div className="d-flex align-items-center w-100" ref={prodRef}>
                                        <SHDatePicker2 setter={setStartDate} name={'constructionStartDate'} defaultValue={startDate} disabled={true}/>
                                        <span>&nbsp;~&nbsp;</span>
                                        <SHDatePicker2 setter={setEndDate} name={'constructionEndDate'} defaultValue={endDate} disabled={true}/>
                                    </div>
                                </td>
                            </tr>
                            <tr className="border-top align-middle">
                                <th className="sh-bg-th">
                                    공종
                                </th>
                                <td>
                                    <SHSelect initOptionGb={'sel'} disabled={true} className={'sh-select'} codeGb={'cmmn'}
                                              codeGrpId={'T002'}
                                              name={'siteType'} onChange={fn_setFormData}
                                              value={data?.siteType}></SHSelect>

                                </td>
                                <th className="sh-bg-th">
                                    공사금액
                                </th>
                                <td>
                                    <div className="d-flex align-items-center px-0">
                                        <SHInput type="text" className="text-right form-control"
                                                 name={'cnstrPrice'} onChange={fn_setFormData}
                                                 value={data?.cnstrPrice?.toLocaleString()}/>
                                        <span>원</span>
                                    </div>
                                </td>
                            </tr>

                            <tr className="border-top align-middle">
                                <th className="sh-bg-th">
                                    주소
                                </th>
                                <td colSpan={3}>
                                    <div
                                        className="d-flex align-items-center justify-content-between w-100 p-0 sh-fs-b3">
                                        <SHInput type="text" name={'siteAddress'} value={data?.siteAddress}
                                                 className="form-control" readOnly/>
                                        {/*<button className="sh-btn btn-secondary min-w-[50px] ml-5 mr-10"
                                                type="button" onClick={fn_openAddrPop}>검색
                                        </button>*/}
                                        <span className={"min-w-[40px]"}>경도</span>
                                        <SHInput type="text" className="w-[100px] form-control"
                                                 name={'longitude'} value={data?.longitude} disabled={true}/>
                                        <span className={"min-w-[40px] ml-10"}>위도</span>
                                        <SHInput type="text" className="w-[100px] form-control" name={'latitude'}
                                                 value={data?.latitude} disabled={true}/>

                                    </div>
                                </td>
                            </tr>
                            <tr className="border-top align-middle">
                                <th className="sh-bg-th">
                                    상세주소
                                </th>
                                <td colSpan={3}>
                                    <div className="d-flex align-items-center px-0">
                                        <SHInput type="text" className={'form-control'}
                                                 onChange={fn_setFormData} name={'siteDtlAddress'}
                                                 value={data?.siteDtlAddress}/>
                                    </div>
                                </td>
                            </tr>

                            <tr className="border-top align-middle">
                                <th className="sh-bg-th">
                                    배정업체<br/>목록
                                </th>
                                <td colSpan={3}>
                                    <div className="w-100">
                                        <CmpnyCardList items={cmpnyDataList}/>
                                    </div>
                                </td>
                            </tr>
                            <tr className="border-top align-middle">
                                <th className="sh-bg-th">
                                    현장사진
                                </th>
                                <td colSpan={3}>
                                    <SHFile fileName={'photos'} fileList={fileList} setFileList={setFileList}
                                            repFileSn={repFileSn} setRepFileSn={setRepFileSn}></SHFile>

                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className="sh-modal-div">
                    <div className="div-header">
                        작성정보
                    </div>
                    <div className="div-body px-3">
                        <Table className="my-2 align-middle">
                            <tbody>
                            <tr className="border-top align-middle">
                                <th className="sh-bg-th" style={{width: '10%'}}>
                                    작성일
                                </th>
                                <td style={{width: '40%'}}>
                                    <SHInput value={currentDate} name={'rgstDt'}
                                             className={"form-control all-white"} disabled={true}/>
                                </td>
                                <th className="sh-bg-th" style={{width: '10%'}}>
                                    작성자
                                </th>
                                <td style={{width: '40%'}}>
                                    <SHInput className={"form-control all-white"} name={'rgstrNm'}
                                             value={data?.rgstrNm} disabled={true}/>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </form>
        </Container>

    )
}

export default SubSiteInfoManagePage;

