import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {fn_getCurrentDate} from "../../../helper/CmmnHelper";
import {getLoginUser} from "../../../helper/SecurityHelper";
import SHInput from "../../../components/common/SHInput";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import SHSelect from "../../../components/common/SHSelect";
import {ChangeMeta, NumberFormatBase} from "react-number-format";
import {Formatter} from "../../../helper/FormatHelper";
import iconTrashBean from "../../../images/icon_remove.png";
import restapi from "../../../helper/restAPIHelper";
import {AxiosResponse} from "axios/index";
import {ProgressContext} from "../../../context/Progress";
import {readonlyForm} from "../../../helper/FormHelper";


interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
}

const responseBody = (response: AxiosResponse) => response.data;
const requests = {
    get: (url: string) => axios.get(url).then(responseBody),
    post: (url: string, body: any, option: any) =>
        axios.post(url, body, option).then(responseBody),
    put: (url: string, body: object, option: any) =>
        axios.put(url, body, option).then(responseBody),
    delete: (url: string) => axios.delete(url).then(responseBody),
};

const AsscWrkDtlModal = (props: IProps) => {

    const currentDate = fn_getCurrentDate();
    const user = getLoginUser();
    const [isEmployeeSiteAuthPopOpend, setIsEmployeeSiteAuthPopOpend] = React.useState<boolean>(false);
    const [data, setData] = useState<any>()
    const [slctdEmployeeSiteAuthData, setSlctdEmployeeSiteAuthData] = useState<any>();
    const [employeeSiteAuthDataList, setEmployeeSiteAuthDataList] = useState<[]>([]);
    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);
    const [userBirthday, setUserBirthday] = useState<string>('');
    const prodRef = useRef<any>();
    const [isDuplChCked, setIsDuplChCked] = useState<boolean>(false);
    const {spinner, setProcess} = useContext(ProgressContext);
    const fileRef = useRef() as React.MutableRefObject<any>;
    const formRef = React.createRef<HTMLFormElement>();


    const fn_setFormData = (e: any) => {


        if (e.target.type == 'checkbox') {
            setData({
                ...data,
                [e.target.name]: e.target.checked
            })
        } else {
            if (e.target.name == 'cnstrPrice') {
                e.target.value = parseInt(e.target.value.replaceAll(',', '')).toLocaleString()
            }
            setData({
                ...data,
                [e.target.name]: e.target.value
            })
        }


    }

    useEffect(() => {
        if (slctdEmployeeSiteAuthData) {
            let isDupl = false;
            console.log("employeeSiteAuthDataList", employeeSiteAuthDataList);
            employeeSiteAuthDataList.map((data: any) => {
                if (data.siteId == slctdEmployeeSiteAuthData.siteId
                    && data.cmpnySeq == slctdEmployeeSiteAuthData.cmpnySeq
                    && data.wrkTpCd == slctdEmployeeSiteAuthData.wrkTpCd
                    && data.ocptCd == slctdEmployeeSiteAuthData.ocptCd) {
                    isDupl = true;
                }
            })

            if (isDupl) {
                toast.warn('중복입력 입니다.');
            } else {
                let cstmEmployeeSiteAuthDataList: any = employeeSiteAuthDataList;
                if (cstmEmployeeSiteAuthDataList.length == 0) {
                    slctdEmployeeSiteAuthData.isRep = true
                }
                console.log('slctdEmployeeSiteAuthData: ', slctdEmployeeSiteAuthData);
                cstmEmployeeSiteAuthDataList.push(slctdEmployeeSiteAuthData);
                setEmployeeSiteAuthDataList(cstmEmployeeSiteAuthDataList);
            }
            setSlctdEmployeeSiteAuthData(null);
        }
    }, [slctdEmployeeSiteAuthData])

    useEffect(() => {
        console.log('data: ', data);
    }, [data])

    const fn_onAfterOpen = () => {

        // 신규

            setUserBirthday(props.detailData.userBirthday);
            setData(props.detailData);
            setIsDuplChCked(true);
            callApi.retrieveEmployeeSiteAuthDataList(props.detailData.userSeq).then((rsltData) => {
                setEmployeeSiteAuthDataList(rsltData);
            })
            setSlctdEmployeeSiteAuthData(null)
            readonlyForm(formRef);
    }

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const submit = (e: any) => {
        e.preventDefault()
    }


    /**
     * 현장정보 목록 조회
     * @param userSeq
     */
    const callApi = {
        retrieveEmployeeSiteAuthDataList: (userSeq: number) =>
            requests.post('/admin/api/employee/retrieveEmployeeSiteAuthDataList',
                {userSeq: userSeq},
                null
            ),
    };


    const fn_onfileChange = (e: any) => {
        let cstmData = {...data};

        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            const imgSrc = URL.createObjectURL(file);

            cstmData.fileTmpSrc = imgSrc;
            cstmData.photoFile = file;
            cstmData.isPhotoUpload = true;
            setData(Object.assign({}, cstmData));
        }
    }

    return (
        <div>
            <Modal
                className="sh-modal" size="xl"
                show={props.modalIsOpen}
                onShow={fn_onAfterOpen}
                onHide={fn_closeModal}
            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        상세보기
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <input hidden type={'file'} ref={fileRef} onChange={fn_onfileChange}/>
                    <form name={'form'} ref={formRef} encType="multipart/form-data" method={"post"}
                          onSubmit={submit}>
                        <div className="sh-modal-div">
                            <div className="div-body px-3">
                                    <Table className="my-2 align-middle">
                                        <colgroup>
                                            <col width='15%'/>
                                            <col width='35%'/>
                                            <col width='15%'/>
                                            <col width='35%'/>
                                        </colgroup>
                                        <tbody>
                                        <tr className="border-top align-middle">
                                            <th className="sh-bg-th">근로자 일련번호</th>
                                            <td>
                                                <SHInput type="text" className="form-control" name={'workerSeq'}
                                                         onChange={fn_setFormData} value={data?.workerSeq}/>
                                            </td>
                                            <th className="sh-bg-th">등록일시</th>
                                            <td>
                                                <SHInput type="text" className="form-control" name={'regDate'}
                                                         onChange={fn_setFormData} value={data?.regDate}/>
                                            </td>
                                        </tr>
                                        <tr className="border-top align-middle">
                                            <th className="sh-bg-th">근로자 이름</th>
                                            <td>
                                                <SHInput type="text" className="form-control" name={'userName'}
                                                         onChange={fn_setFormData} value={data?.userName}/>
                                            </td>
                                            <th className="sh-bg-th">주민등록번호 앞자리(생년월일)</th>
                                            <td ref={prodRef}>
                                                <SHInput type="text" className="form-control" name={'userBirthday'}
                                                         onChange={fn_setFormData} value={data?.userBirthday}/>
                                            </td>

                                        </tr>

                                        <tr>
                                            <th className="sh-bg-th">연락처</th>
                                            <td>
                                                {
                                                    //@ts-ignore
                                                    <NumberFormatBase onChange={fn_setFormData} name={'userPhone'}
                                                                      className={'form-control'}
                                                                      value={data?.userPhone || ''}
                                                                      format={Formatter.phoneNumFormat}
                                                    />
                                                }
                                            </td>
                                            <th className="sh-bg-th">직종</th>
                                            <td>
                                                <SHInput type="text" className="form-control" name={'ocptNm'}
                                                         onChange={fn_setFormData} value={data?.ocptNm}/>
                                            </td>
                                        </tr>
                                        <tr className="border-top align-middle">
                                            <th className="sh-bg-th">소속업체</th>
                                            <td>
                                                <SHInput type="text" className="form-control" name={'companyName'}
                                                         onChange={fn_setFormData} value={data?.companyName}/>
                                            </td>
                                            <th className="sh-bg-th">소속현장명</th>
                                            <td ref={prodRef}>
                                                <SHInput type="text" className="form-control" name={'blngSiteNm'}
                                                         onChange={fn_setFormData} value={data?.blngSiteNm}/>
                                            </td>

                                        </tr>
                                        <tr className="border-top align-middle">
                                            <th className="sh-bg-th">국적</th>
                                            <td>
                                                <SHInput type="text" className="form-control" name={'nationNm'}
                                                         onChange={fn_setFormData} value={data?.nationNm}/>
                                            </td>
                                            <th className="sh-bg-th">성별</th>
                                            <td ref={prodRef}>
                                                <SHInput type="text" className="form-control" name={'sexNm'}
                                                         onChange={fn_setFormData} value={data?.sexNm}/>
                                            </td>

                                        </tr>
                                        <tr className="border-top align-middle">
                                            <th className="sh-bg-th">주소</th>
                                            <td>
                                                <SHInput type="text" className="form-control" name={'homeAddress'}
                                                         onChange={fn_setFormData} value={data?.homeAddress}/>
                                            </td>
                                            <th className="sh-bg-th">주소상세</th>
                                            <td ref={prodRef}>
                                                <SHInput type="text" className="form-control" name={'homeDtlAddress'}
                                                         onChange={fn_setFormData} value={data?.homeDtlAddress}/>
                                            </td>

                                        </tr>
                                        </tbody>
                                    </Table>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AsscWrkDtlModal;

