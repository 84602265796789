import React, {useContext, useEffect, useRef, useState} from 'react';
import Datatables, {getCheckedItems} from "../component/Datatables";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import SHSearch from "../component/SHSearch";
import SubMenuTitle from "../component/SubMenuTitle";
import {Col, Container, Row, Table } from 'react-bootstrap';
import SHIconButton from '../../components/common/SHIconButton';
import {ProgressContext} from "../../context/Progress";
import CnstrBaseInfoRgstModal from '../siteConstructionManagement/modal/CnstrBaseInfoRgstModal';
import SiteManageRgstModal from "./modal/SiteManageRgstModal";
import SHInput from "../../components/common/SHInput";
import restapi from "../../helper/restAPIHelper";
import {getLoginUser} from "../../helper/SecurityHelper";
import ExcelFormUploadModal from "../headoffice/modal/ExcelFormUploadModal";
type Props = {};

const SiteManagePage = (props: Props) => {
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [datatable, setDatatable] = useState<any>();
    const [siteDetailData, setSiteDetailData] = useState(null);
    const [isSaveSuccess, setIsSaveSuccess] = useState(false);
    const searchFormRef = useRef<any>();
    const {spinner} = useContext(ProgressContext);
    const user = getLoginUser();
    const fileRef = useRef<any>();
    const [isExcelFormOpen, setIsExcelFormOpen] = useState<boolean>(false);
    const formRef = React.createRef<HTMLFormElement>();
    useEffect(() => {
        if (isSaveSuccess) {
            fn_search();
            setIsSaveSuccess(false);
        }
    }, [isSaveSuccess])

    const fn_search = (e?: any) => {
        if(e){
            e.preventDefault()
        }
        datatable?.draw()
    }

    /**
     * 등록 버튼 클릭
     */
    const fn_registPopOpen = () => {
        setSiteDetailData(null);
        setModalIsOpen(true);
    }

    const fn_delete = () => {
        let checkedData = getCheckedItems(datatable,"siteId")
        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }

        spinner.start();
        restapi.retrieveIsInUseSite({siteIds: checkedData}).then((isInUse: any) => {
            if(isInUse){
                toast.warn('현재 사용자가 사용중인 현장은 삭제할 수 없습니다.');
                return;
            }else{
                if(!window.confirm(messageCodeDic['004'])){
                    return;
                }
                spinner.start();
                axios.post('/admin/api/site/deleteSiteData', {delList: checkedData}).then(rsltData => {
                    if (rsltData.data > 0) {
                        toast.success(messageCodeDic['002'])
                        fn_search();
                    } else {
                        toast.error(errorCodeDic['003'])
                    }
                }).catch(() => {
                    toast.error(errorCodeDic['003'])
                }).finally(() => {
                    spinner.stop();
                });

            }
        }).catch(() => {
            toast.error(errorCodeDic['003'])
        }).finally(() => {
            spinner.stop();
        });


    }

    const fn_insertData = () => {
        spinner.start();
        axios.post('/admin/api/site/inserSmRskAsmntFctrCodeData', {}).then((rsltData: any) => {
            if (rsltData.data > 0) {
                toast.success(messageCodeDic['002'])
            } else {
                toast.error(errorCodeDic['003'])
            }
        }).catch(() => {
            toast.error(errorCodeDic['003'])
        }).finally(() => {
            spinner.stop();
        });
    }

    const fn_copySite = () => {
        spinner.start();
        axios.post('/admin/api/site/copySite', {}).then((rsltData: any) => {
            if (rsltData.data > 0) {
                toast.success(messageCodeDic['001'])
            } else {
                toast.error(errorCodeDic['003'])
            }
        }).catch(() => {
            toast.error(errorCodeDic['003'])
        }).finally(() => {
            spinner.stop();
        });
    }

    const fn_addFile = (e: any) => {

        e.preventDefault();

        console.log('e.target.value: ', e.target.value);
        console.log('e.target.files: ', e.target.files);
        if (!e.target.files) {
            return;
        }

        // @ts-ignore
        var formData: any = new FormData(formRef.current);
        formData.set('excelFile', e.target.files[0]);

        spinner.start();
        axios.post('/admin/api/site/uploadExcelData', formData).then((result) => {

            const rsltCnt = result.data.rsltCnt;
            const errorMsg = result.data.errorMsg;
            console.log('result: ', result);
            if (rsltCnt > 0) {
                toast.success(messageCodeDic['001'])
                datatable?.draw();

            } else {
                toast.error(errorMsg);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });


    }

    const fn_excelDownload = async () => {

        if(!window.confirm('엑셀 다운로드 하시겠습니까?')){
            return;
        }

        try {
            spinner.start();

            let siteIds = getCheckedItems(datatable,"siteId")

            const response = await axios({
                url: '/admin/api/site/downLoadSiteListExcel',
                method: 'POST',
                responseType: 'blob', // Important: Set the response type to 'blob'
                data: {
                    // Example data you might want to send to the server
                    siteIds: siteIds,
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                },
            }).finally(() => {
                spinner.stop();
            });

            // Retrieve the filename from the Content-Disposition header (optional)
            const filename = '현장 목록.xlsx';

            // Create a URL from the Blob and create a temporary link to download the file
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename ?? 'download.xlsx'); // Default filename if none is provided
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // Remove the link when done
            window.URL.revokeObjectURL(url); // Clean up the URL object
        } catch (error: any) {
            console.error('Download error:', error);
            alert('Failed to download file: ' + error.message);
        }
    }

    return (
        <Container fluid className="bg-white px-4 pt-4 sh-text-font_primary">
            <ExcelFormUploadModal formCd={'D002002'} isReadOnly={true} setModalIsOpen={setIsExcelFormOpen} modalIsOpen={isExcelFormOpen} />
            <SubMenuTitle title={'전체 현장 관리'} parentMenu={'전체 현장 관리'} />
            <SiteManageRgstModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen}
                                    cnstrBaseInfoDtlData={siteDetailData} setIsSaveSuccess={setIsSaveSuccess}/>
            <form name={'form'} ref={formRef} encType="multipart/form-data" method={"post"}></form>
            <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                <Row>
                    <Col>
                        <Table className="sh-font-SP sh-fs-b2 mt-3 mb-3">
                            <tbody>
                            <tr className="border-top border-bottom align-middle">
                               {<SHInput type={'hidden'} name={'isSmSite'} value={'false'}></SHInput>}
                                <th className="sh-bg-th" style={{width:'6%'}}>현장명</th>
                                <td style={{width:'19%', paddingRight:'0'}}>
                                    <input type={'text'} name={"srchTxt"} className='form-control'/>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </SHSearch>
            <div className={'ge-result'}>
            <Datatables
                dataUrl={'/admin/api/site/retrieveSiteDataList'}
                parameterFormRef={searchFormRef}
                columns={[
                    {data: "siteName", title: "현장명", textAlign: 'left'},
                    {data: "siteAddress", title: "주소", textAlign: 'left'},
                    {data: "cnstrProd", title: "공사기간"},
                    {data: "cnstrPrice", title: "공사금액(원)", format: 'withCom', textAlign: 'right'},
                    {data: "rcntUpdateDate", title: "최근 업데이트", orderColumn:"REG_DATE", orderBase:true},
                ]}
                checked={true}
                onLoad={(dt: any) => {
                    setDatatable(dt)
                }}
                onClick={(data) => {
                    setSiteDetailData(Object.assign({},data))
                    setModalIsOpen(true);
                }}
            >
            {user.authCode != 'headRead' &&
                <div className={'w-100 d-flex justify-content-between'}>
                    <div className='w-100 d-flex justify-content-left align-items-center mb-2'>
                        <input className={'hidden'} id="input-file" type={"file"} ref={fileRef} onChange={fn_addFile}/>
                        <label htmlFor="input-file" className={'sh-btn normal p cursor-pointer'} onClick={(e: any) => {
                            fileRef.current.value = null
                        }

                        }> 엑셀 업로드
                        </label>
                        <button className={'ml-5 sh-btn normal p cursor-pointer'}
                                onClick={() => setIsExcelFormOpen(true)}>업로드 양식 다운로드
                        </button>
                        <button className={'ml-5 sh-btn normal p cursor-pointer'}
                                onClick={() => fn_excelDownload()}>엑셀 다운로드
                        </button>
                    </div>

                    <div className='w-100 d-flex justify-content-end align-items-center mb-2'>
                        {/*
                    <SHIconButton kind={'submit'} marginRight={'5px'} onClick={fn_copySite}> 현장복사
                    </SHIconButton>*/}

                    <SHIconButton kind={'submit'} marginRight={'5px'} onClick={fn_registPopOpen}> 등록
                    </SHIconButton>

                    <SHIconButton kind={'delete'} marginRight={'0'} onClick={fn_delete}> 삭제
                    </SHIconButton>
                   {/* <SHIconButton kind={'submit'} marginRight={'5px'} onClick={fn_insertData}> 데이터
                    </SHIconButton>*/}
                </div>
                </div>
                }
            </Datatables>
            </div>

        </Container>
    );
};

export default SiteManagePage;
