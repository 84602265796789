import SHSearch from "../../component/SHSearch";
import {Col, Row, Table} from "react-bootstrap";
import SHSelect from "../../../components/common/SHSelect";
import {CmmnFn} from "../../../helper/CmmnHelper";
import React, {Dispatch, SetStateAction, useRef, useState} from "react";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import SHDatePicker from "../../../components/common/SHDatePicker";
import CustomDatePicker from "../../component/ui/DatePicker";
import {DateHelper} from "../../../helper/DateHelper";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import axios from "axios";
import errorCodeDic from "../../../util/errorCodeDic";
import SHDatePicker2 from "../../../components/common/SHDatePicker2";
import SHIconButton from "../../../components/common/SHIconButton";
import SHInput from "../../../components/common/SHInput";

interface IProps {
    dataList: any;
    setSelData: Dispatch<SetStateAction<any>>;
    srchData: any;
    setSrchData: Dispatch<SetStateAction<boolean>>;
    isDatePopupOpen: boolean;
    setIsDatePopupOpen: Dispatch<SetStateAction<boolean>>;
    onSearch: any;
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    setPicDtlData: Dispatch<SetStateAction<any>>;
    setIsPicModalOpen: Dispatch<SetStateAction<boolean>>;

}

const AlrtMngStatWrkStopPage = (props: IProps) => {
    const prodRef = useRef<any>();
    const [startDate, setStartDate] = useState<Date>(DateHelper.dateByMonth(-1));
    const [endDate, setEndDate] = useState<Date>(DateHelper.dateByMonth(0));
    const [datatable, setDatatable] = useState<any>();

    const fn_setSrchData = (e: any) => {

        props.setSrchData({
            ...props.srchData,
            [e.target.name]: e.target.value
        })

    }


    return(
        <>

            <SHSearch btnType={'p'} onSearch={props.onSearch}>
                <Row>
                    <Col>
                        <Table className="sh-font-SP sh-fs-b2 my-3">
                            <tbody>
                            <tr className="align-middle border-bottom">
                                <th className="sh-bg-th">기간</th>
                                <td>
                                    <div className="d-flex flex-row gap-2">
                                        <div className="d-flex align-items-center" ref={prodRef}>
                                            <SHDatePicker2 name={'strtDt'} readOnly={true} defaultValue={startDate}
                                                            value={props.srchData.strtDt} onChange={fn_setSrchData}/>
                                            <span>&nbsp;~&nbsp;</span>
                                            <SHDatePicker2 name={'endDt'} readOnly={true} defaultValue={endDate}
                                                            value={props.srchData.endDt} onChange={fn_setSrchData}/>
                                        </div>

                                    </div>
                                </td>
                                <th className="sh-bg-th">현장명</th>
                                <td>
                                    <SHInput className={'form-control'} name={'siteName'} value={props.srchData.siteName} onChange={fn_setSrchData} />
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </SHSearch>

            <div className={'ge-result'}>
                <Datatables
                    datas={props.dataList}
                    scrollY="550px"
                    className="sh-fs-b4"
                    columns={[
                        {
                            data: "statCd", title: "상태", textAlign: 'center',
                            render: (data: any, type: any, row: any) => {
                                if (row.statCd == null || row.statCd == '' || row.statCd == 'P001001') {
                                    return '발생';
                                } else if(row.statCd == 'P001002'){
                                    return '해제';
                                } else if(row.statCd == 'P001003'){
                                    return '오류';
                                } else if(row.statCd == 'P001004'){
                                    return '처리중';
                                } else {
                                    return '-';
                                }
                            }
                        },
                        {data: "comments", title: "사유", textAlign: 'center'},
                        {data: "siteName", title: "현장명", textAlign: 'center'},
                        {data: "userName", title: "성명", textAlign: 'center'},
                        {data: "companyName", title: "업체", textAlign: 'center'},
                        {data: "rgstDt", title: "요청일", textAlign: 'center'},
                        {data: "rgstTime", title: "요청시간", textAlign: 'center'},
                        {
                            title: "사진",
                            type: "button",
                            className: "text-center",
                            buttons: [{
                                label: "<img style='height: 20px' class='cursor-pointer' src='/icon_picture.svg' />",
                                onClick: (v: any) => {
                                    if(v.atchFileId){
                                        props.setPicDtlData({title: '사진', fileId: v.atchFileId})
                                        props.setIsPicModalOpen(true);

                                    }else{
                                        toast.warn('등록된 사진이 없습니다');
                                    }
                                },
                            }],
                        },
                    ]}
                    index={true}
                    checked={false}
                    onLoad={(dt: any) => {
                        setDatatable(dt)
                    }}

                    onClick={(data) => {
                        console.log('selData: ', data);
                        props.setSelData(Object.assign({}, data));
                        props.setModalIsOpen(true);
                    }
                    }
                />


            </div>
        </>
    )

}

export default AlrtMngStatWrkStopPage;
