import {Outlet, useNavigate} from "react-router-dom";
import {Box, Toolbar} from "@mui/material";
import colorConfigs from "../configs/colorConfigs";
import sizeConfigs from "../configs/sizeConfigs";
import React, {createContext, Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {getLoginUser} from "../../helper/SecurityHelper";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Menubar from "../common/Menubar";
import ClipLoader from 'react-spinners/ClipLoader';
import {ProgressContext} from "../../context/Progress";
import {RouteType} from "../../routes/config";
import SmSiteMenubar from "../common/SmSiteMenubar";
import {UserContext} from "../../context/User";
import PushModal from "../../pages/headoffice/modal/PushModal";
import {PushContext} from "../../context/Push";

interface IProps {
    isSmSite: boolean;
}


const MainLayout = (props: IProps) => {
    const navigate = useNavigate();
    const user = getLoginUser()
    const {inProgress} = useContext(ProgressContext);
    const {user:userCntxt}: any = useContext(UserContext);
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const {push} = useContext<any>(PushContext);


    useEffect(() => {
        if (!user) {
            navigate('/login');
        }
    }, [])

    useEffect(() => {
        if(push){
            if([true, false].includes(push.isOpen)){
                setModalIsOpen(push.isOpen);
            }
        }
    }, [push])

    return (

        <Box sx={{display: "flex"}}>
            <PushModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen}></PushModal>

            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{zIndex: 11000, width:'400px'}}
            />
            {(userCntxt?.isSmSite || props.isSmSite) ? <SmSiteMenubar /> : <Menubar />}
            {inProgress && <div id="sh-loader-wrapper" style={{zIndex: 11001}}><span className={"loading"}></span></div>}
            <Box
                component="main"
                className={"sh-empty-screen"}
                sx={{
                    marginTop: "64px",
                    flexGrow: 1,
                    minHeight: "calc(100vh - 64px)",
                    backgroundColor: "#000000",
                }}
            >
                <div style={{
                    width: "1px",
                    flex: 1,
                    height: "calc(100vh - 64px)",
                    backgroundColor: "white",
                    overflow: "auto"
                }}>
                    <Outlet/>
                </div>

            </Box>

        </Box>
    );
};

export default MainLayout;
