import React, {useContext, useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import restapi from "../../helper/restAPIHelper";
import {UserContext} from "../../context/User";
import {getLoginUser} from "../../helper/SecurityHelper";
import ImageLoader from "../../components/ImageLoader";

const DashboardSiteCCTV = () => {
    const {user} = useContext<any>(UserContext);
    const defaultUser = getLoginUser();
    const [cctvDataList, setCctvDataList] = useState<any>([]);
    const [cctvData, setCctvData] = useState<any>({});
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

    useEffect(() => {
        let siteId = user?.blngSiteId || defaultUser.blngSiteId
        fn_retrieveCctvDataList(siteId);
    }, [user])

    const fn_retrieveCctvDataList = (siteId: string) => {
        restapi.retrieveCctvList({siteId: siteId}). then((rsltData: any) => {
            console.log('cctv rsltData: ', rsltData);
            setCctvDataList(rsltData);
            if(rsltData.length > 0){
                rsltData[0].idx = 0;
                setCctvData(rsltData[0]);
            }else{
                setCctvData([]);
            }
        })
    }

    const fn_nextCctv = (direction: string) => {
        let cctvIdx;

        if(direction == 'left'){
            if(cctvData.idx == 0){
                cctvIdx = cctvDataList.length - 1;
            }else{
                cctvIdx = cctvData.idx - 1;
            }
        }else if(direction == 'right'){
            if(cctvData.idx == cctvDataList.length -1){
                cctvIdx = 0;
            }else{
                cctvIdx = cctvData.idx + 1;
            }
        }
        cctvDataList[cctvIdx].idx = cctvIdx;
        let cstmCctvData = cctvDataList[cctvIdx];
        setCctvData(cstmCctvData);
    }
    return(
        <>
            <Container fluid className="sh-bg-pane rounded-[5px] py-2 px-3">
                <Row className="px-0 mb-1">
                    <Col bsPrefix="col-7" className="d-flex align-items-center w-100">
                        <div className="w-100 d-flex align-items-center justify-content-between">
                            <span className="pe-2 sh-fs-h4 sh-fw-5 sh-font-SD">현장 CCTV</span>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-1">
                    <Col>
                        <div style={{position:"relative"}}>
                            {cctvDataList.length > 0 ?
                                <>
                                    <div className="position-relative cursor-pointer" onClick={() => {
                                        //<CctvViewerModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} dataList={cctvDataList} detailData={cctvData}/>
                                        var url = process.env.REACT_APP_CCTV_VIEWER_URL + "?url=" + encodeURIComponent(cctvData.rtspUrl)
                                        // @ts-ignore
                                        if(window.___cctv_popup) {
                                            // @ts-ignore
                                            window.___cctv_popup.close()
                                        }
                                        // @ts-ignore
                                        window.___cctv_popup = window.open(url,  "_blank","width=658,height=445,top=100,left=100");
                                        // @ts-ignore
                                        window.___cctv_popup.focus()
                                    }}>
                                        <ImageLoader className="rounded-[5px] object-cover w-100"
                                                     src={process.env.REACT_APP_CCTV_IMAGE_URL + "?url=" + encodeURIComponent(cctvData.rtspUrl)}
                                                     alt="cctv"
                                                     style={{height:"290px"}} />
                                    </div>
                                    <div className="rounded-br-[5px] px-3 py-2 " style={{backgroundColor:"#00000088", position: "absolute", bottom:0, right:0}}>
                                        <span className={"d-inline-block pe-2 cursor-pointer"} onClick={() => fn_nextCctv('left')} style={{borderRight:"solid 1px #36363A"}}><i className="sh-arrow left"></i></span>
                                        <span className={"d-inline-block ps-2 cursor-pointer"} onClick={() => fn_nextCctv('right')} ><i className="sh-arrow right"></i></span>
                                    </div>
                                </>
                                :
                                <div className="position-relative">
                                    <div className="rounded-[5px] object-cover w-100" style={{height:"200px", textAlign: "center", paddingTop: "20px", backgroundColor: "#cccccc88"}}>등록CCTV가 없습니다.</div>
                                </div>
                            }
                        </div>
                    </Col>
                </Row>

            </Container>
        </>
    );
}

export default DashboardSiteCCTV;
