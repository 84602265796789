import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import Datatables from "../../component/Datatables";
import SHSelect from "../../../components/common/SHSelect";
import SHSearch from "../../component/SHSearch";
import SHInput from "../../../components/common/SHInput";
import restapi from "../../../helper/restAPIHelper";
import EqmntRgstModal from "../../siteEquipmentManagement/modal/EqmntRgstModal";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    dataList: any;
    sensorCd: string;
}

const $ = require("jquery")

const SmrtIotListModal = (props: IProps) => {
    const [datatable, setDatatable] = useState<any>();

    const searchFormRef = useRef<any>();
    const [dataList, setDataList] = useState<any>([]);

    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState(null);
    const [data, setData] = useState<any>();
    const [isCodeDataLoaded, setIsCodeDataLoaded] = useState<boolean>(false);



    const fn_setFormData = (e: any) => {

        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_search = (e?: any) => {
        e?.preventDefault()
        datatable?.draw()
    }

    const fn_onShow = () => {
        console.log('props.dataList: ', props.dataList);
        console.log('props.sensorCd: ', props.sensorCd);
        setDataList(props.dataList);
        setData({
            ...data,
            ['sensorCd']: props.sensorCd
        })
    }

    const fn_retrieveDataList = () => {

    }

    return (
        <div>
            <Modal
                className="sh-modal" size="xl"
                show={props.modalIsOpen}
                onShow={() => {
                    fn_onShow()

                }}
                onHide={fn_closeModal}
            >


                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        {'스마트 IOT 현황'}
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">
                    <SHSearch formRef={searchFormRef} srchFnc={fn_retrieveDataList} btnType={'p'}>
                        <Row>
                            <Col>
                                <Table className="sh-font-SP sh-fs-b2 my-3">
                                    <tbody>
                                    <tr className="border-top align-middle border-bottom">
                                        <th className="sh-bg-th">IOT 기기</th>
                                        <td>
                                            <SHSelect setIsCodeDataLoaded={setIsCodeDataLoaded} initOptionGb={'sel'} className={'w-100'} name={'sensorCd'}
                                                      codeGb={'cmmn'} codeGrpId={'S017'}
                                                      onChange={fn_setFormData}
                                                      value={data?.sensorCd}></SHSelect>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                    </SHSearch>

                    <div className={'ge-result'}>
                        <Datatables
                            datas={dataList}
                            scrollY="550px"
                            className="sh-fs-b4"
                            parameterFormRef={searchFormRef}
                            columns={[
                                {data: "sensorName", title: "센서명"},
                                {data: "sensorCnt", title: "기기 대수"},
                                {data: "valueX", title: "X값"},
                                {data: "valueY", title: "Y값"},
                                {data: "valueZ", title: "Z값"},
                                {data: "measureTime", title: "측정시간"}

                            ]}
                            checked={false}
                            onLoad={(dt: any) => {
                                setDatatable(dt)
                            }}

                            onClick={(data) => {
                                setDetailData(data)
                                setModalIsOpen(true);
                            }
                            }
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>

                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default SmrtIotListModal;
