import React, {Dispatch, SetStateAction, useContext, useRef, useState} from "react";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import SHInput from "../../../components/common/SHInput";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {Container} from "@mui/material";
import {toast} from "react-toastify";
import axios from "axios";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import {ProgressContext} from "../../../context/Progress";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    siteId: number;
    isRegist: boolean;
}

const OrganizationChartManagementModal = (props : IProps) => {

    const [data, setData] = useState<any>();
    const [orgnChrtMngList, setOrgnChrtMngList] = useState<any>([]);
    const formRef = React.createRef<HTMLFormElement>();
    const cmpnySeqRef = useRef<any>();
    const {spinner} = useContext(ProgressContext);
    const fileRef = useRef() as React.MutableRefObject<any>;
    const user: any = getLoginUser();

    const fn_updateData = (e: any) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })

        if (e.target.name == 'orgnChrtMngId') {
            orgnChrtMngList.forEach( (item:any, index:number) => {
                if(item["orgnChrtMngId"] == e.target.value){
                    setData(item);
                }
            })
        }
    }

    const fn_openModal = () => {
        if(props.isRegist){
            setData(null);
        }else{
            setData(null);

            fn_getOrgnChrtMngDataList(props.siteId).then((rsltData) => {
                setOrgnChrtMngList(rsltData)
            });
        }
    }

    const fn_getOrgnChrtMngDataList = async (siteId: number) =>{
        return new Promise(function (resolve, reject) {
            axios.post('/admin/api/orgnChrtMng/retrieveOrgnChrtMngDataList', {
                siteId: siteId
            }).then((result) => {
                resolve(result.data);
            })
        });
    }

    const fn_closeModal = () => {
        props.setModalIsOpen(false);
    }

    const fn_registData = () => {

        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if (!data.title) {
            toast.warn('제목을 입력해 주십시오.')
            return;
        }

        if (!data.isPhotoUpload) {
            toast.warn('조직도 사진을 등록해 주십시오.')
            return;
        }

        // @ts-ignore
        var formData: any = new FormData(formRef.current);
        if(data.atchFile){
            formData.set('atchFile', data.atchFile);
            delete data.atchFile;
        }

        formData.set('siteId', props.siteId);
        formData.set('title', data.title);
        formData.set('userSeq', user.userSeq);
        if(!props.isRegist){
            formData.set('orgnChrtMngId', data.orgnChrtMngId);
        }

        spinner.start();
        axios.post('/admin/api/orgnChrtMng/saveOrgnChrtMngData', formData).then((result) => {
            if (result.data > 0) {
                toast.success(messageCodeDic['001'])
                props.setModalIsOpen(false);
            } else {
                toast.error(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });
    }

    const fn_changeApplied = () =>{

        if (!data || !data.orgnChrtMngId) {
            toast.warn('조직도 이력을 선택해 주십시오.')
            return;
        }

        // @ts-ignore
        var formData: any = new FormData(formRef.current);

        formData.set('orgnChrtMngId', data.orgnChrtMngId);
        formData.set('siteId', data.siteId)
        formData.set('userSeq', user.userSeq);
        axios.post('/admin/api/orgnChrtMng/updateOrgnChrtMngApplied', formData).then((result) => {
            if (result.data > 0) {
                toast.success(messageCodeDic['001'])
                props.setModalIsOpen(false);
            } else {
                toast.error(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
        .finally(() => {
            spinner.stop();
        });
    }

    const submit = (e: any) => {
        e.preventDefault()
    }

    const fn_uploadImg = () => {
        fileRef.current.click();
    }

    const fn_onfileChange = (e: any) => {
        let cstmData = data;
        if(cstmData === null){
            cstmData = {}
        }
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            const imgSrc = URL.createObjectURL(file);

            cstmData.fileTmpSrc = imgSrc;
            cstmData.atchFile = file;
            cstmData.isPhotoUpload = true;
            setData(Object.assign({}, cstmData));
        }
    }

    const fn_delete = () =>{

        if (!data || !data.orgnChrtMngId) {
            toast.warn('조직도 이력을 선택해 주십시오.')
            return;
        }

        // @ts-ignore
        var formData: any = new FormData(formRef.current);

        formData.set('orgnChrtMngId', data.orgnChrtMngId);
        formData.set('siteId', data.siteId)
        formData.set('userSeq', user.userSeq);
        axios.post('/admin/api/orgnChrtMng/deleteOrgnChrtMngData', formData).then((result) => {
            if (result.data > 0) {
                toast.success(messageCodeDic['002'])
                props.setModalIsOpen(false);
            } else {
                toast.error(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });
    }

    return (
        <div>
            <Modal
                className="sh-modal" size="xl"
                show={props.modalIsOpen}
                onShow={fn_openModal}
                onHide={fn_closeModal}
            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        조직도 등록
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">
                    <input hidden type={'file'} ref={fileRef} onChange={fn_onfileChange} accept="image/*"/>
                    <form name={'detailForm'} ref={formRef} encType="multipart/form-data" method={"post"} onSubmit={submit}>
                        <SHInput type={'hidden'} value={data?.atchFileId} name={'atchFileId'}></SHInput>
                        <SHInput type={'hidden'} value={data?.emrgNwId} name={'emrgNwId'}></SHInput>
                        <div className="sh-modal-div">
                            <div className="div-header">
                                조직도 정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle" style={{display:props.isRegist ? 'none' : 'table-row'}} >
                                        <th className="sh-bg-th" style={{width: '15%'}}>조직도이력</th>
                                        <td colSpan={3}>
                                            <select className='form-select' name={'orgnChrtMngId'} ref={cmpnySeqRef}
                                                    onChange={fn_updateData}>
                                                <option value=''>- 이전 조직도를 선택하세요 -</option>
                                                {orgnChrtMngList.map((data: any, idx: number) => (
                                                    <option key={idx} value={data.orgnChrtMngId}>{data.title}</option>
                                                ))}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>제목</th>
                                        <td colSpan={3}>
                                            <SHInput type="text" className="form-control" name={'title'}
                                                     onChange={fn_updateData} value={data?.title} disabled={props.isRegist ? false : true}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        <div className="sh-modal-div under mb-2">
                            <div className="div-header">
                                <div>조직도 사진</div>
                            </div>
                            <div>
                                <Container className='sh-box-form py-2 mt-3'>

                                    <Row>
                                        <Col>
                                            {data?.fileTmpSrc ?
                                                <img className={'cursor-pointer'}
                                                     onClick={() => fn_uploadImg()}
                                                     src={data.fileTmpSrc}/>
                                                : data?.atchFileId ?
                                                    <img
                                                        className={user.userSeq == data?.rgstrSeq ? 'cursor-pointer' : ''}
                                                        onClick={() => user.userSeq == data?.rgstrSeq && fn_uploadImg()}
                                                        src={process.env.REACT_APP_FILE_URL + "/shss-data/fileDownload?attachSeq=" + data?.atchFileId + '&sn=0&isPdf=0&time=' + new Date().getTime()}/>
                                                    :
                                                    <label htmlFor="input-file"
                                                           className={'sh-btn btn-secondary w-[100px] cursor-pointer'} style={{display:props.isRegist ? 'block' : 'none'}}
                                                           onClick={() => fn_uploadImg()} >
                                                        사진 업로드
                                                    </label>
                                            }
                                        </Col>
                                        <Col>

                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <button onClick={fn_registData} className='sh-btn pill p white me-3' style={{display:props.isRegist ? 'block' : 'none'}} >등록</button>
                        <button onClick={fn_changeApplied} className='sh-btn pill p white me-3' style={{display:props.isRegist ? 'none' : 'block'}}>대표조직도변경</button>
                        <button onClick={fn_delete} className='sh-btn pill p white' style={{display:props.isRegist ? 'none' : 'block'}}>삭제</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default OrganizationChartManagementModal;
