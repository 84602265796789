import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import {Col, Container, Row, Table} from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";
import iconArrow from "../../images/icon_arrow_01.png";
import iconCross from "../../images/icon_cross_01.png";
import iconReset from "../../images/icon_reset.png";
import iconEdit from "../../images/icon_edit.png"
import restapi from "../../helper/restAPIHelper";
import {getLoginUser} from "../../helper/SecurityHelper";
import RskAsmntCdRgstModal from './modal/RskAsmntCdRgstModal';
import RskAsmntCdClassRgstModal from './modal/RskAsmntCdClassRgstModal';
import warnCodeDic from "../../util/warnCodeDic";
import SHIconButton from "../../components/common/SHIconButton";
import {UserContext} from "../../context/User";
import SHInput from "../../components/common/SHInput";

type Props = {};

const DngrEvalMngPage = (props: Props) => {
    const [siteDataList, setSiteDataList] = useState<any[]>([]);
    const [lvl1DataList, setLvl1DataList] = useState<any>([]);
    const [lvl2DataList, setLvl2DataList] = useState<any>([]);
    const [lvl3DataList, setLvl3DataList] = useState<any>([]);
    const [lvl1Code, setLvl1Code] = useState<string>('');
    const [lvl2Code, setLvl2Code] = useState<string>('');
    const [lvl3Code, setLvl3Code] = useState<string>('');
    const [lvl3CodeNm, setLvl3CodeNm] = useState<string>('');
    const defaultUser = getLoginUser();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isClassModalOpen, setIsClassModalOpen] = useState<boolean>(false);
    const [parentCode, setParentCode] = useState<any>(null);
    const [codeLevel, setCodeLevel] = useState<number>(0);
    const [dtlData, setDtlData] = useState<any>(null);
    const {user} = useContext<any>(UserContext);

    useEffect(()=>{

        setLvl1Code('');
        setLvl2Code('');
        setLvl3Code('');
        fn_retrieveLvl1CodeDataList(user?.blngSiteId || defaultUser.blngSiteId, true);

    }, [user])




    useEffect(() => {

        if (lvl1DataList.length > 0) {
            if(lvl1Code){
                fn_retrieveLvl2CodeDataList(lvl1Code);
            }else{
                setLvl2DataList([])
            }
        }
    }, [lvl1DataList, lvl1Code])

    useEffect(() => {

        if (lvl2DataList.length > 0) {
            if (lvl2Code) {
                fn_retrieveLvl3CodeDataList(lvl2Code);
            }else{
                setLvl3DataList([])
            }
        }
    }, [lvl2DataList, lvl2Code])

    useEffect(() => {
        if(!isClassModalOpen){
            fn_retrieveLvl1CodeDataList(user?.blngSiteId || defaultUser.blngSiteId);
        }

    }, [isClassModalOpen])

    const fn_retrieveLvl1CodeDataList = (siteId:number, isInit?:boolean) => {
        restapi.retrieveRskAsmntCodeDataList({siteId: siteId, codeLevel: 1}).then((lvl1Rslt: any) => {

            if(lvl1Code){
                lvl1Rslt.map((data: any) => {
                    if (lvl1Code == data.code && !isInit) {
                        data.isSelected = true;
                    } else {
                        data.isSelected = false;
                    }
                });
            }
            setLvl1DataList(lvl1Rslt);


        })
    }

    const fn_retrieveLvl2CodeDataList = (lvl1Code: string) => {
        const siteId = (user?.blngSiteId || defaultUser.blngSiteId);
        restapi.retrieveRskAsmntCodeDataList({
            siteId: siteId,
            codeLevel: 2,
            classCode: lvl1Code
        }).then((lvl2Rslt: any) => {

            if(lvl2Code){
                lvl2Rslt.map((data: any) => {
                    if (lvl2Code == data.code) {
                        data.isSelected = true;
                    } else {
                        data.isSelected = false;
                    }
                });
            }
            setLvl2DataList(lvl2Rslt);


        })
    }

    const fn_retrieveLvl3CodeDataList = (lvl2Code: string) => {
        const siteId = (user?.blngSiteId || defaultUser.blngSiteId);
        restapi.retrieveRskAsmntCodeDataList({siteId: siteId, codeLevel: 3, code: lvl2Code}).then((lvl3Rslt: any) => {

            if(lvl3Code){
                lvl3Rslt.map((data: any) => {
                    if (lvl3Code == data.code) {
                        data.isSelected = true;
                    } else {
                        data.isSelected = false;
                    }
                });
            }
            setLvl3DataList(lvl3Rslt);


        })
    }


    const fn_retrieveSiteDataList = async () => {
        return new Promise(function (resolve, reject) {
            let param:any = {}

            if(defaultUser.authGrpId == 'SITE'){
                let siteIdList: any = [];
                const authChrgDataList = defaultUser.authChrgDataList;
                authChrgDataList.map((data: any) => {
                    siteIdList.push(data.siteId);
                })
                param.siteIdList = siteIdList;
            }

            axios.post('/admin/api/site/retrieveSiteCodeList', param).then((result) => {
                resolve(result.data);
            })
        });

    }


    const fn_onLvl1CdChanged = (code: string) => {


        let cstmLvl1DataList = lvl1DataList;

        cstmLvl1DataList.map((data: any) => {
            if (code == data.code) {
                data.isSelected = true;
            } else {
                data.isSelected = false;
            }

        })
        setLvl1Code(code);
        setLvl2Code('');
        setLvl3Code('');
        setLvl1DataList([].concat(cstmLvl1DataList));

    }

    const fn_onlvl2CdChanged = (code: string) => {
        let cstmLvl2DataList = lvl2DataList;

        cstmLvl2DataList.map((data: any) => {
            if (code == data.code) {
                data.isSelected = true;
            } else {
                data.isSelected = false;
            }

        })
        setLvl2Code(code);
        setLvl3Code('');
        setLvl2DataList([].concat(cstmLvl2DataList));
    }

    const fn_onLvl3CdChanged = (code: string, codeName: string) => {
        let cstmLvl3DataList = lvl3DataList;

        cstmLvl3DataList.map((data: any) => {
            if (code == data.code) {
                data.isSelected = true;
            } else {
                data.isSelected = false;
            }

        })
        setLvl3Code(code);
        setLvl3CodeNm(codeName);
        setIsModalOpen(true);
        setLvl3DataList([].concat(cstmLvl3DataList));
    }

    const fn_openClassModal = (paramCodeLevel: number, codeData?: object) => {

        if(paramCodeLevel == 1){
            setParentCode(null);
        }else if(paramCodeLevel == 2){
            if(!lvl1Code){
                toast.warn('먼저 대분류를 선택해 주십시오');
                return;
            }
            setParentCode(lvl1Code);
        }else if(paramCodeLevel == 3){
            if(!lvl2Code){
                toast.warn('먼저 중분류를 선택해 주십시오');
                return;
            }
            setParentCode(lvl2Code);
        }
        if(codeData){
            setDtlData(codeData);
        }else{
            setDtlData(null);
        }
        setCodeLevel(paramCodeLevel);
        setIsClassModalOpen(true);
    }

    const fn_reset = (codeLevel: number, data: any) => {

        let codeLevelList:any = [];

        if(codeLevel == 1){
            codeLevelList = [2,3,4];
        }else if(codeLevel == 2){
            codeLevelList = [3,4];
        }else if(codeLevel == 3){
            codeLevelList = [4];
        }

        let param = {siteId: user?.blngSiteId || defaultUser.blngSiteId, code:data.code, codeLevelList:codeLevelList};

        if(window.confirm('[' + data.codeName + ']의 전체 하위 코드를 초기화 하시겠습니까?')){
            axios.post('/admin/api/rskAsmnt/resetRiskAsmntSiteCodeData', param).then((result) => {
                if (result.data >= 0) {
                    toast.success(messageCodeDic['006'])
                    fn_retrieveLvl1CodeDataList(user?.blngSiteId || defaultUser.blngSiteId);
                } else {
                    if(result.data == -101){
                        toast.warn(warnCodeDic['002']);
                    }else{
                        toast.error(errorCodeDic['003']);
                    }

                }
            })
        }

    }

    const fn_resetAll = () => {
        const selData = siteDataList.filter(data => data.siteId == (user?.blngSiteId || defaultUser.blngSiteId));
        if(window.confirm('[' + selData[0].siteName + '] 현장의 전체 위험성평가 코드를 초기화 하시겠습니까?')){
            let param = {siteId: user?.blngSiteId || defaultUser.blngSiteId};
            axios.post('/admin/api/rskAsmnt/resetRiskAsmntSiteCodeData', param).then((result) => {
                if (result.data >= 0) {
                    toast.success(messageCodeDic['006'])
                    fn_retrieveLvl1CodeDataList(user?.blngSiteId || defaultUser.blngSiteId);
                } else {
                    if(result.data == -101){
                        toast.warn(warnCodeDic['002']);
                    }else{
                        toast.error(errorCodeDic['003']);
                    }
                }
            })
        }
        console.log('selData: ', selData);
    }

    return (
        <Container fluid className="bg-white px-4 pt-4 sh-text-font_primary">
            <RskAsmntCdRgstModal setModalIsOpen={setIsModalOpen} modalIsOpen={isModalOpen}
                                 siteId={user?.blngSiteId || defaultUser.blngSiteId}
                                 lvl3Code={lvl3Code} lvl3CodeNm={lvl3CodeNm}></RskAsmntCdRgstModal>
            <RskAsmntCdClassRgstModal setModalIsOpen={setIsClassModalOpen} modalIsOpen={isClassModalOpen}
                                      siteId={user?.blngSiteId || defaultUser.blngSiteId}
                                      parentCode={parentCode} codeLevel={codeLevel} dtlData={dtlData}></RskAsmntCdClassRgstModal>

            <SubMenuTitle title={'위험성평가설정'} parentMenu={'현장 Admin'}/>


            <div className="rounded-[5px] border mb-3 px-3">

                <SHInput type={'hidden'} name={'siteId'} value={user?.blngSiteId || defaultUser.blngSiteId}></SHInput>
            </div>

            <div className='w-100 d-flex justify-content-end align-items-center mb-2'>
                <SHIconButton kind={'normal'} marginRight={'5px'} onClick={fn_resetAll}> 초기화
                </SHIconButton>
            </div>

            <div className="ge-result container-fluid" >
                <div className="row">
                    <div className="col-md-3 pe-0">
                        <div className="input-group input-group-sm mb-3">
                            <div className="form-control p-3">
                                <div className="ge-title01">대분류</div>
                                <div data-ge-area="step1">
                                    {
                                        lvl1DataList.length > 0 ?
                                            lvl1DataList.map((data: any, idx: number) => (

                                                <div className="row" key={idx}>
                                                    <div className="col-9">
                                                        <div className="form-check">
                                                            <input className="form-check-input"
                                                                   name="lvl1Cd"
                                                                   type="checkbox"
                                                                   id={"lvl1_" + idx}
                                                                   onChange={() => {
                                                                       fn_onLvl1CdChanged(data.code)
                                                                   }}
                                                                   value={data.code}
                                                                   checked={data.isSelected || false}/>
                                                            <label className="form-check-label"
                                                                   htmlFor={"lvl1_" + idx}>{data.codeName}</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className="d-flex align-items-center w-100">
                                                            <img onClick={() => {fn_openClassModal(1, data)}}
                                                                src={iconEdit} width="20"
                                                                className="align-self-end cursor-pointer"/>
                                                            <img onClick={() => {fn_reset(1, data)}}
                                                                src={iconReset}
                                                                width="20"
                                                                className="align-self-end cursor-pointer ml-5"/>

                                                        </div>
                                                    </div>
                                                </div>
                                            )) : <span>조회된 데이터가 없습니다.</span>


                                    }


                                </div>
                                <div className="w-100 d-inline-flex justify-content-between">
                                    <button className="ge-add01" onClick={() => {
                                        fn_openClassModal(1);
                                    }
                                    }><img
                                        className={'ge-img'} src={iconCross} width="13"/></button>

                                </div>

                            </div>
                            <span className="mt-2 px-3"><img
                                src={iconArrow} width="9"
                                className="align-self-start"/></span>

                        </div>
                    </div>
                    <div className="col-md-5 ps-0 pe-0">
                        <div className="input-group input-group-sm mb-3">
                            <div className="form-control p-3">
                                <div className="ge-title01">중분류</div>
                                <div data-ge-area="step2">
                                    {
                                        lvl2DataList.length > 0 ?
                                            lvl2DataList.map((data: any, idx: number) => (

                                                <div className="row" key={idx}>
                                                    <div className="col-9">
                                                        <div className="form-check">
                                                            <input className="form-check-input"
                                                                   name="lvl2Cd"
                                                                   id={"lvl2_" + idx}
                                                                   type="checkbox"
                                                                   onChange={() => {
                                                                       fn_onlvl2CdChanged(data.code)
                                                                   }}
                                                                   value={data.code}
                                                                   checked={data.isSelected || false}/>
                                                            <label className="form-check-label"
                                                                   htmlFor={"lvl2_" + idx}>{data.codeName}</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className="d-flex align-items-center w-100">
                                                            <img onClick={() => {fn_openClassModal(2, data)}}
                                                                src={iconEdit} width="20"
                                                                className="align-self-end cursor-pointer"/>
                                                            <img onClick={() => {fn_reset(2, data)}}
                                                                src={iconReset}
                                                                width="20"
                                                                className="align-self-end cursor-pointer ml-5"/>

                                                        </div>
                                                    </div>
                                                </div>
                                            )) : <span>조회된 데이터가 없습니다.</span>


                                    }

                                </div>
                                <div className="w-100 d-inline-flex text-center justify-content-between">
                                    <button className="ge-add01"  onClick={() => {
                                        fn_openClassModal(2);
                                    }
                                    }><img className={'ge-img'}
                                                                      src={iconCross} width="13"/></button>
                                </div>

                            </div>
                            <span className="mt-2 px-3"><img
                                src={iconArrow} width="9"
                                className="align-self-start"/></span>
                        </div>
                    </div>
                    <div className="col-md-4 ps-0">
                        <div className="input-group input-group-sm mb-3">
                            <div className="form-control p-3">
                                <div className="ge-title01">소분류</div>
                                <div data-ge-area="step3">
                                    {
                                        lvl3DataList.length > 0 ?
                                            lvl3DataList.map((data: any, idx: number) => (

                                                <div className="row" key={idx}>
                                                    <div className="col-9">
                                                        <div className="form-check">
                                                            <input className="form-check-input cursor-pointer"
                                                                   name="lvl3Cd"
                                                                   id={"lvl3_" + idx}
                                                                   type="checkbox"
                                                                   onChange={() => {
                                                                       fn_onLvl3CdChanged(data.code, data.codeName)
                                                                   }}
                                                                   value={data.code}
                                                                   checked={data.isSelected || false}/>
                                                            <label className="form-check-label cursor-pointer"
                                                                   htmlFor={"lvl3_" + idx}>{data.codeName}</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className="d-flex align-items-center w-100">
                                                            <img onClick={() => {fn_openClassModal(3, data)}}
                                                                src={iconEdit} width="20"
                                                                className="align-self-end cursor-pointer"/>
                                                            <img onClick={() => {fn_reset(3, data)}}
                                                                src={iconReset}
                                                                width="20"
                                                                className="align-self-end cursor-pointer ml-5"/>

                                                        </div>
                                                    </div>
                                                </div>
                                            )) : <span>조회된 데이터가 없습니다.</span>


                                    }

                                </div>
                                <div className="w-100 d-inline-flex justify-content-between">
                                    <button className="ge-add01"  onClick={() => {
                                        fn_openClassModal(3);
                                    }
                                    }><img className={'ge-img'}
                                                                                              src={iconCross}
                                                                                              width="13"/></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default DngrEvalMngPage;
