import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {COMMON_MODAL_STYLE} from "../../../helper/StyleHelper";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import SHSelect from "../../../components/common/SHSelect";
import {getLoginUser} from "../../../helper/SecurityHelper";
import SHSearch from "../../component/SHSearch";
import SHInput from "../../../components/common/SHInput";
import SHSearchButton from "../../../components/common/SHSearchButton";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    cmpnyDataList: object[];
    setCmpnyDataList: Dispatch<SetStateAction<object[]>>;
    companyGbCd? : string;
    isSingleCheck? : boolean;
    isHeadAuth? : string;

}

const $ = require("jquery")

const CmpnySrchModal = (props: IProps) => {
    const [datatable, setDatatable] = useState<any>();
    const searchFormRef = useRef<any>();
    const user = getLoginUser();
    const [isCodeDataLoaded, setIsCodeDataLoaded] = useState<any>(null);
    const [cmpnyDataList, setCmpnyDataList] = useState<any>([props.cmpnyDataList]);
    const [cmpnySeqList, setCmpnySeqList] = useState<number[]>([]);

    useEffect(() => {
        if(isCodeDataLoaded){
            fn_search();
        }
    }, [isCodeDataLoaded]);

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_search = (e?: any) => {
        e?.preventDefault()
        datatable?.draw()
    }
    const fn_select = () => {

        let cstmCmpnyDataList = cmpnyDataList
        if (cstmCmpnyDataList.length == 0) {
            toast.warn('회사를 선택해 주십시오.');
            return;
        }

        if(props.isSingleCheck){
            cstmCmpnyDataList = [cstmCmpnyDataList[cstmCmpnyDataList.length - 1]];
        }

        props.setCmpnyDataList(cstmCmpnyDataList);
        props.setModalIsOpen(false);
    }

    const fn_onShow = () => {
        let cmpnySeqList:any = []
        props.cmpnyDataList.map((data: any) => {
            cmpnySeqList.push(data.companySeq);
        })
        setCmpnyDataList(props.cmpnyDataList);
        setCmpnySeqList(cmpnySeqList);
        setIsCodeDataLoaded(false);

    }

    return (
        <div>

            <Modal
                className="sh-modal sub" size="xl"
                show={props.modalIsOpen}
                onShow={() => {
                    fn_onShow()

                }}
                onHide={fn_closeModal}
            >


                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        업체 검색
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">

                        <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                            {props.companyGbCd &&
                                <SHInput type={'hidden'} name={'companyGbCd'} value={props.companyGbCd || ''}></SHInput>
                            }

                            <SHInput type={'hidden'} name={'isHeadAuth'} value={props.isHeadAuth || ''}></SHInput>
                            <Row>
                                <Col>
                                    <Table className="sh-font-SP sh-fs-b2 my-3">
                                        <tbody>
                                        <tr className="border-top align-middle">
                                            {props.isHeadAuth &&
                                                <>
                                                    <th className="sh-bg-th" style={{width:'6%'}}>업체구분</th>
                                                    <td>
                                                    <SHSelect initOptionGb={'all'} className={'sh-select'} codeGb={'cmmn'}
                                                              codeGrpId={'C001'} codes={['C001002', 'C001003']}
                                                              name={'companyGbCd'}></SHSelect>
                                                    </td>
                                                </>
                                            }

                                            <th className="sh-bg-th" style={{width:'6%'}}>업체명</th>
                                            <td>
                                                <input type={'text'} name={"companyName"}  className='form-control'/>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Col>

                            </Row>
                        </SHSearch>
                    <div className={'ge-result'}>
                        <Datatables
                            dataUrl={'/admin/api/company/retrieveCmpnyDataList'}
                            parameterFormRef={searchFormRef}
                            checkedDataList={cmpnySeqList}
                            pk={'companySeq'}
                            columns={[
                                {data: "cmpnyGbNm", title: "업체구분", textAlign: 'center'},
                                {data: "companyName", title: "업체명", textAlign: 'left'},
                                {data: "companyRegNum", title: "사업자등록번호", textAlign: 'center'},
                                {data: "sctrGbNm", title: "대표공종", textAlign: 'center'},
                            ]}
                            checked={true}
                            isSingleCheck={props.isSingleCheck || false}
                            onLoad={(dt: any) => {
                                setDatatable(dt)
                            }}
                            onAllCheckedClick={(datas: any, checked: any)=>{
                                console.log(datas)
                                console.log('checked: ', checked);
                                let cstmCmpnySeqList = cmpnySeqList;
                                let cstmCmpnyDataList = cmpnyDataList;

                                if(checked){
                                    datas.map((data: any) => {
                                        if(cstmCmpnySeqList.indexOf(data.companySeq) == -1){
                                            cstmCmpnySeqList.push(data.companySeq);
                                            cstmCmpnyDataList.push(data);
                                        }
                                    })
                                }else{
                                    datas.map((data: any) => {
                                        const idx = cstmCmpnySeqList.indexOf(data.companySeq);

                                        if(cstmCmpnySeqList.indexOf(data.companySeq) > -1){
                                            cstmCmpnySeqList.splice(idx, 1);
                                            cstmCmpnyDataList.splice(idx, 1);
                                        }
                                    })
                                }
                            }}
                            onClick={(data) => {
                                console.log('on clicked');
                                let cstmCmpnySeqList = cmpnySeqList;
                                let cstmCmpnyDataList = cmpnyDataList;
                                const idx = cstmCmpnySeqList.indexOf(data.companySeq);

                                if(data.isChecked){
                                    if(idx == -1){
                                        cstmCmpnySeqList.push(data.companySeq)
                                        cstmCmpnyDataList.push(data);

                                    }
                                }else{
                                    if(idx > -1){
                                        cstmCmpnySeqList.splice(idx, 1);
                                        cstmCmpnyDataList.splice(idx, 1);
                                    }
                                }
                                // @ts-ignore
                                setCmpnySeqList([].concat(cstmCmpnySeqList));
                                setCmpnyDataList([].concat(cstmCmpnyDataList));

                            }
                            }
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <button onClick={fn_select}
                                className='sh-btn pill p'>선택

                        </button>
                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default CmpnySrchModal;
