import React, {useEffect, useRef, useState} from 'react';
import Datatables, {getCheckedItems} from "../component/Datatables";
import {Button, Container, ListGroup, Overlay, Table} from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";
import restapi from '../../helper/restAPIHelper';
import EnvrntCnfgModal from "./modal/EnvrntCnfgModal";

type Props = {};

const EnvrntCnfgPage = (props: Props) => {
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState({});
    const [dataList, setDataList] = useState<any>([]);

    useEffect(() => {
        if(!modalIsOpen){
            restapi.retrieveSystemInfoList({}).then((rsltData: any) => {
                setDataList(rsltData);
            })
        }

    }, [modalIsOpen])


    return (
        <Container fluid className="bg-white px-4 pt-4 sh-text-font_primary">
            <SubMenuTitle title={'환경설정'} parentMenu={'설정'}/>
            <EnvrntCnfgModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} detailData={detailData} />

            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 197px)'}}>

                <div className={'ge-result'}>
                    <Datatables
                        datas={dataList}
                        scrollY="550px"
                        className="sh-fs-b4"
                        columns={[
                            {data: "key", title: "키"},
                            {data: "value", title: "값", textAlign: 'left'},
                            {data: "description", title: "설명", textAlign: 'left'},

                        ]}
                        index={true}
                        checked={false}
                        onLoad={(dt: any) => {
                        }}

                        onClick={(data) => {
                            setDetailData(Object.assign({}, data));
                            setModalIsOpen(true);
                        }
                        }
                    />
                </div>
            </div>
        </Container>
    );
};

export default EnvrntCnfgPage;
