import SubMenuTitle from "../component/SubMenuTitle";
import React, {useContext, useEffect, useRef, useState} from "react";
import {Col, Container, Row, Table} from "react-bootstrap";
import WrkPlcStatModal from "./modal/WrkPlcStatModal";
import SHSearch from "../component/SHSearch";
import SHInput from "../../components/common/SHInput";
import SHDatePicker2 from "../../components/common/SHDatePicker2";
import Datatables from "../component/Datatables";
import {UserContext} from "../../context/User";
import {getLoginUser} from "../../helper/SecurityHelper";
import {DateHelper} from "../../helper/DateHelper";
import WrkrMoveHistListModal from "./modal/WrkrMoveHistListModal";

type Props = {};
const WrkrMoveHistPage = (props: Props) => {
    const {user} = useContext<any>(UserContext);
    const defaultUser = getLoginUser();
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [wrkDt, setWrkDt] = useState<string>('');
    const [datatable, setDatatable] = useState<any>();
    const searchFormRef = useRef<any>();
    const prodRef = useRef<any>();
    const [strtDate, setStrtDate] = useState<Date>(DateHelper.dateByMonth(-3));
    const [endDate, setEndDate] = useState<Date>(DateHelper.dateByMonth(0));
    const [data, setData] = useState<any>();

    useEffect(()=>{

        if(datatable){
            datatable.draw();
        }

    }, [user, datatable])

    useEffect(() => {
        if (!modalIsOpen) {
            datatable?.draw();
        }
    }, [modalIsOpen])
    const fn_setFormData = (e: any) => {

        setData({
            ...data,
            [e.target.name]: e.target.value
        })

    }
    return (
        <Container fluid className="bg-white px-4 pt-4 sh-text-font_primary">
            <SubMenuTitle title={'근로자 이동기록 현황'} parentMenu={'인력 관리'}/>
            <WrkrMoveHistListModal siteId={user?.blngSiteId || defaultUser.blngSiteId} wrkDt={wrkDt} modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen}></WrkrMoveHistListModal>
            <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                <Row>
                    <Col>
                        <Table className="sh-font-SP sh-fs-b2 my-3">
                            <tbody>
                            <tr className="border-top border-bottom align-middle">
                                <th className="sh-bg-th">기간</th>
                                <td>
                                    <SHInput type={'hidden'} name={'qrNfcTpCd'} value={'T003001'}></SHInput>
                                    <SHInput type={'hidden'} name={'siteId'} value={user?.blngSiteId || defaultUser.blngSiteId}></SHInput>
                                    <div className="d-flex align-items-center w-100" ref={prodRef}>
                                        <SHDatePicker2 setter={setStrtDate} name={'strtDate'} defaultValue={strtDate} onChange={fn_setFormData} />
                                        <span>&nbsp;~&nbsp;</span>
                                        <SHDatePicker2 setter={setEndDate} name={'endDate'} defaultValue={endDate} onChange={fn_setFormData} />
                                    </div>

                                </td>

                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </SHSearch>


            <div className={'ge-result'}>
                <Datatables
                    dataUrl={'/admin/api/qrnfc/retrieveDprtStatDataList'}
                    parameterFormRef={searchFormRef}
                    columns={[
                        {data: "useDt", title: "일자", textAlign: 'center'},
                        {data: "totCnt", title: '총 출역인원', textAlign: 'center'},
                        {data: "mngrCnt", title: "출역 관리자", textAlign: 'center'},
                        {data: "wrkrCnt", title: "출역 근로자", textAlign: 'center'},



                    ]}
                    checked={false}
                    index={true}

                    onLoad={(dt: any) => {

                        setDatatable(dt)
                    }}
                    onClick={(data) => {
                        setWrkDt(data.useDt);
                        setModalIsOpen(true);
                    }
                    }
                />
            </div>
        </Container>
    )
}

export default WrkrMoveHistPage;
