import axios, {AxiosResponse} from 'axios';
//prod
//local
const responseBody = (response: AxiosResponse) => response.data;
const requests = {
    get: (url: string) => axios.get(url).then(responseBody),
    post: (url: string, body: any, option: any) =>
        axios.post(url, body, option).then(responseBody),
    put: (url: string, body: object, option: any) =>
        axios.put(url, body, option).then(responseBody),
    delete: (url: string) => axios.delete(url).then(responseBody),
};

const restapi = {


    /**
     * 현장 아이디 목록 조회
     */
    retrieveSiteCodeList: (siteIdList?: any, selParam?: any) => {
        let param : any = {}
        if (siteIdList) {
            param = {siteIdList: siteIdList};
        }
        if(selParam){
            for(var key in selParam){
                param[key] = selParam[key]
            }
        }
        return requests.post(
            '/admin/api/site/retrieveSiteCodeList',
            param,
            null,
        )
    },

    /**
     * 권한 목록 조회
     * @param authGrpId
     */
    retrieveAuthCodeList: (authGrpId: string, authCode?: string) =>
        requests.post(
            '/admin/api/cmmn/retrieveAuthCodeList',
            {authGrpId: authGrpId, authCode:authCode},
            null,
        ),

    /**
     * 현장별 공통 코드 조회
     * @param siteId
     * @param manageCode
     */
    retrieveSiteManageItemList: (siteId: number, manageCode: string) =>
        requests.post(
            '/admin/api/manage/retrieveSiteManageItemList',
            {siteId: siteId, manageCode: manageCode},
            null,
        ),

    /**
     * 현장별 업체목록 또는 업체별 현장목록 조회
     * @param siteId
     * @param companySeq
     */
    retrieveCmpnySiteDataList: ({siteId, companySeq}: any) =>
        requests.post(
            '/admin/api/company/retrieveCmpnySiteDataList',
            {siteId: siteId, companySeq: companySeq},
            null,
        ),

    /**
     * 현장별 권한 목록 조회
     * @param userSeq
     */
    retrieveUserSiteAuthDataList: (userSeq: number, authCode?: string) =>
        requests.post('/admin/api/user/retrieveUserSiteAuthDataList',
            {userSeq: userSeq, authCode:authCode},
            null
        ),

    /**
     * 사용자 목록 조회
     * @param isDriver
     * @param blngCmpnySeq
     * @param blngSiteId
     */
    retrieveUserDataList: ({isDriver, blngCmpnySeq, blngSiteId, isSiteMngr, isApprMngr, notIncldUserSeqList, userPhone, siteId, authCode, isHeadAppr}: any) =>
        requests.post('/admin/api/user/retrieveUserDataList',
            {
                isDriver: isDriver,
                blngCmpnySeq: blngCmpnySeq,
                blngSiteId: blngSiteId,
                isSiteMngr: isSiteMngr,
                isApprMngr: isApprMngr,
                notIncldUserSeqList: notIncldUserSeqList,
                userPhone: userPhone,
                siteId: siteId,
                authCode: authCode,
                isHeadAppr: isHeadAppr,
            },
            null
        ),

    /**
     * 작업허가서 상세 데이터 조회
     * @param wrkPrmtId
     */
    retrieveWrkPrmtDtlData: (wrkPrmtId: number, siteId?: number) =>
        requests.post('/admin/api/wrkPrmt/retrieveWrkPrmtDtlData',
            {wrkPrmtId: wrkPrmtId, siteId: siteId},
            null
        ),


    /**
     * 안전업무 회의 참석자 목록 조회
     * @param sftyWrkMetId
     */

    retrieveSftyWrkMetAttndInfoData: (sftyWrkMetId: number) =>
        requests.post('/admin/api/sftyWrkMet/retrieveSftyWrkMetAttndInfoData',
            {sftyWrkMetId: sftyWrkMetId},
            null
        ),

    /**
     * 위험성평가 코드 목록 조회
     * @param codeLevel
     * @param classCode
     */
    retrieveRskAsmntCodeDataList: ({siteId, codeLevel, classCode, code, isCnfg, slctdCodeList}: any) =>

        requests.post('/admin/api/rskAsmnt/retrieveRskAsmntCodeDataList',
            {
                siteId: siteId,
                codeLevel: codeLevel,
                classCode: classCode,
                code: code,
                isCnfg: isCnfg,
                slctdCodeList: slctdCodeList
            },
            null
        ),
    /**
     * 위험성평가 항목 목록 조회
     * @param codeList
     */
    retrieveDngrEvlFctrDataList: (codeList: string[]) =>
        requests.post('/admin/api/rskAsmnt/retrieveDngrEvlFctrDataList',
            {codeList: codeList},
            null
        ),

    /**
     * 위험성 평가 항목 불러오기(기 등록된)
     * @param riskAsmntId
     */
    retrieveRiskEvlFctrDtlDataList: (riskAsmntId: string, siteId: number) =>
        requests.post('/admin/api/rskAsmnt/retrieveRiskEvlFctrDtlDataList',
            {riskAsmntId: riskAsmntId, siteId: siteId},
            null
        ),

    /**
     * 부적합 조치 담당자 목록 조회
     * @param incgrtyId
     */
    retrieveIncgrtyChrgrDataList: (incgrtyId: number) =>
        requests.post('/admin/api/incgrty/retrieveIncgrtyChrgrDataList',
            {incgrtyId: incgrtyId},
            null
        ),

    /**
     * 날씨 정보 가져오기
     * @param siteId
     */
    retrieveWtherInfoData: ({siteId}: any) =>
        requests.post('/admin/api/cmmn/retrieveWtherInfoData',
            {siteId: siteId},
            null
        ),

    /**
     * 위험성평가 목록 조회
     * @param raStartDate
     * @param raEndDate
     * @param siteId
     * @param cmpnySeq
     */
    retrieveRskAsmntDataList: ({raStartDate, raEndDate, siteId, cmpnySeq}: any) =>
        requests.post('/admin/api/rskAsmnt/retrieveRskAsmntDataList',
            {raStartDate: raStartDate, raEndDate: raEndDate, siteId: siteId, cmpnySeq: cmpnySeq},
            null
        ),
    retrieveRiskEvlWrkActDataList: ({riskAsmntIdList, siteId}: any) =>
        requests.post('/admin/api/rskAsmnt/retrieveRiskEvlWrkActDataList',
            {riskAsmntIdList: riskAsmntIdList, siteId: siteId},
            null
        ),

    /**
     * 장비점검 목록 조회
     * @param siteId
     * @param eqmntCd
     */
    retrieveEqmntChkList: ({siteId, eqmntCd, prcsRslt}: any) =>
        requests.post('/admin/api/manage/retrieveEqmntChkList',
            {siteId: siteId, eqmntCd: eqmntCd, prcsRslt: prcsRslt},
            null
        ),

    /**
     * 위험성평가 결재 정보 목록 가져오기
     * @param riskAsmntId
     */
    retrieveRiskAsmntApprInfo: ({riskAsmntId}: any) =>
        requests.post('/admin/api/rskAsmnt/retrieveRiskAsmntApprInfo',
            {riskAsmntId: riskAsmntId},
            null
        ),

    /**
     * 작업허가서 결재 정보 목록 가져오기
     * @param wrkPrmtId
     */
    retrieveWrkPrmtApprInfo: ({wrkPrmtId}: any) =>
        requests.post('/admin/api/wrkPrmt/retrieveWrkPrmtApprInfo',
            {wrkPrmtId: wrkPrmtId},
            null
        ),

    retrieveEqmntApprInfo: ({eqmntId}: any) =>
        requests.post('/admin/api/eqmnt/retrieveEqmntApprInfo',
            {eqmntId: eqmntId},
            null
        ),

    /**
     * 첨부파일 목록 조회
     * @param attachSeq
     */
    retrieveAttachInfoList: ({attachSeq}: any) =>
        requests.post('/admin/api/cmmn/retrieveAttachInfoList',
            {attachSeq: attachSeq},
            null
        ),

    /**
     * 첨부파일 삭제
     * @param attachSeq
     * @param sn
     */
    deleteAttachFile: ({attachSeq, sn}: any) =>
        requests.post('/admin/api/cmmn/deleteAttachFile',
            {attachSeq: attachSeq, sn: sn},
            null
        ),
    /**
     * 국가 코드 정보 가져오기
     */
    retrieveNationDataList: () =>
        requests.post('/admin/api/cmmn/retrieveNationDataList', {}, null),

    retrieveBjdDataList: (codeGrpId: string) =>
        requests.post('/admin/api/cmmn/retrieveBjdDataList', {codeGrpId: codeGrpId}, null),

    /**
     * 일일 장비점검 확인
     * @param cnfrmUserSeq
     * @param eqmntId
     * @param userSeq
     * @param prcsDt
     */
    cnfrmEqmntChk: ({cnfrmUserSeq, eqmntId, userSeq, prcsDt}: any) =>
        requests.post('/admin/api/eqmnt/cnfrmEqmntChk', {
            cnfrmUserSeq: cnfrmUserSeq,
            eqmntId: eqmntId,
            userSeq: userSeq,
            prcsDt: prcsDt
        }, null),


    /**
     * QR/NFC 설정 정보 저장
     * @param param
     */
    saveQrNfcData: (param: any) => requests.post('/admin/api/qrnfc/saveQrNfcData', param, null),

    /**
     * 작업 종료
     * @param param
     */
    endWork: ({siteId, cmpnySeq, wrkDt, wrkPrmtId}: any) => requests.post('/admin/api/tbm/endWork', {
        siteId: siteId,
        cmpnySeq: cmpnySeq,
        wrkDt: wrkDt,
        wrkPrmtId: wrkPrmtId
    }, null),

    /**
     * 출역 현황 상세 목록 조회
     * @param wrkDt
     * @param siteId
     */
    retrieveDprtDtlStatDataList: ({
                                      wrkDt,
                                      siteId,
                                      qrNfcTpCd,
                                      wrkPlcCd,
                                      tabGb,
                                      eduGbCd,
                                      isCnstrAssc
                                  }: any) => requests.post('/admin/api/qrnfc/retrieveDprtDtlStatDataList', {
        wrkDt: wrkDt,
        siteId: siteId,
        qrNfcTpCd: qrNfcTpCd,
        wrkPlcCd: wrkPlcCd,
        tabGb: tabGb,
        eduGbCd: eduGbCd,
        isCnstrAssc: isCnstrAssc
    }, null),

    /**
     * 작업구간 목록 조회
     * @param wrkDt
     * @param siteId
     */
    retrieveWrkPlcInfoList: ({wrkDt, siteId}: any) => requests.post('/admin/api/qrnfc/retrieveWrkPlcInfoList', {
        wrkDt: wrkDt,
        siteId: siteId,

    }, null),
    /**
     * 현장안전조치 목록 조회
     * @param userSeq
     */
    retrieveSiteSftyMeasuresDataList: (sftyChkSeq: number) =>
        requests.post('/admin/api/siteSftyManage/retrieveSiteSftyMeasuresDataList',
            {sftyChkSeq: sftyChkSeq},
            null
        ),

    /**
     * 사용자 현장 변경
     * @param userSeq
     * @param siteId
     */
    changeCurrentUserSite: ({userSeq, siteId, isRepSiteChg}: any) =>
        requests.post(
            '/admin/api/user/changeCurrentUserSite',
            {userSeq: userSeq, siteId: siteId, isRepSiteChg: isRepSiteChg},
            null,
        ),

    /**
     * 유저 session 값 가져오기
     * @param userSeq
     */
    getUserSession: (userSeq: number) =>
        requests.post(
            '/admin/api/user/getUserSession',
            {
                userSeq: userSeq,
            },
            null,
        ),

    /**
     * 현장 목록 조회
     * @param userSeq
     */
    retrieveSiteDataListNoPaging: ({siteId, siteIds, siteType, isSmSite, yyyymmdd, blngCmpnySeq}: any) =>
        requests.post(
            '/admin/api/site/retrieveSiteDataListNoPaging',
            {
                siteId: siteId,
                siteIds: siteIds,
                siteType: siteType,
                isSmSite: isSmSite,
                yyyymmdd: yyyymmdd,
                blngCmpnySeq: blngCmpnySeq
            },
            null,
        ),

    /**
     * 유저 상세정보 가져오자
     * @param userId
     */
    retrieveUserInfoDtl: ({userId}: any) =>
        requests.post(
            '/admin/api/user/retrieveUserInfoDtl',
            {
                userId: userId,
            },
            null,
        ),

    /**
     * 총 출역현황 정보 조회
     * @param siteId
     * @param qrNfcTpCd
     */
    retrieveTotEtrncStatInfo: ({siteId, qrNfcTpCd}: any) =>
        requests.post(
            '/admin/api/qrnfc/retrieveTotEtrncStatInfo',
            {
                siteId: siteId,
                qrNfcTpCd: qrNfcTpCd,
            },
            null,
        ),
    /**
     * 현장별 출역 현황
     */
    retrieveInOutStatPerSiteData: ({}) =>
        requests.post(
            '/admin/api/qrnfc/retrieveInOutStatPerSiteData',
            {
            },
            null,
        ),

    /**
     * 단가보수현장 출역 현황
     */
    retrieveInOutStatSmSiteStatData:  ({}) =>
        requests.post(
            '/admin/api/qrnfc/retrieveInOutStatSmSiteStatData',
            {
            },
            null,
        ),

    /**
     * 사용자 현장 권한 삭제
     * @param userSeq
     * @param siteId
     */
    deleteUserSiteAuthData: ({userSeq, siteId}: any) =>
        requests.post(
            '/admin/api/user/deleteUserSiteAuthData',
            {
                userSeq: userSeq,
                siteId: siteId,
            },
            null,
        ),

    /**
     * 사용자 스마트 TBM 현황 정보 조회
     * @param siteId
     * @param wrkDt
     */
    retrieveUserTbmStatDataList:  ({siteId, wrkDt, tbmYn, cmpnySeq, workTypeCd, ocptCd}: any) =>
        requests.post(
            '/admin/api/tbm/retrieveUserTbmStatDataList',
            {
                siteId: siteId,
                wrkDt: wrkDt,
                tbmYn: tbmYn,
                cmpnySeq: cmpnySeq,
                workTypeCd: workTypeCd,
                ocptCd: ocptCd,
            },
            null,
        ),

    /**
     * 본사 정보 가져오기
     */
    retrieveHeadCmpnyInfo: () =>
        requests.post(
            '/admin/api/company/retrieveHeadCmpnyInfo',
            {
            },
            null,
        ),

    /**
     * 업체 전체 목록 조회
     */
    retrieveCmpnyDataTotList: ({companySeq}: any) =>
        requests.post(
            '/admin/api/company/retrieveCmpnyDataTotList',
            {
                companySeq: companySeq
            },
            null,
        ),

    /**
     * 현장 본사, 마스터 사용자 데이터 목록 조회
     * @param siteId
     */
    retrieveHeadSiteMstrUserDataList:  ({siteId}: any) =>
        requests.post(
            '/admin/api/site/retrieveHeadSiteMstrUserDataList',
            {
                siteId: siteId,
            },
            null,
        ),

    /**
     * 교육 참석자 목록 조회
     * @param eduId
     */
    retrieveEduPtcptDataList:  ({eduId}: any) =>
        requests.post(
            '/admin/api/edu/retrieveEduPtcptDataList',
            {
                eduId: eduId,
            },
            null,
        ),

    /**
     * 위험성 평가 목록 불러오기
     * @param isWrkrPov
     * @param siteId
     * @param wrkDt
     */
    retrieveRskAsmntTotDataList: ({
                                      isWrkrPov,
                                      siteId,
                                      wrkDt,
                                      userSeq,
                                      riskAsmntId,
                                      raStartDate,
                                      raEndDate

                                  }: any) =>
        requests.post(
            '/admin/api/rskAsmnt/retrieveRskAsmntTotDataList',
            {
                isWrkrPov: isWrkrPov,
                siteId: siteId,
                wrkDt: wrkDt,
                userSeq: userSeq,
                riskAsmntId: riskAsmntId,
                raStartDate: raStartDate,
                raEndDate: raEndDate
            },
            null,
        ),

    /**
     * 작업허가서 목록 조회
     * @param siteId
     * @param prcsStatCd
     */
    retrieveWrkPrmtDataTotList: ({
                                  siteId,
                                  prcsStatCd,
                                  wrkDt,
                                  userSeq,
                                  isWrkrPov,
                                  cmpnySeq,
                                  wrkStatCd,
                                  wrkSiteNm,
                                     isForMonitoring,
                                  wrkStatNm,

                              }: any) =>
        requests.post(
            '/admin/api/wrkPrmt/retrieveWrkPrmtDataTotList',
            {
                siteId: siteId,
                prcsStatCd: prcsStatCd,
                wrkDt: wrkDt,
                userSeq: userSeq,
                isWrkrPov: isWrkrPov,
                cmpnySeq: cmpnySeq,
                wrkStatCd: wrkStatCd,
                wrkSiteNm: wrkSiteNm,
                isForMonitoring: isForMonitoring,
                wrkStatNm: wrkStatNm,
            },
            null,
        ),

    /**
     * 공지사항 목록 조회
     * @param siteId
     */
    retrieveNoticeDataTotList: ({siteId, noticeId, isValidNotice}: any) =>
        requests.post(
            '/admin/api/notice/retrieveNoticeDataTotList',
            {
                siteId:siteId,
                noticeId: noticeId,
                isValidNotice: isValidNotice
            },
            null,
        ),

    /**
     * 업체별 출역현황 조회
     * @param siteId
     */
    retrieveEtrncStatByCmpny: ({siteId, wrkDt}: any) =>
        requests.post(
            '/admin/api/qrnfc/retrieveEtrncStatByCmpny',
            {
                siteId:siteId,
                wrkDt: wrkDt,
            },
            null,
        ),

    /**
     * 장비현황 목록 조회
     * @param siteId
     */
    retrieveEqmmntStatDataList: ({siteId}: any) =>
        requests.post(
            '/admin/api/eqmnt/retrieveEqmmntStatDataList',
            {
                siteId:siteId
            },
            null,
        ),

    /**
     * 장비 목록 조회
     * @param siteId
     * @param eqmntCd
     */
    retrieveEqmntDataTotList: ({siteId, eqmntCd, cmpnySeq}: any) =>
        requests.post(
            '/admin/api/eqmnt/retrieveEqmntDataTotList',
            {
                siteId:siteId,
                eqmntCd:eqmntCd,
                cmpnySeq:cmpnySeq
            },
            null,
        ),

    /**
     * 구역별 총 인원 현황 조회
     * @param siteId
     */
    retrieveDprtWrkStatDataList: ({siteId}: any) =>
        requests.post(
            '/admin/api/qrnfc/retrieveDprtWrkStatDataList',
            {
                siteId:siteId
            },
            null,
        ),

    /**
     * 부적합 목록 조회
     * @param siteId
     */
    retrieveIncgrtyDataTotList:  ({siteId, incgrtyId}: any) =>
        requests.post(
            '/admin/api/incgrty/retrieveIncgrtyDataTotList',
            {
                siteId:siteId,
                incgrtyId:incgrtyId
            },
            null,
        ),

    /**
     * 작업책임자 목록 조회
     * @param wrkPrmtId
     */
    retrieveWrkPrmtChrgrDataList: ({wrkPrmtId}: any) =>
        requests.post(
            '/admin/api/wrkPrmt/retrieveWrkPrmtChrgrDataList',
            {
                wrkPrmtId:wrkPrmtId
            },
            null,
        ),

    /**
     * 안전점검 확인 상태 업데이트
     * @param sftyChkSeq
     * @param isBsnsCnfrmd
     * @param bsnsCnfrmdUserSeq
     * @param isPrvntCnfrmd
     * @param prvntCnfrmdUserSeq
     * @param isFinalCnfrm
     */
    updateCnfrmStat: ({sftyChkSeq, isBsnsCnfrmd, bsnsCnfrmdUserSeq, isPrvntCnfrmd, prvntCnfrmdUserSeq, isFinalCnfrm}: any) =>
        requests.post(
            '/admin/api/siteSftyManage/updateCnfrmStat',
            {
                sftyChkSeq:sftyChkSeq,
                isBsnsCnfrmd:isBsnsCnfrmd,
                bsnsCnfrmdUserSeq:bsnsCnfrmdUserSeq,
                isPrvntCnfrmd:isPrvntCnfrmd,
                prvntCnfrmdUserSeq:prvntCnfrmdUserSeq,
                isFinalCnfrm:isFinalCnfrm,
            },
            null,
        ),

    /**
     * 안전점검 결재 정보 가져오기
     * @param sftyChkSeq
     */
    retrieveSiteSftyApprDataList: ({sftyChkSeq}: any) =>
        requests.post(
            '/admin/api/siteSftyManage/retrieveSiteSftyApprDataList',
            {
                sftyChkSeq:sftyChkSeq,
            },
            null,
        ),

    /**
     * 안전점검 목록 조회
     * @param isThisWeek
     * @param isNextWeek
     */
    retrieveSiteSftyManageDataTotList: ({isThisWeek, isNextWeek, sftyChkDt, sftyChkSeq, actStatGbCd}: any) =>
        requests.post(
            '/admin/api/siteSftyManage/retrieveSiteSftyManageDataTotList',
            {
                isThisWeek:isThisWeek,
                isNextWeek:isNextWeek,
                sftyChkDt:sftyChkDt,
                sftyChkSeq:sftyChkSeq,
                actStatGbCd:actStatGbCd
            },
            null,
        ),

    /**
     * 안전점검관리 조치현황 조회
     * @param siteId
     */
    retrieveSftyChkActStatData: ({siteId}: any) =>
        requests.post(
            '/admin/api/siteSftyManage/retrieveSftyChkActStatData',
            {
                siteId:siteId,
            },
            null,
        ),

    /**
     * 안전점검 일정 목록 조회
     */
    retrieveSftChkSchdleDataList: ({}: any) =>
        requests.post(
            '/admin/api/siteSftyManage/retrieveSftChkSchdleDataList',
            {},
            null,
        ),

    /**
     * TBM 완료 목록 조회
     * @param siteId
     * @param wrkDt
     */
    retrieveSmrtTbmDataList: ({siteId, wrkDt}: any) =>
        requests.post(
            '/admin/api/tbm/retrieveSmrtTbmDataList',
            {siteId: siteId, wrkDt:wrkDt},
            null,
        ),

    /**
     * SOS요청 전체 목록 조회
     * @param siteId
     * @param rgstDt
     */
    retrieveSosHisTotDataList: ({siteId, rgstDt, strtDt, endDt, siteName}: any) =>
        requests.post(
            '/admin/api/sos/retrieveSosHisTotDataList',
            {siteId: siteId, rgstDt: rgstDt, strtDt:strtDt, endDt:endDt, siteName:siteName},
            null,
        ),

    /**
     * 작업중지요청 전체 목록 조회
     * @param siteId
     * @param rgstDt
     */
    retrieveWrkDisruptHisTotDataList: ({siteId, rgstDt, strtDt, endDt, siteName}: any) =>
        requests.post(
            '/admin/api/wrkStop/retrieveWrkDisruptHisTotDataList',
            {siteId: siteId, rgstDt: rgstDt, strtDt: strtDt, endDt:endDt, siteName: siteName},
            null,
        ),

    /**
     * 안전점검 미조치 현장 목록 조회
     */
    retrieveActNoCmpltSiteDataList: () =>
        requests.post(
            '/admin/api/siteSftyManage/retrieveActNoCmpltSiteDataList',
            null,
            null,
        ),

    /**
     * TBM 완료 건수 조회
     */
    retrieveSmrtTbmTotStatCnt: ({siteId}: any) =>
        requests.post(
            '/admin/api/tbm/retrieveSmrtTbmTotStatCnt',
            {siteId: siteId},
            null,
        ),

    /**
     * 작업허가서에 포함된 작업자 목록 조회
     * @param wrkDt
     * @param siteId
     */
    retrievePrmtWrkrDataList: ({wrkDt, siteId, wrkPrmtId, workTypeCd, ocptCd, tbmYn}: any) =>
        requests.post(
            '/admin/api/wrkPrmt/retrievePrmtWrkrDataList',
            {wrkDt: wrkDt, siteId: siteId, wrkPrmtId:wrkPrmtId, workTypeCd:workTypeCd, ocptCd:ocptCd, tbmYn:tbmYn},
            null,
        ),

    /**
     * 사용중인 현장여부 조회
     * @param siteId
     * @param siteIds
     */
    retrieveIsInUseSite: ({siteId, siteIds}: any) =>
        requests.post(
            '/admin/api/site/retrieveIsInUseSite',
            {siteId: siteId, siteIds: siteIds, },
            null,
        ),

    /**
     * CCTV 목록 조회
     * @param siteId
     */
    retrieveCctvList: ({siteId}: any) =>
        requests.post(
            '/admin/api/cctv/retrieveCctvList',
            {siteId: siteId},
            null,
        ),

    retrieveSiteWrkrMngStatList: ({rgstDt, eduGbCd, tabGb, guCd, srchTxt}: any) =>
        requests.post(
            '/admin/api/qrnfc/retrieveSiteWrkrMngStatList',
            {rgstDt: rgstDt, eduGbCd:eduGbCd, tabGb:tabGb, guCd:guCd, srchTxt:srchTxt},
            null,
        ),

    /**
     * 개인보호구 착용/미착용 데이터 반영
     * @param siteId
     */
    toggleDailyNoCapWrkrData: ({userSeq, siteId, wrkDt, rgstrSeq}: any) =>
        requests.post(
            '/admin/api/qrnfc/toggleDailyNoCapWrkrData',
            {userSeq:userSeq, siteId:siteId, wrkDt:wrkDt, rgstrSeq:rgstrSeq},
            null,
        ),

    /**
     * 본사 알림 수신함 목록 조회
     * @param userSeq
     */
    retrieveHeadRcvNtfyDataList: ({userSeq}: any) =>
        requests.post(
            '/admin/api/rcvNtfy/retrieveHeadRcvNtfyDataList',
            {userSeq:userSeq},
            null,
        ),

    /**
     * 안전 신고 포상제 목록 조회
     * @param sftyNwspprId
     */
    retrieveSftyNwspprTotDataList: ({sftyNwspprId}: any) =>
        requests.post(
            '/admin/api/sftyNwsppr/retrieveSftyNwspprTotDataList',
            {sftyNwspprId:sftyNwspprId},
            null,
        ),

    /**
     * 공지 사항 읽음 처리
     * @param rcvNtfyGb
     * @param rcvNtfyId
     * @param userSeq
     */
    insertRcvNtfyReadData: ({rcvNtfyGb, rcvNtfyId, userSeq}: any) =>
        requests.post(
            '/admin/api/rcvNtfy/insertRcvNtfyReadData',
            {rcvNtfyGb:rcvNtfyGb, rcvNtfyId:rcvNtfyId, userSeq:userSeq},
            null,
        ),

    /**
     * 현장 알림 수신함 조회
     * @param userSeq
     * @param blngCmpnySeq
     * @param siteId
     */
    retrieveSiteRcvNtfyDataList: ({userSeq, blngCmpnySeq, siteId}: any) =>
        requests.post(
            '/admin/api/rcvNtfy/retrieveSiteRcvNtfyDataList',
            {userSeq:userSeq, blngCmpnySeq:blngCmpnySeq, siteId:siteId},
            null,
        ),

    retrieveEmployeeDataListNoPaging: ({isCnstrAssc, siteId, blngCmpnySeq, nationId}: any) =>
        requests.post(
            '/admin/api/employee/retrieveEmployeeDataListNoPaging',
            {isCnstrAssc:isCnstrAssc, siteId:siteId, blngCmpnySeq:blngCmpnySeq, nationId:nationId},
            null,
        ),

    /**
     * PTW 안전 체크 목록 조회
     * @param ptwId
     */
    retrievePtwSftyChkDataList: ({ptwId}: any) =>
        requests.post(
            '/admin/api/ptw/retrievePtwSftyChkDataList',
            {ptwId:ptwId},
            null,
        ),

    /**
     * PTW 책임자 목록 조회
     * @param wrkPrmtId
     */
    retrievePtwChrgrDataList: ({ptwId}: any) =>
        requests.post(
            '/admin/api/ptw/retrievePtwChrgrDataList',
            {
                ptwId:ptwId
            },
            null,
        ),

    retrievePTWDtlData: ({ptwId, siteId}: any) =>
        requests.post(
            '/admin/api/ptw/retrievePTWDtlData',
            {
                ptwId:ptwId,
                siteId:siteId
            },
            null,
        ),

    /**
     * PTW 결재 정보 목록 가져오기
     * @param wrkPrmtId
     */
    retrievePtwApprInfo: ({ptwId}: any) =>
        requests.post('/admin/api/ptw/retrievePtwApprInfo',
            {ptwId: ptwId},
            null
        ),

    /**
     * 상시 PTW 작업내용 목록 가져오기
     * @param ptwId
     */
    retrievePtwAlwaysWrkDataList: ({ptwId}: any) =>
        requests.post('/admin/api/ptw/retrievePtwAlwaysWrkDataList',
            {ptwId: ptwId},
            null
        ),

    /**
     * 안전 관리 게시판 수신자 목록 조회
     * @param sftyMngBrdId
     * @param receiveTypeCd
     */
    retrieveSftyMngBrdRcvrDataList: ({sftyMngBrdId, receiveTypeCd}: any) =>
        requests.post('/admin/api/sftyMngBrd/retrieveSftyMngBrdRcvrDataList',
            {sftyMngBrdId: sftyMngBrdId, receiveTypeCd: receiveTypeCd},
            null
        ),

    /**
     * 근로자 이동기록 현황 목록 조회
     * @param wrkDt
     * @param siteId
     */
    retrieveWrkrMoveHistDataList: ({wrkDt, siteId, cmpnySeq, wrkTypeCd, ocptCd}: any) =>
        requests.post('/admin/api/qrnfc/retrieveWrkrMoveHistDataList',
            {wrkDt: wrkDt, siteId: siteId, cmpnySeq:cmpnySeq, wrkTypeCd:wrkTypeCd, ocptCd:ocptCd},
            null
        ),

    /**
     * 설문조사 타이틀 목록 조회
     * @param pollId
     */
    retrievePollTitleDataList: ({pollId}: any) =>
        requests.post('/admin/api/poll/retrievePollTitleDataList',
            {pollId: pollId},
            null
        ),
    /**
     * 설문조사 서브 타이틀 목록 조회
     * @param pollId
     */
    retrievePollSubTitleDataList: ({pollId}: any) =>
        requests.post('/admin/api/poll/retrievePollSubTitleDataList',
            {pollId: pollId},
            null
        ),
    /**
     * 설문조사 질문 목록 조회
     * @param pollId
     */
    retrievePollQsntDataList: ({pollId}: any) =>
        requests.post('/admin/api/poll/retrievePollQsntDataList',
            {pollId: pollId},
            null
        ),
    /**
     * 설문조사 답변 목록 조회
     * @param pollId
     */
    retrievePollQsntDtlDataList: ({pollId}: any) =>
        requests.post('/admin/api/poll/retrievePollQsntDtlDataList',
            {pollId: pollId},
            null
        ),
    /**
     * 설문조사 완료 목록 조회
     * @param pollId
     */
    retrievePollAswrCmpltdDataList: ({pollId, strtDt, endDt, srchTxt, pollGb}: any) =>
        requests.post('/admin/api/poll/retrievePollAswrCmpltdDataList',
            {pollId: pollId, strtDt: strtDt, endDt:endDt, srchTxt: srchTxt, pollGb: pollGb},
            null
        ),

    /**
     * 설문조사 상세 목록 조회
     * @param pollId
     */
    retrievePollDtlDataList: ({pollId, titleId, userSeq, pollSn}: any) =>
        requests.post(
            '/admin/api/poll/retrievePollDtlDataList',
            {pollId: pollId, titleId: titleId, userSeq:userSeq, pollSn:pollSn},
            null,
        ),

    /**
     * 위험성평가 점검 목록 조회
     * @param siteId
     */
    retrieveRiskEvalChkDataList: ({siteId, actStatNm, chkRsltNm, riskGrdNm, chkRsltCd}: any) =>
        requests.post(
            '/admin/api/rskAsmnt/retrieveRiskEvalChkDataList',
            {siteId: siteId, actStatNm:actStatNm, chkRsltNm:chkRsltNm, riskGrdNm:riskGrdNm, chkRsltCd:chkRsltCd},
            null,
        ),

    /**
     * 위험성 점검 결과 이력 조회
     * @param riskAsmntId
     * @param wrkLvlCd
     * @param riskAsmntCd
     */
    retrieveChkHistDataList: ({riskAsmntId, wrkLvlCd, riskAsmntCd}: any) =>
        requests.post(
            '/admin/api/rskAsmnt/retrieveChkHistDataList',
            {riskAsmntId: riskAsmntId, wrkLvlCd: wrkLvlCd, riskAsmntCd:riskAsmntCd},
            null,
        ),

    retrieveSystemInfoList: ({}) =>
        requests.post(
            '/admin/api/system/retrieveSystemInfoList',
            {},
            null,
        ),

    /**
     * 작업중지 push 메시지 전송
     * @param userSeq
     * @param siteId
     * @param wrkDisruptId
     * @param statCd ('P001002' : 해제, 'P001003' : 오류)
     */
    sendWrkStopPushMsg: ({userSeq, siteId, wrkDisruptId, statCd}: any) =>
        requests.post('/admin/api/wrkStop/sendWrkStopPushMsg',
            {userSeq: userSeq, siteId: siteId, wrkDisruptId: wrkDisruptId, statCd: statCd},
            null
        ),

    /**
     * SOS 해제/오류 message push
     * @param userSeq
     * @param siteId
     * @param sosId
     * @param statCd ('P001002' : 해제, 'P001003' : 오류)
     */
    sendSosPushMsg: ({userSeq, siteId, sosId, statCd}: any) =>
        requests.post('/admin/api/sos/sendSosPushMsg',
            {userSeq: userSeq, siteId: siteId, sosId: sosId, statCd: statCd},
            null
        ),

    /**
     * 안전 업무 보고서 통계 조회
     * @param siteId
     * @param strtDt
     * @param endDt
     */
    retrieveWrkRprtStatData: ({siteId, strtDt, endDt}: any) =>
        requests.post(
            '/admin/api/sftyWrkRprt/retrieveWrkRprtStatData',
            {siteId: siteId, strtDt:strtDt, endDt:endDt},
            null,
        ),

    /**
     * 센서 타입별 통계 데이터 조회
     * @param siteId
     */
    retrieveSensorStatGroupBySenSorType: ({siteId}: any) =>
        requests.post(
            '/admin/api/sensor/retrieveSensorStatGroupBySenSorType',
            {siteId: siteId},
            null,
        ),

    /**
     * 센서데이터 목록 조회
     * @param siteId
     */
    retrieveSensorDataList: ({siteId, sensorCd}: any) =>
        requests.post(
            '/admin/api/sensor/retrieveSensorDataList',
            {siteId: siteId, sensorCd: sensorCd},
            null,
        ),

};
export default restapi;
