export default function SHRadio({ children, value, name, disabled, onChange, className, checked }: any) {
    if(typeof checked === "string"){
        checked = eval(checked)
    }
    if(checked !== true){
        checked = false
    }
    return (
        <label className={'form-check-label sh-input-label'}>
            <input className={className ? className + ' sh-radio form-check-input me-1' : 'sh-radio form-check-input me-1'}
                type="radio"
                value={value}
                name={name}
                checked={checked || false}
                disabled={disabled}
                onChange={onChange}
            />
            {children}
        </label>
    );
}
