import React, {useContext, useEffect, useState} from 'react';
import {Col, Container, Row, Table} from "react-bootstrap";
import {ReactComponent as IconRight} from "../../assets/images/ico/icon-right.svg";
import {ReactComponent as IconNoData} from "../../assets/images/ico/icon-folder.svg";
import {useNavigate} from "react-router-dom";
import {UserContext} from "../../context/User";
import {getLoginUser} from "../../helper/SecurityHelper";
import restapi from '../../helper/restAPIHelper';
import NoticeRegistModal from "../noticeManagement/modal/NoticeRegistModal";
import axios from "axios";
import { CmmnFn } from '../../helper/CmmnHelper';

const DashboardNotice = () => {

    const navigate = useNavigate();
    const {user} = useContext<any>(UserContext);
    const defaultUser = getLoginUser();
    const [dataList, setDataList] = useState<any>([]);
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [noticeDetailData, setNoticeDetailData] = useState(null);
    useEffect(() => {
        const siteId = user?.blngSiteId || defaultUser.blngSiteId;

        restapi.retrieveNoticeDataTotList({siteId: siteId, isValidNotice: true}).then((rsltData: any) => {
            setDataList(rsltData);
        })
    }, [user])

    const fn_openNoticeModal = (data: any) => {
        setNoticeDetailData(Object.assign({}, data))
        setModalIsOpen(true);
        fn_updateViewCnt(data);
    }
    const fn_updateViewCnt = (param: object) => {
        axios.post('/admin/api/notice/updateViewCnt', param)
    }

    return(
        <>
            <Container fluid className="sh-bg-pane rounded-[5px] py-2 px-3 mb-3">
                <NoticeRegistModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} siteId={user?.blngSiteId || defaultUser.blngSiteId}
                                   noticeDetailData={noticeDetailData} />
                <Row className="px-0 mb-2">
                    <Col bsPrefix="col-7" className="d-flex align-items-center w-100">
                        <div className="w-100 d-flex align-items-center justify-content-between">
                            <span className="pe-2 sh-fs-h4 sh-fw-5 sh-font-SD">공지사항</span>
                            <div className={'cursor-pointer'} onClick={() => navigate(CmmnFn.isSmSite()?'/smSite/noticeManagement/noticeRegistration' : '/noticeManagement/noticeRegistration')}>
                            <IconRight style={{width:'20px',marginBottom:'1px'}} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div>
                            <Table className="mb-0 sh-text-font_secondary03 sh-fs-b3">
                                <tbody>
                                {dataList.length > 0 ?
                                    (
                                        dataList.map((data: any, idx: number) => (
                                            idx < 4 &&
                                            <tr key={idx} className={'cursor-pointer'} onClick={() => fn_openNoticeModal(data)} style={{borderBottom:'dotted 1px rgba(255,255,255,0.2)'}}>
                                                <td className="border-bottom-0">{data.title}</td>
                                                <td className="text-end border-bottom-0 text-nowrap">{data.regDate}</td>
                                            </tr>
                                    ))
                                        ) : (
                                        <tr className={'h-[138px]'} style={{borderBottom: 'dotted 1px rgba(255,255,255,0.2)'}}>
                                            <td className="text-center border-bottom-0 fs-6"><div className="d-flex flex-column justify-content-center align-items-center" style={{height: 'calc(100% - 20px)'}}>
                                                <IconNoData style={{width: '40px', height: '40px', marginBottom: '10px'}} />
                                                <div className="bmc-txt-color-gray-01 bmc-txt-size-b6">조회된 데이터가 없습니다.</div>
                                            </div></td>
                                        </tr>
                                    )
                                }
                                {
                                    (dataList.length > 0 && dataList.length < 4) && CmmnFn.fillEmpty(dataList.length)

                                }
                                </tbody>

                            </Table>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default DashboardNotice;
