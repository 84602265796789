import React, {useContext, useEffect, useState} from "react";
import {Container, Row, Col, Table} from "react-bootstrap";
import {ReactComponent as IconRight} from "../../assets/images/ico/icon-right.svg";
import {ReactComponent as IconNoData} from "../../assets/images/ico/icon-folder.svg";
import {CmmnFn} from "../../helper/CmmnHelper";
import restapi from "../../helper/restAPIHelper";
import {UserContext} from "../../context/User";
import {getLoginUser} from "../../helper/SecurityHelper";
import TodayWrkInfoListModal from "./modal/TodayWrkInfoListModal";

const DashTodayWork = () => {

    const {user} = useContext<any>(UserContext);
    const defaultUser = getLoginUser();
    const [dataList, setDataList] = useState<any>([]);
    const [isTodayWrkListModalOpen, setIsTodayWrkListModalOpen] = useState<boolean>(false);


    useEffect(() => {
        const siteId = user?.blngSiteId || defaultUser.blngSiteId;
        const wrkDt = CmmnFn.fn_getCurrentDate();

        restapi.retrieveWrkPrmtDataTotList({
            prcsStatCd: 'S001004',
            siteId: siteId,
            wrkDt: wrkDt
        }).then((rsltData: any) => {
            console.log('todayWrkInfo: ', rsltData);
            setDataList(rsltData);
        })
    }, [user])


    return (
        <>
            <Container fluid className="sh-bg-pane rounded-[5px] py-2 px-3">
                <TodayWrkInfoListModal setModalIsOpen={setIsTodayWrkListModalOpen} modalIsOpen={isTodayWrkListModalOpen} todaywrkInfoDataList={dataList} siteId={user?.blngSiteId || defaultUser.blngSiteId}></TodayWrkInfoListModal>
                <Row className="px-0 mb-2">
                    <Col bsPrefix="col-7" className="d-flex align-items-center w-100">
                        <div className="w-100 d-flex align-items-center justify-content-between">
                            <span className="pe-2 sh-fs-h4 sh-fw-5 sh-font-SD">금일 작업 사항</span>
                            <div className={'cursor-pointer'} onClick={() => setIsTodayWrkListModalOpen(true)}>
                            <IconRight style={{width: '20px', marginBottom: '1px'}}/>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="px-0">
                    <Col className="mx-2 d-flex align-items-center">
                        <Table className="my-1 sh-text-font_secondary03 sh-fs-b4">
                            <tbody>
                            {dataList.length > 0 ?
                                (
                                    dataList.map((data: any, idx: number) => (
                                        idx < 4 &&
                                        <tr key={idx} style={{borderBottom: 'dotted 1px rgba(255,255,255,0.2)'}}>
                                            <td className="text-center border-bottom-0"
                                                style={{width: '20%', minWidth: '60px'}}>{data.wrkTypeNm}</td>
                                            <td className="border-bottom-0"
                                                style={{width: '80%'}}>{data.todayWrkCntnts}</td>
                                        </tr>
                                    ))
                                ) :
                                (


                                    <tr className={'h-[138px]'} style={{borderBottom: 'dotted 1px rgba(255,255,255,0.2)'}}>
                                        <td rowSpan={4} className="text-center border-bottom-0 fs-6"><div className="d-flex flex-column justify-content-center align-items-center" style={{height: 'calc(100% - 20px)'}}>
                                            <IconNoData style={{width: '40px', height: '40px', marginBottom: '10px'}} />
                                            <div className="bmc-txt-color-gray-01 bmc-txt-size-b6">조회된 데이터가 없습니다.</div>
                                        </div></td>
                                    </tr>

                                )
                            }
                            {
                                (dataList.length > 0 && dataList.length < 4) && CmmnFn.fillEmpty(dataList.length)

                            }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default DashTodayWork;

