import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {COMMON_MODAL_STYLE} from "../../../helper/StyleHelper";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import SHSelect from "../../../components/common/SHSelect";
import {getLoginUser} from "../../../helper/SecurityHelper";
import SHSearch from "../../component/SHSearch";
import SHInput from "../../../components/common/SHInput";
import restapi from "../../../helper/restAPIHelper";
import SiteSftyChkRgstModal from "../../safetyWorkReportManagement/modal/SiteSftyChkRgstModal";
import SiteManageRgstModal from "../../headManage/modal/SiteManageRgstModal";
import {SiteContext} from "../../../context/Site";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    dataList: object[];

}

const $ = require("jquery")

const WrkPrmtInfoListModal = (props: IProps) => {
    const [datatable, setDatatable] = useState<any>();

    const [isCodeDataLoaded, setIsCodeDataLoaded] = useState<any>(null);
    const searchFormRef = useRef<any>();
    const [dataList, setDataList] = useState<any>([]);
    const [orgnDataList, setOrgnDataList] = useState<any>([]);
    const {setSite} = useContext<any>(SiteContext);


    useEffect(() => {
        if (isCodeDataLoaded) {
            fn_search();
        }
    }, [isCodeDataLoaded]);

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_search = (e?: any) => {
        e?.preventDefault()
        datatable?.draw()
    }

    const fn_onShow = () => {

        setDataList(props.dataList);

        setOrgnDataList(props.dataList);
    }


    return (
        <div>
            <Modal
                className="sh-modal" size="lg"
                show={props.modalIsOpen}
                onShow={() => {
                    fn_onShow()

                }}
                onHide={fn_closeModal}
            >


                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        TBM 현황 목록
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">

                    <div className={'ge-result'}>
                        <Datatables
                            datas={dataList}
                            scrollY="550px"
                            className="sh-fs-b4"
                            parameterFormRef={searchFormRef}
                            columns={[
                                {data: '', title: '현장명', render: (data: any, type: any, row: any) => {
                                        if(row.isSmSite){
                                            return row.wrkSiteNm
                                        }else{
                                            return row.siteName
                                        }

                                    }},
                                {data: "wrkTypeNm", title: "공종"},
                                {data: "todayWrkCntnts", title: "금일 작업사항 내용", render: (data: any, type: any, row: any) => {
                                        if(row.isSmSite){
                                            return row.istrctns
                                        }else{
                                            return row.todayWrkCntnts
                                        }

                                    }},
                                {data: "calTotWrkrCnt", title: "총인원"},
                                {
                                    data: "totWrkrCntYn",
                                    title: "TBM 여부",
                                    textAlign: 'center'

                                },
                                {
                                    data: "wrkStatNm", title: "작업상태", textAlign: 'center'
                                }

                            ]}
                            checked={false}
                            onLoad={(dt: any) => {
                                setDatatable(dt)
                            }}

                            onClick={(data) => {
                                setSite(data);
                                props.setModalIsOpen(false);
                            }
                            }
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>

                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default WrkPrmtInfoListModal;
