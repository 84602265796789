import React, {useContext, useEffect, useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import {ReactComponent as IconRight} from "../../assets/images/ico/icon-right.svg";
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import {Doughnut} from 'react-chartjs-2';
import {UserContext} from "../../context/User";
import {getLoginUser} from "../../helper/SecurityHelper";
import restapi from "../../helper/restAPIHelper";
import {useNavigate} from "react-router-dom";
import RskAsmntInfoListModal from "./modal/RskAsmntInfoListModal";
import {CmmnFn, fn_getCurrentDate} from "../../helper/CmmnHelper";

ChartJS.register(ArcElement, Tooltip);

const DashRiskAssessment = () => {
    const {user} = useContext<any>(UserContext);
    const defaultUser = getLoginUser();
    const [cntStatData, setCntStatData] = useState<any>({cmpltdCnt:0, ingCnt:0, rjctCnt:0})
    const navigate = useNavigate();
    const [isRskAsmntInfoListModalOpen, setIsRskAsmntInfoListModalOpen] = useState<boolean>(false);
    const [prcsStatGb, setPrcsStatGb] = useState<string>('');
    const [data, setData] = useState<any>({
        labels: ['완료', '진행', '반려'],
        datasets: [
            {
                data: [35, 21, 21],
                backgroundColor: [
                    'rgba(86, 133, 247, 1)',
                    'rgba(48, 228, 199, 1)',
                    'rgba(255, 113, 139, 1)',
                ],
                borderColor: [
                    'rgba(30, 31, 35, 1)',
                    'rgba(30, 31, 35, 1)',
                    'rgba(30, 31, 35, 1)',
                ],
                borderWidth: 1,
                hoverOffset: 5,
            },
        ],
    });

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            }
        },
        cutout: '70%',
    };

    useEffect(() => {
        const siteId = user?.blngSiteId || defaultUser.blngSiteId;

        restapi.retrieveRskAsmntTotDataList({siteId:siteId, raStartDate: fn_getCurrentDate(), raEndDate: fn_getCurrentDate()}).then((rsltData: any) => {
            console.log('rskAsmnt rsltData: ', rsltData);
            let cmpltdCnt = 0;
            let ingCnt = 0;
            let rjctCnt = 0;
            rsltData.map((data: any) => {
                // 완료
                if(data.prcsStatCd == 'S001004'){
                    cmpltdCnt++;
                // 반려
                }else if(data.prcsStatCd == 'S001005'){
                    rjctCnt++;
                // 진행
                }else if(['S001002', 'S001003'].includes(data.prcsStatCd)){
                    ingCnt++;
                }
            })
            setData({
                labels: ['완료', '진행', '반려'],
                datasets: [
                    {
                        data: [cmpltdCnt, ingCnt, rjctCnt],
                        backgroundColor: [
                            'rgba(86, 133, 247, 1)',
                            'rgba(48, 228, 199, 1)',
                            'rgba(255, 113, 139, 1)',
                        ],
                        borderColor: [
                            'rgba(30, 31, 35, 1)',
                            'rgba(30, 31, 35, 1)',
                            'rgba(30, 31, 35, 1)',
                        ],
                        borderWidth: 1,
                        hoverOffset: 5,
                    },
                ],
            })


            const cntData = {cmpltdCnt:cmpltdCnt, ingCnt:ingCnt, rjctCnt:rjctCnt};
            setCntStatData(cntData);
        })
    }, [user])

    const fn_openRskAsmntInfoListModal = (prcsStatGb: string) => {
        setPrcsStatGb(prcsStatGb);
        setIsRskAsmntInfoListModalOpen(true);

    }

    return(
        <>
            <Container fluid className="sh-bg-pane rounded-[5px] py-2 px-3">
                <RskAsmntInfoListModal setModalIsOpen={setIsRskAsmntInfoListModalOpen} modalIsOpen={isRskAsmntInfoListModalOpen} siteId={user?.blngSiteId || defaultUser.blngSiteId} prcsStatGb={prcsStatGb} raStartDate={CmmnFn.fn_getCurrentDate()} raEndDate={CmmnFn.fn_getCurrentDate()} ></RskAsmntInfoListModal>
                <Row className="mb-2">
                    <Col bsPrefix="col-7" className="d-flex align-items-center w-100">
                        <div className="w-100 d-flex align-items-center justify-content-between">
                            <span className="sh-fs-h4 sh-fw-5 sh-font-SD">위험성 평가</span>
                            <div className={'cursor-pointer'} onClick={() => navigate('/siteSafetyManagement/riskAssessmentManagement')}>
                            <IconRight style={{width:'20px',marginBottom:'1px'}} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col bsPrefix="col-7">
                        <div className="d-flex w-100 position-relative justify-content-center" style={{height:'13vh'}}>
                            <Doughnut className="position-relative z-50" options={options} data={data} width="100%" height="13vh" />
                            <div className="position-absolute w-100 text-center start-50 top-50 z-10" style={{transform: 'translate(-50%, -50%)'}}>
                                <div className="sh-fs-b3 sh-text-font_secondary02">Total</div>
                                <div>
                                    <span className="sh-fw-5 sh-fs-h2">{cntStatData.cmpltdCnt + cntStatData.ingCnt + cntStatData.rjctCnt}</span>
                                    <span className="sh-fs-b3">건</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col  bsPrefix="col-5" className="d-flex align-items-center justify-content-center">
                        <div className="sh-fs-b2">
                            <div className={'cursor-pointer'} onClick={() => fn_openRskAsmntInfoListModal('cmpltd')}>
                                <span className="sh-text-secondary03">●</span>
                                <span className="px-1">완료</span>
                                <span className="sh-text-secondary03">{cntStatData.cmpltdCnt}</span>
                            </div>
                            <div className={'cursor-pointer'} onClick={() => fn_openRskAsmntInfoListModal('ing')}>
                                <span className="sh-text-secondary01">●</span>
                                <span className="px-1">진행</span>
                                <span className="sh-text-secondary01">{cntStatData.ingCnt}</span>
                            </div>
                            <div className={'cursor-pointer'} onClick={() => fn_openRskAsmntInfoListModal('rjct')}>
                                <span className="sh-text-secondary02">●</span>
                                <span className="px-1">반려</span>
                                <span className="sh-text-secondary02">{cntStatData.rjctCnt}</span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default DashRiskAssessment;

