import React, {useContext, useEffect, useState} from 'react';
import SubMenuTitle from "../component/SubMenuTitle";
import {Container} from 'react-bootstrap';
import SHIconButton from '../../components/common/SHIconButton';
import {UserContext} from "../../context/User";
import {getLoginUser} from "../../helper/SecurityHelper";
import OrganizationChartManagementModal from "./modal/OrganizationChartManagementModal";
import axios from "axios";

type Props = {};

const OrganizationChartManagementPage = (props: Props) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isRegist, setIsRegist] = useState<boolean>(true);
    const [orgnChrtMngData, setOrgnChrtMngData] = useState<any>();
    const {user} = useContext<any>(UserContext);
    const defaultUser = getLoginUser();


    useEffect(() => {
        fn_getOrgnChrtMngData(user?.blngSiteId || defaultUser.blngSiteId).then((rsltData) => {
            setOrgnChrtMngData(rsltData);
        });
    },[]);

    useEffect(() =>{
        fn_getOrgnChrtMngData(user?.blngSiteId || defaultUser.blngSiteId).then((rsltData) => {
            setOrgnChrtMngData(rsltData);
        });
    },[user?.blngSiteId, modalIsOpen]);

    const fn_getOrgnChrtMngData = async (siteId: number) =>{
        return new Promise(function (resolve, reject) {
            axios.post('/admin/api/orgnChrtMng/retrieveOrgnChrtMngData', {
                siteId: siteId
            }).then((result) => {
                resolve(result.data);
            })
        });
    }

    const fn_registPopOpen = () => {
        setModalIsOpen(true);
        setIsRegist(true);
    }

    const fn_updatePopOpen = () =>{
        setModalIsOpen(true);
        setIsRegist(false);
    }

    return (
        <Container fluid className="orgnChrtMng-container bg-white px-4 pt-4 sh-text-font_primary">
            <SubMenuTitle title={'조직도 관리'} parentMenu={'현장공사관리'}/>
            <OrganizationChartManagementModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen}
                                         siteId={user?.blngSiteId || defaultUser.blngSiteId} isRegist={isRegist}/>
            <div className='w-100 d-flex justify-content-between align-items-center mb-2'>
                <div className="d-flex" >
                    <div style={{display:orgnChrtMngData ? 'block' : 'none'}}>
                        <span className="text-gray-400">최신업데이트 날짜 : {orgnChrtMngData?.updtDt}</span>
                        &nbsp;
                        <span className="text-gray-400">,</span>
                        &nbsp;
                        <span className="text-gray-400">작성 및 수정자 : {orgnChrtMngData?.updtrNm?orgnChrtMngData?.updtrNm:orgnChrtMngData?.rgstrNm}</span>
                    </div>
                </div>
                <div className="d-flex">
                    <SHIconButton kind={'submit'} marginRight={'5px'} onClick={fn_registPopOpen}>조직도 등록하기</SHIconButton>
                    <SHIconButton kind={'copy'} marginRight={'0'} onClick={fn_updatePopOpen}>이전 조직도 보기</SHIconButton>
                </div>

            </div>
            <div className="d-flex flex-column align-items-center justify-content-center"
                 style={{border: "1.5px solid black"}}>
                <div className="text-center h3 m-2">
                    <span>{orgnChrtMngData?.title ? orgnChrtMngData.title : '등록된 조직도가 없습니다.'}</span>
                </div>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="text-center p-5 w-100">
                    <img className="w-100" src={
                        orgnChrtMngData?.atchFileId ?
                        process.env.REACT_APP_FILE_URL + "/shss-data/fileDownload?attachSeq=" + orgnChrtMngData.atchFileId + '&sn=0&isPdf=0&time=' + new Date().getTime()
                        :''
                    }/>
                </div>
            </div>
        </Container>
    );
};

export default OrganizationChartManagementPage;
