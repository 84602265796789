import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {COMMON_MODAL_STYLE} from "../../../helper/StyleHelper";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import SHSelect from "../../../components/common/SHSelect";
import {getLoginUser} from "../../../helper/SecurityHelper";
import SHSearch from "../../component/SHSearch";
import SHInput from "../../../components/common/SHInput";
import restapi from "../../../helper/restAPIHelper";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    wrkrDataList: object[];
    siteId: number;
    title: string;
    tabGb?: string;
    wrkDt?: string;

}

const $ = require("jquery")

const WrkrInfoListModal = (props: IProps) => {
    const [datatable, setDatatable] = useState<any>();

    const [isCodeDataLoaded, setIsCodeDataLoaded] = useState<any>(null);
    const searchFormRef = useRef<any>();
    const [dataList, setDataList] = useState<any>([]);
    const [orgnDataList, setOrgnDataList] = useState<any>([]);
    const [cmpnyDataList, setCmpnyDataList] = useState<any>([]);
    const [columns, setColumns] = useState([
        {data: "userName", title: "이름", textAlign: 'center'},
        {data: "siteCmpnyNm", title: "업체", textAlign: 'center'},
        {data: "siteWrkTpNm", title: "공종", textAlign: 'center'},
        {data: "siteOcptNm", title: "직종", textAlign: 'center'},
        {data: "inTime", title: "현장 입장 시간", textAlign: 'center'},
        {data: "outTime", title: "현장 퇴장 시간", textAlign: 'center'},


    ])


    useEffect(() => {
        if (isCodeDataLoaded) {
            fn_search();
        }
    }, [isCodeDataLoaded]);

    useEffect(() => {
        if(props.tabGb == 'oldWrkr'){
            setColumns([
                {data: "userName", title: "이름", textAlign: 'center'},
                {data: "siteCmpnyNm", title: "업체", textAlign: 'center'},
                {data: "siteWrkTpNm", title: "공종", textAlign: 'center'},
                {data: "siteOcptNm", title: "직종", textAlign: 'center'},
                {data: "inTime", title: "현장 입장 시간", textAlign: 'center'},
                {data: "outTime", title: "현장 퇴장 시간", textAlign: 'center'},
                {data: "htlthRec", title: "건강상담기록", textAlign: 'center'},


            ])
        }else if(props.tabGb == 'crnt'){
            setColumns([
                {data: "userName", title: "이름", textAlign: 'center'},
                {data: "siteCmpnyNm", title: "업체", textAlign: 'center'},
                {data: "siteWrkTpNm", title: "공종", textAlign: 'center'},
                {data: "siteOcptNm", title: "직종", textAlign: 'center'},
                {data: "inTime", title: "현장 입장 시간", textAlign: 'center'},
            ])
        }else if(props.tabGb == 'out'){
            setColumns([
                {data: "userName", title: "이름", textAlign: 'center'},
                {data: "siteCmpnyNm", title: "업체", textAlign: 'center'},
                {data: "siteWrkTpNm", title: "공종", textAlign: 'center'},
                {data: "siteOcptNm", title: "직종", textAlign: 'center'},
                {data: "outTime", title: "현장 퇴장 시간", textAlign: 'center'},
            ])

        }else if(props.tabGb == 'smSite'){
            setColumns([
                {data: "userName", title: "이름", textAlign: 'center'},
                {data: "companyName", title: "업체", textAlign: 'center'},
                {data: "wrkTpNm", title: "공종", textAlign: 'center'},
                {data: "ocptNm", title: "직종", textAlign: 'center'},
                {data: "tbmYn", title: "TBM 여부", textAlign: 'center'},
            ])
        }else{
            setColumns([
                {data: "userName", title: "이름", textAlign: 'center'},
                {data: "siteCmpnyNm", title: "업체", textAlign: 'center'},
                {data: "siteWrkTpNm", title: "공종", textAlign: 'center'},
                {data: "siteOcptNm", title: "직종", textAlign: 'center'},
                {data: "inTime", title: "현장 입장 시간", textAlign: 'center'},
                {data: "outTime", title: "현장 퇴장 시간", textAlign: 'center'},
            ])
        }
    }, [props.tabGb]);

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_search = (e?: any) => {
        e?.preventDefault()
        datatable?.draw()
    }

    const fn_onShow = () => {
        restapi.retrieveEtrncStatByCmpny({siteId: props.siteId, wrkDt: props.wrkDt}).then((rsltData: any) => {
            setCmpnyDataList(rsltData.inWrkrCmpnyDataList);
            console.log('rsltData cmpny: ', rsltData.inWrkrCmpnyDataList);
        });

        setDataList(props.wrkrDataList);
        setOrgnDataList(props.wrkrDataList);
    }

    const fn_retrieveWrkrDataList = () => {
        const obj = new FormData(searchFormRef.current);
        const userName = obj.get('userName');
        const cmpnySeq = obj.get('cmpnySeq');

        let cstmDataList = [];

        cstmDataList = orgnDataList.filter((data: any) => {
            let isValid = true;
            if (userName) {
                if (data.userName.indexOf(userName) == -1) {
                    isValid = false;
                }
            }

            if (cmpnySeq) {
                if (data.cmpnySeq != cmpnySeq) {
                    isValid = false;
                }
            }
            return isValid;
        })

        setDataList(cstmDataList);

    }

    return (
        <div>

            <Modal
                className="sh-modal" size="xl"
                show={props.modalIsOpen}
                onShow={() => {
                    fn_onShow()

                }}
                onHide={fn_closeModal}
            >


                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        {props.title}
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">
                    <SHSearch formRef={searchFormRef} srchFnc={fn_retrieveWrkrDataList} btnType={'p'}>
                        <Row>
                            <Col>
                                <Table className="sh-font-SP sh-fs-b2 my-3">
                                    <tbody>
                                    <tr className="border-top align-middle border-bottom">
                                        <th className="sh-bg-th">이름</th>
                                        <td>
                                            <SHInput type={'text'} name={'userName'}
                                                     className={'form-control'}></SHInput>
                                        </td>
                                        <th className="sh-bg-th">업체</th>
                                        <td>
                                            <select className={'form-select'} name={'cmpnySeq'}>
                                                <option value={''}>전체</option>
                                                {cmpnyDataList.map((data: any, idx: number) => (
                                                    <option key={idx} value={data.cmpnySeq}>{data.cmpnyNm}</option>
                                                ))

                                                }
                                            </select>

                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                    </SHSearch>

                    <div className={'ge-result'}>
                        <Datatables
                            datas={dataList}
                            scrollY="550px"
                            className="sh-fs-b4"
                            parameterFormRef={searchFormRef}
                            columns={columns}
                            checked={false}
                            onLoad={(dt: any) => {
                                setDatatable(dt)
                            }}

                            onClick={(data) => {

                            }
                            }
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>

                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default WrkrInfoListModal;
