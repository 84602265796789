import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import iconCalendar from "../../../images/icon-calendar-black.svg";
import CustomDatePickerRange from "../../component/ui/DatePickerRange";
import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {fn_getCurrentDate} from "../../../helper/CmmnHelper";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {Col, Row} from "react-bootstrap";
import {Container} from "@mui/material";
import {COMMON_MODAL_STYLE} from "../../../helper/StyleHelper";
import SHInput from "../../../components/common/SHInput";
import SHTextarea from "../../../components/common/SHTextarea";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import SHSelect from "../../../components/common/SHSelect";
import SiteCardList from "../../../components/site/SiteCardList";
import {ChangeMeta, NumberFormatBase} from "react-number-format";
import {Formatter} from "../../../helper/FormatHelper";


interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    setData: Dispatch<SetStateAction<any>>;
    data: any;
    setRiskAsmntFileSrc: Dispatch<SetStateAction<any>>;
    setRiskAsmntFile: Dispatch<SetStateAction<any>>;

}
const $ = require("jquery")

const RskAsmntFileUploadModal = (props: IProps) => {

    const [data, setData] = useState<any>(props.data);
    const fileRef = useRef<any>();

    const fn_setFormData = (e: any) => {

        setData({
            ...data,
            [e.target.name]: e.target.value
        })

    }


    const fn_onAfterOpen = () => {
        setData(props.data);
    }


    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const submit = (e: any) => {
        e.preventDefault()

    }


    const fn_registData = () => {



    }
    const fn_addRiskAsmntFile = (e: any) => {
        if (e.target.files.length > 0) {
            let imgSrc = URL.createObjectURL(e.target.files[0]);

            if (e.target.files[0].type !== 'application/pdf') {
                toast.warn('pdf 형식의 파일만 업로드 가능합니다.');
                return
            }

            if(!$.trim(data.wrkTp)){
                toast.warn('세부공종을 입력해 주십시오.')
                return;
            }
            if(!$.trim(data.wrkLvl)){
                toast.warn('작업단계를 입력해 주십시오.');
                return;
            }

            props.setRiskAsmntFileSrc(imgSrc);
            props.setData(data);
            props.setModalIsOpen(false);
            props.setRiskAsmntFile(e.target.files[0]);

        }
    }


    return (
        <div>


            <Modal
                className="sh-modal sub" size={'lg'}
                show={props.modalIsOpen}
                onShow={fn_onAfterOpen}
                onHide={fn_closeModal}
            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        {'파일올리기'}
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>


                <Modal.Body className="px-4 sh-modal-table-w100">
                    <form name={'form'}>
                        <Table className="my-2 align-middle">
                            <tbody>
                            <tr>
                                <th className="sh-bg-th" style={{width: '25%'}}>{'세부공종'}</th>
                                <td style={{width: '75%'}}>
                                    <SHInput type="text" className="form-control" name={'wrkTp'}
                                             onChange={fn_setFormData} value={data?.wrkTp}/>
                                </td>
                            </tr>
                            <tr>
                                <th className="sh-bg-th" style={{width: '25%'}}>{'작업단계'}</th>
                                <td style={{width: '75%'}}>
                                    <SHInput type="text" className="form-control" name={'wrkLvl'}
                                             onChange={fn_setFormData} value={data?.wrkLvl}/>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </form>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <label  htmlFor={'rskAsmntFileUploadModal_input-file'}
                                className='sh-btn pill p cursor-pointer'  onClick={(e: any) => {
                            fileRef.current.value = null}
                        }>파일 첨부

                        </label>
                        <input className={'hidden'} name={"rskAsmntFile"}  ref={fileRef}
                               id="rskAsmntFileUploadModal_input-file" type={"file"}
                               onChange={fn_addRiskAsmntFile}/>

                    </div>
                </Modal.Footer>

            </Modal>
        </div>
    )
}

export default RskAsmntFileUploadModal;

