import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {IoMdClose} from "react-icons/io";
import SHSelect from "../../../components/common/SHSelect";
import {Col, Modal, Row, Table} from "react-bootstrap";
import SHTreeView from "../../../components/common/SHTreeView";
import restapi from "../../../helper/restAPIHelper";
import RskAsmntSlctModal from "./RskAsmntSlctModal";
import iconTrashBean from "../../../images/icon_trashBean.png";
import IconRemove from '../../../images/icon_remove.png';
import { toast } from "react-toastify";
import iconNodata from "../../../images/icon_docdata.png";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData?: any;
    dngrFctrDataList: object[];
    setDngrFctrDataList: Dispatch<SetStateAction<object[]>>;
    isSingleCheck?: boolean
    siteNm?: string
    setIsCrtCmpltd: Dispatch<SetStateAction<boolean>>;
    siteId:number

}

const RskAsmntCrtModal = (props: IProps) => {
    const [treeData, setTreeData] = useState<any>(null);
    const [item, setItem] = useState('A')
    const [parntCode, setParntCode] = useState<string>('');
    const [parntCodeNm, setParntCodeNm] = useState<string>('');
    const [isSlctModalOpen, setIsSlctModalOpen] = useState<boolean>(false);
    const [dngrFctrDataList, setDngrFctrDataList] = useState<any>([]);

    useEffect(() => {
        restapi.retrieveRskAsmntCodeDataList({siteId:props.siteId ,codeLevel: 2, classCode: item}).then((lvl2DataList: any) => {
            let cstmTreeData = {lvl2DataList: lvl2DataList, lvl3DataList: []};
            restapi.retrieveRskAsmntCodeDataList({siteId:props.siteId ,codeLevel: 3, classCode: item}).then((lvl3DataList: any) => {
                cstmTreeData.lvl3DataList = lvl3DataList;
                setTreeData(cstmTreeData);
            })
        })
    }, [item])


    useEffect(() => {
        if (parntCode) {
            setIsSlctModalOpen(true);
        }
    }, [parntCode])

    useEffect(() => {
        if(!isSlctModalOpen){
            setParntCode('');
        }

    }, [isSlctModalOpen])

    const fn_afterOpenEvnt = () => {
        setDngrFctrDataList(props.dngrFctrDataList);
    }


    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_OnItemChg = (e: any) => {
        setItem(e.target.value);
    }

    const fn_delRow = (idx: number) => {

        // @ts-ignore
        let cstmdngrFctrDataList = [].concat(dngrFctrDataList);
        cstmdngrFctrDataList.splice(idx, 1);
        setDngrFctrDataList(cstmdngrFctrDataList);
    }

    const fn_rgstData = () => {

        if(dngrFctrDataList.length == 0){
            toast.warn('위험성 평가 항목을 선택해 주십시오.')
            return;
        }

        props.setDngrFctrDataList(dngrFctrDataList);
        props.setIsCrtCmpltd(true);
        props.setModalIsOpen(false);
    }

    return (
        <div>
            <RskAsmntSlctModal setModalIsOpen={setIsSlctModalOpen} modalIsOpen={isSlctModalOpen}
                               dngrFctrDataList={dngrFctrDataList} setDngrFctrDataList={setDngrFctrDataList}
                               lvl3Code={parntCode} lvl3CodeNm={parntCodeNm} siteId={props.siteId}></RskAsmntSlctModal>
            <Modal
                className="sh-modal sub" size="xl"
                show={props.modalIsOpen}
                onShow={fn_afterOpenEvnt}
                onHide={fn_closeModal}
            >

                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        위험성 평가 항목 등록
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">
                    <Row>
                        <Col>
                            <div className="d-flex align-items-center">
                                <span className="ps-2 me-4" style={{borderLeft:'2px solid #00bf6d', minWidth: '40px'}}>항목</span>
                                <SHSelect initOptionGb={'none'} name={'item'} onChange={fn_OnItemChg} value={item}
                                          selParam={{codeLevel: 1, siteId:props.siteId}} className="sh-form"
                                          codeGb={'dngrEval'}></SHSelect>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{height:'600px'}}>
                        <Col>
                            <div style={{borderTopLeftRadius:'8px',borderTopRightRadius:'8px'}}
                                 className="sh-bg-accent w-100 text-center sh-text-white py-1 sh-fw-5 mt-2">
                                위험성 평가 항목
                            </div>
                            <SHTreeView setlvl3Code={setParntCode} setlvl3CodeNm={setParntCodeNm} treeData={treeData}></SHTreeView>
                        </Col>
                        <Col>
                            <div style={{borderTopLeftRadius:'8px',borderTopRightRadius:'8px'}}
                                 className="sh-bg-accent w-100 text-center sh-text-white py-1 sh-fw-5 mt-2">
                                위험요인
                            </div>
                            <Table borderless>
                                <tbody>
                                {
                                    dngrFctrDataList.length > 0 ?
                                        dngrFctrDataList.map((data: any, idx: number) => (
                                            <tr key={idx}>
                                                <td>
                                                    {data.code}
                                                </td>
                                                <td className={'text-left'}>
                                                    {data.codeName && data.codeName.length > 30 ? data.codeName.substring(0, 30) + '...' : data.codeName}
                                                </td>

                                                <td className={'text-center'}><img
                                                    className={'w-[20px] cursor-pointer inline-block'}
                                                    src={IconRemove} onClick={() => fn_delRow(idx)}/></td>
                                            </tr>

                                        )) : <tr>
                                            <td colSpan={3}>
                                                <div className="w-100 justify-content-center d-flex flex-column align-items-center py-2">
                                                    <img src={iconNodata} alt="이미지" style={{width: '70px'}} />
                                                    <span className="sh-text-font_secondary02 sh-fw-5 mt-2">선택된 평가 항목이 없습니다. 좌측에서 항목을 선택하세요.</span>
                                                </div>
                                            </td>
                                        </tr>
                                }

                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>취소
                        </button>
                        <button
                            className='sh-btn pill p' onClick={fn_rgstData}>저장
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default RskAsmntCrtModal;
