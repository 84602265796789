import React, {Dispatch, SetStateAction, useState} from "react";
import {IoMdClose} from "react-icons/io";
import {Modal, Table} from "react-bootstrap";
import restapi from "../../../helper/restAPIHelper";
import SHInput from "../../../components/common/SHInput";
import axios from "axios";
import {toast} from "react-toastify";
import iconTrashBean from "../../../images/icon_trashBean.png";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;

    lvl3Code: string;
    lvl3CodeNm: string;
    siteId: number

}

const RskAsmntCdRgstModal = (props: IProps) => {
    const [dataList, setDataList] = useState<any>([])


    const fn_afterOpenEvnt = () => {
        fn_retrieveSiteManageItemList();
    }


    function fn_closeModal() {
        props.setModalIsOpen(false);
    }


    const fn_save = () => {
        let isValid = true;
        let paramDataList: any= [];
        let param = {siteId:props.siteId, parentCode:props.lvl3Code, rskAsmntCdDataList:[]}


        dataList.map((data: any, idx: number) => {
            if(data.chgStat){
                data.codeLevel = 4;
                paramDataList.push(data);
                if(!data.codeName){
                    toast.warn((idx + 1) + ' 번째 위험요인을 입력해 주십시오');
                    isValid = false;
                }

                if(!data.managementMethod){
                    toast.warn((idx + 1) + ' 번째 관리방안을 입력해 주십시오');
                    isValid = false;
                }

            }
        }, []);

        if(!isValid){
            return;
        }

        if(paramDataList.length == 0 ){
            toast.warn('수정된 사항이 없습니다.');
            return;
        }

        param.rskAsmntCdDataList = paramDataList;

        console.log('paramDataList: ', paramDataList);
        if(!window.confirm('저장 하시겠습니까?')){
            return;
        }

        axios.post('/admin/api/rskAsmnt/saveRiskAsmntSiteCodeData', param).then((result) => {
            if (result.data > 0) {
                toast.success(messageCodeDic['001'])
                props.setModalIsOpen(false);
            } else {
                toast.error(errorCodeDic['003']);
            }
        })


    }

    const fn_retrieveSiteManageItemList = () => {

        const param = {code:props.lvl3Code,codeLevel:4,siteId:props.siteId, isCnfg:true}
        restapi.retrieveRskAsmntCodeDataList(param).then((result) => {
            setDataList(result)
        })
    }

    const fn_addRow = () => {

        dataList.push({chgStat: 'C', frequency:1, strength:1, parentCode:props.lvl3Code});
        setDataList([].concat(dataList));
    }

    const fn_delRow = (code: string, selIdx: number) => {
        console.log('code: ', code);

        axios.post('/admin/api/rskAsmnt/retrieveUsedRiskAsmntCodeCnt', {siteId:props.siteId, code:code}).then((result) => {

            if(result.data > 0){
                toast.warn('이미 사용중인 코드는 삭제 할 수 없습니다.');
                return;
            }
            if(code){
                dataList.map((data:any, idx:number) => {
                    if(data.code == code){
                        if(data.chgStat == 'D'){

                            data.chgStat = 'U';
                        }else{
                            if(data.code){
                                data.chgStat = 'D';
                            }
                        }
                    }
                })
            }else{
                dataList.splice(selIdx, 1);
            }
            setDataList([].concat(dataList));

        })


    }

    const fn_setDataList = (e:any, code:number, selIdx:number) => {

        if(code){
            dataList.map((data:any) => {

                if(data.code == code){
                    data[e.target.name] = e.target.value
                    data.chgStat = 'U'
                }

            })
        }else{
            dataList[selIdx][e.target.name] = e.target.value
            dataList[selIdx].chgStat = 'C'
        }

        setDataList([].concat(dataList));
    }

    return (
        <div>

            <Modal
                className="sh-modal sub" fullscreen
                show={props.modalIsOpen}
                onShow={fn_afterOpenEvnt}
                onHide={fn_closeModal}
            >

                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        [ {props.lvl3CodeNm} ] 위험요인
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4 sh-modal-table-w100">
                    <div className="sh-modal-div">
                        <div className="div-header">
                            <div className='flex justify-content-between w-100'>
                                <span>위험요인 코드설정</span>
                                <button className="sh-btn btn-secondary" type="button" onClick={() => {
                                    fn_addRow();
                                }}>추가
                                </button>
                            </div>
                        </div>
                        <div className="div-body px-3">
                            <div className="card-body">
                                <table
                                    className="table table-sm text-center table-bordered border-secondary align-middle text-nowrap">
                                    <thead>
                                    <tr className="table-secondary">
                                        <td className="align-middle w-[5%]">No</td>
                                        <td className="align-middle w-[5%]">상태</td>
                                        <td className="align-middle w-[10%]">피해형태</td>
                                        <td className="align-middle w-[8%]">위험빈도</td>
                                        <td className="align-middle w-[8%]">위험강도</td>
                                        <td className="align-middle w-[35%]">위험요인</td>
                                        <td className="align-middle w-[36%]">관리방안</td>
                                        <td className="align-middle w-[5%]">삭제</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        dataList.length > 0 ?
                                            dataList.map((data: any, idx:number) => (
                                                <tr key={idx}>
                                                    <td>
                                                        {idx+1}
                                                    </td>
                                                    <td className={data.chgStat == 'C' ? 'text-green-500' : data.chgStat ==  'U' ? 'text-blue-600' : data.chgStat ==  'D' ? 'text-red-500' : 'text-black'}
                                                    >{data.chgStat == 'C' ? '신규' : data.chgStat ==  'U' ? '수정' : data.chgStat ==  'D' ? '삭제' : '초기'}
                                                    </td>
                                                    <td>
                                                        <SHInput name={'damageForm'} disabled={data.chgStat=='D' ? true :  false} className="sh-form input text-center" value={data.damageForm} onChange={(e) => {fn_setDataList(e, data.code, idx)}} />
                                                    </td>
                                                    <td>
                                                        <select className={'sh-form select w-100'}
                                                                disabled={data.chgStat=='D' ? true :  false}
                                                                value={data?.frequency || ''}
                                                                onChange={(e) => {fn_setDataList(e, data.code, idx)}} name={'frequency'}>
                                                            <option className={'text-center'} value={1}>1</option>
                                                            <option className={'text-center'} value={2}>2</option>
                                                            <option className={'text-center'} value={3}>3</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select className={'sh-form select w-100'}
                                                                disabled={data.chgStat=='D' ? true :  false}
                                                                value={data?.strength || ''}
                                                                onChange={(e) => {fn_setDataList(e, data.code, idx)}} name={'strength'}>
                                                            <option className={'text-center'} value={1}>1</option>
                                                            <option className={'text-center'} value={2}>2</option>
                                                            <option className={'text-center'} value={3}>3</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <SHInput name={'codeName'} className={'sh-form form-control'} disabled={data.chgStat=='D' ? true :  false} value={data.codeName} onChange={(e) => {fn_setDataList(e, data.code, idx)}} />

                                                    </td>
                                                    <td>
                                                        <SHInput name={'managementMethod'} className={'sh-form form-control'} disabled={data.chgStat=='D' ? true :  false} value={data.managementMethod} onChange={(e) => {fn_setDataList(e, data.code, idx)}} />
                                                    </td>

                                                    <td className={'text-center'}><img
                                                        className={'w-[25px] cursor-pointer inline-block'}
                                                        src={iconTrashBean} onClick={() => fn_delRow(data.code, idx)}/>
                                                    </td>
                                                </tr>

                                            )) : <tr>
                                                <td colSpan={8}>위험요인을 등록해 주세요.</td>
                                            </tr>
                                    }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <button onClick={fn_save}
                                className='sh-btn pill p'>저장
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default RskAsmntCdRgstModal;
