import React from "react";
import {Container, Row} from "react-bootstrap";
import HOCRMap from "./HOCRMap";
import DashWeather from "../home/DashWeather";
import HOCRTBMStatus from "./HOCRTBMStatus";
import HOCRInOut from "./HOCRInOut";
import HOCRRiskStatus from "./HOCRRiskStatus";
import HOCRTrafficLight from "./HOCRTrafficLight";

export default function MainControl() {


    return(
        <Container fluid className="py-3 sh-bg-black" style={{overflow: "hidden"}}>
            <Row>
                <div style={{flex: "1 0 0%"}}>
                    <HOCRMap />
                </div>
                <div style={{width:"500px", height:"calc(100vh - 86px)", overflowY:"scroll"}}>
                    <DashWeather />
                    <HOCRTrafficLight />
                    <HOCRInOut />
                    <HOCRTBMStatus />
                    <HOCRRiskStatus />
                </div>
            </Row>
        </Container>
    )
}
