import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import Datatables from "../../component/Datatables";
import SHSelect from "../../../components/common/SHSelect";
import SHSearch from "../../component/SHSearch";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    incgrtyDataList: object[];
    siteId: number;
    prcsStatCd: string;

}

const $ = require("jquery")

const IncgrtyInfoListModal = (props: IProps) => {
    const [datatable, setDatatable] = useState<any>();

    const [isCodeDataLoaded, setIsCodeDataLoaded] = useState<any>(null);
    const searchFormRef = useRef<any>();
    const [dataList, setDataList] = useState<any>([]);
    const [orgnDataList, setOrgnDataList] = useState<any>([]);
    const [cmpnyDataList, setCmpnyDataList] = useState<any>([])


    useEffect(() => {
        if (isCodeDataLoaded) {
            fn_search();
        }
    }, [isCodeDataLoaded]);

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_search = (e?: any) => {
        e?.preventDefault()
        datatable?.draw()
    }

    const fn_onShow = () => {

        let cstmDataList = [];

        if(props.prcsStatCd){
            cstmDataList = props.incgrtyDataList.filter((data: any) => (data.prcsStatCd == props.prcsStatCd))
        }else{
            cstmDataList = props.incgrtyDataList
        }

        setDataList(cstmDataList);

        setOrgnDataList(props.incgrtyDataList);
    }

    const fn_retrieveDataList = () => {
        const obj = new FormData(searchFormRef.current);
        const prcsStatCd = obj.get('prcsStatC');

        let cstmDataList = [];

        cstmDataList = orgnDataList.filter((data: any) => {
            let isValid = true;

            if (prcsStatCd) {
                if (data.prcsStatCd != prcsStatCd) {
                    isValid = false;
                }
            }
            return isValid;
        })

        setDataList(cstmDataList);

    }

    return (
        <div>

            <Modal
                className="sh-modal" size="xl"
                show={props.modalIsOpen}
                onShow={() => {
                    fn_onShow()

                }}
                onHide={fn_closeModal}
            >


                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        {'부적합 현황'}
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">
                    <SHSearch formRef={searchFormRef} srchFnc={fn_retrieveDataList} btnType={'p'}>
                        <Row>
                            <Col>
                                <Table className="sh-font-SP sh-fs-b2 my-3">
                                    <tbody>
                                    <tr className="border-top align-middle border-bottom">
                                        <th className="sh-bg-th">상태</th>
                                        <td>
                                            <SHSelect codeGb={'cmmn'} disabled={true} codeGrpId={'I001'} name={'prcsStatCd'} value={props.prcsStatCd} initOptionGb={'all'}/>

                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                    </SHSearch>

                    <div className={'ge-result'}>
                        <Datatables
                            datas={dataList}
                            scrollY="550px"
                            className="sh-fs-b4"
                            parameterFormRef={searchFormRef}
                            columns={[
                                {data: "prcsStatNm", title: "상태", textAlign: 'center'},
                                {data: "incgrtyTitle", title: "제목", textAlign: 'left'},
                                {data: "incgrtyPlcNm", title: "위치", textAlign: 'center'},
                                {data: "rgstDt", title: "작성일", textAlign: 'center'},
                                {data: "rgstrNm", title: "작성자", textAlign: 'center'},
                                {data: "actCmpltDt", title: "조치 완료일", textAlign: 'center'},

                            ]}
                            checked={false}
                            onLoad={(dt: any) => {
                                setDatatable(dt)
                            }}

                            onClick={(data) => {

                            }
                            }
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>

                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default IncgrtyInfoListModal;
