import SHSearch from "../../component/SHSearch";
import {Col, Row, Table} from "react-bootstrap";
import SHSelect from "../../../components/common/SHSelect";
import {CmmnFn} from "../../../helper/CmmnHelper";
import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import Datatables from "../../component/Datatables";
import SHInput from "../../../components/common/SHInput";
import AsscWrkDtlModal from "../modal/AsscWrkDtlModal";
import SHDatePicker2 from "../../../components/common/SHDatePicker2";
import {DateHelper} from "../../../helper/DateHelper";

interface IProps {
   statData: any;
   dataList: any;
   srchData: any;
   setSrchData: Dispatch<SetStateAction<boolean>>;
   setSelData: Dispatch<SetStateAction<any>>;
   wrkDt: string;
   setWrkDt: Dispatch<SetStateAction<string>>;
   isDatePopupOpen: boolean;
   setIsDatePopupOpen: Dispatch<SetStateAction<boolean>>;

}

const AsscWrkrInOutInfoPage = (props: IProps) => {
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [detailData, setDetailData] = useState<any>({});
    const [calenderDate, setCalenderDate] = useState<Date | null>(null)
    useEffect(() => {
        setCalenderDate(DateHelper.parse(props.wrkDt))
    }, [props.wrkDt])
    useEffect(() => {
        var d = DateHelper.format(calenderDate)
        if(d){
            props.setWrkDt(d)
        }else{
            props.setWrkDt('')
        }
    }, [calenderDate])
    return(
        <>
            <AsscWrkDtlModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} detailData={detailData} />
            <SHSearch btnType={'p'} datatable={datatable} formRef={searchFormRef}>
                <Row>
                    <Col>
                        <Table className="sh-font-SP sh-fs-b2 my-3">
                            <tbody>
                            <tr className="border-top align-middle">
                                <th className="sh-bg-th" style={{width: '10%'}}>현장</th>
                                <td style={{width: '15%'}}>
                                    <SHInput type={'hidden'} name={'isCnstrAssc'} value={'Y'} />
                                    <SHSelect initOptionGb={'all'} className={'form-select'} name={'siteId'} codeGb={'site'} value={props.srchData.siteId} onChange={e => CmmnFn.setFormData(e, props.srchData, props.setSrchData)} ></SHSelect>
                                </td>
                                <th className="sh-bg-th" style={{width: '10%'}}>업체</th>
                                <td style={{width: '15%'}}>
                                    <SHSelect name={'blngCmpnySeq'} onChange={e => CmmnFn.setFormData(e, props.srchData, props.setSrchData)} value={props.srchData.blngCmpnySeq} initOptionGb={'all'} codeGb={'siteCmpny'} selParam={{siteId:props.srchData.siteId}}></SHSelect>
                                </td>
                                <th className="sh-bg-th" style={{width: '10%'}}>국적</th>
                                <td style={{width: '15%'}}>
                                    <SHSelect initOptionGb={'all'} name={'nationId'} codeGb={'nation'} value={props.srchData.nation}
                                              onChange={e => CmmnFn.setFormData(e, props.srchData, props.setSrchData)}
                                    ></SHSelect>
                                </td>
                                <th className="sh-bg-th" style={{width: '10%'}}>일자</th>
                                <td style={{width: '15%'}}>
                                    <SHDatePicker2 setter={setCalenderDate} name={'rgstDt'} defaultValue={calenderDate}/>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </SHSearch>

            <div className={'ge-result'}>
                <Datatables
                    datas={props.dataList}
                    scrollY="550px"
                    className="sh-fs-b4"
                    parameterFormRef={searchFormRef}
                    columns={[
                        {data: "workerSeq", title: "근로자 일련번호", textAlign: 'center'},
                        {data: "userName", title: "성명", textAlign: 'center'},
                        {data: "siteName", title: "소속현장", textAlign: 'center'},
                        {data: "userPhone", title: "전화번호", textAlign: 'center'},
                        {data: "cmpnyNm", title: "소속업체", textAlign: 'center'},
                        {data: "entrTime", title: "출근시간", textAlign: 'center'},
                        {data: "exitTime", title: "퇴근시간", textAlign: 'center'},

                    ]}
                    checked={false}
                    onLoad={(dt: any) => {
                        setDatatable(dt)
                    }}

                    onClick={(data) => {

                    }
                    }
                />


            </div>
        </>
        )

}

export default AsscWrkrInOutInfoPage;
