import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import iconCalendar from "../../../images/icon-calendar-black.svg";
import CustomDatePickerRange from "../../component/ui/DatePickerRange";
import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {fn_getCurrentDate} from "../../../helper/CmmnHelper";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {Col, Row} from "react-bootstrap";
import {Container} from "@mui/material";
import {COMMON_MODAL_STYLE} from "../../../helper/StyleHelper";
import SHInput from "../../../components/common/SHInput";
import SHTextarea from "../../../components/common/SHTextarea";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import SHSelect from "../../../components/common/SHSelect";
import SiteCardList from "../../../components/site/SiteCardList";
import {ChangeMeta, NumberFormatBase} from "react-number-format";
import {Formatter} from "../../../helper/FormatHelper";
import SiteSearchModal from "../../companyManagement/modal/SiteSearchModal";
import SlctAuthChrgModal from "./SlctAuthChrgModal";
import iconTrashBean from "../../../images/icon_trashBean.png";
import restapi from "../../../helper/restAPIHelper";
import CmpnySrchModal from "../../headManage/modal/CmpnySrchModal";
import {ProgressContext} from "../../../context/Progress";
import SHFile from "../../../components/common/SHFile";
import SHIconButton from "../../../components/common/SHIconButton";


interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
    setIsSaveSuccess: Dispatch<SetStateAction<boolean>>;
    siteId: number;
}

const SiteMngrRgstModal = (props: IProps) => {

    const currentDate = fn_getCurrentDate();
    const user = getLoginUser();

    const [data, setData] = useState<any>()
    const [isCmpnyModalOpen, setIsCmpnyModalOpen] = useState<boolean>(false);
    const [cmpnyDataList, setCmpnyDataList] = useState<any>([]);
    const [companyGbCd, setCompanyGbCd] = useState<any>(null);
    const {spinner, setProcess} = useContext(ProgressContext);

    const [fileList, setFileList] = useState([]);
    const formRef = React.createRef<HTMLFormElement>();
    const [isDuplChCked, setIsDuplChCked] = useState<boolean>(false);
    const [isEmployeeSiteAuthPopOpend, setIsEmployeeSiteAuthPopOpend] = React.useState<boolean>(false);
    const [isSitePopOpend, setIsSitePopOpend] = React.useState<boolean>(false);
    const [authChrgDataList, setAuthChrgDataList] = useState<any>([]);


    const fn_setFormData = (e: any) => {

        setData({
            ...data,
            [e.target.name]: e.target.value
        })

    }

    useEffect(() => {
        if(cmpnyDataList.length > 0){
            setData({
                ...data,
                'blngCmpnySeq' : cmpnyDataList[0].companySeq,
                'companyName' : cmpnyDataList[0].companyName,
            })
            setAuthChrgDataList([]);
        }
    }, [cmpnyDataList])


    const fn_onAfterOpen = () => {
        // 신규
        setCmpnyDataList([]);
        if (!props.detailData) {
            setData(null)
            setAuthChrgDataList([]);
            setFileList([]);
            setIsDuplChCked(false)
            // 상세
        } else {
            setData(props.detailData);
            restapi.retrieveUserSiteAuthDataList(props.detailData.userSeq, 'siteMstr').then((rsltData) => {
                setAuthChrgDataList([].concat(rsltData));
            })

            if(props.detailData.atchFileId){
                restapi.retrieveAttachInfoList({attachSeq: props.detailData.atchFileId}).then((rsltData: any) => {
                    setFileList([].concat(rsltData));
                })
            }else{
                setFileList([]);
            }

            setIsDuplChCked(true)
        }
    }


    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const submit = (e: any) => {
        e.preventDefault()

    }



    const fn_registData = () => {
        console.log('data: ', data);

        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if (!data.userName) {
            toast.warn('성명을 입력해 주십시오.')
            return;
        }


        if (!data.userPhone) {
            toast.warn('휴대전화 번호를 입력해 주십시오.');
            return;
        }

        if (!data.userId) {
            toast.warn('아이디를 입력해 주십시오.');
            return;
        }

        if (!isDuplChCked) {
            toast.warn('사용자 ID 중복확인을 해 주십시오.');
            return;
        }

        if (!data.blngCmpnySeq){
            toast.warn('소속업체를 입력해 주십시오.');
            return;
        }

        if (!props.detailData) {
            if (!data.userPassword) {
                toast.warn('비밀번호를 입력해 주십시오');
                return;
            }

            if (!data.userPasswordCnfrm) {
                toast.warn('비밀번호를 확인해 주십시오');
                return;
            }

            if (data.userPassword != data.userPasswordCnfrm) {
                toast.warn('설정한 비밀번호가 일치하지 않습니다')
                return;
            }
            data.authCode = 'siteMstr';
        }



        let param = data;
        param.userPhone = param.userPhone.replaceAll('-', '');
        param.authChrgDataList = authChrgDataList;
        param.rgstrSeq = user.userSeq;
        param.updtrSeq = user.userSeq

        if (!window.confirm('저장 하시겠습니까?')) {
            return;
        }

        console.log('param: ', param);
        // @ts-ignore
        var formData: any = new FormData(formRef.current)

        if(fileList.length > 0){
            for(var i =0;i<fileList.length;i++){
                formData.append('atchFileList',fileList[i]);
            }
        }

        formData.set('userVO', new Blob([JSON.stringify(param)], {type: "application/json"}))

        axios.post('/admin/api/user/saveSiteMngrData', formData).then((result) => {
                if (result.data > 0) {
                    toast.success(messageCodeDic['001'])
                    props.setModalIsOpen(false);
                    props.setIsSaveSuccess(true);
                } else {
                    if (result.data == -12) {
                        toast.error('이미 존재하는 아이디 입니다.');
                    } else {
                        toast.error(errorCodeDic['003']);
                    }
                    props.setIsSaveSuccess(false);
                }
            }
        )


    }

    const fn_delRow = (idx: number) => {
        let cstmAuthChrgDataList: any = [].concat(authChrgDataList);

        cstmAuthChrgDataList.splice(idx, 1);
        setAuthChrgDataList([].concat(cstmAuthChrgDataList));
    }

    const fn_chkIsRep = (idx: number) => {
        let cstmAuthChrgDataList: any = [].concat(authChrgDataList);
        cstmAuthChrgDataList.map((data: any, dataIdx: number) => {
            if (idx == dataIdx) {
                data.isRep = true
            } else {
                data.isRep = false
            }
        })
        setAuthChrgDataList(cstmAuthChrgDataList);

    }

    const fn_openCmpnyModal = () => {

        if(props.detailData?.siteAuthCnt == 0 || !props.detailData){
            setIsCmpnyModalOpen(true)
        }
    }

    const fn_initPassword = () => {
        if(!window.confirm('해당 사용자의 비밀번호를 초기화(\'0000\') 하시겠습니까?')){
            return;
        }

        axios.post('/admin/api/user/updateInitPassword', {userSeq:props.detailData.userSeq}).then((rsltData: any) => {
            if(rsltData.data > 0){
                toast.success(messageCodeDic['006']);
            }else{
                toast.error(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });
    }

    const fn_chkDupl = () => {

        if (!data || !data.userId) {
            toast.warn('사용자 ID를 입력해 주십시오.');
            return;
        }

        if (data.userId) {
            spinner.start();
            restapi.retrieveUserInfoDtl({userId: data.userId}).then((rsltData: any) => {
                console.log('rsltData: ', rsltData);
                if (rsltData) {
                    toast.warn('이미 존재하는 사용자 ID 입니다.')
                } else {
                    toast.success('사용 가능한 사용자 ID 입니다.')
                    setIsDuplChCked(true);
                }

            }).catch(error => {
                toast.error(errorCodeDic['003']);
                console.log('error: ', error);
            })
                .finally(() => {
                    spinner.stop();
                });

        }

    }

    const fn_openSitePopup = () => {
        if(!data?.blngCmpnySeq){
            toast.warn('소속업체를 선택해 주십시오');
            return;
        }
        setIsSitePopOpend(true);
    }

    const fn_chageAuthChrgDataList = (e: any, idx: number) => {
        let cstmAuthChrgDataList: any = [].concat(authChrgDataList);
        cstmAuthChrgDataList.map((data: any, dataIdx: number) => {
            if (idx == dataIdx) {
                data[e.target.name] = e.target.value
            }
        })
        setAuthChrgDataList([].concat(cstmAuthChrgDataList));
    }

    const fn_addAllSite = () => {
        if(!data?.blngCmpnySeq){
            toast.warn('소속업체를 선택해 주십시오');
            return;
        }

        if(!window.confirm('해당 관리자에게 전체현장(업체소속)을 배정 하시겠습니까?')){
            return;
        }

        spinner.start();
        restapi.retrieveSiteDataListNoPaging({blngCmpnySeq: data.blngCmpnySeq}).then((rsltData: any) => {
            console.log('rsltData: ', rsltData);
            setAuthChrgDataList([].concat(rsltData));
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });
    }

    return (
        <div>
            <CmpnySrchModal setModalIsOpen={setIsCmpnyModalOpen} modalIsOpen={isCmpnyModalOpen} isHeadAuth={'true'}
                            cmpnyDataList={cmpnyDataList} setCmpnyDataList={setCmpnyDataList}
                            isSingleCheck={true}/>
            <SiteSearchModal setModalIsOpen={setIsSitePopOpend} modalIsOpen={isSitePopOpend} blngSiteList={authChrgDataList}
                             setBlngSiteList={setAuthChrgDataList} blngCmpnySeq={data?.blngCmpnySeq}/>

            <Modal
                className="sh-modal" size="xl"
                show={props.modalIsOpen}
                onShow={fn_onAfterOpen}
                onHide={fn_closeModal}

            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        현장 관리자 등록/수정
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <form name={'form'} ref={formRef} encType="multipart/form-data" method={"post"}
                          onSubmit={submit}>
                        <div className="sh-modal-div">
                            <div className="div-header">
                                관리자 정보
                                {props.detailData?.userSeq?
                                    <button className="sh-btn btn-secondary btn-sm" type="button"
                                            onClick={fn_initPassword}>비밀번호 초기화
                                    </button> :''
                                }
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>성명</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput type="text" className="form-control" name={'userName'}
                                                     onChange={fn_setFormData} value={data?.userName}/>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>휴대전화</th>
                                        <td style={{width: '35%'}}>
                                            {
                                                //@ts-ignore
                                                <NumberFormatBase onChange={fn_setFormData} name={'userPhone'}
                                                                  className={'form-control'}
                                                                  value={data?.userPhone || ''}
                                                                  format={Formatter.phoneNumFormat}
                                                />
                                            }
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>직위</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput type="text" className="form-control" name={'position'}
                                                     onChange={fn_setFormData} value={data?.position}/>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>소속업체</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <SHInput type={'text'} readOnly={true} className={'form-control'} name={'companyName'} value={data?.companyName} />

                                                <button className="sh-btn btn-secondary min-w-[70px] ml-5"
                                                        type="button" onClick={() => fn_openCmpnyModal()}>검색
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <td colSpan={2}></td>
                                        <td colSpan={2}>
                                            <span className="font-semibold text-sm">※ 현장권한이 설정된 사용자의 업체는 변경할 수 없습니다.</span>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>아이디</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex flex-row gap-2">
                                                <div className="d-flex align-items-center flex-grow-1">
                                                    <SHInput type="text" className="form-control flex-grow-1 d-flex" name={'userId'}
                                                             onChange={fn_setFormData} value={data?.userId} disabled={props.detailData?.userId?true:false}/>
                                                </div>
                                                {!props.detailData &&
                                                    <div className="d-flex align-items-center w-[90px]">

                                                        <button className="sh-btn btn-secondary ml-5 d-flex"
                                                                type="button" onClick={fn_chkDupl}>중복확인
                                                        </button>

                                                </div>
                                                }
                                            </div>
                                        </td>
                                        {!props.detailData &&
                                            <>
                                                <th className="sh-bg-th" style={{width: '15%'}}>비밀번호</th>
                                                <td style={{width: '35%'}}>
                                                    <SHInput type="password" className="form-control"
                                                             name={'userPassword'}
                                                             onChange={fn_setFormData} value={data?.userPassword}/>
                                                </td>
                                            </>
                                        }
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>이메일</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput type="text" className="form-control" name={'userEmail'}
                                                     onChange={fn_setFormData} value={data?.userEmail}/>
                                        </td>
                                        {!props.detailData && <>
                                            <th className="sh-bg-th" style={{width: '15%'}}>비밀번호 확인</th>
                                            <td style={{width: '35%'}}>
                                                <SHInput type="password" className="form-control"
                                                         name={'userPasswordCnfrm'}
                                                         onChange={fn_setFormData} value={data?.userPasswordCnfrm}/>
                                            </td>
                                        </>
                                        }
                                    </tr>
                                    <tr className="align-middle" style={{width: '15%'}}>
                                        <th className="sh-bg-th">첨부파일</th>
                                        <td colSpan={3}>
                                            <SHFile fileName={'fileList'} fileList={fileList} setFileList={setFileList}></SHFile>
                                        </td>
                                    </tr>

                                    </tbody>
                                </Table>

                            </div>

                        </div>


                        <div className="sh-modal-div">
                            <div className="div-header">
                                <div className='flex justify-content-between align-items-center w-100'>
                                    <span>배정 현장 정보</span>
                                    <div className='flex justify-content-between align-items-center'>
                                        <SHIconButton kind='add' marginRight={'5px'} onClick={fn_addAllSite}>전체 현장추가</SHIconButton>
                                        <SHIconButton kind='add' onClick={fn_openSitePopup}>현장추가</SHIconButton>
                                    </div>
                                </div>
                            </div>
                            <div className={"div-body px-3 pt-3" + (authChrgDataList.length > 2 && ' overflow-auto h-[200px]')} >
                                <table className="table sub">
                                    <colgroup>
                                        <col width='5%' />
                                        <col width='80%' />
                                        <col width='10%' />
                                        <col width='5%' />
                                    </colgroup>
                                    <thead>
                                    <tr className="table-secondary">
                                        <th className="align-middle">No</th>
                                        <th className="align-middle">현장</th>
                                        <th className="align-middle">권한</th>
                                        <th className="align-middle">삭제</th>
                                    </tr>
                                    </thead>
                                    <tbody id="wrkDclrRgst_siteTBody">
                                    {
                                        authChrgDataList.length > 0 ?
                                            authChrgDataList.map((authChrgData: any, idx: number) => (
                                                <tr key={idx}>
                                                    <td className={'text-center'}>
                                                        {idx + 1}
                                                    </td>
                                                    <td>
                                                        <SHSelect initOptionGb={'none'} disabled={true} codeGb={'site'}
                                                                  onChange={fn_setFormData} isForDtl={true} className={'form-control'}
                                                                  value={authChrgData.siteId}></SHSelect>
                                                    </td>
                                                    <td>
                                                        <SHSelect initOptionGb={'none'} disabled={true} codeGb={'auth'}
                                                                  className={'form-control'} name={'authCode'}
                                                                  onChange={(e: any) => fn_chageAuthChrgDataList(e, idx)}
                                                                  selParam={{authGrpId: 'SITE', authCode: 'siteMstr'}}
                                                                  value={authChrgData.authCode}></SHSelect>
                                                    </td>

                                                    <td className={'text-center'}>
                                                        <img
                                                            className={'w-[25px] cursor-pointer inline-block'}
                                                            src={iconTrashBean} onClick={() => fn_delRow(idx)}/>
                                                    </td>
                                                </tr>

                                            )) : <tr>
                                                <td colSpan={4} className={'text-center'}>현장별 권한을 등록해 주세요.</td>
                                            </tr>
                                    }

                                    </tbody>
                                </table>
                            </div>
                        </div>




                        <div className="sh-modal-div">
                            <div className="div-header">
                                작성정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>작성일</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput value={data?.regDate || currentDate} name={'rgstDt'} onChange={fn_setFormData}
                                                     className={"form-control"}
                                                     disabled={true}/>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>작성자</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput className={"form-control"} name={'rgstrNm'}
                                                     value={data?.rgstrNm || user.userName}
                                                     onChange={fn_setFormData}
                                                     disabled={true}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                    </form>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        {user.authCode != 'headRead' &&
                        <button onClick={fn_registData}
                                className='sh-btn pill p'>등록/수정
                        </button>
                        }
                    </div>
                </Modal.Footer>

            </Modal>
        </div>
    )
}

export default SiteMngrRgstModal;

