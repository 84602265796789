import React, {useContext, useEffect} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {TopMenuContext, TopMenuProvider} from "../../context/TopMenu";
import DashboardWeather from "../home/DashWeather";
import DashboardRiskAssessment from "../home/DashRiskAssessment";
import DashboardNotice from "../home/DashNotice";
import SSDashInOut from "./SSDashInOut";
import SSDashWorkPermitDoc from "./SSDashWorkPermitDoc";
import SSDashSOSCall from "./SSDashSOSCall";
import SSDashStopWork from "./SSDashStopWork";
import SSDashSiteStatus from "./SSDashSiteStatus";
import SSDashRiskAssessment from "./SSDashRiskAssessment";
import {SiteContext} from "../../context/Site";

type Props = {};

const SSMonitoringPage = (props: Props) => {


    return (
        <>
            <Container className="px-4 pt-4" fluid style={{letterSpacing:'-0.5px', backgroundColor:'#151517'}}>
                <Row>
                    <Col bsPrefix="col-3">
                        <DashboardWeather/>
                        <SSDashRiskAssessment />
                        <DashboardNotice />
                    </Col>
                    <Col bsPrefix="col-9">
                        <Row className={"mb-3"}>
                            <Col>
                                <SSDashInOut />
                            </Col>
                            <Col bsPrefix="col-6">
                                <SSDashWorkPermitDoc />
                            </Col>
                            <Col>
                                <SSDashSOSCall />
                            </Col>
                            <Col>
                                <SSDashStopWork />
                            </Col>

                        </Row>
                        <Row>
                            <Col bsPrefix="col-12">
                                <SSDashSiteStatus />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
export default SSMonitoringPage;

