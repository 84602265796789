import React, {createContext, useEffect, useState} from 'react';

const MenuOpenContext = createContext({
  open: false,
  setOpen: (open:boolean) => { },
});

const MenuOpenProvider = ({children}: any) => {
  const [open, setOpen] = useState(false);
  return <MenuOpenContext.Provider value={{open, setOpen}}>{children}</MenuOpenContext.Provider>;
};

export {MenuOpenContext, MenuOpenProvider};
