import React, {useContext, useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {ReactComponent as IconTeam} from "../../assets/images/ico/icon-team.svg";
import restapi from "../../helper/restAPIHelper";
import {CmmnFn} from "../../helper/CmmnHelper";
import {UserContext} from "../../context/User";
import {getLoginUser} from "../../helper/SecurityHelper";
import WrkrInfoListModal from '../home/modal/WrkrInfoListModal';

const SSDashInOut = () => {
    const {user} = useContext<any>(UserContext);
    const defaultUser = getLoginUser();
    const [dataList, setDataList] = useState<any>([]);
    const [modalIsOpen, setModalIsOpen] = React.useState(false);

    useEffect(() => {
        const siteId = user?.blngSiteId || defaultUser.blngSiteId;
        restapi.retrievePrmtWrkrDataList({
            siteId: siteId
            , wrkDt: CmmnFn.fn_getCurrentDate()
            , tbmYn: 'Y'
        }).then((rsltData: any) => {
            setDataList(rsltData);
        })



    }, [user])

    return(
      <>
          <WrkrInfoListModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen}  wrkrDataList={dataList} siteId={user?.blngSiteId || defaultUser.blngSiteId} title={'총 출역'} tabGb={'smSite'} ></WrkrInfoListModal>
          <Container fluid className="sh-bg-pane rounded-[5px] py-2 px-3 h-100">
              <Row>
                  <Col>
                      <div className="d-flex flex-column justify-content-between cursor-pointer" onClick={() => setModalIsOpen(true)}>
                          <div>
                              <div className="pe-2 sh-fs-h4 sh-fw-5 sh-font-SD mb-3">총 출역 인원</div>
                              <div className="d-flex rounded-3 justify-content-center" style={{backgroundColor: '#35d0b7', width: '4rem', height: '4rem'}}>
                                  <IconTeam className="sh-fill-white" style={{width: '3rem'}}/>
                              </div>
                          </div>
                          <div className="text-end sh-fs-h4 sh-fw-5">
                              <span className="sh-fs-h1 me-1">{dataList.length}</span>명
                          </div>
                      </div>
                  </Col>
              </Row>
          </Container>
      </>
    );
}

export default SSDashInOut;
