import React, {useContext, useEffect, useRef, useState} from 'react';
import restapi from "../../../helper/restAPIHelper";
import {get, transform, transformExtent} from "ol/proj";
import {Feature, Overlay} from "ol";
import {Point} from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import {Cluster, XYZ} from "ol/source";
import VectorSource from "ol/source/Vector";
import {Circle, Fill, Icon, Stroke, Style, Text} from "ol/style";
import TileLayer from "ol/layer/Tile";
import {GeoJSON} from "ol/format";
import proj4 from "proj4";
import {register} from "ol/proj/proj4";
import Map from "ol/Map";
import View from "ol/View";
import SiteInfoListModal from "../modal/SiteInfoListModal";
import {CmmnFn} from "../../../helper/CmmnHelper";
import {SiteContext} from "../../../context/Site";
import {TopMenuContext} from "../../../context/TopMenu";
const $ = require("jquery")
export default function InfoMap() {
    const mapRef = useRef<HTMLDivElement>(null);
    const [total, setTotal] = useState<number>(0);
    const [countT002001, setCountT002001] = useState<number>(0);
    const [countT002002, setCountT002002] = useState<number>(0);
    const [countT002003, setCountT002003] = useState<number>(0);
    const [countT002004, setCountT002004] = useState<number>(0);
    const [countT002005, setCountT002005] = useState<number>(0);
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [dataList, setDataList] = useState<any>([]);
    const { menu, setMenu } = useContext<any>(TopMenuContext);


    function open_site_list(siteIds: string[]){

        restapi.retrieveSiteDataListNoPaging({siteIds:siteIds}).then((rsltData: any) => {
            setDataList(rsltData);
            setModalIsOpen(true);
        })

    }
    function load_site(siteLayer: any){
        restapi.retrieveSiteDataListNoPaging({isSmSite: false, yyyymmdd: CmmnFn.fn_getCurrentDate().replace(/-/gi, "")}).then((rsltData: any) => {
            if(rsltData.length > 0){
                var features = []
                var count1 = 0
                var count2 = 0
                var count3 = 0
                var count4 = 0
                var count5 = 0
                for(var i in rsltData){
                    var item = rsltData[i]
                    var center = transform([item.longitude, item.latitude], 'EPSG:4326', 'EPSG:3857')
                    var feature1 = new Feature({
                        geometry: new Point(center),
                    })
                    feature1.set("LABEL", item.siteName)
                    feature1.set("TYPE_CODE", item.siteType)
                    feature1.set("SITE_ID", item.siteId)
                    features.push(feature1)
                    var typeCode = item.siteType
                    if(typeCode === 'T002001'){
                        // 택지조성공사
                        count1++
                    }else if(typeCode === 'T002002'){
                        // 기반시설공사
                        count2++
                    }else if(typeCode === 'T002003'){
                        // 공동주택건축공사
                        count3++
                    }else if(typeCode === 'T002004'){
                        // 소규모공사
                        count4++
                    }else if(typeCode === 'T002005'){
                        // 해체공사
                        count5++
                    }
                }
                setTotal(rsltData.length)
                setCountT002001(count1)
                setCountT002002(count2)
                setCountT002003(count3)
                setCountT002004(count4)
                setCountT002005(count5)
                siteLayer.getSource().getSource().addFeatures(features)
            }
        })
    }
    useEffect(() => {
        var statisLayer = new VectorLayer({
            source : new Cluster({
                distance: 50,
                source: new VectorSource()
            }),
            maxZoom:19,
            style : function(feature){
                var features = feature.get('features');
                var count = features.length
                var radius = 18 + (1.5 * (count));
                var color = '#BE55F0'
                if(count > 10){
                    color = '#695DFB'
                }else if(count > 6){
                    color = '#FF505D'
                }else if(count > 3){
                    color = '#FAE634'
                }
                var circleStyle = new Circle({
                    radius: radius,
                    stroke: new Stroke({
                        color: "#ffffffaa",
                        width: 4
                    }),
                    fill: new Fill({
                        color: color + "aa"
                    })
                })
                return new Style({
                    image: new Circle({
                        radius: radius,
                        stroke: new Stroke({
                            color: "#ffffffaa",
                            width: 4
                        }),
                        fill: new Fill({
                            color: color + "cc",
                        })
                    }),
                    text: new Text({
                        text: count + "",
                        font:'Bold 14px Verdana',
                        fill: new Fill({
                            color: '#ffffff'
                        })
                    })
                });
            }
        })
        statisLayer.setVisible(true)
        var BASE1_LAYER = new TileLayer({visible : true, opacity: 0.1})
        BASE1_LAYER.setSource(new XYZ({
            projection: "EPSG:900913",
            tileUrlFunction : function(tileCoord, pixelRatio, projection){
                var z = tileCoord[0]
                var x = tileCoord[1]
                var y = tileCoord[2]
                //
                var type = "2D영상"
                var path = null
                var ext = 'png'
                if(type == '2D지도'){
                    path = '2d/Base/service'
                }else if(type == '2D회색'){
                    path = '2d/gray/service'
                }else if(type == '2D야간'){
                    path = '2d/midnight/service'
                }else if(type == '2D하이브리드'){
                    path = '2d/Hybrid/service'
                }else if(type == '2D영상'){
                    path = '2d/Satellite/service'
                    ext = 'jpeg'
                }
                return 'https://xdworld.vworld.kr/' + path + '/' + z + '/' + x + '/' + y + '.' + ext
            },
            crossOrigin: "Anonymous"
        }))
        var boundryLayer = new VectorLayer({
            source: new VectorSource({
                url: '/geojson/seoul_boundry.geojson',
                format: new GeoJSON(),
                wrapX: false
            }),
            style: function(feature, resolution) {
                return [new Style({
                    stroke : new Stroke({color : '#b9babacc',width : 4})
                })]
            },
            opacity: 0.2
        });
        var seoulLayer = new VectorLayer({
            source: new VectorSource({
                url: '/geojson/seoul.geojson',
                format: new GeoJSON(),
                wrapX: false
            }),
            style: function(feature, resolution) {
                var label = feature.get("NAME");
                var geometry = feature.getGeometry();
                var ps = geometry.getPolygons();
                var idx = 0
                var area = 0
                for(var i in ps){
                    if(ps[i].getArea() > area){
                        area = ps[i].getArea()
                        idx = i
                    }
                }
                var g = geometry.getPolygon(+idx)
                return [new Style({
                    geometry: g,
                    text :  new Text({
                        text: label,
                        overflow: true,
                        fill: new Fill({color: '#000000'}),
                        stroke: new Stroke({
                            color: 'rgba(255,255,255,1)',
                            width: 2
                        }),
                        font: "bold 17px Arial", offsetX: 0,offsetY: 10
                    })
                })]
            },
            declutter: true,
            opacity: 0.6
        });
        proj4.defs('EPSG:5179', '+title=Korea 2000 / Unified CS +proj=tmerc +lat_0=38 +lon_0=127.5 +k=0.9996 +x_0=1000000 +y_0=2000000 +ellps=GRS80 +units=m +no_defs')
        register(proj4)
        get('EPSG:5179')?.setExtent([90112, 1192896, 1990673, 2761664]);
        // @ts-ignore
        window.___dangerLayer = new VectorLayer({
            source: new VectorSource({wrapX: false}),
            style: function(){
                // @ts-ignore
                if(window.gifCtx){
                    return new Style({
                        image: new Icon({
                            // @ts-ignore
                            img: window.gifCtx.canvas,
                            // @ts-ignore
                            imgSize: [window.gifFrame.width, window.gifFrame.height],
                            anchor: [0.5, 1.2],
                            offset: [0,0],
                        })
                    })
                }
            }
        });
        var seoulBackLayer = new VectorLayer({
            source: new VectorSource({
                url: '/geojson/seoul.geojson',
                format: new GeoJSON(),
                wrapX: false
            }),
            style: function(feature, resolution) {
                return [new Style({
                    stroke : new Stroke({color : '#b9baba88',width : 2}),
                    fill : new Fill({color : '#ffffff'})
                })]
            },
            declutter: true
        });
        // @ts-ignore
        window.___overlay = new Overlay({
            // @ts-ignore
            element: document.getElementById("sh-ol-modal-layer"),
            autoPan: false,
            positioning: "bottom-left",
            offset: [5,-10],
            autoPanAnimation: {
                duration: 250
            }
        })
        const map = new Map({
            // @ts-ignore
            target: mapRef.current,
            layers: [
                // @ts-ignore
                seoulBackLayer, seoulLayer, boundryLayer, statisLayer, window.___dangerLayer
            ],
            // @ts-ignore
            overlays: [window.___overlay],
            view: new View({
                center: [0,0],
                maxZoom : 19.4,
                minZoom : 7
            }),
        });
        map.once('postrender', function(e) {
            console.log('postrender 12------')
            var map = e.map
            // @ts-ignore
            window.___dashboard_map = map
            var extent3857            // @ts-ignore
            if(window.___last_map_extent){
                // @ts-ignore
                extent3857 = window.___last_map_extent
            }else{
                var extent = [126.6, 37.4, 127.25, 37.7]
                extent3857 = transformExtent(extent, 'EPSG:4326', 'EPSG:3857')
            }
            // @ts-ignore
            map.getView().fit(extent3857 , map.getSize())
            setTimeout(function(){
                map.updateSize()
            },200)
            load_site(statisLayer)
        })
        map.on('singleclick', function(e) {
            var map = e.map
            var finds: any = []
            map.forEachFeatureAtPixel(e.pixel,function(feature) {
                finds.push(feature)
            },{hitTolerance: 2, layerFilter: function(layer) {
                    // @ts-ignore
                    return layer === statisLayer || layer === window.___dangerLayer;
            }})
            if(finds.length > 0) {
                var feature = finds[0]
                var features = feature.get("features")
                var featureType: string = feature.get("FEATURE_TYPE")
                if(featureType === 'DANGER'){

                }else {
                    var siteIds: string[] = []
                    for (var i in features) {
                        siteIds.push(features[i].get("SITE_ID"))
                    }
                    open_site_list(siteIds)
                }
            }
        })
        map.on('pointermove', function(e) {
            var map = e.map
            var finds: any = []
            map.forEachFeatureAtPixel(e.pixel,function(feature) {
                finds.push(feature)
            },{hitTolerance: 2, layerFilter: function(layer) {
                    // @ts-ignore
                    return layer === window.___dangerLayer;
                }})
            if(finds.length > 0) {
                var feature = finds[0]
                var siteName: string = feature.get("SITE_NAME")
                var point = feature.getGeometry().getCoordinates()
                // @ts-ignore
                $(window.___overlay.element).html("<div class='sh-ol-modal-layer-info'><div class='layer-wrap'>" + siteName + "</div></div>")// @ts-ignore
                // @ts-ignore
                window.___overlay.setPosition(point)
            }else{
                // @ts-ignore
                $(window.___overlay.element).empty();
            }
        })
        map.on("moveend", function(e){
            var extent = e.map.getView().getViewStateAndExtent().extent
            console.log(extent)
            // @ts-ignore
            window.___last_map_extent = extent
        });
        return () => {
            // @ts-ignore
            if(mapRef.current){
                mapRef.current.innerHTML = ''
            }
        }
    }, [mapRef.current]);

    const fn_openSiteInfoListModal = (siteType: string) => {
        restapi.retrieveSiteDataListNoPaging({siteType:siteType}).then((rsltData: any) => {
            setDataList(rsltData);
            setModalIsOpen(true);
        })
    }

    return (
        <>
            <SiteInfoListModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} dataList={dataList}></SiteInfoListModal>
            <div id={"map"}  ref={mapRef} style={{width: '100%', height: '100%'}}></div>
            <div className={"p-3 rounded d-inline-block"} style={{position: "relative", left: "15px", top:"-500px", backgroundColor:"#00000055"}} >
                <div className={"mb-2"} style={{color:"#12E573"}}>
                    <span style={{fontSize:"1em"}}>전체현장</span>
                    <br/>
                    <div style={{textAlign:"right"}}>
                        <span>{countT002001 + countT002002 +countT002003 + countT002005}<small>개</small></span>
                    </div>
                </div>
                <div className={"p-2 mb-2 rounded cursor-pointer"} style={{backgroundColor:"#00000099"}} onClick={() => fn_openSiteInfoListModal('T002001')}>
                    <span style={{fontSize:"1em"}}>택지조성공사</span><br/>{countT002001}<small>개</small>
                </div>
                <div className={"p-2 mb-2 rounded cursor-pointer"} style={{backgroundColor:"#00000099"}} onClick={() => fn_openSiteInfoListModal('T002002')}>
                    <span style={{fontSize:"1em"}}>기반시설공사</span><br/>{countT002002}<small>개</small>
                </div>
                <div className={"p-2 mb-2 rounded cursor-pointer"} style={{backgroundColor:"#00000099"}} onClick={() => fn_openSiteInfoListModal('T002003')}>
                    <span style={{fontSize:"1em"}}>공동주택건축공사</span><br/>{countT002003}<small>개</small>
                </div>
                <div className={"p-2 mb-2 rounded"} style={{backgroundColor:"#00000099"}}>
                    <span style={{fontSize:"1em"}}>소규모공사</span><br/>{countT002004}<small>개</small>
                </div>
                <div className={"p-2 mb-2 rounded cursor-pointer"} style={{backgroundColor:"#00000099"}} onClick={() => fn_openSiteInfoListModal('T002005')}>
                    <span style={{fontSize:"1em"}}>해체공사</span><br/>{countT002005}<small>개</small>
                </div>
            </div>
        </>
    );
}
