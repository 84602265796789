import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {IoMdClose} from "react-icons/io";
import SHSelect from "../../../components/common/SHSelect";
import {Col, Modal, Row, Table} from "react-bootstrap";
import SHTreeView from "../../../components/common/SHTreeView";
import restapi from "../../../helper/restAPIHelper";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import SHSearch from "../../component/SHSearch";
import SHInput from "../../../components/common/SHInput";
import axios from "axios";
import {toast} from "react-toastify";
import DprtDtlStatModal from "./DprtDtlStatModal";
import SHDatePicker2 from "../../../components/common/SHDatePicker2";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {UserContext} from "../../../context/User";
import errorCodeDic from "../../../util/errorCodeDic";
import {ProgressContext} from "../../../context/Progress";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;

    siteId: number;
    wrkDt: string;

}

const WrkrMoveHistListModal = (props: IProps) => {
    const [dataList, setDataList] = useState<any>([])
    const [datatable, setDatatable] = useState<any>();
    const searchFormRef = useRef<any>();
    const [srchData, setSrchData] = useState<any>({siteId:props.siteId, wrkDt:props.wrkDt});
    const defaultUser = getLoginUser();
    const {user} = useContext<any>(UserContext);
    const {spinner} = useContext(ProgressContext);

    useEffect(() => {
        fn_srchData();
    }, [srchData]);

    const fn_afterOpenEvnt = () => {
        setSrchData({siteId:props.siteId, wrkDt:props.wrkDt});
    }


    function fn_closeModal() {
        props.setModalIsOpen(false);
    }
    const fn_setFormData = (e: any) => {
        setSrchData({
            ...srchData,
            [e.target.name]: e.target.value
        })

    }

    const fn_srchData = () => {
        if(srchData.wrkDt){
            spinner.start();
            restapi.retrieveWrkrMoveHistDataList(srchData).then((result) => {
                console.log('result: ', result);
                setDataList(result);
            }).catch(error => {
                toast.error(errorCodeDic['003']);
                console.log('error: ', error);
            })
                .finally(() => {
                    spinner.stop();
                });
        }
    }


    return (
        <div>
            <Modal
                className="sh-modal sub" fullscreen
                show={props.modalIsOpen}
                onShow={fn_afterOpenEvnt}
                onHide={fn_closeModal}
            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        근로자 이동기록 현황
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4 sh-modal-table-w100">
                    <SHSearch datatable={datatable} btnType={'p'} srchFnc={fn_srchData}>
                        <Row>
                            <Col>
                                <Table className="sh-font-SP sh-fs-b2 my-3">
                                    <tbody>
                                    <tr className="border-top align-middle">

                                        <th className="sh-bg-th" style={{width: '10%'}}>업체</th>
                                        <td style={{width: '23%'}}>
                                            <SHSelect initOptionGb={'all'} name={'cmpnySeq'}
                                                      selParam={{siteId: user?.blngSiteId || defaultUser.blngSiteId}}
                                                      codeGb={'siteCmpny'}
                                                      className="sh-form select w-100"
                                                      onChange={fn_setFormData}
                                                      value={srchData?.cmpnySeq}></SHSelect>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '10%'}}>공종</th>
                                        <td style={{width: '23%'}}>
                                            <SHSelect initOptionGb={'all'} name={'wrkTypeCd'}
                                                      codeGb={'siteMngCd'} className={'form-select'}
                                                      onChange={fn_setFormData}
                                                      selParam={{
                                                          siteId: user?.blngSiteId || defaultUser.blngSiteId,
                                                          manageCode: 'M001001'
                                                      }}
                                                      value={srchData?.wrkTypeCd}></SHSelect>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '10%'}}>직종</th>
                                        <td style={{width: '23%'}}>
                                            <SHSelect initOptionGb={'all'} name={'ocptCd'}
                                                      codeGb={'siteMngCd'} className={'form-select'}
                                                      onChange={fn_setFormData}
                                                      selParam={{
                                                          siteId: user?.blngSiteId || defaultUser.blngSiteId,
                                                          manageCode: 'M001002'
                                                      }}
                                                      value={srchData?.ocptCd}></SHSelect>
                                        </td>

                                    </tr>

                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </SHSearch>

                    <div className={'ge-result'}>
                        <Datatables
                            parameterFormRef={searchFormRef}
                            scrollY="550px"
                            className="sh-fs-b4"
                            columns={[
                                {data: "authGb", title: "구분", textAlign: 'center'},
                                {data: "userName", title: "이름", textAlign: 'center'},
                                {data: "siteWrkTpNm", title: "공종", textAlign: 'center'},
                                {data: "siteOcptNm", title: "직종", textAlign: 'center'},
                                {data: "moveStat", title: "내용", textAlign: 'left'},
                            ]}
                            checked={false}
                            index={true}
                            onLoad={(dt: any) => {
                                setDatatable(dt);
                            }}
                            isNotPaging={true}
                            isNotOrdering={true}
                            datas={dataList}
                            onClick={(data) => {

                            }}
                        />
                    </div>

                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default WrkrMoveHistListModal;
