import { ListItemButton, ListItemIcon } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import colorConfigs from "../configs/colorConfigs";
import { RootState } from "../../redux/store";
import { RouteType } from "../../routes/config";

type Props = {
  item: RouteType;
};

const SidebarItem = ({ item }: Props) => {
  const { appState } = useSelector((state: RootState) => state.appState);

  return (
    item.sidebarProps && item.path ? (
      <ListItemButton className={appState === item.state ? "sh-font-SD sh-left-menu active" : "sh-font-SD sh-left-menu "}
        component={Link}
        to={item.path}
        sx={{
          paddingY: "12px",
          paddingX: "24px"
        }}
      >
        <ListItemIcon
            className={appState === item.state ? "sh-left-menu-icon active" : "sh-left-menu-icon "}
            sx={{width: 10}}
        >
          {item.sidebarProps.icon && item.sidebarProps.icon}
        </ListItemIcon>
        {item.sidebarProps.displayText}
      </ListItemButton>
    ) : null
  );
};

export default SidebarItem;
