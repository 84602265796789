import React, {useContext, useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {ReactComponent as IconStop} from "../../images/icon_prohibit.svg";
import {UserContext} from "../../context/User";
import {getLoginUser} from "../../helper/SecurityHelper";
import restapi from "../../helper/restAPIHelper";
import {CmmnFn} from "../../helper/CmmnHelper";
import WrkStopInfoListModal from "../headoffice/modal/WrkStopInfoListModal";

const SSDashStopWork = () => {
    const {user} = useContext<any>(UserContext);
    const defaultUser = getLoginUser();
    const [dataList, setDataList] = useState<any>([]);
    const [wrkStopModalIsOpen, setWrkStopModalIsOpen] = useState<boolean>(false);

    useEffect(() => {
        const siteId = user?.blngSiteId || defaultUser.blngSiteId;
        restapi.retrieveWrkDisruptHisTotDataList({siteId: siteId, rgstDt: CmmnFn.fn_getCurrentDate()}).then((rsltData: any) => {
            console.log('stop rsltData: ', rsltData);
            setDataList(rsltData);
        })

    }, [user])

    const fn_openWrkStopInfoListModal = () => {
        setWrkStopModalIsOpen(true);
    }

    return(
        <>
            <WrkStopInfoListModal setModalIsOpen={setWrkStopModalIsOpen} modalIsOpen={wrkStopModalIsOpen} dataList={dataList} />
            <Container fluid className="sh-bg-pane rounded-[5px] py-2 px-3 h-100">
                <Row>
                    <Col>
                        <div onClick={fn_openWrkStopInfoListModal} className="cursor-pointer d-flex flex-column justify-content-between">
                            <div>
                                <div className="pe-2 sh-fs-h4 sh-fw-5 sh-font-SD mb-3">금일 작업중지 요청</div>
                                <div className="d-flex rounded-3 justify-content-center" style={{backgroundColor: '#FF5656', width: '4rem', height: '4rem'}}>
                                    <IconStop style={{width: '3rem'}}/>
                                </div>
                            </div>
                            <div className="text-end sh-fs-h4 sh-fw-5">
                                <span className="sh-fs-h1 me-1">{dataList.length}</span>건
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default SSDashStopWork;
