import React, {useContext, useEffect, useState} from 'react';
import {Col, Container, Row, Table, ProgressBar} from "react-bootstrap";
import {ReactComponent as IconRight} from "../../assets/images/ico/icon-right.svg";
import {ReactComponent as IconNoData} from "../../assets/images/ico/icon-folder.svg";
import {useNavigate} from "react-router-dom";
import {UserContext} from "../../context/User";
import {getLoginUser} from "../../helper/SecurityHelper";
import restapi from "../../helper/restAPIHelper";
import {CmmnFn} from "../../helper/CmmnHelper";
import SmrtIotListModal from "./modal/SmrtIotListModal";

const DashSmartIotStatus = () => {
    const {user} = useContext<any>(UserContext);
    const defaultUser = getLoginUser();
    const [dataList, setDataList] = useState<any>([]);
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [sensorCd, setSensorCd] = useState<any>();

    const navigate = useNavigate();
    const fn_openSmrtIotInfoLIstModal = (sensorCd: number) => {
        setSensorCd(sensorCd);
        setModalIsOpen(true);
    }

    useEffect(() => {
        const siteId = user?.blngSiteId || defaultUser.blngSiteId;
        restapi.retrieveSensorStatGroupBySenSorType({siteId:siteId}).then((rsltData: any) => {
            setDataList(rsltData)
            console.log('iot rsltData: ', rsltData);
        })

    }, [user])
    return(
        <>
            <Container fluid className="sh-bg-pane rounded-[5px] py-2 px-3">
                <SmrtIotListModal setModalIsOpen={setModalIsOpen} sensorCd={sensorCd} modalIsOpen={modalIsOpen} dataList={dataList}></SmrtIotListModal>
                <Row className="px-0">
                    <Col bsPrefix="col-7" className="d-flex align-items-center w-100">
                        <div className="w-100 d-flex align-items-center justify-content-between">
                            <span className="pe-2 sh-fs-h4 sh-fw-5 sh-font-SD">스마트 IOT 현황</span>
                            <div className={'cursor-pointer'} onClick={() => navigate('/sensor/smrtIot')}>
                              <IconRight style={{width:'20px',marginBottom:'1px'}} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table className="my-2 sh-text-white sh-fs-b3" borderless={true}>
                            <thead className="text-center">
                            <tr>
                                <th className="sh-fw-5 py-1 bg-black bg-opacity-50"
                                    style={{borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px'}}>장비명
                                </th>
                                <th className="sh-fw-5 py-1 bg-black bg-opacity-50">개수</th>
                                <th className="sh-fw-5 py-1 bg-black bg-opacity-50">현황</th>
                            </tr>
                            </thead>
                            <tbody className="sh-fw-4">
                                {dataList.length > 0 ?
                                    (
                                        dataList.map((data: any, idx: number) => (
                                            idx < 4 &&
                                            <tr className={'cursor-pointer'}
                                                onClick={() => fn_openSmrtIotInfoLIstModal(data.sensorCd)} key={idx}
                                                style={{borderBottom: 'dotted 1px rgba(255,255,255,0.2)'}}>
                                                <td className="text-center">{data.sensorName}</td>
                                                <td className="text-center">{data.sensorCnt}대</td>
                                                <td className="text-center justify-content-center d-flex">
                                                    <div style={{backgroundColor: (data.valueX >= 5.0 || data.valueY >= 5.0 || data.valueZ >= 5.0) ? '#FF0000' : '#00CB2D' , borderRadius: 100, width: 20, height:20}}></div>
                                                </td>
                                            </tr>
                                        ))

                                    ) : (
                                        <tr className={'h-[138px]'} style={{borderBottom: 'dotted 1px rgba(255,255,255,0.2)'}}>
                                            <td className="text-center border-bottom-0 fs-6" colSpan={3}><div className="d-flex flex-column justify-content-center align-items-center" style={{height: 'calc(100% - 20px)'}}>
                                                <IconNoData style={{width: '40px', height: '40px', marginBottom: '10px'}} />
                                                <div className="bmc-txt-color-gray-01 bmc-txt-size-b6">조회된 데이터가 없습니다.</div>
                                            </div></td>
                                        </tr>
                                    )
                                }
                                {
                                    (dataList.length > 0 && dataList.length < 4) && CmmnFn.fillEmpty(dataList.length)

                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default DashSmartIotStatus;
