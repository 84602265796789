import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import axios from "axios";
import {toast} from "react-toastify";
import {getLoginUser} from "../../../helper/SecurityHelper";
import SHSelect from "../../../components/common/SHSelect";
import SHInput from "../../../components/common/SHInput";
import restapi from "../../../helper/restAPIHelper";
import errorCodeDic from "../../../util/errorCodeDic";
import {ProgressContext} from "../../../context/Progress";
import messageCodeDic from "../../../util/messageCodeDic";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
    siteId: number;
}

const NfcQrRgstModal = (props: IProps) => {

    const user = getLoginUser();

    const [data, setData] = useState<any>({});
    const {spinner} = useContext(ProgressContext);


    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_save = () => {

        if(!data.qrNfcTpCd){
            toast.warn('타입을 선택해 주십시오');
            return;
        }

        if(data.qrNfcTpCd == 'T003002' && !data.wrkPlcCd){
            toast.warn('작업구역을 선택해 주십시오');
            return;
        }

        if(data.qrNfcTpCd == 'T003003' && !data.eduTpCd){
            toast.warn('교육구분을 선택해 주십시오');
            return;
        }

        if(['T003001','T003002'].includes(data.qrNfcTpCd) && !data.inOutGb){
            toast.warn('IN/OUT 을 선택해 주십시오');
            return;
        }

        if(!data.nfc){
            toast.warn('NFC를 입력해 주십시오');
            return;
        }

        if(!window.confirm('저장 하시겠습니까?')){
            return;
        }
        let param = data;
        param.rgstrSeq = user.userSeq;
        param.updtrSeq = user.userSeq;
        param.siteId = props.siteId;

        spinner.start();
        restapi.saveQrNfcData(data).then((rsltData) => {
            if (rsltData > 0) {
                toast.success(messageCodeDic['001'])
                props.setModalIsOpen(false);
            } else {
                if(rsltData == -13){
                    toast.warn('NFC 키가 중복 됩니다.')
                }else{
                    toast.error(errorCodeDic['003']);
                }
                return;
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
        .finally(() => {
            spinner.stop();
        });

    }



    const fn_onAfterShow = () => {

        if(!props.detailData) {
            setData({});
        }else{
            setData(props.detailData)
        }
    }


    const fn_setFormData = (e: any) => {

        setData({
            ...data,
            [e.target.name]: e.target.value
        })

    }

    const fn_onQrNfcTpChange = (qrNfcTpCd: string) => {
        setData({qrNfcTpCd:qrNfcTpCd});
    }

    return (
        <div>
            <Modal
                className="sh-modal  sub" size="lg"
                show={props.modalIsOpen}
                onShow={fn_onAfterShow}
                onHide={fn_closeModal}
            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        NFC/QR 등록
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>


                <Modal.Body className="px-4">
                    <form name={'form'}>
                        <div className="sh-modal-div">
                            <div className="div-header">
                                NFC/QR 설정
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>타입</th>
                                        <td style={{width: '35%'}}>
                                            <SHSelect className={"form-select"} initOptionGb={'sel'}
                                                      name={'qrNfcTpCd'} codeGb={'cmmn'} codeGrpId={'T003'}
                                                      onChange={(e) => {
                                                          fn_onQrNfcTpChange(e.target.value);
                                                      }}
                                                      value={data?.qrNfcTpCd}/>
                                        </td>
                                    </tr>
                                    {data.qrNfcTpCd == 'T003002' &&
                                        <tr>
                                            <th className="sh-bg-th" style={{width: '6%'}}>작업구역</th>
                                            <td style={{width: '30%'}}>
                                                <SHSelect initOptionGb={'sel'} name={'wrkPlcCd'}
                                                          className={"form-select"}
                                                          selParam={{siteId: props.siteId, manageCode: 'M001004'}}
                                                          codeGb={'siteMngCd'}
                                                          onChange={fn_setFormData}
                                                          value={data?.wrkPlcCd}></SHSelect>
                                            </td>
                                        </tr>
                                    }
                                    {data.qrNfcTpCd == 'T003003'
                                        && <tr>
                                            <th className="sh-bg-th" style={{width: '6%'}}>교육 구분</th>
                                            <td style={{width: '30%'}}>
                                                <SHSelect className={"form-select"} initOptionGb={'sel'}
                                                          name={'eduTpCd'} codeGb={'cmmn'} codeGrpId={'E001'}
                                                          onChange={(e) => {
                                                              fn_setFormData(e);
                                                          }}
                                                          value={data?.eduTpCd}/>
                                            </td>
                                        </tr>
                                    }

                                    {['T003001','T003002'].includes(data.qrNfcTpCd) &&
                                        <tr>
                                            <th className="sh-bg-th" style={{width: '6%'}}>IN/OUT</th>
                                            <td style={{width: '30%'}}>
                                                <SHSelect className={"form-select"} initOptionGb={'sel'}
                                                          name={'inOutGb'} codeGb={'cmmn'} codeGrpId={'T004'}
                                                          onChange={(e) => {
                                                              fn_setFormData(e);
                                                          }}
                                                          value={data?.inOutGb}/>
                                            </td>
                                        </tr>
                                    }

                                    <tr>
                                        <th className="sh-bg-th" style={{width: '6%'}}>NFC</th>
                                        <td style={{width: '30%'}}>
                                            <SHInput name={'nfc'} className={'form-control'} value={data?.nfc} onChange={fn_setFormData}></SHInput>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </form>
                </Modal.Body>

                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <button onClick={fn_save}
                                className='sh-btn pill p'>저장
                        </button>

                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default NfcQrRgstModal;
