import React, {useEffect, useRef, useState} from 'react';
import Datatables, {getCheckedItems} from "../component/Datatables";
import {Button, Container, ListGroup, Overlay, Table} from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";
import SHIconButton from "../../components/common/SHIconButton";
import SHSearchButton from "../../components/common/SHSearchButton";
import SHDatePicker2 from "../../components/common/SHDatePicker2";
import {DateHelper} from "../../helper/DateHelper";
import SafetyManagementBoardModal from "./modal/SafetyManagementBoardModal";
import {toast} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import axios from "axios";
import errorCodeDic from "../../util/errorCodeDic";
import SHSearch from "../component/SHSearch";
import {getLoginUser} from "../../helper/SecurityHelper";

type Props = {};

const SafetyManagementBoardPage = (props: Props) => {
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState(null);
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const srchTxtRef = useRef<any>();
    const [data, setData] = useState<any>();
    const prodRef = useRef<any>();
    const [startDate, setStartDate] = useState<any>(DateHelper.dateByMonth(-3));
    const [endDate, setEndDate] = useState<any>(DateHelper.dateByMonth(0));
    const user = getLoginUser();
    const [show, setShow] = useState(false);
    const target = useRef(null);

    useEffect(() => {
        datatable?.draw();
    }, [modalIsOpen])

    const fn_setFormData = (e: any) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const fn_registPopOpen = () => {
        setDetailData(null);
        setModalIsOpen(true);
    }

    const fn_search = () => {
        datatable.draw();
    }

    const fn_delete = ()=>{
        let checkedData = getCheckedItems(datatable, "sftyMngBrdId")

        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }

        if (!window.confirm(messageCodeDic['004'])) {
            return;
        }
        console.log("delList : " , checkedData)
        axios.post('/admin/api/sftyMngBrd/deleteSftyMngBrdData', {delList: checkedData}).then(rsltData => {
                if (rsltData.data > 0) {
                    toast.success(messageCodeDic['002'])
                    datatable?.draw();
                } else {
                    toast.error(errorCodeDic['003'])
                }
            }
        ).catch(() => {
                toast.error(errorCodeDic['003'])
            }
        )
    }

    const fn_updateViewCnt = (param: object) => {
        axios.post('/admin/api/sftyMngBrd/updateViewCnt', param);
    }

    const fn_onIsAllProdChg = (e:any) => {
        if(e.target.checked){
            setStartDate(null);
            setEndDate(null);
        }else{
            setStartDate(DateHelper.dateByMonth(-3));
            setEndDate(DateHelper.dateByMonth(0));
        }
    }

    return (
        <Container fluid className="bg-white px-4 pt-4 sh-text-font_primary">

            <SubMenuTitle title={'안전교육컨텐츠'} parentMenu={'안전 정보 및 공지 관리'}/>
            <SafetyManagementBoardModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} detailData={detailData} />
            <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                <Table className="sh-font-SP sh-fs-b2 my-3">
                    <tbody>
                    <tr className="border-top border-bottom align-middle">
                        <th className="sh-bg-th" style={{width:'15%'}}>기간</th>
                        <td style={{width:'35%'}}>
                            <div className="d-flex flex-row gap-2">
                                <div className="d-flex align-items-center" ref={prodRef}>
                                    <SHDatePicker2 setter={setStartDate} name={'startRgstDt'} defaultValue={startDate} onChange={fn_setFormData} />
                                    <span>&nbsp;~&nbsp;</span>
                                    <SHDatePicker2 setter={setEndDate} name={'endRgstDt'} defaultValue={endDate} onChange={fn_setFormData} />
                                </div>
                                <div className="d-flex align-items-center gap-1" ref={prodRef}>
                                    <input className="form-check-input sh-check" id={'isAll'} name={'isAll'} type="checkbox" onChange={(e: any) => {fn_setFormData(e); fn_onIsAllProdChg(e);}}/>
                                    <label className={'form-check-label text-black'} htmlFor={'isAll'}>전체기간</label>
                                </div>
                            </div>
                        </td>
                        <th className="sh-bg-th" style={{width:'15%'}}>검색어</th>
                        <td style={{width:'35%'}}>
                            <input type={'text'} name={'srchTxt'} className='form-control' ref={srchTxtRef}/>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </SHSearch>

            <div className='w-100 d-flex justify-content-between align-items-center mb-2'>
                <div>
                    <Overlay target={target.current} show={show} placement="right">
                        {({
                              placement: _placement,
                              arrowProps: _arrowProps,
                              show: _show,
                              popper: _popper,
                              hasDoneInitialMeasure: _hasDoneInitialMeasure,
                              ...props
                          }) => (
                            <div
                                {...props}
                                style={{
                                    position: 'absolute',
                                    backgroundColor: 'white',
                                    color: 'white',
                                    ...props.style,
                                }}
                            >
                                <ListGroup defaultActiveKey="#link1">
                                    <ListGroup.Item action href="https://www.law.go.kr/법령/중대재해처벌법" target="_blank" onClick={()=>setShow(false)}>
                                        중대재해처벌법
                                    </ListGroup.Item>
                                    <ListGroup.Item action href="https://www.law.go.kr/법령/산업안전보건법" target="_blank" onClick={()=>setShow(false)}>
                                        산업안전보건법
                                    </ListGroup.Item>
                                </ListGroup>
                            </div>
                        )}
                    </Overlay>
                </div>
                {user.authCode != 'headRead' &&
                <div className='d-flex justify-content-end'>

                    <SHIconButton kind={'submit'} marginRight={'5px'} onClick={fn_registPopOpen}>등록</SHIconButton>

                    <SHIconButton kind={'delete'} marginRight={'0'} onClick={fn_delete}>삭제</SHIconButton>
                </div>
                }
            </div>

            <div style={{ color:'red', padding:5 }}>*300mb 이하 용량의 첨부파일만 업로드 가능합니다. (영상의 경우, 파일을 압축해서 업로드해주시기 바랍니다. )</div>

            <div className={'ge-result'}>
                <Datatables
                    dataUrl={'/admin/api/sftyMngBrd/retrieveSftyMngBrdDataList'}
                    parameterFormRef={searchFormRef}
                    columns={[
                        {data: "title", title: "제목", textAlign: 'center'},
                        {data: "userName", title: "작성자", textAlign: 'center'},
                        {data: "rgstDt", title: "작성일시", textAlign: 'center'},
                        {data: "viewCnt", title: "조회수", textAlign: 'center'},
                    ]}
                    fixedTop={"isFixedTop"}
                    checked={true}
                    onLoad={(dt: any) => {
                        setDatatable(dt)
                    }}
                    onClick={(data) => {
                        setDetailData(data)
                        setModalIsOpen(true);
                        fn_updateViewCnt(data);
                    }}
                />
            </div>
        </Container>
    );
};

export default SafetyManagementBoardPage;
