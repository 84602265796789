import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from "axios";
import Datatables, {getCheckedItems} from "../component/Datatables";
import Modal from 'react-modal';

import {MultiSelect} from '@grapecity/wijmo.react.input';
import {getLoginUser} from "../../helper/SecurityHelper";
import {toast} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import SHSearch from '../component/SHSearch';
import {Col, Container, Row, Table} from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";
import SHIconButton from "../../components/common/SHIconButton";
import SHInput from "../../components/common/SHInput";
import {UserContext} from "../../context/User";
import SiteDocsRgstModal from "./modal/SiteDocsRgstModal";
import SHSelect from "../../components/common/SHSelect";


type Props = {};


Modal.setAppElement('#root');
const VideoMngPage = (props: Props) => {
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const defaultUser = getLoginUser();
    const srchTxtRef = useRef<any>();
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const {user} = useContext<any>(UserContext);
    const [dtlData, setDtlData] = useState<any>({});
    const [data, setData] = useState<any>({})

    useEffect(()=>{

        if(datatable){
            datatable.draw();
        }

    }, [user, datatable])

    useEffect(() => {

        datatable?.draw();

    }, [modalIsOpen])


    const fn_delete = () => {
        let checkedData = getCheckedItems(datatable, "docsId")

        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }

        if (!window.confirm(messageCodeDic['004'])) {
            return;
        }

        axios.post('/admin/api/siteDocs/deleteSiteDocsData', {delList: checkedData}).then(rsltData => {
                if (rsltData.data > 0) {
                    toast.success(messageCodeDic['002'])
                    datatable?.draw();
                } else {
                    toast.error(errorCodeDic['003'])
                }

            }
        ).catch(() => {
                toast.error(errorCodeDic['003'])
            }
        )
    }



    const fn_registPopOpen = () => {
        setDtlData({});
        setModalIsOpen(true);
    }

    const fn_setFormData = (e: any) => {

        setData({
            ...data,
            [e.target.name]: e.target.value
        })

    }



    return (
        <Container fluid className="bg-white px-4 pt-4 sh-text-font_primary">
            <SiteDocsRgstModal docTpCd={'T005002'} siteId={user?.blngSiteId || defaultUser.blngSiteId} setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} dtlData={dtlData}></SiteDocsRgstModal>
            <SubMenuTitle title={'동영상 기록관리'} parentMenu={'안전업무보고서 관리'}/>

            <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                <SHInput type={'hidden'} name={'siteId'} value={user?.blngSiteId || defaultUser.blngSiteId}></SHInput>
                <SHInput type={'hidden'} name={'docTpCd'} value={'T005002'}></SHInput>
                <Table className="sh-font-SP sh-fs-b2 my-3">
                    <tbody>
                    <tr className="border-top align-middle border-bottom">
                        <th className="sh-bg-th">항목</th>
                        <td style={{width: '25%'}}>
                            <SHSelect initOptionGb={'all'} className={'sh-select'} codeGb={'cmmn'}
                                      codeGrpId={'V001'}
                                      name={'videoHistTpCd'} onChange={fn_setFormData}
                                      value={data?.videoHistTpCd}></SHSelect>
                        </td>
                        <th className="sh-bg-th">공종</th>
                        <td style={{width: '25%'}}>
                            <SHSelect initOptionGb={'all'} className={'sh-select'} codeGb={'cmmn'}
                                      codeGrpId={'W002'}
                                      name={'workTpCd'} onChange={fn_setFormData}
                                      value={data?.workTpCd}></SHSelect>
                        </td>
                        <th className="sh-bg-th">검색어</th>
                        <td style={{width: '50%'}}>
                            <input type={'text'} name={'srchTxt'} className='form-control' onChange={fn_setFormData} value={data?.srchTxt}/>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </SHSearch>


            <div className='w-100 d-flex justify-content-end align-items-center mb-2'>

                <SHIconButton kind={'submit'} marginRight={'5px'} onClick={fn_registPopOpen}>등록</SHIconButton>

                <SHIconButton kind={'delete'} marginRight={'0'} onClick={fn_delete}>삭제</SHIconButton>
            </div>

            <div className={'ge-result'}>
                <Datatables
                    dataUrl={'/admin/api/siteDocs/retrieveSiteDocsDataList'}
                    parameterFormRef={searchFormRef}
                    columns={[
                        {data: "videoHistTpNm", title: "항목"},
                        {data: "workTpNm", title: "공종"},
                        {data: "fileNm", title: "파일명"},
                        {data: "rgstrNm", title: "작성자"},
                        {data: "rgstDt", title: "등록일"},
                    ]}
                    checked={true}
                    index={true}

                    onLoad={(dt: any) => {
                        setDatatable(dt)
                    }}
                    onClick={(data) => {
                        setDtlData(data)
                        setModalIsOpen(true);
                    }
                    }
                />
            </div>
        </Container>


    );
};

export default VideoMngPage;
