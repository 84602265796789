import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import iconCalendar from "../../../images/icon-calendar-black.svg";
import CustomDatePickerRange from "../../component/ui/DatePickerRange";
import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {fn_getCurrentDate} from "../../../helper/CmmnHelper";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {Col, Row} from "react-bootstrap";
import {Container} from "@mui/material";
import {COMMON_MODAL_STYLE, COMMON_MULTISEL_STRINGS} from "../../../helper/StyleHelper";
import Select from 'react-select'
import {MultiSelect} from "react-multi-select-component";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import SHInput from "../../../components/common/SHInput";
import SHSelect from "../../../components/common/SHSelect";
import DriverSrchModal from "./DriverSrchModal";
import restapi from "../../../helper/restAPIHelper";
import {ProgressContext} from "../../../context/Progress";
import SHDatePicker2 from "../../../components/common/SHDatePicker2";
import {DateHelper} from "../../../helper/DateHelper";
import SHIconButton from "../../../components/common/SHIconButton";
import ApprCardList from "../../../components/appr/ApprCardList";
import ApprLineModal from "../../siteSafetyManagement/modal/ApprLineModal";

const $ = require("jquery")
interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
    siteId: number;
    isReadOnly?: boolean;
}

const EqmntRgstModal = (props: IProps) => {

    const inspctProdRef = useRef<any>();
    const isrncProdRef = useRef<any>();
    const currentDate = fn_getCurrentDate();
    const user = getLoginUser();

    const [eqmntIspctStrtDt, setEqmntIspctStrtDt] = useState<Date | null>(null);
    const [eqmntIspctEndDt, setEqmntIspctEndDt] = useState<Date | null>(null);
    const [eqmntIsrncStrtDt, setEqmntIsrncStrtDt] = useState<Date | null>(null);
    const [eqmntIsrncEndDt, setEqmntIsrncEndDt] = useState<Date | null>(null);
    const [data, setData] = useState<any>()
    const [isDriverPopOpend, setIsDriverPopOpend] = React.useState<boolean>(false);
    const [driverData, setDriverData] = useState<any>({});
    const [isMainDriver, setIsMainDriver] = useState<boolean>(false);
    const [selected, setSelected] = useState<any>([])
    const [eqmntChkList, setEqmntChkList] = useState<any>([]);
    const {spinner} = useContext(ProgressContext);
    const [isApprLineModalOpen, setIsApprLineModalOpen] = useState(false);
    const [apprLineDataList, setApprLineDataList] = useState<any>([]);
    const [refUserList, setRefUserList] = useState<any>([]);
    const [refSelected, setRefSelected] = useState<any>([])
    const [mngrUserDataList, setMngrUserDataList] = useState<any>([]);
    const [allUserDataList, setAllUserDataList] = useState<any>([]);


    const fn_setFormData = (e: any) => {

        console.log(data);
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    useEffect(() => {

        let apprLinUserSeqList: number[] = [];
        apprLineDataList.map((data: any) => {
            apprLinUserSeqList.push(data.userSeq);
        });

        let cstmAllUserDataList = allUserDataList.filter((data: any) => (!apprLinUserSeqList.includes(data.userSeq)));
        let cstmData: object[] = [];

        cstmAllUserDataList = cstmAllUserDataList.filter((data: any) => (data.userSeq != props.detailData?.rgstrSeq))
        cstmAllUserDataList.map((data: any) => {
            cstmData.push({
                label: '[' + data.companyName + '] ' + data.userName + ' (' + data.chrgNm + ')',
                value: data.userSeq
            });
        })

        // @ts-ignore
        setRefUserList([].concat(cstmData));

    }, [apprLineDataList]);

    useEffect(() => {
        let selSeq: number[] = [];
        refSelected.map((data: any) => {
            selSeq.push(data.value);
        })

        let cstmAllUserDataList: any = allUserDataList.filter((data: any) => {
            return !selSeq.includes(data.userSeq);
        })

        setMngrUserDataList([].concat(cstmAllUserDataList));
        let showText = ''

        if (refSelected.length > 0 && refSelected.length == refUserList.length) {
            refSelected.map((data: any, idx: number) => {
                showText += data.label
                if (idx < refSelected.length - 1) {
                    showText += ', '
                }

            })

            $('#eqmntRgstModal_rgstForm').find('.dropdown-heading-value').find('span').html(showText);
        }

    }, [refSelected, refUserList])

    useEffect(() => {
        if (driverData) {
            console.log('driverData:', driverData)
            if (isMainDriver) {
                setData({
                    ...data,
                    driverNm: driverData.userName,
                    driverSeq: driverData.userSeq,
                    driverPhone: driverData.userPhone,
                    driverLcncNo: driverData.driverLcncNo
                })
            } else {
                setData({
                    ...data,
                    rotDriverSeq: driverData.userSeq,
                    rotDriverNm: driverData.userName
                })
            }


        }
    }, [driverData])

    function afterOpenModal() {
        // 신규
        if (!props.detailData) {
            console.log('siteId: ', props.siteId);
            setData({siteId: props.siteId})
            setEqmntIspctStrtDt(null);
            setEqmntIspctEndDt(null);
            setEqmntIsrncStrtDt(null);
            setEqmntIsrncEndDt(null);
            setEqmntChkList([]);
            setSelected([]);
            setApprLineDataList([]);
            setRefSelected([]);

            // 상세
        } else {
            setData(props.detailData);
            console.log('props.detailData:', props.detailData);
            setEqmntIspctStrtDt( DateHelper.parse(props.detailData.eqmntIspctStrtDt));
            setEqmntIspctEndDt(DateHelper.parse(props.detailData.eqmntIspctEndDt));
            setEqmntIsrncStrtDt(DateHelper.parse(props.detailData.eqmntIsrncStrtDt));
            setEqmntIsrncEndDt(DateHelper.parse(props.detailData.eqmntIsrncEndDt));
            fn_retrieveChkDataList(props.detailData.eqmntCd);

            fn_retrieveApprUserDataList(props.detailData);


            axios.post('/admin/api/eqmnt/retrieveEqmntChkData', {eqmntId: props.detailData.eqmntId}).then((result) => {

                let dataList: any = [];
                result.data.map((item: any) => {
                    dataList.push({label:item.chkCntnts, value: item.chkSeq})
                })
                setSelected(dataList);

                console.log('selRslt: ', result);
            })
        }
    }

    const fn_retrieveApprUserDataList = (detailData: any) => {

        restapi.retrieveEqmntApprInfo({eqmntId: detailData.eqmntId}).then((apprData) => {
            const apprUserDataList = apprData.apprUserDataList;
            const refUserDataList = apprData.refUserDataList;
            let cstmData: object[] = [];

            restapi.retrieveUserDataList({
                cmpnySeq: detailData.cmpnySeq,
                isApprMngr: true,
                siteId: props.siteId
            }).then((rsltData) => {
                setAllUserDataList(rsltData);
                if (apprUserDataList.length == 0 && refUserDataList.length == 0) {
                    setMngrUserDataList(rsltData);
                    rsltData = rsltData.filter((data: any) => (data.userSeq != detailData.rgstrSeq))
                    rsltData.map((data: any) => {
                        cstmData.push({
                            label: '[' + data.companyName + '] ' + data.userName + ' (' + data.chrgNm + ')',
                            value: data.userSeq
                        });
                    })
                    setRefUserList(cstmData);
                    setApprLineDataList([]);
                    setRefSelected([]);
                } else {
                    let refUserSeqList: any = [];
                    let apprUserSeqList: any = [];
                    refUserDataList.map((data: any) => {
                        refUserSeqList.push(data.userSeq)
                    })
                    apprUserDataList.map((data: any) => {
                        apprUserSeqList.push(data.userSeq)
                    })

                    setMngrUserDataList(rsltData.filter((data: any) => {
                        return !refUserSeqList.includes(data.userSeq);
                    }));

                    let cstmApprLineDataList = rsltData.filter((data: any) => {
                        return apprUserSeqList.includes(data.userSeq);
                    })

                    cstmApprLineDataList.map((cstmData: any) => {
                        apprUserDataList.map((apprData: any) => {
                            if (cstmData.userSeq == apprData.userSeq) {
                                cstmData.apprStat = apprData.apprStat;
                                cstmData.apprStatNm = apprData.apprStatNm;
                                cstmData.prcsDt = apprData.prcsDt;
                                cstmData.apprSn = apprData.apprSn;
                            }
                        })
                    })

                    // 오름차순 정렬
                    cstmApprLineDataList = cstmApprLineDataList.sort((a: any, b: any) => {
                        if (a.apprSn > b.apprSn) return 1;
                        if (a.apprSn < b.apprSn) return -1;
                        return 0;
                    });

                    setApprLineDataList(cstmApprLineDataList)

                    let csmtSelectData = rsltData.filter((data: any) => {
                        return refUserSeqList.includes(data.userSeq);
                    })


                    csmtSelectData.map((data: any) => {
                        data.label = '[' + data.companyName + '] ' + data.userName + ' (' + data.chrgNm + ')';
                        data.value = data.userSeq;
                    })


                    rsltData = rsltData.filter((data: any) => {
                        return !apprUserSeqList.includes(data.userSeq);
                    })

                    rsltData.map((data: any) => {
                        cstmData.push({
                            label: '[' + data.companyName + '] ' + data.userName + ' (' + data.chrgNm + ')',
                            value: data.userSeq
                        });
                    })

                    setRefUserList(cstmData);
                    setRefSelected(csmtSelectData);

                }

            })

        })


    }

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const submit = (e: any) => {
        e.preventDefault()

    }

    const fn_registData = () => {

        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if (!data.cmpnySeq) {
            toast.warn('업체를 선택해 주십시오.')
            return;
        }

        if (!data.eqmntCd) {
            toast.warn('장비명을 선택해 주십시오.');
            return;
        }

        if (!data.rntCmpnyNm) {
            toast.warn('임대업체명을 입력해 주십시오.');
            return;
        }

        if (!data.eqmntRgstNo) {
            toast.warn('장비 등록번호를 입력해 주십시오.');
            return;
        }

        if(!data.driverSeq) {
            toast.warn('운전원을 선택해 주십시오.');
            return;
        }

        if(!data.rotDriverSeq){
            toast.warn('장비교대운전원을 선택해 주십시오.');
            return;
        }

        if(selected.length == 0){
            toast.warn('사용 전 장비점검 질문을 선택해 주십시오.');
            return;
        }



        let param = data;
        param.rgstrSeq = user.userSeq;
        param.updtrSeq = user.userSeq;
        param.eqmntIspctStrtDt = DateHelper.format(eqmntIspctStrtDt);
        param.eqmntIspctEndDt = DateHelper.format(eqmntIspctEndDt);
        param.eqmntIsrncStrtDt = DateHelper.format(eqmntIsrncStrtDt)
        param.eqmntIsrncEndDt = DateHelper.format(eqmntIsrncEndDt)
        param.eqmntWght = param.eqmntWght;

        let chkSeqList:number[] = [];

        selected.map((data: any) => {
            chkSeqList.push(data.value);
        })
        param.chkSeqList = chkSeqList;

        if(!window.confirm('저장 하시겠습니까?')){
            return;
        }

        spinner.start();
        axios.post('/admin/api/eqmnt/saveEqmntData', param).then((result) => {

            if (result.data > 0) {
                toast.success(messageCodeDic['001'])
                props.setModalIsOpen(false);
            } else {
                toast.error(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
        .finally(() => {
            spinner.stop();
        });

    }

    const fn_openDriverSrchPop = (flag: boolean) => {

        if (!data.cmpnySeq) {
            toast.warn('업체를 먼저 선택해 주십시오.');
            return;
        }
        setIsMainDriver(flag);
        setIsDriverPopOpend(true);
    }

    const fn_retrieveChkDataList = (eqmntCd:number) => {

        restapi.retrieveEqmntChkList({siteId:props.siteId, eqmntCd:eqmntCd}).then((rsltData) => {
            const rsltEqmntChkList: object[] = []
            rsltData.map((data: any) => {
                rsltEqmntChkList.push({label: data.chkCntnts, value:data.chkSeq})
            })

            setEqmntChkList(rsltEqmntChkList);
        })

    }

    const fn_rqstAppr = () => {
        if (apprLineDataList.length == 0) {
            toast.warn('결재선을 선택해 주십시오.');
            return;
        }

        let apprUserSeqList: number[] = [];
        apprLineDataList.map((data: any, idx: number) => {
            apprUserSeqList.push(data.userSeq);
        })

        let refUserSeqList: any = [];
        refSelected.map((data: any) => {
            refUserSeqList.push(data.userSeq || data.value)
        })

        if (!window.confirm('결재요청 하시겠습니까?')) {
            return;
        }

        let prcsStatCd = '';

        if(apprUserSeqList.length == 1){
            prcsStatCd = 'S001004';
        }else{
            prcsStatCd = 'S001002';
        }

        axios.post('/admin/api/eqmnt/rqstAppr', {
            eqmntId: props.detailData.eqmntId,
            apprUserSeqList: apprUserSeqList,
            refUserSeqList: refUserSeqList,
            prcsStatCd: prcsStatCd,
        }).then((rslt) => {
            if (rslt.data > 0) {
                toast.success(messageCodeDic['006']);
                props.setModalIsOpen(false);
            } else {
                toast.warn(errorCodeDic['003']);
            }
        })

    }

    const fn_appr = (gb: string) => {
        let url = '';
        let param: any = {eqmntId: props.detailData.eqmntId};
        let msg = ''

        if ('appr' == gb) {
            url = '/admin/api/eqmnt/approve';
            let apprUserSeqList = [];
            const myInfo = apprLineDataList.filter((data: any) => (data.userSeq == user.userSeq))[0];
            const nextInfo = apprLineDataList.filter((data: any) => (data.apprSn == (myInfo.apprSn + 1)));

            apprUserSeqList.push(myInfo.userSeq);
            if (nextInfo.length > 0) {
                apprUserSeqList.push(nextInfo[0].userSeq);
            }

            param.apprUserSeqList = apprUserSeqList;
            msg = '승인 하시겠습니까?'

            if (!window.confirm(msg)) {
                return;
            }

            axios.post(url, param).then((rslt) => {
                if (rslt.data > 0) {
                    toast.success(messageCodeDic['006']);
                    props.setModalIsOpen(false);
                } else {
                    toast.warn(errorCodeDic['003']);
                }
            })

        } else if ('rjct' == gb) {

            // setIsRjctRsnModalOpen(true);
            // setRiskAsmntId(props.detailData.eqmntId);
        }

    }

    const fn_cancelRqst = () => {

        let apprUserSeqList: any = [];

        apprLineDataList.map((data: any) => {
            apprUserSeqList.push(data.userSeq)
        })
        if (!window.confirm('회수 하시겠습니까?')) {
            return;
        }

        axios.post('/admin/api/eqmnt/cancelAppr', {
            eqmntId: props.detailData.eqmntId,
            apprUserSeqList: apprUserSeqList
        }).then((rslt) => {
            if (rslt.data > 0) {
                toast.success(messageCodeDic['006']);
                props.setModalIsOpen(false);
            } else {
                toast.warn(errorCodeDic['003']);
            }
        })
    }

    const fn_deleteEqmntData = () => {

        if (!window.confirm('삭제 하시겠습니까?')) {
            return;
        }
        axios.post('/admin/api/eqmnt/deleteEqmntData', {eqmntId: props.detailData.eqmntId}).then((rslt) => {
            if (rslt.data > 0) {
                toast.success(messageCodeDic['006']);
                props.setModalIsOpen(false);
            } else {
                toast.warn(errorCodeDic['003']);
            }
        })
    }



    return (
        <div>
            <DriverSrchModal setModalIsOpen={setIsDriverPopOpend} modalIsOpen={isDriverPopOpend} driverData={driverData}
                             isSingleCheck={true}
                             setDriverData={setDriverData} siteId={data?.siteId} cmpnySeq={data?.cmpnySeq}/>
            <ApprLineModal setModalIsOpen={setIsApprLineModalOpen} modalIsOpen={isApprLineModalOpen}
                           mngrUserDataList={mngrUserDataList}
                           apprLineDataList={apprLineDataList} cmpnySeq={data?.cmpnySeq} rgstrSeq={data?.rgstrSeq}
                           setApprLineDataList={setApprLineDataList} siteId={props.siteId}/>
            <Modal
                className="sh-modal" size="xl"
                show={props.modalIsOpen}
                onShow={afterOpenModal}
                onHide={fn_closeModal}
            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        반입 전 장비 등록/수정
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <form name={'form'} id={'eqmntRgstModal_rgstForm'}
                          onSubmit={submit}>


                        {(props.detailData && props.detailData.prcsStatCd) &&
                            <div className="sh-modal-div">
                                <div className="div-header sh-fw-5">
                                    <div className={'min-w-[100px] font-semibold'}>
                                        결재 정보
                                    </div>
                                    <div className='w-100 d-flex justify-content-end align-items-center mb-2'>

                                        {props.detailData.prcsStatCd == 'S001001' && props.detailData?.rgstrSeq == user.userSeq &&
                                            <>
                                                <SHIconButton kind={'normal'} marginRight={'5px'} onClick={() => {
                                                    setIsApprLineModalOpen(true)
                                                }}> 결재선
                                                </SHIconButton>
                                                <SHIconButton kind={'normal'} marginRight={'5px'}
                                                              onClick={fn_rqstAppr}> 결재요청
                                                </SHIconButton>
                                            </>
                                        }
                                        {
                                            apprLineDataList.filter((data: any) => (data.apprStat == 'S002001'))[0]?.userSeq == user.userSeq &&
                                            <>
                                                <button className={'sh-btn mr-5 btn-primary'} onClick={() => {
                                                    fn_appr('appr')
                                                }}> 승인
                                                </button>
                                                <button className={'sh-btn mr-5 btn-danger'} onClick={() => {
                                                    fn_appr('rjct')
                                                }}> 반려
                                                </button>
                                            </>
                                        }

                                        {(props.detailData.prcsStatCd == 'S001002' && (props.detailData?.rgstrSeq == user.userSeq || apprLineDataList.filter((data: any) => (data.userSeq == user.userSeq)).length > 0)) &&
                                            <SHIconButton kind={'normal'} marginRight={'5px'}
                                                          onClick={fn_cancelRqst}> 회수
                                            </SHIconButton>
                                        }

                                        {(['S001001', 'S001002'].includes(props.detailData.prcsStatCd) && props.detailData?.rgstrSeq == user.userSeq) &&

                                            <SHIconButton kind={'delete'} marginRight={'5px'}
                                                          onClick={fn_deleteEqmntData}> 삭제
                                            </SHIconButton>
                                        }


                                    </div>


                                </div>

                                <div className="div-body px-3">
                                    <div className='w-100 d-flex justify-content-end align-items-center mb-2'>

                                    </div>
                                    <div className="w-100">
                                        {apprLineDataList.length > 0 ? <ApprCardList items={apprLineDataList}/> :
                                            <span>※ 결재정보를 등록해 주십시오.</span>
                                        }

                                    </div>
                                    <div>
                                        <Table className="align-middle">
                                            <tbody>
                                            <tr>
                                                <th className="sh-bg-th" style={{width: '15%'}}>참조자</th>
                                                <td style={{width: '85%'}} colSpan={3} id={'refTd'}>
                                                    <Select
                                                        options={refUserList}
                                                        value={refSelected}
                                                        isDisabled={props.detailData.prcsStatCd == 'S001001' ? false : true}
                                                        onChange={setRefSelected}
                                                        placeholder={"참조 선택"}
                                                        isMulti

                                                    />
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>


                                    </div>
                                </div>
                            </div>
                        }
                        <div className="sh-modal-div">
                            <div className="div-header">
                                장비정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>현장</th>
                                        <td style={{width: '35%'}}>
                                            <SHSelect initOptionGb={'none'} disabled={true} codeGb={'site'}
                                                      onChange={fn_setFormData} className={'form-control'}
                                                      value={data?.siteId}></SHSelect>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>업체</th>
                                        <td style={{width: '35%'}}>
                                            <SHSelect initOptionGb={'sel'} disabled={data?.driverSeq ? true : false}
                                                      name={'cmpnySeq'} codeGb={'siteCmpny'} className={'w-100'}
                                                      onChange={fn_setFormData} selParam={{siteId: props.siteId}}
                                                      value={data?.cmpnySeq}></SHSelect>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>장비명</th>
                                        <td style={{width: '35%'}}>
                                            <SHSelect initOptionGb={'sel'} name={'eqmntCd'} className={'w-100'} codeGb={'siteMngCd'}
                                                      onChange={(e) => {fn_setFormData(e); fn_retrieveChkDataList(e.target.value); setSelected([])}} selParam={{
                                                siteId: props.siteId,
                                                manageCode: 'M001005'
                                            }} value={data?.eqmntCd}></SHSelect>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>임대 업체</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput type="text" className="form-control" name={'rntCmpnyNm'}
                                                     onChange={fn_setFormData} value={data?.rntCmpnyNm}/>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>장비 등록번호</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput type="text" className="form-control" name={'eqmntRgstNo'}
                                                     onChange={fn_setFormData} value={data?.eqmntRgstNo}/>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>장비 규격</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex align-items-center w-100">
                                            <SHInput type="number" className="form-control" name={'eqmntWght'}
                                                     onChange={fn_setFormData} value={data?.eqmntWght}/>
                                            <span className={'ml-5'}>t(톤)</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>장비 검사기간</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex align-items-center w-100" ref={inspctProdRef}>
                                                <SHDatePicker2 setter={setEqmntIspctStrtDt} name={'noticeStartDate'} defaultValue={eqmntIspctStrtDt} onChange={fn_setFormData}/>
                                                <span>&nbsp;~&nbsp;</span>
                                                <SHDatePicker2 setter={setEqmntIspctEndDt} name={'noticeEndDate'} defaultValue={eqmntIspctEndDt} onChange={fn_setFormData}/>
                                            </div>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>보험기간</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex align-items-center w-100" ref={isrncProdRef}>
                                                <SHDatePicker2 setter={setEqmntIsrncStrtDt} name={'x'} defaultValue={eqmntIsrncStrtDt} onChange={fn_setFormData}/>
                                                <span>&nbsp;~&nbsp;</span>
                                                <SHDatePicker2 setter={setEqmntIsrncEndDt} name={'x'} defaultValue={eqmntIsrncEndDt} onChange={fn_setFormData}/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>운전원</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex align-items-center w-100">
                                                <SHInput type="text" disabled={true} className="form-control" name={'driverNm'}
                                                         onChange={fn_setFormData} value={data?.driverNm}/>
                                                <SHInput type="hidden" className="form-control" name={'driverSeq'}
                                                         onChange={fn_setFormData} value={data?.driverSeq}/>
                                                <button className="sh-btn btn-secondary min-w-[70px] ml-5" type="button" onClick={() => fn_openDriverSrchPop(true)}>선택
                                                </button>

                                            </div>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>운전원 연락처</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput type="text" disabled={true} className="form-control" name={'driverPhone'}
                                                     onChange={fn_setFormData} value={data?.driverPhone}/>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>운전원 면허번호</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput type="text" disabled={true} className="form-control" name={'driverLcncNo'}
                                                     onChange={fn_setFormData} value={data?.driverLcncNo}/>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>장비교대운전원</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex align-items-center w-100">
                                                <SHInput type="text" disabled={true} className="form-control" name={'rotDriverNm'}
                                                         onChange={fn_setFormData} value={data?.rotDriverNm}/>
                                                <SHInput type="hidden" disabled={true} className="form-control"
                                                         name={'rotDriverSeq'}
                                                         onChange={fn_setFormData} value={data?.rotDriverSeq}/>

                                                <button className="sh-btn btn-secondary min-w-[70px] ml-5" type="button" onClick={() => fn_openDriverSrchPop(false)}>선택
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>사용 전 장비점검 질문</th>
                                        <td style={{width: '85%', maxWidth:'100px'}} colSpan={3}>
                                            <Select
                                                         options={eqmntChkList}
                                                         value={selected}
                                                         onChange={setSelected}
                                                         placeholder={"사용 전 장비점검 질문"}
                                                         isMulti
                                            />
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        <div className="sh-modal-div">
                            <div className="div-header">
                                작성정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>작성일</th>
                                        <td style={{width: '35%'}}>
                                            <input value={data?.rgstDt || currentDate}
                                                   className={"form-control"}
                                                   disabled={true}/>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>작성자</th>
                                        <td style={{width: '35%'}}>
                                            <input className={"form-control"}
                                                   value={data?.rgstrNm || user.userName} disabled={true}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                    </form>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>

                        {!props.isReadOnly &&
                            ((['S001001', 'S001002'].includes(props.detailData?.prcsStatCd) && props.detailData?.rgstrSeq == user.userSeq)
                                || !props.detailData)
                            &&
                        <button onClick={fn_registData}
                                className='sh-btn pill p'>등록/수정

                        </button>
                        }
                    </div>
                </Modal.Footer>

            </Modal>
        </div>
    )
}

export default EqmntRgstModal;

