import React, {useContext, useEffect, useRef, useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import {ReactComponent as IconRight} from "../../assets/images/ico/icon-right.svg";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend, Chart,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {UserContext} from "../../context/User";
import {getLoginUser} from "../../helper/SecurityHelper";
import restapi from "../../helper/restAPIHelper";
import WrkrInfoListModal from "./modal/WrkrInfoListModal";
import {MenuOpenContext} from "../../context/MenuOpen";
import EtrncStatByCmpnyModal from "./modal/EtrncStatByCmpnyModal";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip
);

interface IProps {
    etrncStatData: any;
}

const DashPartDepartureStatus = (props: IProps) => {
    const {open} = useContext(MenuOpenContext);
    const {user} = useContext<any>(UserContext);
    const defaultUser = getLoginUser();
    const chartRef = useRef<any>();
    const labels = ['금농토건', '대아이엔씨', '대림건설', '신한에스엔지', '신보', '상원전기', '대아이앤씨'];
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [wrkrDataList, setWrkrDataList] = useState<any[]>([]);
    const [etrncStatByCmpnyDataList, setEtrncStatByCmpnyDataList] = useState<any[]>([]);
    const [copData, setCopData] = useState<any>({
        labels,
        datasets: [
            {
                label: '현재인원',
                data: [100, 60, 70, 30, 80, 10, 20],
                backgroundColor: [
                    'rgba(48, 228, 199, 1)',
                ],
                borderColor: [
                    'rgba(30, 31, 35, 1)',
                ],
                borderWidth: 1,
            },
            {
                label: 'OUT인원',
                data: [90, 50, 60, 60, 40, 20, 10],
                backgroundColor: [
                    'rgba(86, 133, 247, 1)',
                ],
                borderColor: [
                    'rgba(30, 31, 35, 1)',
                ],
                borderWidth: 1,
            },
        ]
    });

    useEffect(() => {
        if(props.etrncStatData){
            setWrkrDataList(props.etrncStatData.totWrkrList);
        }
    }, [props]);

    useEffect(() => {
        chartRef.current.resize()
    },[open])
    useEffect(() => {
        const siteId = user?.blngSiteId || defaultUser.blngSiteId;

        restapi.retrieveEtrncStatByCmpny({siteId:siteId}).then((rsltData: any) => {
            const inWrkrCmpnyDataList = rsltData.inWrkrCmpnyDataList;
            let labels:any = [];
            let inWrkrCntArr: any = [];
            let outWrkrCntArr: any = [];
            console.log('inWrkrCmpnyDataList: ', inWrkrCmpnyDataList);
            setEtrncStatByCmpnyDataList(inWrkrCmpnyDataList);

            inWrkrCmpnyDataList.map((inData: any) => {
                labels.push(inData.cmpnyNm);
                inWrkrCntArr.push(inData.remainCnt);
                outWrkrCntArr.push(inData.outCnt);
            })

            const copData = {
                labels,
                datasets: [
                    {
                        label: '현재인원',
                        data: inWrkrCntArr,
                        backgroundColor: [
                            'rgba(48, 228, 199, 1)',
                        ],
                        borderColor: [
                            'rgba(30, 31, 35, 1)',
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'OUT인원',
                        data: outWrkrCntArr,
                        backgroundColor: [
                            'rgba(86, 133, 247, 1)',
                        ],
                        borderColor: [
                            'rgba(30, 31, 35, 1)',
                        ],
                        borderWidth: 1,
                    },
                ]
            };
            setCopData(copData);




        })

    }, [user])



    const options = {
        responsive: true,
        scales: {
            x: {
                ticks: {
                    color: 'rgba(255, 255, 255, 1)',
                    font: {
                        family: "'SCoreDream','Arial",
                        size: 12,
                    },
                }
            },
            y: {
                ticks: {
                    color: 'rgba(255, 255, 255, 1)',
                    font: {
                        family: "'SCoreDream','Arial",
                        size: 8,
                    },
                }
            }
        },
        plugins: {
            legend: {
                display: false,
            }
        },
        elements: {
            bar: {
                borderRadius: 10,
            }
        },
        rotation: -90,
        circumference: 180,
        cutout: '70%',
    };

    const fn_openWrkrInfoModal = () => {
        setModalIsOpen(true);
    }

    return(
        <>
            <Container fluid className="sh-bg-pane rounded-[5px] py-2 px-3">
                <EtrncStatByCmpnyModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} dataList={etrncStatByCmpnyDataList} />
                <Row className="px-0 mb-2">
                    <Col bsPrefix="col-7" className="d-flex align-items-center w-100">
                        <div className="w-100 d-flex align-items-center justify-content-between">
                            <span className="pe-2 sh-fs-h4 sh-fw-5 sh-font-SD">업체별 출역현황</span>
                            <div className={'cursor-pointer'} onClick={fn_openWrkrInfoModal}>
                            <IconRight style={{width:'20px',marginBottom:'1px'}} />
                            </div>
                        </div>
                    </Col>
                </Row>
                    <Row>
                        <Col className="pt-3 d-flex align-items-center">
                            <Container fluid className="px-0">
                                <Row>
                                    <Col>
                                        <Bar options={options} data={copData} height="175px" ref={chartRef} />
                                    </Col>
                                </Row>
                                <Row className="py-2">
                                    <Col className="d-flex justify-content-center w-100 sh-fs-b4">
                                        <div>
                                            <span className="pe-1" style={{color:'#30E4C7'}}>●</span>
                                            <span className="pe-1">현재인원</span>
                                        </div>
                                        <div className="ps-2">
                                            <span className="pe-1" style={{color:'#5685F7'}}>●</span>
                                            <span className="pe-1">OUT인원</span>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
            </Container>
        </>
    );
};

export default DashPartDepartureStatus;

