import React, {Dispatch, SetStateAction, useContext, useRef} from 'react';
import {ReactComponent as IconSubmit} from '../../images/icon_submit.svg';
import {ReactComponent as IconSearch} from '../../images/icon_search.svg';
import IconSave from '../../images/icon_save.png';
import IconCopy from '../../images/icon_copy.png';
import IconDelete from '../../images/icon_delete.png';
import IconView from '../../images/icon_view.png';
import IconNotView from '../../images/icon_notview.png';
import {ReactComponent as IconAdd} from '../../images/icon-add.svg';
import {getLoginUser} from "../../helper/SecurityHelper";
import {UserContext} from "../../context/User";

interface IProps {
    children?: any;
    kind: any;
    marginRight?: any;
    onClick?: Dispatch<SetStateAction<any>>;
    view?: any;
    disabled?:boolean;
    className?: string;
}

export default function View(props: IProps) {

    const defaultUser = getLoginUser();
    const {user} = useContext<any>(UserContext);

    const isDisabled = () => {
        var authCode = user?.authCode || defaultUser.authCode
        if (authCode === 'siteRead' && (props.kind === 'submit' || props.kind === 'delete' || props.kind === 'save')) {
            return true;
        }
        return props.disabled || false;
    }

    return (
        <>
            {
                props.view == null ?
                    <button type={"button"} style={{marginRight:props.marginRight}}
                            disabled={isDisabled()}
                            className={"sh-btn"+
                                (props.kind == 'copy' || props.kind == 'delete' ? ' normal l' : '')+
                                (props.kind == 'save' || props.kind == 'submit' || props.kind == 'normal' ? ' normal p' : '')+
                                (props.kind == 'add' ? ' gray add' : '')
                            } onClick={props.onClick}>
                        {
                            props.kind == 'submit' ?
                                <IconSubmit /> :
                                props.kind == 'save' ?
                                    <img src={IconSave} alt="아이콘" /> :
                                    props.kind == 'copy' ?
                                        <img src={IconCopy} alt="아이콘" /> :
                                        props.kind == 'delete' ?
                                            <img src={IconDelete} alt="아이콘" /> :
                                            props.kind == 'add' ?
                                                <IconAdd /> : null
                        }
                        {props.children}
                    </button>
                    :
                    <button type={"button"} style={{marginRight:props.marginRight}}
                            disabled={isDisabled()}
                            className={"sh-btn gray view"} onClick={props.onClick}>
                        {
                            props.view ?
                                <><img src={IconView} style={{width:'16px', height:'12px'}} alt="아이콘" /> 수기입력 보이기</> :
                                <><img src={IconNotView} style={{width:'16px', height:'12px'}} alt="아이콘" />  수기입력 숨기기</>
                        }
                    </button>
            }

        </>
    );
}

