import React, {Dispatch, SetStateAction, useContext, useState} from "react";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import SHInput from "../../../components/common/SHInput";
import SHSelect from "../../../components/common/SHSelect";
import SHTextarea from "../../../components/common/SHTextarea";
import restapi from "../../../helper/restAPIHelper";
import {toast} from "react-toastify";
import axios from "axios";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import {ProgressContext} from "../../../context/Progress";
import moment from "moment/moment";
import SHIconButton from "../../../components/common/SHIconButton";
import ApprCardList from "../../../components/appr/ApprCardList";
import SftyApprCardList from "../../../components/appr/SftyApprCardList";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
}



export default function SosRqstHisModal(props:IProps){
    const [data, setData] = useState<any>();
    const formRef = React.createRef<HTMLFormElement>();
    const defaultUser: any = getLoginUser();
    const {spinner} = useContext(ProgressContext);

    const fn_updateData = (e: any) => {
        setData((item: any) => {
            return {...item, [e.target.name]: e.target.value}
        });
    }

    const fn_openModal = () => {
        setData(props.detailData);
    }

    const fn_closeModal = () => {
        props.setModalIsOpen(false);
    }

    const fn_confirmAlertApi = () =>{
        sosEventApi('P001004') //확인->처리중
    }
    const fn_releaseAlertApi = () =>{
        sosEventApi('P001002') //해제
    }

    const fn_errorAlertApi = () =>{
        sosEventApi('P001003') //오류
    }

    const sosEventApi = (statCd:string) =>{
        if ((statCd == 'P001004' || statCd == 'P001003') && !data.cntnts) {
            toast.warn('내용을 입력해 주세요.')
            return;
        }

        if ((statCd == 'P001002') && !data.rsltCntnts) {
            toast.warn('처리결과를 입력해 주세요.')
            return;
        }

        let param = data;
        // @ts-ignore
        var formData: any = new FormData(formRef.current)
        param.userSeq = defaultUser.userSeq;
        param.statCd = statCd;
        formData.set('sosHisVO', new Blob([JSON.stringify(param)], {type: "application/json"}))

        if (formData) {
            let confirmMsg = "";
            if(statCd == 'P001004'){
                confirmMsg = '확인 하시겠습니까?';
            }else if(statCd == 'P001003'){
                confirmMsg = '오류알림 하시겠습니까?';
            }else if(statCd == 'P001002'){
                confirmMsg = '해제알림 하시겠습니까?'
            }

            if (!window.confirm(confirmMsg)) {
                return;
            }

            spinner.start();
            axios.post('/admin/api/sos/saveSosHisForEvents', formData).then((result) => {
                if (result.data > 0) {
                    if(statCd == 'P001002' || statCd == 'P001003'){
                        restapi.sendSosPushMsg(
                            {
                                userSeq:defaultUser.userSeq,
                                siteId:data?.siteId,
                                sosId:data?.sosId,
                                statCd:statCd
                            }
                        ).then((rData) => {
                            if(rData>0){
                                toast.success(messageCodeDic['006'])
                                props.setModalIsOpen(false);
                            }else{
                                toast.error(errorCodeDic['003']);
                            }
                        })
                    }else{
                        toast.success(messageCodeDic['006'])
                        props.setModalIsOpen(false);
                    }
                } else {
                    toast.error(errorCodeDic['003']);
                }
            }).catch(error => {
                toast.error(errorCodeDic['003']);
                console.log('error: ', error);
            }).finally(() => {
                spinner.stop();
            });
        }
    }

    return (
        <div>
            <Modal
                className="sh-modal" size="xl"
                show={props.modalIsOpen}
                onShow={fn_openModal}
                onHide={fn_closeModal}
            >

                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        SOS응급콜 상세보기
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <form name={'detailForm'} ref={formRef} method={"post"}>
                        <div className="sh-modal-div">
                            <div className="div-header">
                                <div className="d-flex flex-row gap-2">
                                    <span>기본정보</span>
                                    <span className="fs-6 fw-light">({data?.sosGbNm})</span>
                                    {data?.statCd == null || data?.statCd == 'P001001' ? <button className="sh-btn bg-orange-600 text-white btn-sm" type="button">발생</button> : null}
                                    {data?.statCd == 'P001002' ? <button className="sh-btn bg-green-600 text-white btn-sm" type="button">해제</button> : null}
                                    {data?.statCd == 'P001003' ? <button className="sh-btn bg-gray-600 text-white btn-sm" type="button">오류</button> : null}
                                    {data?.statCd == 'P001004' ? <button className="sh-btn bg-blue-600 text-white btn-sm" type="button">처리중</button> : null}
                                </div>
                                <div className="d-flex flex-row gap-2">
                                    <button className="sh-btn btn-secondary" onClick={fn_errorAlertApi} type="button" style={{display:data?.statCd ==null || data?.statCd == 'P001001' ? 'block' : 'none'}}>오류알림</button>
                                    <button className="sh-btn btn-secondary" onClick={fn_releaseAlertApi} type="button" style={{display:data?.statCd == 'P001004' ? 'block' : 'none'}}>해제알림</button>
                                    <button className="sh-btn bg-black text-white"onClick={fn_confirmAlertApi} type="button" style={{display:data?.statCd ==null || data?.statCd == 'P001001' ? 'block' : 'none'}}>확인</button>
                                </div>
                            </div>
                            <div className="div-body px-3">
                                <div className="container-fluid px-0">
                                    <div className="row justify-content-end" id="ga-company-sites">
                                        {
                                            data?.statCd == 'P001003'?
                                            <div className="col-md-2 mb-4">
                                                <div className="card ga-card small">
                                                    <div className="card-header text-center">{data?.errorAlertCmpnyNm}</div>
                                                    <div className="card-body items-center min-h-[100px]">
                                                        <h5 className="card-title text-center">{data?.errorAlertUserNm}</h5>
                                                        <h5 className={"card-title text-center"}>{moment(data?.errorAlertDt).format("YYYY-MM-DD")}</h5>
                                                        <h5 className={"card-title text-center"}>{moment(data?.errorAlertDt).format("HH:mm")}</h5>
                                                        <h5 className="card-title text-center text-blue-600">오류알림</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            :null
                                        }
                                        {
                                            data?.statCd == 'P001004' || data?.statCd == 'P001002'?
                                            <div className="col-md-2 mb-4">
                                                <div className="card ga-card small">
                                                    <div className="card-header text-center">{data?.cnfrmCmpnyNm}</div>
                                                    <div className="card-body items-center min-h-[100px]">
                                                        <h5 className="card-title text-center">{data?.cnfrmUserNm}</h5>
                                                        <h5 className={"card-title text-center"}>{moment(data?.cnfrmDt).format("YYYY-MM-DD")}</h5>
                                                        <h5 className={"card-title text-center"}>{moment(data?.cnfrmDt).format("HH:mm")}</h5>
                                                        <h5 className="card-title text-center text-blue-600">확인</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            :null
                                        }
                                        {
                                            data?.statCd == 'P001002'?
                                            <div className="col-md-2 mb-4">
                                                <div className="card ga-card small">
                                                    <div className="card-header text-center">{data?.rlasCmpnyNm}</div>
                                                    <div className="card-body items-center min-h-[100px]">
                                                        <h5 className="card-title text-center">{data?.rlasUserNm}</h5>
                                                        <h5 className={"card-title text-center"}>{moment(data?.rlasDt).format("YYYY-MM-DD")}</h5>
                                                        <h5 className={"card-title text-center"}>{moment(data?.rlasDt).format("HH:mm")}</h5>
                                                        <h5 className="card-title text-center text-blue-600">해제조치</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            :null
                                        }

                                    </div>
                                </div>
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>현장명</th>
                                        <td colSpan={3} style={{width: '85%'}}>
                                            <SHInput type="text" className="form-control" name={'siteName'} onChange={fn_updateData} value={data?.siteName} readOnly={true}/>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>발생일시</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput type="text" className="form-control" name={'rgstDt'} onChange={fn_updateData} value={data?.rgstDt + " " +  data?.rgstTime} readOnly={true}/>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>이름</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput type="text" className="form-control" name={'userName'} onChange={fn_updateData} value={data?.userName} readOnly={true}/>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>전화번호</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput type="text" className="form-control" name={'userPhone'} onChange={fn_updateData} value={data?.userPhone} readOnly={true}/>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>업체</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput type="text" className="form-control" name={'companyName'} onChange={fn_updateData} value={data?.companyName} readOnly={true}/>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>공종</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput type="text" className="form-control" name={'workTypeNm'} onChange={fn_updateData} value={data?.workTypeNm} readOnly={true}/>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>직종</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput type="text" className="form-control" name={'ocptNm'} onChange={fn_updateData} value={data?.ocptNm} readOnly={true}/>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>내용</th>
                                        <td style={{width: '85%'}} colSpan={3}>
                                            <SHTextarea rows={5} onChange={fn_updateData} name={'cntnts'}
                                                         value={data?.cntnts}
                                                         className={"form-control"}
                                                         placeholder='내용을 입력해주세요.'
                                                         disabled={data?.statCd =='P001002' || data?.statCd == 'P001003' || data?.statCd == 'P001004' ?true:false}
                                            />
                                        </td>
                                    </tr>
                                    {
                                        data?.statCd == 'P001004' || data?.statCd == 'P001002' ?
                                        <tr className="border-top align-middle">
                                            <th className="sh-bg-th" style={{width: '15%'}}>처리결과</th>
                                            <td style={{width: '85%'}} colSpan={3}>
                                                <SHTextarea rows={5} onChange={fn_updateData} name={'rsltCntnts'}
                                                             value={data?.rsltCntnts}
                                                             className={"form-control"}
                                                             placeholder='처리결과를 입력해주세요.'
                                                             disabled={data?.statCd =='P001002'?true:false}
                                                />
                                            </td>
                                        </tr>
                                            :null
                                    }

                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </form>
                </Modal.Body>

                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                    </div>
                </Modal.Footer>

            </Modal>
        </div>
    )
}
