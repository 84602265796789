import React, {createContext, useState} from 'react';

const TopMenuContext = createContext({
  menu: 'dashboard',
  setMenu: ({}: any) => {},
  param: null,
  setParam: ({}: any) => {},
});

const TopMenuProvider = ({children}: any) => {
  const [menu, setMenu] = useState(null);
  const [param, setParam] = useState(null);
  const value: any = {menu, setMenu, param, setParam};

  return <TopMenuContext.Provider value={value}>{children}</TopMenuContext.Provider>;
};

export {TopMenuContext, TopMenuProvider};
