import React, {useState} from "react";
import {Container, Row, Col} from "react-bootstrap";

import InfoMap from "./map/InfoMap";
import RealMap from "./map/RealMap";



export default function HOLocalFieldStatus() {
    const [showInfoMap, setShowInfoMap] = useState<boolean>(false);

    return(
        <>
            <Container className="sh-bg-pane rounded-[5px] pt-2 px-3 pb-3" style={{height:"calc(100vh - 125px)"}}>
                <Row className="px-0 mb-2">
                    <Col bsPrefix="col-7" className="d-flex align-items-center w-100 justify-content-between">
                        <div className="d-flex align-items-center justify-content-between sh-fs-b4 sh-fw-5">
                            <span className="pe-2 sh-fs-h4 sh-fw-5 sh-font-SD">지역별 현장 현황</span>
                        </div>
                        <div className="d-flex sh-bg-pane rounded-[5px] py-1 px-1 ">
                            <span className={"rounded-[5px] py-1 px-2 me-2 " + (showInfoMap ? 'sh-bg-accent' : '')} onClick={()=>setShowInfoMap(true)}>정보맵</span>
                            <span className={"rounded-[5px] py-1 px-2      " + (showInfoMap ? '' : 'sh-bg-accent')} onClick={()=>setShowInfoMap(false)}>지도</span>
                        </div>
                    </Col>
                </Row>
                <Row className="p-0">
                    <Col>
                        <div className="w-100 position-relative sh-fs-b4 sh-font-SP sh-fw-5" style={{height:"calc(100vh - 207px)", borderRadius: "10px"}}>
                            {showInfoMap ?
                                <InfoMap/>
                                :
                                <RealMap/>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
