import axios, { AxiosResponse } from "axios";

const responseBody = (response: AxiosResponse) => response.data;
const requests = {
	get: (url: string) => axios.get(url).then(responseBody),
	post: (url: string, body: object) => axios.post(url, body).then(responseBody),
	put: (url: string, body: object) => axios.put(url, body).then(responseBody),
	delete: (url: string) => axios.delete(url).then(responseBody),
};

const api = {
  	//User
	getIsUser: (userId: string) => requests.get(`/admin/rest/getIsUser?phone=${userId}`),
	searchCompany: (comSearchQuery: string) => requests.get(`/admin/rest/searchCompany?query=${comSearchQuery}`),
	findByPhoneOrUserId: (phoneOrUserId: string) => requests.post('/admin/api/user/findByPhoneOrUserId',{phoneOrUserId:phoneOrUserId}),
	login: (userId: string, userPassword:string, accessClientUuid:string) => requests.post('/admin/api/user/login',{userId:userId,userPassword:userPassword,accessClientUuid:accessClientUuid}),
	getLoginUser: () => requests.post('/admin/api/user/getLoginUser',{}),
	checkKakaoId: (kakaoId:string) => requests.post('/admin/api/user/loginByKakaoId',{kakaoId:kakaoId}),
	//Env
	getEnv: () => requests.post(`/admin/api/env/getEnv`,{}),
	matchNfc: (serialNumber:string) => requests.post(`/admin/api/env/matchNfc`,{serialNumber:serialNumber}),
	getPccInfo: () => requests.post(`/admin/api/env/getPccInfo`,{}),
	retrieveUserInfoDtl: (userId: string) => requests.post('/admin/api/user/retrieveUserInfoDtl', {userId:userId})

}

export default api;
