import React, {createContext, useEffect, useRef, useState} from 'react';
import {fn_loginHandlerCallback} from "../helper/CmmnHelper";
import {transform} from "ol/proj";
import {Feature} from "ol";
import {Point} from "ol/geom";
import {getLoginUser, USER_SESSION} from "../helper/SecurityHelper";

const PushContext = createContext({
  push: null,
  setPush: ({}: any) => {},
  removeMessage: ({}: any) => {},
  getMessages: () => {},
});

const PushProvider = ({children}: any) => {
  const [socketConnected, setSocketConnected] = useState(false);
  const [items, setItems] = useState([]);
  const webSocketUrl = process.env.REACT_APP_SOCKET_URL;
  let ws = useRef(null);

  function wsconnect(){
    try{
      // @ts-ignore
      ws.current = new WebSocket(webSocketUrl);
      // @ts-ignore
      ws.current.onopen = () => {
        console.log("connected to " + webSocketUrl);
        setSocketConnected(true);
      };
      // @ts-ignore
      ws.current.onclose = (error: any) => {
        console.log("disconnect from " + webSocketUrl);
        console.log(error);
        setTimeout(function() {
          wsconnect();
        }, 1000);
      };
      // @ts-ignore
      ws.current.onerror = (error: any) => {
        console.log("connection error " + webSocketUrl);
        console.log(error);
      };
      // @ts-ignore
      ws.current.onmessage = (evt: any) => {
        const data = JSON.parse(evt.data);
        if(data){
          if(data.make === "SH_LOGOUT"){
            // @ts-ignore
            const user = JSON.parse(localStorage.getItem(USER_SESSION));
            if(user && user.value){
              if(data.userSeq === user.value.userSeq
                  && data.clientUuid !== user.value.accessClientUuid){
                localStorage.removeItem(USER_SESSION);
                alert("다른기기에서 로그인되어 로그아웃 됩니다.");
                window.location.href = "/login"
              }
            }
          }else if(data.make === 'SH_DANGER'){
            // @ts-ignore
            var map = window.___dashboard_map
            // @ts-ignore
            var layer = window.___dangerLayer
            if(layer){
              layer.getSource().clear()
              var points = data.points
              var features = []
              for(var i in points){
                var p = points[i]
                if(p.longitude && p.latitude){
                  var center = transform([p.longitude, p.latitude], 'EPSG:4326', 'EPSG:3857')
                  var feature = new Feature({
                    geometry: new Point(center),
                  })
                  feature.set("FEATURE_TYPE", "DANGER")
                  feature.set("FEATURE_GB", p.markGb)
                  feature.set("SITE_ID", p.siteId)
                  feature.set("SITE_NAME", p.siteName)
                  features.push(feature)
                }
              }
              layer.getSource().addFeatures(features)
            }
          }else if(data.make === 'SH_ALERT'){
            console.log('111333')
            const user = getLoginUser();
            console.log('user: ', user);
            console.log('data: ', data);
            if(data.longitude && data.latitude){
              // @ts-ignore
              var map = window.___dashboard_map
              var center = transform([data.longitude, data.latitude], 'EPSG:4326', 'EPSG:3857')
              if(center && map){
                map.getView().setCenter(center)
                map.getView().setZoom(12)
              }
            }
            const authChrgDataList = user.authChrgDataList;
            const siteId = data.siteId;
            let authSiteIdList: any = [];
            authChrgDataList.map((data: any) => {
              authSiteIdList.push(data.siteId);
            })
            console.log('data: ', data);
            if(user.authGrpId == 'HEAD' || (user.blngSiteId == siteId && user.authGrpId == 'SITE')) {
              // @ts-ignore
              setItems((prevItems: []) => [...prevItems, data]);
            }
          }
        }
      };
    }catch (e){
      console.error(e)
      setTimeout(function() {
        wsconnect();
      }, 3000);
    }
  }
  useEffect(() => {
    wsconnect()
    return () => {
      console.log("clean up");
      // @ts-ignore
      ws.current.close();
    };
  }, []);

  useEffect(() => {
    if(items.length > 0){

      // @ts-ignore
      setPush({isOpen: true, messages:items})
    }
    // @ts-ignore
  },[items])
  function removeMessage(target: any){
    var r = items.filter((item: any) => item.uuid !== target.uuid)
    setItems(r);
    return r;
  }
  function getMessages(){
    return items;
  }
  const [push, setPush] = useState(null);
  const value: any = {push, setPush, items, removeMessage, getMessages};
  return <PushContext.Provider value={value}>{children}</PushContext.Provider>;
};

export {PushContext, PushProvider};
