import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import SHSelect from "../../../components/common/SHSelect";
import {getLoginUser} from "../../../helper/SecurityHelper";
import { Container } from "react-bootstrap";
import SHInput from "../../../components/common/SHInput";
import SHSearch from "../../component/SHSearch";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData?: any;
    actChrgList: object[];
    setActChrgList: Dispatch<SetStateAction<object[]>>;
    isSingleCheck?:boolean
    siteNm?:string
    cmpnySeq:number

}

const ActChrgrModal = (props: IProps) => {
    const [datatable, setDatatable] = useState<any>();
    const [dataList, setDataList] = useState<any[]>([]);
    const searchFormRef = useRef<any>();
    const [data, setData] = useState<any>()
    const user = getLoginUser();
    const defaultUser = getLoginUser();
    useEffect(() => {
        if(dataList.length > 0 && props.actChrgList.length > 0) {
            let settingList = dataList;

            settingList.map((data) => {
                props.actChrgList.map((blngData:any) => {
                    if(data.siteId == blngData.siteId){
                        data._checked = true;
                    }
                })
            })
            setDataList(settingList);
        }
    }, [dataList])


    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_search = (e?: any) => {
        e?.preventDefault()
        datatable?.draw()
    }
    const fn_select = () => {
        var checkedData = getCheckedItems(datatable)
        if (checkedData.length == 0) {
            toast.warn('조치담당자를 선택해 주십시오.');
            return;
        }
        console.log('checkedData: ', checkedData);

        props.setActChrgList(checkedData);
        props.setModalIsOpen(false);
    }

    const fn_setFormData = (e: any) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }
    return (
        <div>
            <Modal
                className="sh-modal sub" size="xl"
                show={props.modalIsOpen}
                onShow={()=>fn_search()}
                onHide={fn_closeModal}

            >

                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        조치담당자 선택
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">
                        <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                            <SHInput type={'hidden'} name={'isMngr'} value={true}></SHInput>
                            <SHInput type={'hidden'} name={'siteId'} value={user?.blngSiteId || defaultUser.blngSiteId}></SHInput>
                            <Row>
                                <Col>
                                    <Table className="sh-font-SP sh-fs-b2 my-3">
                                        <tbody>
                                        <tr className="border-top align-middle">
                                            <th className="sh-bg-th" style={{width:'6%'}}>업체</th>
                                            <td style={{width:'19%'}}>
                                                <SHSelect initOptionGb={'none'} selParam={{siteId: user.blngSiteId}}
                                                          disabled={true} codeGb={'siteCmpny'}
                                                          onChange={fn_setFormData}
                                                          value={props.cmpnySeq}></SHSelect>
                                                <SHInput type={'hidden'} name={'blngCmpnySeq'} value={props.cmpnySeq}></SHInput>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </SHSearch>

                    <div className={'ge-result'}>
                        <Datatables
                            dataUrl={'/admin/api/user/retrieveUserDataListWithView'}
                            parameterFormRef={searchFormRef}
                            columns={[
                                {data: "userSeq", title: "userSeq", textAlign: 'center'},
                                {data: "companyName", title: "업체", textAlign: 'left'},
                                {data: "userName", title: "이름", textAlign: 'left'},
                                {data: "chrgNm", title: "직책", textAlign: 'center'},
                            ]}
                            checked={true}
                            isSingleCheck={true}
                            index={true}
                            datas={dataList}
                            onLoad={(dt: any) => {
                                setDatatable(dt)
                            }}
                            onClick={(data) => {

                            }
                            }
                        />
                    </div>

                </Modal.Body>

                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <button onClick={fn_select}
                                className='sh-btn pill p'>선택

                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ActChrgrModal;
