import {IoMdClose} from "react-icons/io";
import iconCalendar from "../../../images/icon-calendar-solid.svg";
import CustomDatePickerRange from "../../component/ui/DatePickerRange";
import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {CmmnFn, fn_getCurrentDate} from "../../../helper/CmmnHelper";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {Container, Col, Row, Modal, Table} from "react-bootstrap";
import {COMMON_MODAL_STYLE, COMMON_MODAL_STYLE_SCROLL, COMMON_MULTISEL_STRINGS} from "../../../helper/StyleHelper";
import SHInput from "../../../components/common/SHInput";
import SHTextarea from "../../../components/common/SHTextarea";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";

import restapi from "../../../helper/restAPIHelper";
import iconNodata from "../../../images/icon_nodocdata.png";

const $ = require("jquery")

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;

    siteId: number;
}

const RskAsmntDetailModal = (props: IProps) => {

    const user = getLoginUser();

    const prodRef = useRef<any>();
    const raProdRef = useRef<any>();

    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);
    const [isRaDatePopupOpen, setIsRaDatePopupOpen] = useState(false);
    const [data, setData] = useState<any>()
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [raStartDate, setRaStartDate] = useState<string>('');
    const [raEndDate, setRaEndDate] = useState<string>('');

    const [isDngrEvlCrtModalOpen, setIsDngrEvlCrtModalOpen] = useState(false);
    const [dngrFctrDataList, setDngrFctrDataList] = useState<any>([]);
    const [dngrFctrDataRsltList, setDngrFctrDataRsltList] = useState<any>([]);
    const [eqmntCntList, setEqmntCntList] = useState<any>([]);
    const [slctdRowCode, setSlctdRowCode] = useState<String>('');
    const [isCrtCmpltd, setIsCrtCmpltd] = useState<boolean>(false);


    useEffect(() => {
        fn_genEqmntCnt();
    }, [])

    useEffect(() => {
        console.log('data: ', data);
    }, [data])

    useEffect(() => {
       console.log('dngrFctrDataRsltList: ', dngrFctrDataRsltList);
    }, [dngrFctrDataRsltList])

    useEffect(() => {
        if(raStartDate && raEndDate){

                if(raStartDate < startDate ||  raStartDate > endDate || raEndDate < startDate || raEndDate > endDate){
                    toast.warn('작업기간은 평가기간을 벗어날 수 없습니다.');
                    return;
                }


            dngrFctrDataRsltList.map((data: any) => {
                if(data.code == slctdRowCode){
                    data['raStartDate'] = raStartDate;
                    data['raEndDate'] = raEndDate;
                }
            })

            setDngrFctrDataRsltList([].concat(dngrFctrDataRsltList))
            setRaStartDate('')
            setRaEndDate('')
        }

    }, [raStartDate,raEndDate])


    useEffect(() => {
        console.log('dngrFctrDataList: ', dngrFctrDataList);
        if (dngrFctrDataList.length > 0 && isCrtCmpltd) {
            let codeList: string[] = [];
            dngrFctrDataList.map((fctrData: any) => {
                codeList.push(fctrData.code);
            })
            restapi.retrieveDngrEvlFctrDataList(codeList).then((rsltData) => {
                rsltData.map((inData: any) => {
                    inData.siteId = data.siteId
                    inData.raStartDate = startDate;
                    inData.raEndDate = endDate;
                })

                setDngrFctrDataRsltList(rsltData);
            })
            setIsCrtCmpltd(false)
        }

    }, [dngrFctrDataList, isCrtCmpltd])


    const fn_genEqmntCnt = () => {
        let cntList = [];

        for (let i = 1; i < 100; i++) {
            cntList.push(i);
        }
        setEqmntCntList(cntList);
    }

    const fn_setFormData = (e: any) => {
        CmmnFn.setFormData(e, data, setData);

    }

    const fn_onAfterOpen = () => {

        // 신규
        if (!props.detailData) {

            const siteId = user.blngSiteId;
            const cmpnySeq = user.blngCmpnySeq;
            const today = CmmnFn.fn_getCurrentDate();

            setData({
                siteId: siteId,
                cmpnySeq: cmpnySeq,
            })
            setStartDate(today);
            setEndDate(today);
            setDngrFctrDataList([]);
            setDngrFctrDataRsltList([]);

            // 상세
        } else {
            console.log('props.detailData: ', props.detailData);
            setData(props.detailData);
            setStartDate(props.detailData.raStartDate);
            setEndDate(props.detailData.raEndDate);
            restapi.retrieveRiskEvlFctrDtlDataList(props.detailData.riskAsmntId, props.siteId).then((rsltData) => {
                console.log('rsltData: ', rsltData);
                setDngrFctrDataList(rsltData);
                setDngrFctrDataRsltList(rsltData);
            })
        }


    }


    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const submit = (e: any) => {
        e.preventDefault()

    }

    const fn_datePopupOpen = () => {
        setIsDatePopupOpen(!isDatePopupOpen);
    };

    const fn_raDatePopupOpen = () => {
        setIsRaDatePopupOpen(!isRaDatePopupOpen);
    }


    const fn_registData = () => {

        console.log('data: ', data);
        console.log('dngrFctrDataRsltList: ', dngrFctrDataRsltList);



        if (!data) {
            toast.warn('데이터를 입력해 주십시오.');
            return;
        }

        if(!startDate){
            toast.warn('평가 시작일을 선택해 주십시오.');
            return;
        }
        if(!endDate){
            toast.warn('평가 종료일을 선택해 주십시오.');
            return;
        }

        if(!data.evlCntnts){
            toast.warn('평가 내용을 입력해 주십시오.');
            return;
        }

        if(!data.workTypeCd) {
            toast.warn('공종을 선택해 주십시오.');
            return;
        }

        if(!data.ocptCd){
            toast.warn('직종을 선택해 주십시오.');
            return;
        }

        if(dngrFctrDataRsltList.length == 0){
            toast.warn('위험성 평가 항목을 선택해 주십시오.');
            return;
        }

        let flag = false;
        let inData;

        for(let i=0; i<dngrFctrDataRsltList.length; i++){
            inData = dngrFctrDataRsltList[i];
            if(!inData.raStartDate || !inData.raEndDate){
                toast.warn('작업일을 선택해 주십시오');
                flag = true;
                break;
            }
            console.log('inData.raStartDate: ', inData.raStartDate);
            console.log('startDate: ', startDate);

            if(inData.raStartDate < startDate ||  inData.raStartDate > endDate || inData.raEndDate < startDate || inData.raEndDate > endDate){
                toast.warn('작업기간은 평가기간을 벗어날 수 없습니다.');
                flag = true;
                break;
            }
            if(!inData.wrkPlcCd){
                toast.warn('작업위치를 선택해 주십시오.');
                flag = true;
                break;
            }
            if(!inData.wrkrCnt){
                toast.warn('작업인원을 선택해 주십시오.');
                flag = true;
                break;
            }
            inData.riskAsmntCd = inData.code;

        }


        if(flag){
            return;
        }


        let param = data;
        param.raStartDate = startDate;
        param.raEndDate = endDate;
        param.rgstrSeq = user.userSeq;
        param.updtrSeq = user.userSeq;
        param.dngrFctrDataList = dngrFctrDataRsltList;



        if (!window.confirm('저장 하시겠습니까?')) {
            return;
        }

        axios.post('/admin/api/rskAsmnt/saveData', param).then((result) => {
                if (result.data > 0) {
                    toast.success(messageCodeDic['001'])
                    props.setModalIsOpen(false);
                } else {
                    toast.error(errorCodeDic['003']);
                }
            }
        )


    }

    const fn_delRow = (idx: number) => {

        // @ts-ignore
        let cstmDngrFctrDataList: any = [].concat(dngrFctrDataList);
        let cstmRsltDngrFctrDataList: any = [].concat(dngrFctrDataRsltList);
        // @ts-ignore

        cstmDngrFctrDataList.splice(idx, 1);
        cstmRsltDngrFctrDataList.splice(idx, 1);
        setDngrFctrDataList(cstmDngrFctrDataList);
        setDngrFctrDataRsltList(cstmRsltDngrFctrDataList);
    }


    const fn_openDngrEvlCrtPop = () => {
        if(!startDate || !endDate){
            toast.warn('평가기간을 선택해 주십시오.')
            return;
        }
        setIsDngrEvlCrtModalOpen(true);

    }

    const fn_updateDngrFctrDataRsltList = (e:any, rowCode: string) => {


        dngrFctrDataRsltList.map((data: any) => {
            if(data.code == rowCode){
                data[e.target.name] = e.target.value;
            }
        })

        setDngrFctrDataRsltList([].concat(dngrFctrDataRsltList))
    }


    return (
        <div>
            <Modal className="sh-modal" size="xl"
                   show={props.modalIsOpen}
                   onShow={fn_onAfterOpen}
                   onHide={fn_closeModal}

            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        위험성 평가 상세보기
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <div className="sh-modal-div under">
                        <div className="div-header">
                            작업허가서
                        </div>
                        <div className="div-body">
                            <Table className="mb-0">
                                <tbody>
                                <tr>
                                    <th>문서번호</th>
                                    <td></td>
                                    <th>업체</th>
                                    <td>{data?.cmpnyNm}</td>
                                    <th>공종</th>
                                    <td>{data?.workTypeNm}</td>
                                    <th>구분</th>
                                    <td>{data?.prcsStatNm}</td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>

                    <div className="sh-modal-div under">
                        <div className="div-header">
                            평가서
                        </div>
                    </div>

                    {dngrFctrDataRsltList.length > 0 ?
                        dngrFctrDataRsltList.map((fctrData: any, idx: number) => (
                            <Container className="border-1 rounded-2 py-2 px-3 mb-3">
                                <div className="px-0 border-bottom mb-2">
                                    <div>
                                        <div className="sh-font-SD d-flex justify-content-between align-items-end">
                                            <span className="sh-fs-b2 sh-text-accent sh-fw-5 sh-bd-menutitle" style={{paddingBottom:'5px'}}>{fctrData.workTypeNm}</span>
                                            <div className="sh-fs-b4 pb-1">
                                                <span className="me-2 sh-fw-5">| 작업단계</span>
                                                <span className="me-3 sh-text-font_secondary01">{fctrData.wrkLvl}</span>
                                                <span className="me-2 sh-fw-5">| 작업기간</span>
                                                <span className="sh-text-font_secondary01">{fctrData.raStartDate} ~ {fctrData.raEndDate}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="sh-fs-b2 sh-fw-5 mt-3 mb-2">위험성 평가</div>
                                <div>
                                    <Table>
                                        <colgroup>
                                            <col width="12.5%" />
                                            <col width="12.5%" />
                                            <col width="12.5%" />
                                            <col width="12.5%" />
                                            <col width="12.5%" />
                                            <col width="12.5%" />
                                            <col width="12.5%" />
                                            <col width="12.5%" />
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th className="text-center">피해형태</th>
                                                <td className="sh-text-font_secondary01">{fctrData.damageForm}</td>
                                                <th className="text-center">위험빈도</th>
                                                <td className="sh-text-font_secondary01">{fctrData.frequency}</td>
                                                <th className="text-center">위험강도</th>
                                                <td className="sh-text-font_secondary01">{fctrData.strength}</td>
                                                <th className="text-center">위험등급</th>
                                                <td className="sh-text-font_secondary01">{fctrData.frequency * fctrData.strength <= 3 ? '하' :
                                                    fctrData.frequency * fctrData.strength == 9 ? '상' :
                                                        '중'}</td>
                                            </tr>
                                            <tr>
                                                <th className="text-center">위험요인</th>
                                                <td colSpan={7} style={{color:'#ff5656'}}>
                                                    {fctrData.dngrFctr}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="text-center">관리방안</th>
                                                <td  className="sh-text-font_secondary01" colSpan={7}>
                                                    {fctrData.managementMethod}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Container>
                        ))
                        :
                        <div className="w-100 justify-content-center d-flex flex-column align-items-center py-2">
                            <img src={iconNodata} alt="이미지" style={{width: '70px'}} />
                            <span className="sh-text-font_secondary02 sh-fw-5 mt-2">데이터가 없습니다. 항목을 추가하세요.</span>
                        </div>
                    }


                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>취소
                        </button>
                        <button
                            className='sh-btn pill p' >수정
                        </button>
                    </div>
                </Modal.Footer>

            </Modal>
        </div>
    )
}

export default RskAsmntDetailModal;

