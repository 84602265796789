import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from "axios";
import Datatables, {getCheckedItems} from "../component/Datatables";
import Modal from 'react-modal';

import {MultiSelect} from '@grapecity/wijmo.react.input';
import {getLoginUser} from "../../helper/SecurityHelper";
import {toast} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";

import CmpnyInfoRgstModal from "../companyManagement/modal/CmpnyInfoRgstModal";
import MngrRgstModal from "./modal/MngrRgstModal";
import {ClipLoader} from 'react-spinners';
import {ProgressContext} from '../../context/Progress';
import SHSearch from '../component/SHSearch';
import {Col, Container, Table, Row} from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";
import SHIconButton from "../../components/common/SHIconButton";
import {CmmnFn} from "../../helper/CmmnHelper";
import {UserContext} from "../../context/User";
import SHInput from "../../components/common/SHInput";
import SHSelect from "../../components/common/SHSelect";

type Props = {};


Modal.setAppElement('#root');
const ManagerManagementPage = (props: Props) => {
    const [dataList, setDataList] = useState<any[]>([]);
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState(null);
    const [isSaveSuccess, setIsSaveSuccess] = useState(false);
    const defaultUser = getLoginUser();
    const chrgCodeRef = useRef<any>();
    const [chrgCodeList, setChrgCodeList] = useState<any>([]);
    const {spinner} = useContext(ProgressContext);
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const {user} = useContext<any>(UserContext);
    const [isCodeDataLoaded, setIsCodeDataLoaded] = useState<boolean>(false);

    useEffect(()=>{

        if(datatable){
            fn_retrieveSrchCond(user?.blngSiteId || defaultUser.blngSiteId);
            datatable.draw();
        }

    }, [user, datatable])

    useEffect(() => {
        if (isSaveSuccess || !modalIsOpen) {
            datatable?.draw();
        }
    }, [isSaveSuccess, modalIsOpen])

    useEffect(() => {
        if(isCodeDataLoaded){
            datatable?.draw();
        }

    }, [isCodeDataLoaded])


    const fn_retrieveSrchCond = (siteId: number) => {
        fn_retrieveSiteManageItemList(siteId).then((rlstData) => {
            setChrgCodeList(rlstData);
        })
    }


    const fn_delete = () => {
        let checkedData = getCheckedItems(datatable, "userSeq")

        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }

        if (!window.confirm(messageCodeDic['004'])) {
            return;
        }

        axios.post('/admin/api/user/deleteUserData', {delList: checkedData}).then(rsltData => {
                if (rsltData.data > 0) {
                    toast.success(messageCodeDic['002'])
                    datatable?.draw();
                } else {
                    toast.error(errorCodeDic['003'])
                }

            }
        ).catch(() => {
                toast.error(errorCodeDic['003'])
            }
        )
    }


    const fn_registPopOpen = () => {
        setDetailData(null);
        setModalIsOpen(true);
    }

    const fn_updateViewCnt = (param: object) => {
        axios.post('/admin/api/notice/updateViewCnt', param)
    }


    const fn_retrieveSiteManageItemList = (siteId: number) => {
        return new Promise(function (resolve, reject) {

            axios.post('/admin/api/manage/retrieveSiteManageItemList', {
                siteId: siteId,
                manageCode: 'M001003'
            }).then((result) => {
                resolve(result.data);
            })

        });
    }

    const fn_changeUserAuth = () => {
        let checkedData = getCheckedItems(datatable, "userSeq")
        if (checkedData.length == 0) {
            toast.warn('관리자를 선택해 주십시오');
            return;
        }

        if (!window.confirm('총 ' + checkedData.length + '명의 관리자를 근로자로 변경 하시겠습니까?')) {
            return;
        }

        axios.post('/admin/api/user/changeUserAuth', {userSeqList: checkedData, authCode:'siteWrkr'}).then(rsltData => {
            if (rsltData.data > 0) {
                toast.success(messageCodeDic['006'])
                datatable?.draw();
            } else {
                toast.error(errorCodeDic['003'])
            }
        }).catch(() => {
            toast.error(errorCodeDic['003'])
        })
    }


    return (
        <Container fluid className="bg-white px-4 pt-4 sh-text-font_primary">

            <SubMenuTitle title={'관리자 관리'} parentMenu={'인력 관리'}/>
            <MngrRgstModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen}
                           siteId={user?.blngSiteId || defaultUser.blngSiteId}
                           detailData={detailData} setIsSaveSuccess={setIsSaveSuccess}/>


            <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                <Row>
                    <Col>
                        <Table className="sh-font-SP sh-fs-b2 my-3">
                            <tbody>
                            <tr className="border-top border-bottom align-middle">

                                <th className="sh-bg-th">소속업체</th>
                                <td>
                                    <SHInput type={'hidden'} name={'authGrpId'} value={'SITE'}/>
                                    <SHInput type={'hidden'} name={'siteId'}
                                             value={user?.blngSiteId || defaultUser.blngSiteId}></SHInput>
                                    <SHSelect name={'blngCmpnySeq'} setIsCodeDataLoaded={setIsCodeDataLoaded}
                                              initOptionGb={'all'} codeGb={'siteCmpny'}
                                              selParam={{siteId: user?.blngSiteId || defaultUser.blngSiteId}}></SHSelect>

                                </td>
                                <th className="sh-bg-th">권한</th>
                                <td>
                                    <select className='form-select' name={'authCode'}>
                                        <option value=''>전체</option>
                                        <option value={'siteMstr'}>마스터</option>
                                        <option value={'siteCmmn'}>일반</option>
                                        <option value={'siteRead'}>읽기</option>
                                    </select>
                                </td>
                                <th className="sh-bg-th">직책</th>
                                <td>
                                    <select className='form-select' name={'chrgCode'} ref={chrgCodeRef}>
                                        <option value=''>전체</option>
                                        {chrgCodeList.map((data: any, idx: number) => (
                                            <option key={idx} value={data.manageSn}>{data.manageName}</option>
                                        ))}

                                    </select>
                                </td>
                                <th className="sh-bg-th">검색어</th>
                                <td>
                                    <input type={'text'} name={'srchTxt'} className='form-control'/>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </SHSearch>

            <div className={'w-100 d-flex justify-content-between'}>
                <div className='w-100 d-flex justify-content-left align-items-center mb-2'>
                    {defaultUser.authCode == 'siteMstr'
                        && <button className={'ml-5 sh-btn normal p cursor-pointer'}
                                   onClick={fn_changeUserAuth}>근로자로 변경
                        </button>
                    }
                </div>

                <div className='w-100 d-flex justify-content-end align-items-center mb-2'>
                    <SHIconButton kind={'submit'} marginRight={'5px'} onClick={fn_registPopOpen}>등록</SHIconButton>
                    <SHIconButton kind={'delete'} marginRight={'0'} onClick={fn_delete}>삭제</SHIconButton>
                </div>
            </div>

                <div className={'ge-result'}>
                    <Datatables
                        dataUrl={'/admin/api/user/retrieveMngrDataList'}
                        parameterFormRef={searchFormRef}
                        columns={[
                            {data: "userName", title: "성명"},
                            {data: "userId", title: "ID"},
                            {data: "userPhoneReg", title: "전화번호"},
                            {data: "companyName", title: "소속업체"},
                            {data: "authCodeNm", title: "권한"},
                            {data: "chrgNm", title: "직책"},
                        ]}
                        checked={true}
                        index={true}

                        onLoad={(dt: any) => {
                            setDatatable(dt)
                        }}
                        onClick={(data) => {
                            setDetailData(data)
                            setModalIsOpen(true);
                            fn_updateViewCnt(data);
                        }
                        }
                    />
                </div>
        </Container>


);
};

export default ManagerManagementPage;
