import React, {useContext, useEffect, useRef, useState} from 'react';
import Datatables, {getCheckedItems} from "../component/Datatables";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import SHSelect from "../../components/common/SHSelect";
import SHInput from "../../components/common/SHInput";
import CustomDatePickerRange from "../component/ui/DatePickerRange";
import SiteSearchModal from "../companyManagement/modal/SiteSearchModal";
import WrkPrmtRgstModal from "./modal/WrkPrmtRgstModal";
import SHSearch from '../component/SHSearch';
import SubMenuTitle from "../component/SubMenuTitle";
import {getLoginUser, IS_SM_SITE} from "../../helper/SecurityHelper";
import {Col, Container, Row, Table} from "react-bootstrap";
import SHIconButton from "../../components/common/SHIconButton";
import SHDatePicker from "../../components/common/SHDatePicker";
import WrkPrmtDetailModal from "./modal/WrkPrmtDetailModal";
import {CmmnFn} from "../../helper/CmmnHelper";
import {UserContext} from "../../context/User";
import WrkrRgstModal from "./modal/WrkrRgstModal";
import WrkPrmtProdRgstModal from "./modal/WrkPrmtProdRgstModal";
import ProdWrkrRgstModal from './modal/ProdWrkrRgstModal';
import restapi from "../../helper/restAPIHelper";
import {ProgressContext} from "../../context/Progress";
import ExcelFormUploadModal from "../headoffice/modal/ExcelFormUploadModal";
import WrkPrmtProdExcelUploadModal from "./modal/WrkPrmtProdExcelUploadModal";

type Props = {};

const WrkPrmtProdMngPage = (props: Props) => {
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [modalRgstIsOpen, setModalRgstIsOpen] = React.useState(false);
    const [wrkrModalIsOpen, setWrkrModalIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState<any>(null);
    const [isSaveSuccess, setIsSaveSuccess] = useState(false);
    const [isSitePopOpend, setIsSitePopOpend] = React.useState<boolean>(false);
    const [blngSiteList, setBlngSiteList] = useState<any[]>([]);
    const prodRef = useRef<any>();
    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [data, setData] = useState<any>({})
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const defaultUser = getLoginUser();
    const {user} = useContext<any>(UserContext);
    const [isCodeDataLoaded, setIsCodeDataLoaded] = useState<boolean>(false);
    const [isCopy, setIsCopy] = useState<boolean>(false);
    const [isExcelFormOpen, setIsExcelFormOpen] = useState<boolean>(false);
    const fileRef = useRef<any>();
    const {spinner} = useContext(ProgressContext);
    const [isExcelUploadModalOpen, setIsExcelUploadModalOpen] = useState<boolean>(false);

    useEffect(() => {

        if (datatable) {
            datatable.draw();
        }

    }, [user, datatable])

    useEffect(() => {
        if (!modalRgstIsOpen && !wrkrModalIsOpen && !isExcelUploadModalOpen) {
            setIsCopy(false);
            datatable?.draw();
        }
    }, [modalRgstIsOpen, wrkrModalIsOpen, isExcelUploadModalOpen])



    useEffect(() => {
        if(isCodeDataLoaded){
            datatable?.draw();
        }

    }, [isCodeDataLoaded])


    const fn_search = () => {
        datatable.draw();
    }

    /**
     * 등록 버튼 클릭
     */
    const fn_registPopOpen = () => {


        setDetailData(null)
        setModalRgstIsOpen(true);
    }

    const fn_delete = () => {

        var checkedData = getCheckedItems(datatable);
        let delList: number[] = [];

        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }

        checkedData.map((data: any) => {
            delList.push((data.wrkPrmtProdId));
        })

        console.log('delList: ', delList);

        if (!window.confirm(messageCodeDic['004'])) {
            return;
        }

        axios.post('/admin/api/wrkPrmtProd/deleteWrkPrmtProdData', {delList: delList}).then(rsltData => {
                if (rsltData.data > 0) {
                    toast.success(messageCodeDic['002'])
                    fn_search();
                } else {
                    toast.error(errorCodeDic['003'])
                }

            }
        ).catch(() => {
                toast.error(errorCodeDic['003'])
            }
        )
    }


    const fn_datePopupOpen = () => {
        setIsDatePopupOpen(!isDatePopupOpen);
    };

    const fn_setFormData = (e: any) => {

        setData({
            ...data,
            [e.target.name]: e.target.value
        })

    }

    const fn_onChangeToAllVal = (e: any) => {
        const checked = e.target.checked;

        if (e.target.checked) {
        }

    }

    const fn_print = (e: any) =>{
        let param: any ={
            wrkPrmtId:e.wrkPrmtId,
            wrkSiteNm: e.wrkSiteNm, //현장명
            wrkSiteDtl:e.wrkSiteDtl, //고종
            wrkTypeNm:e.wrkTypeNm,
            companyName:e.companyName, //업체
            wrkStrtDt:e.wrkStrtDt,
            wrkrCnt:e.wrkrCnt,
            wrkCmpltDt:e.wrkCmpltDt,
            istrctns:e.istrctns,
            cmplntNm:e.cmplntNm,
            cmplntPhon:e.cmplntPhon,

        }

        restapi.retrieveWrkPrmtApprInfo({wrkPrmtId: e.wrkPrmtId}).then((rsltData: any) => {
            const apprUserDataList: any = rsltData.apprUserDataList;
            let apprData;
            let siteChrgNm;
            let userName;
            let apprStatNm;
            let prcsDt;
            if(apprUserDataList.length > 0){
                let maxLength = apprUserDataList.length;
                if(maxLength > 6){
                    maxLength = 6;
                }

                for(var i= 0; i < maxLength; i++){
                    apprData = apprUserDataList[i];
                    siteChrgNm = apprData.siteChrgNm;
                    userName = apprData.userName;
                    apprStatNm = apprData.apprStatNm;
                    prcsDt = apprData.prcsDt;
                    param['siteChrgNm' + (i+1)] = siteChrgNm;
                    param['userName' + (i+1)] = userName;
                    param['apprStatNm' + (i+1)] = apprStatNm;
                    param['prcsDt' + (i+1)] = prcsDt;
                }

            }

            axios.post('/admin/api/wrkPrmt/createWrkIstrctPrint', param).then(rsltData => {
                    console.log('rsltData: ', rsltData);
                    window.open(process.env.REACT_APP_FILE_URL + "/shss-data/fileDownloadByFileName?fileName="+rsltData.data, "_blank")
                }
            ).catch(() => {
                    toast.error(errorCodeDic['003'])
                }
            )

        })

    }

    const fn_copy = () => {
        var checkedData = getCheckedItems(datatable);

        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['007']);
            return;
        }

        if (checkedData.length > 1) {
            let msg = '';
            if(CmmnFn.isSmSite()) {
                msg = '작업지시서';
            }else{
                msg = '작업허가서'
            }
            toast.warn('하나의 '+ msg +'만 선택해 주십시오.');
            return;
        }
        console.log('checkedData: ', checkedData);

        checkedData[0].prcsStatCd = null;
        checkedData[0].prcsStatNm = null
        checkedData[0].rgstDt = null;
        checkedData[0].rgstrNm = null;
        checkedData[0].rgstrSeq = null;
        checkedData[0].wrkStrtDt = CmmnFn.fn_getCurrentDate();
        checkedData[0].wrkStatNm = null;
        setIsCopy(true);
        setDetailData(checkedData[0]);
        setModalRgstIsOpen(true);
    }


    return (
        <Container fluid className="bg-white px-4 pt-4 sh-text-font_primary">
            <WrkPrmtProdExcelUploadModal setModalIsOpen={setIsExcelUploadModalOpen} modalIsOpen={isExcelUploadModalOpen} />
            <ExcelFormUploadModal formCd={'D002004'} isReadOnly={true} setModalIsOpen={setIsExcelFormOpen} modalIsOpen={isExcelFormOpen} />
            <SubMenuTitle title={'작업지시서 등록'} parentMenu={'현장안전관리'}/>
            <SiteSearchModal setModalIsOpen={setIsSitePopOpend} modalIsOpen={isSitePopOpend}
                             blngSiteList={blngSiteList}
                             setBlngSiteList={setBlngSiteList} isSingleCheck={true} siteNm={data?.siteName}/>
            <WrkPrmtProdRgstModal setModalIsOpen={setModalRgstIsOpen} modalIsOpen={modalRgstIsOpen}
                                  siteId={user?.blngSiteId || defaultUser.blngSiteId}
                                  detailData={detailData} isCopy={isCopy}/>
            <ProdWrkrRgstModal setModalIsOpen={setWrkrModalIsOpen} modalIsOpen={wrkrModalIsOpen}
                               siteId={user?.blngSiteId || defaultUser.blngSiteId}
                               detailData={detailData}></ProdWrkrRgstModal>
                <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                    <Row>
                        <Col>
                            <Table className="sh-font-SP sh-fs-b2 my-3">
                                <tbody>
                                <tr className="border-top align-middle border-bottom">
                                    <th className="sh-bg-th">업체명</th>
                                    <td>
                                        <SHInput type={'hidden'} name={'siteId'}
                                                 value={user?.blngSiteId || defaultUser.blngSiteId}></SHInput>
                                        <SHSelect name={'cmpnySeq'} onChange={fn_setFormData} value={data.cmpnySeq}
                                                  setIsCodeDataLoaded={setIsCodeDataLoaded} initOptionGb={'all'}
                                                  codeGb={'siteCmpny'}
                                                  selParam={{siteId: user?.blngSiteId || defaultUser.blngSiteId}}></SHSelect>

                                    </td>
                                    <th className="sh-bg-th">공종</th>
                                    <td>
                                        <SHSelect className={'form-select'} initOptionGb={'all'} value={data?.sectorCd}
                                                  onChange={fn_setFormData} name={'sectorCd'}
                                                  codeGrpId={'C002'}></SHSelect>
                                    </td>
                                    <th className="sh-bg-th">상태</th>
                                    <td colSpan={2}>
                                        <SHSelect className={'form-select'} initOptionGb={'all'} name={'prcsGbCd'}
                                                  onChange={fn_setFormData} codeGrpId={'S001'}></SHSelect>
                                    </td>
                                </tr>
                                <tr className="align-middle border-bottom">
                                    <th className="sh-bg-th">기간</th>
                                    <td colSpan={2}>
                                        <div className="d-flex align-items-center w-100" ref={prodRef}>
                                            <SHDatePicker
                                                className={"text-center"} readOnly={true}
                                                value={startDate} name={'strtDt'} onClick={fn_datePopupOpen}
                                                onChange={fn_setFormData}
                                                calendar={fn_datePopupOpen}
                                            />
                                            <span>&nbsp;~&nbsp;</span>
                                            <SHDatePicker readOnly={true}
                                                          className={"text-center"}
                                                          value={endDate} name={'endDt'} onClick={fn_datePopupOpen}
                                                          onChange={fn_setFormData}
                                                          calendar={fn_datePopupOpen}
                                            />

                                            {isDatePopupOpen
                                                ? <CustomDatePickerRange setIsDatePopupOpen={setIsDatePopupOpen}
                                                                         prodRef={prodRef}
                                                                         setStartDateView={setStartDate}
                                                                         setEndDateView={setEndDate}/>
                                                : null}
                                        </div>
                                    </td>
                                    <td colSpan={6}>
                                        <div className="d-flex align-items-center">
                                            <div className={'form-check w-25'}>
                                                <input className="form-check-input sh-check" id={'reception_toAll'}
                                                       onChange={fn_onChangeToAllVal} type="checkbox"/>
                                                <label className={'form-check-label text-black'}
                                                       htmlFor={'reception_toAll'}>전체기간</label>
                                            </div>
                                            <SHInput className={'form-control w-100'} name={'srchTxt'}
                                                     onChange={fn_setFormData}/>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </SHSearch>


                <div className={'w-100 d-flex justify-content-between'}>
                    <div className='w-100 d-flex justify-content-left align-items-center mb-2'>
                        <label
                            // htmlFor="input-file"
                               className={'sh-btn normal p cursor-pointer'} onClick={(e: any) => {
                            setIsExcelUploadModalOpen(true);
                            //console.log('fileRef: ', fileRef.current.value);
                            //fileRef.current.value = null
                        }}> 엑셀 업로드
                        </label>
                        <button className={'ml-5 sh-btn normal p cursor-pointer'}
                                onClick={() => setIsExcelFormOpen(true)}>업로드 양식 다운로드
                        </button>

                    </div>
                    <div className='w-100 d-flex justify-content-end align-items-center mb-2'>
                        <SHIconButton kind={'submit'} marginRight={'5px'} onClick={fn_registPopOpen}> 등록
                        </SHIconButton>
                        <SHIconButton kind={'delete'} marginRight={'5px'} onClick={fn_delete}> 삭제
                        </SHIconButton>
                        <SHIconButton kind={'copy'} marginRight={'0'} onClick={fn_copy}> 복사
                        </SHIconButton>
                    </div>
                </div>
                <div className={'ge-result'}>
                    {
                        <Datatables
                            dataUrl={'/admin/api/wrkPrmtProd/retrieveWrkPrmtProdDataList'}
                            parameterFormRef={searchFormRef}
                            columns={
                                CmmnFn.isSmSite() ?
                                    [
                                        {
                                            data: "prcsStatNm",
                                            title: "상태",
                                            textAlign: 'center',
                                            render: (data: any) => {
                                                switch (data) {
                                                    case '작성중':
                                                        return "<div class='sh-box-status writing'>작성중</div>";
                                                    case '결재요청':
                                                        return "<div class='sh-box-status request'>결재요청</div>";
                                                    case '결재중':
                                                        return "<div class='sh-box-status approval'>결재중</div>";
                                                    case '결재완료':
                                                        return "<div class='sh-box-status approvaldone'>결재완료</div>";
                                                    case '반려':
                                                        return "<div class='sh-box-status refuse'>반려</div>";
                                                    default:
                                                        return "";
                                                }
                                            }
                                        },
                                        {
                                            data: "",
                                            title: '작업완료상태',
                                            render: function (data: any, type: any, row: any, meta: any) {
                                                if (row.wrkCmpltDt) {
                                                    return "<div class='sh-box-status approvaldone'>작업완료</div>";
                                                } else {
                                                    return "<div class='sh-box-status refuse'>미완료</div>";
                                                }
                                            }
                                        },
                                        {data: "wrkSiteNm", title: "현장명", textAlign: 'center'},
                                        {data: "companyName", title: "업체", textAlign: 'center'},
                                        {data: "istrctns", title: '작업지시사항'},
                                        {data: "istrctnsDtl", title: '작업지시사항 상세'},
                                        {data: "wrkTypeNm", title: "공종", textAlign: 'center'},
                                        {data: "wrkrCnt", title: "작업인원", textAlign: 'center'},
                                        {data: "wrkStrtDt", title: "작업지시일", textAlign: 'center'},
                                        {data: "wrkCmpltDt", title: "작업 완료일", textAlign: 'center'},
                                        {
                                            title: "기능",
                                            type: "button",
                                            className: "text-center",
                                            buttons: [{
                                                label: "<img style='height: 20px' class='cursor-pointer' src='/icon_print.svg' />",
                                                onClick: (v: any) => {
                                                    fn_print(v);
                                                },
                                            }, {
                                                label: "<img style='height: 20px' class='cursor-pointer' src='/icon_user_plus.svg' />",
                                                onClick: (v: any) => {

                                                    setDetailData(v);
                                                    setWrkrModalIsOpen(true);
                                                    console.log(2, v)

                                                },
                                            }],
                                        },

                                    ]
                                    : [
                                        {
                                            data: "prcsStatNm",
                                            title: "상태",
                                            textAlign: 'center',
                                            render: (data: any) => {
                                                switch (data) {
                                                    case '작성중':
                                                        return "<div class='sh-box-status writing'>작성중</div>";
                                                    case '결재요청':
                                                        return "<div class='sh-box-status request'>결재요청</div>";
                                                    case '결재중':
                                                        return "<div class='sh-box-status approval'>결재중</div>";
                                                    case '결재완료':
                                                        return "<div class='sh-box-status approvaldone'>결재완료</div>";
                                                    case '반려':
                                                        return "<div class='sh-box-status refuse'>반려</div>";
                                                    default:
                                                        return "";
                                                }
                                            }
                                        },
                                        {data: "companyName", title: "업체", textAlign: 'center'},
                                        {data: "wrkTypeNm", title: "공종", textAlign: 'center'},
                                        {data: "wrkrCnt", title: "작업인원", textAlign: 'center'},
                                        {data: "wrkStrtDt", title: "작업지시일", textAlign: 'center'},
                                        {data: "wrkTimeGbNm", title: "작업시간", textAlign: 'center'},
                                        {
                                            title: "기능",
                                            type: "button",
                                            className: "text-center",
                                            buttons: [{
                                                label: "<img style='height: 20px' class='cursor-pointer' src='/icon_print.svg' />",
                                                onClick: (v: any) => {
                                                    console.log(1, v)
                                                },
                                            }, {
                                                label: "<img style='height: 20px' class='cursor-pointer' src='/icon_user_plus.svg' />",
                                                onClick: (v: any) => {

                                                    setDetailData(v);
                                                    setWrkrModalIsOpen(true);
                                                    console.log(2, v)


                                                },
                                            }],
                                        },

                                    ]
                            }
                            checked={true}
                            index={true}

                            onLoad={(dt: any) => {
                                setDatatable(dt)
                            }}
                            onClick={(data) => {
                                setDetailData(data)
                                setModalRgstIsOpen(true);
                                //setModalIsOpen(true);
                            }
                            }
                        />}
                </div>
        </Container>
    );
};

export default WrkPrmtProdMngPage;
