import React, {useEffect, useRef, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {Doughnut} from "react-chartjs-2";
import restapi from "../../helper/restAPIHelper";
import {CmmnFn} from "../../helper/CmmnHelper";
import WrkPrmtInfoListModal from "./modal/WrkPrmtInfoListModal";

const HOCRTBMStatus = () => {
    const [totTbmCnt, setTotTbmCnt] = useState<number>(0);
    const [wrkPrmtList, setWrkPrmtList] = useState<any>([]);
    const [siteDataList, setSiteDataList] = useState<any>([]);
    const [smSiteDataList, setSmSiteDataList] = useState<any>([]);
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [dataList, setDataList] = useState<any>([]);
    const timerRef = useRef<any>();

    const [siteData, setSiteData] = useState<any>({
        labels: ['수행', '미수행'],
        datasets: [
            {
                data: [10, 9],
                backgroundColor: [
                    '#4A3AFF',
                    '#93AAFD',
                ],
                borderColor: [
                    'rgba(30, 31, 35, 1)',
                    'rgba(30, 31, 35, 1)',
                ],
                borderWidth: 1,
                hoverOffset: 5,
            },
        ],
    })

    const [smallsiteData, setSmallsiteData] = useState<any>({
        labels: ['수행', '미수행'],
        datasets: [
            {
                data: [15, 4],
                backgroundColor: [
                    '#4A3AFF',
                    '#93AAFD',
                ],
                borderColor: [
                    'rgba(30, 31, 35, 1)',
                    'rgba(30, 31, 35, 1)',
                ],
                borderWidth: 1,
                hoverOffset: 5,
            },
        ],
    })

    const isSiteData = siteDataList && siteDataList.length > 0;
    const isSmSiteDataList = smSiteDataList && smSiteDataList.length > 0;

    const siteDataOption = {
        tooltips: {
            enabled: isSiteData
        },
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: isSiteData
            }
        },
        rotation: -90,
        circumference: 180,
        cutout: '70%',
    };

    const smallsiteDataOption = {
        tooltips: {
            enabled: isSmSiteDataList
        },
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: isSmSiteDataList
            }
        },
        rotation: -90,
        circumference: 180,
        cutout: '70%',
    };

    useEffect(() => {
        fn_retrieveData();
        timerRef.current = setInterval(fn_retrieveData, 5000);

        return () => {
            clearInterval(timerRef.current);
        };
    }, []);


    const fn_retrieveData = () => {
        restapi.retrieveWrkPrmtDataTotList({wrkDt: CmmnFn.fn_getCurrentDate()}).then((rsltData: any) => {
            setWrkPrmtList(rsltData);
            console.log('wrkPrmt rsltData: ', rsltData);

            const siteDataList = rsltData.filter((data: any) => (!data.isSmSite));
            const smSiteDataList = rsltData.filter((data: any) => (data.isSmSite));
            const cmpltdSiteDataList = siteDataList.filter((data: any) => (data.isWrkCmpltd));
            const cmpltdSmSiteDataList  = smSiteDataList.filter((data: any) => (data.isWrkCmpltd));

            setSiteDataList(siteDataList);
            setSmSiteDataList(smSiteDataList);

            setSiteData({
                labels: ['수행', '미수행'],
                datasets: [
                    {
                        data: [cmpltdSiteDataList.length, siteDataList.length - cmpltdSiteDataList.length],
                        backgroundColor: [
                            '#4A3AFF',
                            '#93AAFD',
                        ],
                        borderColor: [
                            'rgba(30, 31, 35, 1)',
                            'rgba(30, 31, 35, 1)',
                        ],
                        borderWidth: 1,
                        hoverOffset: 5,
                    },
                ],
            });

            setSmallsiteData({
                labels: ['수행', '미수행'],
                datasets: [
                    {
                        data: [cmpltdSmSiteDataList.length, smSiteDataList.length - cmpltdSmSiteDataList.length],
                        backgroundColor: [
                            '#4A3AFF',
                            '#93AAFD',
                        ],
                        borderColor: [
                            'rgba(30, 31, 35, 1)',
                            'rgba(30, 31, 35, 1)',
                        ],
                        borderWidth: 1,
                        hoverOffset: 5,
                    },
                ],
            })


        });
    }

    const fn_openWrkPrmtInfoListModal = (srchGb: string) => {
        if(srchGb == 'tot'){
            setDataList(wrkPrmtList);
        }else if(srchGb == 'site'){
            setDataList(siteDataList)
        }else if(srchGb == 'smSite'){
            setDataList(smSiteDataList)
        }

        setModalIsOpen(true);
    }

    return(
        <>
            <WrkPrmtInfoListModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} dataList={dataList} />
            <Container fluid className="sh-bg-pane rounded-[5px] py-2 px-3 mb-3">
                <Row className="h-100">
                    <Col>
                        <div className="d-flex flex-column justify-content-between h-100">
                            <div>
                                <div className="pe-2 sh-fs-h4 sh-fw-5 sh-font-SD mb-2">TBM 현황</div>
                                <Row>
                                    <Col bsPrefix="col-4">
                                        <div onClick={() => {fn_openWrkPrmtInfoListModal('tot')}} className="rounded-3 h-100 p-2 cursor-pointer" style={{backgroundColor: '#5685F7'}}>
                                            <div>총</div>
                                            <div className="text-end"><span className="sh-fs-h3 sh-fw-6 me-1">{wrkPrmtList.length}</span>건</div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div onClick={() => {fn_openWrkPrmtInfoListModal('site')}} className="cursor-pointer d-flex position-relative justify-content-center" style={{marginTop: '-30px', width: '48%'}} >
                                                <Doughnut className="position-relative z-50" options={siteDataOption}
                                                          data={siteData} width="50%"/>
                                                <div className="position-absolute w-100 text-center z-10 left-0" style={{bottom:"25px"}}>
                                                    <div className="sh-fs-b2 sh-lh-h3">건설현장</div>
                                                </div>
                                            </div>
                                            <div onClick={() => {fn_openWrkPrmtInfoListModal('smSite')}} className="cursor-pointer d-flex position-relative justify-content-center w-50" style={{marginTop: '-30px', width: '48%'}} >
                                                <Doughnut className="position-relative z-50" options={smallsiteDataOption}
                                                          data={smallsiteData} width="50%"/>
                                                <div className="position-absolute w-100 text-center z-10 left-0" style={{bottom:"25px"}}>
                                                    <div className="sh-fs-b2 sh-lh-h3">단가보수현장</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center sh-fs-b4 w-100" style={{marginTop: '-20px'}} >
                                            <div className={'cursor-pointer'} onClick={() => null}>
                                                <span style={{color: '#4A3AFF'}}>●</span>
                                                <span className="ps-1">수행</span>
                                                <span className="ps-2" style={{color: '#93AAFD'}}>●</span>
                                                <span className="ps-1">미수행</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default HOCRTBMStatus;
