import React from "react";
import validator from "validator";
import {toast} from "react-toastify";
const $ = require("jquery")
export var NUMBER = 1;
export function validFormData(form: React.RefObject<HTMLFormElement>, option?: any, param?: any){
    const elem: any = form.current;
    const obj = new FormData(elem);
    if(option){
        var validOpt = option.validOpt
        if(validOpt){
            for(var key in validOpt){
                var message = validOpt[key]
                console.log(key, message)
                var value = obj.get(key)
                // @ts-ignore
                if(value && validator.trim(value) !== ''){
                    continue
                }else{
                    toast.warn(message)
                    return null
                }
            }
        }
        var castType = option.castType
        if(castType){
            for(var key in castType){
                var type = castType[key]
                var value = obj.get(key)
                if(value){
                    if(type === NUMBER){
                        // @ts-ignore
                        value = value.replace(/,/gi, "");
                        // @ts-ignore
                        obj.set(key,+value)
                    }
                }
            }
        }
        var addValues = option.addValues
        if(addValues){
            for(var key in addValues){
                var v = addValues[key]
                if(v){
                    obj.set(key,v)
                }
            }
        }
        var files = option.files;

        if(files){
            for(var i=0; i<files.length; i++){
                console.log('files: ', files);
                var fileNm = files[i];
                var value = obj.get(fileNm);
                console.log('value: ', value);
                if(value){
                    // @ts-ignore
                    if(value.type == "application/octet-stream"){
                        obj.delete(fileNm)
                    }
                }
            }
        }
    }
    if(param){
        var _param = toJsonFormData(obj);
        $.extend(param,_param)
    }
    return obj;
}
export function toJsonFormData(formData: any){
    var param : any = {}
    formData.forEach((value : any, key : any)=>{
        if(value.type === undefined){
            if(param[key]){
                var check = param[key]
                if(check instanceof Array){
                    param[key].push(value)
                }else{
                    param[key] = [check]
                    param[key].push(value)
                }
            }else{
                param[key] = value
            }
        }
    })
    return param

}

export function toValidJsonFormData(form: React.RefObject<HTMLFormElement>, option?: any){
    const obj = validFormData(form, option);
    return toJsonFormData(obj);
}
export function readonlyForm(form: React.RefObject<HTMLFormElement>, option?: any){
    const elem: any = form.current;
    $(elem).find("input, select, textarea").attr("readonly","readonly")
}

export function disableForm(form: React.RefObject<HTMLFormElement>, option?: any){
    const elem: any = form.current;
    $(elem).find("input, select, textarea").attr("disabled","disabled");

}
