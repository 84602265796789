import React from "react";
import {Col, Container, Row} from "react-bootstrap";

export default function SubMenuTitle({title, parentMenu}:any) {
    return(
        <Container fluid>
            <Row className="mb-4">
                <Col className="px-0 border-bottom pb-2">
                    <div>
                        <span className="sh-fs-h3 sh-font-SD sh-text-accent sh-fw-6 me-1 sh-bd-menutitle">{title}</span>
                        {parentMenu &&
                            <><span className="sh-font-SD mx-2 sh-text-font_secondary01">/</span>
                            <span className="sh-font-SD sh-text-font_secondary01">{parentMenu}</span></>
                    }

                </div>
            </Col>
        </Row>
</Container>
)
    ;
}
