import api from "../api/api";
export const USER_SESSION = 'USER_SESSION';
export const IS_SM_SITE = 'IS_SM_SITE';
export const MENU = 'MENU';
export const UNMNG_SCTR_GB_LIST = ['007', '008', '009', '010', '011', '014', '015'];

export const INIT_PSWRD = '12';

export function findByPhoneOrUserId(phoneOrUserId: string,callback:any){
    api.findByPhoneOrUserId(phoneOrUserId).then((r:boolean) => {
        callback(r)
    }).catch(console.error)
}
export async function login(userId: any, userPassword: any, uuid: any, callback: any) {
    await api.login(userId,userPassword,uuid).then((rsltData) => {
        callback(rsltData)
    }).catch(console.error);
}
export function logout(){
    // eslint-disable-next-line no-restricted-globals
    if(confirm("로그아웃 하시겠습니까?")){
        localStorage.removeItem(USER_SESSION);
        localStorage.removeItem(MENU);
        window.location.href = "/login"
    }
}
export function getLoginUser(){
    const objString = localStorage.getItem(USER_SESSION);
    if(!objString) {
        return null;
    }
    const obj = JSON.parse(objString);
    if(Date.now() > obj.expire) {
        alert("로그인 기간이 만료되었습니다.");
        window.localStorage.removeItem(USER_SESSION);
        window.location.replace("/login");
        return null;
    }
    //console.log(4433,obj, obj.value)
    return obj.value;
}


export function retrieveUserInfoDtl(userId:string,callback:any){
    api.retrieveUserInfoDtl(userId).then((user) => {
        callback(user)
    }).catch(console.error);
}

export function setItemWithExpireTime(keyName:string, keyValue:any) {
    const obj = {
        value : keyValue,
        expire : Date.now() + (1440*1000*60) // 1일
    }
    const objString = JSON.stringify(obj);
    localStorage.setItem(keyName, objString);
}

export function getItemWithExpireTime(keyName:string) {
    const objString = localStorage.getItem(keyName);
    if(!objString) {
        return null;
    }
    const obj = JSON.parse(objString);
    if(Date.now() > obj.expire) {
        window.localStorage.removeItem(keyName);
        return null;
    }
    return obj.value;
}
