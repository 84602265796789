import React, {useContext, useEffect, useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import {ReactComponent as IconRight} from "../../assets/images/ico/icon-right.svg";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from "moment";
import IconPrev from '../../images/icon_circle_left.png';
import IconNext from '../../images/icon_circle_right.png';
import restapi from "../../helper/restAPIHelper";
import {TopMenuContext} from "../../context/TopMenu";
import SftyChkInfoListModal from "./modal/SftyChkInfoListModal";

export default function HOMonthlyManagementCalendar() {

    const [dataList, setDataList] = useState<any>([])
    const {setMenu} = useContext<any>(TopMenuContext);
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [sftyChkDataList, setSftyChkDataList] = useState<any>([]);
    const [sftyChkDt, setSftyChkDt] = useState<string>('');

    useEffect(() => {
        restapi.retrieveSftChkSchdleDataList({}).then((rsltData: any) => {
            setDataList(rsltData);
        })
    }, [])

    const fn_onDayClick = (e: any) => {
        const sftyChkDt = moment(e).format("YYYY-MM-DD");


        restapi.retrieveSiteSftyManageDataTotList({sftyChkDt: sftyChkDt}).then((rsltData: any) => {
            setSftyChkDt(sftyChkDt);
            setSftyChkDataList(rsltData);
            setModalIsOpen(true);

        })

    }

    const fn_allDayClick = () => {
        restapi.retrieveSiteSftyManageDataTotList({}).then((rsltData: any) => {
            setSftyChkDt('전체');
            setSftyChkDataList(rsltData);
            setModalIsOpen(true);

        })
    }

    return (
        <>
            <SftyChkInfoListModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} dataList={sftyChkDataList} sftyChkDt={sftyChkDt} ></SftyChkInfoListModal>

            <Container className="sh-bg-pane rounded-[5px] py-2 px-3 mb-3">
                <Row className="px-0 mb-2">
                    <Col bsPrefix="col-7" className="d-flex align-items-center w-100">
                        <div className="w-100 d-flex align-items-center justify-content-between">
                            <span className="pe-2 sh-fs-h4 sh-fw-5 sh-font-SD">월간 안전 점검 관리 일정</span>
                            <div className={'cursor-pointer'} onClick={() => fn_allDayClick()}>
                            <IconRight style={{width: '20px', marginBottom: '1px'}}/>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="p-0">
                    <Col>
                        <Calendar
                            defaultView="month"
                            formatDay={(locale, date) => moment(date).format("D")}
                            tileContent={({date, view}) => {

                                let html = [];
                                const filtrdDataList = dataList.filter((data: any) => (data.sftyChkDt == moment(date).format("YYYY-MM-DD")));
                                if(filtrdDataList.length > 0){
                                    if(filtrdDataList[0].sftyChkDt == moment(date).format("YYYY-MM-DD")){
                                        html.push(<div key={0} className="d-flex justify-content-between align-items-center">
                                            <div className="sh-text-accent">●</div>
                                            <div className="sh-text-white">+{filtrdDataList[0].sftyChkCnt}</div>
                                        </div>);
                                    }
                                }

                                return (
                                        <div key={1}>{html}</div>
                                );
                            }}
                            onClickDay={fn_onDayClick}
                            next2Label={null}
                            prev2Label={null}
                            nextLabel={<img style={{width: '24px'}} src={IconNext} alt="다음"/>}
                            prevLabel={<img style={{width: '24px'}} src={IconPrev} alt="이전"/>}
                        />
                    </Col>
                </Row>
            </Container>
        </>
    );
}
