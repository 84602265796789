import React, {useEffect, useRef, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {ReactComponent as IconStop} from "../../images/icon_prohibit.svg";
import {ReactComponent as IconSiren} from "../../images/icon_siren.svg";
import restapi from "../../helper/restAPIHelper";
import {CmmnFn} from "../../helper/CmmnHelper";
import WrkStopInfoListModal from "./modal/WrkStopInfoListModal";
import SosInfoListModal from "./modal/SosInfoListModal";
import DngrTrfcLgndModal from "../home/modal/DngrTrfcLgndModal";

const HOCRTrafficLight = () => {

    const [sosDataList, setSosDataList] = useState<any>([]);
    const [wrkStopDataList, setWrkStopDataList] = useState<any>([]);
    const [wrkStopModalIsOpen, setWrkStopModalIsOpen] = useState<boolean>(false);
    const [sosModalIsOpen, setSosModalIsOpen] = useState<boolean>(false);
    const timerRef = useRef<any>();
    const [sosStat, setSosStat] = useState<any>({clear: 0, total: 0});
    const [wrkStopStat, setWrkStopStat] = useState<any>({clear: 0, total: 0});
    const [sosfltrdDataList, setSosfltrdDataList] = useState<any>([]);
    const [wrkStopFltrdDataList, setWrkStopFltrdDataList] = useState<any>([]);
    const [isLgndModalOpen, setIsLgndModalOpen] = useState<boolean>(false);

    useEffect(() => {
        fn_retrieveData();
        timerRef.current = setInterval(fn_retrieveData, 5000);

        return () => {
            clearInterval(timerRef.current);
        };
    }, []);

    const fn_retrieveData = () => {
        restapi.retrieveSosHisTotDataList({rgstDt: CmmnFn.fn_getCurrentDate()}).then((rsltData: any) => {
            setSosStat({clear: rsltData.filter((data: any) => (['P001002','P001003'].includes(data.statCd))).length, total: rsltData.length})
            setSosDataList(rsltData);
        })

        restapi.retrieveWrkDisruptHisTotDataList({rgstDt: CmmnFn.fn_getCurrentDate()}).then((rsltData: any) => {
            setWrkStopStat({clear: rsltData.filter((data: any) => (['P001002','P001003'].includes(data.statCd))).length, total: rsltData.length})
            setWrkStopDataList(rsltData);
        })
    }


    const fn_openSosModal = (statCd?:string) => {
        let cstmSosDataList = sosDataList;
        if(statCd){
            cstmSosDataList = cstmSosDataList.filter((data: any) => (['P001002','P001003'].includes(data.statCd)));
        }

        setSosfltrdDataList(cstmSosDataList);

        setSosModalIsOpen(true);
    }
    const fn_openWrkStopInfoListModal = (statCd?:string) => {
        let cstmWrkStopDataList = wrkStopDataList;
        if(statCd){
            cstmWrkStopDataList = cstmWrkStopDataList.filter((data: any) => (['P001002','P001003'].includes(data.statCd)));
        }
        setWrkStopFltrdDataList(cstmWrkStopDataList);

        setWrkStopModalIsOpen(true);
    }

    return(
        <>
            <DngrTrfcLgndModal setModalIsOpen={setIsLgndModalOpen} modalIsOpen={isLgndModalOpen} />
            <WrkStopInfoListModal setModalIsOpen={setWrkStopModalIsOpen} modalIsOpen={wrkStopModalIsOpen} dataList={wrkStopFltrdDataList} />
            <SosInfoListModal setModalIsOpen={setSosModalIsOpen} modalIsOpen={sosModalIsOpen} dataList={sosfltrdDataList} />
            <Container fluid className="sh-bg-pane rounded-[5px] py-2 px-3 mb-3">
                <Row className="h-100">
                    <Col>
                        <div className="d-flex flex-column justify-content-between h-100">
                            <div>
                                <div className="pe-2 sh-fs-h4 sh-fw-5 sh-font-SD mb-2">위험 신호등</div>
                                <div className="d-flex justify-content-center mb-2">
                                    <div className="rounded-pill d-flex justify-content-between align-items-center"
                                         style={{
                                             border: '4px solid #0f0f0f',
                                             backgroundColor: '#212121',
                                             width: '70%',
                                             height: '8vh'
                                         }}>
                                        <div
                                            className="ms-2 rounded-circle d-flex justify-content-center align-items-center"
                                            style={{width: '65px', height: '65px', border: '4px solid #928e8e'}}>
                                            <div className="rounded-circle" style={{
                                                width: '50px',
                                                height: '50px',
                                                backgroundColor: ((wrkStopStat.total - wrkStopStat.clear) > 0 || (sosStat.total - sosStat.clear) > 0) ? '#E0E0E0' : '#12E573',
                                                boxShadow: 'inset 0 10px 0 rgba(0,0,0,0.1)'
                                            }}/>
                                        </div>
                                        <div className="rounded-circle d-flex justify-content-center align-items-center"
                                             style={{width: '65px', height: '65px', border: '4px solid #928e8e'}}>
                                            <div className="rounded-circle" style={{
                                                width: '50px',
                                                height: '50px',
                                                backgroundColor: (wrkStopStat.total - wrkStopStat.clear) > 0 ? '#FF8F51' : '#E0E0E0',
                                                boxShadow: 'inset 0 10px 0 rgba(0,0,0,0.1)'
                                            }}/>
                                        </div>
                                        <div
                                            className="me-2 rounded-circle d-flex justify-content-center align-items-center"
                                            style={{width: '65px', height: '65px', border: '4px solid #928e8e'}}>
                                            <div className="rounded-circle" style={{
                                                width: '50px',
                                                height: '50px',
                                                backgroundColor: (sosStat.total - sosStat.clear) > 0 ? '#FF5656' : '#E0E0E0',
                                                boxShadow: 'inset 0 10px 0 rgba(0,0,0,0.1)'
                                            }}/>
                                        </div>
                                    </div>
                                </div>
                                <div onClick={() => setIsLgndModalOpen(true)} className="cursor-pointer sh-fs-b2 d-flex align-items-center justify-content-center sh-fs-b3">
                                    <div>
                                        <span className="pe-2" style={{color: '#12E573'}}>●</span>
                                        <span>안전작업중</span>
                                    </div>
                                    <div className={'mx-3'}>
                                        <span className="pe-2" style={{color: '#FF8F51'}}>●</span>
                                        <span>작업중지요청 발생</span>
                                    </div>
                                    <div>
                                        <span className="pe-2" style={{color: '#FF5656'}}>●</span>
                                        <span>SOS응급호출 발생</span>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between mt-1" style={{lineHeight: '20px'}}>
                                <div
                                     className="rounded-3 d-flex justify-content-between align-items-center p-2"
                                     style={{border: '1px solid #474747', width: '98%'}}>
                                    <div className="d-flex">
                                        <div className="p-2 rounded-3 me-2" style={{backgroundColor: '#ff8f51'}}>
                                            <IconStop style={{width: '25px'}}/></div>
                                        <div className="sh-fs-b3">작업중지요청<br/>(해제 / 전체)</div>
                                    </div>
                                    <div className="sh-fs-b3"><span
                                        className="sh-fs-h3 sh-fw-6 me-1 cursor-pointer" onClick={() => fn_openWrkStopInfoListModal('P001002')}>{wrkStopStat.clear}</span>건 /
                                    </div>
                                    <div className="sh-fs-b3"><span
                                        className="sh-fs-h3 sh-fw-6 me-1 cursor-pointer" onClick={() => fn_openWrkStopInfoListModal()}>{wrkStopStat.total}</span>건
                                    </div>
                                </div>

                            </div>
                            <div className="d-flex justify-content-between mt-2" style={{lineHeight: '20px'}}>
                                <div
                                     className="cursor-pointer rounded-3 d-flex justify-content-between align-items-center p-2"
                                     style={{border: '1px solid #474747', width: '98%'}}>
                                    <div className="d-flex">
                                        <div className="p-2 rounded-3 me-2" style={{backgroundColor: '#FF5656'}}>
                                            <IconSiren
                                                style={{width: '25px'}}/></div>
                                        <div className="sh-fs-b3">SOS 응급호출<br/>(해제 / 전체)</div>
                                    </div>
                                    <div className="sh-fs-b3" onClick={() => fn_openSosModal('P001002')}><span
                                        className="sh-fs-h3 sh-fw-6 me-1">{sosStat.clear}</span>건 /
                                    </div>
                                    <div className="sh-fs-b3" onClick={() => fn_openSosModal()}><span
                                        className="sh-fs-h3 sh-fw-6 me-1">{sosStat.total}</span>건
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default HOCRTrafficLight;
