import React, {useContext, useEffect, useState} from 'react';
import {Container, Row, Col} from "react-bootstrap";
import DashboardWeather from './DashWeather';
import DashboardProcessRate from './DashProcessRate';
import DashboardWorkPermit from './DashWorkPermitDoc';
import DashboardRiskAssessment from './DashRiskAssessment';
import DashboardTodayWork from './DashTodayWork';
import DashboardTotalDepartureStatus from './DashTotalDepartureStatus';
import DashboardPartDepartureStatus from './DashPartDepartureStatus';
import DashboardZoneInformation from './DashZoneInformation';
import DashboardNotice from "./DashNotice";
import DashboardNonconformityState from "./DashNonconformityState";
import DashboardEquipmentStatus from "./DashEquipmentStatus";
import DashboardSiteCCTV from "./DashSiteCCTV";
import {MenuOpenContext} from "../../context/MenuOpen";
import logo_gsil from "../../assets/images/logo/logo_gsli.png";
import {UserContext} from "../../context/User";
import restapi from "../../helper/restAPIHelper";
import {getLoginUser} from "../../helper/SecurityHelper";
import {toast} from "react-toastify";
import errorCodeDic from "../../util/errorCodeDic";
import {ProgressContext} from "../../context/Progress";
import DashSmartIotStatus from "./DashSmartIotStatus";

type Props = {};

const HomePage = (props: Props) => {
    const {open} = useContext(MenuOpenContext);
    const {user} = useContext<any>(UserContext);
    const defaultUser = getLoginUser();
    const [etrncStatData, setEtrncStatData] = useState<any>();
    const {spinner} = useContext(ProgressContext);

    useEffect(() => {
        const siteId = user?.blngSiteId || defaultUser.blngSiteId;

        spinner.start();
        restapi.retrieveTotEtrncStatInfo({siteId: siteId, qrNfcTpCd: 'T003001'}).then((rsltData: any) => {
            setEtrncStatData(rsltData);
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });
    },[user])

    return (
      <>
          <Container className="px-4 pt-4" fluid style={{letterSpacing:'-0.5px', backgroundColor:'#151517'}}>
            <Row>
                <Col bsPrefix="col-3">
                    <Row>
                        <Col>
                            <DashboardWeather/>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <DashboardProcessRate/>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <DashboardWorkPermit/>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <DashboardRiskAssessment />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <DashboardTodayWork />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <DashboardNonconformityState />
                        </Col>
                    </Row>
                </Col>
                <Col bsPrefix="col-6">
                    <Row>
                        <Col className={"mb-3 " + (!open && "col-6")} style={{minWidth:"350px"}}>
                            <DashboardTotalDepartureStatus etrncStatData={etrncStatData} />
                        </Col>
                        <Col className={"mb-3 " + (!open && "col-6")}>
                            <DashboardPartDepartureStatus etrncStatData={etrncStatData} />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <DashboardZoneInformation />
                        </Col>
                    </Row>
                </Col>
                <Col bsPrefix="col-3">
                    <Row>
                        <Col>
                            <DashboardNotice />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <DashSmartIotStatus />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <DashboardSiteCCTV />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <DashboardEquipmentStatus />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <div className="w-100 d-flex justify-content-end align-items-end">
                                <img  style={{width: 70}} src={logo_gsil} alt="로고" />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
      </>
  );
};

export default HomePage;
