import SHSearch from "../../component/SHSearch";
import {Col, Row, Table} from "react-bootstrap";
import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import Datatables from "../../component/Datatables";
import ecardApi from "../../../helper/ecardAPIHelper";
import SHDatePicker2 from "../../../components/common/SHDatePicker2";
import {DateHelper} from "../../../helper/DateHelper";
import moment from 'moment';
import AsscWrkDtlApiModal from "./AsscWrkDtlApiModal";
interface IProps {
   statData: any;
   dataList: any;
   srchData: any;
   setSrchData: Dispatch<SetStateAction<boolean>>;
   setSelData: Dispatch<SetStateAction<any>>;
   wrkDt: string;
   setWrkDt: Dispatch<SetStateAction<string>>;
   isDatePopupOpen: boolean;
   setIsDatePopupOpen: Dispatch<SetStateAction<boolean>>;

}

const AsscWrkrInOutInfoApiPage = (props: IProps) => {
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [detailData, setDetailData] = useState<any>({});
    const [selectWorkerSeq, setSelectWorkerSeq] = useState<number>();
    const [place, setPlace] = useState<any>([]);
    const [company, setCompany] = useState<any>([]);
    const [deductionNo, setDeductionNo] = useState<string>();
    const [placeCd, setPlaceCd] = useState<string>();
    const [licenseNo, setLicenseNo] = useState<string>();
    const [nationalityCd, setNationalityCd] = useState<string>();
    const [dataList, setDataList] = useState<any>([]);
    const [startDate, setStartDate] = useState<Date>(DateHelper.dateByMonth(-1));
    const [endDate, setEndDate] = useState<Date>(DateHelper.dateByMonth(0));
    useEffect(() => {
        ecardApi.placeList().then((r)=>{
            setPlace(r)
        })
    }, [])
    useEffect(() => {
        if(deductionNo){
            ecardApi.companyList(deductionNo).then((r)=>{
                setCompany(r)
            })
        }
    }, [deductionNo])
    function fn_search(){
        console.log(startDate, endDate)
        ecardApi.workList(placeCd, licenseNo, nationalityCd, startDate, endDate).then((r)=>{
            setDataList(r)
        })
    }
    return(
        <>
            <AsscWrkDtlApiModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} workerSeq={selectWorkerSeq} />
            <SHSearch btnType={'p'} onSearch={fn_search}>
                <Row>
                    <Col>
                        <Table className="sh-font-SP sh-fs-b2 my-3">
                            <tbody>
                            <tr className="border-top align-middle">
                                <th className="sh-bg-th" style={{width: '10%'}}>현장</th>
                                <td style={{width: '25%'}}>
                                    <select className={"form-select"} onChange={(e) => {
                                        if(e.target.value) {
                                            setPlaceCd(e.target.value.split("|")[0]);
                                            setDeductionNo(e.target.value.split("|")[1]);
                                        }else {
                                            setPlaceCd("");
                                            setDeductionNo("");
                                        }
                                    }}>
                                        <option value={""}> - 선택 - </option>
                                        {place && place.map((p: any)=>{
                                            return <option value={p.placeCd + "|" + p.deductionNo} data-place-cd={p.placeCd}>{p.placeNm}</option>
                                        })}
                                    </select>
                                </td>
                                <th className="sh-bg-th" style={{width: '10%'}}>업체</th>
                                <td style={{width: '25%'}}>
                                    <select className={"form-select"} onChange={(e)=>{ setLicenseNo(e.target.value)}}>
                                        <option value={""}> - 전체 -</option>
                                        {company && company.map((p: any)=>{
                                            return <option value={p.licenseNo}>{p.companyNm}</option>
                                        })}
                                    </select>                                </td>
                                <th className="sh-bg-th" style={{width: '10%'}}>국적</th>
                                <td style={{width: '25%'}}>
                                    <select className={"form-select"} onChange={(e)=>{ setNationalityCd(e.target.value)}}>
                                        <option value={""}> - 전체 -</option>
                                        <option value={"KO"}>대한민국</option>
                                        <option value={"CN"}>중국</option>
                                        <option value={"VN"}>베트남</option>
                                        <option value={"UZ"}>우즈베키스탄</option>
                                        <option value={"PH"}>필리핀</option>
                                        <option value={"TH"}>태국</option>
                                        <option value={"ID"}>인도네시아</option>
                                        <option value={"KH"}>캄보디아</option>
                                        <option value={"LK"}>스리랑카</option>
                                        <option value={"MM"}>미얀마</option>
                                        <option value={"MN"}>몽골</option>
                                        <option value={"NP"}>네팔</option>
                                        <option value={"ETC"}>기타</option>
                                    </select>
                                </td>
                            </tr>
                            <tr className="border-top border-bottom align-middle">
                                <th className="sh-bg-th" style={{width: '10%'}}>일자</th>
                                <td colSpan={5}>
                                    <div className="d-flex align-items-center w-100">
                                        <SHDatePicker2 defaultValue={startDate} onChange={(date)=>{ setStartDate(date) }} />
                                        <span>&nbsp;~&nbsp;</span>
                                        <SHDatePicker2 defaultValue={endDate} onChange={(date)=>{ setEndDate(date)  }} />
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </SHSearch>

            <div className={'ge-result mb-5'}>
                <Datatables
                    datas={dataList}
                    className="sh-fs-b4"
                    columns={[
                        {data: "workStartTime", title: "출근시간", render: function (data: any, type: any, row: any, meta: any) {
                            return moment(data,'YYYYMMDDHHmmss').format("YYYY년 MM월 DD일, HH시 mm분 ss초")
                            }},
                        {data: "workEndTime", title: "퇴근시간", render: function (data: any, type: any, row: any, meta: any) {
                                if(data){
                                    return moment(data,'YYYYMMDDHHmmss').format("YYYY년 MM월 DD일, HH시 mm분 ss초")
                                }else{
                                    return "-"
                                }
                            }},
                        {data: "workerSeq", title: "근로자일련번호"},
                        {data: "birth", title: "주민등록번호앞자리"},
                        {data: "workerNm", title: "근로자명"},
                        {data: "workerNick", title: "근로자별칭"},
                        {data: "gender", title: "성별"},
                        {data: "foreignYn", title: "외국인여부"},
                        {data: "nationalityCd", title: "국적", render:(data: any)=> ecardApi.nationalityNm(data)},
                        {data: "phone", title: "연락처"},
                        {data: "addr", title: "주소", textAlign: 'left'},
                        {data: "addrDetail", title: "주소상세", textAlign: 'left'},
                        {data: "stayGlfctCd", title: "체류자격코드"},
                        {data: "placeCd", title: "현장코드"},
                        {data: "deductionNo", title: "공제가입번호"},
                        {data: "licenseNo", title: "소속업체사업자번호"},
                        {data: "companyNm", title: "소속업체명"},
                        {data: "workCd", title: "근로자직종"},
                        {data: "workSeq", title: "근로일일련번호"},
                        {data: "workDt", title: "근로일시"},
                        {data: "startTime", title: "출근태그시간"},
                        {data: "startTimeSky", title: "출근태그날씨"},
                        {data: "endTime", title: "퇴근태그시간"},
                        {data: "endTimeSky", title: "퇴근태그날씨"},
                        {data: "tagCd", title: "인증타입"},
                    ]}
                    checked={false}
                    onLoad={(dt: any) => {
                        setDatatable(dt)
                    }}
                    onClick={(data) => {
                        setSelectWorkerSeq(data.workerSeq)
                        setModalIsOpen(true);
                    }
                    }
                />
            </div>
        </>
        )

}

export default AsscWrkrInOutInfoApiPage;
