import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import iconCalendar from "../../../images/icon-calendar-black.svg";
import CustomDatePickerRange from "../../component/ui/DatePickerRange";
import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {fn_getCurrentDate} from "../../../helper/CmmnHelper";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {Col, Row} from "react-bootstrap";
import {Container} from "@mui/material";
import {COMMON_MODAL_STYLE, COMMON_MULTISEL_STRINGS} from "../../../helper/StyleHelper";
import Select from 'react-select'
import {MultiSelect} from "react-multi-select-component";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import SHInput from "../../../components/common/SHInput";
import SHTextarea from "../../../components/common/SHTextarea";
import {ProgressContext} from "../../../context/Progress";
import SHCheckbox from "../../../components/common/SHCheckbox";
import SHFile from "../../../components/common/SHFile";
import restapi from "../../../helper/restAPIHelper";
import SHDatePicker2 from "../../../components/common/SHDatePicker2";
import {DateHelper} from "../../../helper/DateHelper";


interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    noticeDetailData: any;
    siteId: number;
}

const NoticeRegistModal = (props: IProps) => {

    const prodRef = useRef<any>();
    const currentDate = fn_getCurrentDate();
    const user: any = getLoginUser();
    const [startDate, setStartDate] = useState<Date | null>(new Date())
    const [endDate, setEndDate] = useState<Date | null>(new Date());
    const [data, setData] = useState<any>()
    const [selected, setSelected] = useState<any>([])
    const [cmpnyCodeList, setCmpnyCodeList] = useState<any>([]);
    const [fileList, setFileList] = useState([]);
    const defaultUser: any = getLoginUser();
    const {spinner} = useContext(ProgressContext);
    const formRef = React.createRef<HTMLFormElement>();
    useEffect(() => {
        setData(null)
    }, [props.modalIsOpen])
    const updateData = (e: any) => {
        if (e.target.type == 'checkbox') {
            setData({
                ...data,
                [e.target.name]: e.target.checked
            })
        } else {
            setData({
                ...data,
                [e.target.name]: e.target.value
            })
        }

    }

    useEffect(() => {
        let param:any = {siteId: props.siteId}

        fn_retrieveCmpnyDataList(param)
    }, [props.siteId])

    useEffect(() => {
        // 신규
        if (!props.noticeDetailData) {
            setData(null)
            setStartDate(null)
            setEndDate(null)

            setSelected([])
            // 상세
        } else {
            console.log('props.noticeDetailData: ', props.noticeDetailData);
            setData(props.noticeDetailData);
            setStartDate(DateHelper.parse(props.noticeDetailData.noticeStartDate))
            setEndDate(DateHelper.parse(props.noticeDetailData.noticeEndDate))
            axios.post('/admin/api/notice/retrieveNoticeRecvDataList', {noticeId: props.noticeDetailData.noticeId}).then((result) => {
                let dataList: any = [];
                result.data.map((item: any) => {
                    dataList.push({label: item.companyName, value: item.companySeq})
                })
                setSelected(dataList);

                console.log('selRslt: ', result);
            })
        }

    }, [props.noticeDetailData])


    const fn_retrieveCmpnyDataList = async (param: object) => {
        return new Promise(function (resolve, reject) {
            axios.post('/admin/api/company/retrieveCmpnyDataList', param).then((result) => {
                resolve(result.data);
            })
        }).then((result: any) => {
            if (result) {
                const rsltCmpnyCodeList: object[] = []
                result.map((data: any) => {
                    rsltCmpnyCodeList.push({label: data.companyName, value: data.companySeq})
                })
                setCmpnyCodeList(rsltCmpnyCodeList);
                console.log('result: ', result);
            }
        });

    }

    function afterOpenModal() {
        if(!props.noticeDetailData){
            setFileList([]);
            setData(null);
        }else{
            setData(props.noticeDetailData);
            if(props.noticeDetailData.atchFileId){
                restapi.retrieveAttachInfoList({attachSeq: props.noticeDetailData.atchFileId}).then((rsltData: any) => {
                    setFileList([].concat(rsltData));
                })
            }else{
                setFileList([]);
            }
        }
    }

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const submit = (e: any) => {
        e.preventDefault()

    }

    const fn_registData = () => {

        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if (!data.title) {
            toast.warn('제목을 입력해 주십시오.')
            return;
        }

        if (selected.length == 0) {
            toast.warn('수신자를 선택해 주십시오.')
            return;
        }

        if(!data.isAllProd){
            if (!startDate || !endDate) {
                toast.warn('공지기간을 입력해 주십시오.')
                return;
            }
        }

        if (!data.contents) {
            toast.warn('내용을 입력해 주십시오.')
            return;
        }

        if (!window.confirm('저장 하시겠습니까?')) {
            return;
        }

        let param = data;

        param.noticeStartDate = startDate;
        param.noticeEndDate = endDate;
        param.regSeq = user.userSeq;
        param.updtrSeq = user.userSeq;
        param.siteId = props.siteId;
        let receptionCompanySeqArray: any = [];
        selected.map((data: any) => {
            receptionCompanySeqArray.push(data.value)
        })
        param.rgstCmpnySeq = user.blngCmpnySeq;
        param.receptionCompanySeqArray = receptionCompanySeqArray;

        // @ts-ignore
        var formData: any = new FormData(formRef.current)

        if(fileList.length > 0){
            for(var i =0;i<fileList.length;i++){
                formData.append('atchFileList',fileList[i]);
            }
        }

        formData.set('noticeVo', new Blob([JSON.stringify(param)], {type: "application/json"}))

        spinner.start();
        axios.post('/admin/api/notice/saveNoticeData', formData).then((result) => {

            if (result.data > 0) {
                toast.success(messageCodeDic['001'])
                props.setModalIsOpen(false);
            } else {
                toast.error(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
        .finally(() => {
            spinner.stop();
        });

    }


    const fn_isAllProdChg = (e: any) => {
        if(e.target.value){
            setStartDate(null);
            setEndDate(null);
        }
    }

    return (
        <div>
            <Modal
                className="sh-modal" size="xl"
                show={props.modalIsOpen}
                onShow={afterOpenModal}
                onHide={fn_closeModal}

            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        공지사항 등록
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">
                    <form name={'noticeDetailForm'} ref={formRef} encType="multipart/form-data" method={"post"}
                          onSubmit={submit}>
                        <div className="sh-modal-div">
                            <div className="div-header">
                                공지사항 정보
                            </div>

                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>제목</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput type="text" className="form-control" name={'title'}
                                                     onChange={updateData} value={data?.title}/>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>수신자 선택</th>
                                        <td style={{width: '35%'}}>
                                            {data?.sftyNtfctnMngId ? '전체 업체' :
                                                <Select
                                                             options={cmpnyCodeList}
                                                             value={selected}
                                                             onChange={setSelected}
                                                             placeholder={"수신자 선택"}
                                                             isMulti
                                                />
                                            }

                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>공지기간</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex align-items-center w-100" ref={prodRef}>
                                                <SHDatePicker2 setter={setStartDate} name={'noticeStartDate'} defaultValue={startDate} onChange={updateData} disabled={data?.isAllProd || false}/>
                                                <span>&nbsp;~&nbsp;</span>
                                                <SHDatePicker2 setter={setEndDate} name={'noticeEndDate'} defaultValue={endDate} onChange={updateData} disabled={data?.isAllProd || false}/>
                                                <SHCheckbox name={'isAllProd'} onChange={(e: any) => {updateData(e); fn_isAllProdChg(e);}}
                                                            checked={data?.isAllProd} className={'ml-5 w-[110px]'}>전체</SHCheckbox>
                                            </div>
                                        </td>

                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>내용</th>
                                        <td style={{width: '85%'}} colSpan={3}>
                                            <SHTextarea rows={5} onChange={updateData} name={'contents'}
                                                        value={data?.contents}
                                                        className={"form-control"}
                                                        placeholder='내용을 입력해주세요.'/>
                                        </td>
                                    </tr>
                                    <tr className="align-middle" style={{width: '15%'}}>
                                        <th className="sh-bg-th">첨부파일</th>
                                        <td colSpan={3}>
                                            <SHFile disabled={data?.sftyNtfctnMngId} fileName={'files'} maxFileCnt={1} fileList={fileList} setFileList={setFileList}></SHFile>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        <div className="sh-modal-div">
                            <div className="div-header">
                                작성정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>작성일</th>
                                        <td style={{width: '35%'}}>
                                            <input value={data?.regDate || currentDate}
                                                   className={"form-control"}
                                                   disabled={true}/>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>작성자</th>
                                        <td style={{width: '35%'}}>
                                            <input className={"form-control"}
                                                   value={data?.rgstrNm || user?.userName} disabled={true}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        {!data?.sftyNtfctnMngId &&
                            <button onClick={fn_registData}
                                    className='sh-btn pill p white'>등록/수정

                            </button>
                        }

                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default NoticeRegistModal;

