import SHSearch from "../../component/SHSearch";
import {Col, Row, Table} from "react-bootstrap";
import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import Datatables from "../../component/Datatables";
import CustomDatePickerRange from "../../component/ui/DatePickerRange";
import axios from "axios";
import {toast} from "react-toastify";
import errorCodeDic from "../../../util/errorCodeDic";
import SHDatePicker2 from "../../../components/common/SHDatePicker2";
import {DateHelper} from "../../../helper/DateHelper";
import SHInput from "../../../components/common/SHInput";

interface IProps {
   dataList: any;
   setSelData: Dispatch<SetStateAction<any>>;
   srchData: any;
   setSrchData: Dispatch<SetStateAction<boolean>>;
   isDatePopupOpen: boolean;
   setIsDatePopupOpen: Dispatch<SetStateAction<boolean>>;
   onSearch: any;
}

const WrkrPollSubPage = (props: IProps) => {
    const prodRef = useRef<any>();
    const [startDate, setStartDate] = useState<Date>(DateHelper.dateByMonth(-1));
    const [endDate, setEndDate] = useState<Date>(DateHelper.dateByMonth(0));

    const fn_setSrchData = (e: any) => {
        if(e.target.name == 'isAll'){
            let strtDt: any = DateHelper.dateByMonth(-1);
            let endDt: any = DateHelper.dateByMonth(0);
            console.log('e.target.checked: ', e.target.checked);
            if(e.target.checked){
                strtDt = null;
                endDt = null;
            }

            props.setSrchData({
                ...props.srchData,
                ['strtDt']: strtDt,
                ['endDt']: endDt,
            })
        } else {
            props.setSrchData({
                ...props.srchData,
                [e.target.name]: e.target.value
            })
        }


    }
    return(
        <>
            <SHSearch btnType={'p'} onSearch={props.onSearch}>
                <Row>
                    <Col>
                        <Table className="sh-font-SP sh-fs-b2 my-3">
                            <tbody>
                            <tr className="align-middle border-top border-bottom">
                                <th className="sh-bg-th">기간</th>
                                <td>
                                    <div className="d-flex flex-row gap-2">
                                        <div className="d-flex align-items-center" ref={prodRef}>
                                            <SHDatePicker2 name={'strtDt'} readOnly={true} defaultValue={startDate} value={props.srchData.strtDt}  onChange={fn_setSrchData} />
                                            <span>&nbsp;~&nbsp;</span>
                                            <SHDatePicker2 name={'endDt'} readOnly={true} defaultValue={endDate} value={props.srchData.endDt} onChange={fn_setSrchData} />
                                        </div>

                                    </div>
                                </td>
                                <th className="sh-bg-th">검색어</th>
                                <td>
                                    <SHInput className={'form-control'} name={'srchTxt'} onChange={fn_setSrchData} value={props.srchData.srchTxt} />
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </SHSearch>

            <div className={'ge-result'}>
                <Datatables
                    datas={props.dataList}
                    scrollY="550px"
                    className="sh-fs-b4"
                    columns={[
                        {data: "userName", title: "성명"},
                        {data: "cmpnyNm", title: "업체"},
                        {data: "wrkTpNm", title: "공종"},
                        {data: "ocptNm", title: "직종"},
                        {data: "cmpltdDt", title: "작성일자"},
                        {
                            title: "기능",
                            type: "button",
                            className: "text-center",
                            buttons: [{
                                label: "<img style='height: 20px' class='cursor-pointer' src='/icon_print.svg' />",
                                onClick: (data: any) => {
                                    let titleId = data.titleId;
                                    if(titleId == null){
                                        titleId = "";
                                    }

                                    window.open(process.env.REACT_APP_FILE_URL + "/admin/report.html?userSeq="+data.userSeq+"&pollId="+data.pollId+"&titleId=" + titleId + "&pollSn=" + data.pollSn, "_blank")
                                },
                            }],
                        },
                    ]}
                    index={true}
                    checked={false}
                    onLoad={(dt: any) => {
                        //setDatatable(dt)
                    }}

                    onClick={(data) => {
                        data.isPrint = false;
                        props.setSelData(Object.assign({}, data));
                    }
                    }
                />


            </div>
        </>
        )

}

export default WrkrPollSubPage;
