import {IoMdClose} from "react-icons/io";
import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {Col, Row, Table, Modal} from "react-bootstrap";
import SHCheckbox from "../../../components/common/SHCheckbox";
import restapi from "../../../helper/restAPIHelper";
import {ProgressContext} from "../../../context/Progress";
import errorCodeDic from "../../../util/errorCodeDic";
import { toast } from "react-toastify";
import {useReactToPrint} from "react-to-print";




const $ = require("jquery")

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
}

const WrkrPollModal = (props: IProps) => {
    const [titleDataList, setTitleDataList] = useState<any>([]);
    const [subTitleDataList, setSubTitleDataList] = useState<any>([]);
    const [qstnDataList, setQstnDataList] = useState<any>([]);
    const [qstnDtlDataList, setQstnDtlDataList] = useState<any>([]);
    const {spinner} = useContext(ProgressContext);
    const formRef = React.createRef<HTMLFormElement>();

    const user = getLoginUser();
    const fn_onAfterOpen = () => {
        fn_retrievePollDtlDataList({
            pollId: props.detailData.pollId,
            userSeq: props.detailData.userSeq,
            pollSn: props.detailData.pollSn,
        })
    }

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    useEffect(() => {
        if(qstnDtlDataList.length > 0 && props.detailData.isPrint){
            handlePrint();
        }
    }, [qstnDtlDataList]);

    const fn_retrievePollDtlDataList = (param: any) => {
        spinner.start();

        restapi
            .retrievePollDtlDataList(param)
            .then((rsltData: any) => {
                console.log("rsltData", rsltData)
                setTitleDataList(rsltData.titleDataList);
                setSubTitleDataList(rsltData.subTitleDataList);
                setQstnDataList(rsltData.qstnDataList);
                setQstnDtlDataList(rsltData.qstnDtlDataList);

            })
            .catch(error => {
                toast.warn(errorCodeDic['003']);
                console.log('error', error);
            })
            .finally(() => {
                spinner.stop();
            });
    };
    const handlePrint = useReactToPrint({
        content: () => formRef.current,
        documentTitle: 'test',
    });

    return (
        <div>
            <Modal className="sh-modal" size="xl" show={props.modalIsOpen}
                   onShow={fn_onAfterOpen}>
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        {props.detailData?.pollId == 'A' ? '감정안전' : '스트레스 관리'} 설문조사 상세보기
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <form ref={formRef} className={'text-black'}>
                    <div className="sh-modal-div">
                        <div className="div-header">
                            작성자 정보
                        </div>
                        <div className="div-body px-3">
                            <Table className="my-2 align-middle">
                                <tbody>
                                <tr className="border-top align-middle">
                                    <th className="sh-bg-th" style={{width: '10%'}}>성명</th>
                                    <td style={{width: '23%'}}>
                                        {props.detailData?.userName}
                                    </td>
                                    <th className="sh-bg-th" style={{width: '10%'}}>작성일</th>
                                    <td style={{width: '23%'}}>
                                        {props.detailData?.cmpltdDt}
                                    </td>
                                    <th className="sh-bg-th" style={{width: '10%'}}>업체</th>
                                    <td style={{width: '23%'}}>
                                        {props.detailData?.cmpnyNm}
                                    </td>
                                </tr>
                                <tr className="border-top align-middle">
                                    <th className="sh-bg-th" style={{width: '10%'}}>공종</th>
                                    <td style={{width: '23%'}}>
                                        {props.detailData?.wrkTpNm}
                                    </td>
                                    <th className="sh-bg-th" style={{width: '10%'}}>직종</th>
                                    <td style={{width: '23%'}}>
                                        {props.detailData?.ocptNm}
                                    </td>
                                    <th className="sh-bg-th" style={{width: '10%'}}></th>
                                    <td style={{width: '23%'}}>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div className="sh-modal-div">
                        <div className="div-header">
                            응답 내용
                        </div>

                        <div className="div-body px-3">

                            {titleDataList.map((titleData: any, titleIdx: number) => (
                                <div key={titleIdx}>
                                    <Row className={'text-lg mb-1'}>
                                        {titleData.title}
                                    </Row>
                                    {subTitleDataList.map(
                                        (subTitledata: any, subTitleIdx: number) =>
                                            titleData.titleId == subTitledata.titleId && (
                                                <div key={subTitleIdx} className={'mt-1'}>
                                                    {subTitledata.subTitle && (
                                                        <Row className={'text-lg'}>
                                                            {subTitledata.subTitle}
                                                        </Row>
                                                    )}

                                                    {qstnDataList.map(
                                                        (qstnData: any, qstnIdx: number) =>
                                                            subTitledata.subTitleId == qstnData.subTitleId && (
                                                                <div className={'ml-10 mt-1'} key={qstnIdx}>
                                                                    <Row className={'mb-1'}>
                                                                        {qstnData.qstn}
                                                                    </Row>

                                                                        {qstnDtlDataList.map(
                                                                            (qstnDtlData: any, idx: number) => {
                                                                                return (
                                                                                    qstnData.qstnId == qstnDtlData.qstnId && (
                                                                                        <Row key={idx}>
                                                                                            <SHCheckbox  className={'ml-10'}
                                                                                                        checked={qstnDtlData?.isSlctd}
                                                                                                         readOnly={true}
                                                                                            >
                                                                                                {qstnDtlData.qstnDtl}
                                                                                            </SHCheckbox>
                                                                                        </Row>
                                                                                    )
                                                                                );
                                                                            },
                                                                        )}

                                                                </div>
                                                            ),
                                                    )}
                                                </div>
                                            ),
                                    )}
                                    <hr className={'mt-3 mb-3'}/>
                                </div>
                            ))}




                            {/*<Row className={'border-1'}>
                                <Col bsPrefix="col-3 my-2" >
                                    <SHCheckbox className={'ml-30'}
                                                checked={data?.isSlctd} onChange={() => fn_setSftyChkData(data.chkCode)}>

                                    </SHCheckbox>
                                </Col>

                            </Row>*/}

                        </div>
                    </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>

                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default WrkrPollModal;

