import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from "axios";
import Modal from 'react-modal';
import {getLoginUser} from "../../helper/SecurityHelper";
import {toast} from "react-toastify";
import errorCodeDic from "../../util/errorCodeDic";
import {ProgressContext} from "../../context/Progress";
import restapi from "../../helper/restAPIHelper";

import {Col, Container, Row, Tab, Table, Tabs} from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";

import {CmmnFn} from "../../helper/CmmnHelper";

import WrkrInfoListModal from "../home/modal/WrkrInfoListModal";
import {DateHelper} from "../../helper/DateHelper";

import {UserContext} from "../../context/User";
import SensorTab1Page from "./subpage/SesnorTab1Page";


type Props = {};

Modal.setAppElement('#root');
const SmartIotPage = (props: Props) => {

    const defaultUser = getLoginUser();
    const {user} = useContext<any>(UserContext);
    const {spinner} = useContext(ProgressContext);
    const [dataList, setDataList] = useState<any>([]);
    const [srchData, setSrchData] = useState<any>({siteId:user?.blngSiteId || defaultUser.blngSiteId, tabGb: 'S017001', sensorCd: 'S017001'});
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [selData, setSelData] = useState<any>(null);

    const [isDatePopupOpen, setIsDatePopupOpen] = useState<boolean>(false);
    const prodRef = useRef<any>();
    const [isHistModalOpen, setIsHistModalOpen] = useState<boolean>(false);
    const [riskyDataList, setRiskyDataList] = useState<any>([]);

    useEffect(() => {
        console.log('defaultUser: ', defaultUser);
    }, []);

    useEffect(() => {
        if(!modalIsOpen && srchData){
            fn_search(srchData);
        }
    }, [srchData,modalIsOpen])

    useEffect(() => {
        setSrchData({
            ...srchData,
            siteId: user?.blngSiteId || defaultUser.blngSiteId
        })
    }, [user]);

    useEffect(() => {
        if(selData){
            if(selData.isHist){
                setIsHistModalOpen(true);
            }else{
                setModalIsOpen(true);
            }
        }

    }, [selData]);

    const fn_search = (srchData: any) => {
        console.log('srchData: ', srchData);

        spinner.start();
        restapi.retrieveSensorDataList(srchData).then((rsltData: any) => {
            console.log('rsltData: ', rsltData);
            setDataList(rsltData)
            let filteredData: any = [];
            rsltData.filter((data: any) => {
                if(data.valueX >= 5.0 || data.valueY >= 5.0 || data.valueZ >= 5.0){
                    filteredData.push(data);
                }
            })

            setRiskyDataList(filteredData);

        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
        .finally(() => {
            spinner.stop();
        });
    }

    return (
        <Container fluid className="bg-white px-4 pt-4 sh-text-font_primary">
            <SubMenuTitle title={'스마트 IOT'}/>
            <div className="overflow-y-auto" style={{height: 'calc(100vh - 180px)'}}>
            <Tabs
                transition={true}
                activeKey={srchData.tabGb}
                onSelect={(tabGb) => setSrchData({
                    ...srchData,
                    'sensorCd': tabGb,
                    'tabGb': tabGb
                })}
                id="tabGb"
                className="mb-3 mt-2"
            >
            <Tab eventKey="S017001" title="기울기 센서">
                <SensorTab1Page dataList={dataList} riskyDataList={riskyDataList} />
            </Tab>
            <Tab eventKey="placeHolder1" title="차후 추가 예정">
            </Tab>
            <Tab eventKey="placeHolder2" title="차후 추가 예정">
            </Tab>

            </Tabs>
            </div>
        </Container>


    );



};

export default SmartIotPage;
