import React, {useContext, useEffect, useRef} from 'react';
import restapi from "../../../helper/restAPIHelper";
import {get, Projection, transform, transformExtent} from "ol/proj";
import {Feature, Overlay} from "ol";
import {Point} from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Marker00 from "../../../images/map/icon_marker_gray.png";
import Marker01 from "../../../images/map/icon_marker_red.png";
import Marker02 from "../../../images/map/icon_marker_navy.png";
import Marker03 from "../../../images/map/icon_marker_purple.png";
import Marker04 from "../../../images/map/icon_marker_blue.png";
import Marker05 from "../../../images/map/icon_marker_green.png";
import {Fill, Icon, Stroke, Style, Text} from "ol/style";
import TileLayer from "ol/layer/Tile";
import {XYZ} from "ol/source";
import proj4 from "proj4";
import {register} from "ol/proj/proj4";
import Map from "ol/Map";
import View from "ol/View";
import LegendImage from "../../../images/map/map-legend.png";
import TileGrid from "ol/tilegrid/TileGrid";
import {GeoJSON} from "ol/format";
import {SiteContext} from "../../../context/Site";
import { CmmnFn } from '../../../helper/CmmnHelper';
import {TopMenuContext} from "../../../context/TopMenu";
const $ = require("jquery")

export default function RealMap() {
    const mapRef = useRef<HTMLDivElement>(null);
    const {setSite} = useContext<any>(SiteContext);
    const { menu, setMenu } = useContext<any>(TopMenuContext);
    function pick_site(siteId: string){

        restapi.retrieveSiteDataListNoPaging({siteId:siteId}).then((rsltData: any) => {
            setSite(rsltData[0]);

        })

        console.log('pick site: ', siteId);
    }
    function load_site(siteLayer: any){
        restapi.retrieveSiteDataListNoPaging({isSmSite: false, yyyymmdd: CmmnFn.fn_getCurrentDate().replace(/-/gi, "")}).then((rsltData: any) => {
            if(rsltData.length > 0){
                var features = []
                for(var i in rsltData){
                    var item = rsltData[i]
                    var center = transform([item.longitude, item.latitude], 'EPSG:4326', 'EPSG:3857')
                    var feature1 = new Feature({
                        geometry: new Point(center),
                    })
                    feature1.set("LABEL", item.siteName)
                    feature1.set("TYPE_CODE", item.siteType)
                    feature1.set("SITE_ID", item.siteId)
                    features.push(feature1)
                }
                siteLayer.getSource().addFeatures(features)
            }
        })
    }
    useEffect(() => {

        const siteLayer = new VectorLayer({
            source: new VectorSource({wrapX: false}),
            style: function(f){
                //console.log(f)
                var label = f.get("LABEL")
                var typeCode = f.get("TYPE_CODE")
                if(label.length > 20){
                    //label = label.substring(0,9) + "\n" + label.substring(10,100)
                }
                var icon = Marker00
                if(typeCode === 'T002001'){
                    // 택지조성공사
                    icon = Marker01
                }else if(typeCode === 'T002002'){
                    // 기반시설공사
                    icon = Marker02
                }else if(typeCode === 'T002003'){
                    // 공동주택건축공사
                    icon = Marker03
                }else if(typeCode === 'T002004'){
                    // 소규모공사
                    icon = Marker04
                }else if(typeCode === 'T002005'){
                    // 해체공사
                    icon = Marker05
                }
                return [new Style({
                    image: new Icon({
                        anchor: [0.5, 46],
                        anchorXUnits: 'fraction',
                        anchorYUnits: 'pixels',
                        src: icon,
                        width: 18,
                        height: 22.5,
                    }),
                    text: new Text({
                        text: label,
                        overflow : true,
                        offsetY: 14,
                        fill: new Fill({color: "#00aa00"}),
                        stroke: new Stroke({
                            color: "#fff",
                            width: 5
                        }),
                        font: "bold 14px Arial "
                    }),
                })]
            },
            declutter:true
        });
        siteLayer.setVisible(true)
        //
        var NAVER_AIR_LAYER = new TileLayer({visible : true, opacity: 1})
        NAVER_AIR_LAYER.setSource(new XYZ({
            projection: new Projection({code: 'EPSG:5179',extent: [90112, 1192896, 2187264, 2765760],units: 'm'}),
            cacheSize : 256,
            minZoom: 0,
            maxZoom: [2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5, 0.25].length - 1,
            tileGrid: new TileGrid({
                origin: [90112, 1192896],
                resolutions: [2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5, 0.25]
            }),
            tileUrlFunction : function(tileCoord, pixelRatio, projection) {
                if (tileCoord == null) return undefined;
                var s = Math.floor(Math.random() * 3) + 1;  // 1 ~ 4
                var z = tileCoord[0] + 1;
                var x = tileCoord[1];
                var y = -tileCoord[2] - 1;
                //
                var  path = '203/0/1'
                var path2 = 'bl_st_bg'
                var url = 'https://simg.pstatic.net/onetile/get/' + path + '/' + z + '/' + x + '/' + y + '/' + path2 + ''
                return url
            },
            crossOrigin: null
        }))
        var NAVER_LABEL_LAYER = new TileLayer({visible : true, opacity: 1})
        NAVER_LABEL_LAYER.setSource(new XYZ({
            projection: new Projection({code: 'EPSG:5179',extent: [90112, 1192896, 2187264, 2765760],units: 'm'}),
            cacheSize : 256,
            minZoom: 0,
            maxZoom: [2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5, 0.25].length - 1,
            tileGrid: new TileGrid({
                origin: [90112, 1192896],
                resolutions: [2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5, 0.25]
            }),
            tileUrlFunction : function(tileCoord, pixelRatio, projection) {
                if (tileCoord == null) return undefined;
                var s = Math.floor(Math.random() * 3) + 1;  // 1 ~ 4
                var z = tileCoord[0] + 1;
                var x = tileCoord[1];
                var y = -tileCoord[2] - 1;
                //
                var  path = '203/0/0'
                var path2 = 'bl_vc_bg/ol_vc_an'
                var url = 'https://simg.pstatic.net/onetile/get/' + path + '/' + z + '/' + x + '/' + y + '/' + path2 + ''
                return url
            },
            crossOrigin: null
        }))
        var BASE1_LAYER = new TileLayer({visible : true, opacity: 0.9})
        BASE1_LAYER.setSource(new XYZ({
            projection: "EPSG:900913",
            tileUrlFunction : function(tileCoord, pixelRatio, projection){
                var z = tileCoord[0]
                var x = tileCoord[1]
                var y = tileCoord[2]
                //
                var type = "2D영상"
                var path = null
                var ext = 'png'
                if(type == '2D지도'){
                    path = '2d/Base/service'
                }else if(type == '2D회색'){
                    path = '2d/gray/service'
                }else if(type == '2D야간'){
                    path = '2d/midnight/service'
                }else if(type == '2D하이브리드'){
                    path = '2d/Hybrid/service'
                }else if(type == '2D영상'){
                    path = '2d/Satellite/service'
                    ext = 'jpeg'
                }
                return 'https://xdworld.vworld.kr/' + path + '/' + z + '/' + x + '/' + y + '.' + ext
            },
            crossOrigin: "Anonymous"
        }))
        var BASE2_LAYER = new TileLayer({visible : true, opacity: 0.8})
        //this.Daum_Resolutions = [2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5, 0.25]
        //this.Daum_Extent = [-30000, -60000, 494288, 988576]
        BASE2_LAYER.setSource(new XYZ({
            projection: new Projection({code: 'EPSG:5181',extent: [-30000, -60000, 494288, 988576],units: 'm'}),
            cacheSize : 256,
            minZoom: 0,
            maxZoom: [2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5, 0.25].length - 1,
            tileGrid: new TileGrid({
                origin: [-30000, -60000],
                resolutions: [2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5, 0.25]
            }),
            tileUrlFunction : function(tileCoord, pixelRatio, projection) {
                if (tileCoord == null) return undefined;
                var s = Math.floor(Math.random() * 4);  // 0 ~ 3
                var z = [2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5, 0.25].length - tileCoord[0]
                var x = tileCoord[1];
                var y = -tileCoord[2] - 1;
                //
                var  path = 'map_hybrid/1906plw'
                var ext = 'png'
                var url = 'http://map' + s + '.daumcdn.net/' + path + '/L' + z + '/' + y + '/' + x + '.'+ ext
                return url
            },
            crossOrigin: null
        }))
        var DAUM_LAYER = new TileLayer({visible : true, opacity: 1})
        DAUM_LAYER.setSource(new XYZ({
            projection: new Projection({code: 'EPSG:5181',extent: [-30000, -60000, 494288, 988576],units: 'm'}),
            cacheSize : 256,
            minZoom: 0,
            maxZoom: [2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5, 0.25].length - 1,
            tileGrid: new TileGrid({
                origin: [-30000, -60000],
                resolutions: [2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5, 0.25]
            }),
            tileUrlFunction : function(tileCoord, pixelRatio, projection) {
                if (tileCoord == null) return undefined;
                var s = Math.floor(Math.random() * 4);  // 0 ~ 3
                var z = [2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5, 0.25].length - tileCoord[0]
                var x = tileCoord[1];
                var y = -tileCoord[2] - 1;
                //
                var path = 'map_2d/1906plw'
                var ext = 'png'
                //https://map.daumcdn.net/map_k3f_prod/bakery/image_map_png/PNG01/v22_galhg/3/1537/1010.png
                var url = 'http://map' + s + '.daumcdn.net/' + path + '/L' + z + '/' + y + '/' + x + '.'+ ext
                return url
            },
            crossOrigin: null
        }))
        var boundryLayer = new VectorLayer({
            source: new VectorSource({
                url: '/geojson/seoul_boundry.geojson',
                format: new GeoJSON(),
                wrapX: false
            }),
            style: function(feature, resolution) {
                return [new Style({
                    stroke : new Stroke({color : 'rgba(255,0,0,1)',width : 5})
                })]
            },
            opacity: 0.6
        });
        proj4.defs('EPSG:5179', '+title=Korea 2000 / Unified CS +proj=tmerc +lat_0=38 +lon_0=127.5 +k=0.9996 +x_0=1000000 +y_0=2000000 +ellps=GRS80 +units=m +no_defs')
        proj4.defs('EPSG:5181', '+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=500000 +ellps=GRS80 +units=m +no_defs')
        register(proj4)
        get('EPSG:5179')?.setExtent([90112, 1192896, 1990673, 2761664]);
        get('EPSG:5181')?.setExtent([-30000, -60000, 494288, 988576]);
        // @ts-ignore
        window.___dangerLayer = new VectorLayer({
            source: new VectorSource({wrapX: false}),
            style: function(){
                // @ts-ignore
                if(window.gifCtx){
                    return new Style({
                        image: new Icon({
                            // @ts-ignore
                            img: window.gifCtx.canvas,
                            // @ts-ignore
                            imgSize: [window.gifFrame.width, window.gifFrame.height],
                            anchor: [0.5, 1.2],
                            offset: [0,0],
                        })
                    })
                }
            }
        });
        // @ts-ignore
        window.___overlay = new Overlay({
            // @ts-ignore
            element: document.getElementById("sh-ol-modal-layer"),
            autoPan: false,
            positioning: "bottom-left",
            offset: [5,-10],
            autoPanAnimation: {
                duration: 250
            }
        })
        const map = new Map({
            // @ts-ignore
            target: mapRef.current,
            layers: [
                // @ts-ignore
                DAUM_LAYER, boundryLayer, siteLayer, window.___dangerLayer
            ],
            // @ts-ignore
            overlays: [window.___overlay],
            view: new View({
                center: [0,0],
                maxZoom : 19.4,
                minZoom : 7
            }),
        });
        map.once('postrender', function(e) {
            var map = e.map
            // @ts-ignore
            window.___dashboard_map = map
            var extent3857            // @ts-ignore
            if(window.___last_map_extent){
                // @ts-ignore
                extent3857 = window.___last_map_extent
            }else{
                var extent = [126.6, 37.4, 127.4, 37.7]
                extent3857 = transformExtent(extent, 'EPSG:4326', 'EPSG:3857')
            }
            // @ts-ignore
            map.getView().fit(extent3857 , map.getSize())
            setTimeout(function(){
                map.updateSize()
            },200)
            load_site(siteLayer)
        })
        map.on('singleclick', function(e) {
            var map = e.map
            var finds: any = []
            map.forEachFeatureAtPixel(e.pixel,function(feature) {
                finds.push(feature)
            },{hitTolerance: 2, layerFilter: function(layer) {
                    // @ts-ignore
                    return layer === siteLayer || layer === window.___dangerLayer;
                }})
            if(finds.length > 0) {
                var feature = finds[0]
                var siteId: string = feature.get("SITE_ID")
                var featureType: string = feature.get("FEATURE_TYPE")
                if(featureType === 'DANGER'){
                    //
                }else{
                    pick_site(siteId)
                }
            }
        })
        map.on('pointermove', function(e) {
            var map = e.map
            var finds: any = []
            map.forEachFeatureAtPixel(e.pixel,function(feature) {
                finds.push(feature)
            },{hitTolerance: 2, layerFilter: function(layer) {
                    // @ts-ignore
                    return layer === window.___dangerLayer;
                }})
            if(finds.length > 0) {
                var feature = finds[0]
                var siteName: string = feature.get("SITE_NAME")
                var point = feature.getGeometry().getCoordinates()
                // @ts-ignore
                $(window.___overlay.element).html("<div class='sh-ol-modal-layer-info'><div class='layer-wrap'>" + siteName + "</div></div>")// @ts-ignore
                window.___overlay.setPosition(point)
            }else{
                // @ts-ignore
                $(window.___overlay.element).empty();
            }
        })
        map.on("moveend", function(e){
            var extent = e.map.getView().getViewStateAndExtent().extent
            console.log(extent)
            // @ts-ignore
            window.___last_map_extent = extent
        });
        return () => {
            // @ts-ignore
            if(mapRef.current){
                mapRef.current.innerHTML = ''
            }
        }
    }, [mapRef.current]);
    return (
        <>
            <div id={"map"}  ref={mapRef} style={{width: '100%', height: '100%'}}></div>
            <div style={{textAlign:"center", position: "relative", bottom: "55px", height:"60px"}}>
                <img src={LegendImage} style={{position: "absolute", left: "50%", transform: "translateX(-50%)", width: "500px"}}/>
            </div>
        </>
    );
}
