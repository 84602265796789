import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {COMMON_MODAL_STYLE} from "../../../helper/StyleHelper";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import SHSelect from "../../../components/common/SHSelect";
import {getLoginUser} from "../../../helper/SecurityHelper";
import SHSearch from "../../component/SHSearch";
import SHInput from "../../../components/common/SHInput";
import restapi from "../../../helper/restAPIHelper";
import SiteSftyChkRgstModal from "../../safetyWorkReportManagement/modal/SiteSftyChkRgstModal";
import SiteManageRgstModal from "../../headManage/modal/SiteManageRgstModal";
import {SiteContext} from "../../../context/Site";
import SafetyNewspaperModal from "../../safetyNewspaper/modal/SafetyNewspaperModal";
import errorCodeDic from "../../../util/errorCodeDic";
import {ProgressContext} from "../../../context/Progress";
import NoticeRegistModal from "../../noticeManagement/modal/NoticeRegistModal";
import IncgrtyRgstModal from "../../siteSafetyManagement/modal/IncgrtyRgstModal";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    dataList: object[];
    siteId?: any;

}

const $ = require("jquery")

const RcvNtfyListModal = (props: IProps) => {
    const [datatable, setDatatable] = useState<any>();

    const [isCodeDataLoaded, setIsCodeDataLoaded] = useState<any>(null);
    const [dataList, setDataList] = useState<any>([]);
    const [isNewsPprModalOpen, setIsNewsPprModalOpen] = useState<boolean>(false);
    const [detailData, setDetailData] = useState<any>({});
    const {spinner} = useContext(ProgressContext);
    const user = getLoginUser();
    const [isNoticeModalOpen, setIsNoticeModalOpen] = useState<boolean>(false);
    const [isIncgrtyModalOpen, setIsIncgrtyModalOpen] = useState<boolean>(false);
    const [isSftyChkModalOpen, setIsSftyChkModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (isCodeDataLoaded) {
            fn_search();
        }
    }, [isCodeDataLoaded]);

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_search = (e?: any) => {
        e?.preventDefault()
        datatable?.draw()
    }

    const fn_onShow = () => {

        setDataList(props.dataList);
    }

    const fn_openDtlPop = (data: any) => {
        const rcvNtfyGb = data.rcvNtfyGb;

        // 안전 신고 포상
        if(rcvNtfyGb == 'R002001'){
            spinner.start();
            restapi.retrieveSftyNwspprTotDataList({sftyNwspprId: data.rcvNtfyId}).then((rsltData: any) => {

                setDetailData(rsltData[0]);
                setIsNewsPprModalOpen(true);
                fn_readRcvNtfy(data);
            }).catch(error => {
                toast.error(errorCodeDic['003']);
                console.log('error: ', error);
            })
                .finally(() => {
                    spinner.stop();
                });
        // 공지사항
        }else if(rcvNtfyGb == 'R002002'){
            spinner.start();
            restapi.retrieveNoticeDataTotList({noticeId: data.rcvNtfyId}).then((rsltData: any) => {

                setDetailData(rsltData[0]);
                setIsNoticeModalOpen(true);
                fn_readRcvNtfy(data);
            }).catch(error => {
                toast.error(errorCodeDic['003']);
                console.log('error: ', error);
            })
                .finally(() => {
                    spinner.stop();
                });
        }else if(rcvNtfyGb == 'R002003'){
            spinner.start();
            restapi.retrieveIncgrtyDataTotList({incgrtyId: data.rcvNtfyId}).then((rsltData: any) => {

                setDetailData(rsltData[0]);
                setIsIncgrtyModalOpen(true);
                fn_readRcvNtfy(data);
            }).catch(error => {
                toast.error(errorCodeDic['003']);
                console.log('error: ', error);
            })
                .finally(() => {
                    spinner.stop();
                });
        }else if(rcvNtfyGb == 'R002004'){
            spinner.start();
            restapi.retrieveSiteSftyManageDataTotList({sftyChkSeq: data.rcvNtfyId}).then((rsltData: any) => {

                setDetailData(rsltData[0]);
                setIsSftyChkModalOpen(true);
                fn_readRcvNtfy(data);
            }).catch(error => {
                toast.error(errorCodeDic['003']);
                console.log('error: ', error);
            })
                .finally(() => {
                    spinner.stop();
                });
        }
    }

    const fn_readRcvNtfy = (param: any) => {

        if(!param.isRead){
            param.userSeq = user.userSeq;
            restapi.insertRcvNtfyReadData(param).then((rsltData: any) => {
                if(user.authGrpId == 'SITE'){
                    restapi.retrieveSiteRcvNtfyDataList({userSeq: user.userSeq, blngCmpnySeq: user.blngCmpnySeq, siteId: props.siteId}).then((rsltData: any) => {
                        setDataList(rsltData);
                    })
                }else{
                    restapi.retrieveHeadRcvNtfyDataList({userSeq: user.userSeq}).then((rsltData: any) => {
                        setDataList(rsltData);
                    })
                }

            }).catch(error => {
                toast.error(errorCodeDic['003']);
                console.log('error: ', error);
            })
        }
    }

    return (
        <div>
            <SafetyNewspaperModal setModalIsOpen={setIsNewsPprModalOpen} modalIsOpen={isNewsPprModalOpen} detailData={detailData} isCommentMode={false}/>
            <NoticeRegistModal setModalIsOpen={setIsNoticeModalOpen} modalIsOpen={isNoticeModalOpen} siteId={props.siteId}
                               noticeDetailData={detailData} />
            <IncgrtyRgstModal setModalIsOpen={setIsIncgrtyModalOpen} modalIsOpen={isIncgrtyModalOpen} siteId={props.siteId}
                              detailData={detailData}/>
            <SiteSftyChkRgstModal setModalIsOpen={setIsSftyChkModalOpen} modalIsOpen={isSftyChkModalOpen} prcsGb={'actRgst'}
                                  detailData={detailData}/>


            <Modal
                className="sh-modal" size="lg"
                show={props.modalIsOpen}
                onShow={() => {
                    fn_onShow()

                }}
                onHide={fn_closeModal}
            >


                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        알림수신함
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">

                    <div className="sh-modal-div  h-[300px] overflow-y-auto overflow-x-hidden">
                        <div className="div-body px-3">
                            <div className="card-body">
                                <table
                                    className="sh-table-primary w-100 dataTable no-footer">
                                    <thead>
                                    <tr>
                                        <th className={'text-center'}>No</th>
                                        <th className={'text-center'}>구분</th>
                                        <th className={'text-center'}>상태</th>
                                        <th className={'text-center'}>제목</th>
                                        <th className={'text-center'}>등록일자</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        dataList.length > 0 ?
                                            dataList.map((data: any, idx:number) => (
                                                <tr key={idx} className={data.isRead ? '' : 'fixedTop'} onClick={() => fn_openDtlPop(data)}>
                                                    <td className={'text-center'}>
                                                        {idx + 1}
                                                    </td>
                                                    <td className={'text-center'}>
                                                        {data.rcvNtfyGbNm}
                                                    </td>
                                                    <td className={'text-center'}>
                                                        {data.statusNm}
                                                    </td>
                                                    <td className={'text-center'}>
                                                        {data.title}
                                                    </td>
                                                    <td className={'text-center'}>
                                                        {data.rgstTime}
                                                    </td>
                                                </tr>

                                            )) : <tr>
                                                <td colSpan={5}>수신 알림 내역이 없습니다.</td>
                                            </tr>
                                    }

                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>

                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default RcvNtfyListModal;
