import React, {useContext, useEffect, useRef, useState} from 'react';
import Datatables, {getCheckedItems} from "../component/Datatables";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";

import {Col, Container, Row, Table } from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";
import SHIconButton from "../../components/common/SHIconButton";
import SHSearchButton from "../../components/common/SHSearchButton";
import {getLoginUser} from "../../helper/SecurityHelper";
import SiteSearchModal from '../companyManagement/modal/SiteSearchModal';
import CmpnyInfoRgstModal from '../companyManagement/modal/CmpnyInfoRgstModal';
import SHSearch from '../component/SHSearch';
import SHInput from "../../components/common/SHInput";
import SiteCmpnyRgstModal from "./modal/SiteCmpnyRgstModal";
import {UserContext} from "../../context/User";

type Props = {};

const SiteCmpnyMngPage = (props: Props) => {
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState(null);
    const [isSaveSuccess, setIsSaveSuccess] = useState(false);
    const siteNmRef = useRef<any>();
    const cmpnyNmRef = useRef<any>();
    const [isSitePopOpend, setIsSitePopOpend] = React.useState<boolean>(false);
    const [blngSiteList, setBlngSiteList] = useState<any[]>([])
    const [datatable, setDatatable] = useState<any>();
    const searchFormRef = useRef<any>();
    const {user} = useContext<any>(UserContext);
    const defaultUser = getLoginUser();

    useEffect(() => {
        if (datatable) {
            datatable.draw();
        }

    }, [user, datatable])


    useEffect(() => {
        if (isSaveSuccess) {
            setIsSaveSuccess(false);
            datatable?.draw();
        }
    }, [isSaveSuccess])

    useEffect(() => {
        if (blngSiteList.length > 0) {
            const siteName = blngSiteList[0].siteName
            siteNmRef.current.value = siteName
        }
    }, [blngSiteList])


    /**
     * 등록 버튼 클릭
     */
    const fn_registPopOpen = () => {
        setDetailData(null);
        setModalIsOpen(true);
    }

    const fn_delete = () => {
        let checkedData: number[] = getCheckedItems(datatable, "companySeq")
        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }
        if (!window.confirm(messageCodeDic['004'])) {
            return;
        }
        axios.post('/admin/api/company/deleteCmpnyData', {delList: checkedData}).then(rsltData => {
                if (rsltData.data > 0) {
                    toast.success(messageCodeDic['002'])
                    datatable?.draw()
                } else {
                    toast.error(errorCodeDic['003'])
                }

            }
        ).catch(() => {
                toast.error(errorCodeDic['003'])
            }
        )
    }

    return (
        <Container fluid className="bg-white px-4 pt-4 sh-text-font_primary">

            <SubMenuTitle title={'협력업체 관리'} parentMenu={'현장 Admin'}/>
            <SiteSearchModal setModalIsOpen={setIsSitePopOpend} modalIsOpen={isSitePopOpend} blngSiteList={blngSiteList}
                             setBlngSiteList={setBlngSiteList} isSingleCheck={true} siteNm={siteNmRef.current?.value}/>

            <SiteCmpnyRgstModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen}
                                detailData={detailData} setIsSaveSuccess={setIsSaveSuccess}/>

            <div className="rounded-[5px] border mb-3 px-3">
                <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                    <Table className="sh-font-SP sh-fs-b2 my-3">
                        <tbody>
                        <tr className="border-top align-middle border-bottom">
                            <th className="sh-bg-th" style={{width:'6%'}}>업체명</th>
                            <td>
                                <SHInput type={'hidden'} name={'siteId'}
                                         value={user?.blngSiteId || defaultUser.blngSiteId}></SHInput>
                                <SHInput type={'hidden'} name={'companyGbCd'} value={'C001005'}></SHInput>
                                <input type={'text'} name={"companyName"} ref={cmpnyNmRef} className='form-control'/>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </SHSearch>
            </div>


            <div className='w-100 d-flex justify-content-end align-items-center mb-2'>
                <SHIconButton kind={'submit'} marginRight={'5px'} onClick={fn_registPopOpen}>등록</SHIconButton>
                <SHIconButton kind={'delete'} marginRight={'0'} onClick={fn_delete}>삭제</SHIconButton>
            </div>

            <div className={'ge-result'}>
                <Datatables
                    dataUrl={'/admin/api/company/retrieveCmpnyDataList'}
                    parameterFormRef={searchFormRef}
                    columns={[
                        {data: "cmpnyGbNm", title: "업체구분", textAlign: 'center'},
                        {data: "companyName", title: "업체명", textAlign: 'left'},
                        {data: "companyRegNum", title: "사업자등록번호", textAlign: 'center'},
                        {data: "sctrGbNm", title: "대표공종", textAlign: 'center'},
                        {data: "rcntUpdateDate", title: "최근 업데이트"},
                    ]}
                    checked={true}
                    index={true}

                    onLoad={(dt: any) => {
                        setDatatable(dt)
                    }}
                    onClick={(data) => {
                        setDetailData(data)
                        setModalIsOpen(true);
                    }
                    }
                />
            </div>
        </Container>
    );
};

export default SiteCmpnyMngPage;
