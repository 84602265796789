import DashboardPageLayout from "../pages/dashboard/DashboardPageLayout";
import HomePage from "../pages/home/HomePage";
import { RouteType } from "./config";
import DefaultPage from "../pages/dashboard/DefaultPage";
import DashboardIndex from "../pages/dashboard/DashboardIndex";
import ChangelogPage from "../pages/changelog/ChangelogPage";
import AnalyticsPage from "../pages/dashboard/AnalyticsPage";
import SaasPage from "../pages/dashboard/SaasPage";
import ComponentPageLayout from "../pages/component/ComponentPageLayout";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import AlertPage from "../pages/component/AlertPage";
import ButtonPage from "../pages/component/ButtonPage";
import MonitoringPage from "../pages/monitoring/MonitoringPage";
import DocumentationPage from "../pages/documentation/DocumentationPage";
import SiteConstructionManagementPageLayout from "../pages/siteConstructionManagement/SiteConstructionManagementPageLayout";
import ConstructionBaseInfoManagementPage from "../pages/siteConstructionManagement/ConstructionBaseInfoManagementPage";
import OrganizationChartManagementPage from "../pages/siteConstructionManagement/OrganizationChartManagementPage";
import EmergencyNetworkManagementPage from "../pages/siteConstructionManagement/EmergencyNetworkManagementPage";
import ConstructionProcessProgressManagementPage from "../pages/siteConstructionManagement/ConstructionProcessProgressManagementPage";
import NoticeManagementPageLayout from "../pages/noticeManagement/NoticeManagementPageLayout";
import NoticeRegistrationPage from "../pages/noticeManagement/NoticeRegistrationPage";
import ManPowerManagementPageLayout from "../pages/manpowerManagement/ManPowerManagementPageLayout";
import EmployeeManagementPage from "../pages/manpowerManagement/EmployeeManagementPage";
import ManagerManagementPage from "../pages/manpowerManagement/ManagerManagementPage";
import DepartureStatusManagementPage from "../pages/manpowerManagement/DepartureStatusManagementPage";
import WorkSectionStatusManagement from "../pages/manpowerManagement/WorkSectionStatusManagement";
import SafetyEducationManagementPageLayout from "../pages/safetyEducationManagement/SafetyEducationManagementPageLayout";
import SafetyEducationManagementPage from "../pages/safetyEducationManagement/SafetyEducationManagementPage";
import SiteSafetyManagementPageLayout from "../pages/siteSafetyManagement/SiteSafetyManagementPageLayout";
import RiskAssessmentManagementPage from "../pages/siteSafetyManagement/RiskAssessmentManagementPage";
import SupplierRiskAssessmentManagementPage from "../pages/siteSafetyManagement/SupplierRiskAssessmentManagementPage";
import WorkPermitManagementPage from "../pages/siteSafetyManagement/WorkPermitManagementPage";
import SmartTBMManagementPage from "../pages/siteSafetyManagement/SmartTBMManagementPage";
import IncongruityManagementPage from "../pages/siteSafetyManagement/IncongruityManagementPage";
import MSDSManagementPage from "../pages/siteSafetyManagement/MSDSManagementPage";
import SiteEquipmentManagementPageLayout from "../pages/siteEquipmentManagement/SiteEquipmentManagementPageLayout";
import EquipmentManagementBeforeArrivalPage from "../pages/siteEquipmentManagement/EquipmentManagementBeforeArrivalPage";
import DailyEquipmentInspectionPage from "../pages/siteEquipmentManagement/DailyEquipmentInspectionPage";
import SafetyWorkReportManagementPageLayout from "../pages/safetyWorkReportManagement/SafetyWorkReportManagementPageLayout";
import SafetyWorkMeetingManagementPage from "../pages/safetyWorkReportManagement/SafetyWorkMeetingManagementPage";
import SafetyWorkReportManagementPage from "../pages/safetyWorkReportManagement/SafetyWorkReportManagementPage";
import SafetyInformationBoardPageLayout from "../pages/safetyInformationBoard/SafetyInformationBoardPageLayout";
import SafetyEducationContentsPage from "../pages/safetyInformationBoard/SafetyEducationContentsPage";
import SafetyLawPage from "../pages/safetyInformationBoard/SafetyLawPage";
import SafetyManagementGeneralBulletinBoardPage from "../pages/safetyInformationBoard/SafetyManagementGeneralBulletinBoardPage";
import SiteManagementAdminPage from "../pages/siteManagementAdmin/SiteManagementAdminPage";
import MyPageLayout from "../pages/myPage/MyPageLayout";
import MyInformationManagementPage from "../pages/myPage/MyInformationManagementPage";
import ApprovalStatusManagementPage from "../pages/myPage/ApprovalStatusManagementPage";
import CompanyManagementPage from "../pages/companyManagement/CompanyManagementPage";
import CompanyManagementPageLayout from "../pages/companyManagement/CompanyManagementPageLayout";

/* SVG Icon */
import { ReactComponent as IconMonitoring} from '../assets/images/ico/icon-monitoring.svg';
import { ReactComponent as IconSiteConstruction} from '../assets/images/ico/icon-siteconstruction.svg';
import { ReactComponent as IconNotice} from '../assets/images/ico/icon-notice.svg';
import { ReactComponent as IconManpower} from '../assets/images/ico/icon-manpower.svg';
import { ReactComponent as IconSafetyEducation} from '../assets/images/ico/icon-safetyeducation.svg';
import { ReactComponent as IconSiteSafety} from '../assets/images/ico/icon-sitesafety.svg';
import { ReactComponent as IconFieldEquipment} from '../assets/images/ico/icon-fieldequipment.svg';
import { ReactComponent as IconSafetyWork} from '../assets/images/ico/icon-safetywork.svg';
import { ReactComponent as IconSafetyInformation} from '../assets/images/ico/icon-safetyinformation.svg';
import { ReactComponent as IconSiteManagement} from '../assets/images/ico/icon-sitemanagement.svg';
import {ReactComponent as IconIot} from '../assets/images/ico/icon-iot.svg';
import DngrEvalMngPage from "../pages/dngrEvalMng/DngrEvalMngPage";
import SiteSftyChkPrvntPage from "../pages/safetyWorkReportManagement/SiteSftyChkPrvntPage";
import HeadMngrManagementPage from "../pages/manpowerManagement/HeadMngrManagementPage";
import SosRqstHisPage from "../pages/noticeManagement/SosRqstHisPage";
import WrkDisruptRqstHisPage from "../pages/noticeManagement/WrkDisruptRqstHisPage";
import PreWrkPrmtPage from "../pages/siteSafetyManagement/PreWrkPrmtPage";
import SiteAdminPageLayout from "../pages/siteManagementAdmin/SiteAdminPageLayout";
import NfcQrManagePage from "../pages/siteManagementAdmin/NfcQrManagePage";
import NtcRcvPage from "../pages/noticeManagement/NtcRcvPage";
import SiteSftyChkActPage from "../pages/safetyWorkReportManagement/SiteSftyChkActPage";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import HeadCompanyManagementPage from "../pages/headoffice/HeadCompanyManagementPage";
import SiteCmpnyMngPage from "../pages/siteManagementAdmin/SiteCmpnyMngPage";
import SiteDocsPage from "../pages/safetyWorkReportManagement/SiteDocsPage";
import IncidentMain from "../pages/incident/IncidentMain";
import WrkrMoveHistPage from "../pages/manpowerManagement/WrkrMoveHistPage";
import RiskEvalCheckPage from "../pages/riskEval/RiskEvalCheckPage";
import EqmntIncgrtyMngPage from "../pages/siteEquipmentManagement/EqmntIncgrtyMngPage";
import VideoMngPage from "../pages/safetyWorkReportManagement/VideoMngPage";
import SmartIotPage from "../pages/sensor/SmartIotPage";

const ToPage = ({ link }: any) => {
  const navigate = useNavigate();
  useEffect(()=>{
    navigate(link)
  },[])
  return (
      <>...</>
  );
};
const appRoutes: RouteType[] = [
  {
    index: true,
    element: <ToPage link={"/monitoring"} />,
    state: "home"
  },
  {
    path: "/monitoring",
    element: <HomePage />,
    /*element: <MonitoringPage />,*/
    state: "monitoring",
    sidebarProps: {
      displayText: "모니터링",
      icon: <IconMonitoring />
    }
  },

  {
    path: "/siteConstructionManagement",
    element: <SiteConstructionManagementPageLayout />,
    state: "siteConstructionManagement",
    sidebarProps: {
      displayText: "현장공사관리",
      icon: <IconSiteConstruction />
    },
    child: [

      {
        path: "/siteConstructionManagement/constructionBaseInfoManagement",
        element: <ConstructionBaseInfoManagementPage />,
        state: "siteConstructionManagement.constructionBaseInfoManagement",
        sidebarProps: {
          displayText: "공사기본정보관리"
        },
      },
      {
        path: "/siteConstructionManagement/organizationChartManagement",
        element: <OrganizationChartManagementPage />,
        state: "siteConstructionManagement.organizationChartManagement",
        sidebarProps: {
          displayText: "조직도 관리"
        }
      },
      {
        path: "/siteConstructionManagement/emergencyNetworkManagement",
        element: <EmergencyNetworkManagementPage />,
        state: "siteConstructionManagement.emergencyNetworkManagement",
        sidebarProps: {
          displayText: "비상연락망 관리"
        }
      },
      {
        path: "/siteConstructionManagement/ConstructionProcessProgressManagement",
        element: <ConstructionProcessProgressManagementPage />,
        state: "siteConstructionManagement.ConstructionProcessProgressManagement",
        sidebarProps: {
          displayText: "공사공정진행관리"
        }
      }
    ]
  },
  {
    path: "/noticeManagement",
    element: <NoticeManagementPageLayout />,
    state: "noticeManagement",
    sidebarProps: {
      displayText: "공지사항 관리",
      icon: <IconNotice />
    },
    child: [
      {
        path: "/noticeManagement/noticeRegistration",
        element: <NoticeRegistrationPage />,
        state: "noticeManagement.noticeRegistration",
        sidebarProps: {
          displayText: "공지사항 등록"
        },
      },
      {
        path: "/noticeManagement/SosRqstHis",
        element: <SosRqstHisPage />,
        state: "noticeManagement.SosRqstHis",
        sidebarProps: {
          displayText: "SOS 요청 이력"
        },
      },
      {
        path: "/noticeManagement/WrkDisruptRqstHis",
        element: <WrkDisruptRqstHisPage />,
        state: "noticeManagement.WrkDisruptRqstHis",
        sidebarProps: {
          displayText: "작업중지 요청 이력"
        },
      },
    ]
  },

  {
    path: "/manPowerManagement",
    element: <ManPowerManagementPageLayout />,
    state: "manPowerManagement",
    sidebarProps: {
      displayText: "인력 관리",
      icon: <IconManpower />
    },
    child: [

      {
        path: "/manPowerManagement/employeeManagement",
        element: <EmployeeManagementPage />,
        state: "manPowerManagement.employeeManagement",
        sidebarProps: {
          displayText: "근로자 관리"
        },
      },
      {
        path: "/manPowerManagement/managerManagement",
        element: <ManagerManagementPage />,
        state: "manPowerManagement.managerManagement",
        sidebarProps: {
          displayText: "관리자 관리"
        }
      },
      {
        path: "/manPowerManagement/departureStatusManagementPage",
        element: <DepartureStatusManagementPage />,
        state: "manPowerManagement.departureStatusManagementPage",
        sidebarProps: {
          displayText: "출역 현황 관리"
        }
      },
      {
        path: "/manPowerManagement/WorkSectionStatusManagement",
        element: <WorkSectionStatusManagement />,
        state: "manPowerManagement.WorkSectionStatusManagement",
        sidebarProps: {
          displayText: "작업 구간 현황 관리"
        }
      },
      {
        path: "/manPowerManagement/wrkrMoveHist",
        element: <WrkrMoveHistPage />,
        state: "manPowerManagement.wrkrMoveHist",
        sidebarProps: {
          displayText: "근로자 이동기록 현황"
        }
      }
    ]
  },
  {
    path: "/safetyEducationManagement",
    element: <SafetyEducationManagementPageLayout />,
    state: "safetyEducationManagement",
    sidebarProps: {
      displayText: "안전교육 관리",
      icon: <IconSafetyEducation />
    },
    child: [
      {
        path: "/safetyEducationManagement/safetyEducationManagement",
        element: <SafetyEducationManagementPage />,
        state: "safetyEducationManagement.safetyEducationManagement",
        sidebarProps: {
          displayText: "안전교육 관리"
        },
      },
    ]
  },
  {
    path: "/siteSafetyManagement",
    element: <SiteSafetyManagementPageLayout />,
    state: "siteSafetyManagement",
    sidebarProps: {
      displayText: "현장안전관리",
      icon: <IconSiteSafety />
    },
    child: [

      {
        path: "/siteSafetyManagement/riskAssessmentManagement",
        element: <RiskAssessmentManagementPage />,
        state: "siteSafetyManagement.riskAssessmentManagement",
        sidebarProps: {
          displayText: "위험성평가 실시"
        },
      },
      {
        path: "/siteSafetyManagement/WorkPermitManagement",
        element: <WorkPermitManagementPage />,
        state: "siteSafetyManagement.WorkPermitManagement",
        sidebarProps: {
          displayText: "작업허가서 작성"
        }
      },
      {
        path: "/siteSafetyManagement/preWrkPrmtPage",
        element: <PreWrkPrmtPage />,
        state: "siteSafetyManagement.preWrkPrmtPage",
        sidebarProps: {
          displayText: "사전작업허가제(PTW)"
        }
      },
      {
        path: "/siteSafetyManagement/SmartTBMManagement",
        element: <SmartTBMManagementPage />,
        state: "siteSafetyManagement.SmartTBMManagement",
        sidebarProps: {
          displayText: "스마트 TBM 관리"
        }
      },
      {
        path: "/siteSafetyManagement/incongruityManagement",
        element: <IncongruityManagementPage />,
        state: "siteSafetyManagement.incongruityManagement",
        sidebarProps: {
          displayText: "부적합 관리"
        }
      },
      {
        path: "/siteSafetyManagement/msdsManagement",
        element: <MSDSManagementPage />,
        state: "siteSafetyManagement.msdsManagement",
        sidebarProps: {
          displayText: "MSDS 관리"
        }
      },
      {
        path: "/siteSafetyManagement/icdntOcrMng",
        element: <IncidentMain />,
        state: "siteSafetyManagement.icdntOcrMng",
        sidebarProps: {
          displayText: "사고발생 관리"
        }
      },
      {
        path: "/siteSafetyManagement/riskEvalChk",
        element: <RiskEvalCheckPage />,
        state: "siteSafetyManagement.riskEvalChk",
        sidebarProps: {
          displayText: "위험성평가 점검"
        }
      },
      {
        path: "/siteSafetyManagement/siteSftyChkPage",
        element: <SiteSftyChkActPage />,
        state: "siteSafetyManagement.siteSftyChkPage",
        sidebarProps: {
          displayText: "현장안전점검"
        },
      },
    ]
  },
  {
    path: "/siteEquipmentManagement",
    element: <SiteEquipmentManagementPageLayout />,
    state: "siteEquipmentManagement",
    sidebarProps: {
      displayText: "현장장비관리",
      icon: <IconFieldEquipment />
    },
    child: [
      {
        path: "/siteEquipmentManagement/smrtIot",
        element: <SmartIotPage />,
        /*element: <MonitoringPage />,*/
        state: "siteEquipmentManagement.smrtIot",
        sidebarProps: {
          displayText: "스마트 IOT",
        }
      },
      {
        path: "/siteEquipmentManagement/equipmentManagementBeforeArrival",
        element: <EquipmentManagementBeforeArrivalPage />,
        state: "siteEquipmentManagement.equipmentManagementBeforeArrival",
        sidebarProps: {
          displayText: "반입 전 장비 등록"
        },
      },
      {
        path: "/siteEquipmentManagement/dailyEquipmentInspection",
        element: <DailyEquipmentInspectionPage />,
        state: "siteEquipmentManagement.dailyEquipmentInspection",
        sidebarProps: {
          displayText: "일일장비점검"
        },
      },
      {
        path: "/siteEquipmentManagement/eqmntIncgrtyMng",
        element: <EqmntIncgrtyMngPage />,
        state: "siteEquipmentManagement.eqmntIncgrtyMng",
        sidebarProps: {
          displayText: "중장비 부적합 관리"
        },
      },
    ]
  },
  {
    path: "/safetyWorkReportManagement",
    element: <SafetyWorkReportManagementPageLayout />,
    state: "safetyWorkReportManagement",
    sidebarProps: {
      displayText: "안전업무보고서 관리",
      icon: <IconSafetyWork />
    },
    child: [
      {
        path: "/safetyWorkReportManagement/safetyWorkMeetingManagement",
        element: <SafetyWorkMeetingManagementPage />,
        state: "safetyWorkReportManagement.safetyWorkMeetingManagement",
        sidebarProps: {
          displayText: "안전업무 회의록 관리"
        },
      },
      {
        path: "/safetyWorkReportManagement/safetyWorkReportManagementPage",
        element: <SafetyWorkReportManagementPage />,
        state: "safetyWorkReportManagement.safetyWorkReportManagementPage",
        sidebarProps: {
          displayText: "안전 정보 통계 관리"
        },
      },

      {
        path: "/safetyWorkReportManagement/siteDocsPage",
        element: <SiteDocsPage />,
        state: "safetyWorkReportManagement.siteDocsPage",
        sidebarProps: {
          displayText: "현장문서함"
        },
      },
      {
        path: "/safetyWorkReportManagement/videoMngPage",
        element: <VideoMngPage />,
        state: "safetyWorkReportManagement.videoMngPage",
        sidebarProps: {
          displayText: "동영상 기록관리"
        },
      },
    ]
  },
  {
    path: "/safetyInformationBoard",
    element: <SafetyInformationBoardPageLayout />,
    state: "safetyInformationBoard",
    sidebarProps: {
      displayText: "안전 정보 및 공지 관리",
      icon: <IconSafetyInformation />
    },
    child: [
      {
        path: "/safetyInformationBoard/safetyEducationContents",
        element: <SafetyEducationContentsPage />,
        state: "safetyInformationBoard.safetyEducationContents",
        sidebarProps: {
          displayText: "안전교육컨텐츠"
        },
      },
      {
        path: "/safetyInformationBoard/safetyLawPage",
        element: <SafetyLawPage />,
        state: "safetyInformationBoard.safetyLawPage",
        sidebarProps: {
          displayText: "안전법률"
        },
      },
      {
        path: "/safetyInformationBoard/safetyManagementGeneralBulletinBoard",
        element: <SafetyManagementGeneralBulletinBoardPage />,
        state: "safetyInformationBoard.safetyManagementGeneralBulletinBoard",
        sidebarProps: {
          displayText: "안전 관리 일반 게시판"
        },
      },
    ]
  },
  {
    path: "/siteManagementAdminPage",
    element: <SiteAdminPageLayout />,
    state: "siteManagementAdminPage",
    sidebarProps: {
      displayText: "현장 Admin",
      icon: <IconSiteManagement />
    },
    child: [
      {
        path: "/siteManagementAdminPage/dngrEvalMng",
        element: <DngrEvalMngPage />,
        state: "siteManagementAdminPage.dngrEvalMng",
        sidebarProps: {
          displayText: "위험성평가설정"
        },
      },
      {
        path: "/siteManagementAdminPage/siteManagementAdmin",
        element: <SiteManagementAdminPage />,
        state: "siteManagementAdminPage.siteManagementAdmin",
        sidebarProps: {
          displayText: "현장세부정보설정"
        },
      },
      {
        path: "/siteManagementAdminPage/nfcQrManage",
        element: <NfcQrManagePage />,
        state: "siteManagementAdminPage.nfcQrManage",
        sidebarProps: {
          displayText: "NFC/QR 관리"
        },
      },
      {
        path: "/siteManagementAdminPage/cmpnyManage",
        element: <SiteCmpnyMngPage />,
        state: "siteManagementAdminPage.cmpnyManage",
        sidebarProps: {
          displayText: "협력업체 관리"
        },
      },

    ]
  },
  {
    path: "/myPage",
    element: <MyPageLayout />,
    state: "myPage",
    sidebarProps: {
      displayText: "My Page",
      icon: <AppsOutlinedIcon />
    },
    child: [
      {
        path: "/myPage/myInformationManagement",
        element: <MyInformationManagementPage />,
        state: "myPage.myInformationManagement",
        sidebarProps: {
          displayText: "나의 정보 관리"
        },
      },
      {
        path: "/myPage/companyManagement",
        element: <CompanyManagementPage />,
        state: "myPage.companyManagement",
        sidebarProps: {
          displayText: "소속 업체 관리"
        },
      },
      {
        path: "/myPage/approvalStatusManagementPage",
        element: <ApprovalStatusManagementPage />,
        state: "myPage.approvalStatusManagementPage",
        sidebarProps: {
          displayText: "결재 현황 관리"
        },
      },
    ]
  },
];

export default appRoutes;
