import React, {useContext, useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {ReactComponent as IconTotal} from "../../images/icon_stack.svg";
import {ReactComponent as IconWorking} from "../../images/icon_barricade.svg";
import {ReactComponent as IconWorkDone} from "../../images/icon_square_check.svg";
import restapi from "../../helper/restAPIHelper";
import {CmmnFn} from "../../helper/CmmnHelper";
import {UserContext} from "../../context/User";
import {getLoginUser} from "../../helper/SecurityHelper";
import WrkPrmtInfoListModal from "../headoffice/modal/WrkPrmtInfoListModal";




const SSDashWorkPermitDoc = () => {
    const {user} = useContext<any>(UserContext);
    const defaultUser = getLoginUser();
    const [wrkPrmtDataList, setWrkPrmtDataList] = useState<any>([]);
    const [dataList, setDataList] = useState<any>([]);
    const [ingDataList, setIngDataList] = useState<any>([]);
    const [cmpltdDataList, setCmpltdDataList] = useState<any>([]);
    const [statData, setStatData] = useState({totalCnt: 0, ingCnt: 0, cmpltdCnt: 0});
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

    useEffect(() => {
        const siteId = user?.blngSiteId || defaultUser.blngSiteId;
        restapi.retrieveWrkPrmtDataTotList({siteId: siteId, wrkDt: CmmnFn.fn_getCurrentDate(), prcsStatCd: 'S001004', isForMonitoring: true}).then((rsltData: any) => {
            setWrkPrmtDataList(rsltData);

            const totalCnt = rsltData.length;
            const ingDataList = rsltData.filter((data: any) => (data.wrkStatNm == '작업중'))
            const cmpltdDataList =rsltData.filter((data: any) => (data.wrkStatNm == '작업완료'))
            setIngDataList(ingDataList);
            setCmpltdDataList(cmpltdDataList);

            setStatData({totalCnt:totalCnt, ingCnt:ingDataList.length, cmpltdCnt:cmpltdDataList.length});
        })
    }, [user])

    const fn_openWrkPrmtInfoListModal = (srchGb: string) => {
        if(srchGb == 'tot'){
            setDataList(wrkPrmtDataList);
        }else if(srchGb == 'ing'){
            setDataList(ingDataList);
        }else if(srchGb == 'cmpltd'){
            setDataList(cmpltdDataList);
        }

        setModalIsOpen(true);
    }

    return(
        <>
            <WrkPrmtInfoListModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} dataList={dataList}></WrkPrmtInfoListModal>
            <Container fluid className="sh-bg-pane rounded-[5px] py-2 px-3 h-100">
                <Row>
                    <Col>
                        <div className="pe-2 sh-fs-h4 sh-fw-5 sh-font-SD mb-3">작업지시서</div>
                        <div className="d-flex justify-content-between" style={{height: '72%'}}>
                            <div style={{width: '32%'}}>
                                <div onClick={() => {fn_openWrkPrmtInfoListModal('tot')}} className="cursor-pointer rounded-3 d-flex flex-column justify-content-between h-100 p-3" style={{backgroundColor: '#5685F7'}}>
                                    <div className="sh-fs-b2 sh-fw-6 mb-4">전체</div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <IconTotal style={{width: '3rem'}}/>
                                        <div className="text-end sh-fs-h4 sh-fw-5">
                                            <span className="sh-fs-h1 me-1">{statData.totalCnt}</span>건
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{width: '32%'}}>
                                <div onClick={() => {fn_openWrkPrmtInfoListModal('ing')}} className="cursor-pointer rounded-3 d-flex flex-column justify-content-between h-100 p-3" style={{backgroundColor: '#35D0B7'}}>
                                    <div className="sh-fs-b2 sh-fw-6 mb-4">작업중</div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <IconWorking style={{width: '3rem'}}/>
                                        <div className="text-end sh-fs-h4 sh-fw-5">
                                            <span className="sh-fs-h1 me-1">{statData.ingCnt}</span>건
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{width: '32%'}}>
                                <div onClick={() => {fn_openWrkPrmtInfoListModal('cmpltd')}} className="cursor-pointer rounded-3 d-flex flex-column justify-content-between h-100 p-3" style={{backgroundColor: '#FF718B'}}>
                                    <div className="sh-fs-b2 sh-fw-6 mb-4">작업완료</div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <IconWorkDone style={{width: '3rem'}}/>
                                        <div className="text-end sh-fs-h4 sh-fw-5">
                                            <span className="sh-fs-h1 me-1">{statData.cmpltdCnt}</span>건
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default SSDashWorkPermitDoc;
