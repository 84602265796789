import React, {useContext, useEffect, useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import {UserContext} from "../../context/User";
import {getLoginUser} from "../../helper/SecurityHelper";
import restapi from "../../helper/restAPIHelper";

const DashProcessRate = () => {

    const {user} = useContext<any>(UserContext);
    const defaultUser = getLoginUser();
    const [siteInfo, setSiteInfo] = useState<any>({})

    useEffect(() => {
        const siteId = user?.blngSiteId || defaultUser.blngSiteId;
        restapi.retrieveSiteDataListNoPaging({siteId:siteId}).then((rsltData: any) => {
            if(rsltData.length > 0){
                setSiteInfo(rsltData[0]);
            }
        })
    }, [user])

    return(
        <>
            <Container className="sh-bg-pane rounded-[5px] py-2 px-3">
                <Row>
                    <Col bsPrefix="col-6" className="d-flex align-items-center">
                        <div className="w-100">
                            <div className="text-start ps-2 sh-text-primary sh-fw-6 sh-fs-h4">D-Day</div>
                            <div className="text-end sh-lh-h1">
                                <span className="sh-fw-5 sh-fs-h1 me-1">{siteInfo.remainDays}</span>
                                <span className="sh-fs-b3 sh-text-font_secondary02">일</span>
                            </div>
                        </div>
                    </Col>
                    <Col bsPrefix="col-6" className="d-flex align-items-end justify-content-end pb-1">
                        <div>
                            <div className="text-end sh-fs-b3">
                                <span className="me-2 sh-text-font_secondary02">착공일</span>
                                <span>{siteInfo.constructionStartDatePoint}</span>
                            </div>
                            <div className="text-end sh-fs-b3">
                                <span className="me-2 sh-text-font_secondary02">준공일</span>
                                <span>{siteInfo.constructionEndDatePoint}</span>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="pt-1 px-0">
                    <Col className="pe-1 ps-3">
                        <div className="text-center rounded-3 py-1" style={{border:'1px solid rgba(71,71,71,0.5)'}}>
                            <div className="sh-text-font_secondary02 sh-fs-b4">현장대리인</div>
                            <div className="sh-fs-b3">{siteInfo.siteRepNm || '미정'}</div>
                        </div>
                    </Col>
                    <Col className="px-1">
                        <div className="text-center rounded-3 sh-bg-gray py-1" style={{border:'1px solid rgba(71,71,71,0.5)'}}>
                            <div className="sh-text-font_secondary02 sh-fs-b4">사업감리단</div>
                            <div className="sh-fs-b3">{siteInfo.siteSprvNm || '미정'}</div>
                        </div>
                    </Col>
                    <Col className="ps-1 pe-3">
                        <div className="text-center rounded-3 sh-bg-gray py-1" style={{border:'1px solid rgba(71,71,71,0.5)'}}>
                            <div className="sh-text-font_secondary02 sh-fs-b4">안전관리자</div>
                            <div className="sh-fs-b3">{siteInfo.sftyMngrNm || '미정'}</div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default DashProcessRate;

