import React, {useContext, useEffect, useState} from "react";
import {Container, Row, Col, Table} from "react-bootstrap";
import {ReactComponent as IconRight} from "../../assets/images/ico/icon-right.svg";
import IconWthrCld from '../../assets/images/ico/icon-weather-a-04.png';
import SHSelect from "../../components/common/SHSelect";
import {TopMenuContext} from "../../context/TopMenu";
import restapi from "../../helper/restAPIHelper";
import {transform} from "ol/proj";
import {SiteContext} from "../../context/Site";
import {Doughnut} from "react-chartjs-2";
import {Constatnts} from "../../util/Constants";
import ImageLoader from "../../components/ImageLoader";

export default function HOConstructionSummary() {
    const {setMenu, setParam} = useContext<any>(TopMenuContext);
    const [guCd, setGuCd] = useState<string>('');
    const [siteId, setSiteId] = useState<string>('');
    const {site, setSite} = useContext<any>(SiteContext);
    const [actStatData, setActStatData] = useState<any>({});
    const [cctvDataList, setCctvDataList] = useState<any>([]);
    const [cctvData, setCctvData] = useState<any>({});
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [data, setData] = useState<any>({
        labels: ['미조치', '완료'],
        datasets: [{
            data: [0, 0],
            backgroundColor: [
                'rgba(255, 113, 139, 1)',
                'rgba(86, 133, 247, 1)',

            ],
            borderColor: [
                'rgba(30, 31, 35, 1)',
                'rgba(30, 31, 35, 1)',
            ],
            borderWidth: 1,
            hoverOffset: 5,
        }],
    });
    const plugin = {
        id: 'emptyDoughnut',
        afterDraw(chart: any, args: any, options: any) {
            const {datasets} = chart.data;
            const {color, width, radiusDecrease} = options;
            let hasData = false;
            for (let i = 0; i < datasets.length; i += 1) {
                const dataset = datasets[i];
                hasData = dataset.data.length > 0;
            }
            if (!hasData) {
                const {chartArea: {left, top, right, bottom}, ctx} = chart;
                const centerX = (left + right) / 2;
                const centerY = (top + bottom) / 2;
                const r = Math.min(right - left, bottom - top) / 2;

                ctx.beginPath();
                ctx.lineWidth = width || 2;
                ctx.strokeStyle = color || 'rgba(255, 128, 0, 0.5)';
                ctx.arc(centerX, centerY, (r - radiusDecrease || 0), 0, 2 * Math.PI);
                ctx.stroke();
            }
        }
    };
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            emptyDoughnut: {
                color: 'rgba(255,255,0, 0.5)',
                width: 20,
                radiusDecrease: 10
            }
        },
        cutout: '70%',
    };
    function fn_open_cctv(){
        setParam({siteId: siteId})
        setMenu('siteCctv')
    }
    const fn_retrieveSiteCnstrInfo = (siteId: string) => {
        restapi.retrieveSiteDataListNoPaging({siteId: siteId, isSmSite: false}).then((rsltData: any) => {
            if(rsltData.length > 0){
                var item = rsltData[0]
                setSite(item);
                if(item.longitude && item.latitude){
                    var center = transform([item.longitude, item.latitude], 'EPSG:4326', 'EPSG:3857')
                    console.log(center)
                    var duration = 1000
                    // @ts-ignore
                    var map = window.___dashboard_map
                    var currentCenter = map.getView().getCenter();
                    map.getView().animate({
                        center: [((center[0] + currentCenter[0]) / 2), ((center[1] + currentCenter[1]) / 2)],
                        zoom : 10,
                        duration: 200
                    }, {
                        center: center,
                        zoom : 16,
                        duration: duration
                    });
                }
            }
        })

        restapi.retrieveSftyChkActStatData({siteId: siteId}).then((rsltData: any) => {
            var data: any = [rsltData.actNoCmpltCnt, rsltData.actCmpltCnt]
            if(rsltData.actNoCmpltCnt === undefined && rsltData.actCmpltCnt === undefined){
                data = []
            }
            if(rsltData.actNoCmpltCnt === 0 && rsltData.actCmpltCnt === 0){
                data = []
            }
            setData({
                labels: ['미조치', '완료'],
                datasets: [{
                    data: data,
                    backgroundColor: [
                        'rgba(255, 113, 139, 1)',
                        'rgba(86, 133, 247, 1)',

                    ],
                    borderColor: [
                        'rgba(30, 31, 35, 1)',
                        'rgba(30, 31, 35, 1)',
                    ],
                    borderWidth: 1,
                    hoverOffset: 5,
                }],
            })
            setActStatData(rsltData);
        })
    }

    useEffect(() => {
        if(siteId !== ''){
            fn_retrieveSiteCnstrInfo(siteId);
            fn_retrieveCctvDataList(siteId);
        }else{

        }
    }, [siteId])

    useEffect(() => {
        if(site){
            setSiteId(site.siteId);
            setGuCd(site.guCd);
        }

    }, [site])

    const fn_retrieveCctvDataList = (siteId: string) => {
        restapi.retrieveCctvList({siteId: siteId}). then((rsltData: any) => {
            setCctvDataList(rsltData);
            if(rsltData.length > 0){
                rsltData[0].idx = 0;
                setCctvData(rsltData[0]);
            }else{
                setCctvData([]);
            }
        })
    }

    const fn_nextCctv = (direction: string) => {
        let cctvIdx;

        if(direction == 'left'){
            if(cctvData.idx == 0){
                cctvIdx = cctvDataList.length - 1;
            }else{
                cctvIdx = cctvData.idx - 1;
            }
        }else if(direction == 'right'){
            if(cctvData.idx == cctvDataList.length -1){
                cctvIdx = 0;
            }else{
                cctvIdx = cctvData.idx + 1;
            }
        }
        cctvDataList[cctvIdx].idx = cctvIdx;
        let cstmCctvData = cctvDataList[cctvIdx];
        setCctvData(cstmCctvData);
    }

    return(
        <>
            <Container className="sh-bg-pane rounded-[5px] pt-2 px-3">
                <Row className="px-0 mb-2">
                    <Col bsPrefix="col-7" className="d-flex align-items-center w-100">
                        <div className="w-100 d-flex align-items-center justify-content-between mb-2">
                            <span className="pe-2 sh-fs-h4 sh-fw-5 sh-font-SD">현장 공사 개요</span>
                            <div className={'cursor-pointer'} onClick={() => setMenu('totSiteStat')}>
                            <IconRight style={{width:'20px',marginBottom:'1px'}} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col bsPrefix="col-4" className="pb-2">
                        <label htmlFor="form-label ho_sel_emb">지역</label>
                        <SHSelect initOptionGb={'sel'} className={'form-select'} name={'guCd'} codeGb={'bjd'} codeGrpId={'SEOUL'} value={guCd} onChange={e=> setGuCd(e.target.value)}></SHSelect>
                    </Col>
                    <Col bsPrefix="col-8">
                        <label htmlFor="form-label ho_sel_field">현장</label>
                        <SHSelect  initOptionGb={'sel'} className={'form-control'} name={'siteId'} codeGb={'site'} value={siteId} onChange={e=> setSiteId(e.target.value)} selParam={{guCd:guCd, isSmSite: false}} ></SHSelect>
                    </Col>
                </Row>
                <hr className="my-2" style={{borderBottom:'1px dashed #36363a'}} />
                <Row>
                    <Col>
                        <Container fluid className="px-1">
                            <Row>
                                <Col>
                                    <div className="d-flex justify-content-between align-items-center mb-2" style={{height:"50px"}}>
                                        <span className="pe-2 sh-fs-h4 sh-fw-5 sh-font-SD">{site?.siteName || '선택된 현장이 없습니다'}</span>
                                        <img src={IconWthrCld} alt="날씨" style={{height:'50px',objectFit:'cover'}}/>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div style={{position:"relative"}}>
                                    {site?.cctvCnt > 0 ?
                                        <>
                                            <div className="position-relative cursor-pointer" onClick={() => {
                                                //<CctvViewerModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} dataList={cctvDataList} detailData={cctvData}/>
                                                var url = process.env.REACT_APP_CCTV_VIEWER_URL + "?url=" + encodeURIComponent(cctvData.rtspUrl)
                                                // @ts-ignore
                                                if(window.___cctv_popup) {
                                                    // @ts-ignore
                                                    window.___cctv_popup.close()
                                                }
                                                // @ts-ignore
                                                window.___cctv_popup = window.open(url,  "_blank","width=658,height=445,top=100,left=100");
                                                // @ts-ignore
                                                window.___cctv_popup.focus()
                                            }}>
                                                <ImageLoader className="rounded-[5px] object-cover w-100"
                                                             src={process.env.REACT_APP_CCTV_IMAGE_URL + "?url=" + encodeURIComponent(cctvData.rtspUrl)}
                                                             alt="cctv"
                                                             style={{height:"290px"}} />
                                            </div>
                                            <div className="rounded-br-[5px] px-3 py-2 " style={{backgroundColor:"#00000088", position: "absolute", bottom:0, right:0}}>
                                                <span className={"d-inline-block pe-2 cursor-pointer"} onClick={() => fn_nextCctv('left')} style={{borderRight:"solid 1px #36363A"}}><i className="sh-arrow left"></i></span>
                                                <span className={"d-inline-block ps-2 cursor-pointer"} onClick={() => fn_nextCctv('right')} ><i className="sh-arrow right"></i></span>
                                            </div>
                                        </>
                                         :
                                        <div className="position-relative">
                                            {/*
                                            <img className={"rounded-[5px] object-cover w-100"} style={{height:"290px", textAlign: "center", paddingTop: "20px"}} src={cctvBlank}/>
*/}
                                            <div className="rounded-[5px] object-cover w-100" style={{height:"290px", textAlign: "center", paddingTop: "20px", backgroundColor: "#cccccc88"}}>등록CCTV가 없습니다.</div>


                                        </div>
                                    }
                                    </div>
                                    <div className="text-end mt-3">
                                        <span className="sh-text-accent" style={{cursor:"pointer", border: "solid 1px #12E573", borderRadius:"3px", padding: "5px"}} onClick={fn_open_cctv}>현장 CCTV 더보기 </span>
                                    </div>
                                </Col>
                            </Row>
                            <Row className={"mt-3"} style={{borderTop:"dashed 1px #36363A"}}>
                                <Col className={"pt-2"} style={{height:"calc(100vh - 707px)", overflowY: "scroll"}}>
                                    <Table className="sh-fs-b3 sh-text-white">
                                        <colgroup>
                                            <col width={"10%"}/>
                                            <col width={"40%"}/>
                                            <col width={"10%"}/>
                                            <col width={"40%"}/>
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th>
                                                    <div className="border sh-bd-line_dark rounded-[5px] text-center h-100 sh-fw-5">
                                                        공사위치
                                                    </div>
                                                </th>
                                                <td>
                                                    {site?.siteAddress}
                                                </td>
                                                <th>
                                                    <div className="border sh-bd-line_dark rounded-[5px] text-center h-100 sh-fw-5">
                                                        공종
                                                    </div>
                                                </th>
                                                <td>
                                                    {site?.siteTypeNm}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <div className="border sh-bd-line_dark rounded-[5px] text-center h-100 sh-fw-5">
                                                        공사기간
                                                    </div>
                                                </th>
                                                <td colSpan={3}>
                                                    {site?.cnstrProd}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <div className="border sh-bd-line_dark rounded-[5px] text-center h-100 sh-fw-5">
                                                        안전점검 현황
                                                    </div>
                                                </th>
                                                <td colSpan={3}>
                                                        <div className="position-relative" style={{height:"120px"}}>
                                                            <Doughnut className="position-relative z-50" options={options} data={data} plugins={[plugin]} width="120px" height="120px" />
                                                            <div className="position-absolute z-10" style={{left: "60px", top:"60px", transform: 'translate(-50%, -50%)'}}>
                                                                <div className="sh-fs-b3 sh-text-font_secondary02">Total</div>
                                                                <div>
                                                                    <span className="sh-fw-5 sh-fs-h2">{actStatData.actNoCmpltCnt != undefined && actStatData.actCmpltCnt != undefined ? (actStatData.actNoCmpltCnt + actStatData.actCmpltCnt) : 0}</span>
                                                                    <span className="sh-fs-b3">건</span>
                                                                </div>
                                                            </div>
                                                            <div className="position-absolute z-10" style={{left: "140px", top:"60px", transform: 'translate(0, -50%)'}}>
                                                                <div className="sh-fs-b2">
                                                                    <div className="cursor-pointer"><span
                                                                        style={{color:'rgba(86, 133, 247, 1)'}}>●</span><span
                                                                        className="px-1">완료</span><span
                                                                        style={{color:'rgba(86, 133, 247, 1)'}}>{actStatData.actCmpltCnt}</span>
                                                                    </div>
                                                                    <div className="cursor-pointer"><span
                                                                        style={{color:'rgba(255, 113, 139, 1)'}}>●</span><span
                                                                        className="px-1">미조치</span><span
                                                                        style={{color:'rgba(255, 113, 139, 1)'}}>{actStatData.actNoCmpltCnt}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
