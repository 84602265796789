import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {fn_getCurrentDate} from "../../../helper/CmmnHelper";
import {getLoginUser} from "../../../helper/SecurityHelper";
import SHInput from "../../../components/common/SHInput";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import SHSelect from "../../../components/common/SHSelect";
import {ChangeMeta, NumberFormatBase} from "react-number-format";
import {Formatter} from "../../../helper/FormatHelper";
import SlctAuthChrgModal from "./SlctAuthChrgModal";
import restapi from "../../../helper/restAPIHelper";
import iconTrashBean from "../../../images/icon_remove.png";
import SHIconButton from '../../../components/common/SHIconButton';
import {ProgressContext} from "../../../context/Progress";


interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
    setIsSaveSuccess: Dispatch<SetStateAction<boolean>>;
    siteId: number;
}

const MngrRgstModal = (props: IProps) => {

    const currentDate = fn_getCurrentDate();
    const user = getLoginUser();
    const [isAuthChrgPopOpend, setIsAuthChrgPopOpend] = React.useState<boolean>(false);

    const [data, setData] = useState<any>()
    const [slctdAuthChrgData, setSlctdAuthChrgData] = useState<any>();
    const [authChrgDataList, setAuthChrgDataList] = useState<any>([]);
    const {spinner, setProcess} = useContext(ProgressContext);

    const fn_setFormData = (e: any) => {

        if (e.target.name == 'cnstrPrice') {
            e.target.value = parseInt(e.target.value.replaceAll(',', '')).toLocaleString()
        }
        setData({
            ...data,
            [e.target.name]: e.target.value
        })

    }

    useEffect(() => {
        if (slctdAuthChrgData) {
            let isDupl = false;
            authChrgDataList.map((data: any) => {
                if (data.siteId == slctdAuthChrgData.siteId) {
                    isDupl = true;
                }
            })
            if (isDupl) {
                toast.warn('중복된 현장 입니다.');
            } else {
                let cstmAuthChrgDataList: any = authChrgDataList;
                if (cstmAuthChrgDataList.length == 0) {
                    slctdAuthChrgData.isRep = true
                }

                cstmAuthChrgDataList.push(slctdAuthChrgData);
                setAuthChrgDataList(cstmAuthChrgDataList);
            }

            setSlctdAuthChrgData(null);
        }
    }, [slctdAuthChrgData])

    const fn_onAfterOpen = () => {
        // 신규
        if (!props.detailData) {
            setData({blngCmpnySeq: user.blngCmpnySeq});

            setAuthChrgDataList([]);
            // 상세
        } else {
            setData(props.detailData);
            restapi.retrieveUserSiteAuthDataList(props.detailData.userSeq).then((rsltData) => {
                setAuthChrgDataList(rsltData);
            })

        }
        setSlctdAuthChrgData(null)
    }


    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const submit = (e: any) => {
        e.preventDefault()

    }


    const fn_openSlctAuthChrgPop = () => {

        if (!data || !data.blngCmpnySeq) {
            toast.warn('소속업체를 선택해 주십시오.')
            return;
        }
        setIsAuthChrgPopOpend(true);
    }


    const fn_registData = () => {
        console.log('data: ', data);

        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if (!data.userName) {
            toast.warn('성명을 입력해 주십시오.')
            return;
        }


        if (!data.userPhone) {
            toast.warn('휴대전화 번호를 입력해 주십시오.');
            return;
        }
        if (!data.blngCmpnySeq) {
            toast.warn('소속업체를 선택해 주십시오.');
            return;
        }

        if (!data.userId) {
            toast.warn('아이디를 입력해 주십시오.');
            return;
        }

        if (!props.detailData) {
            if (!data.userPassword) {
                toast.warn('비밀번호를 입력해 주십시오');
                return;
            }

            if (!data.userPasswordCnfrm) {
                toast.warn('비밀번호를 확인해 주십시오');
                return;
            }

            if (data.userPassword != data.userPasswordCnfrm) {
                toast.warn('설정한 비밀번호가 일치하지 않습니다')
                return;
            }
        }

        if (authChrgDataList.length == 0) {
            toast.warn('현장별 권한 및 직책 선택을 입력해 주십시오')
            return;
        }


        let param = data;
        param.userPhone = param.userPhone.replaceAll('-', '');
        param.authChrgDataList = authChrgDataList;
        param.rgstrSeq = user.userSeq;
        param.updtrSeq = user.userSeq

        if (!window.confirm('저장 하시겠습니까?')) {
            return;
        }

        console.log('param: ', param);

        axios.post('/admin/api/user/saveMngrData', param).then((result) => {
                if (result.data > 0) {
                    toast.success(messageCodeDic['001'])
                    props.setModalIsOpen(false);
                    props.setIsSaveSuccess(true);
                } else {
                    if (result.data == -12) {
                        toast.error('이미 존재하는 아이디 입니다.');
                    } else {
                        toast.error(errorCodeDic['003']);
                    }
                    props.setIsSaveSuccess(false);
                }
            }
        )


    }

    const fn_delRow = (idx: number) => {
        console.log('authChrgDataList[idx]: ', authChrgDataList[idx]);
        console.log('siteId: ', props.siteId);

        restapi.getUserSession(authChrgDataList[idx].userSeq).then((userData: any) => {
            if(userData.blngSiteId == authChrgDataList[idx].siteId){
                toast.warn('현재 해당 현장 권한을 현장관리자가 사용중에 있습니다.');
            }else{
                let cstmAuthChrgDataList: any = [].concat(authChrgDataList);

                cstmAuthChrgDataList.splice(idx, 1);
                setAuthChrgDataList(cstmAuthChrgDataList);
            }

        })


    }

    const fn_chkIsRep = (idx: number) => {
        let cstmAuthChrgDataList: any = [].concat(authChrgDataList);
        cstmAuthChrgDataList.map((data: any, dataIdx: number) => {
            if (idx == dataIdx) {
                data.isRep = true
            } else {
                data.isRep = false
            }
        })
        setAuthChrgDataList(cstmAuthChrgDataList);

    }

    const fn_chageAuthChrgDataList = (e: any, idx: number) => {
        let cstmAuthChrgDataList: any = [].concat(authChrgDataList);
        cstmAuthChrgDataList.map((data: any, dataIdx: number) => {
            if (idx == dataIdx) {
                data[e.target.name] = e.target.value
            }
        })
        setAuthChrgDataList(cstmAuthChrgDataList);
    }

    const fn_initPassword = () => {
        if(!window.confirm('해당 사용자의 비밀번호를 초기화(\'0000\') 하시겠습니까?')){
            return;
        }

        spinner.start()
        axios.post('/admin/api/user/updateInitPassword', {userSeq:props.detailData.userSeq}).then((rsltData: any) => {
            if(rsltData.data > 0){
                toast.success(messageCodeDic['006']);
            }else{
                toast.error(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
        .finally(() => {
            spinner.stop();
        });
    }

    return (
        <div>
            <SlctAuthChrgModal setModalIsOpen={setIsAuthChrgPopOpend} modalIsOpen={isAuthChrgPopOpend}
                               slctdAuthChrgData={slctdAuthChrgData}
                               setSlctdAuthChrgData={setSlctdAuthChrgData} blngCmpnySeq={data?.blngCmpnySeq}/>

            <Modal
                className="sh-modal" size="xl"
                show={props.modalIsOpen}
                onShow={fn_onAfterOpen}
                onHide={fn_closeModal}

            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        관리자 등록/수정
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <form name={'form'}
                          onSubmit={submit}>
                        <div className="sh-modal-div">
                            <div className="div-header">
                                <div className='flex justify-content-between w-100 align-items-center'>
                                    <span>관리자 정보</span>
                                    <button className="sh-btn btn-secondary" type="button"
                                            onClick={fn_initPassword}>비밀번호 초기화
                                    </button>
                                </div>
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>성명</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput type="text" className="form-control" name={'userName'}
                                                     onChange={fn_setFormData} value={data?.userName}/>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>휴대전화</th>
                                        <td style={{width: '35%'}}>
                                            {
                                                //@ts-ignore
                                                <NumberFormatBase onChange={fn_setFormData} name={'userPhone'}
                                                                  className={'form-control'}
                                                                  value={data?.userPhone || ''}
                                                                  format={Formatter.phoneNumFormat}
                                                />
                                            }
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>직위</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput type="text" className="form-control" name={'position'}
                                                     onChange={fn_setFormData} value={data?.position}/>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>소속업체</th>
                                        <td style={{width: '35%'}}>
                                            <SHSelect initOptionGb={'sel'}
                                                      name={'blngCmpnySeq'} codeGb={'siteCmpny'}
                                                      onChange={(e) => {fn_setFormData(e); setAuthChrgDataList([]) }} selParam={{siteId: props.siteId}}
                                                      value={data?.blngCmpnySeq}></SHSelect>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <td colSpan={2}></td>
                                        <td colSpan={2}>
                                            <span className="font-semibold text-sm">※ 소속업체 변경 시 '현장 별 권한 및 직책 선택을 재설정 해주셔야 합니다.</span>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>아이디</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput type="text" className="form-control" name={'userId'} disabled={props.detailData ? true : false}
                                                     onChange={fn_setFormData} value={data?.userId}/>
                                        </td>
                                        {!props.detailData &&
                                            <>
                                                <th className="sh-bg-th" style={{width: '15%'}}>비밀번호</th>
                                                <td style={{width: '35%'}}>
                                                    <SHInput type="password" className="form-control"
                                                             name={'userPassword'}
                                                             onChange={fn_setFormData} value={data?.userPassword}/>
                                                </td>
                                            </>
                                        }
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>이메일</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput type="text" className="form-control" name={'userEmail'}
                                                     onChange={fn_setFormData} value={data?.userEmail}/>
                                        </td>
                                        {!props.detailData && <>
                                            <th className="sh-bg-th" style={{width: '15%'}}>비밀번호 확인</th>
                                            <td style={{width: '35%'}}>
                                                <SHInput type="password" className="form-control"
                                                         name={'userPasswordCnfrm'}
                                                         onChange={fn_setFormData} value={data?.userPasswordCnfrm}/>
                                            </td>
                                        </>
                                        }
                                    </tr>
                                    </tbody>
                                </Table>

                            </div>

                        </div>

                        <div className="sh-modal-div">
                            <div className="div-header">
                                <div className='flex justify-content-between align-items-center w-100'>
                                    <span>현장 별 권한 및 직책 선택</span>
                                    <SHIconButton kind='add' onClick={fn_openSlctAuthChrgPop}>추가</SHIconButton>
                                </div>
                            </div>
                            <div className="div-body px-3 pt-3">
                                <table className="table sub">
                                    <colgroup>
                                        <col width='5%' />
                                        <col width='70%' />
                                        <col width='10%' />
                                        <col width='10%' />
                                        <col width='5%' />
                                    </colgroup>
                                    <thead>
                                    <tr className="table-secondary">
                                        <th className="align-middle">대표</th>
                                        <th className="align-middle">현장</th>
                                        <th className="align-middle">권한</th>
                                        <th className="align-middle">직책</th>
                                        <th className="align-middle">삭제</th>
                                    </tr>
                                    </thead>
                                    <tbody id="wrkDclrRgst_siteTBody">
                                    {
                                        authChrgDataList.length > 0 ?
                                            authChrgDataList.map((authChrgData: any, idx: number) => (
                                                <tr key={idx}>
                                                    <td className={'text-center'}>
                                                        <input type={"checkbox"}
                                                             className={'form-check'}
                                                             onChange={fn_setFormData}
                                                             onClick={() => {
                                                                 fn_chkIsRep(idx)
                                                             }}
                                                             checked={authChrgData.isRep || false}/>
                                                    </td>
                                                    <td>
                                                        <SHSelect initOptionGb={'none'} disabled={true} codeGb={'site'}
                                                                  onChange={fn_setFormData} isForDtl={true} className={'form-control'}
                                                                  value={authChrgData.siteId}></SHSelect>
                                                    </td>
                                                    <td>
                                                        <SHSelect initOptionGb={'none'} disabled={false} codeGb={'auth'}
                                                                  className={'form-control'} name={'authCode'}
                                                                  onChange={(e: any) => fn_chageAuthChrgDataList(e, idx)}
                                                                  selParam={{authGrpId: 'SITE'}}
                                                                  value={authChrgData.authCode}></SHSelect>
                                                    </td>
                                                    <td>
                                                        <SHSelect initOptionGb={'none'} disabled={false}
                                                                  codeGb={'siteMngCd'} className={'form-control'}
                                                                  name={'chrgCode'}
                                                                  onChange={(e: any) => fn_chageAuthChrgDataList(e, idx)}
                                                                  selParam={{
                                                                      siteId: authChrgData.siteId,
                                                                      manageCode: 'M001003'
                                                                  }} value={authChrgData.chrgCode}></SHSelect>
                                                    </td>
                                                    <td className={'text-center'}>
                                                        <img
                                                        className={'w-[25px] cursor-pointer inline-block'}
                                                        src={iconTrashBean} onClick={() => fn_delRow(idx)}/>
                                                    </td>
                                                </tr>

                                            )) : <tr>
                                                <td colSpan={5} className={'text-center'}>현장별 권한을 등록해 주세요.</td>
                                            </tr>
                                    }

                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="sh-modal-div">
                            <div className="div-header">
                                작성정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>작성일</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput value={data?.regDate || currentDate} name={'rgstDt'} onChange={fn_setFormData}
                                                     className={"form-control"}
                                                     disabled={true}/>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>작성자</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput className={"form-control"} name={'rgstrNm'}
                                                     value={data?.rgstrNm || user.userName}
                                                     onChange={fn_setFormData}
                                                     disabled={true}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                    </form>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <button onClick={fn_registData}
                                className='sh-btn pill p'>등록/수정
                        </button>
                    </div>
                </Modal.Footer>

            </Modal>
        </div>
    )
}

export default MngrRgstModal;

