import React, {useContext, useEffect, useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import {ReactComponent as IconRight} from "../../assets/images/ico/icon-right.svg";
import restapi from "../../helper/restAPIHelper";
import {TopMenuContext} from "../../context/TopMenu";
import SiteSftyChkRgstModal from "../safetyWorkReportManagement/modal/SiteSftyChkRgstModal";

export default function HOWeeklySafetyCheck() {
    const [thisWeekDataList, setThisWeekDataList] = useState<any>([]);
    const [nextWeekDataList, setNextWeekDataList] = useState<any>([]);
    const {setMenu} = useContext<any>(TopMenuContext);
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [detailData, setDetailData] = useState(null);
    const [sprvDprtGb, setSprvDprtGb] = useState<string>('');

    useEffect(() => {
        restapi.retrieveSiteSftyManageDataTotList({isThisWeek:true}).then((rsltData: any) => {
            console.log('this week: ', rsltData);
            setThisWeekDataList(rsltData);
        })

        restapi.retrieveSiteSftyManageDataTotList({isNextWeek:true}).then((rsltData: any) => {
            console.log('next week: ', rsltData);
            setNextWeekDataList(rsltData);
        })
    }, [])

    const fn_openSftyChkModal = (sftyChkSeq: number) => {
        restapi.retrieveSiteSftyManageDataTotList({sftyChkSeq: sftyChkSeq}).then((rsltData: any) => {
            setDetailData(rsltData[0])
            setSprvDprtGb(rsltData[0].sprvDprtGb)
            setModalIsOpen(true);

        })
    }

    return(
        <>
            <SiteSftyChkRgstModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} prcsGb={'rsltRgst'}
                                  detailData={detailData} sprvDprtGb={sprvDprtGb}/>
            <Container className="sh-bg-pane rounded-[5px] py-2 px-3 mb-3">
                <Row className="px-0 mb-2">
                    <Col bsPrefix="col-7" className="d-flex align-items-center w-100">
                        <div className="w-100 d-flex align-items-center justify-content-between">
                            <span className="pe-2 sh-fs-h4 sh-fw-5 sh-font-SD">안전 점검 관리</span>
                            <div className={'cursor-pointer'} onClick={() => setMenu('sftChkPrvnt')}>
                            <IconRight style={{width:'20px',marginBottom:'1px'}} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="p-0">
                    <Col className="pe-1 sh-fs-b3">
                        <div className="p-2" style={{border:'1px solid #5685F7', borderRadius:'8px'}}>
                            <div className="pb-1 sh-fw-5 sh-text-secondary03" style={{borderBottom:'1px dotted #5685F7'}}>금주 점검 현장</div>
                            {thisWeekDataList.length > 0 ?
                                thisWeekDataList.map((data: any, idx: number) => (
                                    idx < 3 &&
                                    <div key={idx} className="pt-1 sh-fs-b3 cursor-pointer" onClick={() => fn_openSftyChkModal(data.sftyChkSeq)}>
                                        <div>{data.sftyChkMmdd}</div>
                                        <div>{data.siteNameSbstr}</div>
                                    </div>
                                ))

                                :
                                <div className="pt-1 sh-fs-b3">
                                    <div>금주 점검현장이 없습니다.</div>

                                </div>
                            }


                        </div>
                    </Col>
                    <Col className="ps-1 sh-fs-b3">
                        <div className="p-2" style={{border:'1px solid #FF718B', borderRadius:'8px'}}>
                            <div className="pb-1 sh-fw-5 sh-text-secondary02" style={{borderBottom:'1px dotted #ff718b'}}>차주 점검 현장</div>
                            {nextWeekDataList.length > 0 ?
                                nextWeekDataList.map((data: any, idx: number) => (
                                    idx < 3 &&
                                    <div key={idx} className="pt-1 sh-fs-b3 cursor-pointer" onClick={() => fn_openSftyChkModal(data.sftyChkSeq)}>
                                        <div>{data.sftyChkMmdd}</div>
                                        <div>{data.siteNameSbstr}</div>
                                    </div>
                                ))

                                :
                                <div className="pt-1 sh-fs-b3">
                                    <div>금주 점검현장이 없습니다.</div>

                                </div>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
