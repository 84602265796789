import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from "axios";
import Modal from 'react-modal';
import {getLoginUser} from "../../helper/SecurityHelper";
import {toast} from "react-toastify";
import errorCodeDic from "../../util/errorCodeDic";
import {ProgressContext} from "../../context/Progress";
import restapi from "../../helper/restAPIHelper";

import {Col, Container, Row, Tab, Table, Tabs} from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";

import {CmmnFn} from "../../helper/CmmnHelper";

import WrkrInfoListModal from "../home/modal/WrkrInfoListModal";
import {DateHelper} from "../../helper/DateHelper";
import WrkrPollSubPage from "./subPage/WrkrPollSubPage";
import WrkrPollModal from "./modal/WrkrPollModal";

type Props = {};

Modal.setAppElement('#root');
const WrkrPollPage = (props: Props) => {

    const user = getLoginUser();
    const {spinner} = useContext(ProgressContext);
    const [dataList, setDataList] = useState<any>([]);
    const [srchData, setSrchData] = useState<any>({pollGb:'001', strtDt:DateHelper.dateByMonth(-1), endDt:DateHelper.dateByMonth(0)});
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [selData, setSelData] = useState<any>(null);

    const [isDatePopupOpen, setIsDatePopupOpen] = useState<boolean>(false);
    const prodRef = useRef<any>();

    useEffect(() => {
        fn_search();
    }, [srchData])

    useEffect(() => {
        if(selData){
            setModalIsOpen(true);
        }

    }, [selData]);

    const fn_search = () => {

        console.log('srchData: ', srchData);
        spinner.start();
        restapi.retrievePollAswrCmpltdDataList(srchData).then((rsltData: any) => {
            console.log('rsltData: ', rsltData);
            setDataList(rsltData)
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
        .finally(() => {
            spinner.stop();
        });
    }

    return (
        <Container fluid className="bg-white px-4 pt-4 sh-text-font_primary">
            <WrkrPollModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} detailData={selData}/>
            <SubMenuTitle title={'근로자 심리설문조사'} parentMenu={'현장 정보 관리'}/>
            <Tabs
                transition={true}
                activeKey={srchData.tabGb}
                onSelect={(k) => setSrchData({
                    ...srchData,
                    'pollGb': k
                })}
                id="tabGb"
                className="mb-3 mt-2"
            >
            <Tab eventKey="001" title="감성안전">
                <WrkrPollSubPage dataList={dataList} srchData={srchData} setSrchData={setSrchData} setSelData={setSelData} isDatePopupOpen={isDatePopupOpen} setIsDatePopupOpen={setIsDatePopupOpen} onSearch={fn_search}/>
            </Tab>
            <Tab eventKey="002" title="스트레스 관리">
                <WrkrPollSubPage dataList={dataList} srchData={srchData} setSrchData={setSrchData} setSelData={setSelData} isDatePopupOpen={isDatePopupOpen} setIsDatePopupOpen={setIsDatePopupOpen} onSearch={fn_search} />
            </Tab>

            </Tabs>
        </Container>


    );



};

export default WrkrPollPage;
