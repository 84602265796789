import { RouteType } from "./config";
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import NoticeManagementPageLayout from "../pages/noticeManagement/NoticeManagementPageLayout";
import NoticeRegistrationPage from "../pages/noticeManagement/NoticeRegistrationPage";
import ManPowerManagementPageLayout from "../pages/manpowerManagement/ManPowerManagementPageLayout";
import EmployeeManagementPage from "../pages/manpowerManagement/EmployeeManagementPage";
import ManagerManagementPage from "../pages/manpowerManagement/ManagerManagementPage";
import SiteSafetyManagementPageLayout from "../pages/siteSafetyManagement/SiteSafetyManagementPageLayout";
import RiskAssessmentManagementPage from "../pages/siteSafetyManagement/RiskAssessmentManagementPage";
import WorkPermitManagementPage from "../pages/siteSafetyManagement/WorkPermitManagementPage";
import SmartTBMManagementPage from "../pages/siteSafetyManagement/SmartTBMManagementPage";
import SafetyEducationContentsPage from "../pages/safetyInformationBoard/SafetyEducationContentsPage";
import SiteManagementAdminPage from "../pages/siteManagementAdmin/SiteManagementAdminPage";
import MyPageLayout from "../pages/myPage/MyPageLayout";
import MyInformationManagementPage from "../pages/myPage/MyInformationManagementPage";
import ApprovalStatusManagementPage from "../pages/myPage/ApprovalStatusManagementPage";
import CompanyManagementPage from "../pages/companyManagement/CompanyManagementPage";
import SSMonitoringPage from "../pages/smallsite/SSMonitoringPage";

/* SVG Icon */
import { ReactComponent as IconMonitoring} from '../assets/images/ico/icon-monitoring.svg';
import { ReactComponent as IconSiteConstruction} from '../assets/images/ico/icon-siteconstruction.svg';
import { ReactComponent as IconNotice} from '../assets/images/ico/icon-notice.svg';
import { ReactComponent as IconManpower} from '../assets/images/ico/icon-manpower.svg';
import { ReactComponent as IconSafetyEducation} from '../assets/images/ico/icon-safetyeducation.svg';
import { ReactComponent as IconSiteSafety} from '../assets/images/ico/icon-sitesafety.svg';
import { ReactComponent as IconFieldEquipment} from '../assets/images/ico/icon-fieldequipment.svg';
import { ReactComponent as IconSafetyWork} from '../assets/images/ico/icon-safetywork.svg';
import { ReactComponent as IconSafetyInformation} from '../assets/images/ico/icon-safetyinformation.svg';
import { ReactComponent as IconSiteManagement} from '../assets/images/ico/icon-sitemanagement.svg';
import DngrEvalMngPage from "../pages/dngrEvalMng/DngrEvalMngPage";
import SiteSftyChkPrvntPage from "../pages/safetyWorkReportManagement/SiteSftyChkPrvntPage";
import HeadMngrManagementPage from "../pages/manpowerManagement/HeadMngrManagementPage";
import SosRqstHisPage from "../pages/noticeManagement/SosRqstHisPage";
import WrkDisruptRqstHisPage from "../pages/noticeManagement/WrkDisruptRqstHisPage";
import PreWrkPrmtPage from "../pages/siteSafetyManagement/PreWrkPrmtPage";
import SiteAdminPageLayout from "../pages/siteManagementAdmin/SiteAdminPageLayout";
import NfcQrManagePage from "../pages/siteManagementAdmin/NfcQrManagePage";
import NtcRcvPage from "../pages/noticeManagement/NtcRcvPage";
import SiteSftyChkActPage from "../pages/safetyWorkReportManagement/SiteSftyChkActPage";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import SiteCmpnyMngPage from "../pages/siteManagementAdmin/SiteCmpnyMngPage";
import MainSiteInfoManagePage from "../pages/siteConstructionManagement/MainSiteInfoManagePage";
import WrkPrmtProdMngPage from "../pages/siteSafetyManagement/WrkPrmtProdMngPage";

const ToPage = ({ link }: any) => {
  const navigate = useNavigate();
  useEffect(()=>{
    navigate(link)
  },[])
  return (
      <>...</>
  );
};
const appRoutesSmSite: RouteType[] = [
  {
    index: true,
    element: <ToPage link={"monitoring"} />,
    state: "home"
  },
  {
    path: "monitoring",
    element: <SSMonitoringPage />,
    /*element: <MonitoringPage />,*/
    state: "monitoring",
    sidebarProps: {
      displayText: "모니터링",
      icon: <IconMonitoring />
    }
  },
  {
    path: "noticeManagement",
    element: <NoticeManagementPageLayout />,
    state: "noticeManagement",
    sidebarProps: {
      displayText: "알림/공지사항 관리",
      icon: <IconNotice />
    },
    child: [
      {
        path: "/smSite/noticeManagement/WrkDisruptRqstHis",
        element: <WrkDisruptRqstHisPage />,
        state: "noticeManagement.WrkDisruptRqstHis",
        sidebarProps: {
          displayText: "작업중지 요청 이력"
        },
      },
      {
        path: "/smSite/noticeManagement/SosRqstHis",
        element: <SosRqstHisPage />,
        state: "noticeManagement.SosRqstHis",
        sidebarProps: {
          displayText: "SOS 요청 이력"
        },
      },
      {
        path: "/smSite/noticeManagement/noticeRegistration",
        element: <NoticeRegistrationPage />,
        state: "noticeManagement.noticeRegistration",
        sidebarProps: {
          displayText: "공지사항"
        },
      },

    ]
  },

  {
    path: "manPowerManagement",
    element: <ManPowerManagementPageLayout />,
    state: "manPowerManagement",
    sidebarProps: {
      displayText: "인력 관리",
      icon: <IconManpower />
    },
    child: [

      {
        path: "/smSite/manPowerManagement/managerManagement",
        element: <ManagerManagementPage />,
        state: "manPowerManagement.managerManagement",
        sidebarProps: {
          displayText: "관리자 관리"
        }
      },
      {
        path: "/smSite/manPowerManagement/employeeManagement",
        element: <EmployeeManagementPage />,
        state: "manPowerManagement.employeeManagement",
        sidebarProps: {
          displayText: "근로자 관리"
        },
      },
    ]
  },

  {
    path: "siteSafetyManagement",
    element: <SiteSafetyManagementPageLayout />,
    state: "siteSafetyManagement",
    sidebarProps: {
      displayText: "현장안전관리",
      icon: <IconSiteSafety />
    },
    child: [
      {
        path: "/smSite/siteSafetyManagement/wrkPrmtProdMng",
        element: <WrkPrmtProdMngPage />,
        state: "siteSafetyManagement.wrkPrmtProdMng",
        sidebarProps: {
          displayText: "작업지시서 등록"
        }
      },
      {
        path: "/smSite/siteSafetyManagement/riskAssessmentManagement",
        element: <RiskAssessmentManagementPage />,
        state: "siteSafetyManagement.riskAssessmentManagement",
        sidebarProps: {
          displayText: "위험성평가 실시"
        },
      },
      {
        path: "/smSite/siteSafetyManagement/SmartTBMManagement",
        element: <SmartTBMManagementPage />,
        state: "siteSafetyManagement.SmartTBMManagement",
        sidebarProps: {
          displayText: "TBM 관리"
        }
      },
      {
        path: "/smSite/siteSafetyManagement/safetyEducationContents",
        element: <SafetyEducationContentsPage />,
        state: "siteSafetyManagement.safetyEducationContents",
        sidebarProps: {
          displayText: "안전교육컨텐츠"
        },
      },
    ]
  },

  {
    path: "siteManagementAdminPage",
    element: <SiteAdminPageLayout />,
    state: "siteManagementAdminPage",
    sidebarProps: {
      displayText: "현장 Admin",
      icon: <IconSiteManagement />
    },
    child: [
      {
        path: "/smSite/siteManagementAdminPage/dngrEvalMng",
        element: <DngrEvalMngPage />,
        state: "siteManagementAdminPage.dngrEvalMng",
        sidebarProps: {
          displayText: "위험성평가설정"
        },
      },
      {
        path: "/smSite/siteManagementAdminPage/siteManagementAdmin",
        element: <SiteManagementAdminPage />,
        state: "siteManagementAdminPage.siteManagementAdmin",
        sidebarProps: {
          displayText: "현장세부정보설정"
        },
      },
      {
        path: "/smSite/siteManagementAdminPage/cmpnyManage",
        element: <SiteCmpnyMngPage />,
        state: "siteManagementAdminPage.cmpnyManage",
        sidebarProps: {
          displayText: "협력업체 관리"
        },
      },
      {
        path: "/smSite/siteManagementAdminPage/mainSiteInfoManagePage",
        element: <MainSiteInfoManagePage />,
        state: "siteManagementAdminPage.mainSiteInfoManagePage",
        sidebarProps: {
          displayText: "공사기본정보관리"
        },
      },
    ]
  },

  {
    path: "myPage",
    element: <MyPageLayout />,
    state: "myPage",
    sidebarProps: {
      displayText: "My Page",
      icon: <AppsOutlinedIcon />
    },
    child: [
      {
        path: "/smSite/myPage/myInformationManagement",
        element: <MyInformationManagementPage />,
        state: "myPage.myInformationManagement",
        sidebarProps: {
          displayText: "나의 정보 관리"
        },
      },
      {
        path: "/smSite/myPage/companyManagement",
        element: <CompanyManagementPage />,
        state: "myPage.companyManagement",
        sidebarProps: {
          displayText: "소속 업체 관리"
        },
      },
      {
        path: "/smSite/myPage/approvalStatusManagementPage",
        element: <ApprovalStatusManagementPage />,
        state: "myPage.approvalStatusManagementPage",
        sidebarProps: {
          displayText: "결재 현황 관리"
        },
      },
    ]
  },
];

export default appRoutesSmSite;
