import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import axios from "axios";
import {toast} from "react-toastify";
import restapi from "../../../helper/restAPIHelper";
import SHSearch from "../../component/SHSearch";
import SHInput from "../../../components/common/SHInput";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    driverData: object;
    setDriverData: Dispatch<SetStateAction<object>>;
    isSingleCheck?: boolean
    siteId: number
    cmpnySeq: number

}

const DriverSrchModal = (props: IProps) => {

    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_search = (e?: any) => {
        if (e) {
            e.preventDefault()
        }

        datatable?.draw();

    }


    const fn_select = () => {
        let checkedData = getCheckedItems(datatable);


        if (checkedData.length == 0) {
            toast.warn('운전자를 선택해 주십시오.');
            return;
        }

        props.setModalIsOpen(false);
        props.setDriverData(checkedData[0])
    }


    return (
        <div>
            <Modal
                className="sh-modal sub" size="xl"
                show={props.modalIsOpen}
                onShow={() => fn_search()}
                onHide={fn_closeModal}

            >


                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        운전자 검색
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">
                        <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                            <SHInput type={'hidden'} name={'isDriver'} value={true}/>
                            <SHInput type={'hidden'} name={'siteId'} value={props.siteId}/>
                            <SHInput type={'hidden'} name={'cmpnySeq'} value={props.cmpnySeq}/>
                            <Row>
                                <Col>
                                    <Table className="sh-font-SP sh-fs-b2 my-3">
                                        <tbody>
                                        <tr className="border-top align-middle">
                                            <th className="sh-bg-th" style={{width: '6%'}}>운전자명</th>
                                            <td style={{width:'19%'}}>
                                            <input type={'text'} name={'userName'} className='form-control'/>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </SHSearch>

                    <div className={'ge-result'}>
                        <Datatables
                            dataUrl={'/admin/api/user/retrieveUserDataListWithView'}
                            parameterFormRef={searchFormRef}
                            columns={[
                                {data: "userName", title: "이름", textAlign: 'left'},
                                {data: "userPhone", title: "연락처", textAlign: 'center'},
                                {data: "driverLcncNo", title: "면허번호", textAlign: 'left'},
                            ]}
                            checked={true}
                            isSingleCheck={props.isSingleCheck}

                            onLoad={(dt: any) => {
                                setDatatable(dt)
                            }}
                            onClick={(data) => {

                            }
                            }
                        />
                    </div>
                </Modal.Body>

                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <button onClick={fn_select}
                                className='sh-btn pill p'>선택

                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DriverSrchModal;
