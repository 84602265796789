import React, {useContext, useEffect, useRef, useState} from 'react';
import Datatables, {getCheckedItems} from "../component/Datatables";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import SubMenuTitle from "../component/SubMenuTitle";
import SHSearch from "../component/SHSearch";
import {Container, Table} from "react-bootstrap";
import SHInput from "../../components/common/SHInput";
import {UserContext} from "../../context/User";
import {getLoginUser} from "../../helper/SecurityHelper";
import SHIconButton from "../../components/common/SHIconButton";
import EmergencyNetworkRegistModal from "./modal/EmergencyNetworkRegistModal";
import SHSelect from "../../components/common/SHSelect";

type Props = {};

const EmergencyNetworkManagementPage = (props: Props) => {
    const [emrgNwDataList, setEmrgNwDataList] = useState<any[]>([]);
    const [detailData, setDetailData] = useState(null);
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [datatable, setDatatable] = useState<any>();
    const searchFormRef = useRef<any>();
    const [cmpnyCodeList, setCmpnyCodeList] = useState<any>([]);
    const cmpnySeqRef = useRef<any>();
    const srchTxtRef = useRef<any>();
    const defaultUser = getLoginUser();
    const {user} = useContext<any>(UserContext);
    const [data, setData] = useState<any>({blngCmpnySeq: defaultUser.companyGbCd === 'C001005' ? defaultUser.blngCmpnySeq : ''});
    const [isCodeDataLoaded, setIsCodeDataLoaded] = useState<boolean>(false);

    useEffect(() => {

        if (datatable) {
            datatable.draw();
        }

    }, [user, datatable])
    useEffect(() => {
        datatable?.draw();
    }, [modalIsOpen])

    useEffect(() => {
        if(isCodeDataLoaded){
            datatable?.draw();
        }

    }, [isCodeDataLoaded])

    const fn_setSrchData = (e: any) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const fn_registPopOpen = () => {
        setDetailData(null);
        setModalIsOpen(true);
    }

    const fn_delete = () => {
        let checkedData = getCheckedItems(datatable, "emrgNwId")

        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }

        if (!window.confirm(messageCodeDic['004'])) {
            return;
        }
        console.log("checkedData : " , checkedData)
        axios.post('/admin/api/emrgNw/deleteEmrgNwData', {delList: checkedData}).then(rsltData => {
                if (rsltData.data > 0) {
                    toast.success(messageCodeDic['002'])
                    datatable?.draw();
                } else {
                    toast.error(errorCodeDic['003'])
                }
            }
        ).catch(() => {
                toast.error(errorCodeDic['003'])
            }
        )
    }

    return (
        <Container fluid className="bg-white px-4 pt-4 sh-text-font_primary">
            <SubMenuTitle title={'비상연락망 관리'} parentMenu={'현장공사관리'} />
            <EmergencyNetworkRegistModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} siteId={user?.blngSiteId || defaultUser.blngSiteId}
                               detailData={detailData} />
            <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                <Table className="sh-font-SP sh-fs-b2 my-3">
                    <tbody>
                    <tr className="border-top border-bottom align-middle">
                        <th className="sh-bg-th">업체</th>
                        <td>
                            <SHInput type={'hidden'} name={'siteId'} value={user?.blngSiteId || defaultUser.blngSiteId}></SHInput>
                            <SHSelect name={'blngCmpnySeq'} setIsCodeDataLoaded={setIsCodeDataLoaded} onChange={fn_setSrchData} value={data.blngCmpnySeq} initOptionGb={'all'} codeGb={'siteCmpny'} selParam={{siteId:user?.blngSiteId || defaultUser.blngSiteId}}></SHSelect>
                        </td>
                        <th className="sh-bg-th">검색어</th>
                        <td>
                            <input type={'text'} name={'srchTxt'} className='form-control' ref={srchTxtRef}/>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </SHSearch>

            <div className='w-100 d-flex justify-content-end align-items-center mb-2'>
                <SHIconButton kind={'submit'} marginRight={'5px'} onClick={fn_registPopOpen}>등록</SHIconButton>
                <SHIconButton kind={'delete'} marginRight={'0'} onClick={fn_delete}>삭제</SHIconButton>
            </div>

            <div className={'ge-result'}>
                <Datatables
                    dataUrl={'/admin/api/emrgNw/emrgNwDataList'}
                    parameterFormRef={searchFormRef}
                    columns={[
                        {data: "companyName", title: "업체", textAlign: 'center'},
                        {data: "title", title: "제목", textAlign: 'center'},
                        {data: "rgstrNm", title: "작성자", textAlign: 'center'},
                        {data: "rgstDt", title: "작성일", textAlign: 'center'},
                    ]}

                    checked={true}
                    index={true}

                    onLoad={(dt: any) => {
                        setDatatable(dt)
                    }}

                    onClick={(data) => {
                        setDetailData(data)
                        setModalIsOpen(true);
                    }}
                >
                </Datatables>
            </div>
        </Container>
    );
};

export default EmergencyNetworkManagementPage;
