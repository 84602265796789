import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import HOMainMenu from "./HOTopMenuBar";
import HOTotalSafetyStatus from "./HOTotalSafetyStatus";
import {toast} from "react-toastify";
import { TopMenuContext, TopMenuProvider } from "../../context/TopMenu";
import SiteSftyChkPrvntPage from "../safetyWorkReportManagement/SiteSftyChkPrvntPage";
import HeadMngrManagementPage from "../manpowerManagement/HeadMngrManagementPage";
import ConstructionBaseInfoManagementPage from "../siteConstructionManagement/ConstructionBaseInfoManagementPage";
import HOTopMenuBar from "./HOTopMenuBar";
import CompanyManagementPage from "../companyManagement/CompanyManagementPage";
import { ToastContainer } from "react-toastify";
import CctvHeadManagePage from "../headManage/CctvHeadManagePage";
import SiteSftyChkBsnsPage from "../safetyWorkReportManagement/SiteSftyChkBsnsPage";
import SiteManagePage from "../headManage/SiteManagePage";
import SafetyManagementBoardPage from "../safetyManagementBoard/SafetyManagementBoardPage";
import MainControl from "./MainControl";
import SiteMngrManagementPage from "../manpowerManagement/SiteMngrManagementPage";
import SafetyNewspaperPage from "../safetyNewspaper/SafetyNewspaperPage";
import SafetyNotificationManagementPage from "../safetyNotificationManagement/SafetyNotificationManagementPage";
import HeadCompanyManagementPage from "./HeadCompanyManagementPage";
import { PushContext } from "../../context/Push";
import PushModal from "./modal/PushModal";
import {getLoginUser, IS_SM_SITE, MENU} from "../../helper/SecurityHelper";
import SiteWrkrMngPage from "../safetyNewspaper/SiteWrkrMngPage";
import HeadSiteDocsPage from "../safetyInformationBoard/HeadSiteDocsPage";
import MyInformationManagementPage from "../myPage/MyInformationManagementPage";
import HeadManagementAdminPage from "./HeadManagementAdminPage";
import CnstrAsscPage from "../headManage/CnstrAsscPage";
import CnstrAsscApiPage from "../headManage/CnstrAsscApiPage";
import IncidentMain from "../incident/IncidentMain";
import WrkrPollPage from "../poll/WrkrPollPage";
import EnvrntCnfgPage from "../headManage/EnvrntCnfgPage";
import AlrtMngStatPage from "../safetyInformationBoard/AlrtMngStatPage";
import HeadVideoMngPage from "../safetyInformationBoard/HeadVideoMngPage";
import restapi from "../../helper/restAPIHelper";
import {transform} from "ol/proj";
import {SiteContext} from "../../context/Site";
import SafetyLawPage from "../safetyInformationBoard/SafetyLawPage";
import {ProgressContext} from "../../context/Progress";

export default function HeadScreenRoot() {
  const { menu, setMenu } = useContext<any>(TopMenuContext);
  const { push } = useContext<any>(PushContext);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const {inProgress} = useContext(ProgressContext);
  const user = getLoginUser();
    const {site, setSite} = useContext<any>(SiteContext);

  useEffect(() => {
      console.log('push: ', push);
    if (push) {

      if ([true, false].includes(push.isOpen)) {
        setModalIsOpen(push.isOpen);
      }
    }
  }, [push]);

  useEffect(() => {
    const menu = localStorage.getItem(MENU)
    setMenu(menu);

    if (user.authCode == "headOut") {
      setMenu("sftChkPrvnt");
    } else if (user.authCode == "headMsr") {
      setMenu("wallSfty");
      toast.warn(`계측관리 시스템으로 이동하시려면 "계측관리"탭을 클릭해주세요.`);
    }

  }, []);

  useEffect(() => {
      // 122
      if(menu){
          if(menu == 'dashboard'){
              fn_retrieveDefaultSiteData();
              // 운영 default 답십리 세팅
          }else if (menu == 'mainControl'){

              setTimeout(() => {
                  // @ts-ignore
                  window.___last_map_extent = null;
              }, 300);

          }
          localStorage.setItem(MENU, menu);
      }else{
          fn_retrieveDefaultSiteData();
      }
  }, [menu]);


  const fn_retrieveDefaultSiteData = () => {
      restapi.retrieveSiteDataListNoPaging({siteId: 122, isSmSite: false}).then((rsltData: any) => {
          if(rsltData.length > 0){
              var item = rsltData[0]
              setSite(item);
          }
      });
  }
  return (
    <>
      <PushModal
        setModalIsOpen={setModalIsOpen}
        modalIsOpen={modalIsOpen}
      ></PushModal>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ zIndex: 11000, width: "400px" }}
      />
      <HOTopMenuBar />
        {inProgress && <div id="sh-loader-wrapper" style={{zIndex: 11001}}><span className={"loading"}></span></div>}
        <Container
        fluid
        className={
          " " +
          (menu === null || menu === "" || menu === "dashboard"
            ? "sh-bg-dashboard"
            : "")
        }
        style={{ paddingTop: menu === "mainControl" ? "54px" : "84px" }}
      >
        <Row>
          <Col className={"p-0 m-0"}>
            {menu ? (
              menu == "dashboard" ? (
                <HOTotalSafetyStatus />
              ) : menu == "mainControl" ? (
                <MainControl />
              ) : menu == "totSiteStat" ? (
                <SiteManagePage />
              ) : menu == "cmpnyMng" ? (
                <HeadCompanyManagementPage />
              ) : menu == "mngrMng" ? (
                <HeadMngrManagementPage />
              ) : menu == "siteMngrMng" ? (
                <SiteMngrManagementPage />
              ) : menu == "sftChkPrvnt" ? (
                <SiteSftyChkPrvntPage />
              ) : menu == "sftChkBsns" ? (
                <SiteSftyChkBsnsPage />
              ) : menu == "wallSfty" ? (
                "wallSfty"
              ) : menu == "siteCctv" ? (
                <CctvHeadManagePage />
              ) : menu == "sftyNtc" ? (
                <SafetyNotificationManagementPage />
              ) : menu == "sftyMng" ? (
                <SafetyManagementBoardPage />
              ) : menu == "sftyNewspaper" ? (
                <SafetyNewspaperPage />
              ) : menu == "siteWrkrMng" ? (
                <SiteWrkrMngPage />
              ) : menu == "siteDocs" ? (
                <HeadSiteDocsPage />
              ) : menu == "incidentMng" ? (
                <IncidentMain />
              ) : menu == "myPage" ? (
                <MyInformationManagementPage />
              ) : menu == "codeCnfg" ? (
                <HeadManagementAdminPage />
              ) : menu == "cnstrAssc" ? (
                <CnstrAsscPage />
              ) : menu == "cnstrAsscApi" ? (
                <CnstrAsscApiPage />
              ) :  menu == 'wrkrPoll' ? (
                <WrkrPollPage />
              ) : menu == 'envrmntCnfg' ? (
                <EnvrntCnfgPage />
              ) : menu == 'alrtMngStat' ? (
                <AlrtMngStatPage />
              ) : menu == 'videoMng' ? (
                <HeadVideoMngPage />
              ) : menu == 'safetyLaw' ? (
                    <SafetyLawPage />
                ) : null
            ) : (
              <HOTotalSafetyStatus />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
