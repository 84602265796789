import React, {useContext, useEffect, useRef, useState} from 'react';
import Datatables, {getCheckedItems} from "../component/Datatables";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import SHSelect from "../../components/common/SHSelect";
import SHInput from "../../components/common/SHInput";
import iconCalendar from "../../images/icon-calendar-black.svg";
import CustomDatePickerRange from "../component/ui/DatePickerRange";
import SHSearch from '../component/SHSearch';
import SubMenuTitle from "../component/SubMenuTitle";
import {getLoginUser} from "../../helper/SecurityHelper";
import {Col, Container, Row, Table} from "react-bootstrap";
import SafetyEduRgstModal from "../safetyEducationManagement/modal/SafetyEduRgstModal";
import SftyWrkMetRgstModal from "./modal/SftyWrkMetRgstModal";
import restapi from "../../helper/restAPIHelper";
import SHIconButton from "../../components/common/SHIconButton";
import {UserContext} from "../../context/User";

type Props = {};

const SafetyWorkMeetingManagementPage = (props: Props) => {
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState<any>(null);
    const [isSaveSuccess, setIsSaveSuccess] = useState(false);

    const [data, setData] = useState<any>()
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const defaultUser = getLoginUser();
    const siteIdRef = useRef<any>();
    const [siteCodeList, setSiteCodeList] = useState([]);
    const {user} = useContext<any>(UserContext);

    useEffect(()=>{

        if(datatable){
            datatable.draw();
        }

    }, [user, datatable])

    useEffect(() => {
        if (!modalIsOpen) {
            datatable?.draw();
        }
    }, [modalIsOpen])


    const fn_search = () => {
        datatable.draw();
    }

    /**
     * 등록 버튼 클릭
     */
    const fn_registPopOpen = () => {


        setDetailData(null)
        setModalIsOpen(true);
    }

    const fn_delete = () => {

        var checkedData = getCheckedItems(datatable);
        let delList:number[] = [];

        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }

        checkedData.map((data:any) => {
            delList.push((data.sftyWrkMetId));
        })

        console.log('delList: ', delList);

        if (!window.confirm(messageCodeDic['004'])) {
            return;
        }

        axios.post('/admin/api/sftyWrkMet/deleteSftyWrkMetData', {delList: delList}).then(rsltData => {
                if (rsltData.data > 0) {
                    toast.success(messageCodeDic['002'])
                    fn_search();
                } else {
                    toast.error(errorCodeDic['003'])
                }

            }
        ).catch(() => {
                toast.error(errorCodeDic['003'])
            }
        )
    }

    const fn_setFormData = (e: any) => {

        setData({
            ...data,
            [e.target.name]: e.target.value
        })

    }

    return (
        <Container fluid className="bg-white px-4 pt-4 sh-text-font_primary">
            <SubMenuTitle title={'안전업무 회의록 관리'} parentMenu={'안전업무보고서 관리'} />

            <SftyWrkMetRgstModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} siteId={user?.blngSiteId || defaultUser.blngSiteId}
                                detailData={detailData} setIsSaveSuccess={setIsSaveSuccess}/>


                <SHSearch datatable={datatable} formRef={searchFormRef}  btnType={'p'}>
                    <Row>
                        <Col>
                            <Table className="sh-font-SP sh-fs-b2 my-3">
                                <tbody>
                                    <tr className="border-top border-bottom align-middle">
                                        <th className="sh-bg-th">검색어</th>
                                        <td>
                                            <SHInput type={'hidden'} name={'siteId'} value={user?.blngSiteId || defaultUser.blngSiteId}></SHInput>
                                            <SHInput name={'srchTxt'} className={'form-control'} value={data?.srchTxt} onChange={fn_setFormData}/>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </SHSearch>

            <div className='w-100 d-flex justify-content-end align-items-center mb-2'>
                <SHIconButton kind={'submit'} marginRight={'5px'} onClick={fn_registPopOpen}>등록</SHIconButton>
                <SHIconButton kind={'delete'} marginRight={'0'} onClick={fn_delete}>삭제</SHIconButton>
            </div>

            <div className={'ge-result'}>
                <Datatables
                    dataUrl={'/admin/api/sftyWrkMet/retrieveSftyWrkMetDataList'}
                    parameterFormRef={searchFormRef}
                    columns={[
                        {data: "metKndNm", title: "회의록 종류", textAlign: 'left'},
                        {data: "rgstDt", title: "작성일", textAlign: 'center'},
                        {data: "rgstrNm", title: "작성자", textAlign: 'center'},
                        {data: "", title: "프린트", textAlign: 'center'},

                    ]}
                    checked={true}
                    index={true}

                    onLoad={(dt: any) => {
                        setDatatable(dt)
                    }}
                    onClick={(data) => {
                        setDetailData(data)
                        setModalIsOpen(true);
                    }
                    }
                />
            </div>
        </Container>
    );
};

export default SafetyWorkMeetingManagementPage;
