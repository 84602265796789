import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import iconCalendar from "../../../images/icon-calendar-black.svg";
import CustomDatePickerRange from "../../component/ui/DatePickerRange";
import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {CmmnFn, fn_getCurrentDate} from "../../../helper/CmmnHelper";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {Col, Row} from "react-bootstrap";
import {Container} from "@mui/material";
import {COMMON_MODAL_STYLE, COMMON_MULTISEL_STRINGS} from "../../../helper/StyleHelper";
import Select from 'react-select'
import {MultiSelect} from "react-multi-select-component";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import SHInput from "../../../components/common/SHInput";
import SHTextarea from "../../../components/common/SHTextarea";
import SHSelect from "../../../components/common/SHSelect";
import CustomDatePicker from "../../component/ui/DatePicker";
import SiteSearchModal from "../../companyManagement/modal/SiteSearchModal";
import DriverSrchModal from "./DriverSrchModal";
import restapi from "../../../helper/restAPIHelper";
import {disableForm} from "../../../helper/FormHelper";
import iconTrashBean from "../../../images/icon_trashBean.png";
import {ProgressContext} from "../../../context/Progress";
import SHDatePicker2 from "../../../components/common/SHDatePicker2";
import {DateHelper} from "../../../helper/DateHelper";



interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
    setIsSaveSuccess: Dispatch<SetStateAction<boolean>>;
    siteId: number;
}

const DailyChkDtlModal = (props: IProps) => {

    const inspctProdRef = useRef<any>();
    const isrncProdRef = useRef<any>();


    const [eqmntIspctStrtDt, setEqmntIspctStrtDt] = useState<Date | null>(null);
    const [eqmntIspctEndDt, setEqmntIspctEndDt] = useState<Date | null>(null);
    const [eqmntIsrncStrtDt, setEqmntIsrncStrtDt] = useState<Date | null>(null);
    const [eqmntIsrncEndDt, setEqmntIsrncEndDt] = useState<Date | null>(null);
    const [data, setData] = useState<any>()
    const [isDriverPopOpend, setIsDriverPopOpend] = React.useState<boolean>(false);
    const [driverData, setDriverData] = useState<any>({});
    const [selected, setSelected] = useState([])
    const [eqmntChkList, setEqmntChkList] = useState<any>([]);
    const [dataList, setDataList] = useState<any>([]);
    const formRef = useRef<any>();
    const user = getLoginUser();
    const {spinner} = useContext(ProgressContext);
    const fn_setFormData = (e: any) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }


    function afterOpenModal() {
        disableForm(formRef);
        setData(props.detailData);
        console.log('props.detailData:', props.detailData);
        setEqmntIspctStrtDt(DateHelper.parse(props.detailData.eqmntIspctStrtDt));
        setEqmntIspctEndDt(DateHelper.parse(props.detailData.eqmntIspctEndDt));
        setEqmntIsrncStrtDt(DateHelper.parse(props.detailData.eqmntIsrncStrtDt));
        setEqmntIsrncEndDt(DateHelper.parse(props.detailData.eqmntIsrncEndDt));
        fn_retrieveChkDataList(props.detailData.eqmntCd);
        axios.post('/admin/api/eqmnt/retrieveEqmntChkData', {
            eqmntId: props.detailData.eqmntId,
            isDailyHist: true,
            prcsDt: props.detailData.prcsDt,
            chckrSeq: props.detailData.chckrSeq
        }).then((rsltData: any) => {
            setDataList(rsltData.data);
        })

    }

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const submit = (e: any) => {
        e.preventDefault()

    }

    const fn_cnfrmData = () => {
        const param = {
            cnfrmUserSeq: user.userSeq,
            eqmntId: props.detailData.eqmntId,
            userSeq: props.detailData.chckrSeq,
            prcsDt: props.detailData.prcsDt
        }

        spinner.start();
        restapi.cnfrmEqmntChk(param).then((rsltData) => {
            if(rsltData > 0){
                toast.success(messageCodeDic['006'])
                props.setModalIsOpen(false);
            } else {
                toast.error(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
        .finally(() => {
            spinner.stop();
        });
    }

    const fn_retrieveChkDataList = (eqmntCd: number) => {

        restapi.retrieveEqmntChkList({siteId: props.siteId, eqmntCd: eqmntCd}).then((rsltData) => {
            const rsltEqmntChkList: object[] = []
            rsltData.map((data: any) => {
                rsltEqmntChkList.push({label: data.chkCntnts, value: data.chkSeq})
            })

            setEqmntChkList(rsltEqmntChkList);
        })

    }

    return (
        <div>
            <DriverSrchModal setModalIsOpen={setIsDriverPopOpend} modalIsOpen={isDriverPopOpend} driverData={driverData}
                             isSingleCheck={true}
                             setDriverData={setDriverData} siteId={data?.siteId} cmpnySeq={data?.cmpnySeq}/>
            <Modal
                className="sh-modal" size="xl"
                show={props.modalIsOpen}
                onShow={afterOpenModal}
                onHide={fn_closeModal}
            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        일일장비점검 상세
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <form name={'form'} ref={formRef}
                          onSubmit={submit}>
                        <div className="sh-modal-div">
                            <div className="div-header">
                                장비정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>현장</th>
                                        <td style={{width: '35%'}}>
                                            <SHSelect initOptionGb={'none'} disabled={true} codeGb={'site'}
                                                      onChange={fn_setFormData} className={'form-control'}
                                                      value={data?.siteId}></SHSelect>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>업체</th>
                                        <td style={{width: '35%'}}>
                                            <SHSelect initOptionGb={'sel'} disabled={data?.driverSeq ? true : false}
                                                      name={'cmpnySeq'} codeGb={'siteCmpny'} className={'w-100'}
                                                      onChange={fn_setFormData} selParam={{siteId: props.siteId}}
                                                      value={data?.cmpnySeq}></SHSelect>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>장비명</th>
                                        <td style={{width: '35%'}}>
                                            <SHSelect initOptionGb={'sel'} name={'eqmntCd'} className={'w-100'}
                                                      codeGb={'siteMngCd'}
                                                      onChange={(e) => {
                                                          fn_setFormData(e);
                                                          fn_retrieveChkDataList(e.target.value);
                                                          setSelected([])
                                                      }} selParam={{
                                                siteId: props.siteId,
                                                manageCode: 'M001005'
                                            }} value={data?.eqmntCd}></SHSelect>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>임대 업체</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput type="text" className="form-control" name={'rntCmpnyNm'}
                                                     onChange={fn_setFormData} value={data?.rntCmpnyNm}/>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>장비 등록번호</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput type="text" className="form-control" name={'eqmntRgstNo'}
                                                     onChange={fn_setFormData} value={data?.eqmntRgstNo}/>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>장비 규격</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex align-items-center w-100">
                                                <SHInput type="number" className="form-control" name={'eqmntWght'}
                                                         onChange={fn_setFormData} value={data?.eqmntWght}/>
                                                <span className={'ml-5'}>t(톤)</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>장비 검사기간</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex align-items-center w-100" ref={inspctProdRef}>
                                                <SHDatePicker2 setter={setEqmntIspctStrtDt} name={'noticeStartDate'} defaultValue={eqmntIspctStrtDt} onChange={fn_setFormData}/>
                                                <span>&nbsp;~&nbsp;</span>
                                                <SHDatePicker2 setter={setEqmntIspctEndDt} name={'noticeEndDate'} defaultValue={eqmntIspctEndDt} onChange={fn_setFormData}/>
                                            </div>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>보험기간</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex align-items-center w-100" ref={isrncProdRef}>
                                                <SHDatePicker2 setter={setEqmntIsrncStrtDt} name={'x'} defaultValue={eqmntIsrncStrtDt} onChange={fn_setFormData}/>
                                                <span>&nbsp;~&nbsp;</span>
                                                <SHDatePicker2 setter={setEqmntIsrncEndDt} name={'x'} defaultValue={eqmntIsrncEndDt} onChange={fn_setFormData}/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>운전원</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex align-items-center w-100">
                                                <SHInput type="text" disabled={true} className="form-control"
                                                         name={'driverNm'}
                                                         onChange={fn_setFormData} value={data?.driverNm}/>
                                                <SHInput type="hidden" className="form-control" name={'driverSeq'}
                                                         onChange={fn_setFormData} value={data?.driverSeq}/>
                                            </div>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>운전원 연락처</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput type="text" disabled={true} className="form-control"
                                                     name={'driverPhone'}
                                                     onChange={fn_setFormData} value={data?.driverPhone}/>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>운전원 면허번호</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput type="text" disabled={true} className="form-control"
                                                     name={'driverLcncNo'}
                                                     onChange={fn_setFormData} value={data?.driverLcncNo}/>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>장비교대운전원</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex align-items-center w-100">
                                                <SHInput type="text" disabled={true} className="form-control"
                                                         name={'rotDriverNm'}
                                                         onChange={fn_setFormData} value={data?.rotDriverNm}/>
                                                <SHInput type="hidden" disabled={true} className="form-control"
                                                         name={'rotDriverSeq'}
                                                         onChange={fn_setFormData} value={data?.rotDriverSeq}/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>점검일자</th>
                                        <td style={{width: '35%'}}>
                                            <input value={data?.chkTime || ''}
                                                   className={"form-control"}
                                                   disabled={true}/>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>점검자</th>
                                        <td style={{width: '35%'}}>
                                            <input className={"form-control"}
                                                   value={data?.chckrNm || ''} disabled={true}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        <div className="sh-modal-div">
                            <div className="div-header">
                                점검결과
                            </div>
                            <div className="div-body px-3">
                                <Table className="sub my-2 text-center">
                                    <thead>
                                    <tr className="table-secondary">
                                        <td className="align-middle w-[5%]">No</td>
                                        <td className="align-middle w-[45%]">장비점검 질문</td>
                                        <td className="align-middle w-[10%]">상태</td>
                                        <td className="align-middle w-[30%]">불량 사유</td>
                                        <td className="align-middle w-[10%]">사진</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        dataList.length > 0 ?
                                            dataList.map((data: any, idx: number) => (
                                                <tr key={idx}>
                                                    <td>
                                                        {idx + 1}
                                                    </td>
                                                    <td>
                                                        {data.chkCntnts}
                                                    </td>
                                                    <td>
                                                        {data.prcsRsltNm}
                                                    </td>
                                                    <td>
                                                        {data.badRsn || '-'}
                                                    </td>
                                                    <td>
                                                        {data.badRsnFileId ? <img
                                                                src={process.env.REACT_APP_FILE_URL + "/shss-data/fileDownload?attachSeq=" + data.badRsnFileId + '&sn=0&isPdf=0&time=' + new Date().getTime()}/>
                                                            : '-'
                                                        }
                                                    </td>
                                                </tr>

                                            )) : <tr>
                                                <td colSpan={5}>조회된 장비점검 결과가 없습니다</td>
                                            </tr>
                                    }

                                    </tbody>
                                </Table>
                            </div>
                        </div>

                    </form>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        {!props.detailData?.cnfrmDt &&
                            <button onClick={fn_cnfrmData}
                                    className='sh-btn pill p'>확인
                            </button>
                        }

                    </div>
                </Modal.Footer>

            </Modal>
        </div>
    )
}

export default DailyChkDtlModal;

