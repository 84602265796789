import { Modal, Table } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
import iconCalendar from "../../../images/icon-calendar-solid.svg";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { MultiSelect } from "react-multi-select-component";
import axios from "axios";
import { toast } from "react-toastify";
import SHSelect from "../../components/common/SHSelect";
import SHTextarea from "../../components/common/SHTextarea";
import SHFile from "../../components/common/SHFile";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import {CmmnFn, fn_getCurrentDate} from "../../helper/CmmnHelper";
import { getLoginUser } from "../../helper/SecurityHelper";
import restapi from "../../helper/restAPIHelper";
import SHDatePicker2 from "../../components/common/SHDatePicker2";
import { DateHelper } from "../../helper/DateHelper";

interface IProps {
  setModalIsOpen: Dispatch<SetStateAction<boolean>>;
  modalIsOpen: boolean;
  detailData: any;
  siteId: number;
}

const IncidentMngModal = (props: IProps) => {
  const prodRef = useRef<any>();
  const currentDate = fn_getCurrentDate();
  const user = getLoginUser();
  const defaultUser = getLoginUser();
  const [eduDt, setEduDt] = useState<string>("");
  const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);
  const [data, setData] = useState<any>();
  const [selected, setSelected] = useState([]);
  const [eduTimeList, setEduTimeList] = useState<any>([]);
  const [fileList, setFileList] = useState([]);
  const [incidentDt, setIncidentDt] = useState<Date>(DateHelper.dateByMonth(0));
  const formRef = React.createRef<HTMLFormElement>();

  const fn_setFormData = (e: any) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  function afterOpenModal() {
    if (!props.detailData) {
      setFileList([]);
      setData(null);
      setData({ siteId: props.siteId });
      setEduDt("");
      setSelected([]);
      setIncidentDt(new Date());
      // 상세
    } else {
      setData(props.detailData);
      setEduDt(props.detailData.eduDt);
      const specificDate = new Date(props.detailData.incidentDt);
      setIncidentDt(specificDate);
      if (props.detailData.atchFileId) {
        restapi
          .retrieveAttachInfoList({ attachSeq: props.detailData.atchFileId })
          .then((rsltData: any) => {
            setFileList([].concat(rsltData));
          });
      } else {
        setFileList([]);
      }
    }
    fn_genEduTime();
  }

  function fn_closeModal() {
    props.setModalIsOpen(false);
  }

  const fn_datePopupOpen = () => {
    setIsDatePopupOpen(!isDatePopupOpen);
  };

  const submit = (e: any) => {
    e.preventDefault();
  };

  const fn_registData = () => {
    if (!data) {
      toast.warn("데이터를 입력해 주십시오.");
      return;
    }

    if (!window.confirm("저장 하시겠습니까?")) {
      return;
    }

    let param = data;
    param.eduDt = eduDt;
    param.incidentDt = incidentDt;
    param.rgstrSeq = user.userSeq;
    param.updtrSeq = user.userSeq;
    param.incidentTitle = `사고발생보고 (${CmmnFn.dateToString(incidentDt)})`;

    // @ts-ignore
    var formData: any = new FormData(formRef.current);

    if (fileList.length > 0) {
      for (var i = 0; i < fileList.length; i++) {
        formData.append("atchFileList", fileList[i]);
      }
    }

    formData.set(
      "incidentManageVO",
      new Blob([JSON.stringify(param)], { type: "application/json" })
    );

    axios
      .post("/admin/api/incidentManage/saveIncidentManageData", formData)
      .then((result) => {
        if (result.data > 0) {
          toast.success(messageCodeDic["001"]);
          props.setModalIsOpen(false);
        } else {
          toast.error(errorCodeDic["003"]);
        }
      });
  };

  const fn_genEduTime = () => {
    let date = new Date(2023, 0, 1);
    let timeArr = ["00:00"];
    let hr;
    let mi;

    for (var i = 0; i < 47; i++) {
      date.setMinutes(date.getMinutes() + 30);
      hr = date.getHours() + "";
      mi = date.getMinutes() + "";
      if (hr.length == 1) {
        hr = "0" + hr;
      }
      if (mi.length == 1) {
        mi = "0" + mi;
      }
      timeArr.push(hr + ":" + mi);
    }
    setEduTimeList(timeArr);
  };

  return (
    <div>
      <Modal
        className="sh-modal"
        size="xl"
        show={props.modalIsOpen}
        onShow={afterOpenModal}
        onHide={fn_closeModal}
      >
        <Modal.Header>
          <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
            사고관리 등록/수정
            <IoMdClose
              className="hover:cursor-pointer"
              onClick={fn_closeModal}
            />
          </div>
        </Modal.Header>

        <Modal.Body className="px-4">
          <form
            name={"form"}
            ref={formRef}
            encType="multipart/form-data"
            method={"post"}
            onSubmit={submit}
          >
            <div className="sh-modal-div">
              <div className="div-header">작성정보</div>
              <div className="div-body px-3">
                <Table className="my-2 align-middle">
                  <tbody>
                    <tr className="border-top align-middle">
                      <th className="sh-bg-th" style={{ width: "13%" }}>
                        제목
                      </th>
                      <td style={{ width: "20%" }}>
                        <input
                          value={`사고발생보고 (${CmmnFn.dateToString(incidentDt)})`}
                          className={"form-control"}
                          disabled={true}
                        />
                      </td>
                      <th className="sh-bg-th" style={{ width: "13%" }}>
                        작성자
                      </th>
                      <td style={{ width: "20%" }}>
                        <input
                          className={"form-control"}
                          value={data?.rgstrNm || user.userName}
                          disabled={true}
                        />
                      </td>
                      {props.detailData ? (
                        <>
                          <th className="sh-bg-th" style={{ width: "13%" }}>
                            작성일자
                          </th>
                          <td style={{ width: "20%" }}>
                            <input
                              className={"form-control"}
                              value={props?.detailData?.incidentRgstDt}
                              disabled={true}
                            />
                          </td>
                        </>
                      ) : null}
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="sh-modal-div">
              <div className="div-header">사고발생 정보</div>
              <div className="div-body px-3">
                <Table className="my-2 align-middle">
                  <tbody>
                    <tr className="border-top align-middle">
                      <th className="sh-bg-th" style={{ width: "15%" }}>
                        사고발생일
                      </th>
                      <td style={{ width: "85%" }} ref={prodRef}>
                        <SHDatePicker2
                          setter={setIncidentDt}
                          name={"incidentDt"}
                          defaultValue={incidentDt}
                          onChange={fn_setFormData}
                        />
                      </td>
                    </tr>
                    <tr className="border-top align-middle">
                      <th className="sh-bg-th" style={{ width: "15%" }}>
                        내용
                      </th>
                      <td style={{ width: "85%" }} colSpan={3}>
                        <SHTextarea
                          rows={5}
                          onChange={fn_setFormData}
                          name={"incidentContents"}
                          value={data?.incidentContents}
                          className={"form-control"}
                          placeholder="내용을 입력해주세요."
                        />
                      </td>
                    </tr>
                    <tr className="align-middle" style={{ width: "15%" }}>
                      <th className="sh-bg-th">첨부파일</th>
                      <td style={{ width: "85%" }} colSpan={3}>
                        <SHFile
                          fileName={"files"}
                          fileList={fileList}
                          setFileList={setFileList}
                        ></SHFile>
                      </td>
                    </tr>
                    {props.detailData ? (
                      <tr className="align-middle" style={{ width: "15%" }}>
                        <th className="sh-bg-th">수정이력</th>
                        <td style={{ width: "35%" }}>
                          <div>
                            {props?.detailData?.isUpdate
                              ? props?.detailData?.incidentUpdtDt
                                  .slice(0, 19)
                                  .replace("T", " ") +
                                " " +
                                props?.detailData?.userName
                              : "없음"}
                          </div>
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </Table>
              </div>
            </div>
          </form>
        </Modal.Body>
        {user?.blngSiteId || defaultUser.blngSiteId ? (
          <Modal.Footer className="py-2">
            <div className="flex justify-center w-100">
              <button
                className="sh-btn pill l white me-3"
                onClick={fn_closeModal}
              >
                취소
              </button>
              <button className="sh-btn pill p" onClick={fn_registData}>
                저장
              </button>
            </div>
          </Modal.Footer>
        ) : null}
      </Modal>
    </div>
  );
};

export default IncidentMngModal;
