import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {RouterProvider, createBrowserRouter, BrowserRouter, Routes, Route} from 'react-router-dom';
import NotFound from './pages/NotFound';
import Login from './pages/Login';
import MainLayout from './components/layout/MainLayout';
import {Provider} from 'react-redux';
import {store} from './redux/store';
import appRoutes from "./routes/appRoutes";
import {routes} from './routes';
import {ProgressProvider} from "./context/Progress";
import HeadOfficeMonitoring from "./pages/headoffice/HeadScreenRoot";
import {UserProvider} from "./context/User";
import {TopMenuProvider} from './context/TopMenu';
import MainRoutes from "./routes/MainRoutes";
import {SiteProvider} from "./context/Site";
import {MenuOpenProvider} from "./context/MenuOpen";
import {PushProvider} from "./context/Push";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.Fragment>
        <ProgressProvider>
                <UserProvider>
                    <SiteProvider>
                    <TopMenuProvider>
                        <PushProvider>
                        <MenuOpenProvider>
                        <Provider store={store}>
                            <BrowserRouter>
                                <MainRoutes></MainRoutes>
                            </BrowserRouter>
                        </Provider>
                        </MenuOpenProvider>
                        </PushProvider>
                    </TopMenuProvider>
                    </SiteProvider>
                </UserProvider>
        </ProgressProvider>
    </React.Fragment>
);

