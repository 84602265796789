import {IoMdClose} from "react-icons/io";
import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {Col, Row, Table, Modal} from "react-bootstrap";
import SHInput from "../../../components/common/SHInput";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import restapi from "../../../helper/restAPIHelper";
import WrkrSrchModal from "./WrkrSrchModal";
import SHIconButton from "../../../components/common/SHIconButton";
import {ProgressContext} from "../../../context/Progress";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import SHSearch from "../../component/SHSearch";
import {useReactToPrint} from "react-to-print";

const $ = require("jquery")

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
    siteId: number;
    isReadOnly?: boolean;
}

const WrkrRgstModal = (props: IProps) => {

    const user = getLoginUser();

    const {spinner} = useContext(ProgressContext);
    const [datatable, setDatatable] = useState<any>();
    const searchFormRef = useRef<any>();
    const [dataList, setDataList] = useState<any>([])
    const [totCnt, setTotCnt] = useState<number>(0);
    const [orgnDataList, setOrgnDataList] = useState<any>([])
    const [isWrkrModalOpen, setIsWrkrModalOpen] = useState<boolean>(false);
    const [userName, setUserName] = useState<string>('');

    useEffect(() => {
        if(datatable){
            datatable.draw();
        }
    }, [datatable])

    useEffect(() => {
        console.log('orgnDataList: ', orgnDataList);
        setDataList(orgnDataList);
        setUserName('');

    }, [orgnDataList])

    const fn_onAfterOpen = async () => {
        spinner.start()

        await restapi.retrieveWrkPrmtDtlData(props.detailData.wrkPrmtId, props.detailData.siteId).then((rsltData) => {
            const dtlWrkrStatDataList = rsltData.wrkrStatDataList;
            setOrgnDataList(dtlWrkrStatDataList);
            setDataList(dtlWrkrStatDataList);
            setTotCnt(dtlWrkrStatDataList.length);
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });
    }


    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_retrieveWrkPrmtDtlData =  () => {
        console.log('dataList: ', dataList);
        const obj = new FormData(searchFormRef.current);
        const userName:any = obj.get('userName')

        if(userName.trim()){
            setDataList(orgnDataList.filter((data: any) => (data.userName.indexOf(userName) > 1)));
        }else{
            setDataList(orgnDataList);
        }

    }

    const fn_save = () => {

        const param = {wrkPrmtId:props.detailData.wrkPrmtId, wrkrStatDataList:orgnDataList}

        if(!window.confirm('저장 하시겠습니까?')){
            return;
        }

        spinner.start();
        axios.post('/admin/api/wrkPrmt/saveWrkrStataData', param).then((rlstData: any) => {
            if(rlstData.data > 0){
                toast.success(messageCodeDic['001'])
                props.setModalIsOpen(false);
            } else {
                toast.error(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });
    }

    const fn_regist = () => {
        setIsWrkrModalOpen(true);
    }

    const fn_delete = () => {
        var checkedData = getCheckedItems(datatable, 'userSeq');
        console.log('checkedData: ', checkedData);

        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }

        const cstmDataList = orgnDataList.filter((data:any) => !(checkedData.includes(data.userSeq)))
        console.log('cstmDataList: ', cstmDataList);
        setOrgnDataList(cstmDataList);

    }


    return (
        <div>
            <WrkrSrchModal setModalIsOpen={setIsWrkrModalOpen} modalIsOpen={isWrkrModalOpen} wrkrDataList={orgnDataList}
                           setWrkrDataList={setOrgnDataList} siteId={props.siteId} cmpnySeq={props.detailData?.cmpnySeq}/>
            <Modal className="sh-modal" size="xl" show={props.modalIsOpen} onHide={fn_closeModal}
                   onShow={fn_onAfterOpen}>
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        근로자 등록
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">

                    <div className="sh-modal-div">
                        <div className="div-header">
                            <div>
                                선택 근로자 리스트
                            </div>
                        </div>
                        <div className="div-body px-3">
                            <SHSearch formRef={searchFormRef} srchFnc={fn_retrieveWrkPrmtDtlData} btnType={'p'}>
                                <Row>
                                    <Col>
                                        <Table className="sh-font-SP sh-fs-b2 my-3">
                                            <tbody>
                                            <tr className="border-top align-middle border-bottom">
                                                <th className="sh-bg-th">작업인원</th>
                                                <td className={'text-right'}>
                                                    {totCnt + ' 명'}
                                                </td>

                                                <th className="sh-bg-th">이름</th>
                                                <td>
                                                    <SHInput name={'userName'} value={userName} onChange={(e) => setUserName(e.target.value)} className={'form-control'}></SHInput>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>

                            </SHSearch>

                            <div className='w-100 d-flex justify-content-end align-items-center mb-2'>


                                <SHIconButton kind={'submit'} marginRight={'5px'} onClick={fn_regist}> 등록
                                </SHIconButton>

                                <SHIconButton kind={'delete'} marginRight={'5px'} onClick={fn_delete}> 삭제
                                </SHIconButton>

                            </div>

                            <div className={'ge-result'}>
                                <Datatables
                                    datas={dataList}

                                    scrollY="550px"
                                    className="sh-fs-b4"
                                    parameterFormRef={searchFormRef}
                                    columns={[
                                        {data: "userName", title: "이름", textAlign: 'center'},
                                        {data: "companyName", title: "업체", textAlign: 'center'},
                                        {data: "siteWrkTpNm", title: "공종", textAlign: 'center'},
                                        {data: "siteOcptNm", title: "직종", textAlign: 'center'},

                                    ]}
                                    checked={true}
                                    index={true}
                                    isNotPaging={true}
                                    isNotOrdering={true}

                                    onLoad={(dt: any) => {
                                        setDatatable(dt)
                                    }}
                                    onClick={(data) => {

                                    }
                                    }
                                />
                            </div>
                        </div>
                    </div>


                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <button onClick={fn_save}
                                className='sh-btn pill p'>저장
                        </button>

                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default WrkrRgstModal;

