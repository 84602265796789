import SHSearch from "../../component/SHSearch";
import {Col, Row, Table} from "react-bootstrap";
import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import Datatables from "../../component/Datatables";
import SHDatePicker from "../../../components/common/SHDatePicker";
import CustomDatePickerRange from "../../component/ui/DatePickerRange";
import axios from "axios";
import {toast} from "react-toastify";
import errorCodeDic from "../../../util/errorCodeDic";
import SHDatePicker2 from "../../../components/common/SHDatePicker2";
import {DateHelper} from "../../../helper/DateHelper";
import Select from "react-select";

interface IProps {
   dataList: any;
   setSelData: Dispatch<SetStateAction<any>>;
   srchData: any;
   setSrchData: Dispatch<SetStateAction<boolean>>;
   isDatePopupOpen: boolean;
   setIsDatePopupOpen: Dispatch<SetStateAction<boolean>>;
   onSearch: any;
}

const RiskEvalChkStatPage = (props: IProps) => {



    const fn_setSrchData = (e: any) => {

        props.setSrchData({
            ...props.srchData,
            [e.target.name]: e.target.value
        })
    }
    return(
        <>
            <SHSearch btnType={'p'} onSearch={props.onSearch}>
                <Row>
                    <Col>
                        <Table className="sh-font-SP sh-fs-b2 my-3">
                            <tbody>
                            <tr className="align-middle border-bottom">
                                <th className="sh-bg-th">조치상태</th>
                                <td>
                                    <div className="d-flex flex-row gap-2">
                                        <select name={'actStatNm'} className={'form-select'} onChange={fn_setSrchData}>
                                            <option value={''}>전체</option>
                                            <option>해당없음</option>
                                            <option>조치완료</option>
                                        </select>

                                    </div>
                                </td>
                                <th className="sh-bg-th">점검결과</th>
                                <td>
                                    <div className="d-flex flex-row gap-2">
                                        <select name={'chkRsltNm'} className={'form-select'} onChange={fn_setSrchData}>
                                            <option value={''}>전체</option>
                                            <option>점검전</option>
                                            <option>양호</option>
                                            <option>불량</option>
                                        </select>

                                    </div>
                                </td>
                                <th className="sh-bg-th">위험등급</th>
                                <td>
                                    <div className="d-flex flex-row gap-2">
                                        <select name={'riskGrdNm'} className={'form-select'}onChange={fn_setSrchData}>
                                            <option value={''}>전체</option>
                                            <option>상</option>
                                            <option>중</option>
                                        </select>

                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </SHSearch>

            <div className={'ge-result'}>
                <Datatables
                    datas={props.dataList}
                    scrollY="550px"
                    className="sh-fs-b4"
                    columns={[
                        {data: "cmpnyNm", title: "업체"},
                        {data: "dngrFctr", title: "위험요인"},
                        {data: "", title: "위험등급" , render: (data: any, type: any, row: any) => {
                            const frequency = row.frequency;
                            const strength = row.strength;
                            const mltyVal = frequency * strength;
                            let rtrnVal = '';
                            if(mltyVal >= 9) {
                                rtrnVal = '상';
                            }else if(mltyVal <= 3) {
                                rtrnVal = '하';
                            }else {
                                rtrnVal = '중';
                            }
                            return rtrnVal;

                        }},
                        {data: "chckrNm", title: "점검자"},
                        {data: "chkRsltNm", title: "점검결과", render: (data: any, type: any, row: any) => {
                            if(data == '양호') {
                                return '<span class="text-green-500">' + data + '</span>';
                            }else if(data == '불량') {
                                return '<span class="text-red-500">' + data + '</span>';
                            }else{
                                return '<span>' + data + '</span>';
                            }
                            }},
                        {data: "actStatNm", title: "조치상태", render: (data: any) => {
                                if(data == '조치완료') {
                                    return '<span class="text-blue-500">' + data + '</span>';
                                }else{
                                    return '<span>' + data + '</span>';
                                }
                            }},
                        {
                            title: "기능",
                            type: "button",
                            className: "text-center",
                            buttons: [{
                                label: "<img style='height: 20px' class='cursor-pointer' src='/icon_search.svg' />",
                                onClick: (data: any) => {
                                    data.isHist = true;
                                    props.setSelData(Object.assign({}, data));
                                },
                            }],
                        },

                    ]}
                    index={true}
                    checked={false}
                    onLoad={(dt: any) => {
                        //setDatatable(dt)
                    }}

                    onClick={(data) => {
                        data.isHist = false;
                        props.setSelData(Object.assign({}, data));
                    }
                    }
                />


            </div>
        </>
        )

}

export default RiskEvalChkStatPage;
