import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import SHInput from "../../../components/common/SHInput";
import SHTextarea from "../../../components/common/SHTextarea";
import SHFile from "../../../components/common/SHFile";
import {ProgressContext} from "../../../context/Progress";
import {getLoginUser} from "../../../helper/SecurityHelper";
import SHSelect from "../../../components/common/SHSelect";
import restapi from "../../../helper/restAPIHelper";
import {toast} from "react-toastify";
import axios from "axios";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import SHIconButton from "../../../components/common/SHIconButton";
import iconTrashBean from "../../../images/icon_remove.png";
import SHDatePicker2 from "../../../components/common/SHDatePicker2";
import {CmmnFn} from "../../../helper/CmmnHelper";
import SiteMngrSrchModal from "./SiteMngrSrchModal";
import {DateHelper} from "../../../helper/DateHelper";
import ApprCardList from "../../../components/appr/ApprCardList";
import ApprLineModal from "./ApprLineModal";
import SHPdfViewModal from "../../../components/common/SHPdfViewModal";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
    siteId: number;
    cmpnySeq: number;
    isCopy: boolean;

}

const PTWAlwaysRgstModal = (props: IProps) => {

    const user = getLoginUser();

    const [data, setData] = useState<any>();
    const [wrkDt, setWrkDt] = useState<Date | null>();
    const [selRqstr, setSelRqstr] = useState<any>([])
    const [selChrgrs, setSelChrgrs] = useState<any>([])
    const [wrkLeader, setWrkLeader] = useState<any>([]);
    const [sftyMngr, setSftyMngr] = useState<any>([]);

    const [isRqstrSrchModalOpen, setIsRqstrSrchModalOpen] = useState<boolean>(false);
    const [isMngrSrchModalOpen, setIsMngrSrchModalOpen] = useState<boolean>(false);

    const [isWrkLeaderModalOpen, setIsWrkLeaderModalOpen] = useState<boolean>(false);
    const [isSftyMngrModalOpen, setIsSftyMngrModalOpen] = useState<boolean>(false);
    const [isPdfViewModalOpen, setIsPdfViewModalOpen] = useState<boolean>(false);

    const formRef = React.createRef<HTMLFormElement>();
    const {spinner} = useContext(ProgressContext);
    const defaultUser: any = getLoginUser();
    const [cnstrctnDataList, setCnstrctnDataList] = useState<any>([]);
    const [cnstrctnTargetIdx, setCnstrctnTargetIdx] = useState<number>(0);

    const [wrkMiList, setWrkMiList] = useState<any>([]);
    const [wrkHrList, setWrkHrList] = useState<any>([]);


    const [isApprLineModalOpen, setIsApprLineModalOpen] = useState(false);
    const [apprLineDataList, setApprLineDataList] = useState<any>([]);
    const [mngrUserDataList, setMngrUserDataList] = useState<any>([]);
    const [allUserDataList, setAllUserDataList] = useState<any>([]);
    const [refUserList, setRefUserList] = useState<any>([]);




    useEffect(() => {

        console.log('selChrgrs: ', selChrgrs);
        let wrkChrgrNm = '';
        if (selChrgrs.length > 0) {
            selChrgrs.map((data: any, idx: number) => {
                if (idx > 0) {
                    wrkChrgrNm += ', ';
                }
                wrkChrgrNm += data.userName;
            })
        }

        setData({
            ...data,
            'wrkChrgrNm': wrkChrgrNm
        })
    }, [selChrgrs])

    useEffect(()=>{
        console.log("wrkLeader",wrkLeader)
        if(wrkLeader.length != 0){
            let tempArr = [...cnstrctnDataList];
            tempArr[cnstrctnTargetIdx]["wrkLeader"] = wrkLeader[0].userName;
            tempArr[cnstrctnTargetIdx]["wrkLeaderUserSeq"] = wrkLeader[0].userSeq;
            setCnstrctnDataList(tempArr);
        }
    }, [wrkLeader]);

    useEffect(()=>{
        console.log("sftyMngr",sftyMngr)
        if(sftyMngr.length != 0){
            let tempArr = [...cnstrctnDataList];
            tempArr[cnstrctnTargetIdx]["sftyMngr"] = sftyMngr[0].userName;
            tempArr[cnstrctnTargetIdx]["sftyMngrUserSeq"] = wrkLeader[0].userSeq;
            setCnstrctnDataList(tempArr);
        }
    }, [sftyMngr]);

    const fn_updateData = (e: any) => {
        CmmnFn.setFormData(e, data, setData);
    }

    const fn_updateCnstrctnData = (e:any, idx:number) =>{
        let tempArr = [...cnstrctnDataList];
        tempArr[idx][e.target.name] = e.target.value;
        setCnstrctnDataList(tempArr);
    }

    const submit = (e: any) => {
        e.preventDefault()
    }

    const fn_registData = () => {

        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if (!data.wrkDt) {
            toast.warn('작업일자를 입력해 주십시오.')
            return;
        }

        if (!data.wrkTypeCd) {
            toast.warn('공종을 선택해 주십시오.')
            return;
        }

        if (!data.wrkPlcCd) {
            toast.warn('작업장소를 선택해 주십시오.')
            return;
        }

        if(selRqstr.length == 0){
            toast.warn('계획서 작성자를 선택해 주십시오.');
            return;
        }

        if (selChrgrs.length == 0) {
            toast.warn('계획서 검토자를 선택해 주십시오.');
            return;
        }

        if (!data.detailWorkContents) {
            toast.warn('세부 작업내용을 입력해 주십시오.')
            return;
        }

        if (!data.workSafetyContents) {
            toast.warn('작업 안전대책을 입력해 주십시오.')
            return;
        }

        if (!data.pointAndResultContents) {
            toast.warn('지적사항 및 처리결과를 입력해 주십시오.')
            return;
        }

        if(cnstrctnDataList.length == 0){
            toast.warn('공사내용을 입력해 주십시오.')
            return;
        }

        let cnstrctnDataValidationCheck = true;
        cnstrctnDataList.map((item:any, idx:number)=>{
            const no = idx+1;
            if(!item?.wrkNm){
                toast.warn(no + '번째 공사내용의 작업명을 입력해 주십시오.')
                cnstrctnDataValidationCheck = false;
                return;
            }
            if(!item?.dngrsWrk){
                toast.warn(no + '번째 공사내용의 유해 및 위험작업을 입력해 주십시오.')
                cnstrctnDataValidationCheck = false;
                return;
            }
            if(!item?.wrkTimeGbCd || !item?.wrkStrtHr || !item?.wrkStrtMi){
                toast.warn(no + '번째 공사내용의 작업시간을 입력해 주십시오.')
                cnstrctnDataValidationCheck = false;
                return;
            }
            if(!item?.wrkLeaderUserSeq){
                toast.warn(no + '번째 공사내용의 작업책임자를 입력해 주십시오.')
                cnstrctnDataValidationCheck = false;
                return;
            }
            if(!item?.sftyMngrUserSeq){
                toast.warn(no + '번째 공사내용의 안전관리자를 입력해 주십시오.')
                cnstrctnDataValidationCheck = false;
                return;
            }
            if(!item?.wrkrCount){
                toast.warn(no + '번째 공사내용의 근로자수를 입력해 주십시오.')
                cnstrctnDataValidationCheck = false;
                return;
            }
        });

        if(!cnstrctnDataValidationCheck){
            return;
        }

        let parmChrgs: any = []
        selChrgrs.map((data: any) => {
            parmChrgs.push(data.userSeq);
        })

        let param = data;
        param["ptwTypeCd"] = 'S016002'
        param["rgstrSeq"] = defaultUser.userSeq;
        param["updtrSeq"] = defaultUser.userSeq;
        param["wrkRqstrSeq"] = selRqstr[0].userSeq;
        param["chrgDataList"] = parmChrgs;
        param["siteId"] = props.siteId;
        param["cmpnySeq"] = props.cmpnySeq
        param["ptwAlwaysWrkVOList"] = cnstrctnDataList

        // @ts-ignore
        var formData: any = new FormData(formRef.current);



        // 복사된 내용은 신규등록을 해야하므로  ptwId 제거
        if(props.isCopy){
            param.ptwId = null;
        }

        formData.set('ptwData', new Blob([JSON.stringify(param)], {type: "application/json"}));        formData.set('ptwData', new Blob([JSON.stringify(param)], {type: "application/json"}))


        if (formData) {
            if (!window.confirm('저장 하시겠습니까?')) {
                return;
            }
            spinner.start();
            axios.post('/admin/api/ptw/saveAlwaysPtwData', formData).then((result) => {
                    if (result.data > 0) {
                        toast.success(messageCodeDic['001'])
                        props.setModalIsOpen(false);
                    } else {
                        toast.error(errorCodeDic['003']);
                    }
                }
            ).catch(error => {
                toast.error(errorCodeDic['003']);
                console.log('error: ', error);
            })
            .finally(() => {
                spinner.stop();
            });
        }
    }

    const fn_commentRegist = () => {
        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if (!data.comment) {
            toast.warn('코멘트를 입력해 주십시오.')
            return;
        }

        let param = data;
        // @ts-ignore
        var formData: any = new FormData(formRef.current)

        param.cnfrmSeq = defaultUser.userSeq;

        formData.set('sftyNwspprVO', new Blob([JSON.stringify(param)], {type: "application/json"}))

        if (formData) {
            if (!window.confirm('확인 처리 하시겠습니까?')) {
                return;
            }

            spinner.start();
            axios.post('/admin/api/sftyNwsppr/saveSftyNwspprDataForComment', formData).then((result) => {
                if (result.data > 0) {
                    toast.success(messageCodeDic['001'])
                    props.setModalIsOpen(false);
                } else {
                    toast.error(errorCodeDic['003']);
                }
            }).catch(error => {
                toast.error(errorCodeDic['003']);
                console.log('error: ', error);
            }).finally(() => {
                spinner.stop();
            });
        }
    }

    const fn_openModal = () => {
        fn_genWrkTime();
        if(!props.detailData){
            setData({siteId:props.siteId});
            setWrkDt(null);
            setSelRqstr([]);
            setSelChrgrs([]);
            setWrkLeader([]);
            setSftyMngr([]);
            setCnstrctnDataList([])
            setCnstrctnTargetIdx(0);
            setApprLineDataList([]);


            console.log("신규");
        }else{
            //계획서 작성자
            if(props.detailData.wrkRqstrSeq){
                setSelRqstr([{userSeq: props.detailData.wrkRqstrSeq, userName: props.detailData.wrkRqstrNm}])
            }

            // 계획서 검토자
            restapi.retrievePtwChrgrDataList({ptwId: props.detailData.ptwId}).then((rsltData: any) => {
                setSelChrgrs(rsltData);
            });

            //작업 목록
            restapi.retrievePtwAlwaysWrkDataList({ptwId: props.detailData.ptwId}).then((rsltData: any) => {
                console.log("rsltData",rsltData)
                setCnstrctnDataList(rsltData)
            });

            setData(props.detailData);
            setWrkDt(DateHelper.parse(props.detailData?.wrkDt))

            fn_retrieveApprUserDataList(props.detailData);


        }
    }

    const fn_closeModal = () => {
        props.setModalIsOpen(false);
    }

    const fn_delRow = (idx: number) => {
        let tempArr: any = [].concat(cnstrctnDataList);
        tempArr.splice(idx, 1);
        setCnstrctnDataList(tempArr);
    }

    const fn_openMngrSrchModal = (rqstChrgGb: string) => {
        if(rqstChrgGb == 'rqst'){
            setIsRqstrSrchModalOpen(true);
        }else if(rqstChrgGb == 'chrg'){
            setIsMngrSrchModalOpen(true);
        }
    }

    const fn_openCnstrctnMngrSrchModal = (rqstChrgGb: string, idx:number) =>{
        setCnstrctnTargetIdx(idx);

        if(rqstChrgGb == 'wrkLeader'){
            setIsWrkLeaderModalOpen(true);
        }else if(rqstChrgGb == 'sftyMngr'){
            setIsSftyMngrModalOpen(true);
        }
    }

    const fn_addConstruction = ((e:any)=>{
        setCnstrctnDataList([... cnstrctnDataList,
            {
                wrkNm : '',
                dngrsWrk : '',
                wrkTimeGbCd : '',
                wrkStrtHr : '',
                wrkStrtMi : '',
                wrkEndHr : '',
                wrkEndMi : '',
                wrkLeaderUserSeq : '',
                wrkLeader : '',
                sftyMngrUserSeq : '',
                sftyMngr : '',
                wrkrCount : ''
            }
        ])
    });

    const fn_genWrkTime = () => {

        let date = new Date(2023, 0, 1);
        let hrArr = ['00']
        let minArr = ['00']
        let hr;
        let mi;

        for (let i = 0; i < 23; i++) {
            date.setHours(date.getHours() + 1);
            hr = date.getHours() + '';
            if (hr.length == 1) {
                hr = '0' + hr;
            }
            hrArr.push(hr);
        }
        for (let i = 0; i < 5; i++) {
            date.setMinutes(date.getMinutes() + 10);
            mi = date.getMinutes() + '';
            if (mi.length == 1) {
                mi = '0' + mi;
            }
            minArr.push(mi);
        }


        setWrkHrList(hrArr);
        setWrkMiList(minArr)
    }

    const fn_rqstAppr = () => {
        if (apprLineDataList.length == 0) {
            toast.warn('결재선을 선택해 주십시오.');
            return;
        }

        let apprUserSeqList: number[] = [];
        apprLineDataList.map((data: any, idx: number) => {
            apprUserSeqList.push(data.userSeq);
        })

        let refUserSeqList: any = [];

        if (!window.confirm('결재요청 하시겠습니까?')) {
            return;
        }

        let prcsStatCd = '';

        if (apprUserSeqList.length == 1) {
            prcsStatCd = 'S001004';
        } else {
            prcsStatCd = 'S001002';
        }

        axios.post('/admin/api/ptw/rqstAppr', {
            ptwId: props.detailData.ptwId,
            apprUserSeqList: apprUserSeqList,
            refUserSeqList: refUserSeqList,
            prcsStatCd: prcsStatCd
        }).then((rslt) => {
            if (rslt.data > 0) {
                toast.success(messageCodeDic['006']);
                props.setModalIsOpen(false);
            } else {
                toast.warn(errorCodeDic['003']);
            }
        })

    }

    const fn_appr = (gb: string) => {
        let url = '';
        let param: any = {ptwId: props.detailData.ptwId};
        let msg = ''

        if ('appr' == gb) {
            url = '/admin/api/ptw/approve';
            let apprUserSeqList = [];
            const myInfo = apprLineDataList.filter((data: any) => (data.userSeq == user.userSeq))[0];
            const nextInfo = apprLineDataList.filter((data: any) => (data.apprSn == (myInfo.apprSn + 1)));

            apprUserSeqList.push(myInfo.userSeq);
            if (nextInfo.length > 0) {
                apprUserSeqList.push(nextInfo[0].userSeq);
            }

            param.apprUserSeqList = apprUserSeqList;
            msg = '승인 하시겠습니까?'
        } else if ('rjct' == gb) {
            url = '/admin/api/ptw/reject';
            param.userSeq = user.userSeq;
            msg = '반려 하시겠습니까?'
        }

        if (!window.confirm(msg)) {
            return;
        }

        axios.post(url, param).then((rslt) => {
            if (rslt.data > 0) {
                toast.success(messageCodeDic['006']);
                props.setModalIsOpen(false);
            } else {
                toast.warn(errorCodeDic['003']);
            }
        })
    }

    const fn_cancelRqst = () => {

        let apprUserSeqList: any = [];

        apprLineDataList.map((data: any) => {
            apprUserSeqList.push(data.userSeq)
        })
        if (!window.confirm('회수 하시겠습니까?')) {
            return;
        }

        axios.post('/admin/api/ptw/cancelAppr', {
            ptwId: props.detailData.ptwId,
            apprUserSeqList: apprUserSeqList
        }).then((rslt) => {
            if (rslt.data > 0) {
                toast.success(messageCodeDic['006']);
                props.setModalIsOpen(false);
            } else {
                toast.warn(errorCodeDic['003']);
            }
        })
    }

    const fn_deletePtwData = () => {

        if (!window.confirm('삭제 하시겠습니까?')) {
            return;
        }
        axios.post('/admin/api/ptw/deletePtwData', {ptwId: props.detailData.ptwId}).then((rslt) => {
            if (rslt.data > 0) {
                toast.success(messageCodeDic['006']);
                props.setModalIsOpen(false);
            } else {
                toast.warn(errorCodeDic['003']);
            }
        })
    }

    const fn_retrieveApprUserDataList = (detailData: any) => {

        restapi.retrievePtwApprInfo({ptwId: detailData.ptwId}).then((apprData) => {
            const apprUserDataList = apprData.apprUserDataList;
            const refUserDataList: any = [];
            let cstmData: object[] = [];
            console.log('apprData: ', apprData);

            restapi.retrieveUserDataList({
                cmpnySeq: detailData.cmpnySeq,
                isApprMngr: true,
                siteId: props.siteId
            }).then((rsltData) => {
                setAllUserDataList(rsltData);
                if (apprUserDataList.length == 0 && refUserDataList.length == 0) {
                    setMngrUserDataList(rsltData);
                    rsltData = rsltData.filter((data: any) => (data.userSeq != detailData.rgstrSeq))
                    rsltData.map((data: any) => {
                        cstmData.push({
                            label: '[' + data.companyName + '] ' + data.userName + ' (' + data.chrgNm + ')',
                            value: data.userSeq
                        });
                    })
                    setRefUserList(cstmData);
                    setApprLineDataList([]);

                } else {
                    let refUserSeqList: any = [];
                    let apprUserSeqList: any = [];
                    refUserDataList.map((data: any) => {
                        refUserSeqList.push(data.userSeq)
                    })
                    apprUserDataList.map((data: any) => {
                        apprUserSeqList.push(data.userSeq)
                    })

                    setMngrUserDataList(rsltData.filter((data: any) => {
                        return !refUserSeqList.includes(data.userSeq);
                    }));

                    let cstmApprLineDataList = rsltData.filter((data: any) => {
                        return apprUserSeqList.includes(data.userSeq);
                    })

                    cstmApprLineDataList.map((cstmData: any) => {
                        apprUserDataList.map((apprData: any) => {
                            if (cstmData.userSeq == apprData.userSeq) {
                                cstmData.apprStat = apprData.apprStat;
                                cstmData.apprStatNm = apprData.apprStatNm;
                                cstmData.prcsDt = apprData.prcsDt;
                                cstmData.apprSn = apprData.apprSn;
                            }
                        })
                    })

                    // 오름차순 정렬
                    cstmApprLineDataList = cstmApprLineDataList.sort((a: any, b: any) => {
                        if (a.apprSn > b.apprSn) return 1;
                        if (a.apprSn < b.apprSn) return -1;
                        return 0;
                    });

                    setApprLineDataList(cstmApprLineDataList)

                    let csmtSelectData = rsltData.filter((data: any) => {
                        return refUserSeqList.includes(data.userSeq);
                    })


                    csmtSelectData.map((data: any) => {
                        data.label = '[' + data.companyName + '] ' + data.userName + ' (' + data.chrgNm + ')';
                        data.value = data.userSeq;
                    })


                    rsltData = rsltData.filter((data: any) => {
                        return !apprUserSeqList.includes(data.userSeq);
                    })

                    rsltData.map((data: any) => {
                        cstmData.push({
                            label: '[' + data.companyName + '] ' + data.userName + ' (' + data.chrgNm + ')',
                            value: data.userSeq
                        });
                    })

                    setRefUserList(cstmData);

                }

            })

        })


    }

    const fn_onWrkTimeSelChg = (e: any, paramIdx: number) => {
        const timeGb = e.target.value;
        let wrkStrtHr: any;
        let wrkStrtMi: any;
        let wrkEndHr: any;
        let wrkEndMi: any;

        // 주간
        if (timeGb == 'T001001') {
            wrkStrtHr = '08';
            wrkStrtMi = '00';
            wrkEndHr = '17';
            wrkEndMi = '00';

            // 조출
        } else if (timeGb == 'T001002') {
            wrkStrtHr = '05';
            wrkStrtMi = '00';
            wrkEndHr = '08';
            wrkEndMi = '00';
            // 중식
        } else if (timeGb == 'T001003') {
            wrkStrtHr = '12';
            wrkStrtMi = '00';
            wrkEndHr = '13';
            wrkEndMi = '00';
            // 연장
        } else if (timeGb == 'T001004') {
            wrkStrtHr = '17';
            wrkStrtMi = '00';
            wrkEndHr = '22';
            wrkEndMi = '00';
            // 야간
        } else if (timeGb == 'T001005') {
            wrkStrtHr = '22';
            wrkStrtMi = '00';
            wrkEndHr = '00';
            wrkEndMi = '00';

        }

        cnstrctnDataList.map((data: any, idx: number) => {
            if(idx == paramIdx){
                data.wrkStrtHr = wrkStrtHr;
                data.wrkStrtMi = wrkStrtMi;
                data.wrkTimeGbCd = timeGb;
            }
        })

        setCnstrctnDataList([].concat(cnstrctnDataList));


    }

    return (
        <div>
            <Modal
                className="sh-modal" size="xl"
                show={props.modalIsOpen}
                onShow={fn_openModal}
                onHide={fn_closeModal}
            >

                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        {'상시PTW 상세보기'}
                        <div className={'d-flex justify-content-between align-items-center'}>
                            <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <SiteMngrSrchModal setModalIsOpen={setIsRqstrSrchModalOpen} modalIsOpen={isRqstrSrchModalOpen}
                                       mngrDataList={selRqstr} setMngrDataList={setSelRqstr} siteId={props.siteId}
                                       cmpnySeq={props.cmpnySeq} isSingleCheck={true}></SiteMngrSrchModal>
                    <SiteMngrSrchModal setModalIsOpen={setIsMngrSrchModalOpen} modalIsOpen={isMngrSrchModalOpen}
                                       mngrDataList={selChrgrs} setMngrDataList={setSelChrgrs} siteId={props.siteId}
                                       cmpnySeq={props.cmpnySeq}></SiteMngrSrchModal>
                    <ApprLineModal setModalIsOpen={setIsApprLineModalOpen} modalIsOpen={isApprLineModalOpen}
                                   mngrUserDataList={mngrUserDataList}
                                   apprLineDataList={apprLineDataList} cmpnySeq={data?.cmpnySeq} rgstrSeq={data?.rgstrSeq}
                                   setApprLineDataList={setApprLineDataList} siteId={props.siteId}/>

                    <SiteMngrSrchModal setModalIsOpen={setIsWrkLeaderModalOpen} modalIsOpen={isWrkLeaderModalOpen}
                                       mngrDataList={wrkLeader} setMngrDataList={setWrkLeader} siteId={props.siteId}
                                       cmpnySeq={props.cmpnySeq} isSingleCheck={true}></SiteMngrSrchModal>
                    <SiteMngrSrchModal setModalIsOpen={setIsSftyMngrModalOpen} modalIsOpen={isSftyMngrModalOpen}
                                       mngrDataList={sftyMngr} setMngrDataList={setSftyMngr} siteId={props.siteId}
                                       cmpnySeq={props.cmpnySeq} isSingleCheck={true}></SiteMngrSrchModal>
                    <SHPdfViewModal setModalIsOpen={setIsPdfViewModalOpen} modalIsOpen={isPdfViewModalOpen} src={"/wrkPln.pdf"} title={'상시PTW 작성방법 보기'}/>
                    <form name={'detailForm'} ref={formRef} encType="multipart/form-data" method={"post"}
                          onSubmit={submit}>

                        <div className="d-flex justify-content-end">
                            <span style={{cursor:"pointer", color:"blue", fontSize:"13px", textDecoration:"underline"}} onClick={(e)=>{
                                setIsPdfViewModalOpen(true);
                            }}>작성방법 보기</span>
                        </div>
                        {(props.detailData && props.detailData.prcsStatCd) &&
                            <div className="sh-modal-div">
                                <div className="div-header sh-fw-5">
                                    <div className={'min-w-[100px] font-semibold'}>
                                        결재 정보
                                    </div>
                                    <div className='w-100 d-flex justify-content-end align-items-center mb-2'>

                                        {props.detailData.prcsStatCd == 'S001001' && props.detailData?.rgstrSeq == user.userSeq &&
                                            <>
                                                <SHIconButton kind={'normal'} marginRight={'5px'} onClick={() => {
                                                    setIsApprLineModalOpen(true)
                                                }}> 결재선
                                                </SHIconButton>
                                                <SHIconButton kind={'normal'} marginRight={'5px'}
                                                              onClick={fn_rqstAppr}> 결재요청
                                                </SHIconButton>
                                            </>
                                        }
                                        {
                                            apprLineDataList.filter((data: any) => (data.apprStat == 'S002001'))[0]?.userSeq == user.userSeq &&
                                            <>
                                                <button className={'sh-btn mr-5 btn-primary'} onClick={() => {
                                                    fn_appr('appr')
                                                }}> 승인
                                                </button>
                                                <button className={'sh-btn mr-5 btn-danger'} onClick={() => {
                                                    fn_appr('rjct')
                                                }}> 반려
                                                </button>
                                            </>
                                        }

                                        {(props.detailData.prcsStatCd == 'S001002' && (props.detailData?.rgstrSeq == user.userSeq || apprLineDataList.filter((data: any) => (data.userSeq == user.userSeq)).length > 0)) &&
                                            <SHIconButton kind={'normal'} marginRight={'5px'}
                                                          onClick={fn_cancelRqst}> 회수
                                            </SHIconButton>
                                        }

                                        {(['S001001', 'S001002'].includes(props.detailData.prcsStatCd) && props.detailData?.rgstrSeq == user.userSeq) &&

                                            <SHIconButton kind={'delete'} marginRight={'5px'}
                                                          onClick={fn_deletePtwData}> 삭제
                                            </SHIconButton>
                                        }


                                    </div>


                                </div>

                                <div className="div-body px-3">
                                    <div className='w-100 d-flex justify-content-end align-items-center mb-2'>

                                    </div>
                                    <div className="w-100">
                                        {apprLineDataList.length > 0 ? <ApprCardList items={apprLineDataList}/> :
                                            <span>※ 결재정보를 등록해 주십시오.</span>
                                        }

                                    </div>

                                </div>
                            </div>
                        }
                        <div className="sh-modal-div">
                            <div className="div-header">
                                기본 정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>현장명</th>
                                        <td style={{width: '35%'}}>
                                            <SHSelect initOptionGb={'none'} disabled={true} codeGb={'site'}
                                                      onChange={fn_updateData} className={'form-control w-100'}
                                                      value={data?.siteId}></SHSelect>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>작업일자</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-inline-flex gap-1">
                                                <div>
                                                    <SHDatePicker2 setter={setWrkDt} name={'wrkDt'} defaultValue={wrkDt} onChange={fn_updateData} fitWidth={true}/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>공종</th>
                                        <td style={{width: '35%'}}>
                                            <SHSelect initOptionGb={'sel'} name={'wrkTypeCd'}
                                                      selParam={{siteId: props.siteId, manageCode: 'M001001'}}
                                                      codeGb={'siteMngCd'} className="w-100"
                                                      onChange={fn_updateData}
                                                      value={data?.wrkTypeCd}
                                            ></SHSelect>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>작업장소</th>
                                        <td style={{width: '35%'}}>
                                            <div className="w-100 d-flex flex-row gap-2">
                                                <div className="flex-grow-1">
                                                    <SHSelect initOptionGb={'sel'} name={'wrkPlcCd'}
                                                              selParam={{
                                                                  siteId: props.siteId,
                                                                  manageCode: 'M001004'
                                                              }}
                                                              codeGb={'siteMngCd'} className="w-100"
                                                              onChange={fn_updateData}
                                                              value={data?.wrkPlcCd}></SHSelect>
                                                </div>
                                                <div className="w-[150px]">
                                                    <SHInput type="text" name={'wrkPlcDtl'}
                                                             onChange={fn_updateData}
                                                             value={data?.wrkPlcDtl || 0}
                                                             className="sh-form input"
                                                             placeholder="상세장소를 입력해주세요."
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>계획서 작성자</th>
                                        <td style={{width: '35%'}}>
                                            <div className="w-100 d-flex flex-row gap-1">
                                                <div className="flex-grow-1">
                                                    <SHInput type={'text'} readOnly={true}
                                                             className={'form-control'}
                                                             value={selRqstr[0]?.userName}/>
                                                </div>
                                                <div className="w-[80px]">
                                                    <button className="sh-btn btn-secondary min-w-[70px] ml-5"
                                                            type="button" onClick={() => fn_openMngrSrchModal('rqst')}>검색
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>계획서 검토자</th>
                                        <td style={{width: '35%'}}>
                                            <div className="w-100 d-flex flex-row gap-1">
                                                <div className="flex-grow-1">
                                                    <SHInput type={'text'} readOnly={true}
                                                             className={'form-control'} name={'wrkChrgrNm'}
                                                             value={data?.wrkChrgrNm}/>
                                                </div>
                                                <div className="w-[80px]">
                                                    <button className="sh-btn btn-secondary min-w-[70px] ml-5"
                                                            type="button" onClick={() => fn_openMngrSrchModal('chrg')}>검색
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        <div className="sh-modal-div">
                            <div className="div-header">
                                <div className='flex justify-content-between align-items-center w-100'>
                                    <span>공사 내용</span>
                                    <SHIconButton kind='add' onClick={fn_addConstruction}>추가</SHIconButton>
                                </div>
                            </div>
                            <div className="div-body px-3 pt-3">


                                    {
                                        cnstrctnDataList.length > 0 &&
                                            cnstrctnDataList.map((authChrgData: any, idx: number) => (
                                                <Table className="mb-3 align-middle" key={idx}>
                                                    <tbody>
                                                <tr className="border-top align-middle">
                                                    <th className="sh-bg-th">작업명</th>
                                                    <td colSpan={7}>
                                                        <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                                                        <SHInput type="text" name={'wrkNm'}
                                                                 onChange={(e)=>{
                                                                     fn_updateCnstrctnData(e,idx);
                                                                 }}
                                                                 value={cnstrctnDataList[idx].wrkNm}
                                                                 className="sh-form input"
                                                                 placeholder="작업명을 입력해주세요."
                                                        />
                                                        <img
                                                            className={'w-[25px] cursor-pointer inline-block ml-5'}
                                                            src={iconTrashBean} onClick={() => fn_delRow(idx)}/>
                                                        </div>

                                                    </td>
                                                </tr>
                                                <tr className="border-top align-middle">
                                                    <th className="sh-bg-th">유해 및 위험작업</th>
                                                    <td colSpan={7}>
                                                        <SHInput type="text" name={'dngrsWrk'}
                                                                 onChange={(e)=>{
                                                                     fn_updateCnstrctnData(e,idx);
                                                                 }}
                                                                 value={cnstrctnDataList[idx].dngrsWrk}
                                                                 className="sh-form input"
                                                                 placeholder="유해 및 위험작업을 입력해주세요."
                                                        />
                                                    </td>
                                                </tr>
                                                <tr className="border-top align-middle">
                                                    <th className="sh-bg-th">작업시간</th>
                                                    <td>
                                                    <div className="d-inline-flex gap-1">
                                                        <div>
                                                            <SHSelect initOptionGb={'sel'} name={'wrkTimeGbCd'}
                                                                      className={'sh-form min-w-[80px] select mr-10'}
                                                                      codeGb={'cmmn'}
                                                                      codeGrpId={'T001'}
                                                                      onChange={(e)=>{
                                                                          fn_updateCnstrctnData(e,idx);
                                                                          fn_onWrkTimeSelChg(e, idx);
                                                                      }}
                                                                      value={cnstrctnDataList[idx]?.wrkTimeGbCd}
                                                            ></SHSelect>
                                                        </div>
                                                        <div className="d-flex flex-column gap-1">
                                                            <div className="d-flex">
                                                                <select className={'sh-form select'} value={cnstrctnDataList[idx]?.wrkStrtHr || ''}
                                                                        onChange={(e)=>{
                                                                            fn_updateCnstrctnData(e,idx);
                                                                        }}
                                                                        name={'wrkStrtHr'}>
                                                                    {
                                                                        wrkHrList.map((data: string, idx: number) => (
                                                                            <option className={'text-center'} key={idx}
                                                                                    value={data}>{data}</option>
                                                                        ))
                                                                    }

                                                                </select>
                                                                <span className="mx-10 align-self-center">시</span>
                                                                <select className={'sh-form select'} name={'wrkStrtMi'}
                                                                        onChange={(e)=>{
                                                                            fn_updateCnstrctnData(e,idx);
                                                                        }}
                                                                        value={cnstrctnDataList[idx]?.wrkStrtMi || ''}
                                                                >
                                                                    {
                                                                        wrkMiList.map((data: string, idx: number) => (
                                                                            <option className={'text-center'} key={idx}
                                                                                    value={data}>{data}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                                <span className="mx-10 align-self-center">분</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </td>
                                                    <th className="sh-bg-th">작업책임자</th>
                                                    <td>
                                                        <SHInput type={'hidden'} name={'wrkLeaderUserSeq'} value={cnstrctnDataList[idx].wrkLeaderUserSeq}></SHInput>
                                                        <SHInput type={'text'} readOnly={true}
                                                                 name={'wrkLeader'}
                                                                 className={'form-control'}
                                                                 placeholder="작업책임자를 선택해주세요."
                                                                 onClick={() => fn_openCnstrctnMngrSrchModal('wrkLeader', idx)}
                                                                 value={cnstrctnDataList[idx].wrkLeader}/>
                                                    </td>
                                                    <th className="sh-bg-th">안전관리자</th>
                                                    <td>
                                                        <SHInput type={'hidden'} name={'sftyMngrUserSeq'} value={cnstrctnDataList[idx].sftyMngrUserSeq}></SHInput>
                                                        <SHInput type={'text'} readOnly={true}
                                                                 name={'sftyMngr'}
                                                                 className={'form-control'}
                                                                 placeholder="안전관리자를 선택해주세요."
                                                                 onClick={() => fn_openCnstrctnMngrSrchModal('sftyMngr', idx)}
                                                                 value={cnstrctnDataList[idx].sftyMngr}/>
                                                    </td>
                                                    <th className="sh-bg-th">근로자 수</th>
                                                    <td>
                                                        <SHInput type="number" name={'wrkrCount'}
                                                                 onChange={(e)=>{
                                                                     fn_updateCnstrctnData(e,idx);
                                                                 }}
                                                                 value={cnstrctnDataList[idx].wrkrCount}
                                                                 className="sh-form input"
                                                                 placeholder="숫자입력"
                                                        />
                                                    </td>
                                                </tr>
                                                    </tbody>
                                                </Table>
                                        ))

                                    }


                            </div>
                        </div>
                        <div className="sh-modal-div">
                            <div className="div-header">
                                상세 정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>세부 작업내용</th>
                                        <td style={{width: '85%'}} colSpan={3}>
                                            <SHTextarea rows={5} onChange={fn_updateData} name={'detailWorkContents'}
                                                        value={data?.detailWorkContents}
                                                        className={"form-control"}
                                                        placeholder='세부 작업내용을 입력해주세요.'
                                            />
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>작업 안전대책</th>
                                        <td style={{width: '85%'}} colSpan={3}>
                                            <SHTextarea rows={5} onChange={fn_updateData} name={'workSafetyContents'}
                                                        value={data?.workSafetyContents}
                                                        className={"form-control"}
                                                        placeholder='작업 안전대책을 입력해주세요.'
                                            />
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>지적사항 및 처리결과</th>
                                        <td style={{width: '85%'}} colSpan={3}>
                                            <SHTextarea rows={5} onChange={fn_updateData} name={'pointAndResultContents'}
                                                        value={data?.pointAndResultContents}
                                                        className={"form-control"}
                                                        placeholder='지적사항 및 처리결과을 입력해주세요.'
                                            />
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </form>
                </Modal.Body>

                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <button onClick={fn_registData}
                                className='sh-btn pill p'>저장
                        </button>
                    </div>
                </Modal.Footer>

            </Modal>
        </div>
    )
}

export default PTWAlwaysRgstModal;
