import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import Datatables, { getCheckedItems } from "../component/Datatables";
import Modal from "react-modal";
import { getLoginUser } from "../../helper/SecurityHelper";
import { toast } from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import { Col, Container, Row, Table } from "react-bootstrap";
import SubMenuTitle from "../component/SubMenuTitle";
import SHSearch from "../component/SHSearch";
import SHIconButton from "../../components/common/SHIconButton";
import { UserContext } from "../../context/User";
import SHDatePicker2 from "../../components/common/SHDatePicker2";
import { DateHelper } from "../../helper/DateHelper";
import IncidentMngModal from "./IncidentMngModal";
import SHInput from "../../components/common/SHInput";
import restapi from "../../helper/restAPIHelper";
type Props = {};

Modal.setAppElement("#root");
const IncidentMain = (props: Props) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [detailData, setDetailData] = useState(null);
  const defaultUser = getLoginUser();
  const srchTxtRef = useRef<any>();
  const searchFormRef = useRef<any>();
  const [datatable, setDatatable] = useState<any>();
  const prodRef = useRef<any>();
  const [startDate, setStartDate] = useState<any>(DateHelper.dateByMonth(-3));
  const [endDate, setEndDate] = useState<any>(DateHelper.dateByMonth(3));
  const [data, setData] = useState<any>();
  const [isCodeDataLoaded, setIsCodeDataLoaded] = useState<boolean>(false);
  const { user } = useContext<any>(UserContext);

  useEffect(() => {
    if (datatable) {
      datatable.draw();
    }
  }, [user, datatable]);

  useEffect(() => {
    if (!modalIsOpen) {
      datatable?.draw();
    }
  }, [datatable, modalIsOpen]);

  useEffect(() => {
    if (isCodeDataLoaded) {
      datatable?.draw();
    }
  }, [isCodeDataLoaded]);

  const fn_setFormData = (e: any) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const fn_search = (e?: any) => {
    if (e) {
      e.preventDefault();
    }
    datatable.draw();
  };

  const fn_delete = () => {
    let checkedData = getCheckedItems(datatable, "incidentId");
    if (checkedData.length === 0) {
      toast.warn(messageCodeDic["005"]);
      return;
    }
    if (!window.confirm(messageCodeDic["004"])) {
      return;
    }
    axios
      .post("/admin/api/incidentManage/deleteIncidentManageData", {
        delList: checkedData,
      })
      .then((rsltData) => {
        if (rsltData.data > 0) {
          toast.success(messageCodeDic["002"]);
          fn_search();
        } else {
          toast.error(errorCodeDic["003"]);
        }
      })
      .catch(() => {
        toast.error(errorCodeDic["003"]);
      });
  };

  const fn_registPopOpen = () => {
    setDetailData(null);
    setModalIsOpen(true);
  };

  const fn_downLoadFiles = (v: any) => {
    if (v.atchFileId) {
      restapi
        .retrieveAttachInfoList({ attachSeq: v.atchFileId })
        .then((rsltData: any) => {
          rsltData.forEach((file: any) => {
            const url =
              process.env.REACT_APP_FILE_URL +
              "/shss-data/fileDownload?attachSeq=" +
              file.attachSeq +
              "&sn=" +
              file.sn +
              "&isPdf=0";
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "file_name_to_download");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        });
    } else {
      toast.error("존재하지 않는 파일입니다.");
    }
  };

  const fn_updateViewCnt = (param: object) => {
    axios.post("/admin/api/notice/updateViewCnt", param);
  };

  return (
    <Container fluid className="bg-white px-4 pt-4 sh-text-font_primary">
      <SubMenuTitle title={"사고발생 관리"} parentMenu={defaultUser.authGrpId == 'HEAD' ? "안전 정보 및 공지 관리" : "현장안전 관리"} />
      <IncidentMngModal
        setModalIsOpen={setModalIsOpen}
        modalIsOpen={modalIsOpen}
        siteId={user?.blngSiteId || defaultUser.blngSiteId}
        detailData={detailData}
      />
      <SHSearch datatable={datatable} formRef={searchFormRef} btnType={"p"}>
        <Row>
          <Col>
            <Table className="sh-font-SP sh-fs-b2 my-3">
              <tbody>
                <tr className="border-top border-bottom align-middle">
                  <th className="sh-bg-th" style={{ width: "15%" }}>
                    기간검색
                  </th>
                  <td style={{ width: "35%" }}>
                    <div className="d-flex flex-row gap-2">
                      <SHInput
                        type={"hidden"}
                        name={"siteId"}
                        value={user?.blngSiteId || defaultUser.blngSiteId}
                      ></SHInput>
                      <div className="d-flex align-items-center" ref={prodRef}>
                        <SHDatePicker2
                          setter={setStartDate}
                          name={"startRgstDt"}
                          defaultValue={startDate}
                          onChange={fn_setFormData}
                        />
                        <span>&nbsp;~&nbsp;</span>
                        <SHDatePicker2
                          setter={setEndDate}
                          name={"endRgstDt"}
                          defaultValue={endDate}
                          onChange={fn_setFormData}
                        />
                      </div>
                      <div
                        className="d-flex align-items-center gap-1"
                        ref={prodRef}
                      >
                        <input
                          className="form-check-input sh-check"
                          id={"isAll"}
                          name={"isAll"}
                          type="checkbox"
                          onChange={fn_setFormData}
                        />
                        <label
                          className={"form-check-label text-black"}
                          htmlFor={"isAll"}
                        >
                          전체기간
                        </label>
                      </div>
                    </div>
                  </td>
                  <th className="sh-bg-th" style={{ width: "15%" }}>
                    검색어
                  </th>
                  <td colSpan={3}>
                    <input
                      type={"text"}
                      name={"srchTxt"}
                      className="form-control"
                      ref={srchTxtRef}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </SHSearch>
      <div className="w-100 d-flex justify-content-end align-items-center mb-2">
        {user?.blngSiteId || defaultUser.blngSiteId ? (
          <>
            <SHIconButton
              kind={"submit"}
              marginRight={"5px"}
              onClick={fn_registPopOpen}
            >
              등록
            </SHIconButton>

            <SHIconButton kind={"delete"} marginRight={"0"} onClick={fn_delete}>
              삭제
            </SHIconButton>
          </>
        ) : null}
      </div>
      <div className={"ge-result"}>
        <Datatables
          dataUrl={"/admin/api/incidentManage/retrieveIncidentManageDataList"}
          parameterFormRef={searchFormRef}
          columns={[
            { data: "incidentTitle", title: "제목", textAlign: "left" },
            { data: "siteName", title: "현장명" },
            { data: "userName", title: "작성자" },
            {
              data: "incidentRgstDt",
              title: "작성일",
              orderColumn: "REG_DATE",
              orderBase: true,
            },
            {
              title: "첨부파일",
              type: "button",
              className: "text-center",
              buttons: [
                {
                  label:
                    "<img style='height: 20px' class='cursor-pointer' src='/download.svg' />",
                  onClick: (v: any) => {
                    fn_downLoadFiles(v);
                  },
                },
              ],
            },
          ]}
          checked={true}
          index={true}
          onLoad={(dt: any) => {
            setDatatable(dt);
          }}
          onClick={(data) => {
            setDetailData(data);
            setModalIsOpen(true);
            fn_updateViewCnt(data);
          }}
        />
      </div>
    </Container>
  );
};

export default IncidentMain;
