import React, {useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import InfoMap from "./map/InfoMap";
import RealMap from "./map/RealMap";
const HOCRMap = () => {
    const [showInfoMap, setShowInfoMap] = useState<boolean>(true);

    return(
        <>
            <Container fluid className="sh-bg-pane rounded-[5px] py-2 px-3 h-100">
                <Row className="px-0 mb-2">
                    <Col>
                        <div className="d-flex flex-row justify-content-between">
                            <div>
                                <div className="d-flex pe-2 sh-fs-h4 sh-fw-5 sh-font-SD mb-3">지역별 현장 현황</div>
                            </div>
                            <div className="d-flex sh-bg-pane rounded-[5px] py-1 px-1 ">
                                <span className={"rounded-[5px] py-1 px-2 me-2 " + (showInfoMap ? 'sh-bg-accent' : '')} onClick={()=>setShowInfoMap(true)}>정보맵</span>
                                <span className={"rounded-[5px] py-1 px-2      " + (showInfoMap ? '' : 'sh-bg-accent')} onClick={()=>setShowInfoMap(false)}>지도</span>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="p-0">
                    <Col><div className="w-100 position-relative sh-fs-b4 sh-font-SP sh-fw-5" style={{height:"calc(100vh - 161px)", borderRadius: "10px"}}>
                            {showInfoMap ?
                                <InfoMap/>
                                :
                                <RealMap/>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default HOCRMap;
