
import React, {Dispatch, MutableRefObject, SetStateAction, useContext, useEffect, useRef, useState} from "react";


import {getLoginUser} from '../../helper/SecurityHelper';
import MainSiteInfoManagePage from "./MainSiteInfoManagePage";
import SubSiteInfoManagePage from "./SubSiteInfoManagePage";


type IProps = {};


const ConstructionBaseInfoManagementPage = (props: IProps) => {
    const defaultUser = getLoginUser();

    return (
        defaultUser.companyGbCd === 'C001002' ? <MainSiteInfoManagePage />
            : <SubSiteInfoManagePage />

    )
}

export default ConstructionBaseInfoManagementPage;

