import React, {Dispatch, SetStateAction, useEffect, useRef} from 'react';
import {ReactComponent as IconSearch} from '../../images/icon_search.svg';

interface IProps {
    datatable?: any;
    children: any;
    formRef?: any;
    btnType?: any;
    onSearch?: any;
    srchFnc?: any;

}

export default function View(props: IProps) {
    const fn_search = (e?: any) => {
        console.log('search');
        e.preventDefault();
        if(props.datatable){
            props.datatable.draw()
        }else if(props.onSearch){
            props.onSearch()
        }else if(props.srchFnc){
            props.srchFnc()
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', handler);
        return () => {
          window.removeEventListener('keydown', handler);
        }
      }, []);

    const handler = (e: any) => {
        if(e.key=='Enter') {
            console.log(props.formRef.current);

            props.formRef.current.dispatchEvent(
                new Event("submit", { cancelable: true, bubbles: true })
            );
        }
    }

    return (
        <div className="rounded-[5px] border mb-3 px-3">
            <form ref={props.formRef} onSubmit={fn_search}>
                <div className="ge-search">
                    {props.children}
                    <div className='row'>
                        <div className="col-md-12 text-end mb-3 d-flex justify-content-end">
                            {props.btnType != 'h' &&
                                <button type={"button"} className={"sh-btn py-2 px-3 search " + (props.btnType == 'p' ? ' p' : ' l')}
                                        onClick={fn_search}>
                                    {
                                        props.btnType == 'p' ?
                                            <IconSearch style={{fill: '#ffffff'}}/> :
                                            <IconSearch style={{fill: '#a4a4a4'}}/>
                                    }
                                    검색
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

