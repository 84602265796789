import {Col, Container, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import ecardApi from "../../../helper/ecardAPIHelper";


interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    workerSeq?: number;
}

const AsscWrkDtlApiModal = (props: IProps) => {
    const [data, setData] = useState<any>()
    useEffect(() => {
        if(props.workerSeq){
            ecardApi.workerInfo(props.workerSeq).then((r)=>{
                setData(r)
            })
        }
    }, [props.workerSeq])
    function fn_closeModal() {
        props.setModalIsOpen(false);
    }
    return (
        <div>
            <Modal
                className="sh-modal" size="xl"
                show={props.modalIsOpen}
                onHide={fn_closeModal}
            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        상세보기
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <Container>
                        <Row>
                        <Col className={"col-6 mb-2"}>
                            <label>근로자번호</label>
                            <span className="form-control form-control-sm">{data?.workerSeq}</span>
                        </Col>
                        <Col className={"col-6 mb-2"}>
                            <label>근로자명</label>
                            <span className="form-control form-control-sm">{data?.workerNm}</span>
                        </Col>
                        <Col className={"col-6 mb-2"}>
                            <label>근로자한글명</label>
                            <span className="form-control form-control-sm">{data?.workerNick}</span>
                        </Col>
                        <Col className={"col-6 mb-2"}>
                            <label>국적</label>
                            <span className="form-control form-control-sm">{ecardApi.nationalityNm(data?.nationalityCd)}</span>
                        </Col>
                        <Col className={"col-6 mb-2"}>
                            <label>생년월일</label>
                            <span className="form-control form-control-sm">{data?.birth}</span>
                        </Col>
                        <Col className={"col-6 mb-2"}>
                            <label>성별</label>
                            <span className="form-control form-control-sm">{data?.gender}</span>
                        </Col>
                        <Col className={"col-6 mb-2"}>
                            <label>연락처</label>
                            <span className="form-control form-control-sm">{data?.phone}</span>
                        </Col>
                        <Col className={"col-6 mb-2"}>
                            <label>우편번호</label>
                            <span className="form-control form-control-sm">{data?.zipNo}</span>
                        </Col>
                        <Col className={"col-12 mb-2"}>
                            <label>기본주소</label>
                            <span className="form-control form-control-sm">{data?.addr}</span>
                        </Col>
                        <Col className={"col-12 mb-2"}>
                            <label>상세주소</label>
                            <span className="form-control form-control-sm">{data?.addrDetail}</span>
                        </Col>
                        <Col className={"col-6 mb-2"}>
                            <label>취업인정교육증 번호</label>
                            <span className="form-control form-control-sm">{data?.employmentCertificateNo}</span>
                        </Col>
                        <Col className={"col-6 mb-2"}>
                            <label>취업교육기관명</label>
                            <span className="form-control form-control-sm">{data?.employmentAuthEduInsttNm}</span>
                        </Col>
                        <Col className={"col-6 mb-2"}>
                            <label>증명서 유효 시작일</label>
                            <span className="form-control form-control-sm">{data?.employmentCertificateValidStartDate}</span>
                        </Col>
                        <Col className={"col-6 mb-2"}>
                            <label>증명서 유효 종료일</label>
                            <span className="form-control form-control-sm">{data?.employmentCertificateValidEndDate}</span>
                        </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AsscWrkDtlApiModal;

