import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from "axios";
import Datatables, {getCheckedItems} from "../component/Datatables";
import Modal from 'react-modal';
import {getLoginUser} from "../../helper/SecurityHelper";
import {toast} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import SHSelect from "../../components/common/SHSelect";
import {CmmnFn} from "../../helper/CmmnHelper";
import {ProgressContext} from "../../context/Progress";
import SHSearch from '../component/SHSearch';
import {Col, Container, Row, Table} from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";
import SHIconButton from '../../components/common/SHIconButton';
import SiteSftyChkRgstModal from "./modal/SiteSftyChkRgstModal";
import SiteSftyChkRsltModal from "./modal/SiteSftyChkRsltModal";
import {TopMenuContext} from "../../context/TopMenu";
import SHDatePicker2 from "../../components/common/SHDatePicker2";
import {DateHelper} from "../../helper/DateHelper";
import SHInput from "../../components/common/SHInput";

type IProps = {
};

Modal.setAppElement('#root');
const SiteSftyChkBsnsPage = (props: IProps) => {
    const [dataList, setDataList] = useState<any[]>([]);
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [rsltModalIsOpen, setRsltModalIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState(null);
    const [isSaveSuccess, setIsSaveSuccess] = useState(false);
    const [srchData, setSrchData] = useState<any>()
    const {spinner} = useContext(ProgressContext);
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const [startDate, setStartDate] = useState<Date>(DateHelper.dateByMonth(-3));
    const [endDate, setEndDate] = useState<Date>(DateHelper.dateByMonth(0));
    const user = getLoginUser();
    const prodRef = useRef<any>();
    const [prcsGb, setPrcsGb] = useState<any>(null);
    const {menu} = useContext<any>(TopMenuContext);


    useEffect(() => {
        if (datatable && srchData &&  !modalIsOpen &&!rsltModalIsOpen) {
            fn_search();
        }
    }, [datatable, srchData, modalIsOpen, rsltModalIsOpen])

    const fn_setSearchData = (e: any) => {
        setSrchData({
            ...srchData,
            [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        if (!modalIsOpen) {
            datatable?.draw();
        }
    }, [modalIsOpen])

    useEffect(() => {
        if (!rsltModalIsOpen) {
            datatable?.draw();
        }
    }, [rsltModalIsOpen])

    const fn_search = (e?: any) => {
        if (e) {
            e.preventDefault()
        }
        datatable.draw();
        setIsSaveSuccess(false);
    }

    const fn_delete = () => {
        let checkedData = getCheckedItems(datatable,"sftyChkSeq")
        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }
        if(!window.confirm(messageCodeDic['004'])){
            return;
        }
        axios.post('/admin/api/siteSftyManage/deleteSftyWrkMetData', {delList: checkedData}).then(rsltData => {
            if (rsltData.data > 0) {
                toast.success(messageCodeDic['002'])
                fn_search();
            } else {
                toast.error(errorCodeDic['003'])
            }
        }).catch(() => {
            toast.error(errorCodeDic['003'])
        })
    }

    const fn_registPopOpen = () => {
        setPrcsGb(null);
        setDetailData(null);
        setModalIsOpen(true);
    }

    const fn_updateViewCnt = (param: object) => {
        axios.post('/admin/api/notice/updateViewCnt', param)
    }

    function dateFormat(addMonth: number) {
        let today = new Date();
        let month = today.getMonth() + addMonth;
        let day = today.getDate();
        let monthFormat = month >= 10 ? month : '0' + month;
        let dayFormat = day >= 10 ? day : '0' + day;

        return today.getFullYear() + '-' + monthFormat + '-' + dayFormat ;
    }

    return (
        <Container fluid className="bg-white px-4 pt-4 sh-text-font_primary">
            <SubMenuTitle title={'타사업부 주관'} parentMenu={'현장 안전점검'}/>
            <SiteSftyChkRgstModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} prcsGb={prcsGb}
                                 detailData={detailData} sprvDprtGb={'C006002'}/>
            <SiteSftyChkRsltModal setModalIsOpen={setRsltModalIsOpen} modalIsOpen={rsltModalIsOpen}
                                  detailData={detailData}/>
                <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                    <SHInput type={'hidden'} name={'sprvDprtGb'} value={'C006002'}></SHInput>
                    <Row>
                        <Col>
                            <Table className="sh-font-SP sh-fs-b2 my-3">
                                <tbody>
                                <tr className="border-top align-middle">
                                    <th className="sh-bg-th"  style={{width:'6%'}}>공종</th>
                                    <td style={{width:'19%'}}>
                                        <SHSelect initOptionGb={'all'} className={'form-select'} name={'workTypeCd'} codeGb={'cmmn'} codeGrpId={'T002'}
                                                  onChange={fn_setSearchData}
                                                  value={srchData?.workTypeCd}></SHSelect>
                                    </td>
                                    <th className="sh-bg-th" style={{width:'6%'}}>진행상황</th>
                                    <td style={{width:'19%'}}>
                                        <SHSelect initOptionGb={'all'} className={'form-select'} name={'sftyChkGbCd'} codeGb={'cmmn'} codeGrpId={'C003'}
                                                  onChange={fn_setSearchData}
                                                  value={srchData?.sftyChkGbCd}></SHSelect>
                                    </td>
                                    <th className="sh-bg-th" style={{width:'6%'}}>점검일시</th>
                                    <td style={{width:'35%'}}>
                                        <div className="d-flex align-items-center w-100" ref={prodRef}>
                                            <SHDatePicker2 setter={setStartDate} name={'startSftyChkDt'} defaultValue={startDate} onChange={fn_setSearchData} />
                                            <span>&nbsp;~&nbsp;</span>
                                            <SHDatePicker2 setter={setEndDate} name={'endSftyChkDt'} defaultValue={endDate} onChange={fn_setSearchData} />
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </SHSearch>
            {user.authCode != 'headRead' &&
            <div className='w-100 d-flex justify-content-end align-items-center mb-2'>

                <SHIconButton kind={'submit'} marginRight={'5px'} onClick={fn_registPopOpen}> 결과등록
                </SHIconButton>

                <SHIconButton kind={'delete'} marginRight={'0'} onClick={fn_delete}> 삭제
                </SHIconButton>
            </div>
            }

            <div className={'ge-result'}>
                <Datatables
                    dataUrl={'/admin/api/siteSftyManage/retrieveSiteSftyManageDataList'}
                    parameterFormRef={searchFormRef}
                    columns={[
                        {data: "workTypeNm", title: "공종"},
                        {data: "sftyChkDt", title: "점검일시", render: (data: any, type: any, row: any) => {
                                return row.sftyChkDt + "&nbsp;&nbsp;" + row.sftyChkHour + ':' + row.sftyChkMin;
                            }},
                        {data: "siteName", title: "점검현장"},
                        {data: "siteAddress", title: "현장주소"},
                        {data: "dprtNm", title: "점검부서"},
                        {data: "sftyChkGbNm", title: "진행상황"},
                        {data: '', title: '점검결과등록', render: (data: any, type: any, row: any) => {
                                if(row.sftyChkGbNm == '현장조치중'){
                                    return "<div class='sh-box-status approvaldone'><button>확인</button></div>";
                                }else if(row.sftyChkGbNm == '결재요청 대기중'){
                                    return "<div class='sh-box-status request'><button>결재요청</button></div>";
                                }else if(row.sftyChkGbNm == '결재중'){
                                    return "<div class='sh-box-status approval'><button>결재</button></div>";
                                }else if(row.sftyChkGbNm == '최종확인 대기중'){
                                    return "<div class='sh-box-status approval'><button>최종확인</button></div>";
                                }
                                else{
                                    return "<div class='sh-box-status approvaldone'><button>확인</button></div>";
                                }

                            }},
                    ]}
                    checked={true}
                    index={true}

                    onLoad={(dt: any) => {
                        setDatatable(dt)
                    }}
                    onClick={(data, gb) => {
                       /* if(gb == 'btn'){
                            console.log('btn data: ', data);
                            setPrcsGb('rsltRgst');
                        }else{
                            setPrcsGb(null);
                        }*/

                        if(data.sftyChkGbNm == '현장조치중') {
                            setPrcsGb('actRgst');
                        } else {
                            setPrcsGb('rsltRgst');
                        }

                        setDetailData(data)
                        setModalIsOpen(true);
                        //점검예정일 경우 수정팝업 호출


                       /* if(data.sftyChkGbCd == "C003002") {
                            setDetailData(data)
                            setModalIsOpen(true);
                            fn_updateViewCnt(data);
                        } else { // 점검중 또는 점검완료일 경우 점검결과 팝업 호출
                            setDetailData(data)
                            setRsltModalIsOpen(true);
                            fn_updateViewCnt(data);
                        }*/
                    }
                    }
                />
            </div>
        </Container>
    );
};

export default SiteSftyChkBsnsPage;
