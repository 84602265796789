import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {COMMON_MODAL_STYLE} from "../../../helper/StyleHelper";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import SHSelect from "../../../components/common/SHSelect";
import {getLoginUser} from "../../../helper/SecurityHelper";
import SHSearch from "../../component/SHSearch";
import SHInput from "../../../components/common/SHInput";
import restapi from "../../../helper/restAPIHelper";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;

}

const $ = require("jquery")

const DngrTrfcLgndModal = (props: IProps) => {
    function fn_closeModal() {
        props.setModalIsOpen(false);
    }


    const fn_onShow = () => {

    }

    return (
        <div>

            <Modal
                className="sh-modal sub" size="lg"
                show={props.modalIsOpen}
                onShow={() => {
                    fn_onShow()

                }}
                onHide={fn_closeModal}
            >


                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        {'범례 설명'}
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">
                    <Row>
                        <Col bsPrefix="col-4" className="d-flex align-items-start">
                            <div>
                                <span className="pe-2" style={{color: '#12E573'}}>●</span>
                                <span>안전작업중</span>
                            </div>
                        </Col>
                        <Col bsPrefix="col-8" className="d-flex align-items-start">
                            <span>현장에서 작업중지요청 또는 sos응급 호출이 없는 상태</span>
                        </Col>
                    </Row>
                    <Row className={'my-4'}>
                        <Col bsPrefix="col-4" className="d-flex align-items-start">

                            <div>
                                <span className="pe-2" style={{color: '#FF8F51'}}>●</span>
                                <span>작업중지요청 발생</span>
                            </div>

                        </Col>
                        <Col bsPrefix="col-8" className="d-flex align-items-start">
                            <span>현장에서 작업중지요청이 발생한 상태</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col bsPrefix="col-4" className="d-flex align-items-start">

                            <div>
                                <span className="pe-2" style={{color: '#FF5656'}}>●</span>
                                <span>SOS응급호출 발생</span>
                            </div>
                        </Col>
                        <Col bsPrefix="col-8" className="d-flex align-items-start">
                            <span>현장에서 SOS응급호출이 발생한 상태</span>
                        </Col>
                    </Row>
                    <Row className={'mt-5'}>
                        <Col bsPrefix="col-12" className="d-flex align-items-start">
                            <span className={'fw-bolder'}>* 모니터링의 데이터는 실시간 데이터 이며, 금일 기준에 한하여 데이터를 제공합니다. <br/> (금일 기준: 00시 ~ 23시 59분 59초)</span>
                        </Col>
                    </Row>


                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                    <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>

                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default DngrTrfcLgndModal;
