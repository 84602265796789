import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";

import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {fn_getCurrentDate} from "../../../helper/CmmnHelper";
import {getLoginUser} from "../../../helper/SecurityHelper";

import axios from "axios";
import {toast} from "react-toastify";
import errorCodeDic from "../../../util/errorCodeDic";
import restapi from "../../../helper/restAPIHelper";
import SHFile from "../../../components/common/SHFile";
import {ProgressContext} from "../../../context/Progress";
import {UserContext} from "../../../context/User";



interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
}

const InitRskEvalModal = (props: IProps) => {

    const [fileList, setFileList] = useState([]);
    const formRef = React.createRef<HTMLFormElement>();
    const defaultUser = getLoginUser();
    const {user} = useContext<any>(UserContext);
    const {spinner} = useContext(ProgressContext);
    const siteId = user?.blngSiteId || defaultUser.blngSiteId
    const [data, setData] = useState<any>({siteId: siteId})


    function afterOpenModal() {
        spinner.start();
        axios.post('/admin/api/rskAsmnt/retrieveRiskAsmntInitData', data).then((dtlData: any) => {
            if(dtlData.data){
                setData(dtlData.data);
                restapi.retrieveAttachInfoList({attachSeq: dtlData.data.atchFileId}).then((rsltData: any) => {
                    setFileList(rsltData);
                })
            }

        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });
    }

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_registData = () => {

        if(fileList.length == 0){
            toast.warn('파일을 업로드해 주십시오.');
            return;
        }

        if(!window.confirm('저장 하시겠습니까?')){
            return;
        }

        // @ts-ignore
        var formData: any = new FormData(formRef.current)

        if(fileList.length > 0){
            for(var i =0;i<fileList.length;i++){
                formData.append('atchFileList',fileList[i]);
            }
        }
        let param = data;
        param.rgstrSeq = defaultUser.userSeq;
        param.updtrSeq = defaultUser.userSeq;

        formData.set('dtlData', new Blob([JSON.stringify(param)], {type: "application/json"}))

        spinner.start();
        axios.post('/admin/api/rskAsmnt/saveRiskAsmntInitData', formData).then((rsltData: any) => {
            if(rsltData.data > 0){
                toast.success('저장 되었습니다.');
                props.setModalIsOpen(false);
            }else{
                toast.error(errorCodeDic['003']);
            }

        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });
    }


    return (
        <div>

            <Modal
                className="sh-modal" size="sm"
                show={props.modalIsOpen}
                onShow={afterOpenModal}
                onHide={fn_closeModal}

            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        {'최초 위험성 평가'}
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">
                    <form name={'form'} ref={formRef} encType="multipart/form-data" method={"post"}>
                    <SHFile maxFileCnt={1} fileName={'initRskEvlFileList'} fileList={fileList} setFileList={setFileList}></SHFile>
                    </form>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <button
                            className='sh-btn pill p' onClick={fn_registData}>저장
                        </button>
                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default InitRskEvalModal;

