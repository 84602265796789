import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {COMMON_MODAL_STYLE} from "../../../helper/StyleHelper";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import SHSelect from "../../../components/common/SHSelect";
import {getLoginUser} from "../../../helper/SecurityHelper";
import SHSearch from "../../component/SHSearch";
import SHInput from "../../../components/common/SHInput";
import restapi from "../../../helper/restAPIHelper";
import SiteSftyChkRgstModal from "../../safetyWorkReportManagement/modal/SiteSftyChkRgstModal";
import SiteManageRgstModal from "../../headManage/modal/SiteManageRgstModal";
import {SiteContext} from "../../../context/Site";
import IncgrtyPicModal from "../../siteSafetyManagement/modal/IncgrtyPicModal";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    dataList: object[];

}

const $ = require("jquery")

const WrkStopInfoListModal = (props: IProps) => {
    const [datatable, setDatatable] = useState<any>();

    const [isCodeDataLoaded, setIsCodeDataLoaded] = useState<any>(null);
    const searchFormRef = useRef<any>();
    const [dataList, setDataList] = useState<any>([]);
    const [orgnDataList, setOrgnDataList] = useState<any>([]);
    const {setSite} = useContext<any>(SiteContext);
    const [isPicModalOpen, setIsPicModalOpen] = useState<boolean>(false);
    const [picDtlData, setPicDtlData] = useState<any>({});


    useEffect(() => {
        if (isCodeDataLoaded) {
            fn_search();
        }
    }, [isCodeDataLoaded]);

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_search = (e?: any) => {
        e?.preventDefault()
        datatable?.draw()
    }

    const fn_onShow = () => {

        setDataList(props.dataList);

        setOrgnDataList(props.dataList);
    }


    return (
        <div>
            <IncgrtyPicModal detailData={picDtlData} setModalIsOpen={setIsPicModalOpen} modalIsOpen={isPicModalOpen} />
            <Modal
                className="sh-modal" size="lg"
                show={props.modalIsOpen}
                onShow={() => {
                    fn_onShow()

                }}
                onHide={fn_closeModal}
            >


                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        작업중지 요청 목록
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">

                    <div className={'ge-result'}>
                        <Datatables
                            datas={dataList}
                            scrollY="550px"
                            className="sh-fs-b4"
                            parameterFormRef={searchFormRef}
                            columns={[
                                {data: "siteName", title: "현장명", textAlign: 'center'},
                                {data: "rgstDt", title: "요청일", textAlign: 'center'},
                                {data: "rgstTime", title: "요청시간", textAlign: 'center'},
                                {data: "comments", title: "사유", textAlign: 'center'},
                                {data: "userName", title: "성명", textAlign: 'center'},
                                {data: "userPhone", title: "전화번호", textAlign: 'center'},
                                {data: "companyName", title: "업체", textAlign: 'center'},
                                {data: "workTypeNm", title: "공종", textAlign: 'center'},
                                {data: "ocptNm", title: "직종", textAlign: 'center'},
                                {data: 'atchFileId', title: '사진', type:"button", buttons: [{
                                        label: "<img style='height: 20px' class='cursor-pointer' src='/icon_picture.svg' />",
                                        onClick: (v: any) => {
                                            if(v.atchFileId){
                                                setPicDtlData({title: '사진', fileId: v.atchFileId})
                                                setIsPicModalOpen(true);

                                            }else{
                                                toast.warn('등록된 사진이 없습니다');
                                            }
                                        },
                                    }]}
                            ]}

                            checked={false}
                            onLoad={(dt: any) => {
                                setDatatable(dt)
                            }}

                            onClick={(data, gb) => {

                                setSite(data);
                                props.setModalIsOpen(false);
                            }
                            }
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>

                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default WrkStopInfoListModal;
