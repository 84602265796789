import React, {useContext, useEffect, useRef, useState} from 'react';

import SHSearch from '../component/SHSearch';
import SubMenuTitle from "../component/SubMenuTitle";
import {Card, Col, Container, Row, Table} from "react-bootstrap";

import SHSelect from "../../components/common/SHSelect";
import axios from "axios";
import SHInput from "../../components/common/SHInput";
import {TopMenuContext} from "../../context/TopMenu";
import ImageLoader from "../../components/ImageLoader";


type Props = {};

const CctvHeadManagePage = (props: Props) => {
    const {param} = useContext<any>(TopMenuContext);
    const searchFormRef = useRef<any>();
    const [guCd, setGuCd] = useState<string>('');
    const [siteId, setSiteId] = useState<string>('');
    const [query, setQuery] = useState<string>('');
    const [result, setResult] = useState<any>([]);
    const [select, setSelect] = useState<any>({});
    useEffect(() => {
        if(param && param.siteId){
            setSiteId(param.siteId)
            fn_search(guCd, param.siteId,query)
        }else{
            fn_search(guCd, siteId,query)
        }
    }, [])
    function openModal(data: any){
        var url = process.env.REACT_APP_CCTV_VIEWER_URL + "?url=" + encodeURIComponent(data.rtspUrl)
        // @ts-ignore
        if(window.___cctv_popup) {
            // @ts-ignore
            window.___cctv_popup.close()
        }
        // @ts-ignore
        window.___cctv_popup = window.open(url,  "_blank","width=658,height=445,top=100,left=100");
        // @ts-ignore
        window.___cctv_popup.focus()
    }
    function fn_search(guCd: any, siteId: any, query: any){
        axios.post('/admin/api/cctv/retrieveCctvList', {guCd:guCd, siteId:siteId, query: query})
            .then(r => {
                if(r.status === 200){
                    setResult(r.data)
                }
            })
    }
    return (
    <Container fluid className="bg-white px-4 pt-4 sh-text-font_primary">
        <SubMenuTitle title={'CCTV 관리'} parentMenu={'CCTV 관리'} />
        <SHSearch formRef={searchFormRef} btnType={'p'} onSearch={() => fn_search(guCd, siteId,query)}>
            <Row>
                <Col>
                    <Table className="sh-font-SP sh-fs-b2 my-3">
                        <colgroup>
                            <col width="10%" />
                            <col width="20%" />
                            <col width="10%" />
                            <col width="20%" />
                            <col width="10%" />
                            <col/>
                        </colgroup>
                        <tbody>
                        <tr className="border-top border-bottom align-middle">
                            <th className="sh-bg-th">지역</th>
                            <td>
                                <SHSelect initOptionGb={'all'} className={'form-select'} name={'guCd'} codeGb={'bjd'} codeGrpId={'SEOUL'} value={guCd} onChange={e=> setGuCd(e.target.value)}></SHSelect>
                            </td>
                            <th className="sh-bg-th">현장</th>
                            <td>
                                <SHSelect initOptionGb={'all'} className={'form-control'} name={'siteId'} codeGb={'site'} value={siteId} onChange={e=> setSiteId(e.target.value)} selParam={{guCd:guCd}} ></SHSelect>
                            </td>
                            <th className="sh-bg-th">검색어</th>
                            <td>
                                <SHInput type="text" className="form-control" name={'query'} value={query} onChange={e=> setQuery(e.target.value)}/>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </SHSearch>
        <hr/>
        <div className={'ge-result mt-3'}>
            <Container fluid={true} className={"px-0"}>
                <Row>
                    {result.length === 0 ?
                        <Col className={"text-center fs-3 mt-5"}>등록된 CCTV가 존재하지 않습니다.</Col>
                        :
                        result.map((data: any, idx: number) => (
                            <Col key={idx} className={"col-sm-6 col-md-4 col-lg-3 mb-4"} onClick={() => openModal(data)}>
                                <Card>
                                    <ImageLoader className="card-img-top"
                                                 src={process.env.REACT_APP_CCTV_IMAGE_URL + "?url=" + encodeURIComponent(data.rtspUrl)} />
                                    <div className="card-body">
                                        <h5 className="card-title">{data.siteName}</h5>
                                        <p className="card-text mb-2">{data.name} / {data.location}</p>
                                        <div className={"text-right"}>
                                            <a href="#" className="btn btn-sm btn-secondary">확인</a>
                                        </div>
                                    </div>
                                </Card>
                            </Col>))
                    }
                </Row>
            </Container>
        </div>
    </Container>
    );
};

export default CctvHeadManagePage;
