import React, {useContext, useEffect, useRef, useState} from 'react';
import Datatables from "../component/Datatables";
import Modal from 'react-modal';
import {getLoginUser} from "../../helper/SecurityHelper";
import {ProgressContext} from "../../context/Progress";
import SHSearch from '../component/SHSearch';
import {Col, Container, Row, Table} from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";
import SHInput from "../../components/common/SHInput";
import DprtDtlStatModal from "./modal/DprtDtlStatModal";
import {UserContext} from "../../context/User";
import SHDatePicker2 from "../../components/common/SHDatePicker2";
import {DateHelper} from "../../helper/DateHelper";

type Props = {};

Modal.setAppElement('#root');
const DepartureStatusManagementPage = (props: Props) => {
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const defaultUser = getLoginUser();
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const siteIdRef = useRef<any>();
    const [data, setData] = useState<any>()
    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);
    const prodRef = useRef<any>();

    const {spinner} = useContext(ProgressContext);

    const [strtDate, setStrtDate] = useState<Date>(DateHelper.dateByMonth(-3));
    const [endDate, setEndDate] = useState<Date>(DateHelper.dateByMonth(0));
    const [wrkDt, setWrkDt] = useState<string>('');
    const {user} = useContext<any>(UserContext);


    useEffect(()=>{

        if(datatable){
            datatable.draw();
        }

    }, [user, datatable])

    useEffect(() => {

        datatable?.draw();

    }, [modalIsOpen])


    const fn_setFormData = (e: any) => {

        setData({
            ...data,
            [e.target.name]: e.target.value
        })

    }

    const fn_datePopupOpen = () => {
        setIsDatePopupOpen(!isDatePopupOpen);
    };


    return (
        <Container fluid className="bg-white px-4 pt-4 sh-text-font_primary">
            <SubMenuTitle title={'출역 현황 관리'} parentMenu={'인력 관리'}/>
            <DprtDtlStatModal qrNfcTpCd={'T003001'} siteId={user?.blngSiteId || defaultUser.blngSiteId} wrkDt={wrkDt} modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen}></DprtDtlStatModal>
                <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                    <Row>
                        <Col>
                            <Table className="sh-font-SP sh-fs-b2 my-3">
                                <tbody>
                                <tr className="border-top border-bottom align-middle">

                                    <th className="sh-bg-th">기간</th>
                                    <td>
                                        <SHInput type={'hidden'} name={'qrNfcTpCd'} value={'T003001'}></SHInput>
                                        <SHInput type={'hidden'} name={'siteId'} value={user?.blngSiteId || defaultUser.blngSiteId}></SHInput>
                                        <div className="d-flex align-items-center w-100" ref={prodRef}>
                                            <SHDatePicker2 setter={setStrtDate} name={'strtDate'} defaultValue={strtDate} onChange={fn_setFormData} />
                                            <span>&nbsp;~&nbsp;</span>
                                            <SHDatePicker2 setter={setEndDate} name={'endDate'} defaultValue={endDate} onChange={fn_setFormData} />
                                        </div>


                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </SHSearch>


            <div className={'ge-result'}>
                <Datatables
                    dataUrl={'/admin/api/qrnfc/retrieveDprtStatDataList'}
                    parameterFormRef={searchFormRef}
                    columns={[
                        {data: "useDt", title: "일자", textAlign: 'center'},
                        {data: "mngrCnt", title: "관리자", textAlign: 'center'},
                        {data: "wrkrCnt", title: "근로자", textAlign: 'center'},
                        {
                            data: "", title: "개인보호구 미착용", textAlign: 'center', render: (data: any, type: any, row: any) => {
                                return row.noCapCnt;
                            }
                        },
                        {data: "totCnt", title: "합계", textAlign: 'center'},

                    ]}
                    checked={false}
                    index={true}

                    onLoad={(dt: any) => {

                        setDatatable(dt)
                    }}
                    onClick={(data) => {
                        setWrkDt(data.useDt);
                        setModalIsOpen(true);
                    }
                    }
                />
            </div>
        </Container>


    );
};

export default DepartureStatusManagementPage;
