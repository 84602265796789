import React, {useContext, useEffect, useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import {ReactComponent as IconRight} from "../../assets/images/ico/icon-right.svg";
import {UserContext} from "../../context/User";
import restapi from "../../helper/restAPIHelper";
import {getLoginUser} from "../../helper/SecurityHelper";
import {CmmnFn} from "../../helper/CmmnHelper";
import WrkPrmtInfoListModal from "./modal/WrkPrmtInfoListModal";
import {useNavigate} from "react-router-dom";

const DashWorkPermit = () => {
    const {user} = useContext<any>(UserContext);
    const defaultUser = getLoginUser();
    const [wrkStatCnt, setWrkStatCnt] = useState<any>({wrkWaitCnt:0, wrkIngCnt:0, wrkCmpltdCnt: 0});
    const [isWrkPrmtInfoModalOpen, setIsWrkPrmtInfoModalOpen] = useState<boolean>(false);
    const [wrkStatCd, setWrkStatCd] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        const siteId = user?.blngSiteId || defaultUser.blngSiteId;
        const wrkDt = CmmnFn.fn_getCurrentDate();
        let wrkWaitCnt = 0;
        let wrkIngCnt = 0;
        let wrkCmpltdCnt = 0;
        restapi.retrieveWrkPrmtDataTotList({prcsStatCd:'S001004', siteId: siteId, wrkDt:wrkDt}).then((rsltData: any) => {
            rsltData.map((data: any) => {
                if (data.totWrkrCnt == data.tbmCmpltCnt && data.totWrkrCnt > 0) {
                    if(data.isWrkCmpltd) {
                        wrkCmpltdCnt++;
                    }else{
                        wrkIngCnt++;
                    }

                } else {
                    return wrkWaitCnt++;
                }
            })

            const cntData = {wrkCmpltdCnt:wrkCmpltdCnt, wrkIngCnt:wrkIngCnt, wrkWaitCnt:wrkWaitCnt};

            setWrkStatCnt(cntData);
        })
    }, [user])

    const fn_openWrkPrmtInfoModal = (wrkStat: string) => {
        setWrkStatCd(wrkStat);
        setIsWrkPrmtInfoModalOpen(true);

    }

    return(
        <>
            <Container fluid className="sh-bg-pane rounded-[5px] py-2 px-3">
                <WrkPrmtInfoListModal wrkStatCd={wrkStatCd} setModalIsOpen={setIsWrkPrmtInfoModalOpen} modalIsOpen={isWrkPrmtInfoModalOpen} siteId={user?.blngSiteId || defaultUser.blngSiteId} />
                <Row className="px-0 mb-2">
                    <Col bsPrefix="col-7" className="d-flex align-items-center w-100">
                        <div className="w-100 d-flex align-items-center justify-content-between">
                            <span className="pe-2 sh-fs-h4 sh-fw-5 sh-font-SD">작업허가서</span>
                            <div className={'cursor-pointer'} onClick={() => navigate('/siteSafetyManagement/WorkPermitManagement')}>
                            <IconRight style={{width:'20px',marginBottom:'1px'}} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="px-0 mb-1">
                    <Col>
                        <div onClick={() => fn_openWrkPrmtInfoModal('S004002')} className="cursor-pointer rounded-3 px-2 py-1 sh-bg-secondary03 sh-fw-5 sh-fs-b3">
                            <div>작업대기</div>
                            <div className="text-end">
                                <span className="sh-fs-h2 sh-fw-5 sh-lh-h4">{wrkStatCnt.wrkWaitCnt}</span>
                                <span className="sh-fs-h3 sh-fw-4">건</span>
                            </div>
                        </div>
                    </Col>
                    <Col className="ps-0 pe-0">
                        <div onClick={() => fn_openWrkPrmtInfoModal('S004003')} className="cursor-pointer rounded-3 px-2 py-1 sh-bg-secondary01 sh-fw-5 sh-fs-b3">
                            <div>작업중</div>
                            <div className="text-end">
                                <span className="sh-fs-h2 sh-fw-5 sh-lh-h4">{wrkStatCnt.wrkIngCnt}</span>
                                <span className="sh-fs-h3 sh-fw-4">건</span>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div onClick={() => fn_openWrkPrmtInfoModal('S004004')} className="cursor-pointer rounded-3 px-2 py-1 sh-bg-secondary02 sh-fw-5 sh-fs-b3">
                            <div>작업완료</div>
                            <div className="text-end">
                                <span className="sh-fs-h2 sh-fw-5 sh-lh-h4">{wrkStatCnt.wrkCmpltdCnt}</span>
                                <span className="sh-fs-h3 sh-fw-4">건</span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default DashWorkPermit;

