import React, {useContext, useEffect, useRef, useState} from 'react';

import Modal from 'react-modal';
import {getLoginUser} from "../../helper/SecurityHelper";
import {toast} from "react-toastify";
import errorCodeDic from "../../util/errorCodeDic";
import {ProgressContext} from "../../context/Progress";
import restapi from "../../helper/restAPIHelper";
import {Col, Container, Row, Tab, Table, Tabs} from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";
import {CmmnFn} from "../../helper/CmmnHelper";
import WrkrInfoListModal from "../home/modal/WrkrInfoListModal";
import EduStatPage from "../safetyNewspaper/subPage/EduStatPage";
import AsscWrkrInfoPage from "./subPage/AsscWrkrInfoPage";
import AsscWrkrInOutInfoPage from "./subPage/AsscWrkrInOutInfoPage";
import ecardApi from "../../helper/ecardAPIHelper";
import AsscWrkrInOutInfoApiPage from "./subPageApi/AsscWrkrInOutInfoApiPage";
import AsscWrkrInfoApiPage from "./subPageApi/AsscWrkrInfoApiPage";

type Props = {};

Modal.setAppElement('#root');
const CnstrAsscPage = (props: Props) => {

    const user = getLoginUser();
    const {spinner} = useContext(ProgressContext);
    const [dataList, setDataList] = useState<any>([]);
    const [srchData, setSrchData] = useState<any>({tabGb: 'wrkrInfo', isCnstrAssc: 'Y', qrNfcTpCd: 'T003001'});
    const [statData, setStatData] = useState<any>({totSiteCnt: 0, noSiteCnt: 0, totWrkrCnt: 0, noWrkrCnt: 0});
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [wrkrDataList, setWrkrDataList] = useState<any>([]);
    const [selData, setSelData] = useState<any>(null);
    const [wrkDt, setWrkDt] = useState<string>(CmmnFn.fn_getCurrentDate());
    const [isDatePopupOpen, setIsDatePopupOpen] = useState<boolean>(false);


    useEffect(() => {
        fn_search();
    }, [srchData])

    useEffect(() => {
        setSrchData({
            ...srchData,
            'wrkDt': wrkDt
        })
    }, [wrkDt]);


    const fn_search = () => {
        spinner.start();
        console.log('srchData: ', srchData);


        if(srchData.tabGb == 'wrkrInfo'){
            console.log('wrkinfo srchData: ', srchData);
            restapi.retrieveEmployeeDataListNoPaging(srchData).then((rsltData: any) => {
                console.log('wrkinfo rsltData: ', rsltData);
                setDataList(rsltData);
            }).catch(error => {
                toast.error(errorCodeDic['003']);
                console.log('error: ', error);
            })
                .finally(() => {
                    spinner.stop();
                });
        }else if(srchData.tabGb == 'wrkrIOInfo'){
            restapi.retrieveDprtDtlStatDataList(srchData).then((rsltData: any) => {
                setDataList(rsltData);
            }).catch(error => {
                toast.error(errorCodeDic['003']);
                console.log('error: ', error);
            })
                .finally(() => {
                    spinner.stop();
                });
        }

    }

    return (
        <Container fluid className="bg-white px-4 pt-4 sh-text-font_primary">
            <WrkrInfoListModal setModalIsOpen={setModalIsOpen} wrkDt={wrkDt.replace(/-/gi, '')} modalIsOpen={modalIsOpen} wrkrDataList={wrkrDataList} siteId={selData?.siteId} title={selData?.siteName} tabGb={srchData.tabGb} />
            <SubMenuTitle title={'건설근로자 공제회 정보'} parentMenu={'현장 정보 관리'}/>
            <Tabs
                transition={true}
                activeKey={srchData.tabGb}
                onSelect={(k) => setSrchData({
                    ...srchData,
                    'tabGb': k
                })}
                id="tabGb"
                className="mb-3"
            >
            <Tab eventKey="wrkrInfo" title="근로자 정보">
                <AsscWrkrInfoApiPage statData={statData} dataList={dataList} srchData={srchData} setSelData={setSelData} setSrchData={setSrchData} isDatePopupOpen={isDatePopupOpen} setIsDatePopupOpen={setIsDatePopupOpen} setWrkDt={setWrkDt} wrkDt={wrkDt}/>
            </Tab>
            <Tab eventKey="wrkrIOInfo" title="근로자 출역정보">
                <AsscWrkrInOutInfoApiPage statData={statData} dataList={dataList} srchData={srchData} setSelData={setSelData} setSrchData={setSrchData} isDatePopupOpen={isDatePopupOpen} setIsDatePopupOpen={setIsDatePopupOpen} setWrkDt={setWrkDt} wrkDt={wrkDt}/>
            </Tab>
            </Tabs>
        </Container>


    );



};

export default CnstrAsscPage;
