import React, {useEffect, useRef, useState} from "react"
import uuid from "react-uuid"
const $ = require("jquery")
$.DataTable = require("datatables.net")

export interface IProps {
    columns: any[]
    dataUrl?: string,
    parameterFormRef?: any
    datas?: any[]
    scrollY?: string
    scrollYCutHeight?: number
    color?: string
    borderColor?: string
    background?: string
    height?: string
    width?: string
    isSimpleAtMobile?: boolean
    margin?: string
    padding?: string
    checked?: boolean
    isNotCheckedAll?: boolean
    checkedAllLabel?: string
    index?: boolean
    className?: string
    obj?: any
    onAllCheckedClick?: (data: any, gb?: any) => any
    onClick?: (data: any, gb?: any, evt?: any) => any
    onLoad?: (dataTables: any) => any
    isSingleCheck?: boolean
    isNotPaging?: boolean
    isNotOrdering?: boolean
    children?: any
    checkedDataList?: any
    pk?: any
    fixedTop?: string
    isHideTotCnt?: boolean
}
export function getCheckedItems(dataTable: any, targetColumn?: string) {
    const datas = dataTable.data()
    const checkList = []
    for (let i = 0; i < datas.length; i++) {
        const data = datas[i]
        if (data._checked === true) {
            if(targetColumn){
                checkList.push(data[targetColumn])
            }else{
                checkList.push(data)
            }
        }
    }

    return checkList
}
export function uncheckedAll(dataTable: any) {
    const datas = dataTable.data()
    for (let i = 0; i < datas.length; i++) {
        const data = datas[i]
        data._checked = false
    }
    const body = dataTable.table().container()
    $(body).find("[data-ga-checkall='true']").prop("checked", null)
    $(body).find("[data-ga-check='true']").prop("checked", null)
    //dataTable.draw()
}
export function checkItem(data: any, type: any, event: any) {
    if($(event.target).parents("tr").find('input[type=checkbox]').length > 0){
        $(event.target).parents("tr").find('input[type=checkbox]').trigger("click")
    }
}
export function removeCheckedItems(dataTable: any) {
    const datas = dataTable.data()
    for (let i = 0; i < datas.length; i++) {
        const data = datas[i]
        if (data._checked === true) {
            dataTable.row(data.idx).remove()
        }
    }
    dataTable.draw()
}

export default function Datatables(props: IProps) {
    const { ...param } = props
    const dataTableRef = useRef<HTMLTableElement | null>(null)
    const [dataTable, setDataTable] = useState<any>(null)
    const [parameterForm, setParameterForm] = useState<HTMLFormElement>()
    const [datas, setDatas] = useState<any[] | undefined>([])
    const [columns, setColumns] = useState<any[]>([])
    const [first, setFirst] = useState<boolean>(true)


    useEffect(() => {
        //dataTable.draw()
        setFirst(true)
        setDatas(props.datas)
        setColumns(props.columns)
        setParameterForm(props.parameterFormRef?.current)
    }, [props.datas])
    useEffect(() => {
        if (columns.length === 0) {
            return
        }
        const buttons: any = []
        const columnDef = []
        const _columns = Object.assign([], columns)
        const fixedColumns = {
            left: 0,
            right: 0,
        }


        if (props.checked) {
            fixedColumns.left = 1
            console.log(fixedColumns);
            if(!props.isNotCheckedAll){
                if (props.checkedAllLabel) {
                    _columns.unshift({
                        data: "_checked",
                        title:
                            "<input type='checkbox' class='form-check-input sh-check' data-ga-checkall='true'  style='vertical-align: middle'>" +
                            "&nbsp;<span style='vertical-align: middle'>" +
                            props.checkedAllLabel +
                            "</span>",
                    })
                } else {
                    _columns.unshift({
                        data: "_checked",
                        title: props.isSingleCheck ? '선택' : "<input type='checkbox' id='datatalbes_chkAll' class='form-check-input sh-check' data-ga-checkall='true'>",
                    })
                }
            }else{
                _columns.unshift({
                    data: "_checked",
                    title: '선택',
                })
            }

            columnDef.push({
                targets: 0,
                className: "text-center",
                orderable: false,
                render: function (data: any, type: any, row: any, meta: any) {

                    let rtrnTxt = "<input type='checkbox' class='form-check-input sh-check' data-ga-check='true' data-row-num='" +
                        meta.row +
                        "' " ;
                    if(props.pk && props.checkedDataList){
                        rtrnTxt += (props.checkedDataList.includes(row[props.pk]) ? "checked='checked'" : "");
                    }else{
                        rtrnTxt += (data === true ? "checked='checked'" : "")
                    }
                    rtrnTxt += ">";
                    return rtrnTxt;
                },
            })
        }

        if (props.index) {
            fixedColumns.left = fixedColumns.left + 1
            if (props.checked) {
                _columns.splice(1, 0, {
                    data: "_index",
                    title: "No",
                })
            } else {
                _columns.unshift({
                    data: "_index",
                    title: "No",
                })
            }
            columnDef.push({
                targets: fixedColumns.left - 1,
                className: "text-center",
                orderable: false,
                render: function (data: any, type: any, row: any, meta: any) {
                    return meta.row + 1
                },
            })
        }
        var orderIndex = null
        for (const i in _columns) {
            const info: any = _columns[i]
            if (info.type === "button") {
                // @ts-ignore
                info.render = function (data: any, type: any, row: any) {
                    let html = ""
                    if(!(info.buttons instanceof Array)){
                        info.buttons = [info.buttons]
                    }
                    for (const b in info.buttons) {
                        const btn = info.buttons[b]
                        const id = uuid()
                        html += "<button class='ji-cell-btn " + btn.className + "' data-ji-button-uuid='" + id + "'>" + btn.label + "</button>&nbsp;&nbsp;"
                        buttons.push({ uuid: id, click: btn.onClick })
                    }
                    return "<div style='white-space: nowrap'>" + html + "</div>"
                }
                continue
            }

            // @ts-ignore
            if (info.data === null) {
                columnDef.push({
                    targets: +i,
                    // @ts-ignore
                    className: info.className,
                    orderable: false,
                    render: function (data: any, type: any, row: any, meta: any) {
                        return ""
                    },
                })
            }


            // @ts-ignore
            if (info.data !== null && info.data !== "_checked" && info.data !== "_index") {
                if(info.orderBase === true){
                    orderIndex = +i
                }
                // @ts-ignore
                if (info.editable === true) {
                    // @ts-ignore
                    if (info.className === undefined) {
                        // @ts-ignore
                        info.className = ""
                    }
                    // @ts-ignore
                    info.className = info.className + " " + "ji-no-hover"
                    // @ts-ignore
                    if (info.items) {
                        // @ts-ignore
                        info.render = function (data: any, type: any, row: any, meta: any) {
                            let options = ""
                            // @ts-ignore
                            for (const j in info.items) {
                                // @ts-ignore
                                if (info.items[j].value == data) {
                                    options +=
                                        "<option value='" +
                                        // @ts-ignore
                                        info.items[j].value +
                                        "' selected='selected'>" +
                                        // @ts-ignore
                                        info.items[j].label +
                                        "</option>"
                                } else {
                                    options +=
                                        "<option value='" +
                                        // @ts-ignore
                                        info.items[j].value +
                                        "'>" +
                                        // @ts-ignore
                                        info.items[j].label +
                                        "</option>"
                                }
                            }
                            // @ts-ignore
                            const column = _columns[meta.col].data
                            return (
                                "<select type='text' class='ji-input02' value='" +
                                data +
                                "' data-row-num='" +
                                meta.row +
                                "' data-column='" +
                                column +
                                "'>" +
                                options +
                                "</select>"
                            )
                        }
                    } else {
                        // @ts-ignore
                        info.render = function (data: any, type: any, row: any, meta: any) {
                            if (data === null) {
                                data = ""
                            }
                            // @ts-ignore
                            const column = _columns[meta.col].data
                            return (
                                "<input type='text' class='ji-input02' value='" +
                                data +
                                "' data-row-num='" +
                                meta.row +
                                "' data-column='" +
                                column +
                                "'/>"
                            )
                        }
                    }
                } else {
                    if(info.render) {
                        // nothing
                    } else {
                        // @ts-ignore
                        info.render = function (data: any, type: any, row: any) {
                            if (data !== undefined && data !== null && data !== '') {
                                if(info.format){
                                    if(info.format = 'withCom'){
                                        data = data.toLocaleString();
                                    }
                                }
                                if(info.maxWidth){
                                    return "<span style='max-width:"+info.maxWidth+"px; display: block; text-overflow: ellipsis; overflow: hidden;'>" + data + "</span>"
                                }else{
                                    return "<span>" + data + "</span>"
                                }
                            } else {
                                return ""
                            }
                        }
                    }





                    if(info.textAlign){
                        if(info.textAlign == 'left'){
                            info.className = "text-left";
                        }else if(info.textAlign == 'right'){
                            info.className = "text-right";
                        }else if(info.textAlign == 'center'){
                            info.className = "text-center";
                        }
                    }else{
                        info.className = "text-center";
                    }


                }
            }
        }
        let scrollY = param.scrollY ? param.scrollY : null
        if (param.scrollYCutHeight) {
            scrollY = "calc(100vh - " + param.scrollYCutHeight + "px)"
        }

        let paging = true;
        if(props.isNotPaging) {
            paging = false
        }else{
            paging = true
        }
        let ordering = true;
        if(props.isNotOrdering) {
            ordering = false
        }else{
            ordering = true;
        }
        var option = {}
        if(param.dataUrl){
            // @ts-ignore
            option.serverSide = true
            // @ts-ignore
            option.ajax = {
                url: param.dataUrl,
                type: "POST",
                contentType: "application/json",
                data: function (d: any) {
                    // @ts-ignore
                    window.document.getElementById("sh-out-loader-wrapper").style.display = "block"
                    var param = serializeObject(parameterForm)
                    // @ts-ignore
                    param.datatables = true
                    for (const i in _columns) {
                        const info: any = _columns[i]
                        if(info.orderColumn){
                            d.columns[i].orderColumn = info.orderColumn
                        }
                    }
                    return JSON.stringify($.extend(d, param))
                },
                complete : function(){
                    // @ts-ignore
                    window.document.getElementById("sh-out-loader-wrapper").style.display = "none"
                },
            }
        }else{
            // @ts-ignore
            option.data = param.datas
        }
        // @ts-ignore
        option.drawCallback = function(settings: any, json: any) {
            if (buttons.length > 0) {
                for (const b in buttons) {
                    const btn = buttons[b]
                    const elem = $(dataTableRef.current).find("[data-ji-button-uuid='" + btn.uuid + "']")
                    if (btn.click) {
                        elem.off("click").on("click", function (e: any) {
                            const data = dataTable.row($(e.target).parents("tr")).data()
                            btn.click(data)
                        })
                    }
                }
            }
        }
        //console.log(option)
        $.extend(option,{
            fixedColumns: fixedColumns,
            columns: _columns,
            columnDefs: columnDef,
            dom: "Bfritp",
            paging: paging,
            scrollX: true,
            scrollXInner: "100%",
            autoWidth: false,
            scrollY: scrollY,
            scrollCollapse: true,
            searching: false,
            select: true,
            language: {
                decimal: "",
                emptyTable: "데이터가 없습니다.",
                info:"",
                infoEmpty: "<span class='sh-fs-b2 sh-fw-5'>Total <span class='sh-text-accent'>0</span></span>",
                infoFiltered:"<span class='sh-fs-b2 sh-fw-5'>Total <span class='sh-text-accent'>_TOTAL_</span></span>",
                infoPostFix: "",
                thousands: ",",
                lengthMenu: "_MENU_개씩보기",
                loadingRecords: "로딩중...",
                processing: "처리중...",
                search: "검색:",
                zeroRecords: "검색된 데이터가 없습니다.",
                paginate: {
                    first: "<<",
                    last: ">>",
                    next: ">",
                    previous: "<",
                },
                aria: { sortAscending: ":오름차순 정렬", sortDescending: ":내림차순 정렬" },
            },
            order: orderIndex ? [[orderIndex, 'desc']] : [],
            ordering: ordering,
            destroy: true,
            createdRow: function( row: any, data: string[], dataIndex: any){
                if(props.fixedTop) {
                    // @ts-ignore
                    if (data[props.fixedTop] == 't'|| data[props.fixedTop] == true) {
                        $(row).addClass('fixedTop');
                    }
                }
            }
        })
        const dataTable = $(dataTableRef.current).DataTable(option)
        setDataTable(dataTable)

        //
        // @ts-ignore
        if (param.onClick) {
            $(dataTableRef.current)
                .off("click", "tr")
                .on("click", "tr", function (e: any) {
                    if (dataTable) {

                        // 체크박스를 포함한 td 클릭시 팝업 X
                        if($(e.target).find('input[type=checkbox]').length > 0){
                            return;
                        }
                        //console.log(1212, e.target.tagName)
                        if (e.target.tagName === "BUTTON") {
                            const data = dataTable.row($(e.target).parents("tr")).data()

                            if (data && param) {

                                // @ts-ignore
                                param.onClick(data, 'btn', e)
                            }
                            // exist button
                            return
                        } else if (e.target.tagName === "SPAN") {
                            const data = dataTable.row($(e.target).parents("tr")).data()
                            if (data && param) {
                                // @ts-ignore
                                param.onClick(data,null, e)
                            }
                        } else if(e.target.tagName === "INPUT") {
                            if(e.target.type === 'checkbox'){
                                let data = dataTable.row($(e.target).parents("tr")).data();
                                data.isChecked = $(e.target).is(':checked')
                                if(data && param && param.checkedDataList){
                                    // @ts-ignore
                                    param.onClick(data,null, e);
                                }
                            }
                        } else {
                            if (
                                $(e.target).parents("td").find("button").length > 0 ||
                                $(e.target).find("button").length > 0
                            ) {
                                // exist button
                                return
                            }
                            if (e.target.data) {
                                if (e.target.data.length > 0) {
                                    // @ts-ignore
                                    param.onClick(e.target.data[0],null, e)
                                }
                            } else {
                                const data = dataTable.row(e.target).data()
                                if (data && param) {
                                    // @ts-ignore
                                    param.onClick(data,null, e)
                                }
                            }
                        }
                    }
                }
                )
        }
        $(dataTableRef.current)
            .parents(".dataTables_wrapper")
            .on("change", "input[data-ga-checkall='true']", function (e: any) {
                if (dataTable) {
                    const self = e.target
                    const value = self.checked
                    const datas = dataTable.rows({ search: "applied" }).data()
                    var items = []
                    // @ts-ignore
                    for (let i = 0; i < datas.length; i++) {
                        const data = datas[i]
                        data._checked = value
                        data._index = i
                        items.push(data)
                    }
                    $(dataTableRef.current).find("[data-ga-check='true']").prop("checked", value)
                    if(param.onAllCheckedClick){
                        param.onAllCheckedClick(items, value);
                    }
                }
            })
            .on("change", "input[data-ga-check='true']", function (e: any){
                if (dataTable) {
                    if(props.isSingleCheck){
                        var datas = dataTable.data()

                        for(var i=0; i<datas.length; i++){
                            var data = datas[i]
                            data._checked = false
                            data.checked = false
                        }
                        const self = e.target
                        var cur = self.checked
                        $(dataTableRef.current).find('[data-ga-check="true"]').not(self).prop("checked",false)
                        var rownum = $(self).data("row-num")
                        var data = dataTable.data()[+rownum]
                        data._checked = cur
                    }else{
                        const self = e.target
                        const value = self.checked
                        const row = $(self).data("row-num")
                        const data = dataTable.data()[row]
                        data._checked = value
                        $(self).prop("checked", value)
                    }

                }
            })
            .on("change", "input[type='text']", function (e: any) {
                if (dataTable) {
                    const self = e.target
                    const value = self.value
                    const row = $(self).data("row-num")
                    const column = $(self).data("column")
                    const data = dataTable.data()[row]
                    data[column] = value
                }
            })
            .on("change", "select", function (e: any) {
                if (dataTable) {
                    const self = e.target
                    const value = self.value
                    const row = $(self).data("row-num")
                    const column = $(self).data("column")
                    const data = dataTable.data()[row]
                    data[column] = value
                }
            })
        // @ts-ignore
        if (param.onLoad) {
            param.onLoad(dataTable)
        }

        return function () {
            dataTable.destroy()
        }

    }, [columns, datas])
    useEffect(() => {}, [dataTableRef.current, parameterForm])
    function serializeObject(form: any) {
        var obj = {};
        var formObj = $(form)
        try {
            if(form.tagName && form.tagName.toUpperCase() == "FORM" ) {
                var dis = formObj.find(":disabled").removeAttr("disabled")
                var arr = formObj.serializeArray();
                dis.prop("disabled",true)
                if(arr){
                    obj = {};
                    $.each(arr, function() {
                        // @ts-ignore
                        var datepicker = formObj.find("[name='"+this.name+"']").data("r-datepicker")
                        // @ts-ignore
                        var value = $.trim(this.value)
                        if(datepicker){
                            //value = this.value.replace(/[-]/g,"")
                        }else{
                            //value = jQuery.trim(this.value)
                        }
                        // @ts-ignore
                        if(obj[this.name] instanceof Array){
                            // @ts-ignore
                            obj[this.name].push(value);
                            // @ts-ignore
                        }else if(obj[this.name] !== undefined){
                            // @ts-ignore
                            var ori = obj[this.name];
                            // @ts-ignore
                            obj[this.name] = [];
                            // @ts-ignore
                            obj[this.name].push(ori);
                            // @ts-ignore
                            obj[this.name].push(value);
                        }else{
                            // @ts-ignore
                            obj[this.name] = value;
                        }
                    });
                }
            }
        }catch(e) {
            // @ts-ignore
           console.error(e?.message);
        }finally  {}
        return obj;
    }
    return (
        /*<div className={'ge-result'}>*/
        <div>
            {props.children}
            {(props.datas && param.dataUrl === undefined) && !props.isHideTotCnt &&
                <div className="dataTables_info" id="DataTables_Table_32_info" role="status" aria-live="polite">
                    <span className="sh-fs-b2 sh-fw-5">Total <span
                        className="sh-text-accent">{props.datas.length}</span></span>
                </div>
            }

            <table
                className={"sh-table-primary w-100 " + (props.className ?? props.className)}
                ref={dataTableRef}
            />
        </div>


    )
}
