import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {IoMdClose} from "react-icons/io";
import SHSelect from "../../../components/common/SHSelect";
import {Modal, Table} from "react-bootstrap";
import SHTreeView from "../../../components/common/SHTreeView";
import restapi from "../../../helper/restAPIHelper";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import SHSearch from "../../component/SHSearch";
import SHInput from "../../../components/common/SHInput";
import axios from "axios";
import {toast} from "react-toastify";
import DprtDtlStatModal from "./DprtDtlStatModal";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;

    siteId: number;
    wrkDt: string;

}

const WrkPlcStatModal = (props: IProps) => {
    const [dataList, setDataList] = useState<any>([])
    const [datatable, setDatatable] = useState<any>();
    const searchFormRef = useRef<any>();
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [wrkPlcCd, setWrkPlcCd] = useState<any>(null);


    const fn_afterOpenEvnt = () => {
        console.log('aft open');
        const param = {siteId:props.siteId, wrkDt:props.wrkDt}

        restapi.retrieveWrkPlcInfoList(param).then((result) => {
            console.log('result: ', result);
            setDataList(result);
        })
    }


    function fn_closeModal() {
        props.setModalIsOpen(false);
    }





    return (
        <div>
            <Modal
                className="sh-modal sub" fullscreen
                show={props.modalIsOpen}
                onShow={fn_afterOpenEvnt}
                onHide={fn_closeModal}
            >
                <DprtDtlStatModal qrNfcTpCd={'T003002'} wrkPlcCd={wrkPlcCd} siteId={props.siteId} wrkDt={props.wrkDt} modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen}></DprtDtlStatModal>

                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        작업구간 목록
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4 sh-modal-table-w100">

                    <div className={'ge-result'}>
                        <Datatables
                            parameterFormRef={searchFormRef}
                            scrollY="550px"
                            className="sh-fs-b4"
                            columns={[
                                {data: "useDt", title: "일자", textAlign: 'center'},
                                {data: "wrkPlcNm", title: "작업위치", textAlign: 'center'},

                            ]}
                            checked={false}
                            index={true}
                            onLoad={(dt: any) => {
                                setDatatable(dt);
                            }}
                            isNotPaging={true}
                            isNotOrdering={true}
                            datas={dataList}
                            onClick={(data) => {
                                setModalIsOpen(true);
                                setWrkPlcCd(data.wrkPlcCd);
                            }}
                        />
                    </div>

                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default WrkPlcStatModal;
