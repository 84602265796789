import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import SHInput from "../../../components/common/SHInput";
import SHTextarea from "../../../components/common/SHTextarea";
import SHFile from "../../../components/common/SHFile";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {validFormData} from "../../../helper/FormHelper";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import {ProgressContext} from "../../../context/Progress";
import restapi from "../../../helper/restAPIHelper";
import SHSelect from "../../../components/common/SHSelect";
import {MultiSelect} from "react-multi-select-component";
import {COMMON_MULTISEL_STRINGS} from "../../../helper/StyleHelper";
import {CmmnFn} from "../../../helper/CmmnHelper";
import Select from 'react-select';

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
}

const SafetyManagementBoardModal = (props: IProps) => {

    const [data, setData] = useState<any>();
    const [repFileSn, setRepFileSn] = useState<any>(null);
    const [fileList, setFileList] = useState([]);
    const formRef = React.createRef<HTMLFormElement>();
    const {spinner} = useContext(ProgressContext);
    const user: any = getLoginUser();
    const [refSidoList, setRefSidoList] = useState<any>([]);
    const [selectedSidoList, setSelectedSidoList] = useState<any>([]);
    const [refSiteList, setRefSiteList] = useState<any>([]);
    const [selectedSiteList, setSelectedSiteList] = useState<any>([])

    useEffect(() =>{
        let pushSiteData: object[] = [];

        restapi.retrieveSiteDataListNoPaging({siteId:null}).then((rsltData) => {
            rsltData.map((data:any) =>{
                pushSiteData.push({
                    label:data.siteName, value: data.siteId
                });
            });
        });
        // @ts-ignore
        setRefSiteList(pushSiteData);

        let pushSidoData: object[] = [];
        restapi.retrieveBjdDataList('SEOUL').then((rsltData) => {
            rsltData.map((data:any) =>{
                pushSidoData.push({
                    label:data.codeNm, value: data.code
                });
            });
        });
        // @ts-ignore
        setRefSidoList(pushSidoData);

    },[])

    const fn_updateData = (e: any) => {
        if (e.target.type == 'checkbox') {
            setData({
                ...data,
                [e.target.name]: e.target.checked
            })
            console.log(e.target.checked)
        } else {
            setData({
                ...data,
                [e.target.name]: e.target.value
            })
        }
    }

    const fn_registData = () => {

        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if (!data.receiveTypeCd) {
            toast.warn('수신구분을 선택해 주십시오.')
            return;
        }

        if (data.receiveTypeCd == 'S006002') {//현장별 선택 확인
            if(selectedSiteList.length == 0){
                toast.warn('현장을 선택해 주십시오.')
                return;
            }
        }

        if (data.receiveTypeCd == 'S006003') {//지역별 선택 확인
            if(selectedSidoList.length == 0){
                toast.warn('지역을 선택해 주십시오.')
                return;
            }
        }

        var option = {
            addValues: {
                regSeq: user.userSeq,
                updateSeq: user.userSeq,
            },
            validOpt: {
                title: '제목을 입력해 주십시오.',
                contents: '내용을 입력해 주십시오.',
            },
            files: ['files']
        }

        var formData: any = validFormData(formRef, option)
        if(!formData){
            return;
        }

        if(fileList.length > 0){
            formData.set('repFileSn', repFileSn);
        }

        let rcvrIdList: string[] = [];

        if(data.receiveTypeCd == 'S006002'){
            selectedSiteList.map((data: any) => {
                rcvrIdList.push(data.value + '');
            })
        }else if(data.receiveTypeCd == 'S006003'){
            selectedSidoList.map((data: any) => {
                rcvrIdList.push(data.value);
            })
        }

        formData.set('rcvrIdList', rcvrIdList);

        if (formData) {
            if (!window.confirm('저장 하시겠습니까?')) {
                return;
            }

            spinner.start();
            axios.post('/admin/api/sftyMngBrd/saveSftyMngBrdData', formData).then((result) => {

                if (result.data > 0) {
                    toast.success(messageCodeDic['001'])
                    props.setModalIsOpen(false);
                } else {
                    toast.error(errorCodeDic['003']);
                }
            }).catch(error => {
                toast.error(errorCodeDic['003']);
                console.log('error: ', error);
            }).finally(() => {
                spinner.stop();
            });
        }
    }

    const fn_openModal = () => {
        if(!props.detailData){
            setFileList([]);
            setData(null);
            setSelectedSidoList([]);
            setSelectedSiteList([]);
        }else{
            setData(props.detailData);

            restapi.retrieveSftyMngBrdRcvrDataList({sftyMngBrdId: props.detailData.sftyMngBrdId, receiveTypeCd: props.detailData.receiveTypeCd}).then((rsltData: any) => {

                let items: any = [];
                let rcvrId: any;
                rsltData.map((data: any) => {
                    if(props.detailData.receiveTypeCd == 'S006002'){
                        rcvrId = parseInt(data.rcvrId);
                    }else{
                        rcvrId = data.rcvrId;
                    }

                    items.push({label: data.rcvrNm, value: rcvrId});
                })
                console.log('items: ', items);

                if(props.detailData.receiveTypeCd === 'S006002'){
                    setSelectedSiteList(items);
                }else if(props.detailData.receiveTypeCd === 'S006003'){
                    setSelectedSidoList(items);
                }
            })


            if(props.detailData.atchFileId){
                restapi.retrieveAttachInfoList({attachSeq: props.detailData.atchFileId}).then((rsltData: any) => {
                    if (props.detailData?.repfileSn !== null && props.detailData?.repfileSn !== undefined) {
                        rsltData.map((data: any, idx: number) => {
                            if (data.sn == props.detailData.repfileSn) {
                                data.isRep = true
                                setRepFileSn(idx);
                            }
                        })
                    }

                    setFileList([].concat(rsltData));
                })
            }else{
                setFileList([]);
            }
        }
    }

    const fn_closeModal = () => {
        props.setModalIsOpen(false);
    }

    const submit = (e: any) => {
        e.preventDefault()

    }

    return (
        <div>
            <Modal
                className="sh-modal" size="xl"
                show={props.modalIsOpen}
                onShow={fn_openModal}
                onHide={fn_closeModal}
            >

                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        안전관리 등록/수정
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <form name={'detailForm'} ref={formRef} encType="multipart/form-data" method={"post"} onSubmit={submit}>
                        <SHInput type={'hidden'} value={data?.atchFileId} name={'atchFileId'}></SHInput>
                        <SHInput type={'hidden'} value={data?.sftyMngBrdId} name={'sftyMngBrdId'}></SHInput>

                        <div className="sh-modal-div">
                            <div className="div-header">
                                수신자 정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle" style={{tableLayout:"fixed"}}>
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>수신구분</th>
                                        <td style={{width: '35%'}}>
                                            <SHSelect initOptionGb={'sel'} className={'w-100'} name={'receiveTypeCd'} codeGrpId={'S006'}
                                                      onChange={fn_updateData}
                                                      value={data?.receiveTypeCd}
                                                      disabled={data?.sftyNtfctnMngId?true:false}
                                            ></SHSelect>
                                        </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th className="sh-bg-th" style={{width: '15%'}}>수신대상</th>
                                        <td colSpan={3} style={{width: '85%'}}>
                                            {
                                                data?.receiveTypeCd == '' || data?.receiveTypeCd == null ? <span>수신구분을 선택해주세요.</span>
                                                    :data?.receiveTypeCd == 'S006001'?
                                                        <span>모든 대상에게 알림</span>
                                                        :data?.receiveTypeCd == 'S006002'?
                                                            <Select
                                                                         options={refSiteList}
                                                                         value={selectedSiteList}
                                                                         isDisabled={data?.sftyNtfctnMngId?true:false}
                                                                         onChange={setSelectedSiteList}
                                                                         placeholder={"참조 선택"}
                                                                         isMulti

                                                            />
                                                            :<Select
                                                                          options={refSidoList}
                                                                          value={selectedSidoList}
                                                                          isDisabled={data?.sftyNtfctnMngId?true:false}
                                                                          onChange={setSelectedSidoList}
                                                                          placeholder={"참조 선택"}
                                                                          isMulti

                                                            />
                                            }
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        <div className="sh-modal-div">
                            <div className="div-header">
                                안전관리 정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>작성자</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput type="text" className="form-control" name={'userName'}
                                                     onChange={fn_updateData}
                                                     value={data?.userName ? data?.userName : user.userName}
                                                     disabled={true}/>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>작성일시</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput type="text" className="form-control" name={'rgstDt'}
                                                     onChange={fn_updateData} value={data?.rgstDt || CmmnFn.fn_getCurrentDate()} disabled={true}/>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>제목</th>
                                        <td colSpan={3}>
                                            <div className="d-flex gap-2">
                                                <div className="d-flex align-items-center flex-grow-1">
                                                    <SHInput type="text" className="form-control" name={'title'}
                                                             onChange={fn_updateData} value={data?.title} placeholder={'제목을 입력해주세요.'}/>
                                                </div>
                                                <div className="d-flex align-items-center gap-1 flex-grow-2">
                                                    <input className="form-check-input sh-check" id={'isFixedTop'} checked={data?.isFixedTop == true || data?.isFixedTop == 't' ? true : false}
                                                           name={'isFixedTop'} type="checkbox" onChange={fn_updateData}/>
                                                    <label className={'form-check-label text-black'}
                                                           htmlFor={'isFixedTop'}>상단고정</label>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>내용</th>
                                        <td style={{width: '85%'}} colSpan={3}>
                                            <SHTextarea rows={5} onChange={fn_updateData} name={'contents'}
                                                        value={data?.contents}
                                                        className={"form-control"}
                                                        placeholder='내용을 입력해주세요.'/>
                                        </td>
                                    </tr>
                                    <tr className="align-middle" style={{width: '15%'}}>
                                        <th className="sh-bg-th">첨부파일</th>
                                        <td colSpan={3}>
                                            <SHFile fileName={'files'} fileList={fileList} setFileList={setFileList}
                                                    repFileSn={repFileSn} setRepFileSn={setRepFileSn}></SHFile>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </form>
                </Modal.Body>

                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        {user.authCode != 'headRead' &&
                            <button onClick={fn_registData}
                                    className='sh-btn pill p'>등록/수정
                            </button>
                        }
                    </div>
                </Modal.Footer>

            </Modal>
        </div>
    )
}

export default SafetyManagementBoardModal;
